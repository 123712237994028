//
// Song Title
//
// Interpret, nazev pisne, ostylovano notickou vlevo
//



$song-title_font-size_mobile: 14px;
$song-title_font-size_desktop: $base-font-size;



.song-title {
	
	color: $color_text_paragraph;
	
	padding-left: 1.6667em;
	
	@include adjust-font-size-to($song-title_font-size_mobile, 0.75);
	
	background-image: $file_icon_note_double;
	background-repeat: no-repeat;
	background-size: 1em 1em;
	background-position: left top 3px;
	background-position-x: left; // IE safe positioning
	background-position-y: top 3px;
	
	@include breakpoint($breakpoint_desktop) {
		
		@include adjust-font-size-to($song-title_font-size_desktop, 1);
		
		background-position: left top 5px;
		background-position-x: left; // IE safe positioning
		background-position-y: top 5px;
	}
}
	
	.song-title__link {
		
		@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
	}
		
		.song-title__performer {
		}