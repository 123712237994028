//
// Prototypovani carouselu
//

.radiozurnal,
.dvojka,
.plus,
.wave,
.jazz {

	.cro-carouselVideo.inited {
		position: relative;

		.b-006__subsection {
		}

			.b-006__list {
				display: flex;
				flex-direction: row;

				overflow: hidden;
			}

				.b-006__list-item {
					position: relative;

					min-width: 100%;
				}
	}

	// u regionu ve kterem je umisten carousel
	// potrebujeme overflow kvuli pretekajicim buttonum
	.l-grid--12-1 {
		overflow: visible;
		padding-top: 0.1px;  // HACK: resi margin collapsing po nastaveni overflow na visible
	}
}



// pokud je carousel vložen do aside, zobrazuje se rovnou mobilní zobrazení
.l-grid--4-9,
.l-grid--4-1 {
	#b044a {
		.b-044a__list-item {
			min-width: 80%;
		}
	}
}