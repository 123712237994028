//
// Layout
//


$layout_page_max-width: 1250px;


$layout_main_horizontal-padding_mobile: 10px;
$layout_main_horizontal-padding_desktop: 40px;


$layout_grid_column-gutter_tablet: 25px; // TODO toto neni z zadneho Alesovo navrhu, tablet navrhy neexistuji
$layout_grid_column-gutter_desktop: 50px;