//
// Zelenavlna - mapa
//



.zelenavlna {
}

	// zresponzivneni mapy
	.zelenavlna div#trafficTabs-map {
		overflow: hidden;
		left: 0;
		top: 0;
		margin-top: .5em;
		height: calc(100% - 100px);
		width: 100% !important;
		position: relative;
		padding-bottom: 56.25%;	
	}
	
	// oprava velikosti ikon
	.zelenavlna .gm-style div:not(.gm-bundled-control-on-bottom) > div:not([class*='gm-style-iw']) > div:not([class*='gm-style-iw']) > div {
		height: 48px !important;
	}

	// opravy velikosti popisku u mapy
	.zelenavlna #zoom-button {
		width: 115px;
	}
	.zelenavlna #selection-button {
		width: 92px;
	}
	.zelenavlna #zoom-button,
	.zelenavlna #route-button,
	.zelenavlna #layers-button,
	.zelenavlna #map-button,
	.zelenavlna #selection-button,
	.zelenavlna .traffic-map-button,
	.zelenavlna #regions-map-control {
		display: none !important;
		@include breakpoint($breakpoint_desktop) {
			display: block !important;
		}
	}

	.zelenavlna #zoom-button.traffic-map-button ~ #regions-map-control {
		@include breakpoint($breakpoint_desktop) {
			display: none !important;
		}
	}

	.zelenavlna #zoom-button.traffic-map-button.active ~ #regions-map-control {
		@include breakpoint($breakpoint_desktop) {
			display: block !important;
		}
	}
	
	// korekce 
	.zelenavlna #regions-map-control,
	.zelenavlna form#layers-control.traffic-map-control,
	.zelenavlna form#map-type-control.traffic-map-control,
	.zelenavlna form#selection-control.traffic-map-control {
		top: 26px !important;
	}
	.zelenavlna #regions-map-control.traffic-map-control {
		width: 264px !important;
		height: 165px !important;
	} 

	.zelenavlna #route-control,
	.zelenavlna #route-button.traffic-map-button {
		// skryto, jelikož servis stejne nefunguje
		// The webpage is not allowed to use the directions service.
		display: none !important;
	}

	.zelenavlna #zoom-button,
	.zelenavlna #route-button,
	.zelenavlna #layers-button,
	.zelenavlna #map-button,
	.zelenavlna #selection-button {
		white-space: nowrap;
	}
	
	.zelenavlna .box.box-jsdi {
		margin-top: .75em;
		margin-bottom: 2em;
		@include breakpoint($breakpoint_desktop) {
			display: flex;
			justify-content: center;
		} 
		&:after {
			content: '';
			display: table;
			clear: both;
		} 
		p.info-jsdi {
			white-space: nowrap !important;
			margin-top: 0 !important;
			padding-top: 0 !important;
			padding-bottom: 0 !important;	
			width: 100%;
			@include breakpoint($breakpoint_tablet) {
				width: auto;
				left: 100%;
				margin-right: 20px;
				margin-top: 10px !important;
			}
		}
		.legend {
			position: relative !important;
			width: 100% !important;
			margin-top: 0 !important;
			right: auto !important;
			display: block;
			@include breakpoint($breakpoint_tablet) {
				width: 575px !important;
			}
			ul li {
				min-height: 34px;
				margin-bottom: 20px;
				line-height: 3;
				@include breakpoint($breakpoint_tablet) {
					margin-bottom: 0;
				}
				// vytluky
				&.holes {
					margin-left: 0 !important;
					padding-right: 0 !important;
				}
			}
			.header {
				float: none;
				@include breakpoint($breakpoint_tablet) {
					float: left;
					line-height: 3;
				}
			}
			.header::after {
				content: '';
				display: table;
				clear: both;
			}
			p {
				width: 100%;
				margin-top: 0 !important;
				margin-bottom: 5px !important;
				@include breakpoint($breakpoint_tablet) {
					width: auto;
				}
			}
		}
	}