//
// B-041a Styles
//



.b-041a {
}
	
	.b-041a__list {
	}
		
		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-041a__list-item {
			
			display: flex;
			flex-direction: row;
		}
			
			.b-041a__block--image {
				
				flex-shrink: 0;
				
				max-width: 5em;
				padding-bottom: 1em;
				
				@include breakpoint($breakpoint_desktop) {
					max-width: 7em;
				}
					
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					position: relative;
				}
				
				.image--mobile {
					
					display: block;
				
					@include breakpoint($breakpoint_desktop) {
							display: none;
					}
				}
				
				.image--desktop {
					
					display: none;
				
					@include breakpoint($breakpoint_desktop) {
						display: block;
					}
				}
			}
			
			
			.b-041a__block--description {
				
				padding-left: 0.75em;
				
				max-width: calc(100% - 5em - 0.5em); // kvuli stitkum, 5em je sirka obrazku, 0.5em je padding
				
				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041a__block--description .tag {
					margin-bottom: 0.5em;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041a__block--description h3 {
					margin: 0;
					margin-bottom: 0.5em;
				}
					// kdyz se odstrani pole autor, a je h3 posledni dite, chceme vetsi margin
					.b-041a__block--description h3:last-child {
						margin-bottom: 1em;
					}
				
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041a__block--description a p {
					
					margin-top: 0.5em;
					
					color: $color_text_paragraph;
					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041a__author-link {
					
					display: inline-block;
					
					margin-bottom: 1em;
					
					color: $color_text_accent;
					
					@include adjust-font-size-to($fontsize_author_main);
					
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($fontsize_author_main_desktop);
					}
				}
					a.b-041a__author-link {
						
						@include link-style($color_decoration_main, none, $color_decoration_main, underline);
					}
