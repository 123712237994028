//
// Rada - Clenove
//



.rada.page-clenove {
	.rada-custom {
		img {
			margin-bottom: 1.25rem;
		}
		font-size: 19px;
		line-height: 1.6em;
		li {
			margin-bottom: 1.25rem;
		}
	}
	// fulltext nechceme
	.view-filters form .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper {
		display: none;
	}
}
