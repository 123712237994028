//
// Rada - Zapisy
//



.rada.page-zapisy {

	// widget
	.view-filters form .views-exposed-form .views-exposed-widgets {
		margin-bottom: 2em;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 3em;
		}
	}

	// widget - checkboxy nechceme
	.view-filters form .views-exposed-form .views-exposed-widgets .bef-checkboxes {
		display: none;
	}

	// widget - label selectu
	.view-filters form .views-exposed-form .views-exposed-widgets label {
		display: inline-block;

		@include typography_font(regular);
		font-size: 15px;
		line-height: 19px;

		margin-bottom: 0.5em;
	}

}
