//
// Rada - HP
//



.rada.page-rada-hp {

	// b-002g - legendarni znasilnovany box, idealne na kazdem webu jinak
	.b-002g {

		.b-002g__list {
		}

			.b-002g__list-item {
				color: $color_text_paragraph;

				strong {
					@include typography_font(medium);
				}

				a {
					@include typography_font(medium);
					border-bottom: 1px solid $color_background-9;
					@include link-style($color_text_link, none, $color_text_link, none);

					&:hover {
						color: $color_background-6;
						border-bottom: 1px solid $color_background-6;
					}
				}

				ul {

					li {
						list-style: disc;
						margin-left: 1em;
					}
				}

				ol {

					li {
						list-style: decimal;
						margin-left: 1.5em;
					}
				}
			}
	}

}
