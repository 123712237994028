//
// Concertino Praga - HP
//



.page-concertino-hp,
.page-concertino-en-hp {
  // B-007a - Carousel
  // definice pro Wave jsou ještě v wave_hp.scss, nutno zglobalizovat
  .b-007a {
    @include breakpoint($breakpoint_desktop) {
      /*flex-basis: 60%;
      flex-grow: 1;
      max-width: 65.5%; // fallback pro calc()
      max-width: calc(67% - 1em);*/

      //width: 65.5%; // fallback pro calc()
      //width: calc(67% - 1em);

      //margin-right: 2em;
    }

    .b-007a__list-item {

      ul li:not(:first-child) {
        display: none;
      }

      @include breakpoint($breakpoint_desktop) {

        min-width: 80%;

        margin-bottom: $b-007a_marginbottom_item; // kvuli pretekajicimu boxu s H3 Title
      }
    }

    .b-007a__block--second {

      @include breakpoint($breakpoint_desktop) {

        max-width: 65%;

        padding: 0.5em;

        right: 3em;
        bottom: -1em;
      }
    }
  }
}
