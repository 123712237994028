// obecne styly - pro vsechny formulare
form {

	.form-item label {
		@include typography_font(regular);
	}

	.form-item .description {
		color: $color_text_paragraph;
		margin-top: .5em;
	}

	.form-actions {
		margin-bottom: 0;
		margin-top: 1.875em;
	}

	.marker,
	.form-required {
		@include typography_font(regular);
		color: $color_decoration_main;
	}

	&.user-login,
	&.user-pass {
		@include breakpoint($breakpoint_desktop) {
			width: 50%;
		}
	}

	.grippie {
		display: none;
	}

	fieldset.date-combo .container-inline-date .date-padding {
		padding: 0;
	}

	legend span {
		font-size: .875rem;
		line-height: 1.5rem;
		text-transform: uppercase;
	}

	.pre-instructions {
		@extend %pre-instructions;
		p {
			margin-top: 0;
		}
	}

	p {
		font-size: .9em;
	}

	.form-item.form-type-checkbox {
		@extend %form-checkbox;
	}

	.form-item.form-type-radio {
		@extend %form-radio;
	}

	[id^='edit-actions'] {
		@extend %form-submit-button-and-disclaimer;
	}

	// plupload responsivity fix
	.plupload_wrapper {
		min-width: 100%;

		.plupload_header_content {
			padding: 0 10px 0 60px;

			@include breakpoint($breakpoint_desktop) {
				padding: 0 160px 0 60px;
			}

			.plupload_header_text {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}


// specificke styly - pro vybrane formulare
.view-filters form,
#search-form {
	padding: 0;
	background: none;
}


// zrejme historicke - overit (pouziva se cro_SearchLightBox)
.b-014a__searchbar form {
	margin-top: 1em;
	input {
		float: left;
		height: 1.4em;
		margin-right: 1.4em;
	}

	button {
		height: 1.4em;
		padding: .2em;
		float: left;

		span {
			width: 1em;
			height: 1em;
			margin: 0;
			padding: 0;
		}
	}
}


// Newsletter form
#cro-common-newsletter-ecomail-entityform-edit-form {
	// pokud bude v sablone upraveno do pre-instructions, muzou se styly pro "p" odstranit
	p {
		color: $color_text_paragraph;
		font-size: .9em;
		margin-top: 0;
	}
}


// Vltava
#kontakt-vltava-entityform-edit-form {
	input[type="submit"] {
		width: 30%;
	}
}


// Prix Bohemia Radio
// akreditacni form
//
// Pardubice, Hradec, Liberec
// form Pisnicky od srdce
#cro-pbr-accreditation-entityform-edit-form,
#cro-pbr-accreditation-en-entityform-edit-form,
#cro-common-pisnicky-od-srdce-entityform-edit-form {
	// wrapper?
	> div {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}

	// instrukce - Prix Bohemia Radio
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) .pre-instructions {
		p {
			font-size: .9em;
			color: $color_text_paragraph;

			margin-top: 0;
			padding-bottom: 1em;

			border-bottom: 1px solid $color_background-4;
		}

		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}

	}

	// jmeno (cele) - Pisnicky od srdce
	// jmeno (krestni) - Prix Bohemia Radio
	// ucast jako - Prix Bohemia Radio
	// mobil - Prix Bohemia Radio
	&:not(#cro-pbr-accreditation-entityform-edit-form) #edit-field-form-name,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-form-firstname,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-pbr-occupation,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-pbr-occupation-en,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-form-mobile {
		@extend %form-field-size-responsive-half;
		@extend %form-mr;
	}
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-pbr-occupation,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-pbr-occupation-en {
		#edit-field-pbr-occupation-school,
		#edit-field-pbr-occupation-cro {
			margin-top: .5em;
			margin-bottom: 1em;

			label {
				font-size: 12px;
				line-height: 22px;
			}
		}
	}

	// email - Pisnicky od srdce
	// jmeno (prijmeni) - Prix Bohemia Radio
	// dny ucasti - Prix Bohemia Radio
	// email - Prix Bohemia Radio
	&:not(#cro-pbr-accreditation-entityform-edit-form) #edit-field-form-email,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-form-name,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-pbr-event-attendance,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-pbr-event-attendance-en,
	&:not(#cro-common-pisnicky-od-srdce-entityform-edit-form) #edit-field-form-email {
		@extend %form-field-size-responsive-half;
	}

	// datum
	#edit-field-form-date {
		flex-basis: 100%;

		// warpper
		.form-item.form-type-textfield.form-item-field-form-date-und-0-value-date {
			display: flex;

			// label kalendar
			label {
				position: unset !important; // musi byt important, protoze prebiji jiny important :/
				display: block;

				font-size: 0; // HACK: nechci text

				width: 33px; // nemuze byt v em, protoze font je 0
				height: 33px; // nemuze byt v em, protoze font je 0

				margin-right: 10px; // nemuze byt v em, protoze font je 0

				background-color: $color_decoration_main;

				background-image:$file_icon_calendar_colored-white;
				background-size: 55%;
				background-repeat: no-repeat;
				background-position: 50%;
			}

			// description
			.description {
				display: none;
			}
		}
	}

	// porad
	#edit-field-form-show {
		flex-basis: 100%;

		// label k radiobuttonum poradu
		> .form-item-field-form-show-und > label {
			display: none;
		}
	}

	// pisnicka
	#edit-field-form-subject {
		flex-basis: 100%;
	}

	// prani
	#edit-field-form-message {
		flex-basis: 100%;
	}

	// newsletter
	#edit-field-form-newsletter {
		flex-basis: 100%;
		margin-top: 1em;
		@extend %form-checkbox;
	}

	// submit & disclaimer
	#edit-actions {
		flex-basis: 100%;

		display: flex;

		@include breakpoint($breakpoint_desktop) {
			align-items: center;
		}

		input {
			@include breakpoint($breakpoint_desktop) {
				margin-right: 1em;
			}
		}

		p {
			font-size: .9em;
			color: $color_text_paragraph;

			@include breakpoint($breakpoint_desktop) {
				margin: 0;
			}

			a {
				@include link-style($color_decoration_main, none, $color_decoration_main, underline);
			}
		}
	}

	// label obecne
	label {
		a {
			text-transform: unset;
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
			@include breakpoint($breakpoint_desktop) {
				font-size: .9em;
			}
		}
		span {
			@include typography_font(medium);
		}
	}

	// input obecne
	input:not([type="submit"]) {
		padding: 0 .5em;
		@include breakpoint($breakpoint_desktop) {
			font-size: .9em;
		}
	}
	input[type="radio"]+label,
	input[type="checkbox"]+label {
		text-transform: unset;
		@include breakpoint($breakpoint_desktop) {
			//font-size: 1em;
		}
	}
	input[type="radio"]:not(:checked)+label,
	input[type="checkbox"]:not(:checked)+label{
		color: $color_background-5;
	}

	// textarea obecne
	textarea {
		padding: 0 .5em;
		@include breakpoint($breakpoint_desktop) {
			font-size: .9em;
		}
	}

	// checkboxes/radios wrappers obecne
	.form-checkboxes .form-item,
	.form-radios .form-item {
		margin-bottom: 0;
	}
}


/* Form na kontaktovani autora clanku */
.page-node {

	// wrapper (sys message & button)
	.cro-node-contact-form-link-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;

		clear: both;

		margin-top: 2em;

		border-top: 1px solid $color_background-4;

		// sys message
		.cro-node-contact-form-message {

			.messages {
				margin: 0; // reset default hodnot
				margin-top: 2em;
			}
		}

		// button
		.cro-node-contact-form-link {
			margin-top: 2em;
			margin-left: auto;
			margin-right: auto;
			padding: 1em;

			background-color: $color_decoration_main;

			@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
		}
	}

	// modal
	#modalContent.modal-cro-node-contact-form {

		background: rgba(0,0,0,.75) none repeat scroll 0 0;

		position: fixed !important; // prebiji inline styly ctools
		top: 0 !important; // prebiji inline styly ctools
		left: 0 !important; // prebiji inline styly ctools
		right: 0 !important; // prebiji inline styly ctools
		bottom: 0 !important; // prebiji inline styly ctools

		width: 100vw !important; // prebiji inline styly ctools
		height: 100vh !important; // prebiji inline styly ctools

		.ctools-modal-content {
			background: none;

			width: 100% !important; // prebiji inline styly ctools
			height: 100% !important; // prebiji inline styly ctools

			margin: 0;
			padding: 0;

			border: none;

			.modal-header {
				display: none;
			}

			.modal-content {

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				width: 100% !important; // prebiji inline styly ctools
				height: 100% !important; // prebiji inline styly ctools

				margin: 0;
				padding: 0;

				// samotnej form
				form#cro-node-contact-form,
				form#cro-node-contact-form--2 {

					// textarea warpper (ctools?)
					.form-textarea-wrapper {
						width: unset; // reset ctools hodnot
						margin-left: unset; // reset ctools hodnot
						margin-right: unset; // reset ctools hodnot
					}

					// button Cancel
					#edit-cancel,
					#edit-cancel--2 {
						//@include breakpoint($breakpoint_desktop) {
							margin-left: 1em;
						//}
					}
				}
			}
		}
	}
}


.login-webeditor {
	form {
		&.user-login {
			background: $color_background-0;
			display: flex;
			align-self: center;
			justify-content: center;
			width: 100%;
			height: 100vh;
			min-width: 420px;
			margin-left: -60px;
			margin-right: -60px;
			@include breakpoint($breakpoint_tablet) {
				margin-left: 0;
				margin-right: 0;
			}
			// pretizeni defaultnich hodnot
			input[type="text"],
			input[type="password"] {
				border: 1px solid $color_background-4;
				border-radius: 3px;
				line-height: 2.4em;
				padding-left: .5em;
				padding-right: .5em;
				width: 78%;
			}
			// input chrome background color - default yellow reset
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px white inset;
			}
			// placeholder color set
			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $color_background-6;
			}
			::-moz-placeholder { /* Firefox 19+ */
				color: $color_background-6;
			}
			:-ms-input-placeholder { /* IE 10+ */
				color: $color_background-6;
			}
			:-moz-placeholder { /* Firefox 18- */
				color: $color_background-6;
			}
			.form-item {
				display: flex;
			}
			// hlavni kontejner
			& > div {
				display: flex;
				align-self: center;
				flex-direction: column;
				max-width: 400px;
				background: $color_background-1 url(../images/icon/login-bcg.png) no-repeat center 30px;
				border-radius: 2px;
				padding: 44px;
				box-shadow: 0px 0px 1px 0px $color_background-5;
				padding-top: 95px;
			}
			label {
				width: 22%;
				height: 40px;
				float: left;
				text-transform: capitalize;
				line-height: 2.9;
			}
			select.form-select {
				width: calc(78% - 2px);
				background-color: $color_background-0;
				border: 1px solid $color_background-4;
			}
			// sirka submit tlacitka do plne sirky
			#edit-actions input[type="submit"] {
				width: 100%;
			}
		}
	}
}


// Vysocina - SOUTEZNI FORMULAR
.domain-vysocina-rozhlas-cz {
	#cro-vysocina-obce-entityform-edit-form {
		& > div {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			label {
				@include breakpoint($breakpoint_tablet) {
					font-size: .875rem;
				}
			}

			// levy sloupec
			#edit-field-form-firstname,
			#edit-field-form-email {
				@extend %form-field-size-responsive-half;
				@extend %form-mr;
			}

			// pravy sloupec
			#edit-field-form-name,
			#edit-field-form-mobile {
				@extend %form-field-size-responsive-half;
			}

			// 100% sirky
			#edit-field-form-location,
			#edit-actions {
				flex-basis: 100%;
			}
		}
	}

	// Vysocina - soutez o nejkrasnejsi knihu
	#cro-common-best-image-2-entityform-edit-form {
		.form-radios {
			align-items: flex-start;
		}

		#edit-field-form-name,
		#edit-field-form-location {

			@include breakpoint($breakpoint_tablet) {
				width: 49%;
				margin-right: 1%;
				float: left;
			}

			@include breakpoint($breakpoint_desktop) {
				width: 49.5%;
				margin-right: .5%;
			}
		}

		#edit-field-form-email,
		#edit-field-form-mobile {

			@include breakpoint($breakpoint_tablet) {
				width: 49%;
				margin-left: 1%;
				margin-right: 0;
			}

			@include breakpoint($breakpoint_desktop) {
				width: 49.5%;
				margin-left: .5%;
				margin-right: 0;
			}
		}

		#edit-field-form-best-image-2 {
			@include clearfix;
		}
	}
}


// Event - Pochoutkovy rok
.form-pochoutkovy-rok {
	h2.block__title {
		margin: 0;
	}

	.pre-instructions {
		h2 {
			margin: 1em 0;
			padding-left: 0;
		}

		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}
	}

	.focal-point-help, .focal-point-indicator, .file-icon {
		display: none;
	}
}


// Event - Hlasovani o nejlepsi film
.entityform.entitytype-cro_common_best_img_in_cat_1-form {
	// clearfix
	#edit-field-form-best-img-cat-1-0 {
		display: inline-block; // for Firefox
		@include clearfix;
		width: 100%;
	}
	// item harmoniky
	[id^='edit-field-form-best-img-cat-1'] {
		.form-item.form-type-radios {
			position: relative;
			margin-bottom: 0;
			// nadpis sekce
			& > label:not(.option) {
				margin-bottom: 10px;
				display: flex;
				z-index: 9;
				cursor: pointer;
				text-transform: inherit;
				background-color: $color_background-2;
				color: $color_background-6;
				transition: color 250ms ease-in-out;
				&:hover {
					text-decoration: underline;
					color: $color_background-9;
				}
				// pozadi pred textem labelu
				// pozadi za ikonou
				.bg-before,
				.bg-end {
					width: 20px;
					min-width: 20px;
					margin-right: 4px;
					// pseudotridy tvorici souvisle cary
					&:before,
					&:after {
						content: '';
						position: absolute;
						left: 0;
						width: 15px;
						height: 1px;
						background-color: $color_background-5;
					}
					&:before {
						top: 11px;
					}
					&:after {
						top: 13px;
					}
				}
				// uprava pro bcg za ikonou
				.bg-end {
					&:before,
					&:after {
						left: auto;
						right: 0;
					}
				}
				// vlastni textace
				.category-name {
					white-space: nowrap;
				}
				// pozadi za textem labelu
				.bg-after {
					display: flex;
					width: calc(100% - 37px);
					position: relative;
					// pseudotridy tvorici souvisle cary
					&:before,
					&:after {
						content: '';
						display: block;
						position: absolute;
						right: 30px;
						width: inherit;
						height: 1px;
						background-color: $color_background-5;
					}
					&:before {
						top: 11px;
					}
					&:after {
						top: 13px;
					}
				}
				// ikona pro otvirani itemu harmoniky
				.icon {
					&:after {
						transition: transform 300ms;
						content: '';
						background: url(/sites/all/themes/custom/e7/images/icon/ico_close_colored-white.svg) no-repeat center;
						background-size: 12px;
						width: 24px;
						height: 24px;
						position: absolute;
						right: 21px;
						top: 0;
						transform: rotate(45deg);
						background-color: $color_background-5;
						border-radius: 50%;
					}
				}
			}
			// ikona pro zavirani itemu harmoniky
			& > label.show:not(.option) {
				.icon {
					&:after {
						content: '';
						transform: rotate(0deg);
					}
				}
			}
		}
	}
	// chceckbox zasilani newsletteru
	#edit-field-form-newsletter {
		margin-top: 20px;
		@include breakpoint($breakpoint_desktop) {
			margin-top: 40px;
		}
		.form-item {
			display: flex;
			align-self: center;
			label {
				text-transform: inherit;
				color: $color_text_paragraph;
			}
		}
	}

	.field-widget-options-buttons {
		&.option-selected {
			.category-name::after {
				content: " ✓";
			}
		}
	}
	#cro-web-forms-selected-items {
		ul {
			padding-left: 20px;
		}
		li {
			list-style-type: disc;
		}
	}
}


// Bohemia Prix Radio
#pbr-form-apply-entityform-edit-form,
#pbr-form-apply-en-entityform-edit-form {

	.form-subtitle {
		margin: 0;
	}

	fieldset {
		margin: 1em 0 0;

		.fieldset-legend {
			display: block;
			@include typography_font(medium);
			text-transform: none;
			margin: .5em 0;
		}

		&#edit-category {
			margin-top: 0;
		}
	}

	#field-show-aired-add-more-wrapper,
	#edit-field-coverage-date, #field-multi-date-add-more-wrapper {
		.date-combo {
			margin: 1em 0 .5em;

			label:not([for="edit-field-multi-date-und-0-value2"]),
			.description,
			.form-item-field-multi-date-und-0-show-todate {
				display: none;
			}
		}

		.fieldset-legend {
			display: inline;
			@include typography_font(regular);
			text-transform: uppercase;
		}
	}

	.field-type-text,
	.field-type-text-long {
		width: 100%;
		clear: both;
	}

	.field-name-field-form-firstname,
	.field-name-field-secondname,
	.field-name-field-email,
	.field-name-field-phone,
	.field-name-field-director,
	.field-name-field-redaktor-dramaturg,
	.field-name-field-compositor,
	.field-name-field-technical-realisation,
	.field-name-field--production,
	/*.field-name-field-coverage-language,*/
	.field-name-field-film-length,
	.field-name-field-coverage-film-length {
		@include breakpoint($breakpoint_tablet) {
			width: 49%;
			float: left;
			clear: initial;
		}
	}

	.field-name-field-form-firstname,
	.field-name-field-email,
	.field-name-field-director,
	.field-name-field-compositor,
	.field-name-field--production,
	/*.field-name-field-coverage-language*/ {
		@include breakpoint($breakpoint_tablet) {
			margin-right: 2%;
		}
	}

	.field-name-field-review-available-en {
		.form-type-radio {
			margin: 0;

			label {
				text-transform: none;
			}
		}
	}
}

// Bohemia Prix Radio CZ
#pbr-form-apply-entityform-edit-form {

	.field-name-field-coverage-language,
	.field-name-field-coverage-film-length {
		@include breakpoint($breakpoint_tablet) {
			width: 49%;
			float: left;
			clear: initial;
		}
	}

	.field-name-field-coverage-language {
		@include breakpoint($breakpoint_tablet) {
			margin-right: 2%;
		}
	}
}

// Bohemia Prix Radio EN
#pbr-form-apply-en-entityform-edit-form {

	.field-name-field-film-length {
		@include breakpoint($breakpoint_tablet) {
			width: 100%;
		}
	}
}


// Concertino form
.concertino form {

	// nadpisy
	h1 {
		line-height: 1.2;
	}
	h2 {
		margin-bottom: 20px;
	}
	h3 {
	}

	// form field
	legend {
		width: 100%;
		position: relative;
		top: -5px;
		.fieldset-legend {
			font-size: 115%;
			display: block;
			margin-top: 10px;
			margin-bottom: 20px;
			text-transform: inherit;
			position: relative;
			&::after {
				content: '';
				width: 100%;
				height: 1px;
				background-color: $color_background-5;
				display: inline-block;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
	fieldset {
		background-color: $color_background-3;
		border-radius: 2px;
		margin-bottom: 20px;
		.fieldset-wrapper {
			padding: 10px;
		}
	}

	// char counter
	.max-length-info {
		font-size: .75rem;
	}

	// input add item
	input[type="button"].add_more_webform {
		background-color: $color_decoration_success;
		color: $color_background-0;
		padding: .5em .75em;
		font-size: 80%;
		border: 0;
		text-transform: uppercase;
		&:hover {
			opacity: .8;
			cursor: pointer;
		}
	}
}
// Concertino form - specific
#concertino-form-entityform-edit-form,
#concertino-form-en-entityform-edit-form,
#edit-field-solo-skladba-many,
#edit-field-chamber-with-piano {

	input[name*="_remove_button"] {
		display: none;
	}

	table.sticky-header {
		// skryti fixace hlavicky - !important je z duvodu prebijeni javascriptu
		visibility: hidden !important;
	}

	.part1 {
		@include typography_font(medium);
		margin: .5em 0 ;
	}

	.part2,
	.part3,
	.part4 {
		@include typography_font(medium);
		margin: 2.5em 0 .5em;
	}

	.field-name-field-birthdate,
	.field-name-field-death-date,
	.field-name-field-klavirista-birthdate {
		.date-combo {
			margin: 0 0 .5em 0;

			.description {
				display:none;
			}

			.date-padding > .form-item {
				display: flex;
			}

			label {
				background-image: $file_icon_calendar_colored-white;
				background-color: $color_decoration_main;
				background-size: 55%;
				background-repeat: no-repeat;
				background-position: 50% center;
				margin-right: 10px;
				position: unset !important;
				display: block;
				width: 33px;
				height: 33px;
				text-indent: -9999px;
			}
		}
	}

	// 2 cols
	.field-name-field-form-firstname,
	.field-name-field-secondname,
	.field-name-field-pbr-location,
	.field-name-field-state,
	.field-name-field-email,
	.field-name-field-phone,
	.field-name-field-form-email,
	.field-name-field-form-mobile,
	.field-name-field-show-lenght,
	.field-name-field--slo-tracku,
	.field-name-field-phone-2,
	.field-name-field-pbr-companion-email,
	.field-name-field-pbr-companion-mobile,
	.field-name-field-pbr-music-teacher-email,
	.field-name-field-pbr-music-teacher-mobile {
		@include breakpoint($breakpoint_tablet) {
			width: 49%;
			float: left;
		}
	}

	.field-name-field-form-firstname,
	.field-name-field-email,
	.field-name-field-pbr-location,
	.field-name-field-form-email,
	.field-name-field-show-lenght,
	.field-name-field-pbr-companion-email,
	.field-name-field-pbr-music-teacher-email {
		@include breakpoint($breakpoint_tablet) {
			margin-right: 2%;
		}
	}

	.field-name-field-instrument,
	.field-name-field-competition-category-en {
		.form-radios {
			@include breakpoint($breakpoint_tablet) {
				display: flex;
			}

			label {
				text-transform: none;
				margin-right: 1.5em;
			}

		}
	}

	// Field collection
	.field-type-field-collection {
		table {
			width: 100%;
			margin-top: 1em;
			position: relative;

			th {
				background-color: transparent;

				label {
					text-transform: none;
					text-transform: none;
					font-size: $fontsize_h3_node;
					@include typography_font(regular);
					margin-bottom: 1em;
					@include breakpoint($breakpoint_tablet) {
						font-size: $fontsize_h3_node_desktop;
					}
				}
			}

			tr.odd {
				border-top: 1px solid $color_background-5;
				border-bottom: 1px solid $color_background-5;
				background-color: $color_background-3;
			}

			td {
				background-color: $color_background-3;
				padding: 0 1em;
				display: inline-block;
			}

			td:not(.field-multiple-drag) {
				position: relative;
			}
		}

		// rozbaleny stav
		//cz
		table[id*='field-skladby-values--'] tbody tr,
		table[id*='field-solo-skladba-values--'] tbody tr,
		table[id*='field-skladby-multiple-values--'] tbody tr,
		table[id*='field-solo-skladba-many-values--'] tbody tr,

		//en
		table[id*='field-skladby-en-values--'] tbody tr,
		table[id*='field-solo-skladba-en-values--'] tbody tr,
		table[id*='field-skladby-multiple-en-values--'] tbody tr,
		table[id*='field-chamber-with-piano-values--'] tbody tr {
			position: relative;
			display: block;
			margin-top: 1em;
			padding-bottom: 1.5em;
			margin-bottom: 2em;
			background-color: $color_background-3;
			border-top: 1px solid $color_background-5;
			border-bottom: 1px solid $color_background-5;
		}

		.field-multiple-drag,
		.tabledrag-toggle-weight-wrapper {
			display: none;
		}

		.field-type-text,
		.field-type-date,
		.field-type-number-integer {
			margin-top: 1em;
		}

		.field-name-field-hra {
			clear: both;
		}

		.field-name-field-birthdate,
		.field-name-field-death-date {
			@include breakpoint($breakpoint_tablet) {
				width: 49%;
				float: left;
			}
		}

		.field-name-field-birthdate {
			@include breakpoint($breakpoint_tablet) {
				margin-right: 2%;
			}
		}

		// cz
		input[name="field_skladby_und_0_remove_button"],
		input[name="field_skladby_und_1_remove_button"],
		input[name="field_skladby_und_2_remove_button"],
		input[name="field_skladby_und_3_remove_button"],
		input[name="field_skladby_und_4_remove_button"],
		input[name="field_skladby_und_5_remove_button"],
		input[name="field_skladby_add_more"],
		input[name="field_solo_skladba_und_0_remove_button"],
		input[name="field_solo_skladba_und_1_remove_button"],
		input[name="field_solo_skladba_und_2_remove_button"],
		input[name="field_solo_skladba_und_3_remove_button"],
		input[name="field_solo_skladba_und_4_remove_button"],
		input[name="field_solo_skladba_und_5_remove_button"],
		input[name="field_solo_skladba_add_more"],
		input[name="field_skladby_multiple_und_0_remove_button"],
		input[name="field_skladby_multiple_und_1_remove_button"],
		input[name="field_skladby_multiple_und_2_remove_button"],
		input[name="field_skladby_multiple_und_3_remove_button"],
		input[name="field_skladby_multiple_und_4_remove_button"],
		input[name="field_skladby_multiple_und_5_remove_button"],
		input[name="field_solo_skladba_many_und_2_remove_button"],
		input[name="field_solo_skladba_many_und_3_remove_button"],
		input[name="field_solo_skladba_many_und_4_remove_button"],
		input[name="field_solo_skladba_many_und_5_remove_button"],
		input[name="field_skladby_multiple_add_more"],
		input[name="field_solo_skladba_many_add_more"],
		input[name="field_solo_skladba_many_und_0_remove_button"],
		// en
		input[name="field_skladby_en_und_0_remove_button"],
		input[name="field_skladby_en_und_1_remove_button"],
		input[name="field_skladby_en_und_2_remove_button"],
		input[name="field_skladby_en_und_3_remove_button"],
		input[name="field_skladby_en_und_4_remove_button"],
		input[name="field_skladby_en_und_5_remove_button"],
		input[name="field_skladby_en_add_more"],
		input[name="field_solo_skladba_en_und_0_remove_button"],
		input[name="field_solo_skladba_en_und_1_remove_button"],
		input[name="field_solo_skladba_en_und_2_remove_button"],
		input[name="field_solo_skladba_en_und_3_remove_button"],
		input[name="field_solo_skladba_en_und_4_remove_button"],
		input[name="field_solo_skladba_en_und_5_remove_button"],
		input[name="field_solo_skladba_en_add_more"],
		input[name="field_skladby_multiple_en_und_0_remove_button"],
		input[name="field_skladby_multiple_en_und_1_remove_button"],
		input[name="field_skladby_multiple_en_und_2_remove_button"],
		input[name="field_skladby_multiple_en_und_3_remove_button"],
		input[name="field_skladby_multiple_en_und_4_remove_button"],
		input[name="field_skladby_multiple_en_und_5_remove_button"],
		input[name="field_chamber_with_piano_und_2_remove_button"],
		input[name="field_chamber_with_piano_und_3_remove_button"],
		input[name="field_chamber_with_piano_und_4_remove_button"],
		input[name="field_chamber_with_piano_und_5_remove_button"],
		input[name="field_skladby_multiple_en_add_more"],
		input[name="field_chamber_with_piano_add_more"],
		input[name="field_chamber_with_piano_und_0_remove_button"] {
			padding: .2em 1em;
			margin-top: 3em;
			font-size: 85%;
		}

		.clearfix {
			margin-top: -1.5em;
		}
		// cz
		input[name="field_skladby_und_0_remove_button"],
		input[name="field_skladby_und_1_remove_button"],
		input[name="field_skladby_und_2_remove_button"],
		input[name="field_skladby_und_3_remove_button"],
		input[name="field_skladby_und_4_remove_button"],
		input[name="field_skladby_und_5_remove_button"],
		input[name="field_solo_skladba_und_0_remove_button"],
		input[name="field_solo_skladba_und_1_remove_button"],
		input[name="field_solo_skladba_und_2_remove_button"],
		input[name="field_solo_skladba_und_3_remove_button"],
		input[name="field_solo_skladba_und_4_remove_button"],
		input[name="field_solo_skladba_und_5_remove_button"],
		input[name="field_skladby_multiple_und_0_remove_button"],
		input[name="field_skladby_multiple_und_1_remove_button"],
		input[name="field_skladby_multiple_und_2_remove_button"],
		input[name="field_skladby_multiple_und_3_remove_button"],
		input[name="field_skladby_multiple_und_4_remove_button"],
		input[name="field_skladby_multiple_und_5_remove_button"],
		input[name="field_solo_skladba_many_und_0_remove_button"],
		input[name="field_solo_skladba_many_und_2_remove_button"],
		input[name="field_solo_skladba_many_und_3_remove_button"],
		input[name="field_solo_skladba_many_und_4_remove_button"],
		input[name="field_solo_skladba_many_und_5_remove_button"],
		// en
		input[name="field_skladby_en_und_0_remove_button"],
		input[name="field_skladby_en_und_1_remove_button"],
		input[name="field_skladby_en_und_2_remove_button"],
		input[name="field_skladby_en_und_3_remove_button"],
		input[name="field_skladby_en_und_4_remove_button"],
		input[name="field_skladby_en_und_5_remove_button"],
		input[name="field_solo_skladba_en_und_0_remove_button"],
		input[name="field_solo_skladba_en_und_1_remove_button"],
		input[name="field_solo_skladba_en_und_2_remove_button"],
		input[name="field_solo_skladba_en_und_3_remove_button"],
		input[name="field_solo_skladba_en_und_4_remove_button"],
		input[name="field_solo_skladba_en_und_5_remove_button"],
		input[name="field_skladby_multiple_en_und_0_remove_button"],
		input[name="field_skladby_multiple_en_und_1_remove_button"],
		input[name="field_skladby_multiple_en_und_2_remove_button"],
		input[name="field_skladby_multiple_en_und_3_remove_button"],
		input[name="field_skladby_multiple_en_und_4_remove_button"],
		input[name="field_skladby_multiple_en_und_5_remove_button"],
		input[name="field_chamber_with_piano_und_0_remove_button"],
		input[name="field_chamber_with_piano_und_2_remove_button"],
		input[name="field_chamber_with_piano_und_3_remove_button"],
		input[name="field_chamber_with_piano_und_4_remove_button"],
		input[name="field_chamber_with_piano_und_5_remove_button"] {
			position: absolute;
			left: 0;
			top: -20px;
			background-color: $color_background-6;
			transition: all 250ms;
			margin: 0;
			background-image: $file_icon_close_colored-white;
			background-size: .8em;
			padding: .2em 2.5em .2em 1em;
			background-repeat: no-repeat;
			background-position: 90% center;

			&:hover {
				opacity: .8;
			}
		}
	}

	.add-item-1,
	.add-item-2,
	.add-item-3 {
		font-size: 85%;
		margin-top: 0;
		padding: .2em 1em;
		border: 0;
		text-transform: uppercase;
		color: $color_text_accent_inverted;
		background-color: $color_decoration_success;
		cursor: pointer;
		line-height: 1;

		&:hover {
			opacity: .8;
			text-decoration: none;
		}
	}

	.add-item {
		margin-top: -1.6em;
		border-bottom: 4px double $color_background-5;
	}

	// padding inputu dle navrhu
	// cz
	input#edit-field-skladby-und-add-more,
	input#edit-field-solo-skladba-und-add-more,
	input#edit-field-skladby-multiple-und-add-more,
	input[id*='edit-field-skladby-und-add-more--'],
	input[id*='edit-field-solo-skladba-und-add-more--'],
	input[id*='edit-field-skladby-multiple-und-add-more--'],
	input[id*='edit-field-solo-skladba-many-und-add-more'],
	// en
	input#edit-field-skladby-en-und-add-more,
	input#edit-field-solo-skladba-en-und-add-more,
	input#edit-field-skladby-multiple-en-und-add-more,
	input[id*='edit-field-chamber-with-piano'],
	input[id*='edit-field-skladby-en-und-add-more--'],
	input[id*='edit-field-solo-skladba-en-und-add-more--'],
	input[id*='edit-field-skladby-multiple-en-und-add-more--'],
	input[id*='edit-field-chamber-with-piano-und-add-more'] {
		padding: 5px 10px;
	}

	// dve horizontalni cary u tlacitek Pridat polozku
	// cz
	#edit-field-skladby,
	#edit-field-solo-skladba,
	#edit-field-solo-skladba-many,
	#edit-field-skladby-multiple,
	// en
	#edit-field-skladby-en,
	#edit-field-solo-skladba-en,
	#edit-field-chamber-with-piano,
	#edit-field-skladby-multiple-en {
		div[id*='-add-more-wrapper'] > .form-item {
			margin-bottom: 24px;
			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 32px;
			}
			.clearfix {
				border-bottom: 1px solid $color_background-5;
				position: relative;
				&:after {
					content: '';
					display: inline-block;
					width: 100%;
					height: 1px;
					background-color: $color_background-5;
					position: absolute;
					bottom: -3px;
					left: 0;
				}
			}
		}
	}
}


// formy na webu Informace
.domain-informace-rozhlas-cz {
	.cro-informace-partnerstvi-celoro-entityform-edit-form,
	.cro-informace-partnerstvi-celoro-entityform-edit-form--2,
 	.cro-informace-partnerstvi-stan-entityform-edit-form,
	.cro-informace-partnerstvi-stan-entityform-edit-form--2,
 	.cro-informace-exkurze-entityform-edit-form,
	.cro-informace-exkurze-entityform-edit-form--2 {
	 	// obal kontejneru
	 	& > div {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
	 	}

		// nadpisy sekci
		[id*='edit-part'] {
	 		flex-basis: 100%;
	 		h3 {
		 		margin-top: 0;
		 		margin-bottom: 0;
	 		}
	 	}

		// vyber stanice
		[id*='edit-field-vyber-stanice'].form-checkboxes {
			@include breakpoint($breakpoint_tablet) {
				display: flex;
				flex-wrap: wrap;
			}
			.form-item {
				display: flex;
				@include breakpoint($breakpoint_tablet) {
					flex-basis: 50%;
				}
			}
		}

	 	.field-name-field-birthdate {
			flex-basis: 100%;
	 	}

		// itemy, ktere se maji na desktopu zalamovat do 2 sloupcu
		.field-name-field-newsletter-title,
		.field-name-field-form-firstname,
		.field-name-field-form-mobile,
		.field-name-field-form-email,
		.field-name-field-secondname,
		.field-name-field-birthdate,
		.field-name-field_death_date,
		.field-name-field-form-attendees,
		.field-name-field-form-age,
		.field-name-field-titulek {
			@include breakpoint($breakpoint_tablet) {
				flex-basis: 49%;
				margin-right: 1%;
			}
		}

		// itemy, ktere maji byt do plne sirky
		.field-name-field-titulek,
		.field-name-field-domicil,
		.field-name-field-firstname,
		.field-name-field-form-firstname,
		.field-name-field-form-location,
		.field-name-field-lokace,
		.field-name-field-form-date,
		.field-name-field-coverage-internal-notice,
		.field-name-field-broadcast-type,
		.field-name-field-form-message,
		.field-name-field-form-message2,
		.field-name-field-message3,
		.field-name-field-form-subject,
		.field-name-field-annotation,
		.field-name-field-form-name,
		.field-name-field-director,
		.field-name-field-summary,
		.field-name-field-autor,
		.field-name-field-hra {
			@include breakpoint($breakpoint_tablet) {
				flex-basis: 100%;
				margin-right: 1%;
			}
		}

		// uprava datumoveho pole
		.field-type-date,
		.field-type-datetime {
			.date-combo.form-wrapper {
				.fieldset-legend {
					font-size: .75rem;
				}
				.date-no-float.container-inline-date {
					width: 110%;
				}
				.form-item {
					display: flex;
					flex-wrap: wrap;

					// label kalendar
					.form-item-field-death-date-und-0-value-date > label,
					.form-item-field-birthdate-und-0-value-date > label,
					.form-item-field-form-datetime-und-0-value-date > label {
						position: unset !important; // musi byt important, protoze prebiji jiny important :/
						display: block;

						font-size: 0; // HACK: nechci text

						width: 33px; // nemuze byt v em, protoze font je 0
						height: 33px; // nemuze byt v em, protoze font je 0
						flex-basis: 33px;

						margin-right: 10px; // nemuze byt v em, protoze font je 0

						background-color: $color_decoration_main;

						background-image:$file_icon_calendar_colored-white;
						background-size: 55%;
						background-repeat: no-repeat;
						background-position: 50%;

						text-indent: -9999px; // skryti labelu "DATUM"
					}
					input {
						max-width: 130px;
						@include breakpoint($breakpoint_tablet) {
							max-width: 100%;
						}
					}

					.form-item-field-death-date-und-0-value-date,
					.form-item-field-birthdate-und-0-value-date,
					.form-item-field-form-datetime-und-0-value-date {
						> .description {
							display: none;
						}

					}
					.form-item-field-form-datetime-und-0-value-time > label {
						display: none;
					}
					.form-item-field-form-datetime-und-0-value-time > .description {
						&:first-of-type {
							margin-left: .5em;
						}
					}
					.description {
						margin-bottom: .5em;
					}
				}
			}
		}

		// file upload
		.file-widget {
			input[type='submit'] {
				margin-top: 10px;
				@include breakpoint($breakpoint_tablet) {
					margin-top: 0;
				}
			}
		}
		.file-widget + .description {
			max-width: 60%;
			@include breakpoint($breakpoint_tablet) {
				max-width: 100%;
			}
		}

		.field-name-field-form-attachment {
			.form-item {
				label {
					margin-bottom: .5em;
				}
				.file-widget {
					display: flex;
					flex-wrap: wrap;
					input[type='file'] {
						margin-bottom: 10px;
					}
					img {
						max-width: 24px;
					}
					.file,
					.file-size {
						display: inline-flex;
						align-items: center;
						font-size: .85em;
						line-height: 1.5;
						margin-right: 4px;
					}
				}
			}
		}
 	}
	// formular Potvrzení o poskytnutem daru pro danove ucely
	.cro-nadacni-fond-potvrzeni-entityform-edit-form {
		#edit-field-form-person-type {
			& > .form-item > label[for^=edit-field-form-person-type-und] {
				display: none;
			}
		}
		#edit-field-form-firstname,
		#edit-field-form-name,
		#edit-field-titulek,
		#edit-field-integeritemvalue,
		#edit-field-slo-tracku {
			.form-item label {
				&:after {
					content: '*';
					color: $color_decoration_main;
				}
			}
		}
	}
}


// formy na webu Rada
.rada {
	[class^='cro-rada-stiznost-entityform-edit-form'] {
		.pre-instructions {
			padding-bottom: 30px;
		}
		[id^='field-form-firstname-add-more-wrapper'],
		[id^='field-form-location-add-more-wrapper'],
		[id^='field-firstname-add-more-wrapper'],
		[id^='field-director-add-more-wrapper'],
		[id^='field-domicil-add-more-wrapper'] {
			.form-item label {
				&:after {
					content: '*';
					color: $color_decoration_main;
				}
			}
		}
		[id^='edit-field-form-firstname'] {
			[id^='field-form-firstname-add-more-wrapper'] {
				margin-top: 32px;
			}
		}
		.form-item-field-form-person-type-und,
		.form-item-field-osoba-select-und {
			&.form-type-radios.form-item > label {
				display: none;
			}
		}
		.form-item-field-form-confirmed-und {
			input {
				top: -2px;
			}
			label {
				color: $color_text_paragraph;
				text-transform: inherit;
			}
		}
	}
}


// Plzen - SOUTEZNI FORMULAR
.cro-web-form-contest {
	> div {
		display: flex;
		flex-wrap: wrap;
	}
	// perex
	.service-description {
		margin-bottom: 32px;
		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 24px;
		}
	}
	textarea,
	.service-description,
	.form-item-allow-ecomailn {
		flex-basis: 100%;
	}
	.form-item-allow-ecomail {
		display: flex;
		flex-basis: 100%;
	}
	label,
	input[type="file"],
	.disclaimer,
	.service-description {
		color: $color_text_paragraph;
		font-size: 14px;
		line-height: 22px;
		margin-top: 0;
		text-transform: inherit;
	}
	a {
		color: $color_decoration_main;
	}
	.form-item-files-file {
		width: 100%;
	}
	input[type="file"] {
		color: $color_text_paragraph;
		// IE 11
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			max-width: 300px;
		}
	}
	[id^='edit-actions'] {
		.disclaimer {
			margin-top: .5em;
			@include breakpoint($breakpoint_tablet) {
				margin-top: 0;
			}
			@include breakpoint($breakpoint_desktop) {
				margin-top: .5em;
			}
		}
	}
}


// Pisnicky pro radost - kalendare pro vyber data
.pisnicky-pro-radost-wrap {
	@include breakpoint($breakpoint_tablet) {
		display: inline-flex;
		min-width: 277px;
	}
	.pisnicky-pro-radost {
		border: 1px solid $color_divider;
		border-collapse: separate; // IE 11
		margin: 24px auto;
		overflow: hidden;
		padding: 15px;
		box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
		&__month {
			font-size: 1.12rem;
		}
		&__week-day {
			text-transform: uppercase;
			font-size: .8rem;
		}
		&__month,
		&__week-day {
			color: $color_text_paragraph_node;
			background-color: transparent;
			padding: 4px;
			text-align: center;
			cursor: default;
			@include typography_font(medium);
			@include no-select;
		}
		&__day {
			text-align: center;
			width: 30px;
			height: 30px;
			max-width: 30px;
			border-radius: 50%;
			line-height: 2.5;
			@include no-select;
			&:hover {
				background-color: $color_decoration_main;
				a {
					color: $color_background-0;
					text-decoration: none;
				}
			}
			a {
				font-size: .75rem;
				color: $color_background-6;
				width: 100%;
				height: 100%;
				display: block;
			}
		}
		// skryvani nevyuzitych dni
		&__empty-day {
			font-size: 0;
			text-indent: -999px;
		}
		// obsazeny den
		&--full-day {
			opacity: .35;
			font-size: .75rem;
			color: $color_background-6;
			min-width: 30px;
			&:hover {
				background-color: transparent;
				cursor: default;
				color: $color_background-6;
			}
		}
	}
}


// Pisnicky pro radost - objednavkovy formular
#msw-pisnicky-na-prani-form-edit {
	margin-top: 24px;
	.pnp-header,
	.pnp-subheader {
		font-size: 14px;
		line-height: 22px;
	}
	label,
	legend,
	.pnp-header,
	.pnp-subheader {
		color: $color_text_paragraph;
	}
	legend {
		padding: 16px 0 8px 0;
	}
	.pnp-header {
		margin: 0;
	}
	.pnp-subheader {
		margin-top: 0;
	}
}


// form pro zadost o obchodni spolupraci
#cro-web-form-biz {
	// custom delky inputu
	@include breakpoint($breakpoint_desktop) {
		.form-item-phone {
			max-width: 48%;
			float: left;
		}
		.form-item-email {
			max-width: 48%;
			float: right;
		}
	}

	// checkbox
	.form-type-checkbox {
		@extend %form-checkbox;
	}
}


// form Zelena vlna
#cro-zelenavlna-registrace-entityform-edit-form {
	.pre-instructions {
		@extend %pre-instructions;

		p {
			margin-top: 0;
		}
	}
}


// Two-Factor Authentication form
.page-system .tfa-form {
	margin-top: 2em;
	input#edit-code {
		@include breakpoint($breakpoint_desktop) {
			max-width: 50%;
		}
	}
	.form-item.form-type-checkbox.form-item-trust-browser {
		flex-wrap: wrap;
		margin-top: 0;
		.description {
			flex-basis: 100%;
		}
	}
	input#edit-trust-browser {
		margin-top: 0;
	}
	label[for="edit-trust-browser"] {
		width: calc(100% - 30px)
	}
	.form-actions {
		input[type="submit"]:not(:last-child) {
			margin-bottom: 20px;
			@include breakpoint($breakpoint_tablet) {
				margin-bottom: 0;
			}
		}
		// potlaceni defaultni barevnosti buttonu
		input[type="submit"]#edit-skip,
		input[type="submit"]#edit-resend,
		#edit-skip,
		#edit-resend {
			background-color: $color_background-5;
		}
	}
}


// formular pro reset hesla
.page-user-reset .user-pass-reset {
	margin-top: 2em;
	@include breakpoint($breakpoint_desktop) {
		margin-top: 3em;
	}
}


// form-actions message - zdurazneni textu
.form-actions	.form-important-note {
	color: $color_decoration_alert;
}


// formulare, ktere jsou na desktop breakpointu umistene v side
.l-grid--4-9 {
	[id^='edit-actions'] {
		@extend %form-submit-button-and-disclaimer-side;
	}
}




/*
 * form positioned
 */
.cro_web_form--positioned {

	form {
		> div,
		.fieldset-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			// reset
			.form-item,
			.form-actions,
			.pre-instructions {
				margin-bottom: 0;
				padding-bottom: 0;
			}

			// form-item position
			.form-item-position {
				//margin-bottom: 1em;

				&--center {
					flex-basis: 100%;
				}
				&--left,
				&--right {
					@extend %form-field-size-responsive-half;

					@include breakpoint($breakpoint_desktop) {
						max-width: 50%; // neni soucasti %form-field-size-responsive-half
					}
				}

				&--left {
					@extend %form-mr;
				}
				&--right {}

				p:first-of-type {
					margin-top: 0;
				}

				.form-item {
					margin-bottom: 1em;

					.form-item {
						margin-bottom: 0;
					}
				}
			}

			// form-item label hidden
			.form-item-label-hidden {
				label {
					display: none;
				}
			}

			// inputy
			.form-item {
				input[type="radio"]:not(:checked) + label,
				input[type="radio"]:not(:checked) + label a,
				input[type="checkbox"]:not(:checked) + label,
				input[type="checkbox"]:not(:checked) + label a {
					color: $color_background-5;
				}
			}

			// datepicker
			.date-padding {
				> .form-item {
					display: flex;
					flex-wrap: wrap;

					// label jako kalendar
					label {
						position: unset !important; // musi byt important, protoze prebiji jiny important :/
						display: block;

						font-size: 0; // HACK: nechci text

						width: 33px; // nemuze byt v em, protoze font je 0
						height: 33px; // nemuze byt v em, protoze font je 0

						margin-right: 10px; // nemuze byt v em, protoze font je 0

						background-color: $color_decoration_main;

						background-image:$file_icon_calendar_colored-white;
						background-size: 55%;
						background-repeat: no-repeat;
						background-position: 50%;
					}

					// description
					.description {
						display: none;
					}
				}
			}
		}
	}
}
