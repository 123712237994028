//
// b-065 Styles
//



.b-065 {
}

	.b-065__list {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-065__list-item {

			display: flex;
			flex-direction: column;
			justify-content: space-between;

			margin-bottom: 1.5em;
			padding: 1em;

			background-color: $color_background-2;

			background-image: url(../images/icon/ico_arr_double_colored-decoration-main.svg);
			background-repeat: no-repeat;
			background-position: bottom +1em right +1em;
			background-size: 15px auto;

			@include breakpoint($breakpoint_desktop) {

				// trik jak 2 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud je jen jeden
				flex-basis: 45%; // toto neni polovine, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do poloviny
				max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(50% - 0.75em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.75em? protoze je to 1.5 / 2 viz (*)

				margin-right: 1.5em; // (*)
			}

		}
		.b-065__list-item:last-child {

			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {

				margin-right: 0;
				margin-bottom: 1.5em;
			}
		}

		// every 2nd child
		.b-065__list-item:nth-child(2n+2) {

			@include breakpoint($breakpoint_desktop) {

				margin-right: 0;
			}
		}

		// last 2 childs
		/*.b-065__list-item:nth-last-child(-n+2) {

			@include breakpoint($breakpoint_desktop) {

				margin-bottom: 0;
			}
		}*/

			.b-065__list-link {
				@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
			}

				.b-065__image {}

				.b-065__block {
				}

				.b-065__block--description {
					padding-right: 2em;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-065__block--description h3 {
						margin: 0;
						margin-top: 0.5em;
						padding-top: 0.5em;
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-065__block--description p {
						margin-top: 0.5em;
						margin-bottom: 0.5em;
						color: $color_text_paragraph;
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-065__block--description a p {
						@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
					}
