//
// Poplatek - obecne vlastnosti vsech stranek
//



// vars
$color_poplatek_b-016-list-item: rgba(#8a8a96, 1);



.poplatek {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_poplatek_main, $color_poplatek_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_poplatek_main);
	}

	// stanicni nav
	.b-016__wrapper {
		margin: 0;

		h1,
		h2 {
			background-color: $color_background-0;
			//color: $color_background-9;
		}

		h1:before,
		h2:before {
			background-color: $color_background-0;
		}

		.b-016 {
			background-color: $color_background-0;

			/* barevnost listy a odkazu */
			.b-016__list {
				background-color: $color_background-0;

				.b-016__list-item {

					a {
            //@include link-style ($color_background-6, none, $color_background-9, none);
						@include link-style ($color_poplatek_b-016-list-item, none, $color_background-9, none);
					}

					a.expanded {
						background-color: $color_background-2;
					}

					a:hover,
					a.expanded:hover {
						background-color: $color_background-1;
					}
				}

				.b-016__list-more {
					background-color: $color_background-2;

					a:hover {
						background-color: $color_background-1;
					}
				}
			}

		}
	}


	// box
	.box {

		// titulek
		h2 {
			color: $color_background-6;

			a {
				@include link-style ($color_background-6, none, $color_background-6, underline);
			}
		}
	}

}
