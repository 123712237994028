//
// B-050e Styles
//


// image block flex basis
$b-050e_image_block-size-basis: 6em;
$b-050e_image_block-size-basis_desktop: 3.5em;

// logo size
$b-050e_image_block-logo-size: 40%;



.b-050e {
}

	// list
	.b-050e__list {
		margin-bottom: 2em;
	}

		// item
		.b-050e__list-item {
			display: flex;
			flex-direction: column;

			padding: 20px 0;

			border-top: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;

				padding: 10px 0;
			}
		}

		// item: first
		.b-050e__list-item:first-child,
		.b-050e__list-item:first-of-type {
			padding-top: 0;
			border-top: none;
		}

			// block
			.b-050e__block {
			}

			// block: image
			.b-050e__block--image {
				flex-shrink: 0;

				max-width: $b-050e_image_block-size-basis;
				max-height: $b-050e_image_block-size-basis;

				width: $b-050e_image_block-size-basis; // fix pro chybejici nebo mensi obrazky
				height: $b-050e_image_block-size-basis; // fix pro chybejici nebo mensi obrazky

				@include breakpoint($breakpoint_desktop) {
					max-width: $b-050e_image_block-size-basis_desktop;
					max-height: $b-050e_image_block-size-basis_desktop;

					width: $b-050e_image_block-size-basis_desktop; // fix pro chybejici nebo mensi obrazky
					height: $b-050e_image_block-size-basis_desktop; // fix pro chybejici nebo mensi obrazky
				}
			}


				.b-050e__image {
					width: 100%;
					height: 100%;

					border-radius: 5px;
				}

					.b-050e__image-logo {
						width: 100%;
						height: 100%;

						background-image: $file_logo_R_white;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: auto $b-050e_image_block-logo-size;
					}

					.b-050e__image-logo--junior {
						background-color: $color_corporate_main !important; // vyjimka
					}

						.b-050e__image-logo span {
							display: none;
						}


			// block: description
			.b-050e__block--description {
				display: flex;
				flex-direction: column;
				flex-grow: 1;

				padding-top: 1em;

				@include breakpoint($breakpoint_desktop) {
					padding-top: 0.25em;
					padding-left: 1em;
					padding-right: 5em;

					overflow: hidden;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-050e__block--description h3 {
					font-size: 20px;
					line-height: 1.3;

					margin-top: 0;
					margin-bottom: 0.25em;

					@include breakpoint($breakpoint_desktop) {
						font-size: 18px;
						line-height: 1.3;

						margin-bottom: 0.25em;

						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				.b-050e__list-item--opened .b-050e__block--description h3 {
					@include breakpoint($breakpoint_desktop) {
						white-space: normal; // default
						text-overflow: none; // default
						overflow: visible; // default
					}
				}

				.b-050e__list-item--topped .b-050e__block--description h3 {
					background-image: $file_icon_star_colored-ffd400;
					background-repeat: no-repeat;
					background-position: 0 1px;
					background-size: auto 20px;

					text-indent: 28px;

					@include breakpoint($breakpoint_desktop) {
						background-position: 0 2px;
						background-size: auto 18px;

						text-indent: 24px;
					}
				}

					.b-050e__subblock--description {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
					}

						.b-050e__description--text {
							font-size: 16px;
							line-height: 1.4;

							margin-top: 0;
							margin-bottom: 0;

							color: $color_text_paragraph;

							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;

							@include breakpoint($breakpoint_desktop) {
								font-size: 15px;
								line-height: 1.4;

								margin-top: 0;
							}
						}

						.b-050e__list-item--opened .b-050e__description--text {
							white-space: normal; // default
							text-overflow: none; // default
							overflow: visible; // default
						}

						.b-050e__description--more {}

							.b-050e__description--more .button-simple--readmore {}

							// TODO z duvodu atomu zatim ne pres specifickou tridu
							.b-050e__description--more .button-simple--readmore {
								display: inline-flex;

								margin-top: 0;
								margin-left: 0.5em;
							}

							// TODO z duvodu atomu zatim ne pres specifickou tridu
							.b-050e__list-item--opened .b-050e__description--more .button-simple--readmore {
								display: none;
							}

								.b-050e__description--more .button-simple--readmore .button-simple__icon--arrow-double-east {
									display: none;
								}


				.b-050e__subblock--metainfo-text {
					display: none;

					margin-top: 0.5em;
					margin-right: 0;
					padding-top: 0;
					padding-bottom: 0;
				}

				.b-050e__list-item--opened .b-050e__subblock--metainfo-text {
					//display: block;
				}

				.b-050e__subblock--others {
					display: none;
					flex-direction: column;
				}

				.b-050e__list-item--opened .b-050e__subblock--others {
					//display: block;
				}

					.b-050e__others-row {}

						.b-050e__others-row--tag {

						}

						.b-050e__others-row--button {

						}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-050e__others-row--tag .tag {
							text-transform: uppercase;

							margin-top: 1em;
							margin-right: auto;
						}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-050e__others-row--tag .tag.bg-color-main-junior {
							background-color: $color_corporate_main !important; // vyjimka
						}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-050e__others-row--button .button-simple--readmore {
							margin-top: 0.5em;
							margin-right: auto;
						}


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-050e__block--description .b-050e__description--more .button-simple--readmore {
					display: inline-flex;

					margin-top: 0;
					margin-left: 0.5em;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-050e__list-item--opened .b-050e__block--description .b-050e__description--more .button-simple--readmore {
					display: none;
				}


			// block: actions
			.b-050e__block--actions {
				margin-top: 1.5em;

				@include breakpoint($breakpoint_desktop) {
					margin-top: 12px;
				}
			}

				// box-019c: subscribe button + bubble
				.b-050e__block--actions .b-019c {
					margin: 0;
				}

					.b-050e__block--actions .b-019c .b-019__button--subscribe {
						@include breakpoint($breakpoint_desktop) {
							width: 96px; // fix: safe zone
						}
					}

						.b-050e__block--actions .b-019c .b-019__button--subscribe .button-listaction {
							width: 2.5em; // default je 3em, TODO: zmenit globalne
							height: 2.5em; // default je 3em, TODO: zmenit globalne

							@include breakpoint($breakpoint_desktop) {
								width: 2em;
								height: 2em;
							}
						}

					.b-050e__block--actions .b-019c .bubble-subscribe {
					}





// vizualni debug - plab only
.patternlab .b-050e {

	@include breakpoint($breakpoint_desktop) {
		max-width: 755px;
	}

	.b-050e__block--actions .b-019c .bubble-subscribe {
		display: none; // debug only
	}

	.b-050e__list-item--opened {
		.b-050e__subblock--metainfo-text,
		.b-050e__subblock--others {
			display: block;
		}
	}
}
