//
// B-041h Styles
//



.b-041h {
}
	
	.b-041h__list {
	}
		
		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-041h__list-item {
			
			display: flex;
			flex-direction: row;
		}
			
			.b-041h__block--image {
				
				flex-shrink: 0;
				
				max-width: 5em;
				padding-bottom: 1em;
				
				@include breakpoint($breakpoint_desktop) {
					max-width: 7em;
				}
					
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					position: relative;
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.button-play {
						position: absolute;
						bottom: 0.35em;
						left: 0.35em;
						
						width: 2.25em;
						height: 2.25em;
						
						@include breakpoint($breakpoint_desktop) {
							width: 1.75em;
							height: 1.75em;
						}
					}
				}
				
				.image--mobile {
					
					display: block;
				
					@include breakpoint($breakpoint_desktop) {
							display: none;
					}
				}
				
				.image--desktop {
					
					display: none;
				
					@include breakpoint($breakpoint_desktop) {
						display: block;
					}
				}
			}
			
			
			.b-041h__block--description {
				
				padding-left: 0.75em;
				
				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041h__block--description h3 {
					margin: 0;
					margin-bottom: 0.5em;
				}
					// kdyz se odstrani pole autor, a je h3 posledni dite, chceme vetsi margin
					.b-041h__block--description h3:last-child {
						margin-bottom: 1em;
					}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041h__block--description a p {
					
					margin-top: 0.5em;
					
					color: $color_text_paragraph;
					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041h__author-link {
					
					display: inline-block;
					
					margin-bottom: 1em;
					
					color: $color_text_accent;
					
					@include adjust-font-size-to($fontsize_author_main);
					
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($fontsize_author_main_desktop);
					}
				}
					a.b-041h__author-link {
						
						@include link-style($color_decoration_main, none, $color_decoration_main, underline);
					}
