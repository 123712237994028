//
// B-008b Styles
//
// krome dlasich variant b008 blizka podobnost s boxem b004e
// alespon cose rozlozeni elementu tyka, protoze tenhle styl je od nej odvozen ale prosel zasadnim refactoringem
//



.b-008b {
}
	
	.b-008b__subsection {
	}
		
		.b-008b__list {
		}
			
			// obecny item, vlastnosti shodne pro primarni (1) i nasledujici (2 + 3)
			.b-008b__list-item {
			}
				
				// zde je jeste mezistupen obalu block, resp subblock
				// nez se zacnou zanorovat nasledujici elementy
				
				.b-008b__image {
					position: relative;
				}
					
					// tagy jsou zde u itemu s obrazkem dvoji, jeden navic v obrazku a jeden standardne
					// skryvaji se podle breakpointu
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008b__image .tag {
						
						position: absolute;
						bottom: 0;
						left: 10px;
					}
				
				
				.b-008b__list-item .caption {
					
					margin-top: 0.75em;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008b__list-item h3 {
					
					margin: 0;
					padding-top: 0.5em;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008b__list-item a p {
					
					margin-top: 0.5em;
					margin-bottom: 0;
					
					color: $color_text_paragraph;
					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
				}
		
		
		
		// primarni prispevek
		.b-008b__list--primary {
			
			margin-bottom: 2em;
		}
			
			// abstrakt horizontalni neboli prvni siroky element
			.b-008b__list-item--primary {
				
				display: flex;
				flex-direction: column;
				
				background-color: $color_background-2;
				
				
				@include breakpoint($breakpoint_desktop) {
					
					flex-direction: row;
					background-color: transparent;
				}
			}
				
				.b-008b__block--image-primary {
					
					@include breakpoint($breakpoint_desktop) {
						flex: 3;
					}
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008b__block--image-primary .tag {
						
						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}
				
				
				.b-008b__block--description-primary {
					padding-left: 0.5em;
					padding-right: 0.5em;
					
					@include breakpoint($breakpoint_desktop) {
						
						flex: 2;
						
						padding-left: 1em;
						padding-right: 1em;
					}
				}
					
					.b-008b__subblock--primary {
						
						// tohle je obalovak jenom proto
						// aby to v mobile variante mohlo flexovat uplne jinak nez na desktopu
						// tam to jenom sype pod sebou a nic se neresi protoze button na prehravani
						// kvuli kterymu tu ten konstrukt je, tam neni zobrazen a zobrazuje se jiny dole
						
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						
						@include breakpoint($breakpoint_desktop) {
							display: block;
						}
					}
						
						.b-008b__description-heading-wrapper {
						}
							
							// TODO z duvodu atomu zatim ne pres specifickou tridu
							.b-008b__description-heading-wrapper .tag {
								display: none;
								
								@include breakpoint($breakpoint_desktop) {
									display: inline-block;
								}
							}
						
						// tlacitko prehrat pro mobile
						.b-008b__button-play-wrapper--primary-intext {
							
							margin-top: 0.5em;
							margin-left: 1em;
							padding-left: 0.5em;
							
							border-left: 1px solid $color_divider;
							
							@include breakpoint($breakpoint_desktop) {
								display: none;
							}
						}
					
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008b__block--description-primary a p {
						
						margin-bottom: 1em;
					}
					
					// tlacitko prehrat pro desktop
					.b-008b__button-play-wrapper--primary-bellowtext {
						display: none;
						
						@include breakpoint($breakpoint_desktop) {
							display: block;
						}
					}
		
		
		
		
		// nasledujici dva prispevky
		.b-008b__list--following {
			
			display: flex;
			flex-direction: column;
			
			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
			}
		}
			
			// abstrakty vertikalni
			.b-008b__list-item--following {
				
				margin-bottom: 2em;
				
				@include breakpoint($breakpoint_desktop) {
					
					flex-basis: 50%;
				}
			}
			.b-008b__list-item--following:first-child {
				
				@include breakpoint($breakpoint_desktop) {
					
					margin-right: 2em;
				}
			}
				
				.b-008b__block--description-following {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
				}
					
					.b-008b__subblock--following {
					}
					
					// TODO bud specifikovat pro following nebo zobecnit
					.b-008b__button-play-wrapper--following {
						margin-left: 2em;
					}