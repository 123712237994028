//
// Pseudo Exposed Filter - Colored
// filtry pro SOCR - prizpusobeni Drupal markupu
//


// colors
$color-filters-selected: #9c9fab;
$color-rudolfinum: #0090b4;
$color-premium: #b32844;
$color-horizonts: #f6ce3c;
$color-studio: #48b494;
$color-classical: #333378;
$color-obecnidum: #be619d;
$color-dox: #ffcd00;
$color-text: $color_background-0;

// settings
$transition-speed: .125s;
$label-padding: 5px 10px;
$filter-margin-mobile: 5px;
$filter-margin-desktop: 10px;


.socr,
.prso {
	.view-filters .views-exposed-widgets .views-widget .bef-select-as-radios {
		display: flex;
		flex-wrap: wrap;
		flex-basis: 100%;

		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
		margin-top: 1em;
		margin-bottom: 2.5em;
		}
	}

	// item
	.view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item {
		display: flex;
		//flex-basis: 100%;
		margin-right: $filter-margin-mobile;
		margin-bottom: $filter-margin-mobile;

		@include breakpoint($breakpoint_desktop) {
		//flex-basis: auto;
		margin-right: $filter-margin-desktop;
		margin-bottom: $filter-margin-desktop;
		}
	}

	// skryti inputu
	.view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input[type=radio] {
		display: none;
	}

	// label: defaultni stav - nezaskrtnuty checkbox
	.view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input[type=radio] + label {
		font-size: $fontsize_paragraph;
		text-transform: inherit;

		background-color: $color_corporate_main;
		border-color: $color_corporate_main;
		color: $color_background-0;

		margin-bottom: 1px;
		padding: $label-padding;

		width: 100%;

		transition: background-color $transition-speed;

		cursor: pointer;

		white-space: nowrap;

		@include no-select;
	}

	// lebel: zaskrtnuty checkbox
	.view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input:checked + label {
		background-color: $color-filters-selected !important;
		border-color: $color-filters-selected !important;
		text-decoration: underline;
	}

	// :hover
	.view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input[type=radio] + label:hover {
		color: $color_background-0;
		@include breakpoint($breakpoint_desktop) {
		text-decoration: underline;
		}
	}

	// barvy
	.view-filters .views-exposed-widgets .views-widget .bef-select-as-radios .form-item input[type=radio] {

		// vse
		& + label[for='edit-term-node-tid-depth-all'] {
			background-color: $color_corporate_main;
			color: $color-text;
			&:hover {
				color: $color-text;
			}
		}

		// Premium
		& + label[for='edit-term-node-tid-depth-7644453'], // SOCR
		& + label[for='edit-term-node-tid-depth-7655736'] {
			background-color: $color-premium;
			border-color: $color-premium;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}

		// Rudolfinum
		& + label[for='edit-term-node-tid-depth-7644452'], // SOCR
		& + label[for='edit-term-node-tid-depth-7655739'] {
			background-color: $color-rudolfinum;
			border-color: $color-rudolfinum;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}

		// Komorni
		& + label[for='edit-term-node-tid-depth-7644456'], // SOCR
		& + label[for='edit-term-node-tid-depth-7655742'] {
			background-color: $color-classical;
			border-color: $color-classical;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}

		// Nove horizonty
		& + label[for='edit-term-node-tid-depth-7644454'], // SOCR
		& + label[for='edit-term-node-tid-depth-7655745'] {
			background-color: $color-horizonts;
			border-color: $color-horizonts;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}

		// Studio
		& + label[for='edit-term-node-tid-depth-7724116'], // SOCR
		& + label[for='edit-term-node-tid-depth-7724119'] {
			background-color: $color-studio;
			border-color: $color-studio;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}

		// Betlemska kaple
		& + label[for='edit-term-node-tid-depth-7749933'], // SOCR
		& + label[for='edit-term-node-tid-depth-7750404'] {
			background-color: $color-classical;
			border-color: $color-classical;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}

		// Obecni dum
		& + label[for='edit-term-node-tid-depth-7771102'], // SOCR
		& + label[for='edit-term-node-tid-depth-7771981'] {
			background-color: $color-obecnidum;
			border-color: $color-obecnidum;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}

		// Dox
		& + label[for='edit-term-node-tid-depth-7772185'], // SOCR
		& + label[for='edit-term-node-tid-depth-7772182'] {
			background-color: $color-dox;
			border-color: $color-dox;
			color: $color-text;
			white-space: nowrap;
			&:hover {
				color: $color-text;
			}
		}
	}
}
