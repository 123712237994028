//
// station-toggle
//


.station-toggle {
	display: flex;
	justify-content: space-between;
	color: $color_background-6;
	margin: 10px 0;
	margin: 0 auto 18px auto;
	position: relative;
	background-color: $color_corporate_main; // default

	// vlastni rozbalovatko (klikatelna oblast)
	&__toggler {
		color: $color_background-0;
		display: flex;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding: 2px 30px 2px 10px;
		text-transform: inherit;
		&:after {
			content: '';
			background: $file_icon_arrow_single_east_colored-white 2px 0 no-repeat;
			display: block;
			width: 18px;
			height: 12px;
			position: absolute;
			top: 10px;
			right: 6px;
			transform: rotate(90deg);

			// IE fix
			@media all and (-ms-high-contrast: none) {
				top: 5px;
			}
		}
		@include breakpoint($breakpoint_desktop) {
			&:hover {
				cursor: pointer;
				.station-toggle__choose-station {
					text-decoration: underline;
				}
			}
		}
	}
	// korekce pozice ikony pro zavreni dropdownu
	.hidden-checkbox:checked + .station-toggle__toggler:after {
		top: 7px;
	}

	// textace rozbalovatka
	&__choose-station {
		@include no-select;
	}

	// nazev aktualne vybrane stanice
	&__actual-station {
		color: $color_background-0;
		text-transform: uppercase;
		@include typography_font(bold);
		font-size: 1rem;
		line-height: 1.3;
		@include no-select;
		@include breakpoint($breakpoint_desktop) {
			font-size: .9rem;
			line-height: 1.55;
		}
	}
	&__wrap {
		display: flex;
		position: relative;
	}
	&__print {
		position: absolute;
		right: 10px;
		top: 15px;
		display: none;
		color: $color_background-5;
		font-size: .75rem;
		transition: color 100ms ease-in-out;
		@include no-select;
		@include breakpoint($breakpoint_tablet) {
			display: flex;
		}
		&:after {
			content: '';
			background: $file_button_action_print no-repeat;
			width: 24px;
			height: 24px;
			background-size: 24px 24px;
			display: inline-block;
			margin-left: 7px;
		}
		&:hover {
			color: darken($color_background-5, 25%);
		}
	}
	&__arrow-left {
		background: $file_icon_arrow_single_east no-repeat center transparent;
		background-size: 10px 10px;
		position: absolute;
		top: 26px;
		left: 0;
		width: 30px;
		height: 30px;
		cursor: pointer;
		transform: rotate(180deg);
		@include breakpoint($breakpoint_tablet) {
			display: none;
		}
		&--large-screen {
			display: none;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
				left: -30px;
				transition: opacity 250ms ease-in-out;
				opacity: .6;
				z-index: 1;
			}
			&:hover {
				opacity: 1;
			}
		}
	}
	&__arrow-right {
		background: $file_icon_arrow_single_east no-repeat center transparent;
		background-size: 10px 10px;
		position: absolute;
		top: 26px;
		right: 0;
		width: 30px;
		height: 30px;
		cursor: pointer;
		@include breakpoint($breakpoint_tablet) {
			display: none;
		}
		&--large-screen {
			display: none;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
				right: -30px;
				transition: opacity 250ms ease-in-out;
				opacity: .6;
				z-index: 1;
			}
			&:hover {
				opacity: 1;
			}
		}
	}
	&__item {
		text-align: center;
		flex-direction: column;
		text-decoration: none;
		&:nth-child(2),
		&:nth-child(6) {
			display: none;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
			}
		}
		&:hover {
			text-decoration: none;
			.datepicker__day-name {
				text-decoration: underline;
			}
		}
	}
	&__day-name {
		color: $color_background-5;
		font-size: .75rem;
	}
	&__day-date {
		color: $color_decoration_main;
		@include typography_font(medium);
		font-size: 1.2rem;
		word-spacing: -2px;
		padding: 5px 12px;
		&:hover {
			color: darken($color_decoration_main, 12%);
		}
		@include breakpoint($breakpoint_tablet) {
			font-size: 1.3rem;
			padding: 5px 20px;
		}
		&--actual {
			border: 1px solid $color_divider;
			color: $color_background-9 !important;
			padding: 5px 8px 5px 10px;
			display: flex;
			align-items: center;
			&:after {
				content: '';
				background: $file_icon_calendar_colored-black no-repeat 10px;
				background-size: 20px 20px;
				width: 30px;
				height: 20px;
				display: inline-block;
			}
		}
	}
}
