//
// B-031-v2 Styles
//

// vars
//$breakpoint_desktop: 0; //debug


// vars
$b-110_color_radioteka_main: rgba(#0058a9, 1);
$b-110_color_radioteka_alert: rgba(#ca003a, 1);
$b-110_file_extras_gradient: url('../images/extras/radioteka/radioteka_gradient.png');


.b-111 {
}

	.b-111 > h2 {
		@include typography_font(medium);
		font-size: 18px;
		text-transform: none;

		color: $b-110_color_radioteka_main;
	}

	.b-111__container {
		display: block;

		position: relative; // aspect ratio
		padding-top: 45%; // aspect ratio - 2.5:1
	}

		.b-111__subsection {
			display: flex;
			flex-direction: row;

			position: absolute; // aspect ratio
			top: 0; // aspect ratio
			right: 0; // aspect ratio
			bottom: 0; // aspect ratio
			left: 0; // aspect ratio

			overflow: hidden;

			@include breakpoint($breakpoint_desktop) {
			}
		}

			.b-111__block--primary {
				background-color: $b-110_color_radioteka_main;

				width: 45%;

				position: relative;
				z-index: 2;

				@include breakpoint($breakpoint_desktop) {
				}
			}

			.b-111__block--primary::before {
				content: "";

				position: absolute;
				right: 0;
				bottom: 0;

				width: 100%;
				height: 125%;

				transform: rotate(6deg);
				transform-origin: 100% 100%;

				background-color: $b-110_color_radioteka_main;
			}

				.b-111__block--primary .b-111__overlay {
					position: absolute;
					top: 1.5rem;
					right: 0.25rem;
					bottom: 1.5rem;
					left: 1rem;

					overflow: hidden;

					//border: 1px solid red; // debug only
				}

					.b-111__block--primary .b-111__overlay::before {
						content: "";

						position: absolute;
						top: 50%;
						right: 0;
						bottom: 0;
						left: 0;

						background-image: linear-gradient(180deg, rgba($b-110_color_radioteka_main, 0), $b-110_color_radioteka_main);
					}

					.b-111__block--primary .b-111__overlay .b-111__logo--radioteka {
						display: block;
						width: 80%;

						@include breakpoint($breakpoint_desktop) {
							width: 60%;
						}
					}

					.b-111__block--primary .b-111__overlay h3 {
						margin: 0;
						margin-top: 1rem;

						font-size: 15px;
						line-height: 1.4;

						color: $color_text_accent_inverted;

						@include breakpoint($breakpoint_desktop) {
							font-size: 20px;
							line-height: 1.3;
						}
					}

					.b-111__block--primary .b-111__overlay p {
						margin: 0;
						margin-top: 0.5rem;

						font-size: 12px;
						color: $color_text_accent_inverted;

						@include breakpoint($breakpoint_desktop) {
							font-size: 14px;
						}
					}


			.b-111__block--secondary {
				width: 55%;

				position: relative;
				z-index: 1;

				@include breakpoint($breakpoint_desktop) {
				}
			}

				.b-111__block--secondary .b-111__image {
					display: flex;
					align-items: center;
					justify-content: center;

					width: 100%;
					height: 100%;
				}

				.b-111__block--secondary .b-111__overlay {
					background-image: $b-110_file_extras_gradient;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

				}

					.b-111__block--secondary .b-111__overlay .button {
						position: absolute;
						right: 0.75em;
						bottom: 0.75em;

						padding: 0.1em 0.5em;

						background-color: $b-110_color_radioteka_main;
						color: $color_text_accent_inverted;

						border: none;

						@include breakpoint($breakpoint_desktop) {
							right: 1.25em;
							bottom: 1.25em;

							padding: 0.5em 0.75em;
						}

						&:hover {
							opacity: initial; // reset
						}

						.button-box-flat__label {
							font-size: 14px;

							@include breakpoint($breakpoint_desktop) {
								font-size: 18px;
							}
						}

						.button-box-flat__icon.button-box-flat__icon--arrow-double-east {
							background-image: $file_icon_arrow_double_east_colored-white;
							width: 0.75em;

							@include breakpoint($breakpoint_desktop) {
								width: 1em;
							}
						}
					}


	// hover
	.b-111__container {
		.b-111__block--secondary .b-111__image img {
			@include breakpoint($breakpoint_desktop) {
				transition: transform 0.5s;
			}
		}
	}
	.b-111__container:hover {
		.b-111__image img {
			@include breakpoint($breakpoint_desktop) {
				transform: scale(1.1);
			}
		}
		.button {
			background-color: $b-110_color_radioteka_alert;
			.button-box-flat__label {
				text-decoration: underline;
			}
		}
	}





			// vizualni debug - plab only
			.patternlab .b-111 {

				@include breakpoint($breakpoint_desktop) {
					max-width: 755px;
				}
			}
