$rozhovory_text_color_main: $color_background-6;
$rozhovory_text_color_main_highlight: $color_background-8;
$rozhovory_text_font_size_main: .9rem;
$rozhovory_lh: 1.3;
$rozhovory_form_bcg: $color_background-1;

.discussion-wrapper-d {
	.loading {
		position: absolute;
		background: transparent url('https://aplikace.rozhlas.cz/rozhovory/sites/default/themes/rozhovory/img/ajax-loader.gif') no-repeat 0 0;
		width: 220px;
		height: 19px;
	}
	#cro-discussion div,
	#cro-discussion textarea,
	#cro-discussion input {
		max-width: 100%;
	}
	#discussion-header, #discussion-content {
		margin-bottom: 0;
		width: 100%;
		font-size: $rozhovory_text_font_size_main;
		line-height: $rozhovory_lh;
		color: $rozhovory_text_color_main;
	}
	#discussion-content {
		background-color: $rozhovory_form_bcg;
		outline: 0;
	}

  #discussion-content.load-more {
    overflow-y: initial !important;
    max-height: initial !important;
  }

	#discussion-content .question-data {
		width: calc(100% - 20px);
		margin-left: 10px;
		margin-right: 10px;
		outline: 0;
		border-top: 1px solid $color_background-3;
		border-bottom: 0;
		@include breakpoint($breakpoint_desktop) {
			width: calc(100% - 40px);
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	#discussion-content .question-data:last-child {
		padding-bottom: 6px;
		border-bottom: 0;
		@include breakpoint($breakpoint_desktop) {
			padding-bottom: 10px;
		}
	}

	#discussion-header {
		.info {
			float: left;
			font-size: 14px;
			font-weight: bold;
			padding-left: 25px;
			color: $color_background-9;
			background: url('https://aplikace.rozhlas.cz/rozhovory/sites/default/themes/rozhovory/img/ico_info_blue.png') no-repeat 0 4px;
		}

		.message {
			font-size: $rozhovory_text_font_size_main;
			width: 100%;
		}

		.status {
			display: block;
		}

		iframe.form {
			width: 100%;
			margin-top: 10px;
			padding-top: 10px;
			padding-left: 10px;
			@include breakpoint($breakpoint_desktop) {
				padding-left: 20px;
			}
		}
	}

	#article .question .date {
		float: left;
		margin: 0px;
	}

	.question {
		padding: 10px 0 0 0;
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		.text-wrapper {
			width: 100%;
			margin-bottom: 4px;
		}

		.title {
			width: 100%;
			padding-right: 0;
			padding-left: 0;
			font-weight: normal;
			font-family: $font-medium;
			font-size: .9rem;
			color: $color_background-9;
		}

		.title::before {
			content: '';
			display: inline-block;
			margin-right: 4px;
			width: 5px;
			height: 5px;
			background-color: $color_background-5;
			border-radius: 50%;
			position: relative;
			top: -2px;
		}

		.name {
			margin-left: 5px;
			background: none;
			color: $color_background-9;
			font-size: .6875rem;
			strong {
				color: $color_background-9;
			}
		}
	}

	.answer {
		width: calc(100% - 20px);
		margin: 10px 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		background: transparent;
		border-left: 2px solid $color_decoration_main;

		.photo {
			padding-right: 0;
			padding-left: 0;
			max-width: 60px !important; // nastaveni sirky fotky u odpovedi
			img {
				width: auto;
			}
		}

		.text {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			flex-grow: 1;
			margin-bottom: 10px;
			padding-right: 0;
			width: auto;
			line-height: $rozhovory_lh;
		}

			.name {
				padding-right: 10px;
				padding-left: 10px;
				display: flex;
				order: 1;
				color: $color_background-9;
				font-weight: normal;
				font-family: $font-medium;
			}

			.assignment {
				margin-left: 10px;
				order: 3;
				flex-basis: 100%;
				color: $color_background-8;
				font-size: .75rem;
				line-height: $rozhovory_lh;
			}

			.date {
				display: flex;
				order: 2;
				max-height: 18px;
				margin-left: 10px;
				padding: 2px 3px;
				font-size: 11px;
				color: $color_background-0;
				background-color: $color_background-5;
			}

		.inner-text {
			display: flex;
			flex-basis: 100%;
			flex-wrap: wrap;
			padding: 0 0 0 10px;
			p {
				margin-top: 10px;
				margin-bottom: 0;
				font-size: $rozhovory_text_font_size_main;
				color: $rozhovory_text_color_main;
				line-height: $rozhovory_lh;
			}
			p > * {
				// ochrana pred formatovanim inline styly pres formular
				background-color: transparent !important;
				color: $color_background-6 !important;
			}
		}
}


#cro-discussion .button {
	margin-top: 10px;
	padding: 1em;
	border: 0;
	text-transform: uppercase;
	color: $color_text_accent_inverted;
	background-color: $color_decoration_success;
	cursor: pointer;

	&:hover {
		opacity: .8;
	}
}

#cro-discussion #load-more,
#cro-discussion #moderator-load-more {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	padding: 15px;
	color: $color_decoration_main;
	background-color: $rozhovory_form_bcg;
	font-size: $rozhovory_text_font_size_main;
	@include breakpoint($breakpoint_desktop) {
		padding: 20px;
	}
}


// states
#cro-discussion #load-more:hover,
#cro-discussion #moderator-load-more:hover {
	text-decoration: underline;
}

#cro-discussion #load-more:focus,
#cro-discussion #load-more:active,
#cro-discussion #moderator-load-more:focus,
#cro-discussion #moderator-load-more:active {
	outline: 0;
}


// pseudoelements
#cro-discussion #load-more::before {
	content: '';
	width: 100%;
	height: 1px;
	margin-bottom: 10px;
	background-color: $color_background-3;
}

#cro-discussion #load-more::after,
#cro-discussion #moderator-load-more::after {
	content: '';
	background-image: $file_icon_arrow_single_south_colored-decoration_main;
	background-size: 12px 12px;
	width: 12px;
	height: 12px;
	margin-left: 12px;
	display: inline-block;
}

#cro-discussion #moderator-load-more.hide-all::after {
	transform: rotate(180deg);
}

#cro-discussion .info {
	margin-left: 10px;
	@include breakpoint($breakpoint_desktop) {
		margin-left: 20px;
	}
}

#cro-discussion #discussion-header {
	background-color: $rozhovory_form_bcg;
}

	#cro-discussion #discussion-header h2,
	#cro-discussion #discussion-header .info-header-text {
		margin-left: 10px;
		@include breakpoint($breakpoint_desktop) {
			margin-left: 20px;
		}
	}

	#cro-discussion #discussion-header .info-header-text,
	#cro-discussion #discussion-header .message {
		font-size: $rozhovory_text_font_size_main;
		line-height: $rozhovory_lh;
		margin-top: 0;
		margin-bottom: 0;
	}

	#cro-discussion #discussion-header .info-header-text {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: .95rem;
		color: $rozhovory_text_color_main_highlight;
	}

	#cro-discussion #discussion-content .text-wrapper .text p {
		font-size: $rozhovory_text_font_size_main;
		line-height: $rozhovory_lh;
		color: $rozhovory_text_color_main;
		margin-top: 2px;
		margin-bottom: 0;
	}

	// zahlavi s profily moderatoru
	#cro-discussion #discussion-header .moderator-data {
		display: flex;
		flex-wrap: wrap;
		margin: 10px 10px 0 10px;
		border-bottom: 1px solid $color_background-3;
			@include breakpoint($breakpoint_desktop) {
				margin-left: 20px;
				margin-right: 20px;
			}
	}

		#cro-discussion #discussion-header .moderator-data .moderator {
			display: flex;
			flex-basis: 100%;
			margin-bottom: 10px;
			border-top: 2px solid $color_decoration_main;
			@include breakpoint($breakpoint_desktop) {
				flex-basis: calc(50% - 10px);
				margin-bottom: 20px;
			}
		}

		#cro-discussion #discussion-header .moderator-data .moderator:nth-child(2n) {
			@include breakpoint($breakpoint_desktop) {
				margin-left: 20px;
			}
		}

			#cro-discussion #discussion-header .moderator-data .moderator .text-wrapper {
				flex-basis: 75%;
			}

				#cro-discussion #discussion-header .moderator-data .moderator .text-wrapper .name {
					margin-top: 10px;
					margin-right: 10px;
					color: $color_background-9;
					letter-spacing: .6px;
					font-size: 1.1rem;
					line-height: $rozhovory_lh;
					font-family: $font-medium;
				}

				#cro-discussion #discussion-header .moderator-data .moderator .text-wrapper .text {
					color: $rozhovory_text_color_main;
					font-size: $rozhovory_text_font_size_main;
					line-height: $rozhovory_lh;
				}

			#cro-discussion #discussion-header .moderator-data .moderator .photo {
				flex-basis: 25%;
			}
}

// specificke styly pro side
.l-grid--4-9 {
	.discussion-wrapper-d #cro-discussion #discussion-header {
		h2 {
			text-transform: inherit;
			color: $color_text_accent;
			padding-top: 10px;
			@include breakpoint($breakpoint_desktop) {
				margin-left: 10px;
			}
		}
		.moderator-data .moderator {
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 100%;
				&:nth-child(2n) {
					margin-left: 0;
				}
			}
		}
		.info-header-text {
			margin-right: 20px;
		}
	}
}
