//
// B-011 Styles
//
// varianty a/b nize se lisi prebarvenim podkladu
// a protoze ruzne umisteni v layoutu stranky, tak nejake tweaky
//



.b-011 {

	position: relative;

	//margin: 0;
	//margin-top: 1em;
	//margin-bottom: 1em;

	background-color: $color_decoration_main;
	color: $color_text_accent_inverted;

	@include breakpoint($breakpoint_desktop) {

		//margin: 0;

		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

	.b-011__button-close {

		position: absolute;
		display: block;
		right: 0;
		top: 0;

		width: 3em;
		height: 3em;

		//background-color: rgba(255,0,0,0.2);

		background-image: $file_icon_close_colored-white;
		background-repeat: no-repeat;
		background-position: right 0.75em top 0.75em;
		background-size: 0.75em;

		//filter: brightness(100); //neobarvujeme filtrem, nefunguje v Safari

		@include breakpoint($breakpoint_desktop) {

			position: initial;
			//margin-right: 0.25em;

			background-position: center;
		}
	}


	.b-011__button-forward {

		display: flex;
		flex-direction: column;
		align-items: flex-start;

		padding: 1em;

		@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);

		@include breakpoint($breakpoint_desktop) {

			flex-grow: 1;

			padding-top: 0.75em;
			padding-bottom: 0.75em;
			padding-right: 1em;

			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

		.b-011__message {

			//margin-bottom: 0.5em;
			//margin-right: 1em;

			@include typography_font(medium);
			font-size: $fontsize_h2;

			@include breakpoint($breakpoint_desktop) {

				//margin: 0;
			}
		}

		.b-011__label-action {

			flex-shrink: 0;

			padding: 0;
			padding-right: 2em;
			margin-top: 1em;

			font-size: $fontsize_box_petit_main_desktop;

			background-image: $file_icon_arrow_double_east_colored-white;
			background-size: 1em 1em;
			background-repeat: no-repeat;
			background-position: right center;

			@include breakpoint($breakpoint_desktop) {

				padding-left: 2em;
				margin-top: 0;
			}
		}




//
// B-011a Styles
//
.b-011a {

	//margin-bottom: 1.75em;

	@include breakpoint($breakpoint_desktop) {

		//margin-bottom: 3em;
	}
}



//
// B-011b Styles
//
.b-011b {

	//margin-left: $layout_main_horizontal-padding_mobile;
	//margin-right: $layout_main_horizontal-padding_mobile;

	background-color: $color_decoration_alert;

	@include breakpoint($breakpoint_desktop) {

		//margin-left: 0;
		//margin-right: 0;
		//margin-bottom: 1.5em;

		//padding-left: $layout_main_horizontal-padding_desktop;
		//padding-right: $layout_main_horizontal-padding_desktop;
	}
}
