//
// B-010b Styles
//


$b-010b_item_padding_horizontal_mobile: 0.75em;
$b-010b_item_padding_horizontal_desktop: 1.0em;



.b-010b {
}

	.b-010b__block {
	}

		.b-010b__block .image {
			position: relative;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-010b__block .image .button-play {
			position: absolute;
			left: $b-010b_item_padding_horizontal_mobile;
			bottom: $b-010b_item_padding_horizontal_mobile;

			width: 2.5em;
			height: 2.5em;

			@include breakpoint($breakpoint_desktop) {
				left: $b-010b_item_padding_horizontal_desktop;
				bottom: $b-010b_item_padding_horizontal_desktop;

				width: 2em;
				height: 2em;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-010b__block h3 {
			padding: $b-010b_item_padding_horizontal_mobile;
			margin: 0;

			background-color: $color_background-3;

			@include breakpoint($breakpoint_desktop) {
				padding: $b-010b_item_padding_horizontal_desktop;
			}
		}


	.b-010b__list {
		background-color: $color_background-1;
	}

		.b-010b__list-item {
			position: relative;

			padding: 1em $b-010b_item_padding_horizontal_mobile;
			
			@include breakpoint($breakpoint_desktop) {
				padding: 1em $b-010b_item_padding_horizontal_desktop;
			}
		}

			// play button obal
			.b-010b__item-button-wrapper {
				float: left;
				//margin-right: 0.5em;
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-010b__item-button-wrapper .button-play {
					width: 2em;
					height: 2em;

					@include breakpoint($breakpoint_desktop) {
						width: 1.25em;
						height: 1.25em;;
					}
				}


			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-010b__list-item h3 {
				display: block;
				margin: 0;
				margin-left: 2.5em;

				@include breakpoint($breakpoint_desktop) {
					margin-left: 1.75em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-010b__item-title h3 a {
				}
		
		
		// oddelovaci pseudo linka...
		.b-010b__list-item:before {
			content: "";

			position: absolute;
			left: $b-010b_item_padding_horizontal_mobile;
			right: $b-010b_item_padding_horizontal_mobile;
			bottom: 0;

			//width: 100%;

			border-bottom: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
				left: $b-010b_item_padding_horizontal_desktop;
				right: $b-010b_item_padding_horizontal_desktop;
			}
		}
		// ...neni na poslednim miste
		.b-010b__list-item:last-child:before {
			//content: initial; // nefunguje v IE
			display: none;
		}
		// clearfix
		.b-010b__list-item:after {
			clear: both;
			content: "";
			display: table;
		}
		// last item
		.b-010b__list-item:last-child {
			//padding-bottom: 0;
		}





// vizualni debug - plab only
.patternlab .b-010b {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}