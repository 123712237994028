//
// Button Share Box
//



.button-share {

	position: relative;

	display: block;
	width: 3.5em;
	height: 3.5em;

	margin: 0;
	padding: 0;

	background-image: $file_button_social_sharing_colored-decoration-main;
	background-size: 200%;
	background-position: 0;


	.button__label {

		// cely span popisek je ve vychozim stavu skryt a slouzi jen jako fallback pro obrazek
		display: none;


		// pokud je ale zobrazen, funguje jako normalne lidsky popisek vedle tlacitka
		// a proto je zde jiz predstylovan

		// zobrazen by mel byt pomoci display: flex aby byl popisek zpravne zcentrovan
		// viz treba b013
		align-items: center;

		height: 100%;
		margin-left: 100%;
		padding-left: 0.5em;

		font-size: 1.25em;
		letter-spacing: initial;

		.button__label-nest {
		}
	}

	@include breakpoint($breakpoint_desktop) {

		width: 3.25em;
		height: 3.25em;
	}
}
.button-share::after {

	content: '';

	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;

	background-image: $file_button_social_sharing_colored-decoration-main;
	background-size: 200%;
	background-position: 100%;

	opacity: 0;
	transition: opacity 0.2s;
}
.button-share:hover::after {

	opacity: 1;

}
