//
// D-dur - HP
//

.d-dur.page-d-dur-hp {

	// ted je nove prvni box praveho sloupce
	.box.b-038c {
		
		// prvni box praveho sloupce na desktop nepotrebuje default vertikalni odsazeni
		// naopak je trochu zanoren mimo svuj chlivek aby doslo k zarovnani vrchni hrany sloupce s H2 boxu
		@include breakpoint($breakpoint_desktop) {
			margin-top: -0.5em; // u boxu s h2 neni 0
		}
	}
}