//
// Vary - HP
//



.vary.page-vary-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}