//
// B-067 Styles
//


// vars
//$breakpoint_desktop: 0; //debug
$b-067_block-second_padding: 1.25em;
$b-067_height: 400px;
$b-067_block-first_width: 400px;
$b-067_block-second_width: 350px;
$b-067_block-second_width-nopadding: calc(#{$b-067_block-second_width} - (2 * #{$b-067_block-second_padding}));
$b-067_block-second_height-nopadding: calc(#{$b-067_height} - (2 * #{$b-067_block-second_padding}));



.b-067 {
}

	.b-067__subsection {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			position: relative; // kvuli absolutnimu pozicovani grdientu
			overflow: hidden;
		}
	}

		// block: first
		.b-067__block--first {
			@include breakpoint($breakpoint_desktop) {
				flex-grow: 1;
				min-width: 112.5px;
				max-width: $b-067_block-first_width;
			}

			// overlay
			.b-067__overlay {
				position: relative;

				@include breakpoint($breakpoint_desktop) {
					position: relative;
					height: $b-067_height;
					overflow: hidden;
				}

				// image
				.b-067__image {
					@include breakpoint($breakpoint_desktop) {
						position: absolute;
						left: 50%;
						transform: translate(-50%, 0);

						width: $b-067_block-first_width; // drupal fix
					}

					img {
						@include breakpoint($breakpoint_desktop) {
							width: auto;
							height: $b-067_height !important; // important je fix proti lazyloadu
							transition: transform 0.3s;
						}
					}
				}

				// playitko
				.button.button-play {
					width: 40px;
					height: 40px;

					position: absolute;
					bottom: 15px;
					left: 15px;

					@include breakpoint($breakpoint_desktop) {
						width: 60px;
						height: 60px;

						bottom: 20px;
						left: 20px;
					}
				}
			}

			.b-067__overlay:hover {
				.b-067__image {
					img {
						@include breakpoint($breakpoint_desktop) {
							transform: scale(1.1);
						}
					}
				}
			}
		}

		.b-067__block--first,
		.b-067__block--first:hover {
			@include breakpoint($breakpoint_desktop) {
				transition: min-width 250ms;
			}
		}

		.b-067__block--first:hover {
			@include breakpoint($breakpoint_desktop) {
				min-width: $b-067_block-first_width;
			}
		}

		// block: second
		.b-067__block--second {
			padding: 1em;
			background-color: $color_background-2;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: column;

				width: $b-067_block-second_width-nopadding;
				min-width: $b-067_block-second_width-nopadding;
				height: $b-067_block-second_height-nopadding;

				padding: $b-067_block-second_padding;

				box-sizing: content-box; // ie11
				overflow: hidden;
			}
		}

			// subblock
			.b-067__subblock {
			}

			// subbock: second
			.b-067__subblock--second {
				@include breakpoint($breakpoint_desktop) {
					position: relative;
					flex-grow: 1;
					//max-height: 330px;
					overflow: hidden;
				}
			}

			// gradient
			.b-067__subblock--second::after {
				@include breakpoint($breakpoint_desktop) {
					content: '';

					position: absolute;
					top: 0;
					left: 0;

					width: 100%;
					height: 100%;

					// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
					//background-image: linear-gradient(to top, $color_background-0, transparent 50%);
					background-image: linear-gradient(to top, $color_background-2, rgba(#ffffff, 0) 25%); // list: gradient color

					pointer-events: none; // click skrz
				}
			}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-067__subblock--second > h3 {
					margin: 0;

					// TODO
					a {
						@include link-style($color_text_accent, none, $color_text_accent, underline);
					}
				}

				// episode list
				.b-067__subblock--second .b-067__episode-list {
				}

					// episode item
					.b-067__subblock--second .b-067__episode-list-item {
						margin-bottom: 0.75em;

						@include breakpoint($breakpoint_desktop) {
							margin-bottom: 0.25em;
						}
					}

					// episode item: first 7 visible
					/*.b-067__subblock--second .b-067__episode-list-item:nth-child(n+8) {
						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}*/

					// episode item: last-child
					.b-067__subblock--second .b-067__episode-list-item:last-child {
						margin-bottom: 0;
					}

						// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
						.b-067__subblock--second .b-067__episode-list-item h4 {
							margin: 0;
							color: $color_background-5;

							// span: last-child
							span:last-child {
								@include breakpoint($breakpoint_desktop) {
									/*display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;*/

									/*white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;*/
								}
							}

							// TODO
							a {
								display: flex; // pro potreby ikony play
								@include link-style($color_text_link, none, $color_text_link, underline);
							}

								// ico play
								.button.button-play {
									flex-shrink: 0;

									width: 20px;
									height: 20px;

									margin-right: 0.5em;
								}
						}


				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-067__subblock--second > a:not(.button) {

					@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);

					// TODO
					p {
						display: inline-block;
						margin: 0.5em 0 1em 0;

						@include breakpoint($breakpoint_desktop) {
							margin: 0.5em 0 1em 0;
							font-size: 15px; // default je 16px
						}
					}
				}

				// clearfix
				.b-067__subblock--second > a:after {
					clear: both;
					content: "";
					display: table;
				}


			// readmore
			.b-067__block--second .button-simple--readmore {
				margin-top: 2em;

				@include breakpoint($breakpoint_desktop) {
					//display: inline-flex;
					align-self: flex-start;

					margin-top: auto;
					//margin-right: auto;
				}
			}
