//
// Shadows
//



$shadow_light: 0 0 5px 0 rgba(0, 0, 0, 0.25);

$shadow_bubble: 0 0 30px 0 rgba(0, 0, 0, 0.25);
$shadow_popup: 0 0 50px 0 rgba(0, 0, 0, 0.5);

$shadow_station-header: 0 0 25px 0 rgba(0, 0, 0, 0.25);

$shadow_b-014a: 0 -5px 5px 0 rgba(0, 0, 0, 0.1); // dle Alese by melo byt odsazeni -1em misto -5px
$shadow_portal_b-014a: 0 -5px 5px 0 rgba(0, 0, 0, 0.25); // dle Alese by melo byt odsazeni -1em misto -5px
$shadow_b-001c: 0 0 5px 0 rgba(0, 0, 0, 0.1);
