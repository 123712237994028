//
// Dvojka - Porady
//



.dvojka.page-porady {
	
	// skryti celeho listovaciho obrazkoveho boxu poradu
	// mozna docasne, mozna jindy jinak..
	.b-023d {
		
		//display: none; // default, viz 99_development/_page_porady.scss
		display: block;
		
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
	
		.b-023d__list-item:nth-child(n+7) {
			display: none;
			
			@include breakpoint($breakpoint_desktop) {
				display: block;
			}
		}
}