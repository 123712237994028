//
// Vysocina - HP
//



.vysocina.page-vysocina-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}