//
// Vltava - Americký rok
//



// vars
$americkyrok-bg-desktop: url('../images/bg/americkyrok/americky_rok_bg.jpg');
$americkyrok-bg-mobile: url('../images/bg/americkyrok/americky_rok_bg_mobil.jpg');
$americkyrok-bg-layer: url('../images/bg/americkyrok/americky_rok_bg_nad.png');
$americkyrok-logo: url('../images/logo/americkyrok/americky_rok.png');



//debug only
.vltava.page-americkyrok .b-015 {
	display: none;
}



.vltava.page-americkyrok {
	
	.page {

		.docs .docs-icon {
			z-index: 100;
		}

		.docs .docs-body {
			z-index: 99;

			h1 {
				height: 1.2em;
				background: none;
				backround-image: none;

				&:before, &:after  {
					background: none;
					backround-image: none;
				}
			}
		}
	}
		
		header {
		}
			
			// nav
			.b-016 {
				margin-bottom: 0;
				margin-top: 0.5em;
				
				@include breakpoint($breakpoint_desktop) {
					margin-top: 1em;
				}
			}
		
		
		// logo
		h1 {
			position: relative;
			
			background-image: $americkyrok-logo;
			background-repeat: no-repeat;
			background-size: 225px 66px;
			
			width: 225px;
			height: 66px;
			
			margin: 1em 0 3em 1em;
			padding: 0;
			
			font-size: 1em;
			
			@include breakpoint($breakpoint_desktop) {
				width: 323px;
				height: 95px;
				
				background-size: 323px 95px;
				
				margin-top: 4em;
				margin-right: 0;
				margin-bottom: 2.75em;
				margin-left: $layout_main_horizontal-padding_desktop;
			}
		}
		
		h1:before {
			content: "";
			
			background-image: $americkyrok-bg-mobile;
			background-repeat: no-repeat;
			background-position: center top;
			background-size: cover;
			background-attachment: fixed;
			
			position: absolute;
			top: -64em;
			left: -1em;
			
			width: 100vw;
			height: 12em;
			
			z-index: -2;
			
			@include breakpoint($breakpoint_desktop) {
				background-image: $americkyrok-bg-desktop;
				
				top: -120px;
				left: -915px;
				
				width: 3000px;
				height: 738px;
			}
		}
		
		h1:after {
			
			@include breakpoint($breakpoint_desktop) {
				content: "";
				
				background-image: $americkyrok-bg-layer;
				background-repeat: no-repeat;
				background-size: auto 100%;
				background-position: center top;
				
				position: absolute;
				top: -128px;
				left: -915px;
				
				width: 3000px;
				height: 738px;
				
				z-index: -1;
			}
		}
			
			h1 span {
				display: none;
			}
			
		.main {
			padding-top: 0.1px;
			margin-top: 0;
		}
		// FIX: kdyz .main nema .clearfix
		.main::after {
			clear: both;
			content: "";
			display: table;
		}
}