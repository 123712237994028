//
// Temata - Recepty
//



.temata.page-recepty {

	.views-exposed-form-temata-vsechny-b-004f {
		margin-top: 2em;
		@include breakpoint($breakpoint_desktop) {
			margin-top: 3em;
		}
	}
}
