//
// Files
//



// Top menu
$file_icon_hamburger: url('../images/icon/ico_hamburger.svg');
$file_icon_hamburger_colored-73737f: url('../images/icon/ico_hamburger_colored-73737f.svg');
$file_icon_hamburger_colored-555561: url('../images/icon/ico_hamburger_colored-555561.svg');
$file_icon_hamburger_colored-white: url('../images/icon/ico_hamburger_colored-white.svg');
$file_icon_hamburger_colored-black: url('../images/icon/ico_hamburger_colored-black.svg');
$file_icon_hamburger_colored-bg2: url('../images/icon/ico_hamburger_colored-white.svg');

$file_icon_search: url('../images/icon/ico_search.svg');
$file_icon_search_colored-white: url('../images/icon/ico_search_colored-white.svg'); // white
$file_icon_search_colored-black: url('../images/icon/ico_search_colored-black.svg'); // black
$file_icon_search_colored-bg2: url('../images/icon/ico_search_colored-bg2.svg'); // bg2
$file_icon_search_colored-bg5: url('../images/icon/ico_search_colored-bg5.svg'); // bg5


// Main logos
$file_logo_corporate_main: url('../images/logo/rozhlas_colored-73737f.svg'); // je tam sede logo, main by asi melo byt cosi jineho?
$file_logo_corporate_colored-73737f: url('../images/logo/rozhlas_colored-73737f.svg'); // seda, napr. v hlavni nav liste
$file_logo_corporate_colored-white: url('../images/logo/rozhlas_colored-white.svg'); // bila, napr. v paticce
$file_logo_corporate_colored-bg2: url('../images/logo/rozhlas_colored-bg2.svg'); // bg2, napr. v hlavni nav liste Portal

// Main logos EN
$file_logo_corporate_en_main: url('../images/logo/czech-radio_colored-73737f.svg'); // je tam sede logo, main by asi melo byt cosi jineho?
$file_logo_corporate_en_colored-73737f: url('../images/logo/czech-radio_colored-73737f.svg'); // seda, napr. v hlavni nav liste
$file_logo_corporate_en_colored-white: url('../images/logo/czech-radio_colored-white.svg'); // bila, napr. v paticce
$file_logo_corporate_en_colored-bg2: url('../images/logo/czech-radio_colored-bg2.svg'); // bg2, napr. v hlavni nav liste Portal

// 1OOlet
$file_logo_corporate-100let_main: url('../images/logo/rozhlas-100let_colored-73737f.svg'); // je tam sede logo, main by asi melo byt cosi jineho?
$file_logo_corporate-100let_colored-73737f: url('../images/logo/rozhlas-100let_colored-73737f.svg'); // seda, napr. v hlavni nav liste
$file_logo_corporate-100let_colored-white: url('../images/logo/rozhlas-100let_colored-white.svg'); // bila, napr. v paticce
$file_logo_corporate-100let_colored-bg2: url('../images/logo/rozhlas-100let_colored-bg2.svg'); // bg2, napr. v hlavni nav liste Portal

// iRozhlas & iRozhlas.cz
$file_logo_irozhlascz_colored-main: url('../images/logo/irozhlascz_colored-main.svg'); // main barva iRozhlas.cz
$file_logo_irozhlascz_colored-white: url('../images/logo/irozhlascz_colored-white.svg'); // iRozhlas.cz bila
$file_logo_irozhlas_colored-white: url('../images/logo/irozhlas_colored-white.svg'); // iRozhlas bila

// mujRozhlas
$file_logo_mujrozhlas_colored-main: url('../images/logo/mujrozhlas_colored-main.svg'); // main barva mujRozhlas
$file_logo_mujrozhlas_colored-white: url('../images/logo/mujrozhlas_colored-white.svg'); // mujRozhlas bila
$file_logo_mujrozhlas_colored-grey: url('../images/logo/mujrozhlas_colored-grey.svg'); // mujRozhlas seda

// Radiozurnal
$file_logo_radiozurnal_main: url('../images/logo/radiozurnal_colored-main.svg'); // main barva stanice
$file_logo_radiozurnal_colored-white: url('../images/logo/radiozurnal_colored-white.svg'); // bila

// Dvojka
$file_logo_dvojka_main: url('../images/logo/dvojka_colored-main.svg'); // main barva stanice
$file_logo_dvojka_colored-white: url('../images/logo/dvojka_colored-white.svg'); // bila

// Vltava
$file_logo_vltava_main: url('../images/logo/vltava_colored-main.svg'); // main barva stanice
$file_logo_vltava_colored-white: url('../images/logo/vltava_colored-white.svg'); // bila

// Wave
$file_logo_wave_main: url('../images/logo/wave_colored-main.svg'); // main barva stanice
$file_logo_wave_colored-white: url('../images/logo/wave_colored-white.svg'); // bila

// D-dur
$file_logo_d-dur_main: url('../images/logo/ddur_colored-main.svg'); // main barva stanice
$file_logo_d-dur_colored-white: url('../images/logo/ddur_colored-white.svg'); // bila

// Jazz
$file_logo_jazz_main: url('../images/logo/jazz_colored-main.svg'); // main barva stanice
$file_logo_jazz_colored-white: url('../images/logo/jazz_colored-white.svg'); // bila

// Junior
$file_logo_junior_main: url('../images/logo/junior_colored-main.svg'); // main barva stanice
$file_logo_junior_colored-white: url('../images/logo/junior_colored-white.svg'); // bila

// Junior +
$file_logo_juniorplus_main: url('../images/logo/juniorplus_colored-main.svg'); // main barva stanice
$file_logo_juniorplus_colored-white: url('../images/logo/juniorplus_colored-white.svg'); // bila

// Plus
$file_logo_plus_main: url('../images/logo/plus_colored-main.svg'); // main barva stanice
$file_logo_plus_colored-white: url('../images/logo/plus_colored-white.svg'); // bila

// Brno
$file_logo_brno_main: url('../images/logo/brno_colored-main.svg'); // main barva stanice
$file_logo_brno_colored-white: url('../images/logo/brno_colored-white.svg'); // bila

// Budejovice
$file_logo_budejovice_main: url('../images/logo/budejovice_colored-main.svg'); // main barva stanice
$file_logo_budejovice_colored-white: url('../images/logo/budejovice_colored-white.svg'); // bila

// Hradec
$file_logo_hradec_main: url('../images/logo/hradec_colored-main.svg'); // main barva stanice
$file_logo_hradec_colored-white: url('../images/logo/hradec_colored-white.svg'); // bila

// Liberec
$file_logo_liberec_main: url('../images/logo/liberec_colored-main.svg'); // main barva stanice
$file_logo_liberec_colored-white: url('../images/logo/liberec_colored-white.svg'); // bila

// Olomouc
$file_logo_olomouc_main: url('../images/logo/olomouc_colored-main.svg'); // main barva stanice
$file_logo_olomouc_colored-white: url('../images/logo/olomouc_colored-white.svg'); // bila

// Ostrava
$file_logo_ostrava_main: url('../images/logo/ostrava_colored-main.svg'); // main barva stanice
$file_logo_ostrava_colored-white: url('../images/logo/ostrava_colored-white.svg'); // bila

// Pardubice
$file_logo_pardubice_main: url('../images/logo/pardubice_colored-main.svg'); // main barva stanice
$file_logo_pardubice_colored-white: url('../images/logo/pardubice_colored-white.svg'); // bila

// Plzen
$file_logo_plzen_main: url('../images/logo/plzen_colored-main.svg'); // main barva stanice
$file_logo_plzen_colored-white: url('../images/logo/plzen_colored-white.svg'); // bila

// Regina
$file_logo_regina_main: url('../images/logo/regina_colored-main.svg'); // main barva stanice
$file_logo_regina_colored-white: url('../images/logo/regina_colored-white.svg'); // bila

// Radio DAB
$file_logo_dab_main: url('../images/logo/dab_colored-main.svg'); // main barva stanice
$file_logo_dab_colored-white: url('../images/logo/dab_colored-white.svg'); // bila
$file_logo_dab_colored-corporate: url('../images/logo/dab_colored-corporate.svg'); // korporatni modra

// Sever
$file_logo_sever_main: url('../images/logo/sever_colored-main.svg'); // main barva stanice
$file_logo_sever_colored-white: url('../images/logo/sever_colored-white.svg'); // bila

// Vary
$file_logo_vary_main: url('../images/logo/vary_colored-main.svg'); // main barva stanice
$file_logo_vary_colored-white: url('../images/logo/vary_colored-white.svg'); // bila

// Vysocina
$file_logo_vysocina_main: url('../images/logo/vysocina_colored-main.svg'); // main barva stanice
$file_logo_vysocina_colored-white: url('../images/logo/vysocina_colored-white.svg'); // bila

// Zlin
$file_logo_zlin_main: url('../images/logo/zlin_colored-main.svg'); // main barva stanice
$file_logo_zlin_colored-white: url('../images/logo/zlin_colored-white.svg'); // bila

// Region (Stredni Cechy)
$file_logo_region_main: url('../images/logo/region_colored-main.svg'); // main barva stanice
$file_logo_region_colored-white: url('../images/logo/region_colored-white.svg'); // bila

// Stredni Cechy (drive Region)
$file_logo_strednicechy_main: url('../images/logo/strednicechy_colored-main.svg'); // main barva stanice
$file_logo_strednicechy_colored-white: url('../images/logo/strednicechy_colored-white.svg'); // bila

// Regiony (Radio vaseho kraje)
$file_logo_regiony_main: url('../images/logo/regiony_colored-main.svg'); // main barva stanice
$file_logo_regiony_colored-white: url('../images/logo/regiony_colored-white.svg'); // bila

// Radio Retro
$file_logo_retro_main: url('../images/logo/retro_colored-main.svg'); // main barva stanice
$file_logo_retro_colored-white: url('../images/logo/retro_colored-white.svg'); // bila

// Sport
$file_logo_sport_main: url('../images/logo/sport_colored-main.svg'); // main barva stanice
$file_logo_sport_colored-white: url('../images/logo/sport_colored-white.svg'); // bila

// Pohoda
$file_logo_pohoda_main: url('../images/logo/pohoda_colored-main.svg'); // main barva stanice
$file_logo_pohoda_colored-white: url('../images/logo/pohoda_colored-white.svg'); // bila

// Radiozurnal Sport
$file_logo_radiozurnalsport_main: url('../images/logo/radiozurnalsport_colored-main.svg'); // main barva stanice
$file_logo_radiozurnalsport_colored-white: url('../images/logo/radiozurnalsport_colored-white.svg'); // bila

// Radiozurnal Sport +
$file_logo_radiozurnalsportplus_main: url('../images/logo/radiozurnalsportplus_colored-main.svg'); // main barva stanice
$file_logo_radiozurnalsportplus_colored-white: url('../images/logo/radiozurnalsportplus_colored-white.svg'); // bila

// Radio Praha
//$file_logo_radiopraha_main: url('../images/logo/praha_colored-main.svg'); // main barva stanice
//$file_logo_radiopraha_colored-white: url('../images/logo/praha_colored-white.svg'); // bila
$file_logo_radiopraha_main: url('../images/logo/prague_colored-main.svg'); // main barva stanice
$file_logo_radiopraha_colored-white: url('../images/logo/prague_colored-white.svg'); // bila
$file_logo_radioprague-int_main: url('../images/logo/prague-int_colored-main.svg'); // main barva stanice
$file_logo_radioprague-int_colored-white: url('../images/logo/prague-int_colored-white.svg'); // bila

// Radioteka
//$file_logo_radioteka_main: url('../images/logo/radioteka_colored-main.svg'); // main barva
$file_logo_radioteka_colored-white: url('../images/logo/radioteka_colored-white.svg'); // bila

// Nadacni fond
$file_logo_nadacnifond_main: url('../images/logo/nadacnifond_colored-main.svg'); // main barva
$file_logo_nadacnifond_colored-white: url('../images/logo/nadacnifond_colored-white.svg'); // bila

// Program
//$file_logo_program_main: url('../images/logo/program_colored-main.svg'); // main barva
$file_logo_program_colored-white: url('../images/logo/program_colored-white.svg'); // bila

// Rada
$file_logo_rada_main: url('../images/logo/rada_colored-main.svg'); // main barva
$file_logo_rada_colored-white: url('../images/logo/rada_colored-white.svg'); // bila

// SOCR
$file_logo-long_socr_desktop_colored-white: url('../images/logo/socr_long_desktop_colored-white.svg'); // bila
$file_logo-long_socr_mobile_colored-white: url('../images/logo/socr_long_mobile_colored-white.svg'); // bila
$file_logo-long_prso_desktop_colored-white: url('../images/logo/prso_long_desktop_colored-white.svg'); // bila
$file_logo-long_prso_mobile_colored-white: url('../images/logo/prso_long_mobile_colored-white.svg'); // bila

// Svet rozhlasu
$file_logo_svet_main: url('../images/logo/svet_colored-main.svg'); // main barva
$file_logo_svet_colored-white: url('../images/logo/svet_colored-white.svg'); // bila

// R
$file_logo_R_main: url('../images/logo/R_main.svg');
$file_logo_R_vltava: url('../images/logo/R_vltava.svg');
$file_logo_R_plus: url('../images/logo/R_plus.svg');
$file_logo_R_wave: url('../images/logo/R_wave.svg');
$file_logo_R_region: url('../images/logo/R_region.svg');
$file_logo_R_digital: url('../images/logo/R_digital.svg');
$file_logo_R_white: url('../images/logo/R_white.svg');



// Header icons
$file_icon_search: url('../images/icon/ico_search.svg');
$file_icon_menu: url('../images/icon/ico_hamburger.svg');



// Article icons
$file_icon_timer: url('../images/icon/ico_timer.svg');
$file_icon_timer_colored-main: url('../images/icon/ico_timer_colored-main.svg');
$file_icon_quotation: url('../images/icon/ico_citace.svg');
$file_icon_tag: url('../images/icon/ico_tag.svg');

$file_icon_calendar: url('../images/icon/ico_cal.svg');
$file_icon_calendar_colored-main: url('../images/icon/ico_cal_colored-main.svg'); // main
$file_icon_calendar_colored-white: url('../images/icon/ico_cal_colored-white.svg'); // white
$file_icon_calendar_colored-black: url('../images/icon/ico_cal_colored-black.svg'); // black
$file_icon_calendar-junior: url('../images/icon/ico_cal-junior.svg');



// Flag icons
$file_flag_cze: url('../images/icon/flag_cz.svg');
$file_flag_eng: url('../images/icon/flag_eng.svg');
$file_flag_ger: url('../images/icon/flag_de.svg');
$file_flag_esp: url('../images/icon/flag_esp.svg');
$file_flag_fra: url('../images/icon/flag_fr.svg');
$file_flag_rus: url('../images/icon/flag_ru.svg');



// Print icons
$file_icon_print_colored-555561: url('../images/icon/ico_print_colored-555561.svg'); // seda
$file_icon_print-eco_colored-555561: url('../images/icon/ico_print-eco_colored-555561.svg'); // seda



// Poplatek icons
$file_icon_enter_colored-main: url('../images/icon/ico_enter_colored-decoration-main.svg'); // main
$file_icon_enter_colored-white: url('../images/icon/ico_enter_colored-white.svg'); // white
$file_icon_form_colored-main: url('../images/icon/ico_form_colored-decoration-main.svg'); // main
$file_icon_form_colored-white: url('../images/icon/ico_form_colored-white.svg'); // white
$file_icon_radio_colored-main: url('../images/icon/ico_radio_colored-decoration-main.svg'); // main
$file_icon_radio_colored-white: url('../images/icon/ico_radio_colored-white.svg'); // white
$file_icon_mail_colored-main: url('../images/icon/ico_mail_colored-decoration-main.svg'); // main
$file_icon_mail_colored-white: url('../images/icon/ico_mail_colored-white.svg'); // white
$file_icon_new-user_colored-main: url('../images/icon/ico_new-user_colored-decoration-main.svg'); // main
$file_icon_new-user_colored-white: url('../images/icon/ico_new-user_colored-white.svg'); // white
$file_icon_pay-card_colored-main: url('../images/icon/ico_pay-card_colored-decoration-main.svg'); // main
$file_icon_pay-card_colored-white: url('../images/icon/ico_pay-card_colored-white.svg'); // white
$file_icon_at_colored-main: url('../images/icon/ico_at_colored-decoration-main.svg'); // main
$file_icon_at_colored-white: url('../images/icon/ico_at_colored-white.svg'); // white
$file_icon_data-box_colored-main: url('../images/icon/ico_data-box_colored-decoration-main.svg'); // main
$file_icon_data-box_colored-white: url('../images/icon/ico_data-box_colored-white.svg'); // white
$file_icon_phone-handset_colored-main: url('../images/icon/ico_phone-handset_colored-decoration-main.svg'); // main
$file_icon_phone-handset_colored-white: url('../images/icon/ico_phone-handset_colored-white.svg'); // white



// DAB+ icons
$file_icon_dab-sound_colored-main: url('../images/icon/dab/ico_zvuk_colored-decoration-main.svg'); // main
$file_icon_dab-selection_colored-main: url('../images/icon/dab/ico_vyber_colored-decoration-main.svg'); // main
$file_icon_dab-display_colored-main: url('../images/icon/dab/ico_displej_colored-decoration-main.svg'); // main
$file_icon_dab-signal_colored-main: url('../images/icon/dab/ico_prijem_colored-decoration-main.svg'); // main
$file_icon_dab-energy_colored-main: url('../images/icon/dab/ico_setrnost_colored-decoration-main.svg'); // main
$file_icon_dab-cover_colored-main: url('../images/icon/dab/ico_pokryti_colored-decoration-main.svg'); // main



// Webcam thumbnails - default
$file_thumbnail_webcam-default: url('../images/extras/kamery/default/thumbnail-1.jpg'); // default

// Webcam thumbnails - celoplosne
$file_thumbnail-01_webcam-dvojka: url('../images/extras/kamery/dvojka/thumbnail-1.jpg'); // dvojka
$file_thumbnail-01_webcam-vltava: url('../images/extras/kamery/vltava/thumbnail-1.jpg'); // vltava - 1
$file_thumbnail-02_webcam-vltava: url('../images/extras/kamery/vltava/thumbnail-2.jpg'); // vltava - 2
$file_thumbnail-01_webcam-plus: url('../images/extras/kamery/plus/thumbnail-1.jpg'); // plus - moderator
$file_thumbnail-02_webcam-plus: url('../images/extras/kamery/plus/thumbnail-2.jpg'); // plus - host
$file_thumbnail-01_webcam-wave: url('../images/extras/kamery/wave/thumbnail-1.jpg'); // wave
$file_thumbnail-01_webcam-junior: url('../images/extras/kamery/junior/thumbnail-1.jpg'); // junior

// Webcam thumbnails - regiony
$file_thumbnail-01_webcam-brno: url('../images/extras/kamery/brno/thumbnail-1.jpg'); // brno - studio
$file_thumbnail-02_webcam-brno: url('../images/extras/kamery/brno/thumbnail-2.jpg'); // brno - rezie
$file_thumbnail-01_webcam-budejovice: url('../images/extras/kamery/budejovice/thumbnail-1.jpg'); // budejovice
$file_thumbnail-01_webcam-liberec: url('../images/extras/kamery/liberec/thumbnail-1.jpg'); // liberec
$file_thumbnail-01_webcam-hradec: url('../images/extras/kamery/hradec/thumbnail-1.jpg'); // hradec - 1
$file_thumbnail-02_webcam-hradec: url('../images/extras/kamery/hradec/thumbnail-2.jpg'); // hradec - 2
$file_thumbnail-01_webcam-plzen: url('../images/extras/kamery/plzen/thumbnail-1.jpg'); // plzen
$file_thumbnail-01_webcam-olomouc: url('../images/extras/kamery/olomouc/thumbnail-1.jpg'); // olomouc
$file_thumbnail-01_webcam-ostrava: url('../images/extras/kamery/ostrava/thumbnail-1.jpg'); // ostrava - 1
$file_thumbnail-02_webcam-ostrava: url('../images/extras/kamery/ostrava/thumbnail-1.jpg'); // ostrava - 2
$file_thumbnail-01_webcam-dab: url('../images/extras/kamery/dab/thumbnail-1.jpg'); // dab - 1
$file_thumbnail-02_webcam-dab: url('../images/extras/kamery/dab/thumbnail-2.jpg'); // dab - 2
$file_thumbnail-01_webcam-region: url('../images/extras/kamery/region/thumbnail-1.jpg'); // region
$file_thumbnail-01_webcam-sever: url('../images/extras/kamery/sever/thumbnail-1.jpg'); // sever
$file_thumbnail-01_webcam-vary: url('../images/extras/kamery/vary/thumbnail-1.jpg'); // vary
$file_thumbnail-01_webcam-zlin: url('../images/extras/kamery/zlin/thumbnail-1.jpg'); // zlin - 1
$file_thumbnail-02_webcam-zlin: url('../images/extras/kamery/zlin/thumbnail-2.jpg'); // zlin - 2



// Podcast icons
$file_icon_podcast_colored-main: url('../images/icon/ico_podcast_colored-decoration-main.svg'); // main color
$file_icon_mujrozhlas: url('../images/icon/ico_subscribe_mujrozhlas.svg'); // black
$file_icon_mujrozhlas_colored-white: url('../images/icon/ico_subscribe_mujrozhlas_colored-white.svg'); // white
$file_icon_apple: url('../images/icon/ico_subscribe_apple.svg'); // black
$file_icon_apple_colored-white: url('../images/icon/ico_subscribe_apple_colored-white.svg'); // white
$file_icon_android: url('../images/icon/ico_subscribe_android.svg'); // black
$file_icon_android_colored-white: url('../images/icon/ico_subscribe_android_colored-white.svg'); // white
$file_icon_spotify: url('../images/icon/ico_subscribe_spotify.svg'); // black
$file_icon_spotify_colored-white: url('../images/icon/ico_subscribe_spotify_colored-white.svg'); // white
$file_icon_rss: url('../images/icon/ico_subscribe_rss.svg'); // black
$file_icon_rss_colored-white: url('../images/icon/ico_subscribe_rss_colored-white.svg'); // white
$file_icon_ytmusic: url('../images/icon/ico_subscribe_ytmusic.svg'); // black
$file_icon_ytmusic_colored-white: url('../images/icon/ico_subscribe_ytmusic_colored-white.svg'); // white



// Poplatek icons
$file_icon_households_colored-white: url('../images/icon/ico_households_colored-white.svg'); // white
$file_icon_businessmen_colored-white: url('../images/icon/ico_bussinesmen_colored-white.svg'); // white
$file_icon_legalentities_colored-white: url('../images/icon/ico_legalentities_colored-white.svg'); // white



// Doba dabová icons
$file_icon_bulb: url('../images/icon/ico_bulb.svg');
$file_icon_map: url('../images/icon/ico_map.svg');
$file_icon_tunebutton: url('../images/icon/ico_tunebutton.svg');



// Badge icons
// vizualni vlastnost v nahledu
// obrazkove priznaky - muze se zarovnavat k obrazku nebo titulku apod
$file_icon_badge_gallery: url('../images/icon/minico_cam.svg'); // clanek obsahuje fotogalerii
$file_icon_badge_audio: url('../images/icon/minico_sound.svg'); // clanek obsahuje audio
$file_icon_badge_audio_colored-main: url('../images/icon/minico_sound_colored-decoration-main.svg');
$file_icon_badge_audio_colored-fff3dd: url('../images/icon/minico_sound_colored-fff3dd.svg'); // bezova, napr. junior
$file_icon_badge_video: url('../images/icon/minico_video.svg'); // clanek obsahuje video
$file_icon_badge_facebook: url('../images/icon/socico_facebook_colored-73737f.svg'); // clanek obsahuje facebook
$file_icon_badge_twitter: url('../images/icon/socico_twitter_colored-73737f.svg'); // clanek obsahuje twitter
$file_icon_badge_x: url('../images/icon/socico_x_colored-73737f.svg'); // clanek obsahuje x
$file_icon_badge_youtube: url('../images/icon/socico_youtube_colored-73737f.svg'); // clanek obsahuje youtube
$file_icon_badge_instagram: url('../images/icon/socico_instagram_colored-73737f.svg'); // clanek obsahuje instagram
$file_icon_badge_audio-play_colored-black: url('../images/icon/ico_play_audio-colored-black.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-black: url('../images/icon/ico_play_serial_colored-black.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-white: url('../images/icon/ico_play_audio-colored-white.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-white: url('../images/icon/ico_play_serial_colored-white.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-efeff2: url('../images/icon/ico_play_audio-colored-efeff2.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-efeff2: url('../images/icon/ico_play_serial_colored-efeff2.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-1a1a1e: url('../images/icon/ico_play_audio-colored-1a1a1e.svg'); // clanek obsahuje audio (ikona play)
$file_icon_badge_serial-play_colored-1a1a1e: url('../images/icon/ico_play_serial_colored-1a1a1e.svg'); // clanek obsahuje audio serial (ikona play + hamburger)
$file_icon_badge_audio-play_colored-0a9ae4: url('../images/icon/ico_play_audio-colored-0a9ae4.svg'); // clanek obsahuje audio (ikona play), napr. junior
$file_icon_badge_serial-play_colored-0a9ae4: url('../images/icon/ico_play_serial_colored-0a9ae4.svg'); // clanek obsahuje audio serial (ikona play + hamburger), napr. junior



// Playback buttons
$file_button_play: url('../images/button/btn_play_sprite.svg'); // atlas
$file_button_pause: url('../images/button/btn_pause_sprite.svg'); // atlas
$file_button_play_colored-white: url('../images/button/btn_play_sprite_colored-white.svg'); // atlas, white
$file_button_play_launcher: url('../images/button/btn_play_launcher_sprite.svg'); // atlas
$file_button_play_launcher-sport_colored-ed2e38: url('../images/button/btn_play_launcher-sport_colored-ed2e38.svg'); // #ed2e38
$file_button_play_launcher-sport_colored-bc1526: url('../images/button/btn_play_launcher-sport_colored-bc1526.svg'); // #bc1526
$file_button_pause_launcher-sport_colored-ed2e38: url('../images/button/btn_pause_launcher-sport_colored-ed2e38.svg'); // #ed2e38
$file_button_pause_launcher-sport_colored-bc1526: url('../images/button/btn_pause_launcher-sport_colored-bc1526.svg'); // #bc1526
$file_button_play_launcher-live-sport_colored-white: url('../images/button/btn_play_launcher-zive-sport_colored-white.svg'); // white
$file_button_play_launcher-live-pohoda_colored-main: url('../images/button/btn_play_launcher-zive-pohoda_colored-main.svg'); // main
$file_button_play_launcher-live-pohoda_colored-white: url('../images/button/btn_play_launcher-zive-pohoda_colored-white.svg'); // white
$file_button_eq_launcher-live_colored-white: url('../images/button/btn_eq_launcher-zive_colored-white.svg'); // white
$file_button_play_launcher-mujrozhlas_colored-white: url('../images/button/btn_play_launcher-mujrozhlas_colored-white.svg'); // white

// Playback buttons Radio Junior
$file_button_play_launcher_colored-fff3dd: url('../images/button/btn_play_launcher_sprite_colored-fff3dd.svg'); // atlas
$file_button_play_colored-fff3dd: url('../images/button/btn_play_sprite_colored-fff3dd.svg'); // atlas
$file_button_play_colored-0a9ae4: url('../images/button/btn_play_sprite_colored-0a9ae4.svg'); // atlas
// Video playback button
$file_button_badge_video-play: url('../images/button/btn_play_video_red_sprite.svg'); // atlas, YT red



// Other icons
//$file_icon_arrow_double_east: url('../images/icon/ico_arr_double.svg'); // dvojita sipka vpravo
$file_icon_arrow_single_east: url('../images/icon/ico_arr_single.svg'); // sipka vlevo
// junior
$file_icon_arrow_single-junior_west_colored-white: url('../images/icon/ico_arr_single-junior_colored-white.svg'); // sipka vlevo
$file_icon_arrow_single-junior_west_colored-0a9ae4: url('../images/icon/ico_arr_single-junior_colored-0a9ae4.svg'); // sipka vlevo
$file_icon_arrow_cta-junior_south_colored-white: url('../images/icon/ico_arr_cta-junior_colored-white.svg'); // sipka dolu
$file_icon_arrow_cta-junior_south_colored-fff3dd: url('../images/icon/ico_arr_cta-junior_colored-fff3dd.svg'); // sipka dolu
// Colored variations
$file_icon_arrow_double_east_colored-white: url('../images/icon/ico_arr_double_colored-white.svg'); // sipka dvojita vpravo bila
$file_icon_arrow_double_east_colored-decoration-main: url('../images/icon/ico_arr_double_colored-decoration-main.svg'); // sipka dvojita vpravo obarvena
$file_icon_arrow_double_east_colored-decoration-grey: url('../images/icon/ico_arr_double_colored-decoration-grey.svg'); // sipka dvojita vpravo obarvena
$file_icon_arrow_single_east_colored-white: url('../images/icon/ico_arr_single_east_colored-white.svg'); // sipka vlevo obarvena
$file_icon_arrow_single_east_colored-decoration-main: url('../images/icon/ico_arr_single_east_colored-decoration-main.svg'); // sipka vlevo obarvena
$file_icon_arrow_single_north_colored-decoration-main: url('../images/icon/ico_arr_single_north_colored-decoration-main.svg'); // sipka nahoru obarvena
$file_icon_arrow_single_south_colored-decoration-main: url('../images/icon/ico_arr_single_south_colored-decoration-main.svg'); // sipka dolu obarvena
$file_icon_arrow_single_south_colored-white: url('../images/icon/ico_arr_single_south_colored-white.svg'); // sipka dolu obarvena
$file_icon_arrow_single_south_colored-grey: url('../images/icon/ico_arr_single_south_colored-grey.svg'); // sipka dolu obarvena

$file_icon_sandtimer: url('../images/icon/ico_sandtimer.svg'); // presypacky, default zelene
$file_icon_sandtimer_colored-009645: url('../images/icon/ico_sandtimer_colored-009645.svg'); // presypacky, zelene
$file_icon_sandtimer_colored-09e9ea9: url('../images/icon/ico_sandtimer_colored-9e9ea9.svg'); // presypacky, sede
$file_icon_sandtimer_colored-white: url('../images/icon/ico_sandtimer_colored-white.svg'); // presypacky, bile

$file_icon_note_double: url('../images/icon/ico_double_note.svg'); // noticky
$file_icon_note_double_colored-white: url('../images/icon/ico_double_note_colored-white.svg');
$file_icon_triangle_south: url('../images/icon/ico_arr_more.svg'); // vyplneny trojuhelnik ukazujici dolu
$file_icon_triangle_south_colored-white: url('../images/icon/ico_arr_more_colored-white.svg'); // vyplneny trojuhelnik ukazujici dolu, white
$file_icon_triangle_south_colored-black: url('../images/icon/ico_arr_more_colored-black.svg'); // vyplneny trojuhelnik ukazujici dolu, black
$file_icon_triangle_south_colored-bg2: url('../images/icon/ico_arr_more_colored-bg2.svg'); // vyplneny trojuhelnik ukazujici dolu, bg2
$file_icon_close_colored-519fd7: url('../images/icon/ico_close_colored-519fd7.svg'); // zaviraci X
$file_icon_close_colored-white: url('../images/icon/ico_close_colored-white.svg'); // zaviraci X bile
$file_icon_close_colored-bg5: url('../images/icon/ico_close_colored-bg5.svg'); // zaviraci X bg5
$file_icon_close-circle_colored-ca003a: url('../images/icon/ico_close-circle_colored-ca003a.svg'); // zaviraci X v kolecku
$file_icon_upscale_colored-white: url('../images/icon/ico_upscale_colored-white.svg'); // upscale tlacitko
$file_icon_zoomin_colored-white: url('../images/icon/ico_zoom_in_colored-white.svg'); // zoom in lupa
$file_icon_zoomout_colored-white: url('../images/icon/ico_zoom_out_colored-white.svg'); // zoom out lupa
$file_icon_gallery_colored-white: url('../images/icon/ico_pic_gallery_colored-white.svg'); // gallery
$file_icon_gallery_colored-73737f: url('../images/icon/ico_pic_gallery_colored-73737f.svg'); // gallery
$file_icon_audio_expired: url('../images/icon/ico_alert_fff.svg'); // ikona sedeho vykricniku
$file_icon_audio_expired_line: url('../images/icon/ico_alert_fff_line_d6d6de.svg'); // ikona sedeho vykricniku, sedy 1px trojuhelnik
$file_icon_audio_expired-f9c011: url('../images/icon/ico_alert_f9c011.svg'); // ikona cerneho vykricniku se zlutym solid pozadim trojuhelniku
$file_icon_info: url('../images/icon/ico_info.svg'); // icko v dutem kolecku
$file_icon_star_colored-white: url('../images/icon/ico_star_colored-white.svg'); // icko hvezdicka, white
$file_icon_star_colored-ffd400: url('../images/icon/ico_star_colored-ffd400.svg'); // icko hvezdicka, yellow

$file_icon_mail: url('../images/icon/ico-1-mial.svg'); // mail ikona v kolecku
$file_icon_message: url('../images/icon/ico-2-messages.svg'); // sms ikona v kolecku
$file_icon_twitter: url('../images/icon/ico-3-twitter.svg'); // twitter ikona v kolecku
$file_icon_facebook: url('../images/icon/ico-4-facebook.svg'); // face ikona v kolecku

$file_icon_amazon_alexa: url('../images/icon/ico_assist_amazon_alexa.svg'); // ikona pro hlasove asistenty - Amazon Alexa
$file_icon_google_home: url('../images/icon/ico_assist_google_home.svg'); // ikona pro hlasove asistenty - Google Home



// Store buttons
$file_button_store_apple: url('../images/button/btn_store-apple.svg');
$file_button_store_apple_rounded: url('../images/button/btn_store-apple_rounded.svg');
$file_button_store_google: url('../images/button/btn_store-google.svg');
$file_button_store_google_rounded: url('../images/button/btn_store-google_rounded.svg');
$file_button_store_amazon_rounded: url('../images/button/btn_store-amazon_rounded.svg');



// Social buttons
$file_button_social_share: url('../images/icon/socico_share.svg');
$file_button_social_sharing_colored-decoration-main: url('../images/button/btn_sharing_colored-decoration-main.svg'); // obarveny velky button share

$file_button_social_newsletter: url('../images/icon/socico_newsletter.svg');
$file_button_social_rss: url('../images/icon/socico_rss.svg');

$file_button_social_newsletter_colored-73737f: url('../images/icon/socico_newsletter_colored-73737f.svg');
$file_button_social_rss_colored-73737f: url('../images/icon/socico_newsletter_colored-73737f.svg');

$file_button_social_newsletter_colored-decoration-main: url('../images/icon/socico_newsletter_colored-decoration-main.svg');
$file_button_social_rss_colored_decoration-main: url('../images/icon/socico_rss_colored-decoration-main.svg');

$file_button_social_facebook: url('../images/icon/socico_facebook.svg');
$file_button_social_googleplus: url('../images/icon/socico_gplus.svg');
$file_button_social_instagram: url('../images/icon/socico_instagram.svg');
$file_button_social_linkedin: url('../images/icon/socico_linkedin_colored-white.svg');
$file_button_social_twitter: url('../images/icon/socico_twitter.svg');
$file_button_social_youtube: url('../images/icon/socico_youtube.svg');
$file_button_social_x: url('../images/icon/socico_x.svg');

$file_button_social_facebook_colored: url('../images/icon/socico_facebook_colored.svg');
$file_button_social_googleplus_colored: url('../images/icon/socico_gplus_colored.svg');
$file_button_social_instagram_colored: url('../images/icon/socico_instagram_colored.svg');
$file_button_social_linkedin_colored: url('../images/icon/socico_linkedin_colored-007bb6.svg');
$file_button_social_twitter_colored: url('../images/icon/socico_twitter_colored.svg');
$file_button_social_x_colored: url('../images/icon/socico_x_colored.svg');
$file_button_social_youtube_colored: url('../images/icon/socico_youtube_colored.svg');
$file_button_social_x_colored: url('../images/icon/socico_x_colored.svg');

$file_button_social_facebook_colored-decoration-main: url('../images/icon/socico_facebook_colored-decoration-main.svg');
$file_button_social_googleplus_colored-decoration-main: url('../images/icon/socico_gplus_colored-decoration-main.svg');
$file_button_social_instagram_colored-decoration-main: url('../images/icon/socico_instagram_colored-decoration-main.svg');
$file_button_social_linkedin_colored-decoration-main: url('../images/icon/socico_linkedin_colored-decoration-main.svg');
$file_button_social_twitter_colored-decoration-main: url('../images/icon/socico_twitter_colored-decoration-main.svg');
$file_button_social_x_colored-decoration-main: url('../images/icon/socico_x_colored-decoration-main.svg');
$file_button_social_youtube_colored-decoration-main: url('../images/icon/socico_youtube_colored-decoration-main.svg');



// Services
$file_button_service_google: url('../images/button/btn_plst_google_sprite.svg'); // atlas
$file_button_service_spotify: url('../images/button/btn_plst_spotify_sprite.svg'); // atlas
$file_button_service_youtube: url('../images/button/btn_plst_youtube_sprite.svg'); // atlas
$file_button_service_tidal: url('../images/button/btn_plst_tidal_sprite.svg'); // atlas



// Action buttons
$file_button_action_play: url('../images/button/btn_play_sprite.svg'); // atlas
$file_button_action_play_colored-fff3dd: url('../images/button/btn_play_sprite_colored-fff3dd.svg'); // atlas
$file_button_action_pause: url('../images/button/btn_pause_sprite.svg'); // atlas
$file_button_action_pause_colored-fff3dd: url('../images/button/btn_pause_sprite_colored-fff3dd.svg'); // atlas, napr. junior
$file_button_action_stop_colored-fff3dd: url('../images/button/btn_stop_sprite_colored-fff3dd.svg'); // atlas
$file_button_action_download: url('../images/button/btn_download_sprite.svg'); // atlas
$file_button_action_podcast: url('../images/button/btn_podcast_sprite.svg'); // atlas
$file_button_action_share: url('../images/button/btn_share_sprite.svg'); // atlas
$file_button_action_share_colored-white: url('../images/button/btn_share_sprite_colored-white.svg'); // atlas
$file_button_action_print: url('../images/button/btn_print.svg'); // atlas



// Box buttons
$file_button_close: url('../images/button/btn_close.svg');

// Box movement icons (soupani pro slidery apod)
$file_button_icon_arrow_east: url('../images/button/btn_arr.svg'); // sipka vpravo bila
$file_button_icon_arrow_east_colored-blue: url('../images/button/btn_arr_colored-decoration-main.svg'); // sipka vpravo modra

$file_button_icon_arrow_east_colored-white: url('../images/button/btn_arr_colored-white.svg');
$file_button_icon_arrow_west: url('../images/button/btn_arr.svg'); // sipka vlevo - TODO chybi
$file_button_icon_arrow_north: url('../images/button/btn_arr.svg'); // sipka vlevo - TODO chybi
$file_button_icon_arrow_south: url('../images/button/btn_arr.svg'); // sipka vlevo - TODO chybi
// Box movement icons with ending
$file_button_icon_arrow_east_end: url('../images/button/btn_arr_end.svg'); // sipka vpravo se stopkou



// Checkbox buttons
$file_checkbox_checked: url('../images/button/btn_checkbox_checked.svg'); // checkbox symbol zaskrtnuty
$file_checkbox_unchecked: url('../images/button/btn_checkbox_unchecked.svg'); // checkbox symbol nezaskrtnuty (jen sedy ctverec)
$file_checkbox_unchecked-2: url('../images/button/btn_checkbox_unchecked-2.svg'); // checkbox symbol nezaskrtnuty (sedy ctverec s sedou fajfkou)