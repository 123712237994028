//
// B-008c Styles
//
// prima pribuznost s boxem b004b z ktereho je odvozeno
// nejake vlastnosti sdileji, zahodno zamyslet zda zmeny nezanaset spolecne
//
// zaroven elementy jista podobnost s b008b
//



.b-008c {
}
	
	// obal dvou UL seznamu aby se mohli rozflexovat vedle sebe v pote nasledne specifikovanem pomeru
	.b-008c__subsection {
		
		display: flex;
		flex-direction: column;
		
		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}
		
		// obecny seznam
		.b-008c__list {
		}
			
			// obecny item (vlastnosti v toku vlevo i vpravo)
			.b-008c__list-item {
			}
				
				.b-008c__list-item .caption {
					
					margin-top: 0.75em;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008c__list-item h3 {
					
					margin: 0;
					padding-top: 0.5em;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008c__list-item a p {
					
					margin-top: 0.5em;
					margin-bottom: 0;
					
					color: $color_text_paragraph;
					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
				}
		
		
		
		// primarni prispevek (obrazek + abstrakt horizontalne)
		.b-008c__list--column-primary {
			
			flex: 5;
			
			margin-bottom: 2em;
			
			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0em;
			}
		}
			
			// abstrakt horizontalni neboli prvni siroky element
			.b-008c__list-item--column-primary {
				
				display: flex;
				flex-direction: column;
				
				background-color: $color_background-2;
				
				
				@include breakpoint($breakpoint_desktop) {
					
					flex-direction: row;
					background-color: transparent;
				}
			}
				
				.b-008c__block--image-primary {
					
					@include breakpoint($breakpoint_desktop) {
						flex: 3;
					}
				}
					
					.b-008c__image {
						position: relative;
					}
					
					// tagy jsou zde u itemu s obrazkem dvoji, jeden navic v obrazku a jeden standardne
					// skryvaji se podle breakpointu
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008c__block--image-primary .tag {
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
						
						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}
				
				
				.b-008c__block--description-primary {
					padding-left: 0.5em;
					padding-right: 0.5em;
					
					@include breakpoint($breakpoint_desktop) {
						
						flex: 2;
						
						padding-left: 1em;
						padding-right: 1em;
					}
				}
					
					.b-008c__subblock--primary {
						
						// tohle je obalovak jenom proto
						// aby to v mobile variante mohlo flexovat uplne jinak nez na desktopu
						// tam to jenom sype pod sebou a nic se neresi protoze button na prehravani
						// kvuli kterymu tu ten konstrukt je, tam neni zobrazen a zobrazuje se jiny dole
						
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						
						@include breakpoint($breakpoint_desktop) {
							display: block;
						}
					}
						
						.b-008c__description-heading-wrapper {
						}
							
							// TODO z duvodu atomu zatim ne pres specifickou tridu
							.b-008c__description-heading-wrapper .tag {
								display: none;
								
								@include breakpoint($breakpoint_desktop) {
									display: inline-block;
								}
							}
						
						// tlacitko prehrat pro mobile
						.b-008c__button-play-wrapper--primary-intext {
							
							margin-top: 0.5em;
							margin-left: 1em;
							padding-left: 0.5em;
							
							border-left: 1px solid $color_divider;
							
							@include breakpoint($breakpoint_desktop) {
								display: none;
							}
						}
					
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008c__block--description-primary a p {
						
						margin-bottom: 1em;
					}
					
					// tlacitko prehrat pro desktop
					.b-008c__button-play-wrapper--primary-bellowtext {
						display: none;
						
						@include breakpoint($breakpoint_desktop) {
							display: block;
						}
					}
		
		
		
		
		// nasledujici dva abstrakty
		.b-008c__list--column-following {
			
			// vaha pomerneho roztahovani po sirce
			flex: 2;
			
			// nicmene zaroven flexuje vlastni obsah
			display: flex;
			flex-direction: column;
		}
			
			.b-008c__list-item--column-following {
				
				margin-bottom: 2em;
				
			}
				
				.b-008c__block--description-following {
				}
					
					.b-008c__subblock--following {
						display: flex;
						flex-direction: row;
						align-items: flex-end;
					}
					
					// TODO bud specifikovat pro following nebo zobecnit
					.b-008c__button-play-wrapper--following {
						margin-left: 2em;
					}