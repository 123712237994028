//
// a-006 Styles
//



.a-006 {
}

	.a-006__list {
		//margin-top: -0.5em; // kvuli .a-006__list-item-link
	}

		// ovalujici link celeho itemu
		.a-006__list-item-link {
			display: block;

			//padding-top: 0.5em;
			//padding-bottom: 0.5em;

			padding-bottom: 1em;
		}
		.a-006__list-item-link:last-child {
			padding-bottom: 0;
		}
		.a-006__list-item-link:hover {
			text-decoration: none;

			.button {
				text-decoration: none;
				opacity: 0.8;
			}
		}


		// obecny item (vlastnosti v toku vlevo i vpravo)
		.a-006__list-item {
			display: flex;
			flex-direction: row;
		}

			.a-006__block--image {
				flex-shrink: 0;

				max-width: 5.5em;

				@include breakpoint($breakpoint_desktop) {
					max-width: 6em;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					position: relative;
				}
			}


			.a-006__block--description {
				padding-left: 0.75em;
				//padding-right: 0.75em;

				@include breakpoint($breakpoint_desktop) {
					//padding-left: 1em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.a-006__block--description h3 {
					margin: 0;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.a-006__block--description p {
					margin-top: 0.5em;
					margin-bottom: 0.5em;

					font-size: 16px;
					line-height: 1.3;

					color: $color_text_paragraph;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.a-006__block--description .button {
					display: inline-flex;
					background-color: $color_decoration_main;
					color: $color_text_accent_inverted;

					//margin-top: 0.5em;

					border: none;

					.button-box-flat__label {
						font-size: 14px;
					}

					.button-box-flat__icon.button-box-flat__icon--arrow-double-east {
						background-image: $file_icon_arrow_double_east_colored-white;
						width: 0.75em;
					}
				}





// vizualni debug - plab only
.patternlab .a-006 {
	max-width: 755px;
}
