//
// B-006h Styles
//



//vars
$b-006h_button-open-close_size: 2.5em;

$b-006h_dropdown_height: 2.5em;

//$b-006h_block_padding-vertical-desktop: 4.5em;
//$b-006h_block_padding-horizontal-desktop: 8.5em;
$b-006h_block_padding-top-mobil: 2.5em;
$b-006h_block_padding-bottom-mobil: 1.5em;
$b-006h_block_padding-horizontal-mobil: 0;

$b-006h_thumbnails_caption_font-size_mobile: 13px;
$b-006h_thumbnails_caption_font-size_desktop: 14px;


.b-006h {
	position: relative;
	overflow: hidden;
}

	// dropdown
	.b-006h__dropdown {
		display: flex;
		flex-direction: row;
		align-items: center;

		padding-left: 1em;
		padding-right: 2.5em;

		height: $b-006h_dropdown_height;

		background-color: $color_background-9;

		//transition: left 0.5s ease;

		@include breakpoint($breakpoint_desktop) {
			position: relative;
			left: 0;
			right: 0;

			height: $b-006h_dropdown_height;

			z-index: 1;
		}

		.button-popup {
			margin-left: auto;

			&.button-popup--open {
				position: absolute;
				left: 0;
				right: 0;

				.button-popup__label {
					float: right;
				}
			}

			&.button-popup--close {
				display: none;

				position: absolute;
				left: 0;
				right: 0;

				@include breakpoint($breakpoint_desktop) {
					position: relative;
					left: auto;
					right: auto;
				}

				.button-popup__label {
					float: right;

					@include breakpoint($breakpoint_desktop) {
						float: none;
					}
				}
			}

			.button-popup__label {
				// opticka zmena velikosti k specifickemu zarovnani s nadpisem boxu H2
				width: $b-006h_button-open-close_size;
				height: $b-006h_button-open-close_size;

				background-image: $file_icon_close_colored-white;
			}
		}

		> h3 {
			flex-grow: 1;

			color: $color_background-4;

			margin-top: 0;
			margin-bottom: 0;

			padding-left: 0;
			padding-right: 0;

			font-size: 17px;
			line-height: 21px;
			text-align: left;

			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			/*position: relative;
			top: 0;
			transition: all 0.5s ease;*/

			@include breakpoint($breakpoint_desktop) {
				font-size: 19px;
				line-height: 23px;
			}
		}

			> h3 a {
				@include link-style($color_background-4, none, $color_background-4, none);
			}


		&.expanded {
			padding-left: 0;
			padding-right: 0;

			@include breakpoint($breakpoint_desktop) {
				//position: absolute;
				left: 50%;
				right: 0;

				width: 50%;

				z-index: 1;
			}

			.button-popup {

				&.button-popup--open {
					display: none;
				}

				&.button-popup--close {
					display: block;
				}
			}

			h3 {
				display: none;
				/*top: -30px;
				opacity: 0;*/
			}
		}
	}

	// dropdown: inverted
	.b-006h--inverted .b-006h__dropdown {
		background-color: $color_background-1;

		.button-popup {

			.button-popup__label {
				background-image: $file_icon_close_colored-519fd7; // main color
			}
		}

		> h3 {
			color: $color_text_accent;
		}

			> h3 a {
				@include link-style($color_text_accent, none, $color_text_accent, none);
			}

	}

	// subsection
	.b-006h__subsection {
		background-color: $color_background-9;

		@include breakpoint($breakpoint_desktop) {
			position: relative;

			margin-top: -$b-006h_dropdown_height; // top by nechalo nechtenou mezeru
		}

		&.collapsed {
			display: none;
		}

	}

	// subsection: inverted
	.b-006h--inverted .b-006h__subsection {
		background-color: $color_background-1;
	}

		.b-006h__block {
			// neexituje vlastnost obecneho bloku
			// 1. blok je obsah (video a text)
			// 2. blok je wrapper tlacitek
		}

		.b-006h__block--content {
			display: flex;
			flex-direction: column;

			padding-top: calc(#{$b-006h_block_padding-top-mobil} - #{$b-006h_dropdown_height});
			padding-bottom: $b-006h_block_padding-bottom-mobil;

			//overflow: auto;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				align-items: center;

				padding: 0;
				//padding: $b-006h_block_padding-vertical-desktop $b-006h_block_padding-horizontal-desktop;
				//padding-top: calc(#{$b-006h_block_padding-vertical-desktop} - #{$b-006h_dropdown_height});
			}
		}

			.b-006h__subblock--player,
			.b-006h__subblock--slideshow {
				@include breakpoint($breakpoint_desktop) {
					flex-grow: unset; // prebiji .b-006__subblock--player

					width: 50%;

					z-index: 1; // aby pri zavirani sel pres .006g__dropdown
				}
			}

				.b-006h__list-slideshow {
					display: flex;
					flex-direction: row;

					overflow: hidden;
				}

					.b-006h__list-slideshow-item {
						position: relative;

						min-width: 100%;
					}


			.b-006h__subblock--description {
				display: block; // ie11
				//flex-direction: column;
				//align-items: center;

				@include breakpoint($breakpoint_desktop) {
					display: block; // ie11
					flex-basis: auto; // prebiji .b-006__subblock--description
					//flex-shrink: 1;

					width: calc(50% - 9em); // 50% - (margin-left + margin-right)

					//justify-content: center;
					//align-items: flex-start;

					margin-top: 2.5em;
					margin-bottom: 2em;
					margin-left: 4.5em;
					margin-right: 4.5em;
				}
			}

			.b-006h__subblock--webcam {
				//display: block; // ie11
				//flex-direction: column;
				//align-items: center;

				@include breakpoint($breakpoint_desktop) {
					align-self: stretch;

					display: flex;
					flex-direction: column;

					//display: block; // ie11
					flex-basis: auto; // prebiji .b-006__subblock--description
					//flex-shrink: 1;

					width: calc(50% - 9em); // 50% - (margin-left + margin-right)

					//justify-content: center;
					//align-items: flex-start;

					margin-top: 2.5em;
					margin-bottom: 2em;
					margin-left: 4.5em;
					margin-right: 4.5em;
				}
			}

				.b-006h__description {

					@include breakpoint($breakpoint_desktop) {
						flex-grow: 1;

						display: flex;
						flex-direction: column;
						justify-content: center;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h__subblock--description > h3,
				.b-006h__subblock--webcam .b-006h__description > h3 {
					color: $color_background-4;

					margin-top: 1.5em;
					margin-bottom: 1em;

					padding-left: 1em;
					padding-right: 1em;

					text-align: center;

					@include breakpoint($breakpoint_desktop) {
						font-size: 26px;
						//line-height: 1.25em;
						line-height: 36px;

						padding-left: 0;
						padding-right: 0;

						margin-top: 0;
						margin-bottom: 0;

						text-align: left;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h--inverted .b-006h__subblock--description > h3,
				.b-006h--inverted .b-006h__subblock--webcam .b-006h__description > h3 {
					color: $color_text_accent;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006h__subblock--description h3 a,
					.b-006h__subblock--webcam .b-006h__description h3 a {
						@include link-style($color_background-4, none, $color_background-4, underline);
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006h--inverted .b-006h__subblock--description h3 a,
					.b-006h--inverted .b-006h__subblock--webcam .b-006h__description h3 a {
						@include link-style($color_text_accent, none, $color_text_accent, underline);
					}


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h__subblock--description > a,
				.b-006h__subblock--webcam .b-006h__description > a {
					@include link-style($color_background-5, none, $color_background-5, none);
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h--inverted .b-006h__subblock--description > a,
				.b-006h--inverted .b-006h__subblock--webcam .b-006h__description > a {
					@include link-style($color_text_paragraph_node, none, $color_text_paragraph_node, none);
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h__subblock--description > p > a,
				.b-006h__subblock--webcam .b-006h__description > p > a {
					@include link-style($color_decoration_main, none, $color_decoration_main, underline);
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006h__subblock--description p,
					.b-006h__subblock--description a p,
					.b-006h__subblock--webcam .b-006h__description p,
					.b-006h__subblock--webcam .b-006h__description a p {
						margin: 0;

						padding-left: 1em;
						padding-right: 1em;

						text-align: center;

						@include breakpoint($breakpoint_desktop) {
							margin-top: 0.25em;
							padding-left: 0;
							padding-right: 0;

							text-align: left;
						}
					}

					// muze byt i mimo <a>
					.b-006h .b-006h__subblock--description p,
					.b-006h .b-006h__subblock--webcam .b-006h__description p {
						color: $color_background-5;
					}

					// muze byt i mimo <a>: inverted
					.b-006h--inverted .b-006h__subblock--description p,
					.b-006h--inverted .b-006h__subblock--webcam .b-006h__description p {
						color: $color_text_paragraph;
					}

					 .b-006h__subblock--description p.b-006h__meta {
						margin-top: 0.5em;
						color: $color_background-5;
					 }

					 .b-006h--inverted .b-006h__subblock--description p.b-006h__meta {
						color: $color_text_paragraph;
					 }


				// fix, resp. prebiti Martinova hacku a:hover > p z _box.scss
				// tady se chovalo nestandardne a poskakovalo
				.b-006h__subblock--description a:hover p,
				.b-006h__subblock--webcam .b-006h__description a:hover p { display: block; }


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h__subblock--description .tag {
					display: table;

					background-color: $color_background-4;
					color: $color_text_accent;

					@include typography_font(regular);
					font-size: 14px;
					line-height: 1.2;

					padding: 0.2em 0.4em;
					margin-top: 1em;
					margin-left: auto;
					margin-right: auto;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 2em;
						margin-left: 0;
						margin-right: auto;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h--inverted .b-006h__subblock--description .tag {
					background-color: $color_background-6;
					color: $color_text_accent;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006h__subblock--description .tag span {
						color: $color_text_accent;
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006h--inverted .b-006h__subblock--description .tag span {
						color: $color_text_accent_inverted;
					}


				.b-006h__meta--time {
					color: $color_background-4;

					@include typography_font(bold);
					font-size: 14px;
					line-height: 18px;
				}

				.b-006h--inverted .b-006h__meta--time {
					color: $color_text_paragraph_node;
				}

				.b-006h__meta--show {
					@include typography_font(regular);
					font-size: 14px;
					line-height: 18px;
				}

				// readmore
				.b-006h .button-box-flat--readmore {
					margin-top: 1em;
					margin-left: 1em;
					margin-right: 1em;

					@include breakpoint($breakpoint_desktop) {
						display: inline-flex;
						//align-self: flex-start;

						//margin-top: auto;
						margin-top: 2em;
						margin-left: 0;
						margin-right: auto;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h .button-box-flat--readmore {
					@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h--inverted .button-box-flat--readmore {
					@include link-style($color_text_link, none, $color_text_link, underline);
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h .button-box-flat--readmore {
					background-color: transparent;
					border: 1px solid $color_text_link_inverted;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006h--inverted .button-box-flat--readmore {
					border: 1px solid $color_text_link;
				}

					.b-006h .button-box-flat--readmore .button-box-flat__label {
						border-right-color: $color_text_link_inverted;

						@include breakpoint($breakpoint_desktop) {
							padding: 0.25em 0.5em;
						}
					}

					.b-006h--inverted .button-box-flat--readmore .button-box-flat__label {
						border-right-color: $color_text_link;
					}


				/*.b-006h .button-box-flat--readmore .button-box-flat__icon--arrow-double-east {
					background-image: $file_icon_arrow_double_east_colored-decoration-main;
				}*/

				.b-006h .button-box-flat--readmore .button-box-flat__icon--arrow-double-east,
				.b-006h .button-box-flat--readmore.button-box-flat--transparent-negative .button-box-flat__icon--arrow-double-east {
					background-image: $file_icon_arrow_double_east_colored-white;
				}

				.b-006h--inverted .button-box-flat--readmore .button-box-flat__icon--arrow-double-east,
				.b-006h--inverted .button-box-flat--readmore.button-box-flat--transparent-negative .button-box-flat__icon--arrow-double-east {
					background-image: $file_icon_arrow_double_east_colored-decoration-grey;
				}

				// nahledy dalsich kamer
				// * tento blok v pripade jedne kamery neni pritomen, resp. ma inline display: none
				.b-006h__webcam-thumbnail-list-wrapper {
					display: block; // *

					@include breakpoint($breakpoint_desktop) {
						margin-left: -2em;
						margin-right: -2em;
					}
				}

					.b-006h__webcam-thumbnail-list {
						margin-top: 3em;

						display: flex;
						// nahledy vzdy v radku jak v mobile tak v desktop
						// alespon zatim nez pribude nejaky touch slider system ala alda xmac
						flex-direction: row;

						@include breakpoint($breakpoint_desktop) {
							margin-top: 2em;
						}
					}

						// jeden nahledovy blok
						.b-006h__webcam-thumbnail-list-item {
							flex-basis: 33.3334%;
							overflow: hidden;

							margin-right: 1px;

							@include breakpoint($breakpoint_desktop) {
								margin-right: 1em;
							}
						}
						.b-006h__webcam-thumbnail-list-item:last-child {
							margin-right: 0;

							@include breakpoint($breakpoint_desktop) {
								margin-right: 0;
							}
						}

							// href obal
							.b-006h__webcam-thumbnail {

								@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
							}
							.b-006h__webcam-thumbnail.selected {
								// vlastnosti 'selected' definovany nize na detech
							}

								// z duvodu atomu chybejici BEM selektor
								.b-006h__webcam-thumbnail > h3 {
									margin: 0;
									margin-bottom: 5px;
									padding-left: 0.5em;
									padding-right: 0.5em;

									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;

									color: $color_background-5;

									@include adjust-font-size-to($b-006h_thumbnails_caption_font-size_mobile);

									@include breakpoint($breakpoint_desktop) {
										@include adjust-font-size-to($b-006h_thumbnails_caption_font-size_desktop);
									}
								}
								// z duvodu atomu chybejici BEM selektor
								.b-006h--inverted .b-006h__webcam-thumbnail > h3 {
									color: $color_text_paragraph;
								}
								// specifikace pokud je nadrazeny thumb 'selected'
								.b-006h__webcam-thumbnail.selected > h3 {
									// k pretizeni specifickou stanicni barvou
									color: $color_decoration_main;
								}

								.b-006h__webcam-thumbnail-image {
									position: relative; // priprava pro pseudotridni efekt selected nize
								}
								.b-006h__webcam-thumbnail-image > img {
									display: block;
								}
								// specifikace pokud je nadrazeny thumb 'selected'
								.b-006h__webcam-thumbnail.selected > .b-006h__webcam-thumbnail-image > img {
									//opacity: 0.3334;
								}
								.b-006h__webcam-thumbnail.selected > .b-006h__webcam-thumbnail-image::after {
									content: "";
									position: absolute;
									left: 0;
									top: 0;

									width: 100%;
									height: 10px;

									// k pretizeni specifickou stanicni barvou
									background-color: $color_decoration_main;
								}
								.b-006h__webcam-thumbnail.selected > .b-006h__webcam-thumbnail-image::before {
									content: "";
									position: absolute;
									left: 0;
									top: 0;
									right: 0;
									bottom: 0;

									width: 100%;
									height: 100%;

									background-color: $color_background-0;

									opacity: 0.3334;
								}
