//
// Button assistance
//

$fontsize_listactionbuttons: 14px;


.button-assistance {
	
	display: inline-block;
	width: 5.5em;
	height: 5.5em;
	
	margin: 0;
	padding: 0;
	
	background-size: 100%;
	background-position: 0;
	
	transition: opacity .3s;
	
	@include link-style($color_decoration_main, none, $color_decoration_main, underline);
	
	@include breakpoint($breakpoint_desktop) { 
		width: 3.5em;
		height: 3.5em;
	}
}
// spriteatlas hovering
.button-assistance:hover {
	opacity: .8;
}
	
	.button-assistance__label {
		
		// cely span popisek je ve vychozim stavu skryt a slouzi jen jako fallback pro obrazek
		display: none;
		
		align-items: center;
	
		height: 100%;
		margin-left: 100%;
		padding-left: 0.5em;
	
		font-size: $fontsize_listactionbuttons;
	}
	
	.button-listaction__label span {
		white-space: nowrap;
	}





//
// modifikatory - varianty buttonu
//

.button-assistance--alexa {
	
	background-image: $file_icon_amazon_alexa;
}


.button-assistance--home {
	
	background-image: $file_icon_google_home;
}