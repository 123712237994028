//
// B-007 Styles
//



.b-007 {
}

	.b-007 h2 {
		text-transform: uppercase;
	}

	.b-007__subsection {
		position: relative;

		overflow: hidden;
	}

		.b-007__list {
			display: flex;
			flex-direction: row;
		}

		// gradient
		.b-007__list.b-007__list--start::before,
		.b-007__list.b-007__list--middle::before,
		.b-007__list.b-007__list--middle::after,
		.b-007__list.b-007__list--end::before,

		.b-007__list.cro-carouselStart::before,
		.b-007__list.cro-carouselMiddle::before,
		.b-007__list.cro-carouselMiddle::after,
		.b-007__list.cro-carouselEnd::before {
			content: "";

			display: block;

			position: absolute;
			right: 0;

			width: 25%;
			height: 100%;

			@extend .bg-gradient-transparent-to-color-background-0;

			z-index: 2;

			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
		}

		.b-007__list.b-007__list--middle::before,
		.b-007__list.b-007__list--middle::after,

		.b-007__list.cro-carouselMiddle::before,
		.b-007__list.cro-carouselMiddle::after {
			width: 12.5%;
		}

		.b-007__list.b-007__list--middle::after,
		.b-007__list.b-007__list--end::before,

		.b-007__list.cro-carouselMiddle::after,
		.b-007__list.cro-carouselEnd::before {
			right: unset;
			left: 0;

			transform: rotate(180deg);
		}

			.b-007__list-item {
			}

			.b-007__list.b-007__list--start .b-007__list-item {
			}

			.b-007__list.b-007__list--middle .b-007__list-item {
			}

			.b-007__list.b-007__list--end .b-007__list-item {
			}

				.b-007__block--second {
					display: none;
				}

				.b-007__list-item--selected .b-007__block--second,
				.cro-carouselEl--selected .b-007__block--second {
					display: block;
				}

				.b-007__image {
					position: relative;
				}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-007 h3 {
					margin: 0;

					@include breakpoint($breakpoint_desktop) {
					}
				}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-007 h3 a {
				}


			.b-007__list-item:last-child {
			}


		.b-007__block--slider-buttons {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: row;
				justify-content: center;

				position: absolute;

				width: 100%;
				height: 100%;

				top: 0;
				left: 0;

				pointer-events: none;

				align-items: center;
				justify-content: space-between;

				z-index: 3;
			}
		}

			// oba buttony
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-007__block--slider-buttons .button-box-flat {
				// obnoveni neklikatelnosti rodice
				pointer-events: auto;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-007__block--slider-buttons .button-box-flat--slider-left {
				margin-right: 1em;

				@include breakpoint($breakpoint_desktop) {
					margin: 0;
					margin-left: -0.5em;
				}
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-007__block--slider-buttons .button-box-flat--slider-right {

				@include breakpoint($breakpoint_desktop) {
					margin-right: -0.5em;
				}
			}





//
// B-007a Styles
//

$b-007a_fontsize_h3: 15px;
$b-007a_fontsize_h3_desktop: 18px;
$b-007a_lineheight_h3: 1.1em;
$b-007a_lineheight_h3_desktop: 1.1em;

$b-007a_marginbottom_item: 0.85em;
$b-007a_marginbottom_item_desktop: 1.3em;



.b-007a {
}

	.b-007a h2 {
	}

	.b-007a__subsection {
		overflow: visible;
	}

		.b-007a__list {
			overflow: hidden;
			//overflow: visible; // kvuli pretekajicimu boxu s H3 Title
		}

			.b-007a__list-item {
				position: relative;

				// start
				//right: 0;    // first

				// middle
				//right: 70%;  // second (+70)
				//right: 150%; // third  (+80)
				//right: 230%; // fourth (+80)
				//right: 310%; // fifth  (+80)

				// end
				//right: 380%; // last   (+70)

				min-width: 80%;

				margin-bottom: $b-007a_marginbottom_item; // kvuli pretekajicimu boxu s H3 Title

				overflow: visible; // kvuli pretekajicimu boxu s H3 Title

				@include breakpoint($breakpoint_desktop) {
					// start
					//right: 0;     // first

					// middle
					//right: 39%;   // second (+39)
					//right: 83.5%; // third  (+44.5)
					//right: 128%;  // fourth (+44.5)

					//last
					//right: 167%;  // last   (+39)

					min-width: 44.45%;

					margin-bottom: $b-007a_marginbottom_item_desktop; // kvuli pretekajicimu boxu s H3 Title
				}
			}

			.b-007a__list.b-007a__list--start .b-007a__list-item {
				right: 0; // first

				@include breakpoint($breakpoint_desktop) {
					right: 0; // first
				}
			}

			.b-007a__list.b-007a__list--middle .b-007a__list-item {
				right: 70%; // second (+70)

				@include breakpoint($breakpoint_desktop) {
					right: 39%; // second (+39)
				}
			}

			.b-007a__list.b-007a__list--end .b-007a__list-item {
				right: 380%; // last (+70)

				@include breakpoint($breakpoint_desktop) {
					right: 167%; // last (+42.75-7.5)
				}
			}

				.b-007a__block--first  {
				}

					.b-007a__icon-photocounter {
						display: none;

						position: absolute;
						top: 0;
						right: 0;

						box-sizing: unset; // Drupal fix

						z-index: 1;
					}
					.b-007__list-item--selected .b-007a__icon-photocounter,
					.cro-carouselEl--selected .b-007a__icon-photocounter {
						display: block;
					}

				.b-007a__block--second {
					max-width: 65%;

					padding: 0.5em;

					position: absolute;
					right: 0.75em;
					bottom: -1em;

					background-color: $color_background-9_alpha-low;

					@include breakpoint($breakpoint_desktop) {
						max-width: 55%;
						padding: 0.75em 1em;

						right: 2.5em;
						bottom: -1.25em;
					}
				}

				.b-007a__image {
				}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-007a h3 {
					font-size: $b-007a_fontsize_h3;
					line-height: $b-007a_lineheight_h3;

					color: $color_text_accent_inverted;

					@include breakpoint($breakpoint_desktop) {
						font-size: $b-007a_fontsize_h3_desktop;
						line-height: $b-007a_lineheight_h3_desktop;
					}
				}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-007a h3 a {
					@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
				}


			.b-007a__list-item:last-child {

				@include breakpoint($breakpoint_desktop) {
				}
			}


	.b-007a__block--slider-buttons {

		@include breakpoint($breakpoint_desktop) {
			height: calc(100% - #{$b-007a_marginbottom_item_desktop});
		}
	}





//
// B-007b Styles
//

//vars
$b-007b_fontsize_p: 14px;
$b-007b_fontsize_p_desktop: 16px;
$b-007b_lineheight_p: 1.25em;
$b-007b_lineheight_p_desktop: 1.25em;
$b-007b_block-second_height: 4.5em;


.b-007b {
}

	.b-007b h2 {
	}

	.b-007b__subsection {
		overflow: visible;
	}

		.b-007b__list {
			display: flex;
			flex-direction: row;

			position: relative;

			overflow: hidden;
			//overflow: visible; // kvuli pretekajicimu boxu s H3 Title
		}

			.b-007b__list-item {
				position: relative;

				// start
				//right: 0;      // first

				// middle
				//right: 70%;  // second (+70)
				//right: 150%; // third  (+80)
				//right: 230%; // fourth (+80)
				//right: 310%; // fifth  (+80)

				// end
				//right: 380%; // last     (+70)

				min-width: 80%;
			}

			.b-007b__list.b-007b__list--start .b-007b__list-item {
				right: 0; // first
			}

			.b-007b__list.b-007b__list--middle .b-007b__list-item {
				right: 70%; // second (+70)
			}

			.b-007b__list.b-007b__list--end .b-007b__list-item {
				right: 140%; // last (+70)
			}

				.b-007__block--first {
					position: relative;
				}

				.b-007b__block--second {
					width: 125%;

					padding-top: 0.5em;

					position: relative;

					height: $b-007b_block-second_height; // pevna vyska, aby pri pohybu karuselem neskakaly boxy pod nim, a take kvuli pozicovani slider buttonu

					z-index: 3;
				}

				// kdyz ma galerie jen jeden item
				.b-007b__list-item:first-child:last-child .b-007b__block--second {
					width: auto;
				}

				.b-007b__list.b-007b__list--start .b-007__list-item--selected .b-007b__block--second,
				.b-007b__list.cro-carouselStart .b-007__list-item--selected .b-007b__block--second,

				.b-007b__list.b-007b__list--start .cro-carouselEl--selected .b-007b__block--second,
				.b-007b__list.cro-carouselStart .cro-carouselEl--selected .b-007b__block--second {
					left: 0;
				}

				.b-007b__list.b-007b__list--middle .b-007__list-item--selected .b-007b__block--second,
				.b-007b__list.cro-carouselMiddle .b-007__list-item--selected .b-007b__block--second,

				.b-007b__list.b-007b__list--middle .cro-carouselEl--selected .b-007b__block--second,
				.b-007b__list.cro-carouselMiddle .cro-carouselEl--selected .b-007b__block--second {
					left: -12.5%;
				}

				.b-007b__list.b-007b__list--end .b-007__list-item--selected .b-007b__block--second,
				.b-007b__list.cro-carouselEnd .b-007__list-item--selected .b-007b__block--second,

				.b-007b__list.b-007b__list--end .cro-carouselEl--selected .b-007b__block--second,
				.b-007b__list.cro-carouselEnd .cro-carouselEl--selected .b-007b__block--second {
					left: -25%;
				}

					.b-007b__image {
						position: relative;
					}

					.b-007b__icon-upscale {
						display: none;

						position: absolute;
						top: 0;
						right: 0;

						width: 2.25em;
						height: 2.25em;

						z-index: 1;

						@include breakpoint($breakpoint_desktop) {

							width: 3em;
							height: 3em;
						}
					}

					.b-007__list-item--selected .b-007b__icon-upscale,
					.cro-carouselEl--selected .b-007b__icon-upscale {
						display: block;
					}

						.b-007b__icon-upscale .button-popup--upscale .button-popup__label {
							width: 2.25em;
							height: 2.25em;

							background-size: 1.25em;

							transition: background-size 125ms;

							@include breakpoint($breakpoint_desktop) {
								width: 3em;
								height: 3em;

								background-size: 1.5em;
							}
						}
						.b-007b__icon-upscale .button-popup--upscale .button-popup__label:hover {
							background-size: 1.5em;

							@include breakpoint($breakpoint_desktop) {
								background-size: 1.75em;
							}
						}

					// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
					.b-007b p {
						margin: 0;
						padding: 0;

						font-size: $b-007b_fontsize_p;
						line-height: $b-007b_lineheight_p;

						color: $color_text_accent;

						@include breakpoint($breakpoint_desktop) {
							font-size: $b-007b_fontsize_p_desktop;
							line-height: $b-007b_lineheight_p_desktop;
						}
					}

					.b-007b p .b-007b__text-highlight,
					.b-007b p .b-007__text-highlight {
						margin-right: 0.5em;

						color: $color_text_accent;
					}

					// img-metadata
					.b-007b p .img-metadata__title {
						font-size: 0.875em;
					}

					.b-007b p .img-metadata__spacer {
					}

					.b-007b p .img-metadata__spacer--pipe {
					}

					.b-007b p .img-metadata__spacer--comma {
					}

					.b-007b p .img-metadata__author {
						font-size: 0.875em;
					}

					.b-007b p .img-metadata__source {
						font-size: 0.875em;
					}

					.b-007b p .img-metadata__licence {
						font-size: 0.875em;
					}


			.b-007b__list-item:last-child {

				@include breakpoint($breakpoint_desktop) {
				}
			}


	.b-007b .b-007__block--slider-buttons {

		@include breakpoint($breakpoint_desktop) {
			height: calc(100% - #{$b-007b_block-second_height});
		}
	}





// vizualni debug - plab only
.patternlab .b-007b {
	max-width: 700px;
}
.patternlab .b-007__list-item {
	position: relative;
}
.patternlab .b-007__list-item:nth-child(odd)::before,
.patternlab .b-007__list-item:nth-child(even)::before {
	content: "";

	position: absolute;

	width: 100%;
	height: 100%;

	opacity: 0.5;

	z-index: 1;
}
.patternlab .b-007__list-item:nth-child(odd)::before {
	background-color: $color_radiozurnal_main;
}
.patternlab .b-007__list-item:nth-child(even)::before {
	background-color: $color_vltava_main;
}
.patternlab .b-007__list-item--selected .b-007b__block--second,
.patternlab .cro-carouselEl--selected .b-007b__block--second {
	background-color: $color_background-0;
}
