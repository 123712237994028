//
// Radiozurnal - HP
//



.radiozurnal.page-radiozurnal-hp {

	/* prvni region .l-grid--12-1 */
	.l-grid--12-1.item-first {

		// prvni box
		.box:first-of-type {
		}

		// posledni box
		.box:last-of-type {
		}
	}

	/* prvni region .l-grid--8-1 */
	.l-grid--8-1.item-first {

		// prvni box
		section.box:first-of-type {
		}

		// box Z vysilani - oba
		.b-002g {
			p {
				margin-bottom: 0.5em;
			}
		}

		// box Z vysilani - prvni
		.b-002g--items-1 {
		}

		// box Z vysilani - druhy
		.b-002g--items-2 {
			margin-top: 1em;

			.b-002g__list-item {
				@include breakpoint($breakpoint_desktop) {
					width: 25%;
				}
			}

			h2 {
				display: none;
			}

			// dalsi ul po kliknuti na infinity scroll button
			.b-002g__list:not(:first-child) {
				margin-top: 1em;
			}
		}
	}

	/* prvni region .l-grid--4-9 */
	.l-grid--4-9.item-first {

		// b-038c programove tipy
		.b-038c {
			// skryje vsechny krome prvniho
			.b-038c__list-item:not(:first-child)  {
				display: none;
			}
			.b-038c__list-item  {
				border-bottom: none;
			}
		}
	}

	/* infinite scroll styles for more than 1 row */
	#b002g7 {
		.b-002g__list {
			@include breakpoint($breakpoint_desktop) {
				flex-wrap: wrap;
			}
		}
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 45%;
				margin-bottom: 1em;
			}
			&:nth-child(odd) {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}
		}
	}
}
