//
// Jazz - Ke stažení
//



.jazz.page-ke-stazeni {
	
	// samotny fulltext (bez filtru nad nim) nepotrebuje margin-top
	.view-filters form .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper {
		margin-top: 0;
	}
}