//
// Portal - Vlnohrani
//



.portal.page-node-9131284 {

	/* b-016 wrapper - defaultne neni nav, nechceme ani wrapper, ale tady naopak ano */
	.b-016__wrapper {
		display: flex;
	}

	// stanicni nav
	.b-016__wrapper {
		margin: 0;

		h1,
		h2 {
			background-color: $color_background-0;
			color: $color_background-9;
		}

		h1:before,
		h2:before {
			background-color: $color_background-9;
		}
	}

}
