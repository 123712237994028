//
// Timeline Styles
//



.b-timeline {
	@include typography_font(regular);

	&.box > h2 {
		padding-bottom: 0.75em;
		//color: $color_background-6;
		border-bottom: 1px solid $color_background-4;

		a {
			@include link-style($color_text_link, none, $color_text_link, underline);
		}
	}

	// timeline
	.tl-timeline {
		@include typography_font(regular);
		font-size: $base-font-size;
		line-height: $base-line-height;
		font-weight: unset;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@include typography_font(regular);

			a {
				@include link-style($color_text_link, none, $color_text_link, underline);
			}
		}

		// story
		.tl-storyslider {

			// media
			.tl-media {
				img,
				iframe {
					display: block;
				}
				.tl-media-shadow {
					box-shadow: none;
				}
				//player
				.file.file-audio.file-audio-mpeg {
					margin-left: 0;
				}
				.file-audio .sm2-playlist ul .filename {
					max-width: 100%;
				}

				// media credits
				.tl-credit {
					margin-top: 0.25em;
					margin-bottom: 1em;

					.img-metadata {
						margin-top: 0;

						.img-metadata__title,
						.img-metadata__spacer,
						.img-metadata__spacer--pipe,
						.img-metadata__spacer--comma,
						.img-metadata__author,
						.img-metadata__source,
						.img-metadata__licence, {
							font-size: 12px;
						}
					}
				}
			}

			// text
			.tl-text {
				padding: 0 2em;

				// date
				.tl-headline-date {
					font-size: 17px;
					font-weight: bold !important;
					padding: 0.25em 0;
					color: $color_background-6;
					line-height: 1;
				}

				// title
				.tl-headline {
					@include typography_font(medium);
					font-size: 24px;
					line-height: 1.2;
					text-transform: none;
					margin: 0;
					padding-left: 0;
					color: $color_text_accent;
					text-align: left;
				}

				// paragraph
				p {
					padding: 0.25em 0;
					color: $color_text_paragraph_node;
					line-height: 1.25;
				}
			}

			// buttons next, previous
			.tl-slidenav-next,
			.tl-slidenav-previous {
				.tl-slidenav-content-container {
					width: 65px;

					.tl-slidenav-icon {
						width: 2em;
						height: 2em;
						padding: 0.5em;
						margin: 0;

						background-color: $color_background-5;
					}
					&:hover .tl-slidenav-icon {
						background-color: $color_background-6;
					}
					.tl-slidenav-icon:before {
						content: '';
						display: block;

						width: 100%;
						height: 100%;
						margin: 0;
						background-position: center center;

						background-image: $file_button_icon_arrow_east_colored-white;
					}
					.tl-slidenav-title,
					&:hover .tl-slidenav-title {
						width: 85px;
						margin-left: 0;
						margin-right: 0;
						font-size: 12px;
						line-height: 1.2em !important;
						@include typography_font(regular);
						color: $color_decoration_main;
						text-shadow: unset;
						opacity: 1;
					}
					.tl-slidenav-description,
					&:hover .tl-slidenav-description {
						width: 65px;
						margin-left: 0;
						margin-right: 0;
						font-size: 12px;
						color: $color_background-6;
						opacity: 1;
					}
				}
			}

			// button next
			.tl-slidenav-next {
				right: 65px;
				margin-top: -50px; // posun kvůli vertikálnímu "vycentrování" - pozn. fuj fuj

				.tl-slidenav-content-container {
					.tl-slidenav-icon {}margin-left: 0;
						.tl-slidenav-icon:before {}
					.tl-slidenav-title,
					&:hover .tl-slidenav-title {
						margin-left: -20px;
						text-align: right;
					}
					.tl-slidenav-description {}
				}
			}

			// button previous
			.tl-slidenav-previous {
				margin-top: -50px; // posun kvůli vertikálnímu "vycentrování" - pozn. fuj fuj
				.tl-slidenav-content-container {
					.tl-slidenav-icon {}
						.tl-slidenav-icon:before {
							transform: rotate(180deg);
						}
					.tl-slidenav-title {}
					.tl-slidenav-description {}
				}
			}
		}

		// nav
		.tl-timenav {
			background-color: $color_background-1;
			border: none;
			border-top: 1px solid $color_background-3;

			.tl-timenav-slider {
				.tl-timenav-container-mask {
					.tl-timenav-container {
						.tl-timenav-item-container {
							.tl-timemarker,
							.tl-timemarker.tl-timemarker-active {
								.tl-timemarker-timespan {}

								// timemarker content
								.tl-timemarker-content-container {
									.tl-timemarker-content {

										// media
										.tl-timemarker-media-container {
											.tl-icon-image {}
										}

										// text
										.tl-timemarker-text {
											.tl-headline.tl-headline-fadeout {
												@include typography_font(medium);
												text-transform: none;
											}
										}
									}
								}
							}
							.tl-timemarker.tl-timemarker-active {
								.tl-timemarker-text {
									.tl-headline {
										color: $color_decoration_main;

									}
								}
							}

							.tl-timemarker {}
						}
					}
				}
			}

			// timeaxis
			.tl-timeaxis-background {
				background-color: $color_background-2;
			}

			/*.tl-timenav-container {
				position: relative;

				.tl-timemarker-active:before {
					content: '';
					display: block;

					width: 3px;
					height: 100%;

					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;

					background-color: red;

					z-index: 2;
				}

				.tl-timemarker-active:after {
					content: '';
					display: block;

					width: 11px;
					height: 11px;

					position: absolute;
					top: -11px;
					left: -4px;

					transform: rotate(45deg);

					background-color: white;

					z-index: 3;
				}

				.tl-timemarker-line-left:after {

				}
			}*/


			// Timeline JS copy
			.tl-attribution {
				display: none; // debug
			}
		}

		// menubar
		.tl-menubar {
			background-color: $color_background-0;
			border: 1px solid $color_background-3;

			// button
			.tl-menubar-button {
				color: $color_background-6;
				background-color: transparent;
			}

			.tl-menubar-button:hover {
				color: $color_background-9;
			}
		}
	}



	// fixes
	.tl-skinny .tl-slide {
		padding-top: 0;
	}
	.tl-skinny .tl-media .tl-media-content-container .tl-caption,
	.tl-skinny .tl-media .tl-media-content-container .tl-credit {
		padding-left: 0;
		padding-right: 0;
	}
	.tl-timeline .tl-timenav-slider h2.tl-headline {
		@include typography_font(medium);
	}
}
.timeline-asset-wrapper {
	.b-timeline .tl-timeline .tl-storyslider {
		.tl-slidenav-next,
		.tl-slidenav-previous {
			.tl-slidenav-icon {
				width: 1em;
				height: 1em;
				padding: 0.16em;
			}
		}
		.tl-slidenav-next {
			.tl-slidenav-icon {
				margin-left: 1.2em;
			}
		}
	}
}
//@include breakpoint($breakpoint_desktop) {}
