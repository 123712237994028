//
// Radio Junior - detska zed
//


$junior_selector_body-playlisty: $junior_selector_body + '.page-playlisty';
$junior_selector_body-program: $junior_selector_body + '.page-program';
$junior_selector_celodenni-zaznamy: 'body.program.page-zaznamy--radiojunior';
$junior_selector_hajaja: $junior_selector_body + '.page-node-7230824';



#{$junior_selector_body} {

	// topnav
	.b-014a {
		.b-014a__logo > a {
			pointer-events: none;
		} 
		.b-014a__list,
		.b-014a__list--addons {
			display: none;
		}
	}

	// station header
	.b-015 {

		.b-015__list-item {}

		.b-015__list-item--left {}

		.b-015__list-item--right {

			.b-015__launcher-wrapper {

				#cro-box-schedule-single {
					//flex-grow: 0;

					.b-015__launcher {
						&:hover {
							cursor: pointer;
						}

						.b-015__launcher-visual,
						.b-015__launcher-info {
							//display: none;
						}

						.b-015__launcher-button {}
					}
				}
			}

			@media (min-width: 960px) {
				.b-015__launcher-wrapper {
					//justify-content: flex-end;
				}
			}
		}
	}

	// social box
	.b-032b {
		display: none;
	}
	
	// mujRozhlas player
	.mujRozhlasPlayer {
		.mrp-holder__brand {
			.mrp-holder__logo {
				a.mrp-logo {
					pointer-events: none;
				}
			}
		}
		.mrp-holder__actions {
			display: none;
		}
	}

	// player
	.b-114 {

		.b-114__subsection {

			.b-114__launcher {
				background-color: $color_junior_add-2;
				border-radius: $junior_box_radius;

				.b-114__launcher-block {}

				.b-114__launcher-block--primary {

					.b-114__launcher-button-play {}

					.b-114__launcher-content {
						
						.b-114__launcher-title {
							font-family: $font-junior-medium;
							color: $color_junior_main;
						}

						.b-114__launcher-subtitle {}
					}
				}

				.b-114__launcher-block--secondary {

					.b-015__launcher-timing {

						.b-015__launcher-timing-timespan {
							font-size: 8px;
						}

						@media (min-width: 960px) {
							.b-015__launcher-timing-timespan {
								font-size: 10px;
							}
						}

						.b-114__launcher-timing-progressbar {
							background-color: $color_background-0;
							border-radius: $junior_box_radius;
							overflow: hidden;

							.b-114__launcher-timing-progressbar-duration {
							}
	
							.b-114__launcher-timing-progressbar-progress {
								background-color: $color_junior_add-7-dark;
							}
						}
					}

					.b-114__launcher-now-playing {
						background-color: $color_junior_main;
						border-radius: $junior_box_radius;

						.b-114__launcher-now-playing-icon {}

						.b-114__launcher-now-playing-text {}
					}

					.b-114__launcher-following {

						.b-114__launcher-following-label {}

						.b-114__launcher-following-title {}
					}

				}
			}

			.b-114__visual {
				border-radius: $junior_box_radius;
				overflow: hidden;
				
				@include breakpoint($breakpoint_desktop) {
					width: calc(33% - 10px);
					margin-left: 10px;
					//display: none; // vzhledem k umisteni v gridu
				}
			}
		}
	}

	// footer
	.footer-legal {

		.footer-legal__logo {

			.footer-legal__link {
				pointer-events: none;
			}
		}
	}

	// node
	&.page-node {
		// odebirejte podcast poradu
		.b-019a {
			display: none;
		}

		// vsechny dily poradu na mujRozhlas 
		.asset.a-005 {
			display: none;
		}

		// aside
		.l-grid--4-9.nth-of-type--1.item-first {
			// kolegove
			.b-005 {
				display: none;
			}
		}
	}

}


// playlisty
#{$junior_selector_body-playlisty} {

	// zamezeni prokliku na rozbaleni sluzeb Google, Spotify, YT, Tidal 
	.b-030 {
		.b-030__subsection--list {
			.b-030__list-item {
				.b030__list-item-row-info-wrapper {
					background-image: none;
					pointer-events: none;

					.b-030__item-info-wrapper {
						padding-right: 0;
					}
				}
			}
		}
	}

}


// program
#{$junior_selector_body-program} {

	// banner
	.b-novy-program {
		display: none;
	}

	// odkazy na audioarchiv poradu
	.b-038 {
		.b-038__list {
			.b-038__list-item {
				.b-038__block--description {
					.b-038__actions {
						display: none;
					}
				}
			}
		}
	}

	// aside
	.l-grid--4-9.nth-of-type--1.item-first {
		.button-box-flat--program {
			display: none;
		}
	}

}


// celodenni zaznamy
#{$junior_selector_celodenni-zaznamy} {

	// MR banner
	.l-grid--8-1.nth-of-type--1.item-first {
		.box:first-of-type {
			display: none;
		}
	}

	// aside
	.l-grid--4-9.nth-of-type--1.item-first {
		display: none;
	}

}


// Hajaja
#{$junior_selector_hajaja} {

	// zakazani prokliku tagu Dvojka
	.b-018 {
		.b-018__subsection {
			.broadcast-times {
				.broadcast-times__timetable {
					.broadcast-times__timetable-row {
						.broadcast-times__timetable-item--station {
							a.bg-color-main-dvojka {
								pointer-events: none;
							}
						}
					}
				}
			}
		}
	}

	// zakazani prokliku tagu Dvojka
	.b-020 {
		.b-020__subsection {
			.broadcast-times {
				.broadcast-times__timetable {
					.broadcast-times__timetable-row {
						.broadcast-times__timetable-item--station {
							a.bg-color-main-dvojka {
								pointer-events: none;
							}
						}
					}
				}
			}
		}
	}

}
