//
// Button Bean
//



// struktura obecneho bean tlacitka
.button-bean {
	display: inline-flex;
	align-items: center;

	@include link-style($color_decoration_main, none, $color_decoration_main, underline);

	@include adjust-font-size-to($fontsize_simple_button);

	padding: 0.25em 1em;

	border: 1px solid $color_decoration_main;
	border-radius: 25px;

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_simple_button_desktop);
	}

}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-bean:hover {
	opacity: 0.8;
}

	.button-bean__emblem {
		flex-shrink: 0;

		width: 1em;
		height: 1em;

		margin-right: 0.5em;

		background-repeat: no-repeat;
		background-position: left center;
	}

	.button-bean__label {
		flex-grow: 1;
	}

		.button-bean__label-nest {
			flex-grow: 1;

			display: block;
		}


	.button-bean__icon {
		flex-shrink: 0;

		width: 10px;
		height: 10px;
		margin-left: 0.25em;

		background-repeat: no-repeat;
		background-position: left center;

	}

		.button-bean__icon-textcontent {
			display: none;
		}





// inverzni varianta obecneho tlacitka
.button-bean--negative {
	border: 0;
	background-color: $color_decoration_main;
	@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-bean--negative:hover {
	opacity: 0.8;
}





// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-bean--negative:hover {
	opacity: 0.8;
}





// sipky
.button-bean__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-decoration-main;
	background-position: left 3px; // korekce
}
// obecna negativni varianta ikony
.button-bean--negative .button-bean__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-white;
	background-position: left 3px; // korekce
}

.button-bean__icon--arrow-south {
	background-image: $file_icon_arrow_double_east_colored-decoration-main;
	background-position: left 3px; // korekce

	// TODO chybejici ikona (ve variables je dosazena opacna)
	transform: rotate(90deg);
}

.button-bean__icon--arrow-single-south {
	background-image: $file_icon_arrow_single_south_colored-decoration-main;
	background-position: left 3px; // korekce
}
	.expanded .button-bean__icon--arrow-single-south,
	.button-bean__icon--arrow-single-south.expanded {

		// TODO chybejici ikona north, tak otacime a zarovnavame
		background-position: right center;
		transform: rotate(180deg);
	}
// obecna negativni varianta ikony
.button-bean--negative .button-bean__icon--arrow-single-south {
	background-image: $file_icon_arrow_single_south_colored-white;
	background-position: left 3px; // korekce
}

.button-bean__icon--arrow-single-east {
	background-image: $file_icon_arrow_single_east_colored-decoration-main;
	background-position: left 3px; // korekce

	// TODO cele tohle barveni svg je haluzni hack
	//filter: invert(0.5) sepia(1) hue-rotate(145deg);
}
// obecna negativni varianta ikony
.button-bean--negative .button-bean__icon--arrow-single-east {
	background-image: $file_icon_arrow_single_east_colored-white;
	background-position: left 3px; // korekce

	// TODO cele tohle barveni svg je haluzni hack
	//filter: none;
}



// specificke ikony
.button-bean__icon--reset {
	width: 14px;
	height: 14px;

	background-image: $file_icon_close-circle_colored-ca003a;
}



// specificke dekorace
// struktura tlacitka je emblem - label - ikona
// vizual emblemu a ikony je obecny, autonomni, znovupouzitelny, nenavazany na konkretni specificke tlacitko
.button-bean__emblem--audio-archive {
	background-image: $file_icon_badge_audio_colored-main;
}



// specificke modifikatory obecneho buttonu - neboli specificka tlacitka
// obcas neBEMove pretezovani protoze classovat to dle typu boxu by bylo silenstvi

// readmore
.button-bean--readmore {
}

// dropdown
.button-bean--dropdown {

	.button-bean__label {
		padding: 0.15em 0.5em;

		border-right: 0;

		text-align: left;
	}

}
