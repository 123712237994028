//
// B-038d Styles - specificke styly pro Drupal
//



.b-038d {
}

	.b-038d__list {
	}

		.b-038d__list-item {
		}

			.b-038d__list-item--highlight p {
				padding: 0.5em 0.75em;
				color: $color_text_accent_inverted;
			}

			.b-038d__js-link {
				color: $color_text_link;
				text-decoration: none;
			}
			.b-038d__js-link:hover {
				color: $color_text_link;
				text-decoration: underline;
			}
