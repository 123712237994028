//
// B-023 Styles
//



.b-023 {
}
	
	.b-023__list {
		
		display: flex;
		flex-direction: column;
		
		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}
		
		.b-023__list-item {
			
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			margin-bottom: 1.5em;
			
			@include breakpoint($breakpoint_desktop) {
				
				// mobile-first reset
				margin-bottom: 0;
				
				margin-right: 2em;
			}
			
			padding: 1em;
			
			background-color: $color_background-2;
			
		}
		.b-023__list-item:last-child {
			
			margin-bottom: 0;
			
			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
			
		}
			
			.b-023__block {
			}
			
			.b-023__block--description {
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-023__block--description h3 {
					margin: 0;
					padding-top: 0.5em;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-023__block--description p {
					margin-top: 0.5em;
					margin-bottom: 0.5em;
					color: $color_text_paragraph;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-023__block--description a p {
					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
				}
			
			
			.b-023__block--metainfo {
				
				display: flex;
				flex-direction: column;
				
				@include breakpoint($breakpoint_desktop) {
					
					flex-direction: row;
					justify-content: space-between;
					
					// i v desktopove velikosti muze dojit k tomu, ze se tlacitko vedle textu nevejde
					// pak je zalomeno na dalsi radek
					flex-wrap: wrap;
				}
				
				
				border-top: 1px solid $color_divider;
				
				padding-top: 1em;
				
			}
				
				.b-023__subblock {
					
					@include breakpoint($breakpoint_desktop) {
						
						// pokud je v desktopove velikosti tlacitko zalomeno na dalsi radek
						// tak je dobre mit grow definovany aby radek popisu i tlacitka
						// vyplnily celou sirku bloku
						flex-grow: 1;
					}
				}
				
				.b-023__subblock--metainfo-text {
					
					@include breakpoint($breakpoint_desktop) {
						
						padding-top: 0.25em;
						
						// pokud text vedle tlacitka, tak toto je vzduch mezi
						margin-right: 1em;
						// pokud text nad tlacitkem (wrap v desktop stavu), tak vzduch mezi
						padding-bottom: 0.5em;
					}
				}
					
					.b-023__metainfo-row {
						
						@include adjust-font-size-to($fontsize_box_petit_main);
						
						@include breakpoint($breakpoint_desktop) {
							@include adjust-font-size-to($fontsize_box_petit_main_desktop);
						}
					}
					// nepouzite specificke tridy radku
					.b-023__metainfo-row--timespan {
					}
					.b-023__metainfo-row--scheduled {
					}
						
						.b-023__metainfo-row-label {
							color: $color_text_paragraph;
						}
						
						.b-023__metainfo-row-value {
						}
				
				
				.b-023__subblock--metainfo-button {
					
					margin-top: 1em;
					
					@include breakpoint($breakpoint_desktop) {
						
						// mobile-first reset
						margin-top: 0;
					}
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-023__subblock--metainfo-button .button-box-flat--readmore {
						//margin-top: 0.5em;
					}







//
// B-023a Styles
//

.b-023a {
}
	// .b-023a__list specificky pro variantu "a" neni
		
		.b-023a__list-item {
			
			@include breakpoint($breakpoint_desktop) {
				
				flex-basis: 25%;
			}
		}
		
			.b-023a__image {
					
					position: relative;
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-023a__image .tag {
						
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
					}






//
// B-023b Styles
//

.b-023b {
}
	// .b-023b__list specificky pro variantu "b" neni
		
		.b-023b__list-item {
			
			@include breakpoint($breakpoint_desktop) {
				
				flex-basis: 33.333%;
			}
		}







//
// B-023c Styles
//
// pozor, az na pocet sloupcu identicke s b023d nize
//

.b-023c {
}
	
	.b-023c__list {
		
		@include breakpoint($breakpoint_desktop) {
			
			flex-wrap: wrap;
		}
	}
		
		.b-023c__list-item {
			
			background-color: $color_background-2;
			
			margin-bottom: 1.5em;
			
			padding: 1em;
			
			@include breakpoint($breakpoint_desktop) {
				
				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 1em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1em? protoze je to 1.5 * 2 / 3 viz (*)
				
				margin-right: 1.5em; // (*)
			}
		}
			
			.b-023c__block--description {
			}
				
				.b-023c__image {
					
					position: relative;
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-023c__image .tag {
						
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
					}
				
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-023c__block--description h3 {
					margin-top: 0.5em;
				}
		
		
		.b-023c__list-item:last-child {
			
			margin-bottom: 0;
			
			@include breakpoint($breakpoint_desktop) {
				
				margin-bottom: 1.5em;
			}
		}
		
		.b-023c__list-item:nth-child(3n+3) {
			
			@include breakpoint($breakpoint_desktop) {
				
				margin-right: 0;
			}
		}







//
// B-023d Styles
//
// pozor, az na pocet sloupcu identicke s b023c vyse
//

.b-023d {
}
	
	.b-023d__list {
		
		@include breakpoint($breakpoint_desktop) {
			
			flex-wrap: wrap;
		}
	}
		
		.b-023d__list-item {
			
			background-color: $color_background-2;
			
			margin-bottom: 1.5em;
			
			padding: 1em;
			
			@include breakpoint($breakpoint_desktop) {
				
				// trik jak 2 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud je jen jeden
				flex-basis: 45%; // toto neni polovine, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do poloviny
				max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(50% - 0.75em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1em? protoze je to 1.5 / 2 viz (*)
				
				margin-right: 1.5em; // (*)
			}
		}
			
			.b-023d__block--description {
			}
				
				.b-023d__image {
					
					position: relative;
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-023d__image .tag {
						
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
					}
				
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-023d__block--description h3 {
					margin-top: 0.5em;
				}
		
		
		.b-023d__list-item:last-child {
			
			margin-bottom: 0;
			
			@include breakpoint($breakpoint_desktop) {
				
				margin-bottom: 1.5em;
			}
		}
		
		.b-023d__list-item:nth-child(2n+2) {
			
			@include breakpoint($breakpoint_desktop) {
				
				margin-right: 0;
			}
		}