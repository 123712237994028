//
// Clipboard copyline
//
// button na kopirovani "neceho" do schranky
// napr. na sdileni URL, nebo pro copypastu kodu iframe pro vlozeni na svuj web apod
//



$clipboard-copyline_padding-vertical: 0.3334em;
$clipboard-copyline_padding-horizontal: 0.5em;

$clipboard-copyline_font-size: 14px;
$clipboard-copyline_vrythm-line: 0.75;



.clipboard-copyline {

	display: flex;
	flex-direction: row;
	align-items: center;

	padding: $clipboard-copyline_padding-vertical $clipboard-copyline_padding-horizontal;

	border: 1px solid $color_background-4;
	background-color: $color_background-2;


	@include adjust-font-size-to($clipboard-copyline_font-size, $clipboard-copyline_vrythm-line);

}

	.clipboard-copyline__code {

		position: relative;

		flex-grow: 1;

		white-space: nowrap;
		overflow: hidden;

		@include typography_font(regular);
	}
	// zakryvaci pseudo:gradient
	.clipboard-copyline__code::before {

		content: "";

		position: absolute;
		right: 0;
		top: 0;

		width: 3em;
		height: 100%;

		@extend .bg-gradient-transparent-to-color-background-2;
	}

	textarea.clipboard-copyline__code {

		min-height: 2em;
		height: 2em;

		border: none;
		background-color: $color_background-2;
	}

	.clipboard-copyline__button {

		margin-left: $clipboard-copyline_padding-horizontal;
		border-left: 1px solid $color_background-4;
		padding-left: $clipboard-copyline_padding-horizontal;
	}

		.clipboard-copyline__action-link {

			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}
