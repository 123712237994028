//
// Styles JV - menu
//

/*
 * @author Jaromír Navara [BG] <web@bagricek.cz>
 * _menu.scss
 * 26.8.2016 4:15
 */


// vars
//$jv-edgeOffset: 15px;



.jeziskova-vnoucata {

	.menu {
		position: relative;
		text-align: center;
		padding: 0;
		z-index: 1;

		// uprava pro novou hlavicku pro .top-header-v2
		// pokud bude navrat k puvodni, tak pryc
		@include breakpoint($breakpoint_desktop) {
			padding: 0 15px;
			padding: 0 25px; // kvuli nove hlavicce .top-header-v2)
		}

		>.container{
			padding: 0;
			height: 32px;
			line-height: 32px;
			vertical-align: middle;
		}

		#main-menu, #bottom-menu{
			.menu-nav{
				li:last-child{
					margin: 0 0 0 10px;
				}
			}
		}

		#main-menu{
			margin-bottom: 15px;
			.menu-nav{
				text-align: left;
			}
		}

		#bottom-menu{
			.menu-nav{
				justify-content: center;
				text-align: center;
				a{
					font-size: 15px;
				}
				li:last-child{
					//margin: 0 0 0 10px;
				}
			}
		}

		#main-menu, #nav_bottom, #bottom-menu{
			padding: 0;
		}
		@media screen and (max-width: 991px) {
			#nav_bottom{
				margin: 0 -15px;
			}

			#main-menu, #nav_bottom, #bottom-menu{
				margin: 0;
				border: 0;
				width: 100%;
				.menu-nav{
					height: 100%;
					display: flex;
					flex-direction: column;
					text-align: center;
					li{
						background: #FFF;
						width: 100%;
						margin: 0;
						height: 50px;
						line-height: 50px;
						vertical-align: middle;
						display: block;
						&.separator{
							height: 1px;
							background: rgba(0,0,0,0.1);
							margin: 0;
							padding: 0;
							opacity: 0.2;
						}
						&.lang{
							display: block;
						}
					}
				}
			}
			#main-menu, #bottom-menu{
				.menu-nav{
					li:last-child{
						margin: 0;
					}
				}
			}
			#bottom-menu{
				margin-bottom: 40px;
			}
			.navbar-collapse.collapse {
				display: none !important;
			}
			.navbar-collapse.collapse.in {
				display: block !important;
			}
			.navbar-header .collapse, .navbar-toggle {
				display:block !important;
			}
			.navbar-header {
				float:none;
			}
		}

		.navbar-header{
			>button{
				margin: 0 15px 0 0;
			}
			//height: 30px;
			.icon-bar{
				background: #000;
			}
		}
		.navbar-default{
			background: none;
			border: none;
			//height: 30px;
		}
		.menu-nav{
			display: flex;
			align-items: center;
			padding: 0;
			margin: 0;
			width: 100%;
			list-style: none;
			//height: 30px;
			vertical-align: middle;
			text-align: center;
			li{
				display: inline-block;
				margin: 0 8px;
				padding: 0;
				//height: 30px;
				font-weight: 300;
				font-size: 16px;
				font-size: 14px; // docasne, az bude final naplnene menu upravime
				text-transform: uppercase;
				a{
					color: #000;
				}
				&:last-child{
					border-left: none;
				}
				&:first-child{
					margin: 0 8px 0 0;
					@media screen and (max-width: 1200px) {
						margin-left: 0;
					}
				}
				@media screen and (max-width: 1200px){
					margin: 0 7px;
					font-size: 12px;
				}
			}
			.separator{
				opacity: 1!important;
				width: 1px;
				height: 15px;
				margin: 0;
				background: #000;
				//margin-top: 8px;
				&.lang{
					display: none;
				}
			}
			span.separator{
				display: inline-block;
				margin: 0 5px -3px 5px;
			}
		}
	}

}
