//
// B-052d Styles
//



$b-052d_item_fontsize_mobile: 14px;
$b-052d_item_fontsize_desktop: 18px;

$b-052d_item_background-arrow-size_mobile: 12px; // arrow mobile size
$b-052d_item_background-arrow-size_desktop: 16px; // arrow desktop size

$b-052d_block_radius_mobile: 25px;
$b-052d_block_radius_desktop: 50px;

$b-052d_subblock_width_desktop: 250px;
$b-052d_subblock_position-right_desktop: 25px;

$b-052d_list_position-left_desktop: 50px;

$b-052d_icon_size_desktop: 8em;
$b-052d_icon_position-left_desktop: 75px;

$b-052d_heading_font-size_desktop: 26px;



.b-052d {
}

	.b-052d__block {
		padding: 1.5em 1.75em;

		background-color: $color_background-6; // default bude prebita
		border-radius: $b-052d_block_radius_mobile;

		@include breakpoint($breakpoint_desktop) {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;

			padding: 2em;

			border-radius: $b-052d_block_radius_desktop;
		}
	}

		.b-052d__subblock {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: column;
				align-items: center;

				width: $b-052d_subblock_width_desktop;
				height: auto;

				margin-right: $b-052d_subblock_position-right_desktop;
			}
		}

			.b-052d__ico {
				@include breakpoint($breakpoint_desktop) {
					background-image: $file_icon_close_colored-white; // default k prebiti modifikatorem
					background-repeat: no-repeat;
					background-position: center center;
					background-size: auto $b-052d_icon_size_desktop;

					width: 100%;
					height: $b-052d_icon_size_desktop;
				}
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-052d__subblock > h3 {

				@include breakpoint($breakpoint_desktop) {
					margin-top: 0.5em;
					margin-bottom: 0;

					color: $color_text_accent_inverted;

					//@include adjust-font-size-to($trifork-poplatek_heading_font-size_desktop, 1.25);
					font-size: $b-052d_heading_font-size_desktop;
					line-height: 1.3;
					word-wrap: break-word; // jen na ultra uzkych viewportech
					text-transform: uppercase;
					text-align: center;
				}
			}


	.b-052d__list {

		@include breakpoint($breakpoint_desktop) {
			padding: 0.75em 0;
			padding-left: $b-052d_list_position-left_desktop;

			border-left: 1px solid $color_background-0_alpha-mid;
		}
	}

		.b-052d__list-item {
			position: relative;

			padding: 0.25em 0;

			@include breakpoint($breakpoint_desktop) {
				padding: 0.25em 0;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-052d__list-item > h3 {
				margin: 0;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-052d__list-item > h3 > a {
				position: relative;

				display: inline-block;
				width: 100%;

				padding-left: calc(2 * #{$b-052d_item_background-arrow-size_mobile}); // arrow mobile padding

				@include typography_font(medium);
				@include adjust-font-size-to($b-052d_item_fontsize_mobile);
				@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);

				// arrow bg
				background-image: $file_icon_arrow_double_east_colored-white;
				background-repeat: no-repeat;
				background-position: left center;
				background-position-x: left; // IE safe positioning
				background-position-y: center;
				background-size: $b-052d_item_background-arrow-size_mobile $b-052d_item_background-arrow-size_mobile; // arrow mobile size

				@include breakpoint($breakpoint_desktop) {
					padding-left: calc(2 * #{$b-052d_item_background-arrow-size_desktop}); // arrow desktop padding

					@include adjust-font-size-to($b-052d_item_fontsize_desktop, 1.5);

					// arrow bg
					background-size: $b-052d_item_background-arrow-size_desktop $b-052d_item_background-arrow-size_desktop;  // arrow desktop size
				}
			}


		// first item
		.b-052d__list-item:first-child {
			padding-top: 0;
		}

		// last item
		.b-052d__list-item:last-child {
			padding-bottom: 0;
		}






//
// barvy a ikonove varianty pomoci modifikatoru
//

.b-052d--households .b-052d__block {
	background-color: $color_corporate_add-2;
}

	.b-052d--households .b-052d__ico,
	.b-052d .b-052d__ico--households {

		@include breakpoint($breakpoint_desktop) {
			background-image: $file_icon_households_colored-white;
		}
	}


.b-052d--bussinesmen .b-052d__block {
	background-color: $color_corporate_add-1;
}

	.b-052d--bussinesmen .b-052d__ico,
	.b-052d .b-052d__ico--bussinesmen {

		@include breakpoint($breakpoint_desktop) {
			background-image: $file_icon_businessmen_colored-white;
		}
	}


.b-052d--legalentities .b-052d__block {
	background-color: $color_corporate_main;
}

	.b-052d--legalentities .b-052d__ico,
	.b-052d .b-052d__ico--legalentities {

		@include breakpoint($breakpoint_desktop) {
			background-image: $file_icon_legalentities_colored-white;
		}
	}





// vizualni debug - plab only
.patternlab .b-052d {

	@include breakpoint($breakpoint_desktop) {
		max-width: 770px;
	}
}
