//
// B-403 Styles
//



$b-403_h3_fontsize-mobile: 24px;
$b-403_h3_lineheight-mobile: 30px;
$b-403_h3_fontsize-desktop: 30px;
$b-403_h3_lineheight-desktop: 36px;

$b-403_p_fontsize-mobile: 14px;
$b-403_p_lineheight-mobile: 20px;
$b-403_p_fontsize-desktop: 16px;
$b-403_p_lineheight-desktop: 22px;


.b-403 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

	.b-403__block {
		position: relative;

		@include breakpoint($breakpoint_desktop) {
			max-width: 570px;
		}
	}

		.b-403__image {
		}

			.b-403__image--mobile {

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}

			.b-403__image--desktop {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}


		.b-403__content {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			padding: 1em;

			text-align: center;

			@include breakpoint($breakpoint_desktop) {
				padding: 2em 5em;
			}
		}

			.b-403__content h3 {
				margin: 0;

				font-size: $b-403_h3_fontsize-mobile;
				line-height: $b-403_h3_lineheight-mobile;

				@include breakpoint($breakpoint_desktop) {
					font-size: $b-403_h3_fontsize-desktop;
					line-height: $b-403_h3_lineheight-desktop;
				}
			}

			.b-403__block p {
				color: $color_text_paragraph;

				margin: 0;
				margin-top: 0.25em;

				font-size: $b-403_p_fontsize-mobile;
				line-height: $b-403_p_lineheight-mobile;

				@include breakpoint($breakpoint_desktop) {
					margin-top: 0.5em;

					font-size: $b-403_p_fontsize-desktop;
					line-height: $b-403_p_lineheight-desktop;
				}
			}






// specificke chovani v Drupal prostredi
.page-page-403 {
	.l-grid--12-1 {
		overflow: visible;

		.b-403 {
			margin-left: -10px; // .main - padding: 0 10px;
			margin-right: -10px; // . main - padding: 0 10px;
			padding: 1em 0;

			@include breakpoint($breakpoint_desktop) {
				margin-left: 0;
				margin-right: 0;
				padding: 2em 0;
			}
		}
	}
}





// vizualni debug - plab only
.patternlab .b-403 {

	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		//max-width: 400px;
	}
}
