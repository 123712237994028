//
// Contact
//



$contact_font-size_mobile: 18px;
$contact_font-size_desktop: 18px;



.contact {

	// pripadne odsazeni specificky v situaci pouziti, netreba mit obecne
	//margin-top: 0.5em;
	//margin-bottom: 0.5em;

	display: flex;
	flex-direction: row;
	align-items: center;

}

	.contact__icon {

		position: relative;

		flex-shrink: 0;

		display: inline-block;
		width: 1.75em;
		height: 1.75em;

		background-color: $color_decoration_main;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 0.85em auto;

		border-radius: 100%;
	}

	.contact__label,
	.contact__link {

		margin: 0;
		padding-left: 0.5em;

		@include typography_font(regular);

		color: $color_decoration_main;

		@include adjust-font-size-to($contact_font-size_mobile, 1);

		@include breakpoint($breakpoint_desktop) {

			@include adjust-font-size-to($contact_font-size_desktop, 1);
		}
	}

	.contact__link {
		@include link-style($color_decoration_main, none, $color_decoration_main, underline);
	}



// specificke varianty takovychto informativnich popisku
// neBEMove pretezovani vnitrku at se neuclassujeme

.contact--email {

	.contact__icon {

		background-image: $file_icon_at_colored-white;
	}

	.contact__label {
		color: $color_background-5; // seda
	}
}

.contact--phone-number {

	.contact__icon {

		background-image: $file_icon_phone-handset_colored-white;
	}
}

.contact--data-box {

	.contact__icon {

		background-image: $file_icon_data-box_colored-white;
	}
}
