//
// p
//



/*p {
	@include rhythm(1, 0, 0, 1);
}*/

p {
	@include adjust-font-size-to($fontsize_paragraph);
	@include rhythm(1, 0, 0, 1, $fontsize_paragraph);

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_paragraph_desktop);
		@include rhythm(1, 0, 0, 1, $fontsize_paragraph_desktop);
	}
}