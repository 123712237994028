//
// B-008h Styles
//
// blizka podobnost s boxem b008a, jen pouziti jineho elementu a to 2c
//



.b-008h {
}
	
	.b-008h__subsection {
	}
		
		.b-008h__list {
			
			display: flex;
			flex-direction: column;
			
			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
			}
		}
			
			.b-008h__list-item {
				
				margin-bottom: 2em;
				
				@include breakpoint($breakpoint_desktop) {
					
					margin-right: 2em;
					flex-basis: 25%;
				}
			}
			.b-008h__list-item:last-child {
				
				@include breakpoint($breakpoint_desktop) {
					
					margin-right: 0;
				}
			}
				
				.b-008h__block--image {
				}
					
					.b-008h__image {
						position: relative;
					}
						
						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-008h__image .tag {
							
							position: absolute;
							bottom: 0;
							left: 10px;
							
							max-inline-size: calc(100% - 20px);
						}
				
				
				.b-008h__block--fullwidth {
					
					padding-left: 0.5em;
					padding-right: 0.5em;
					
					@include breakpoint($breakpoint_desktop) {
						padding: 0;
					}
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008h__block--fullwidth .caption {
						
						margin-top: 0.75em;
					}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008h__block--fullwidth h3 {
						
						margin: 0;
						padding-top: 0.5em;
					}
				
				
				.b-008h__block--divided {
					
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					
					padding-left: 0.5em;
					padding-right: 0.5em;
					
					@include breakpoint($breakpoint_desktop) {
						padding: 0;
					}
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008h__block--divided a p {
						
						margin-top: 0.5em;
						margin-bottom: 0;
						
						color: $color_text_paragraph;
						@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
					}
					
					
					.b-008h__button-play-wrapper {
						margin-left: 2em;
					}