//
// H1
//



h1 {
	@include typography_font(bold);

	@include adjust-font-size-to($fontsize_h1);

	margin-top: 1em;
	margin-bottom: .75em;
	line-height: 3.3rem;

	color: $color_text_page_heading;

	@include breakpoint($breakpoint_desktop) {

		@include adjust-font-size-to($fontsize_h1_desktop);
	}
}
