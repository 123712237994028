//
// B-024 Styles
//



.b-024 {
}

	.b-024__subsection {

		padding: 1em;

		background-color: $color_background-2;

	}

		.b-024__block {
		}



		.b-024__block--description {
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-024__block--description h3 {
				margin-top: 0.25em;
				margin-bottom: 0.25em;
			}

			// popis
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			// prvni instance po jmenu poradu - proto specificka selekce s h3
			.b-024__block--description h3+p {
				margin-top: 0.25em;
				margin-bottom: 0.75em;
				color: $color_text_paragraph;
			}

			// odrazeny odstavec pred buttonem
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			// druha instance paragrafu - proto specificka selekce
			.b-024__block--description p+p {
				margin-top: 1em;
				margin-bottom: 0.5em;

				padding-top: 1em;

				border-top: 1px solid $color_divider;


				color: $color_text_paragraph;

				@include adjust-font-size-to($fontsize_box_petit_main);

				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_box_petit_main_desktop);
				}
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-024__block--description .button-box-flat--readmore {
				margin-top: 0.5em;
			}



// plab only
.patternlab .b-024 {
	max-width: 365px;
}
