//
// Radiozurnal - node id 8380655 (udalost 20 tvari 2020)
//



.radiozurnal.page-node-8380655 {

	h1.page-type--serial {
		display: none;
	}

	.l-grid--4-9.item-first,
  .l-grid--4-9.item-second {
		margin-top: 0;
	}

	.l-grid--8-1.item-second {

		.box.b-oprojektu {

			h2.pane-title {}

			.block__content {

				.field.body {
					padding: 0;
					margin: 0;

					p:first-of-type {
						margin-top: 0;
					}
				}
			}
		}
	}

	.l-grid--12-1.item-first {
		margin-bottom: 0;
	}
}
