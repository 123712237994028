//
// Label
//


// obecny label je definovan v html defaults pomoci selekce primo na tag <label>
.label {
}



// specificke labely
.label--default-text,
.label--input-text,
.label--textarea-text {
	
	display: block;
	
	margin-bottom: 0.25em;
}





// specificke labely
.label--checkbox-text {
	
	display: inline-block;
	
	text-transform: none;
}