//
// Svetluska - obecne vlastnosti vsech stranek
//



.svetluska {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_svetluska_main, $color_svetluska_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_svetluska_main);
	}

	// stanicni nav
	.b-016__wrapper {
		margin: 0;

		h1,
		h2 {
			//background-color: $color_background-0;
			//color: $color_background-9;
		}

		h1:before,
		h2:before {
			background-color: $color_background-8;
		}

		.b-016 {
			background-color: $color_background-8;

			/* barevnost listy a odkazu */
			.b-016__list {
				background-color: $color_background-8;

				.b-016__list-item {

					a {
						@include link-style ($color_background-4, none, $color_background-9, none);
					}

					a.expanded {
						background-color: $color_background-6;
					}

					a:hover,
					a.expanded:hover {
						background-color: $color_svetluska_main;
					}
				}

				.b-016__list-more {
					background-color: $color_background-6;

					a:hover {
						background-color: $color_svetluska_main;
					}
				}
			}

		}
	}


	// box
	/*.box {

		// titulek
		h2 {
			color: $color_svetluska_main;

      a {
				@include link-style ($color_svetluska_main, none, $color_svetluska_main, underline);
			}
		}
	}*/

}
