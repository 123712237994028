//
// Program - Tydenik
//



.program.page-tydenik {
	
	//
	// B-006a - Carousel
	// definice pro ostatní carousel tohoto boxu (Wave, Jazz) jsou v _carousel.scss
	//

	.b-006__subsection.cro-carouselVideo.inited {
		position: relative;

		.b-006__list {
			display: flex;
			flex-direction: row;
			list-style-type: none;

			overflow: hidden;
		}

		.b-006__list-item {
			position: relative;

			min-width: 100%;
		}
	}
}