//
// Wave - Starter
//



.wave.page-node-5183274 {
	// body o starteru
	.o-starteru .body {
		@include breakpoint($breakpoint_desktop) {
			margin: 0;
			padding: 0;
		}
	}

	// button
	.l-grid--8-1 .button-box-flat--readmore {
		@include breakpoint($breakpoint_desktop) {
			display: inline-flex;
		}
	}
}
