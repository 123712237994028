//
// Vltava - Hrana
//



.vltava.page-node-8970372 {

	/* defaults */
	.main {
			background-color: transparent;
	}

	.box {
		h2 {
			color: $color_background-9;
		}

		h2 a {
			@include link-style($color_background-9, none, $color_background-9, underline);
		}
	}



	/* grid */
	.l-grid--8-1.nth-of-type--1 {

		/* b-100b */
		.b-100b {

			> h2 {
				display: none;
			}

			.b-100b__episode-name-wrap .tag,
			.b-100b__episode-name-wrap h3 {
				background-color: initial;
				padding: 0;
				text-transform: uppercase;
				//word-spacing: 100vw;
			}

			.b-100b__episode-wrap:hover a.b-100b__episode-item .b-100b__episode-name-wrap h3,
			.b-100b__episode-wrap:hover a.b-100b__episode-item .b-100b__episode-name-wrap .tag {
				background-color: initial;
			}
		}
	}
}
