//
// B-027 Styles
//



$b-027_row-height: 2.25em;
$b-027_row-height_desktop: 1.75em;
$b-027_background_color: $color_background-0; // nutne specikovat pro urezavani ruzku pomoci CSS



.b-027 {
}
	
	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.b-027 h3 {
		margin-bottom: 0.5em;
	}
	
	// z semantickeho hlediska se jedna o tabulku polozek a jejich hodnot
	// css vlastnosti nejsou zachovany, je transformovano v flexboxovy progressbar
	.b-027__table {
		display: block;
	}
		
		.b-027__table-body {
			display: block;
		}
			
			.b-027__row {
				
				display: flex;
				align-items: stretch;
				
				height: $b-027_row-height;
				margin-bottom: 1px;
				
				@include breakpoint($breakpoint_desktop) {
					height: $b-027_row-height_desktop;
				}
			}
				
				.b-027__column-name {
					
					flex-basis: 33%;
					
					display: flex;
					align-items: center;
					
					padding-left: 0.75em;
					border-radius: $b-027_row-height / 2 0 0 $b-027_row-height / 2;
					
					
					// korporatni default barevnost
					// pripraveno k prebiti pomoci dalsi tridy, ktera zajisti stanicni obarveni dle rodice
					// viz tridy v html
					background-color: $color_corporate_main;
					
					@include breakpoint($breakpoint_desktop) {
						border-radius: $b-027_row-height_desktop/2 0 0 $b-027_row-height_desktop/2;
					}
				}
					
					.b-027__item-title {
						
						width: 100%;
						
						padding-right: 0.5em;
						border-right: 1px solid $color_text_accent_inverted;
						
						line-height: 1.25em;
						color: $color_text_accent_inverted;
						
						white-space: nowrap;
						
						@include breakpoint($breakpoint_desktop) {
							line-height: 1em;
						}
					}
				
				
				.b-027__column-value {
					
					flex-basis: 66%;
					
					display: flex;
					align-items: stretch;
					
				}
					
					.b-027__item-bar {
						
						position: relative;
						
						display: flex;
						align-items: center;
						justify-content: flex-end;
						
						// default - je prebijeno inline, protoze se jedna o prepocet procent sirky
						width: 50%;
						
						// korporatni default barevnost
						// pripraveno k prebiti pomoci dalsi tridy, ktera zajisti stanicni obarveni dle rodice
						// viz tridy v html
						background-image: linear-gradient(90deg, $color_corporate_main, $color_corporate_add-1);
					}
					// CSS zkosene ruzky
					.b-027__item-bar:before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						width: 0;
						border-top: $b-027_row-height/2 solid $b-027_background_color;
						border-left: $b-027_row-height/2 solid transparent;
						
						@include breakpoint($breakpoint_desktop) {
							border-top: $b-027_row-height_desktop/2 solid $b-027_background_color;
							border-left: $b-027_row-height_desktop/2 solid transparent;
						}
					}
					.b-027__item-bar:after {
						content: '';
						position: absolute;
						bottom: 0;
						right: 0;
						width: 0;
						border-bottom: $b-027_row-height/2 solid $b-027_background_color;
						border-left: $b-027_row-height/2 solid transparent;
						
						@include breakpoint($breakpoint_desktop) {
							border-bottom: $b-027_row-height_desktop/2 solid $b-027_background_color;
							border-left: $b-027_row-height_desktop/2 solid transparent;
						}
					}
						
						.b-027__item-value {
							
							padding-right: 1em;
							
							color: $color_text_accent_inverted;
							
							@include breakpoint($breakpoint_desktop) {
								@include adjust-font-size-to($fontsize_h3_desktop);
							}
						}
							
							.b-027__item-value-numeric {
								font-weight: bolder;
								margin-right: 0.25em;
							}