//
// Informace - obecne vlastnosti vsech stranek
//



.informace {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_informace_main, $color_informace_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_informace_main);
	}

	// stanicni nav
	.b-016__wrapper {

		h1,
		h2 {
			background-color: $color_background-0;
			//color: $color_background-9;
		}

		h1:before,
		h2:before {
			background-color: $color_informace_main;
		}

		.b-016 {
			background-color: $color_informace_main;

			.b-016__list {
				background-color: $color_informace_main;

				.b-016__list-item {

					a {
						//@include link-style(#1a1a1e, none, #1a1a1e, none);
					}

					a.expanded,
					a:hover {
						background-color: $color_informace_main_darker;
					}
				}

				.b-016__list-more {
					background-color: $color_informace_main_darker;

					a:hover {
						background-color: $color_informace_main;
					}
				}
			}

		}
	}

	// box
	.box {

		// titulek
		h2 {
			color: $color_background-6;
			a {
				@include link-style ($color_background-6, none, $color_background-6, underline);
			}
		}
	}

	// b-001b
	.b-001b {
		.b-001b__list-item {
			.active {
	  		@include typography_font(medium);
			}
		}
	}

	// b-002g - legendarni znasilnovany box, idealne na kazdem webu jinak
	.b-002g {
		.b-002g__list {
			@include breakpoint($breakpoint_desktop) {
				flex-wrap: wrap;
			}
		}
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 45%;
				margin-bottom: 1em;
			}
			&:nth-child(odd) {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}
		}
	}

}
