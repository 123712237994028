//
// Button serviceaction - volani ext sluzby ikonou, pouziti napr. playlisty
//



$button-serviceaction_size: 34px;



.button-serviceaction {
	
	display: inline-block;
	width: $button-serviceaction_size;
	height: $button-serviceaction_size;
	
	margin: 0;
	padding: 0;
	
	background-size: 200%;
	background-position: 0;
	
	
}
// spriteatlas hovering
.button-serviceaction:hover {
	
	background-position: 100%;
	
	border: 1px solid $color_background-4;
	border-radius: 50%;
}
	
	.button-serviceaction__label {
		
		// cely span popisek je ve vychozim stavu skryt a slouzi jen jako fallback pro obrazek
		display: none;
	}





//
// specificke varianty buttonu
//

.button-serviceaction--google {
	
	background-image: $file_button_service_google;
}

.button-serviceaction--spotify {
	
	background-image: $file_button_service_spotify;
}

.button-serviceaction--youtube {
	
	background-image: $file_button_service_youtube;
}

.button-serviceaction--tidal {
	
	background-image: $file_button_service_tidal;
}