//
// 1968 - Pribehy
//



.domain-1968-rozhlas-cz.page-pribehy {

	// prvni box
	.box.b-004d {
		// prvni box leveho sloupce nepotrebuje default vertikalni odsazeni
		margin-top: 0em;
	}

	// samotny fulltext (bez filtru nad nim) nepotrebuje margin-top
	.view-filters form .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper {
		//margin-top: 0;
	}
}