//
// H3
//



h3 {
	@include typography_font(medium);

	@include adjust-font-size-to($fontsize_h3);
	@include rhythm(2, 0, 0, 1, $fontsize_h3);

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_h3_desktop);
		@include rhythm(2, 0, 0, 1, $fontsize_h3_desktop);
	}
}
