//
// B-041n Styles
//



.b-041n {
}

	.b-041n__block {
		position: relative;
	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-041n__block p {
			margin: 0;
			padding: 0.75em 1em 0 1em;

			font-size: 12px;
			line-height: 16px;

			color: $color_text_paragraph;

			@include breakpoint($breakpoint_desktop) {
				padding: 0.75em 0 0 0;

				font-size: 14px;
				line-height: 20px;
			}
		}

		// obalujici link promo a item casti
		.b-041n__link {
			display: block;
		}

			.b-041n__link:hover {
				text-decoration: none;

				.button {
					text-decoration: none;
					opacity: 0.8;
				}
			}


		.b-041n__promo {
		}

			.b-041n__promo--description {
				position: relative;

				padding: 1em 1em 1em 1em;

				background-color: $color_background-2;
				border-bottom: 4px solid $color_background-0;

				z-index: 1;

				@include breakpoint($breakpoint_desktop) {
					padding: 1.25em 1.25em 1em 1.25em;
				}
			}

			.b-041n__promo--description::before {
				content: "";

				position: absolute;
				left: 30px;
				bottom: -15px;

				width: 25px;
				height: 25px;

				transform: rotate(45deg);

				background-color: $color_background-2;
				border-right: 4px solid $color_background-0;
				border-bottom: 4px solid $color_background-0;

				z-index: -1;
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041n__promo--description h3 {
					margin-top: 0;
					margin-bottom: 0.25em;

					@include typography_font(medium);
					font-size: 18px;
					line-height: 22px;

					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: 22px;
						line-height: 26px;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041n__promo--description p {
					padding: 0;
				}


			.b-041n__promo--image {
			}

				.b-041n__promo--image img {
					display: block;
				}


		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-041n__item {
			position: relative;

			display: flex;
			flex-direction: row;

			padding: 0 1em 1em 1em;

			background-color: $color_background-2;
			border-top: 4px solid $color_background-0;

			@include breakpoint($breakpoint_desktop) {
				padding: 0 1.25em 1.25em 1.25em;
			}
		}

			.b-041n__item--image {
				flex-shrink: 0;

				width: 120px;
				margin-top: -35px;

				@include breakpoint($breakpoint_desktop) {
					width: 160px;
					margin-top: -45px;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					position: relative;
				}
			}


			.b-041n__item--description {
				margin-top: 10px;
				padding-left: 0.75em;
				padding-right: 0.75em;
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041n__item--description h3 {
					margin: 0;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041n__item--description .button {
					display: inline-flex;
					background-color: $color_decoration_main;
					color: $color_text_accent_inverted;

					margin-top: 0.5em;

					border: none;

					.button-box-flat__icon.button-box-flat__icon--arrow-double-east {
						background-image: $file_icon_arrow_double_east_colored-white;
					}
				}





// vizualni debug - plab only
.patternlab .b-041n {
	max-width: 450px;
}
