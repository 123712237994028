//
// B-008e Styles
//
// blizka podobnost s boxem b008a - zahodno zvazit zda zmeny nezanaset spolecne
// protoze ale dalsi varianty b008 tyto podobnosti nesdili, doslo u b008 k specifickemu roztristeni stylopisu
//



.b-008e {
}
	
	.b-008e__list {
		
		display: flex;
		flex-direction: column;
	}
		
		.b-008e__list-item {
			
			margin-bottom: 2em;
		}
		.b-008a__list-item:last-child {
			
			margin-bottom: 0;
		}
			
			.b-008e__block--image {
			}
				
				.b-008e__image {
					position: relative;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008e__block--image .tag {
					position: absolute;
					bottom: 0;
					left: 10px;
					
					max-inline-size: calc(100% - 20px);
				}
			
			
			.b-008e__block--description {
				display: flex;
				flex-direction: row;
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008e__block--description h3 {
					flex-grow:1;
					margin: 0;
					padding-right: 0.75em;
					padding-top: 0.75em;
				}
				
				.b-008e__button-play-wrapper-pipe {
					border-left: 1px solid $color_divider;
					padding: 0 0 0 0.75em;
					margin: 0.75em 0 0 0;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008e__button-play-wrapper-pipe .button-play {
				}