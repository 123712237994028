//
// B-034 Styles
//



.b-034 {
}

	.b-034__list {
	}

		.b-034__list-item {
		}


	.b-034 .button-box-flat--dropdown {
		background-color: $color_background-3;

		border: 0;

		@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);

		.button-box-flat__label {
			@include typography_font(medium);
		}

		.button-box-flat__icon--arrow-single-south {
			background-image: $file_icon_arrow_single_south_colored-grey;
		}
	}



// plab only
.patternlab .b-034 {
	max-width: 450px;
}
