//
// Temata - Priroda
//



.temata.page-priroda:not(.page-priroda-projekty) {

	// samotny fulltext (bez filtru nad nim) nepotrebuje margin-top
	.view-filters form .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper {
		margin-top: 0;
	}
}
