//
// Radio Junior - HP
//



#{$junior_selector_body-hp} {

	/**
	 * regiony
	 */

	// region 12-1
	.l-grid--12-1 {

		//obecne

		// prvni region 12-1
		&.item-first {}

		// druhy region 12-1
		&.item-second {}
	}

	// region 6-1
	.l-grid--6-1 {

		// obecne

		// prvni region 6-1
		&.item-first {}

		// druhy region 6-1
		&.item-second {}
	}

	// region 6-7
	.l-grid--6-7 {

		//obecne

		// prvni region 6-7
		&.item-first {}

		// druhy region 6-7
		&.item-second {}
	}

	// region 8-1
	.l-grid--8-1 {

		//obecne

		// prvni region 8-1
		&.item-first {}

		// druhy region 8-1
		&.item-second {}
	}

	// region 4-9
	.l-grid--4-9 {

		//obecne

		// prvni region 4-9
		&.item-first {}

		// druhy region 4-9
		&.item-second {}
	}



	/**
	 * boxy
	 */

	// b-002b
	.b-002b {

		.b-002b__list {

			.b-002b__list-item {

				.b-002b__block {

					.image {

						img {
							border-radius: $junior_box_radius $junior_box_radius 0 0;
						}

						@include breakpoint($breakpoint_desktop) {
							border-radius: $junior_box_radius;
						}
					}

					.b-002b__overlay {

						.b-002b__overlay-content {

							.tag {

								@include breakpoint($breakpoint_desktop) {
									margin-left: 10px;
								}
							}

							h3 {
								border-radius: 0;

								@include breakpoint($breakpoint_desktop) {
									border-radius: $junior_box_radius $junior_box_radius 0 0;
									background-color: $color_junior_main-tag_darker_alpha-low;
								}

								a {
									@include breakpoint($breakpoint_desktop) {
										@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
									}
								}
							}
						}
					}
				}

				.b-002b__block.b-002b__block--banner {

					.image {

						img {
							border-radius: $junior_box_radius;
						}
					}

					.b-002b__overlay {

						.b-002b__overlay-content {

							.tag {
								background-color: $color_junior_main-tag_darker_alpha-low;
								color: $color_junior_add-1;

								span {
									color: $color_junior_add-1;
								}

								a {
									@include link-style($color_junior_add-1, none, $color_junior_add-1, underline);
								}
							}
						}
					}
				}

				h3 {
					border-radius: 0 0 $junior_box_radius $junior_box_radius;

					background-color: $color_junior_add-2-dark;
					color: $color_junior_add-1;

					@include breakpoint($breakpoint_desktop) {
						border-radius: 0;
					}

					span {
						color: $color_junior_add-1;
					}

					a {
						@include link-style($color_junior_add-1, none, $color_junior_add-1, underline);
					}
				}

				&.b-002b__list-item--list-primary {
					margin-bottom: 0;
				}
			}
		}
	}

	// b-002d
	.b-002d {

		.b-002d__list {

			.b-002d__list-item {

				@include breakpoint($breakpoint_desktop) {
					margin-right: 2em;
				}

				.b-002d__block {
					display: flex;
					flex-direction: column;
					height: 100%;

					.image {

						img {
							border-radius: $junior_box_radius $junior_box_radius 0 0;
						}
					}

					h3 {
						flex-grow: 1;

						border-radius: 0 0 $junior_box_radius $junior_box_radius;
						background-color: $color_background-0;
						padding: 0.5em;

						a {}
					}
				}
			}

			.b-002d__list-item:last-child {

				@include breakpoint($breakpoint_desktop) {
					margin-right: 0;
				}
			}
		}
	}

	// b-004d
	.b-004d {

		.b-004d__list {

			.b-004d__list-item {

				.b-004__block {
					display: flex;
					flex-direction: column;
					height: 100%;

					.image {

						img {
							border-radius: $junior_box_radius $junior_box_radius 0 0;
						}
					}

					.b-004d__subblock {
						flex-grow: 1;

						border-radius: 0 0 $junior_box_radius $junior_box_radius;
						background-color: $color_junior_add-3_dark;
						padding: 0.5em;

						h3 {
							padding-top: 0;

							a {
								@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
							}
						}

						a {
							p {
								display: none;
								color: $color_text_accent_inverted;
								@include link-style($color_text_link_inverted, none, $color_text_link_inverted, none);
							}
						}
					}
				}
			}
		}
	}

}
