//
// B-012 Styles
//



.b-012 {
}

	.b-012 h2 {
		text-transform: uppercase;
	}

	.b-012__list {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		.b-012__list-item {
			margin-bottom: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 1em;
				margin-bottom: 0;
			}

			background-color: $color_background-2;
		}

			.b-012__block--second {
				padding: 1em;
			}

			.b-012__image {
				position: relative;
			}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-012__image .tag {
					position: absolute;
					bottom: 0;
					left: 10px;

					max-inline-size: calc(100% - 20px);
				}


			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-012 h3 {
				margin: 0;
				padding-top: 0.5em;
			}

			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-012 p {
			}

				.b-012 p a {
					@include typography_font(medium);
					@include link-style($color_text_link, underline, $color_text_paragraph_node, underline);
				}


		.b-012__list-item:last-child {
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}


	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	.b-012 .button-box-flat {
		margin-top: 3em;

		@include breakpoint($breakpoint_desktop) {
			display: inline-flex;
			float: right;
		}
	}





//
// B-012a Styles
//



// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
.b-012a__block--second .button-play {
	margin-top: 1em;
}

	.b-012a__block--second .button-play .button__label {
		// zobrazeni popisku (stylovani ve vychozim stavu skryteho popisku je vlastnosti atomu)
		display: flex;
	}





//
// B-012b Styles
//



// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
.b-012b__image .badges {
	position: absolute;
	right: 0;
	top: 0;
}




//
// B-012c Styles
//


.b-012c .b-012__block--second {
	padding: 2em;
}

	.b-012__image {
	}

		// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
		.b-012__image .tag {
			text-transform: uppercase;
		}

			// barvy stitku
			.b-012__image .tag {
				background-color: $color_corporate_main;

				&.radiozurnal {
					background-color: $color_radiozurnal_main;
				}
				&.dvojka {
					background-color: $color_dvojka_main;
				}
				&.vltava {
					background-color: $color_vltava_main;
				}
				&.plus {
					background-color: $color_plus_main;
				}
				&.wave {
					background-color: $color_wave_main;
				}
				&.d-dur,
				&.ddur {
					background-color: $color_d-dur_main;
				}
				&.jazz {
					background-color: $color_jazz_main;
				}
				&.region {
					background-color: $color_region_main;
				}
			}


	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	.b-012c h3 {
		margin: 0;
		padding: 0;

		font-size: 22px;
	}

	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	.b-012c p {
		margin: 0.25em 0 0 0;

		color: $color_text_paragraph;
	}
