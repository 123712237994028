//
// B-002e Styles
//



$b-002e_primary-heading_fontsize_mobile: 24px;
$b-002e_primary-heading_fontsize_desktop: 28px;

$b-002e_primary-heading_maxwidth_desktop: 80%;

$b-002e_element-spacing_desktop: 2em; // podle alese ma byt 30px, ale coz



.b-002e {
	display: flex;
	flex-direction: column;

	@include breakpoint($breakpoint_desktop) {
		flex-direction: row;
	}
}

	// obecny seznam
	.b-002e__list {
	}

		// obecny element
		.b-002e__list-item {
		}

			.b-002e__block {
				position: relative;
			}

				.b-002e__image {
				}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002e__image .badge {
						position: absolute;
						top: 0;
						right: 0;
					}

					// buttonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002e__image .button-video-play-box {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}


				.b-002e__overlay {
					position: absolute;
					bottom: 0;
					left: 10px;
					right: 10px;
				}

					.b-002e__overlay-content {
					}

						// tag, (h3)



	// pseudoseznam s jednim (prvnim) elementem
	.b-002e__list--primary {
		@include breakpoint($breakpoint_desktop) {
			flex-basis: 54%;

			margin-right: $b-002e_element-spacing_desktop;
		}
	}

		// specifika: prvni velky element
		.b-002e__list-item--list-primary {

			margin-bottom: 1.2em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}

			// obecne vlastnosti nadpisu prvniho elementu
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002e__list-item--list-primary h3 {

				margin: 0;
				padding: 0.5em;

				background-color: $color_background-2;

				@include adjust-font-size-to($b-002e_primary-heading_fontsize_mobile, 1.25);

				@include breakpoint($breakpoint_desktop) {

					clear: both;
					display: inline-block;
					float: left;

					@include adjust-font-size-to($b-002e_primary-heading_fontsize_desktop, 1.25);
				}
			}

			.b-002e__overlay--item-list-primary {
			}

				// nadpis uvnitr overlay - pouze pro desktop
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002e__overlay--item-list-primary > .b-002e__overlay-content > h3 {

					display: none;

					@include breakpoint($breakpoint_desktop) {

						display: block;

						max-width: $b-002e_primary-heading_maxwidth_desktop;
						padding: 0.75em 1em;

						background-color: $color_background-2_alpha-low;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002e__overlay--item-list-primary .tag {

					display: inline-block;
					float: left;
				}


			// druha instance nadpisu mimo overlay - pouze pro mobile
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002e__list-item--list-primary > h3 {

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}



	// seznam nasledujicich elementu
	.b-002e__list--following {

		@include breakpoint($breakpoint_desktop) {
			flex-basis: 46%;
		}
	}

		// specifika: nasledujici elementy (vsechny)
		.b-002e__list-item--list-following {

			margin-bottom: 0.5em;

			@include breakpoint($breakpoint_desktop) {

				margin-right: 0;
				margin-bottom: 1em;
			}
		}

		// drupal fix
		.b-002e__list-item--list-wrapper {
			margin-bottom: 0;
		}

			.b-002e__list-item--list-following .b-002e__image {
					//display: none;

					@include breakpoint($breakpoint_desktop) {
						//display: block;
					}
				}

			/*.b-002e__overlay--item-list-following {
				position: relative;

				@include breakpoint($breakpoint_desktop) {
						position: absolute;
					}
			}*/

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002e__list-item--list-following h3 {

					margin: 0;
					padding: 0.5em 0.5em;

					@include breakpoint($breakpoint_desktop) {
						padding: 0.5em 0;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002e__list-item--list-following .tag {
				}

		.b-002e__list--secondary {

			display: flex;
			flex-direction: column;

			@include breakpoint($breakpoint_desktop) {
				flex-flow: row nowrap;
			}
		}

			.b-002e__list--secondary .b-002e__list-item--list-following {

				@include breakpoint($breakpoint_desktop) {
					flex-basis: 50%;

					margin-bottom: 0;
				}
			}

			.b-002e__list--secondary .b-002e__list-item--list-following:first-child {

				@include breakpoint($breakpoint_desktop) {
					margin-right: $b-002e_element-spacing_desktop;
				}
			}
