//
// A-005 Styles
//



.a-005 {
}

	.a-005__list {
		display: flex;
		flex-wrap: wrap;
	}

		.a-005__list-item {
			margin-right: 1px;
			margin-bottom: 1px;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0.5em;
				margin-bottom: 0.5em;
			}
		}
			.a-005__list-item:last-child {
				margin-right: 0;
			}

			.a-005__list-item .button-subscribe {
				border: 1px solid $color_divider;
				padding: 1em 2.3em 1em 3.6em;
			}
				.a-005__list-item .button-subscribe:before {
					width: 20px;
				}
					.a-005__list-item .button-subscribe:not(:hover) {
						background-color: $color_background-1;
					}
						.a-005__list-item .button-subscribe:not(:hover):before {
							background-image: $file_icon_arrow_single_east_colored-decoration_main;
						}





// vizualni debug - plab only
.patternlab .a-005 {
	max-width: 750px;
}
