//
// Wave - Czeching
//



.wave.page-czeching {

	// vertical menu na desktopu
	.l-grid--4-9.item-first .b-001b {
		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}

	// vertical menu na mobilu
	.l-grid--4-9.item-second .b-001b {
		display: block;

		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}
}