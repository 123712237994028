//
// Sever - HP
//



.sever.page-sever-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}