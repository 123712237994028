//
// B-041j Styles
//



.b-041j {
}

	.b-041j__list {
	}

		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-041j__list-item {
			display: flex;
			flex-direction: row;
		}

			.b-041j__block--image {
				flex-shrink: 0;

				max-width: 5.5em;
				padding-bottom: 1em;

				@include breakpoint($breakpoint_desktop) {
					max-width: 6em;
					padding-bottom: 1.25em;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					position: relative;

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.button-play {
						position: absolute;
						bottom: 0.35em;
						left: 0.35em;

						width: 2.25em;
						height: 2.25em;

						@include breakpoint($breakpoint_desktop) {
							width: 1.75em;
							height: 1.75em;
						}
					}
				}
			}


			.b-041j__block--description {
				padding-left: 0.75em;
				max-width: calc(100% - 5em - 0.5em); // kvuli stitkum, 5em je sirka obrazku, 0.5em je padding

				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041j__block--description .tag {
					margin-bottom: 0.5em;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041j__block--description h3 {
					@include adjust-font-size-to(14px);
					@include rhythm(2, 0, 0, 1, 14px);

					margin: 0;
					
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to(15px);
						@include rhythm(2, 0, 0, 1, 15px);

						margin: 0;
					}
				}
					// kdyz se odstrani pole autor, a je h3 posledni dite, chceme vetsi margin
					.b-041j__block--description h3:last-child {
						margin-bottom: 1em;
					}


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041j__block--description a p {
					margin-top: 0.5em;

					color: $color_text_paragraph;
					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041j__date {
					display: block;

					margin-bottom: 1em;

					color: $color_background-6;

					@include adjust-font-size-to(10px);

					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to(10px);
					}
				}





// vizualni debug - plab only
.patternlab .b-041j {
	
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}
