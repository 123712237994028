//
// B-018 Styles
//



.b-018 {
	
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}
	
	.b-018__subsection {
		
		padding: 1em;
		
		background-color: $color_background-2;
		
	}
		
		.b-018__block {
		}
		
		
		
		.b-018__block--description {
		}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-018__block--description h3 {
				margin-top: 0.25em;
				margin-bottom: 0.25em;
			}
			
			// popis
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			// prvni instance po jmenu poradu - proto specificka selekce s h3
			.b-018__block--description h3+p {
				margin-top: 0.25em;
				margin-bottom: 0.75em;
				color: $color_text_paragraph;
			}
			
			.b-018__timespan {
				@include adjust-font-size-to($fontsize_box_petit_main);
				
				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_box_petit_main_desktop);
				}
				margin: 1em 0;
			}
				
				.b-018__timespan-label {
					color: $color_text_paragraph;
				}
				
				.b-018__timespan-duration {
				}
			
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-018__block--description h4 {
				margin-top: 0.75em;
				margin-bottom: 0.25em;
			}
			
			// seznam redaktoru
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			// prvni instance po jmenu poradu - proto specificka selekce s h4
			.b-018__block--description h4+p {
				margin-top: 0.25em;
				margin-bottom: 0.5em;
				
				color: $color_text_paragraph;
				
				@include adjust-font-size-to($fontsize_box_petit_main);
				
				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_box_petit_main_desktop);
				}
			}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-018__block--description .button-box-flat--readmore {
				margin-top: 1em;
			}
		
		
		.b-018__block--no-broadcast {
			margin-top: 1em;
		}
			
			.b-018__no-broadcast-message {
				display: block;
				padding: 0.25em 1em;
				
				color: $color_text_accent_inverted;
				background-color: $color_decoration_alert;
				
				@include adjust-font-size-to($fontsize_box_petit_main);
				
				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_box_petit_main_desktop);
				}
			}
		
		
		
		
		.b-018__block--related {
			// puvodni styly dle navrhu
			/*margin-top: 1em;
			border-top: 1px solid $color_divider;*/
			// quickfix dvojite linky, neni cas
			margin-top: 2em;
		}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-018__block--related h5 {
				
				margin-top: 1em;
				margin-bottom: 0.5em;
				
				color: $color_text_paragraph;
				
				@include adjust-font-size-to($fontsize_box_petit_main);
				
				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_box_petit_main_desktop);
				}
			}
			
			.b-018__related {
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-018__related li a {
					
					@include link-style($color_decoration_main, none, $color_decoration_main, underline);
				}