//
// Node
//



$timer-bg: url('../images/icon/ico_timer.svg');
$node_color_text_lighter: #d7d6de;
$node_color_text_medium: #8b8993;

$node_left-margin: 4.375rem;
$node_padding: 1.875rem;

$file_icon_audio_expired: url('../images/icon/ico_alert_fff_line_d6d6de.svg');
$file_icon_audio_expired_yellow: url('../images/icon/ico_alert_f9c011.svg');
$file_icon_audio_future: url('../images/icon/ico_coming_soon.svg');
$file_ico_audio_future_green: url('../images/icon/ico_coming_soon_green_bcg.svg');
$color_background_player_custom_alpha: rgba($color_background-0, .9);

$node_messages_margin: 25px;
$node_messages_margin_mobile: 20px;

// odsazeni fieldu v plnem zneni
// je obecne a melo by byt definovanou promennou na jednom miste v node.scss ale neni
$node_field_padding-left: 30px;
$node_field_padding-right: 30px;
