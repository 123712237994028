//
// Udalost - Pochoutkovy rok
//



// udalost s id 7542424 na regionalnich webech
.region.pochoutkovy-rok {

	// vertikalni menu
	.b-001b {
		.b-001b__list-item.active-trail {
			display: none;
		}
	}
}
