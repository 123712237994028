//
// Specificke stanicni barveni elementu boxu
//
// nahrazuje bloky pretezovani ve stanicnich stylopisech
// opakuje se - pretezuji se shodne prvky
//



// prebarvovani obecne stranky
@mixin station_element-coloring($param_color_main, $param_color_main-darker) {

	// b-016 - nav
	.b-016__list-item a.active::after,
	.b-016__list-item a.expanded,
	.b-016__list-item a:hover,
	.b-016__list-more {
		background-color: $param_color_main;
	}
	.b-016__list-more .b-016__list-item a:hover {
		background-color: $param_color_main-darker;
	}

	// stanicni barveni titulku boxu opusteno
	/*.box h2 {
		color: $param_color_main;
		a {
			@include link-style($param_color_main, none, $param_color_main, underline);
		}
	}*/

	// b-006h - webcam
	.b-006h__webcam-thumbnail.selected > h3 {
		color: $param_color_main;
	}
	.b-006h__webcam-thumbnail.selected > .b-006h__webcam-thumbnail-image::after {
		background-color: $param_color_main;
	}

	// b-033a - webcam
	.b-033a__webcam-thumbnail.selected > h3 {
		color: $param_color_main;
	}
	.b-033a__webcam-thumbnail.selected > .b-033a__webcam-thumbnail-image::after {
		background-color: $param_color_main;
		background-image: linear-gradient(to bottom, $param_color_main 0 10px, rgba($color_background-0, 0.6666) 10px 100%);
	}
	.b-033a__subblock > h5 {
		color: $param_color_main;
	}
	.b-033a .progress-bar__position {
		background-color: $param_color_main;
	}
}



// prebarvovani node plneho zneni
@mixin station_element-coloring_node-type-article($param_color_main) {

	// defaultni barveni dle webu
	// stanicni barveni titulku assetu opusteno
	/*.asset h2 {
		color: $param_color_main;
		a {
			@include link-style($param_color_main, none, $param_color_main, underline);
		}
	}*/
	.asset .a-001a__block h3,
	.asset .a-001b__block h3 {
		background-color: $param_color_main;
	}


	.asset.a-002::before {
		background-color: $param_color_main;
	}
	// 10%
	.asset.a-002 .a-002__voting--10 svg {

		.svg-voting-1b { fill: $param_color_main; }
	}
	// 20%
	.asset.a-002 .a-002__voting--20 svg {

		.svg-voting-1a { fill: $param_color_main; }
	}
	// 30%
	.asset.a-002 .a-002__voting--30 svg {

		.svg-voting-1a,
		.svg-voting-2b { fill: $param_color_main; }
	}
	// 40%
	.asset.a-002 .a-002__voting--40 svg {

		.svg-voting-1a,
		.svg-voting-2a { fill: $param_color_main; }
	}
	// 50%
	.asset.a-002 .a-002__voting--50 svg {

		.svg-voting-1a,
		.svg-voting-2a,
		.svg-voting-3b { fill: $param_color_main; }
	}
	// 60%
	.asset.a-002 .a-002__voting--60 svg {

		.svg-voting-1a,
		.svg-voting-2a,
		.svg-voting-3a { fill: $param_color_main; }
	}
	// 70%
	.asset.a-002 .a-002__voting--70 svg {

		.svg-voting-1a,
		.svg-voting-2a,
		.svg-voting-3a,
		.svg-voting-4b { fill: $param_color_main; }
	}
	// 80%
	.asset.a-002 .a-002__voting--80 svg {

		.svg-voting-1a,
		.svg-voting-2a,
		.svg-voting-3a,
		.svg-voting-4a { fill: $param_color_main; }
	}
	// 90%
	.asset.a-002 .a-002__voting--90 svg {

		.svg-voting-1a,
		.svg-voting-2a,
		.svg-voting-3a,
		.svg-voting-4a,
		.svg-voting-5b { fill: $param_color_main; }
	}
	// 100%
	.asset.a-002 .a-002__voting--100 svg {

		.svg-voting-1a,
		.svg-voting-2a,
		.svg-voting-3a,
		.svg-voting-4a,
		.svg-voting-5a { fill: $param_color_main; }
	}


	// asset Ctete take (stary)
	.cteteTake,
	.body .cteteTake {
		> strong {
			color: $param_color_main;
		}
		> a {
			background-color: $param_color_main;
		}
	}
	// stejne tak asset Clanek ktery z predchazejiciho vizualne vychazi
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-article,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-event,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-recipe,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-serial,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-show {
		> h2::before {
			color: $param_color_main;
		}
		> h2 > a {
			background-color: $param_color_main;
		}
	}
}
