//
// page
//



.page {
	max-width: $layout_page_max-width;
	margin: 0 auto;
}
	
	
	.page > header {
		overflow: visible;
	}
	
	
	.main {
		background-color: $color_background-0;
		
		// zkusebne davam pryc protoze nevim k cemu to tu bylo a vadi mi to v tweakovani layoutu
		//overflow: hidden;
		
		padding: 0 $layout_main_horizontal-padding_mobile;
		
		@include breakpoint($breakpoint_desktop) {
			
			padding: 0 $layout_main_horizontal-padding_desktop;
		}
	}