//
// B-004 Styles
//



.b-004 {
}

	// obal dvou UL listu aby se mohli rozflexovat vedle sebe v pote nasledujicim pomeru
	.b-004__subsection {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		.b-004__list {

			// oba listy zaroven sami flexuji vlastni obsah
			display: flex;
			flex-direction: column;
		}

		// pro pripad varianty s infinity scroll buttonem
		.b-004__list:not(:first-of-type) {
			margin-top: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 2em;
			}
		}

			// obecny item (vlastnosti v toku vlevo i vpravo)
			.b-004__list-item {

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					position: relative;

					// tagy jsou zde u itemu s obrazkem dvoji, jeden navic v obrazku a jeden standardne
					// skryvaji se podle breakpointu
					.tag {
						position: absolute;
						bottom: 0;
						left: 10px;

						max-inline-size: calc(100% - 20px);

						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}

					// ikonove priznaky vzhledem k primarnimu obrazku
					.badge {
						position: absolute;
						top: 0;
						right: 0;
					}

					// buttonove priznaky vzhledem k primarnimu obrazku
					.button-video-play-box {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004__list-item h3 {
					margin: 0;
					padding-top: 0.5em;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004__list-item p,
				.b-004__list-item a p {

					margin-top: 0.5em;
					margin-bottom: 1.5em;

					color: $color_text_paragraph;
					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
				}


		.b-004__list--column-primary {

			flex: 5;

			margin-bottom: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0em;
			}
		}

			// abstrakt s obrazkem (vlevo)
			.b-004__list-item--column-primary {

				display: flex;
				flex-direction: column;

				background-color: $color_background-2;

				.b-004__block:nth-child(2) {
					padding-left: 0.5em;
					padding-right: 0.5em;

					.tag {
						display: none;
					}
				}

				@include breakpoint($breakpoint_desktop) {
					flex-direction: row;
					background-color: transparent;

					.b-004__block:nth-child(1) {
						flex: 3;
					}
					.b-004__block:nth-child(2) {
						flex: 2;

						padding-left: 1em;
						padding-right: 1em;

						.tag {
							display: inline-block;
						}
					}
				}

			}


		.b-004__list--column-following {

			flex: 2;
		}

			// nasledujici dva abstrakty
			.b-004__list-item--column-following {
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004__list-item--column-following .tag {
					float: left;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004__list-item--column-following .badge {
					float: right;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004__list-item--column-following h3 {
					clear: both;
				}






//
// B-004b Styles
//

// varianta b je default
// protoze byla udelana jako prvni (a obsahuje asi cose tyka slozitosti nejvice definic)
// ostatni jsou vicemene odvozene
// neni to asi uplne dobre, ale je to v tuhle chvili tak
.b-004b .b-004__list-item:last-child p {
	margin-bottom: 0;
}




//
// B-004c Styles
//
.b-004c {
}

	.b-004c__list--column-following {
		flex: none;  // ie fix - reset hodnoty z .b-004_list--column-folowing
	}

	.b-004c__list-item--column-following {
		display: flex;
		flex-direction: row;
	}

		.b-004c__block--image {
			flex-shrink: 0;

			max-width: 6em;
			//max-height: 6em;

			//padding-bottom: 1em;
			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				max-width: 10em; // az bude upraven markup v drupalu na 3:2 pro desktop, dat hodnotu 14em
				//max-height: 10em;
			}

			.image--mobile {
				display: block;

				@include breakpoint($breakpoint_desktop) {
						display: none;
				}
			}

			.image--desktop {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}
		}

		.b-004c__block--description {
			flex: 1;
			padding-left: 1em;

			max-width: calc(100% - 6em - 1em); // kvuli stitkum, 6em je sirka obrazku, 1em je padding
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004c__block--description h3 {
				margin: 0;
				padding-top: 0.5em;
				padding-bottom: 1em; // na mobilu neni perex, tak ochranna zona

				@include breakpoint($breakpoint_desktop) {
					padding-bottom: 0; // na desktopu nepotřebujeme
				}
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004c__block--description a p {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}

// spodni odsazeni boxu fix
.b-004c .b-004__list-item:last-child .b-004c__block--image {
	margin-bottom: 0;
}
.b-004c .b-004__list-item:last-child .b-004c__block--description p {
	margin-bottom: 0;
}





//
// B-004d Styles
//
.b-004d {
}

	.b-004d__list {

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-004d__list-item {

			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {

				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 1.34em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1.34em? protoze je to 2 * 2 / 3 viz (*)

				margin-right: 2em; // (*)
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004d__list-item .image .tag {

				display: inline-block;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004d__list-item a p {

				margin-top: 0.5em;
				margin-bottom: 0;
			}


		.b-004d__list-item:nth-child(3n) {

			@include breakpoint($breakpoint_desktop) {

				margin-right: 0;
			}
		}


	.b-004d__buttons-wrap {
		margin-top: 1em;

		text-align: center;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.button-box-flat-small {

			display: inline-flex;

			margin-top: 3em;
		}
	}

// spodni odsazeni boxu fix
.b-004d .b-004__list-item:last-child {
	margin-bottom: 0;
}
.b-004d .b-004__list-item:nth-last-child(-n+3) {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}




//
// B-004e Styles
//
.b-004e {
}

	.b-004e__subsection {

		@include breakpoint($breakpoint_desktop) {
			flex-direction: column;
		}
	}

		// TODO pro revizi, nema to bem, pravidla jsou nesmyslna, prebijim aby nedelalo nic, dela problemy v IE
		.b-004e__list--column-primary {
			flex: none; // ie fix - reset hodnoty z .b-004_list--column-folowing
		}

		.b-004e__list--column-following {
			flex: none; // ie fix - reset hodnoty z .b-004_list--column-folowing

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;

				margin-top: 2em;
			}
		}

			.b-004e__list-item--column-primary {
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004e__list-item--column-primary a p {
					margin-bottom: 1em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 1.5em;
					}
				}


			.b-004e__list-item--column-following {
				flex-basis: 50%;
			}

			.b-004e__list-item--column-following:nth-child(1) {

				@include breakpoint($breakpoint_desktop) {
					margin-right: 2em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004e__list-item--column-following .image .tag {
					display: inline-block;

					position: absolute;
					bottom: 0;
					left: 10px;

					max-inline-size: calc(100% - 20px);
				}

// spodni odsazeni boxu fix
.b-004e .b-004e__list-item--column-primary a p {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}
.b-004e .b-004e__list-item--column-following:last-child p {
	margin-bottom: 0;
}
.b-004e .b-004e__list-item--column-following:nth-last-child(-n+2) p {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}





//
// B-004f Styles
//
.b-004f {
}

	.b-004f__list {

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-004f__list-item {
			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 50%;
				max-width: 50%;
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004f__list-item .image .tag {
				display: inline-block;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004f__list-item a p {
				margin-top: 0.5em;
				margin-bottom: 0;
			}


		.b-004f__list-item:nth-child(odd) {

			@include breakpoint($breakpoint_desktop) {
				padding-right: 1em;
			}
		}

		.b-004f__list-item:nth-child(even) {

			@include breakpoint($breakpoint_desktop) {
				padding-left: 1em;
			}
		}


	.b-004f__buttons-wrap {
		margin-top: 1em;

		text-align: center;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.button-box-flat-small {
			display: inline-flex;

			margin-top: 3em;
		}
	}

// spodni odsazeni boxu fix
.b-004f .b-004__list-item:last-child {
	margin-bottom: 0;
}
.b-004f .b-004__list-item:nth-last-child(-n+2) {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}

// varianta kdy itemy jsou jednotlive boxy, viz RZ hp
.b-004f__wrapper {
	display: flex;
	flex-direction: column;

	@include breakpoint($breakpoint_desktop) {
		flex-direction: row;
		flex-wrap: wrap;
	}

	> h2 {
		flex-basis: 100%;
	}

	.b-004f {
		margin-top: 0;
		margin-bottom: 2em;

		@include breakpoint($breakpoint_desktop) {
			//margin-bottom: 0;

			// trik jak 2 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 40%; // toto neni ctvrtina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do tretin
			max-width: 48%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(50% - 1em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 1em? protoze je to 2 * 1 / 2 viz (*)

			margin-right: 2em; // (*)
		}

		.b-004f__list {
			display: block; // ma jen jeden item, tak netreba flexovat, navic vadi ie11
		}

			.b-004f__list-item {
				padding-right: 0;
			}
	}

	.b-004f:nth-child(odd) {

		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}

	.b-004f:nth-last-child(-n+2) {

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 0;
		}
	}

	.b-004f:last-child {
		margin-bottom: 0;
	}
}

// varianta se 2 polozkami
.l-grid--8-1,
.l-grid--12-1 {
	.b-004f {
		.b-004f__list {
			.b-004f__list-item {
				@include breakpoint($breakpoint_desktop) {
					flex-basis: 50%;
					max-width: 50%;
				}
			}
		}
	}
}

// varianta se 1 polozkou, resp napr. varinat na hp RZ, 4x ul s 1x li
.l-grid--8-1,
.l-grid--12-1 {
	.b-004f {
		.b-004f__list {
			.b-004f__list-item:first-child:last-child {
				@include breakpoint($breakpoint_desktop) {
					flex-basis: 100%;
					max-width: 100%;
				}
			}
		}
	}
}





//
// B-004h Styles
//
.b-004h {
}

	.b-004h__list {

		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		.b-004h__list-item {

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 50%;
			}
		}

		.b-004h__list-item:nth-child(1) {

			@include breakpoint($breakpoint_desktop) {
				margin-right: 2em;
			}
		}

// spodni odsazeni boxu fix
.b-004h .b-004__list-item:last-child p {
	margin-bottom: 0;
}
.b-004h .b-004__list-item:nth-last-child(-n+2) p {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}




//
// B-004i Stylese
//
.b-004i {
}
	.b-004i__list {

		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-004i__list-item {

			// IE
			min-height: 1px;

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 50%;
				max-width: 50%;

				margin-bottom: 2em;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					.tag {
						display: inline-block;
					}
				}
		}

		.b-004i__list-item:nth-child(odd) {

			@include breakpoint($breakpoint_desktop) {
				padding-right: 1em;
			}
		}

		.b-004i__list-item:nth-child(even) {

			@include breakpoint($breakpoint_desktop) {
				padding-left: 1em;
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004i__list-item a p {

				margin-top: 0.5em;
				margin-bottom: 0;
			}


		// item s vnorenym boxem b-042
		.b-004i__list-item.b-004i__list-item--b-042 {
			margin-top: 2em;
			margin-bottom: 3em;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 0;
				margin-bottom: 2em;
			}
		}

			// vnoreny box b-042
			.b-004i__list-item.b-004i__list-item--b-042 .box.b-042 {
				margin-top: 0;
			}

				// h2 vnoreneho boxu b-042 h2
				.b-004i__list-item .box.b-042 h2 {
					display: none;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-004i__list-item.b-004i__list-item--b-042 a p {

					color: $color_text_accent_inverted;
					@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
				}


		// pokud je aktivni promo pozice s boxem b-042, pak poslendi 5. item je na desktopu skryty
		.b-004i__list-item:nth-child(5) {

			@include breakpoint($breakpoint_desktop) {
				//display: none; // opusteno
			}
		}

		// razeni itemu v mobilni verzi
		.b-004i__list-item:nth-child(1) {

			order: 10;

			@include breakpoint($breakpoint_desktop) {
				order: unset;
			}
		}
		.b-004i__list-item:nth-child(2) {

			order: 20;

			@include breakpoint($breakpoint_desktop) {
				order: unset;
			}
		}
		.b-004i__list-item:nth-child(3) {

			order: 30;

			@include breakpoint($breakpoint_desktop) {
				order: unset;
			}
		}
		.b-004i__list-item:nth-child(4) {

			order: 40;

			@include breakpoint($breakpoint_desktop) {
				order: unset;
			}
		}
		.b-004i__list-item:nth-child(5) {

			order: 50;

			@include breakpoint($breakpoint_desktop) {
				order: unset;
			}
		}
		.b-004i__list-item.b-004i__list-item--b-042 {

			order: 35;

			@include breakpoint($breakpoint_desktop) {
				order: unset;
			}
		}

// spodni odsazeni boxu fix
/*.b-004i .b-004__list-item:last-child {
	margin-bottom: 0;
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 2em
	}
}
.b-004i .b-004__list-item:nth-last-child(-n+2) p {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}*/




//
// B-004j Styles
//
.b-004j {
}

	.b-004j__list {

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-004j__list-item {

			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {

				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 1.34em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1.34em? protoze je to 2 * 2 / 3 viz (*)

				margin-right: 2em; // (*)
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004j__list-item .image .tag {

				display: inline-block;
			}


		.b-004j__list-item:nth-child(3n) {

			@include breakpoint($breakpoint_desktop) {

				margin-right: 0;
			}
		}

// spodni odsazeni boxu fix
.b-004j .b-004__list-item:last-child {
	margin-bottom: 0;
}
.b-004j .b-004__list-item:nth-last-child(-n+3) {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}




//
// B-004k Styles
//
.b-004k {
}

	.b-004k__list {

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-004k__list-item {

			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {

				// trik jak 2 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 40%; // toto neni polovina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do polovin
				max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(50% - 1em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1em? protoze je to 2 * 1 / 2 viz (*)

				margin-right: 2em; // (*)
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004k__list-item .image .tag {

				display: inline-block;
			}


		.b-004k__list-item:nth-child(2n) {

			@include breakpoint($breakpoint_desktop) {

				margin-right: 0;
			}
		}

// spodni odsazeni boxu fix
.b-004k .b-004__list-item:last-child {
	margin-bottom: 0;
}
.b-004k .b-004__list-item:nth-last-child(-n+2) {
	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 0;
	}
}




//
// B-004l Styles
//
.b-004l {
}

	.b-004l__list {

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-004l__list-item {

			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004l__list-item .image .tag {

				display: inline-block;
			}

// spodni odsazeni boxu fix
.b-004l .b-004__list-item:last-child {
	margin-bottom: 0;
}




//
// B-004m Styles
//
.b-004m {
}

	.b-004m__list {

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-004m__list-item {

			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004m__list-item .image .tag {

				display: inline-block;

				background-color: $color_background-3_alpha-low;

				text-transform: uppercase;
			}

				// tag nezalinkovany
				.b-004m__list-item .image .tag span {
					color: $color_text_accent;
				}

				// tag zalinkovany
				.b-004m__list-item .image .tag a {
					@include link-style($color_text_link, none, $color_text_link, underline);

					span {
					}
				}


			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004m__list-item h3 {

				padding: 0.5em;

				background-color: $color_background-6;
			}

				.b-004m__list-item h3 a {

				color: $color_text_accent_inverted;
			}

// spodni odsazeni boxu fix
.b-004m .b-004__list-item:last-child {
	margin-bottom: 0;
}





//
// B-004n Styles
//
.b-004n {
}

	.b-004c__list--column-following {
		flex: none;  // ie fix - reset hodnoty z .b-004_list--column-folowing
	}

	.b-004n__list-item--column-following {
		display: flex;
		flex-direction: row;
	}

		.b-004n__block--image {
			max-width: 6em;
			max-height: 6em;

			//padding-bottom: 1em;
			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				max-width: 10em;
				max-height: 10em;
			}
		}

		.b-004n__block--description {
			flex: 1;
			padding-left: 0.75em;

			@include breakpoint($breakpoint_desktop) {
				padding-left: 1em;
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-004n__block--description h3 {
				margin: 0;
				padding-top: 0.25em;
				padding-bottom: 0;
			}

// spodni odsazeni boxu fix
.b-004n .b-004__list-item:last-child p {
	margin-bottom: 0;
}





// vizualni debug - plab only
.patternlab .b-004l,
.patternlab .b-004m {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
