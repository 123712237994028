//
// 1968 - obecne vlastnosti vsech stranek
//



$domain-1968_bg_header_desktop: url('../images/bg/1968/1968_header_desktop.jpg');
$domain-1968_bg_header_mobile: url('../images/bg/1968/1968_header_mobile.jpg');
$domain-1968_logo_desktop: url('../images/logo/1968/logo_68_main.svg?20180528');
$domain-1968_logo_fx_desktop: url('../images/logo/1968/logo_68_main_FX.svg?20180528');

$domain-1968_logo_corporate_colored-9e9ea9: url('../images/logo/rozhlas_colored-9e9ea9.svg'); // seda, napr. v hlavni nav liste

$domain-1968_menu-bar-background-color: #02091d;

// duplicity s _b-016.scss
$domain-1968_b-016_menu-bar-height_mobile: 75px;
$domain-1968_b-016_menu-bar-height_desktop: 140px;
$domain-1968_b-016_menu-logo-size_desktop: 75px;
$domain-1968_b-016_menu-logo-size_mobile: 45px;
$domain-1968_b-016_menu-logo-margin-left_desktop: 40px;
$domain-1968_b-016_menu-logo-margin-right_desktop: 30px;
$domain-1968_b-016_menu-logo-margin-left_mobile: 10px;
$domain-1968_b-016_menu-logo-margin-right_mobile: 20px;



.domain-1968-rozhlas-cz { // doacasne, nazev tridy nemuze byt 1968

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_1968_main, $color_1968_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_1968_main);
	}


	// bg
	// before po prihlaseni kolidovalo s adminimal menu
	&::after {
		content: "";

		width: 100vw;
		height: 100vh;

		position: absolute;
		top: 0;
		left: 0;

		background-image: $domain-1968_bg_header_mobile;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;

		z-index: -2;

		@include breakpoint($breakpoint_desktop) {
			background-image: $domain-1968_bg_header_desktop;
		}
	}
	// bg fixed
	&.fixed::after {
		position: fixed;
		top: calc(-100vh + #{$domain-1968_b-016_menu-bar-height_mobile});

		//top: 0;
		//height: calc(#{$domain-1968_b-016_menu-bar-height_mobile});

		z-index: 98; // z-index: 99 ma .b-016__wrapper.fixed

		@include breakpoint($breakpoint_desktop) {
			top: calc(-100vh + #{$domain-1968_b-016_menu-bar-height_desktop});

			//top: 0;
			//height: calc(#{$domain-1968_b-016_menu-bar-height_desktop});

			//background-position: 50% 43%;
		}
	}



	header {
		position: relative;

		//@include transition(all 200ms ease-in-out);
		//transition: all 0.5s ease;

		@include breakpoint($breakpoint_desktop) {
			//margin-bottom: 3em;
		}

		// header bg
		.header__promo {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			padding-bottom: $domain-1968_b-016_menu-bar-height_mobile;

			width: 100%;
			//height: calc(100vh - #{$domain-1968_b-016_menu-bar-height_mobile});
			height: calc(100vh - #{$b-014a_menu-bar-height_mobile});

			@include breakpoint($breakpoint_desktop) {
				padding-bottom: $domain-1968_b-016_menu-bar-height_desktop;

				//height: calc(100vh - #{$domain-1968_b-016_menu-bar-height_desktop});
				height: calc(100vh - #{$b-014a_menu-bar-height_desktop});

			}
		}

			.header__logo {
				max-width: 270px;
				max-height: 270px;

				min-width: 100px;
				min-height: 100px;

				width: 100%;
				height: 100%;

				margin: 50px;

				background-image: $domain-1968_logo_desktop;
				background-repeat: no-repeat;
				background-position: 50% 50%;

				@include breakpoint($breakpoint_desktop) {

				}

				/*&:hover {
					@include breakpoint($breakpoint_desktop) {
						background-image: $domain-1968_logo_fx_desktop;
					}
				}*/

				span {
					display: none;
				}
			}

	}



	// hp vs podstranky
	// nav ma byt vzdy ve stavu fixed u podstranek
	// a podle toho se musi nastavit i dalsi elementy

	// stanicni nav wrapper
	&:not(.page-1968-hp) {

		// nav nechceme
		.b-014a,
		.b-014b {
			display: none;
		}

		// bg fixed
		// je automaticky pro podstranky
		&::after {
			position: fixed;
			top: calc(-100vh + #{$domain-1968_b-016_menu-bar-height_mobile});

			//top: 0;
			//height: calc(#{$domain-1968_b-016_menu-bar-height_mobile});

			z-index: 98; // z-index: 99 ma .b-016__wrapper.fixed

			@include breakpoint($breakpoint_desktop) {
				top: calc(-100vh + #{$domain-1968_b-016_menu-bar-height_desktop});

				//top: 0;
				//height: calc(#{$domain-1968_b-016_menu-bar-height_desktop});

				//background-position: 50% 43%;
			}
		}

		header {
			margin-bottom: 3em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 2em;
			}

			.header__promo {
				height: $domain-1968_b-016_menu-bar-height_mobile;

				@include breakpoint($breakpoint_desktop) {
					height: $domain-1968_b-016_menu-bar-height_desktop;
				}

				.header__logo {
					display: none
				}
			}
		}

		// stanicni nav wrapper
		// je automaticky pro podstranky
		.b-016__wrapper {
			position: fixed;
			top: 0;
			bottom: unset;

			z-index: 99;

			@include breakpoint($breakpoint_desktop) {
				max-width: $layout_page_max-width;
				margin: auto;
			}

			.nav__logo {
				//display: block;

				width: $domain-1968_b-016_menu-logo-size_mobile;
				height: $domain-1968_b-016_menu-logo-size_mobile;

				margin-left: $domain-1968_b-016_menu-logo-margin-left_mobile;
				margin-right: $domain-1968_b-016_menu-logo-margin-right_mobile;

				@include breakpoint($breakpoint_desktop) {
					width: $domain-1968_b-016_menu-logo-size_desktop;
					height: $domain-1968_b-016_menu-logo-size_desktop;

					margin-left: $domain-1968_b-016_menu-logo-margin-left_desktop;
					margin-right: $domain-1968_b-016_menu-logo-margin-right_desktop;
				}
			}
		}
	}



	// main
	.main {
		margin-top: 0;
	}

}
