//
// B-006d Styles
//



.b-006d {
	
}
	
	.b-006d__subsection {
		
		position: relative;
		
		background-color: $color_background-9;
		
	}
		
		.b-006d__block {
			// neexituje vlastnost obecneho bloku
			// 1. blok je obsah (video a text)
			// 2. blok je wrapper tlacitek
		}
		
		.b-006d__block--content {
			
			display: flex;
			flex-direction: column;
			
			padding-top: 1em;
			padding-bottom: 4.5em;
			
			overflow: auto;
			
			@include breakpoint($breakpoint_desktop) {
				
				flex-direction: row;
				
				//padding: 3em 6em;
				padding: 2em 5.5em;
			}
			
		}
			
			.b-006d__subblock--player {
				
				@include breakpoint($breakpoint_desktop) {
					flex-grow: 1;
				}
			}
		
		
		.b-006d__block--slider-buttons {
			
			position: absolute;
			bottom: -1.5em;
			width: 100%;
			
			display: flex;
			flex-direction: row;
			justify-content: center;
			
			@include breakpoint($breakpoint_desktop) {
				
				// mobile reset v nic
				height: 100%;
				bottom: initial;
				
				top: 0;
				left: 0;
				
				pointer-events: none;
				
				align-items: center;
				justify-content: space-between;
			}
		}
			
			// oba buttony
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006d__block--slider-buttons .button-box-flat {
				// obnoveni neklikatelnosti rodice
				pointer-events: auto;
			}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006d__block--slider-buttons .button-box-flat--slider-left {
				
				margin-right: 1em;
				
				@include breakpoint($breakpoint_desktop) {
					margin: 0;
					margin-left: -0.5em;
				}
			}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006d__block--slider-buttons .button-box-flat--slider-right {
				
				@include breakpoint($breakpoint_desktop) {
					margin-right: -0.5em;
				}
			}