//
// Zelenavlna - mapa
//



.zelenavlna {
}
	
	.zelenavlna #trafficTabs-tables {
		margin-top: .5em;
	}

		.zelenavlna #trafficTabs-tables .ui-accordion .accordion-panel.ui-accordion-content {
			border: none !important;
			border: 0 !important;
		}

		.zelenavlna #trafficTabs-tables h3:nth-of-type(3).accordion-head.ui-accordion-header {
			display: none; // vysilace ZV (nefunguje)
		}


		.zelenavlna #trafficTabs-tables div#tables-navbar,
		.zelenavlna #trafficTabs-tables div#msg-roadClasses-group {
			background-image: none;
			border-bottom: 1px solid $color_background-4;;
		}
		
		.zelenavlna #trafficTabs-tables div#tables-navbar,
		.zelenavlna #trafficTabs-tables div#msg-roadClasses-group,
		.zelenavlna #trafficTabs-tables div#msg-types-group {
			height: auto;
		}
		
		.zelenavlna #trafficTabs-tables div#msg-types-group #msg-roadClasses-group label,
		.zelenavlna #trafficTabs-tables div#msg-types-group #msg-types-group label {
			line-height: 2;
		}