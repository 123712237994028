//
// footer legal styles
//



$b-017_legal_vertical-padding: 1.25em;



.footer-legal {
	
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	
	padding: $b-017_legal_vertical-padding $b-017_child-section-horizontal-padding_mobile;
	
	border-top: 1px solid $color_background-5;
	
	@include breakpoint($breakpoint_desktop) {
		
		flex-direction: row;
		align-items: stretch;
		
		padding: $b-017_legal_vertical-padding $b-017_child-section-horizontal-padding_desktop;
	}
	
	color: $color_text_paragraph_inverted;
	
}
	
	.footer-legal__link {
		@include link-style($color_text_paragraph_inverted, none, $color_text_paragraph_inverted);
	}
	
	.footer-legal__link--separated {
		padding-right: 0.5em;
		border-right: 1px solid $color_divider;
		margin-right: 0.5em;
	}

	.footer-legal__link--title {
		color: $color_text_link_inverted;
		margin-right: 5px;
		@include typography_font(medium);
		@include link-style($color_text_link_inverted, none, $color_text_link_inverted, none);
	}
	
	
	
	
	.footer-legal__logo {
		
		display: block;
		
		margin-top: 0.5em;
		padding-left: 0.5em;
		padding-right: 0.5em;
		
		@include breakpoint($breakpoint_desktop) {
			
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			
			margin-top: 0;
			padding: 0;
		}
		
		a {
			
			display: block;
			
			width: 8em;
			height: 1.5em;
			
			padding: 0;
			
			background-image: $file_logo_corporate_colored-white;
			background-repeat: no-repeat;
			background-position: left center;
			
			
			@include breakpoint($breakpoint_desktop) {
				width: 9em;
			}
			
			span {
				// v patternlabu je class hidden ale v D nutno specifikovat, v markupu neni
				display: none;
			}
		}
	}
	
	
	
	
	.footer-legal__contentinfo {
		
		margin-top: 0.75em;
		padding-left: 0.5em;
		padding-right: 0.5em;
		
		@include breakpoint($breakpoint_desktop) {
			
			text-align: right;
			
			margin-top: 0;
			padding: 0;
		}
		
		// TODO velikost fontu pro popisek v pate
		@include adjust-font-size-to($fontsize_h4_desktop);
	}
		
		.footer-legal__links {
			@include rhythm(0, 0, 0, 0.25);
		}
		
		.footer-legal__copyright {
			@include rhythm(0.25, 0, 0, 0);
		}