//
// Rastova aplikace Otazky a.k.a SocApp
//

$icon-path: "../images/icon/";

#otazky {
	margin-bottom: 40px;
	.panel {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.title {
			color: $color_text_accent_inverted;
			background: $color_background-7;
			font-size: 14px;
			line-height: 36px;
			text-align: center;
			transition: font-size .25s;
			@include breakpoint($breakpoint_tablet) {
				font-size: 18px;
				display: flex;
				padding: 0 15px;
			}
		}
		.icons {
			display: flex;
			justify-content: center;
			height: 101px;
			@include breakpoint($breakpoint_tablet) {
				order: -1;
				align-self: flex-end;
				position: relative;
				margin-right: 4vw;
			}
			.shareb {
				font-size: 11px;
				color: $color_text_accent_inverted;
				width: 60px;
				height: 80px;
				cursor: pointer;
				position: relative;
				align-self: center;
				margin-right: 5vw;
				&:last-child {
					margin-right: 0;
				}
				@include breakpoint($breakpoint_tablet) {
					width: 70px;
					height: 91px;
					margin-right: 2vw;
				}

				.label {
					display: block;
					text-align: center;
					color: $color_text_accent;
					@include breakpoint($breakpoint_tablet) {
						color: $color_text_accent;
						height: 36px;
						display: flex;
						flex-direction: column;
						justify-content: center;
					}
				}
				&.active .label {
					@include typography_font(medium);
					font-size: 12px;
					text-decoration: underline;
					&:before {
						content: "";
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
						border-top: 5px solid $color_background-6;
						position: absolute;
						bottom: 16px;
						left: calc(50% - 4px);
						@include breakpoint($breakpoint_tablet) {
							bottom: 25px;
							left: calc(50% - 6px);
							border-top: 6px solid $color_background-0;
							border-left: 6px solid transparent;
							border-right: 6px solid transparent;
						}
					}
				}
				//icons
				&.wf {
					.icon {
						background: url("#{$icon-path}ico-1-mail.svg") 50% 0 no-repeat;
					}
				}
				&.sms {
					.icon {
						background: url("#{$icon-path}ico-2-message.svg") 50% 0 no-repeat;
					}
				}
				&.tw {
					.icon {
						background: url("#{$icon-path}ico-3-twitter.svg") 50% 0 no-repeat;
					}
				}
				&.fb {
					.icon {
						background: url("#{$icon-path}ico-4-facebook.svg") 50% 0 no-repeat;
					}
				}
				&.wf,
				&.sms,
				&.tw,
				&.fb  {
					.icon {
						display: block;
						height: 60px;
						transition: all .15s;
						-webkit-transform: translate3d(0, 0, 0); // Safari blinking fix
					}
				}
				// hover a active stavy u ikon
				&:hover,
				&:active {
					.icon {
						transform: scale(1.08);
						&:last-child {
							margin-right: 0;
						}
					}
					.label {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.show {
		margin-top: 1px;
		.wf-comments {
			border-radius: 2px;
			background-color: $color_background-1;
			.webform-component--jmeno{
				@include breakpoint($breakpoint_tablet) {
					width: 50%;
					float: left;
					margin-right: -100%;
					margin-left: 0;
					padding-right: 12.5px;
				}
			}
			.webform-component--email {
				@include breakpoint($breakpoint_tablet) {
					width: 50%;
					float: right;
					margin-left: 0;
					margin-right: 0;
					clear: none;
					padding-left: 12.5px;
				}
			}
			.webform-component--message {
				grid-area: message;
				@include breakpoint($breakpoint_tablet) {
					width: 100%;
					float: left;
					clear: both;
				}
				textarea {
					width: 100%;
					max-width: 100%;
				}
			}
			.form-actions {
				clear: both;
			}
		}
		.sms-comments {
			padding: 15px;
			background-color: $color_background-2;
			font-size: 14px;
			@include breakpoint($breakpoint_tablet) {
				border-radius: 2px;
			}
		}
	}
	// loadovací kolečko, nejspíše zbytečné
	.kolecko {
		display: none;
		width: 20px;
		height: 20px;
		background: url('https://aplikace.rozhlas.cz/otazky/sites/default/libs/ajax-loader.gif') no-repeat;
		float: left;
	}
	.feed {
		.message {
			padding: 9px 20px 16px 48px;
				border-bottom: 1px dotted $color_divider;
			&.wf {
				background: url("#{$icon-path}ico-1-mail.svg") 10px 10px no-repeat;
				background-size: 30px 30px;
			}
			&.sm {
				background: url("#{$icon-path}ico-2-message.svg") 10px 10px no-repeat;
				background-size: 30px 30px;
			}
			&.tw {
				background: url("#{$icon-path}ico-3-twitter.svg") 10px 10px no-repeat;
				background-size: 30px 30px;
				&.photo .photo {
					display: none;
				}
			}
			&.fb {
				background: url("#{$icon-path}ico-4-facebook.svg") 10px 10px no-repeat;
				background-size: 30px 30px;
			}
			&.last {
				border-bottom: 1px solid $color_divider;
			}
			.text {
				color: $color_background-8;
				font-size: 0.9rem;
				word-wrap: break-word; // ochrana proti rozbití layoutu zalamováním dlouhých textů, např. vložením dlouhého url do příspěvku
				overflow: hidden;
			}
			.separator {
				padding: 0 10px;
			}
			.name {
				color: $color_background-9;
				@include typography_font(medium);
				font-size: 0.9rem;
			}
			img {
				width: auto;
			}
		}
	}
	// skryté elementy
	.fb-comments,
	.fb_iframe_widget,
	.fbFeedbackReplies,
	.uiMorePager,
	.fbFeedbackPosts {
		display: none;
	}
	// ostatní
	.message.ad {
		background: $color_divider;
	}
	.message.wa {
		background: $color_decoration_alert;
		.text {
			color: $color_text_accent_inverted;
		}
	}
	.more {
		float: right;
		cursor: pointer;
		color: $color_background-7;
		@include typography_font(medium);
		margin-top: 10px;
		padding-right: 25px;
		background: url("https://aplikace.rozhlas.cz/otazky/sites/default/libs/arrow_vice.png?0402") 100% 50% no-repeat;
	}
	// textarea
	.otazky-textarea textarea {
		height: 100px;
		width: calc(100% - 0.5em - 2px);
		@include breakpoint($breakpoint_tablet) {
			height: 200px;
		}
	}
}
