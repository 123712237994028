$icon-path: "../images/icon/";

.livereport-wrapper-d {
	@-moz-keyframes rotate {
		0% {
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg)
		}
		100% {
		transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg)
		}
	}
	@-webkit-keyframes rotate {
		0% {
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg)
		}
		100% {
		transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg)
		}
	}
	@-o-keyframes rotate {
		0% {
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg)
		}
		100% {
		transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg)
		}
	}
	@-ms-keyframes rotate {
		0% {
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg)
		}
		100% {
		transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg)
		}
	}
	@keyframes rotate {
		0% {
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg)
		}
		100% {
		transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg)
		}
	}
	@-moz-keyframes evenhighlight {
		0% {
		background-color: #eeee8e
		}
		100% {
		background-color: #eee
		}
	}
	@-webkit-keyframes evenhighlight {
		0% {
		background-color: #eeee8e
		}
		100% {
		background-color: #eee
		}
	}
	@keyframes evenhighlight {
		0% {
		background-color: #eeee8e
		}
		100% {
		background-color: #eee
		}
	}
	@-moz-keyframes asidehighlight {
		0% {
		background-color: #eeee8e
		}
		100% {
		background-color: #ed2d38
		}
	}
	@-webkit-keyframes asidehighlight {
		0% {
		background-color: #eeee8e
		}
		100% {
		background-color: #ed2d38
		}
	}
	@keyframes asidehighlight {
		0% {
		background-color: #eeee8e
		}
		100% {
		background-color: #ed2d38
		}
	}
	@-moz-keyframes oddhighlight {
		0% {
		background-color: #f5f5ba
		}
		100% {
		background-color: #d7d7d7
		}
	}
	@-webkit-keyframes oddhighlight {
		0% {
		background-color: #f5f5ba
		}
		100% {
		background-color: #d7d7d7
		}
	}
	@keyframes oddhighlight {
		0% {
		background-color: #f5f5ba
		}
		100% {
		background-color: #d7d7d7
		}
	}
	@-moz-keyframes spin {
		100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg)
		}
	}
	@-webkit-keyframes spin {
		100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
		}
	}
	@keyframes spin {
		100% {
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
		}
	}
	.live {
		background-color: $color_background-1;
		margin-top: 10px;
		padding: 20px;
		display: flex;
		flex-direction: column;
	}

	.livereport {
		display: block;
		float: left;
		margin-bottom: 40px;
		width: 100%
	}
	.livereport blockquote {
		margin: 0;
		padding: 0
	}
	.livereport .timestamp {
		display: flex;
		height: 18px;
		line-height: 1.5;
	}
	.livereport a.time, .livereport a.time:active, .livereport a.time:focus, .livereport a.time:visited {
		padding: 0 4px;
		color: $color_background-0;
		background-color: $color_background-7_alpha-mid;
		font-size: .8rem;
		text-align: center;
		@include no-select;
	}
	.livereport .date {
		margin-left: 5px;
		padding: 0 4px;
		background-color: $color_background-7_alpha-mid;
		font-size: .8rem;
		color: $color_background-0;
		cursor: default;
		@include no-select;
	}
	.livereport .text {
		display: block;
		float: left;
		overflow: hidden;
		width: 100% !important;
	}
	.livereport .text a,
	.livereport .text strong {
		@include typography_font(medium);
		color: $color_text_accent;
	}
	.livereport .text a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.livereport .text ul {
		list-style: none;
	}
	.livereport .text ul li {
		margin-left: 16px;
		margin-top: 8px;
		margin-bottom: 8px;
		list-style-type: circle;
		list-style-position: inside;
	}
	.livereport .text br {
		display: none; // skryvame, svadelo by k formatovani, nektere reportaze uz <br> pouzivaji a pak se nezobrazuje OK
	}
	.livereport .message {
		clear: both;
		color: $color_background-7;
		display: block;
		float: left;
		width: 100%;
	}
	.livereport .message:not(:first-child) {
		border-top: 1px solid $color_divider;
		padding-top: 20px;
		margin-top: 10px;
	}
	.livereport .message.even.animate {
		-moz-animation-name: evenhighlight;
		-webkit-animation-name: evenhighlight;
		animation-name: evenhighlight;
		-moz-animation-duration: 3s;
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
		-moz-animation-timing-function: ease-in;
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in
	}
	.livereport .message.odd.animate {
		-moz-animation-name: oddhighlight;
		-webkit-animation-name: oddhighlight;
		animation-name: oddhighlight;
		-moz-animation-duration: 3s;
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
		-moz-animation-timing-function: ease-in;
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in
	}
	.livereport .message-share {
		color: #555;
		cursor: pointer;
		display: block;
		float: left;
		width: 100%
	}
	.livereport .message-share:visited {
		color: #555
	}
	.livereport .message-share:focus, .livereport .message-share:hover {
		color: #222
	}
	.livereport .livebody {
		margin-bottom: 10px;
		width: 100%
	}
	.livereport p {
		margin: 5px 0 !important;
		line-height: 1.42 !important;
		font-size: 1rem !important;
	}
	.livereport h2 {
		color: #ca003a;
		display: block;
		font-size: 20px;
		margin: 0;
		padding: 0 0 5px
	}
	.livereport.newsfeed h2 {
		color: #555
	}
	.livereport.newsfeed-aside h2, .livereport.report-aside h2 {
		background-color: #fff;
		color: #ed2d38
	}
	.livereport .image {
		margin: 5px 0 0 0;
		width: 100%
	}
	.livereport .author, .livereport .source {
		margin: 5px 0
	}
	.livereport .report-fb {
		color: #3b5998;
		float: right;
		font-size: 16px
	}
	.livereport .fb-status {
		border-left: .3em #3b5998 solid;
		padding: 0 0 0 .7em
	}
	.livereport .divider {
		border-bottom: 1px dotted #b7b7b7;
		clear: left;
		display: block;
		float: left;
		margin: 10px 0;
		width: 100%
	}
	#article .livereport .date {
		background: none;
		clear: both;
		color: #ca003a;
		font-size: .8em;
		height: auto;
		margin: 0;
		padding: 0;
		width: 100%
	}
	#article .livereport .image {
		margin: 0;
		max-width: 100%;
		width: auto
	}
	#article .livereport .author {
		font-size: 11px;
		line-height: 13px;
		margin: 5px 0
	}
	#article .livereport a.time, #article .livereport a.time:active, #article .livereport a.time:focus, #article .livereport a.time:visited {
		color: #ca003a
	}
	#article .livereport p {
		margin: 0
	}
	.newsfeed-aside, .report-aside {
		background: #eee;
		margin-bottom: 0;
		padding: 0 0 10px
	}
	.newsfeed-aside .live, .report-aside .live {
		height: 1500px;
		overflow: hidden
	}
	.newsfeed-aside .clear, .report-aside .clear {
		display: block;
		float: left;
		margin-bottom: 5px;
		margin-top: 5px;
		width: 100%
	}
	.newsfeed-aside .full-newsfeed, .report-aside .full-newsfeed {
		-moz-border-radius: 20px;
		-webkit-border-radius: 20px;
		border-radius: 20px;
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2U4ZThlOCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
		background-size: 100%;
		background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #e8e8e8));
		background-image: -moz-linear-gradient(#ffffff, #e8e8e8);
		background-image: -webkit-linear-gradient(#ffffff, #e8e8e8);
		background-image: linear-gradient(#ffffff, #e8e8e8);
		border: #d0d0d0 1px solid;
		border-bottom-color: #bcbcbc;
		border-top-color: #ddd;
		color: $color_decoration_main;
		cursor: pointer;
		display: block;
		float: left;
		font-size: 10px;
		height: 20px;
		line-height: 20px;
		margin-left: 10px;
		padding: 0 10px
	}
	.newsfeed-aside .full-newsfeed:focus, .newsfeed-aside .full-newsfeed:hover, .report-aside .full-newsfeed:focus, .report-aside .full-newsfeed:hover {
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzk5OTk5OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzU3NTc1NyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
		background-size: 100%;
		background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #999999), color-stop(100%, #575757));
		background-image: -moz-linear-gradient(#999999, #575757);
		background-image: -webkit-linear-gradient(#999999, #575757);
		background-image: linear-gradient(#999999, #575757);
		border: #707070 1px solid;
		border-bottom-color: #565656;
		border-top-color: #777;
		color: #fff;
		text-decoration: none
	}
	.newsfeed-aside .full-newsfeed:focus .fa, .newsfeed-aside .full-newsfeed:hover .fa, .report-aside .full-newsfeed:focus .fa, .report-aside .full-newsfeed:hover .fa {
		color: #f9f9f9
	}
	.newsfeed-aside .full-newsfeed .fa, .report-aside .full-newsfeed .fa {
		color: #003369;
		float: right;
		font-size: 15px;
		@include typography_font(medium);
		margin-top: 2px
	}
	.newsfeed-aside .full-newsfeed .fa:last-child, .report-aside .full-newsfeed .fa:last-child {
		margin-left: 5px
	}
	.newsfeed-aside .message.odd, .report-aside .message.odd {
		background: #ed2d38
	}
	.newsfeed-aside .message.odd.animate, .report-aside .message.odd.animate {
		-moz-animation-name: asidehighlight;
		-webkit-animation-name: asidehighlight;
		animation-name: asidehighlight;
		-moz-animation-duration: 3s;
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
		-moz-animation-timing-function: ease-in;
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in
	}
	.newsfeed-aside .message.odd .text, .report-aside .message.odd .text {
		background: #d7d7d7;
		border-left-color: transparent;
		min-height: 48px
	}
	.newsfeed-aside .message.even, .report-aside .message.even {
		background: #ed2d38
	}
	.newsfeed-aside .message.even.animate, .report-aside .message.even.animate {
		-moz-animation-name: asidehighlight;
		-webkit-animation-name: asidehighlight;
		animation-name: asidehighlight;
		-moz-animation-duration: 3s;
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
		-moz-animation-timing-function: ease-in;
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in
	}
	.newsfeed-aside .message.even .text, .report-aside .message.even .text {
		background: #eee;
		border-left-color: transparent;
		min-height: 48px
	}
	.newsfeed-aside .date, .newsfeed-aside .message-share, .newsfeed-aside a.time, .report-aside .date, .report-aside .message-share, .report-aside a.time {
		color: #fff
	}
	.newsfeed-aside .date:active, .newsfeed-aside .date:visited, .newsfeed-aside .message-share:active, .newsfeed-aside .message-share:visited, .newsfeed-aside a.time:active, .newsfeed-aside a.time:visited, .report-aside .date:active, .report-aside .date:visited, .report-aside .message-share:active, .report-aside .message-share:visited, .report-aside a.time:active, .report-aside a.time:visited {
		color: #fff
	}
	.livebody {
		clear: both;
		display: block;
		float: left;
	}
	// obnovit
	.refresh {
		display: flex;
		font-size: .9rem;
		align-items: center;
	}
	.refresh::before {
		content: '';
		width: 16px;
		height: 16px;
		margin-right: 10px;
		background: url("#{$icon-path}ico_duration.svg") center no-repeat;
	}
	// zobrazit celou reportaz
	.live-next {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $color_decoration_success;
		color: $color_background-0;
		clear: both;
		text-transform: uppercase;
		cursor: pointer;
		font-size: 16px;
		height: 50px;
		line-height: 1.15;
		margin-top: 10px;
		padding: 0 20px;
		width: 100%;
		@media only screen and (min-width: 960px) {
			width: auto;
			width: fit-content;
		}
	}
	.live-next:hover {
		background: $color_decoration_success !important;
		opacity: .8;
	}
	.live-next i {
		color: #b5b5b5
	}
	.live-next:focus, .live-next:hover {
		background: #b5b5b5;
		color: #fff;
		text-decoration: none
	}
	.live-next:focus i, .live-next:hover i {
		color: #fff
	}
	.refreshtext {
		display: inline-block;
	}
	.link, .livereport a, .refreshtext {
		color: $color_decoration_main;
		cursor: pointer;
		text-decoration: none
	}
	.link:hover, .livereport a:hover, .refreshtext:hover {
		text-decoration: underline
	}
	.link:visited, .livereport a:visited, .refreshtext:visited {
		color: $color_decoration_main
	}
	.share-popup {
		background: #fff;
		display: block;
		@include typography_font(regular);
		height: 140px;
		overflow: hidden;
		padding: 5px;
		position: absolute;
		width: 150px;
		z-index: 5000
	}
	.timestamp {
		color: #fff
	}
	.throbber, .throbberhist {
		color: #555;
		display: block;
		float: left;
		font-size: 20px;
		height: 20px;
		line-height: 20px;
		width: 20px
	}
	.throbber i, .throbberhist i {
		-moz-animation-name: spin;
		-webkit-animation-name: spin;
		animation-name: spin;
		-moz-animation-duration: 2s;
		-webkit-animation-duration: 2s;
		animation-duration: 2s;
		-moz-animation-timing-function: linear;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-moz-animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite
	}
	.livebody.edited, .message.edited {
		border-left: 3px solid red;
		margin-left: -3px
	}
	#expired p, .expired p {
		background: #e6b405;
		color: #000;
		@include typography_font(bold);
		font-size: 13px;
		height: auto;
		left: 0;
		line-height: 18px;
		margin: 10px;
		padding: 20px 10px;
		text-align: center;
		top: 0
	}
	.fade {
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZWVlZWUiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
		background-size: 100%;
		background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, #eeeeee));
		background-image: -moz-linear-gradient(rgba(255, 255, 255, 0), #eeeeee);
		background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), #eeeeee);
		background-image: linear-gradient(rgba(255, 255, 255, 0), #eeeeee);
		display: block;
		height: 70px;
		margin-top: -70px;
		position: relative;
		width: 100%
	}
	.livereport .croplayer, .livereport .cro-player {
		background: #eee;
		padding: 5px 10px 10px 5px
	}
}
