//
// B-110 Styles
//



.b-110 {
}

	.b-110__section {
		display: flex;
		align-items: center;
	}

		.b-110__block--calendar {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			margin-right: 0.75em;

			width: 50px;
			height: 63px;

			background-image: $file_icon_calendar-junior;
			background-repeat: no-repeat;

			@include breakpoint($breakpoint_desktop) {
				width: 30px;
				height: 38px;
			}
		}

			.b-110__calendar-number {
				position: relative;
				top: 8px;

				@include typography_font(medium);
				font-size: 24px;

				@include breakpoint($breakpoint_desktop) {
					top: 5px;
					font-size: 16px;
				}
			}


		.b-110__block--description {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;

			padding: 0.5em 0;

			font-size: 14px;
			line-height: 1.3;

			border-top: 1px solid $color_divider;
			border-bottom: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
		}

			.b-110__description-subblock {

				.button-simple--readmore {

					.button-simple__label {

						.button-simple__label-nest {
							font-size: 14px;
							line-height: 1.3;
						}
					}
				}
			}

			.b-110__description-subblock:not(:last-child) {

				@include breakpoint($breakpoint_desktop) {
					margin-right: 1em;
				}
			}

				.b-110__calendar-name {
					@include typography_font(medium);
					color: $color_decoration-main;
				}





// vizualni debug - plab only
.patternlab .b-110 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 755px;
	}
}
