//
// search styles
//



.search-combo {
	
	position: relative;

	margin-top: 1em;
	margin-bottom: 2em;
	
	@include breakpoint($breakpoint_desktop) {
	}
}

	.search-combo .input-search {
		
		height: 2.5em;
		
		padding-left: 0.75em;
		padding-right: 2.5em;
	}
	
	.search-combo .search-submit {
		
		background-image: $file_icon_search;
		background-position: right center;
		background-repeat: no-repeat;
		
		width: 1.5em;
		height: 1.5em;
		
		position: absolute;
		top: 0.5em;
		right: 0.5em;
	}