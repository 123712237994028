//
// B-041k Styles - Audioplayer Metadata + Anchor Styles
// Radio Junior Skin
//

$junior_player_radius: 8px;


// clanek s jednim audiem
.junior {
	.b-041k {
		&__metadata {
			border: none;
			background-color: $color_junior_main;
			border-bottom-left-radius: $junior_player_radius;
			border-bottom-right-radius: $junior_player_radius;
		}
		&__wrap {

		}
		&__item {

		}
		&__item span {

		}
		&__audio-title {
			color: $color_junior_add-1 !important;
			font-family: $font-junior-book;
			letter-spacing: 1.8px;
		}
		&__availability-title,
		&__audio-length-title,
		&__description-title,
		&__availability-value,
		&__audio-length-value,
		&__description-value {
			color: $color_junior_add-1 !important;
			font-family: $font-junior-light;
		}
		&__availability-value,
		&__audio-length-value,
		&__description-value,
		&__description-value > * {
			color: $color_junior_add-1 !important;
			font-family: $font-junior-medium;
			a {
				color: $color_junior_add-1;
			}
		}
		&__link:link,
		&__link:visited {
			color: $color_junior_add-1;
		}
		&__buttons {
			.button {
				border: none;
			}
			// zmena barevnosti ikony
			.button-listaction--share {
				background-image: $file_button_action_share_colored-white;
			}
		}
		&__anchor {
			border: none;
			background-color: $color_junior_main;
			color: $color_junior_add-1 !important;
			border-radius: $junior_player_radius;
			&:link,
			&:visited {
			}
			&:after {
				background-image: $file_button_action_play_colored-fff3dd;
			}
			&:hover {
				&::after  {
				}
			}
		}
	}
}

// clanek s vice audii (serial)
.junior .audio-serial-player {
	.b-041k {
		&__metadata {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}
