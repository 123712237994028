//
// B-039b Styles
//
// 



.b-039b {
}
	
	.b-039b__title {
		// v plabu neni, ale v drupalu tato trida pritomna
	}
	
	.b-039b__list {
		border: 1px solid $color_divider; 
	}
		
		.b-039b__list-item {
			
			position: relative; // kvuli absolutinimu pozicovani pseudo linky
		}
			
			.b-039b__list-item .button-box-flat--localization {
				
				border: none;
			}
				
				// flag
				.b-039b__list-item .button-box-flat--localization .button-box-flat__emblem--localization {
					
					box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
				}
				
				// label
				.b-039b__list-item .button-box-flat--localization .button-box-flat__label {
				
					border: none;
				}
				
				// arrow
				.b-039b__list-item .button-box-flat--localization .button-box-flat__icon {
					
					width: 0.75em;
				}
			
			
			// oddelovaci pseudo linka...
			.b-039b__list-item > .button-box-flat--localization:after {
				
				content: "";
				
				position: absolute;
				top: 0;
				left: 1em;
				right: 1em;
				
				border-bottom: 1px solid $color_divider;
			}
			// ...neni na poslednim miste
			.b-039b__list-item:first-child > .button-box-flat--localization:after {
				
				//content: initial; // nefunguje v IE
				display: none;
			}





// vizualni debug - plab only
.patternlab .b-039b {
	
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}