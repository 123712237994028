//
// a-007 Styles
//


$a-007_block-image_max-width: 10em;
$a-007_block-image_margin_vertical: 1.5em;
$a-007_block-description_margin_horizontal: 1.5em;
$a-007_item_background-arrow-size: 14px;



.a-007 {
	display: flex;
	flex-direction: column;

	@include breakpoint($breakpoint_desktop) {
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
	}

}



	// pozicovani: right
	.a-007--position-right .a-007__block--image {

		@include breakpoint($breakpoint_desktop) {
			order: 1;
		}
	}

	.a-007--position-right .a-007__block--description {

		@include breakpoint($breakpoint_desktop) {
			order: 2;
		}
	}

		.a-007--position-right .a-007__subblock--description {

			@include breakpoint($breakpoint_desktop) {
				margin-left: $a-007_block-description_margin_horizontal;
			}
		}

		.a-007--position-right .a-007__subblock--description::before {

			@include breakpoint($breakpoint_desktop) {
				left: -15px;
			}
		}


	// pozicovani: left
	.a-007--position-left .a-007__block--image {

		@include breakpoint($breakpoint_desktop) {
			order: 2;
		}
	}

	.a-007--position-left .a-007__block--description {

		@include breakpoint($breakpoint_desktop) {
			order: 1;
		}
	}

		.a-007--position-left .a-007__subblock--description {

			@include breakpoint($breakpoint_desktop) {
				margin-left: auto; // prebiji fallback
				margin-right: $a-007_block-description_margin_horizontal;
			}
		}

		.a-007--position-left .a-007__subblock--description::before {

			@include breakpoint($breakpoint_desktop) {
				left: auto; // prebiji fallback
				right: -15px;
			}
		}



	// block: image
	.a-007__block--image {

		@include breakpoint($breakpoint_desktop) {
			flex-shrink: 0;
			align-self: flex-start;

			max-width: $a-007_block-image_max-width;
			margin-top: $a-007_block-image_margin_vertical;
		}
	}

	// block: description
	.a-007__block--description {
	}

		// subblock: description
		.a-007__subblock--description {
			position: relative;

			padding: 0.5em;
			margin-top: 0.5em;

			background-color: $color_background-5;

			z-index: 0;

			@include breakpoint($breakpoint_desktop) {
				padding: 1em;
				margin-top: 0;
				margin-left: $a-007_block-description_margin_horizontal; // fallback
			}



			// totozne (krome h2 a p) s asstetem factbox (_node_article_assets.scss)
			// specifikace p:not(.cteteTake) a:not(.button) je haluzni fix, proti vetsi sile pravidla z _node.scss p:not(.cteteTake) a:not(.button)
			a:not(.button),
			strong a:not(.button),
			em a:not(.button),
			p:not(.cteteTake) a:not(.button),
			li a:not(.button) {
				@include typography_font(medium);
				@include link-style($color_text_link_inverted, underline, $color_background-2, underline);

				strong {
					color: unset;
				}
			}

			strong {
				@include typography_font(medium);
				color: $color_text_accent_inverted;
			}

			ol, ul {
				margin-top: 10px;
				margin-top: 0.625rem;
				margin-bottom: 10px;
				margin-bottom: 0.625rem;

				margin-left: 1em;

				li {
					list-style: inherit; // reset _box.scss (tyka se b-047)
					//margin-left: 1em;

					// FIX: floating overlap
					//position: relative;
					//left: 1em;
				}
			}

			ol {
				list-style: decimal;
			}

			ul {
				list-style: disc;
			}
		}

		// sipka
		.a-007__subblock--description::before {
			content: "";

			position: absolute;
			top: -6px;
			left: 2em;

			width: 12px;
			height: 12px;

			background-color: $color_background-5;

			transform: rotate(45deg);
			z-index: -1;

			@include breakpoint($breakpoint_desktop) {
				top: 3em;
				left: -15px; // fallback

				width: 30px;
				height: 30px;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.a-007__subblock--description h3 {
			margin: 0;

			font-size: 20px;
			line-height: 1.3;

			//color: $color_text_paragraph_inverted;
			color: $color_background-2;

			@include breakpoint($breakpoint_desktop) {
				font-size: 24px;
				line-height: 1.3;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.a-007__subblock--description p {
			margin-top: 0.5em;
			margin-bottom: 0;

			font-size: 14px;
			line-height: 1.3;

			//color: $color_text_paragraph_inverted;
			color: $color_background-2;

			@include breakpoint($breakpoint_desktop) {
				font-size: 16px;
			}
		}


		// block-image: img-data
		.a-007 .a-007__block--image .img-metadata {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: inline-block;
			}
		}

		// block-description: img-data
		.a-007 .a-007__block--description .img-metadata {
			display: inline-block;

			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
		}





// vizualni debug - plab only
.patternlab {
	.a-007 {
		@include breakpoint($breakpoint_desktop) {
			max-width: 755px;
		}
	}
}
