//
// B-006f
//



.b-006f {
	
}
	
	.b-006f__subsection {
		
		display: flex;
		flex-direction: column;
		
		background-color: transparent;
		
		@include breakpoint($breakpoint_desktop) {
			
			flex-direction: row;
		}
	}
	
	.b-006f__list {
		
		@include breakpoint($breakpoint_desktop) {
			
			width: 30%;
			
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
	
	.b-006f__list.b-006f__list--primary {
		
		@include breakpoint($breakpoint_desktop) {
			
			width: 70%;
			
			margin-right: 2em;
		}
	}
		
		.b-006f__list-item {
			
			margin-bottom: 0;
		}
		
		.b-006f__list-item:first-child {
			
			margin-bottom: 1em;
			
			@include breakpoint($breakpoint_desktop) {
				
				margin-bottom: 0;
			}
			
		}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006f__list-item h3 {
				
				margin: 0;
				padding-top: 0.5em;
			}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006f__list:not(.b-006f__list--primary) .b-006f__list-item:first-child h3 {
				
				@include breakpoint($breakpoint_desktop) {
				
					margin-bottom: 1em; // jen pro pripad viceradkoveho titulku, jinak mezeru mezi itemy resi justify-content: space-between
				}
			}