//
// mvla - article assets
//
// nazvy trid originalni hruza z RSCR (c) 2010 Pitr Kandelabr
//



// citace
$node_article_assets_citation_quote_font-size_mobile: 18px;
$node_article_assets_citation_quote_font-size_desktop: 24px;
$node_article_assets_citation_quote_line-height_mobile: 1.4em;
$node_article_assets_citation_quote_line-height_desktop: 1.45em;
$node_article_assets_citation_quote_long_font-size_desktop: 16px;
$node_article_assets_citation_quote_long_line-height_desktop: 1.4em;
$node_article_assets_citation_author_font-size_mobile: 17px;
$node_article_assets_citation_author_font-size_desktop: 20px;


// factbox
$node_article_assets_factbox_padding_horizontal: 10px;
$node_article_assets_factbox_font-size_mobile: 14px;
$node_article_assets_factbox_font-size_desktop: 16px;
$node_article_assets_factbox_h2_font-size_mobile: 20px;
$node_article_assets_factbox_h2_font-size_desktop: 24px;

// cteteTake / asset Clanek
$node_article_assets_ctetetake_padding_horizontal: 10px;
$node_article_assets_ctetetake_caption_font-size: 22px;
$node_article_assets_ctetetake_link_font-size: 18px;

// related
$node_article_assets_related_padding_horizontal: 10px;
$node_article_assets_related_font-size_mobile: 16px;
$node_article_assets_related_font-size_desktop: 18px;

// icon path
$icon-path: "../images/icon/";


.page-node .body,
.b-047 .body {

	//
	// snippets - citace
	//
	.citation {

		display: block;

		margin-top: 1em;
		margin-bottom: 1em;

		margin-left: 0;
		padding-left: 2.5em;

		background-image: $file_icon_quotation;
		background-repeat: no-repeat;
		background-position: left 0.25em;
		background-size: 1.75em;
		min-height: 2em; // aby nedoslo k orezu (bg size 1.75 + bg pos 0.25)

		@include breakpoint($breakpoint_desktop) {
			margin: 1.5em -1.875rem;
			padding-left: 4.5em;
			background-size: 2em;
			min-height: 2.25em; // aby nedoslo k orezu (bg size 2 + bg pos 0.25)
		}

		&.citation-long {
			@include breakpoint($breakpoint_desktop) {
				padding-left: 3.5em;
			}

			> em {
				@include breakpoint($breakpoint_desktop) {
					font-size: $node_article_assets_citation_quote_long_font-size_desktop;
					line-height: $node_article_assets_citation_quote_long_line-height_desktop;
				}
			}
		}

		> em {

			display: block;
			font-style: normal;
			color: $color_text_accent;
			@include typography_font(medium);
			font-size: $node_article_assets_citation_quote_font-size_mobile;
			line-height: $node_article_assets_citation_quote_line-height_mobile;
			@include breakpoint($breakpoint_desktop) {
				font-size: $node_article_assets_citation_quote_font-size_desktop;
				line-height: $node_article_assets_citation_quote_line-height_desktop;
			}
		}

		> br { display: none; }

		// z historickych duvodu je strong jako autor citace
		> strong {

			display: block;
			margin-top: 0.25em;
			color: $color_text_accent;
			@include typography_font(regular);
			font-size: $node_article_assets_citation_author_font-size_mobile;
			@include breakpoint($breakpoint_desktop) {
				font-size: $node_article_assets_citation_author_font-size_desktop;
			}
		}
	} // citation



	//
	// snippets - factbox
	//
	.factbox {

		display: block;
		clear: both; // nefloatovat kdy je v toku napr. za Ctete take

		margin-top: 1em;
		margin-bottom: 1em;

		padding: 0.5em $node_article_assets_factbox_padding_horizontal;

		background-color: $color_background-5;
		color: $color_background-2;

		font-size: $node_article_assets_factbox_font-size_mobile;
		line-height: calc(#{$node_article_assets_factbox_font-size_mobile} + 10px);

		@include breakpoint($breakpoint_desktop) {
			margin: 1.5em -1.875rem;
			padding: 1em 1.5em;

			font-size: $node_article_assets_factbox_font-size_desktop;
			line-height: calc(#{$node_article_assets_factbox_font-size_desktop} + 10px);
		}

		h2 {
			@include typography_font(medium);
			font-size: $node_article_assets_factbox_h2_font-size_mobile;
			line-height: calc(#{$node_article_assets_factbox_h2_font-size_mobile} + 10px);

			margin-top: 10px;
			margin-top: 0.625rem;
			margin-bottom: 10px;
			margin-bottom: 0.625rem;

			color: $color_text_accent_inverted;

			@include breakpoint($breakpoint_desktop) {
				font-size: $node_article_assets_factbox_h2_font-size_desktop;
				line-height: calc(#{$node_article_assets_factbox_h2_font-size_desktop} + 10px);
			}
		}

		p {
			margin-top: 10px;
			margin-top: 0.625rem;
			margin-bottom: 10px;
			margin-bottom: 0.625rem;

			font-size: $node_article_assets_factbox_font-size_mobile;
			line-height: calc(#{$node_article_assets_factbox_font-size_mobile} + 10px);

			@include breakpoint($breakpoint_desktop) {
				font-size: $node_article_assets_factbox_font-size_desktop;
				line-height: calc(#{$node_article_assets_factbox_font-size_desktop} + 10px);
			}
		}

		// specifikace p:not(.cteteTake) a:not(.button) je haluzni fix, proti vetsi sile pravidla z _node.scss p:not(.cteteTake) a:not(.button)
		a:not(.button),
		strong a:not(.button),
		em a:not(.button),
		p:not(.cteteTake) a:not(.button),
		li a:not(.button) {
			@include typography_font(medium);
			@include link-style($color_text_link_inverted, underline, $color_background-2, underline);

			strong {
				color: unset;
			}
		}

		strong {
			@include typography_font(medium);
			color: $color_text_accent_inverted;
		}

		ol, ul {
			margin-top: 10px;
			margin-top: 0.625rem;
			margin-bottom: 10px;
			margin-bottom: 0.625rem;

			//margin-left: 1em;

			li {
				list-style: inherit; // reset _box.scss (tyka se b-047)
				//margin-left: 1em;

				// FIX: floating overlap
				//position: relative;
				//left: 1em;
			}
		}

		ol {
			list-style: decimal;
			margin-left: 1.5em;
		}

		ul {
			list-style: disc;
			margin-left: 1em;
		}

		&.factbox--position-left {
			@include breakpoint($breakpoint_desktop) {
				float: left;
				clear: left;
				width: 40%;
				min-width: 11em;

				margin-top: 0;
				margin-bottom: 1em;
				margin-left: -$node_field_padding-left;
				margin-right: 1.5em;
			}
		}

		&.factbox--position-right {
			@include breakpoint($breakpoint_desktop) {
				float: right;
				clear: right;
				width: 40%;
				min-width: 11em;

				margin-top: 0;
				margin-bottom: 1em;
				margin-left: 1.5em;
				margin-right: -$node_field_padding-left;
			}
		}

	} // factbox



	//
	// snippets - audio
	//
	.asset-type-audio {

		// nakonec nepotrebujeme, odrazeni ma dite .file-audio ve _node_.scss
		/*margin-top: 1.5em;
		margin-bottom: 1.5em;*/

		@include breakpoint($breakpoint_desktop) {
			clear: both; // nefloatovat kdy je v toku napr. za Ctete take
		}
	} // audio



	//
	// snippets - button__wrapper--aligned-stretch
	//
	.button__wrapper--aligned-stretch {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			clear: both; // nefloatovat kdy je v toku napr. za Ctete take

			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: -$node_field_padding-left;
			margin-right: -$node_field_padding-left;
		}
	} // asset button__wrapper--aligned-stretch



	//
	// snippets - ctete take (novy)
	//
	.a-001 {
		margin-top: 0;
		margin-bottom: 1em;

		@include breakpoint($breakpoint_desktop) {
			float: left;
			clear: left;
			width: 40%;
			min-width: 11em;

			margin-left: -$node_field_padding-left;
			margin-right: 1.5em;
		}

		&.a-001--position-left {
			@include breakpoint($breakpoint_desktop) {
				float: left;
				clear: left;

				margin-left: -$node_field_padding-left;
				margin-right: 1.5em;
			}
		}

		&.a-001--position-right {
			@include breakpoint($breakpoint_desktop) {
				float: right;
				clear: right;

				margin-left: 1.5em;
				margin-right: -$node_field_padding-left;
			}
		}

		.a-001__block h3 {
			@include typography_font(medium);
		}
	} // asset ctete take (novy)



	//
	// snippets - kredits
	//
	.a-002 {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			clear: both; // nefloatovat kdy je v toku napr. za Ctete take

			margin-left: -$node_field_padding-left;
			margin-right: -$node_field_padding-left;
		}

		&.a-002--position-left {
			margin-top: 0;
			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				float: left;
				clear: left;
				width: 40%;
				min-width: 11em;

				margin-left: -$node_field_padding-left;
				margin-right: 1.5em;
			}
		}

		&.a-002--position-right {
			margin-top: 0;
			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				float: right;
				clear: right;
				width: 40%;
				min-width: 11em;

				margin-left: 1.5em;
				margin-right: -$node_field_padding-left;
			}
		}
	} // asset kredits



	//
	// snippets - faq
	//
	.a-003 {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			clear: both; // nefloatovat kdy je v toku napr. za Ctete take

			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: -$node_field_padding-left;
			margin-right: -$node_field_padding-left;
		}
	} // asset faq



	//
	// snippets - k prodeji na radiotece
	//
	.a-006 {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			clear: both; // nefloatovat kdy je v toku napr. za Ctete take

			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: -$node_field_padding-left;
		}
	} // asset k prodeji na radiotece



	//
	// snippets - medailonek
	//
	.a-007 {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			clear: both; // nefloatovat kdy je v toku napr. za Ctete take

			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: -$node_field_padding-left;
			margin-right: -$node_field_padding-left;
		}
	} // asset medailonek



	//
	// snippets - keywordpromo
	//
	.a-008 {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			clear: both; // nefloatovat kdy je v toku napr. za Ctete take

			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: -$node_field_padding-left;
			margin-right: -$node_field_padding-left;
		}
	} // asset keywordpromo



	//
	// snippets - mapa regionu
	//
	.asset-type-map-cr-regions {

		// box
		.views-exposed-map {
			background-color: $color_background-2;

			margin-top: 1.5em;
			margin-bottom: 1.5em;
			padding: 1em;

			@include breakpoint($breakpoint_desktop) {
				clear: both; // nefloatovat kdy je v toku napr. za Ctete take

				margin-top: 1.5em;
				margin-bottom: 1.5em;
				margin-left: -$node_field_padding-left;
				margin-right: -$node_field_padding-left;
			}
		}

			// svg
			.views-exposed-map svg {}

				// link
				.views-exposed-map svg .map-link {}

					// svg
					.views-exposed-map svg .map-region {
						fill: $color_region_main;
					}

					// polygon: region
					.views-exposed-map svg .map-link:hover .map-region {
						fill: $color_region_main_darker;
					}

					.views-exposed-map svg .map-link--selected .map-region {
						fill: $color_region_add-1;
					}

					// polygon, path, rect: region, city, text
					.views-exposed-map svg .map-region,
					.views-exposed-map svg .map-city,
					.views-exposed-map svg .map-city-text {
						fill-rule: evenodd;
					}

					.views-exposed-map svg .map-city {
						fill: $color_background-4;
					}

					.views-exposed-map svg .map-city-text {
						fill: $color_text_accent_inverted;
					}

	} // asset mapa regionu



	//
	// snippets - ctete take (puvodni markup i zjednoduseny tvar - ciste text, ala rscr)
	// pozor, lecktere vlastnosti kopiruje nasledujici "asset Clanek", vizualne je stvoren ala tento snippet
	// takze co je spolecne tak zahrnuje mixin
	//
	@mixin cteteTake_layout() {

		margin-top: 0;
		margin-bottom: 1em;

		@include breakpoint($breakpoint_desktop) {

			float: left;
			clear: left;
			width: 40%;
			min-width: 11em;

			margin-left: -$node_field_padding-left;
			margin-right: 1.5em;
		}
	}
	@mixin cteteTake_caption() {

		display: block;

		padding: 0.25em $node_article_assets_ctetetake_padding_horizontal;

		// default, k pretizeni stanicni barvou
		color: $color_decoration_main;

		text-transform: uppercase;
		font-size: $node_article_assets_ctetetake_caption_font-size;
	}
	@mixin cteteTake_title-link() {

		display: block;

		padding: 0.5em $node_article_assets_ctetetake_padding_horizontal;

		@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);

		// default, k pretizeni stanicni barvou
		background-color: $color_decoration_main;

		font-size: $node_article_assets_ctetetake_link_font-size;
		line-height: 1.2em;
		@include typography_font(medium);

		text-transform: initial;
	}

	.cteteTake,
	.body .cteteTake {

		display: block;

		@include cteteTake_layout();

		// nadpis "ctete take"
		> strong {

			@include typography_font(bold);

			@include cteteTake_caption();
		}

		// link - titulek clanku
		a {
			@include cteteTake_title-link();
		}

	} // cteteTake

	//
	// asset Clanek - asset pro zobrazeni Titulku, perexu a fotky konrektniho clanku ze systemu
	// velice souvisejici s predchazejicim cteteTake, defakto je stylovan do stejneho vizualu
	//
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-article,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-event,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-recipe,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-serial,
	.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-show {

		.contextual-links-wrapper {
			display: none;
		}

		@include cteteTake_layout();

		&.clearfix {
			overflow: visible;
		}

		// nadpis "ctete take"
		> h2::before {

			content: "Čtěte také";

			@include cteteTake_caption();

			padding-top: 0;
			padding-bottom: 0;
		}
		// link - titulek clanku
		> h2 {

			margin: 0;
			padding: 0;

			> a {

				@include cteteTake_title-link();
			}
		}

		// obrazek, v mobile skryty
		> .content > .field-name-field-image-primary {

			display: none;

			@include breakpoint($breakpoint_desktop) {

				display: block;
				line-height: 0;
			}
		}

		// perex (zatim skryty)
		> .content > .field-name-field-perex {

			display: none;
		}

	} // asset Clanek



	//
	// snippets - image
	//
	.asset-type-image {

		margin-top: 0;
		margin-bottom: 1em;
	} // asset image



	// Negativni odsazeni assetu

	.asset-type-image.image__big_img,
	.asset-type-video {
		@include breakpoint($breakpoint_desktop) {
			margin-left: -$node_padding;
			margin-right: -$node_padding;
		}
	}

	.asset-type-image.image__small_img_left {
		@include breakpoint($breakpoint_desktop) {
			margin-left: -$node_padding;
		}
	}

	.asset-type-image.image__small_img_right {
		@include breakpoint($breakpoint_desktop) {
			margin-right: -$node_padding;
		}
	}

	//
	// snippets - souvisejici prispevky / weby / odkazy ("odkazy" v 04.assets)
	// vsechny maji stejnou tridu "related_articles"
	//
	.related_articles {

		display: block;
		clear: both; // nefloatovat kdy je v toku napr. za Ctete take

		margin-top: 1em;
		margin-bottom: 1em;

		padding: 0.5em $node_article_assets_related_padding_horizontal;

		background-color: $color_background-1;
		color: $color_text_accent;

		@include typography_font(medium);
		font-size: $node_article_assets_related_font-size_mobile;

		@include breakpoint($breakpoint_desktop) {
			margin: 1.5em -1.875rem;
			font-size: $node_article_assets_related_font-size_desktop;
		}

		> h3 {
			margin-top: 0;
			margin-bottom: 0.75em;

			text-transform: uppercase;
		}

		> h4 {
			margin-top: 0;
			margin-bottom: 0.5em;

			> a {
				color: $color_decoration_main;
			}
		}
		> h4:last-child {
			margin-bottom: 0;
		}

	} // related_articles



	//
	// asset priloha
	//
	.asset-type-attachment {
		margin-bottom: 10px;

		a {
			font-size: 0.85rem;
			color: $color_decoration_main;
			@include breakpoint($breakpoint_desktop) {
				font-size: 1rem;
			}
		}

		a:before {
			content: "";
			display: block;
			background: url("#{$icon-path}ico_attachment.svg") 0 0 no-repeat;
			width: 20px;
			height: 20px;
			float: left;
			margin: 4px 10px 0 0;
		}
	} // priloha



	//
	// asset HTML embeds
	//
	.html-embed {
		margin-top: 20px;
		margin-top: 1.25rem;
		margin-bottom: 20px;
		margin-bottom: 1.25rem;

		iframe {
			border: none;
		}
	} // html-embeds



	.croplayer {
		width: unset;
	}

}





//
// Hiding badly rendered assets
//

.page-node .body
.b-047 .body {

	p > .wysiwyg-asset-type-image,
	p > .wysiwyg-asset-type-audio,
	p > .wysiwyg-asset-type-video,
	p > .wysiwyg-asset-type-article,
	p > .wysiwyg-asset-type-photogallery,
	p > .wysiwyg-asset-type-citation,
	p > .wysiwyg-asset-type-factbox,
	p > .wysiwyg-asset-type-readbox,
	p > .wysiwyg-asset-type-links {
		display: none;
	}

	// Hiding edit, clone tabs of asset entities for logged in users. No styles yet.
	div.wysiwyg-asset .contextual-links-wrapper {
		display: none;
	}

	.field-name-field-audio-duration {
		display: none;
	}
}

//
// Metadata for image isset
//
.page-node .asset-type-image .node-image-metadata__author,
.page-node .asset-type-image .node-image-metadata__source,
.page-node .asset-type-image .node-image-metadata__licence {
    color: black;
}





/*
 * Assety, ktere jsou mimo body
 */
.page-node,
.b-047 {

	//
	// snippets - odebirat podcast
	//
	.a-005 {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: $node_left-margin;
		}
	} // asset odebirat podcast



	//
	// snippets - keywordpromo
	//
	.a-008 {

		margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: $node_left-margin;
		}
	} // asset keywordpromo



	//
	// snippets -  box
	//
	// docasne jen pokud je pod obsahovym kontejnerem .content-1--7-2
	// TODO: nasledne prejde odrazeni tohoto a podobnych assetu na obsahovy kontejner .content-1--7-2
	.content-1--7-2 {
		//.b-004,
		//.b-007,
		//.b-022,
		.box {

			@include breakpoint($breakpoint_desktop) {
				margin-left: $node_left-margin;
			}
		} // asset box
	}
}



/*
 * Pokud nema hlavni obrazek/audio/video (a je v .body)
 * Pokud nema vert. soc buttony
 */
.page-node.no-main-media .body, // page
.b-047.no-main-media .body, // page

.page-node.no-social-icons .body, // event, page
.b-047.no-social-icons .body { // event, page

	// Reset Negativnich odsazeni assetu

	.asset-type-image.image__big_img,
	.asset-type-video {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.asset-type-image.image__small_img_left {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}

	.asset-type-image.image__small_img_right {

		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}

	.citation,
	.factbox,
	.related_articles {

		@include breakpoint($breakpoint_desktop) {
			margin: 1.5em 0;
		}
	}

	.factbox {

		&.factbox--position-left {

			@include breakpoint($breakpoint_desktop) {
				margin-top: 0;
				margin-bottom: 1em;
				margin-left: 0;
				margin-right: 1.5em;
			}
		}

		&.factbox--position-right {

			@include breakpoint($breakpoint_desktop) {
				margin-top: 0;
				margin-bottom: 1em;
				margin-left: 1.5em;
				margin-right: 0;
			}
		}
	}

	.button__wrapper--aligned-stretch {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.a-001 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 1.5em;
		}

		&.a-001--position-left {

			@include breakpoint($breakpoint_desktop) {
				margin-left: 0;
				margin-right: 1.5em;
			}
		}

		&.a-001--position-right {

			@include breakpoint($breakpoint_desktop) {
				margin-left: 1.5em;
				margin-right: 0;
			}
		}
	}

	.a-002 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
		}

		&.a-002--position-left {

			@include breakpoint($breakpoint_desktop) {
				margin-left: 0;
				margin-right: 1.5em;
			}
		}

		&.a-002--position-right {

			@include breakpoint($breakpoint_desktop) {
				margin-left: 1.5em;
				margin-right: 0;
			}
		}
	}

	.a-003 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.a-006 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}

	.a-007 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.a-008 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.asset-type-map-cr-regions {
		.views-exposed-map {

			@include breakpoint($breakpoint_desktop) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}



/*
 * Pokud nema hlavni obrazek/audio/video (a neni v .body)
 * Pokud nema vert. soc buttony
 */
.page-node.no-main-media, // page
.b-047.no-main-media, // page

.page-node.no-social-icons, // event, page
.b-047.no-social-icons { // event, page

	// Reset Negativnich odsazeni assetu

	.event-annotation,
	//.b-004,
	//.b-007,
	//.b-022,
	//.box,
	.b-041k__anchor,
	.node_attachment,
	.node-block--authors {
		@include breakpoint($breakpoint_desktop) {
			margin: 1.5em 0;
		}
	}

	.a-005 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}

	.a-008 {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
