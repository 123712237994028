//
// Rakouský rok - obecne vlastnosti vsech stranek
//

// vars
$rakouskyrok-bg-desktop: url('../images/bg/rakouskyrok/rakousky_rok_bg.jpg?20170209');
$rakouskyrok-bg-mobile: url('../images/bg/rakouskyrok/rakousky_rok_bg_mobil.jpg');
$rakouskyrok-bg-blue: url('../images/bg/rakouskyrok/rakousky_rok_bg_modra_kosa.svg');
$rakouskyrok-bg-people: url('../images/bg/rakouskyrok/rakousky_rok_bg_postavy.png');
$rakouskyrok-logo-mobile: url('../images/logo/rakouskyrok/rakousky_rok-mobile.svg');
$rakouskyrok-logo-desktop: url('../images/logo/rakouskyrok/rakousky_rok-desktop.svg');

$rakouskyrok-flag: url('../images/icon/rakouskyrok/bar_200x66.svg');

.rakouskyrok {
	
	// coloring
	.box h2 {
		a {
			@include link-style($color_corporate_add-2, none, $color_corporate_add-2, underline);
		}
	}
	
	.page {
		z-index: 1;
	}
	
	// logo, main-menu
	.rr-nav {
		position: relative;
		
		display: flex;
		flex-direction: column;
		
		height: 19em;
		
		@include breakpoint($breakpoint_tablet) {
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
			
			height: 17em;
		}
		
		.logo {
			flex-shrink: 0;
			
			background-image: $rakouskyrok-logo-mobile;
			background-repeat: no-repeat;
			background-position: left top;
			
			width: 12em;
			height: 3.5em;
			
			margin: 1em 0.5em;
			
			@include breakpoint($breakpoint_tablet) {
				background-image: $rakouskyrok-logo-desktop;
				
				width: 17em;
				height: 10em;
			
				margin-top: 3.5em;
				margin-bottom: 3.5em;
				margin-left: $layout_main_horizontal-padding_desktop;
				margin-right: $layout_main_horizontal-padding_desktop;
			}
			
			a {
				display: block;
				
				width: 100%;
			}
			
			.logo-rr {
				width: 187px;
				height: 27px;
				
				margin-bottom: 10px;
				
				@include breakpoint($breakpoint_tablet) {
					width: 265px;
					height: 105px;
					
					margin-bottom: 26px;
				}
			}
			
			.logo-vltava {
				width: 54px;
				height: 14px;
				
				@include breakpoint($breakpoint_tablet) {
					width: 82px;
					height: 22px;
				}
			}
		}
		
		// bg stripe mozaik
		.logo:before {
			
			content: "";
			
			background-image: $rakouskyrok-bg-mobile;
			background-repeat: no-repeat;
			background-size: cover;
			background-attachment: fixed;
			background-position: center top;
			
			position: absolute;
			top: 0;
			left: 0;
			
			width: 960px;
			height: 17.5em;
			
			z-index: -2;
			
			@include breakpoint($breakpoint_desktop) {
				background-image: $rakouskyrok-bg-desktop;
				width: 3000px;
				height: 19em;
				
				left: -875px
			}
		}
		
		// bg left blue stripe
		.logo:after {
			
			content: "";
			
			background-image: $rakouskyrok-bg-blue;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: right top;
			
			position: absolute;
			top: 0;
			left: -77em;
			
			width: 95em;
			height: 17.5em;
			
			z-index: -1;
			
			@include breakpoint($breakpoint_tablet) {
				left: -70em;
				
				height: 19em;
			}
			
			@include breakpoint($breakpoint_desktop) {
			}
		}
	}
	
	.rr-nav__list {
		display: flex;
		flex-wrap: no-wrap;
		flex-direction: column;
		
		width: auto;
		
		margin: 1em 0;
		
		@include breakpoint($breakpoint_tablet) {
			width: 22em;
			
			margin: 0 3em 4em 0;
		}
				
		@include breakpoint($breakpoint_desktop) {
			flex-wrap: wrap;
			flex-direction: row;
			//justify-content: flex-end;
			align-items: flex-start;
			
			width: auto;
			
			margin: 0 3em 2.5em 0;
		}
	}
	
	// bg right persons
	// nemuze byt pseudotrida na tento flexovany .rr-nav element, nefunguji korektne vypocty pro justify-items: space-between
	.rr-nav__list:after {
		@include breakpoint($breakpoint_tablet) {
			content: "";
			
			background-image: $rakouskyrok-bg-people;
			background-repeat: no-repeat;
			background-position: center -4em;
			
			position: absolute;
			top: 0;
			left: 13.75em;
			
			width: 40em; //26em
			height: 19em;
			
			z-index: -1;
		}
		
		@include breakpoint($breakpoint_desktop) {
			background-position: center -4em;
			
			right: 0;
			left: auto;
		}
	}
	
	.rr-nav__list-item {
		background-color: #e02634;
		
		background-image: $rakouskyrok-flag;
		background-repeat: no-repeat;
		background-position: left center;
		background-size: auto 0.75em;
		
		margin: 0 .5em;
		margin-bottom: 0.75em;
		
		a {
			@include link-style( $color_text_accent_inverted, none,  $color_text_accent_inverted, none);
			
			display: block;
			
			background-image: $file_icon_arrow_single_east_colored-white;
			background-repeat: no-repeat;
			background-position: right 0.5em center;
			background-size: auto 0.75em;
			
			width: 100%;
			
			padding: 0.5em 1.5em 0.5em 3em;
			
			text-transform: uppercase;
			
			@include breakpoint($breakpoint_desktop) {
				background-image: none;
				
				padding: 0.5em 1em 0.5em 3em;
			}
		}
	}
		
		.rr-nav__list-item:hover {
			//background-color: rgba(214, 48, 60, 0.8);
			background-color: #b0232d;
		}
	
	
	.rr-nav__list-item:last-child {
		margin-bottom: 0;
	}
}



// header vs nav+h1 fix
/* rakousky rok nav h1, h2 fix */
.rakouskyrok {
	.b-016__wrapper {
		margin-top: 0;
		margin-bottom: 0;
		
		h1,
		h2 {
			display: none;
		}
		
		.rr-nav {
			flex-grow: 1;
		}
	}
	
	.main {
		margin-top: 0;
		padding-top: 0.1px;
	}
}