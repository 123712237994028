//
// B-002c Styles
//
// markup tohoto boxu je stejny (velmi podobny) s b002b
// zmeny jsou na urovni stylu
//



$b-002c_primary-heading_fontsize_mobile: 24px;
$b-002c_primary-heading_fontsize_desktop: 28px;



.b-002c {

	@include breakpoint($breakpoint_desktop) {

		display: flex;
		flex-direction: row;
	}
}

	// obecny seznam
	.b-002c__list {
	}

		// obecny element
		.b-002c__list-item {

			@include breakpoint($breakpoint_desktop) {
				margin-right: 1em;
			}
		}

			.b-002c__block {
				position: relative;
			}

				.b-002c__image {
				}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002c__image .badge {
						position: absolute;
						top: 0;
						right: 0;
					}

					// buttonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002c__image .button-video-play-box {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}


				.b-002c__overlay {
					position: absolute;
					bottom: 0;
					left: 10px;
					right: 10px;
				}

					.b-002c__overlay-content {
					}

						// tag, (h3)



	// pseudoseznam s jednim (prvnim) elementem
	.b-002c__list--primary {

		@include breakpoint($breakpoint_desktop) {

			// na desktopu zabira 1/3 sirky boxu
			flex-basis: 25%;
		}
	}

		// specifika: prvni velky element
		.b-002c__list-item--list-primary {

			flex-basis: 100%;

			margin-bottom: 1em;
		}

			// obecne vlastnosti nadpisu prvniho elementu
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002c__list-item--list-primary h3 {

				margin: 0;
				padding: 0.5em;

				background-color: $color_background-4;

				@include adjust-font-size-to($b-002c_primary-heading_fontsize_mobile, 1.5);

				@include breakpoint($breakpoint_desktop) {

					clear: both;
					display: inline-block;
					float: left;

					@include adjust-font-size-to($b-002c_primary-heading_fontsize_desktop, 1.25);
				}
			}

			.b-002c__overlay--item-list-primary {
			}

				// nadpis uvnitr overlay - pouze pro desktop
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002c__overlay--item-list-primary > .b-002c__overlay-content > h3 {

					display: none;

					@include breakpoint($breakpoint_desktop) {

						display: block;

						padding: 0.75em 1em;

						background-color: $color_background-4_alpha-low;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002c__overlay--item-list-primary .tag {

					display: inline-block;
					float: left;
				}


			// druha instance nadpisu mimo overlay - pouze pro mobile
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002c__list-item--list-primary > h3 {

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}



	// seznam nasledujicich elementu
	.b-002c__list--following {

		display: flex;

		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {

			// na dekstopu zabira 3/4 sirky boxu
			flex-basis: 75%;

			flex-direction: row;
		}
	}

		// specifika: nasledujici elementy (vsechny)
		.b-002c__list-item--list-following {

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {

				flex-basis: 33.33%;

				margin-bottom: 0;
				margin-right: 1em;
			}
		}
		.b-002c__list-item--list-following:last-child {

			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			.b-002c__overlay--item-list-following {
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-002c__list-item--list-following h3 {

					margin: 0;
					padding: 0.5em 0.5em;

					@include breakpoint($breakpoint_desktop) {
						padding: 0.5em 0;
					}
				}

				// tag
