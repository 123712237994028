//
// B-099d Styles
//



.b-099d {
}

// list
.b-099d__list {
}

	// list: item
	.b-099d__list-item {
		margin-bottom: 1em;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 1.25em;
		}
	}

	// list: item
	.b-099d__list-item:last-child {
		margin-bottom: 0;
	}

		// image
		.b-099d__image {
			position: relative;
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-099d__image .tag {
				position: absolute;
				bottom: 0.75em;
				left: 0;

				@include lineClamp(1);

				pointer-events: none;
				opacity: 0.9;

				@include breakpoint($breakpoint_desktop) {
					font-size: 18px;
					line-height: 1.3;
				}
			}





// vizualni debug - plab only
.patternlab .b-099d {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
