//
// 1968 B-014b Styles
//
// puvodni BEMove classovani zachovano nad vnorenymi selektory
//



.domain-1968-rozhlas-cz {

	// skryta cast hlavniho menu (polozky pro "vice")
	.b-014b {
	
		position: absolute;
	}


	// sekundarni seznam
	.b-014b__list-wrapper {
		//background-color: transparent;
	}
	.b-014b__list-wrapper::before {
		//opacity: 0.85;

		// podkladovy absolutne pozicovany pruh, sirsi nez sirka stranky
		// jen pro desktop, na mobile postrada smysl
		@include breakpoint($breakpoint_desktop) {
			box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.25);
		}
	}
}