//
// B-017 Styles
//
// zajimavy jev je to, ze obsah paty je v mobilni variante odsazen od okraje stranky souctem zalozenem na
// univerzalnim odsazeni mobilni stranky, neboli: $layout_main_horizontal-padding_mobile +
// + designove odsazeni obsahu boxu (tj. treba odsazeni tagy nebo textu v boxu), coz je pro mobile 0.5em
//


$b-017_child-section_horizontal-padding_mobile: $layout_main_horizontal-padding_mobile;
$b-017_child-section_horizontal-padding_desktop: 2.5em;



.b-017 {
	
	background-color: $color_background-6;
	
	margin-top: 4em;
	
	@include breakpoint($breakpoint_desktop) {
		
		margin-top: 6em;
		margin-bottom: 3em;
		
		margin-left: $layout_main_horizontal-padding_desktop;
		margin-right: $layout_main_horizontal-padding_desktop;
	}
}