//
// Wave B-015
//
// wave-specific prekrucovani hlavicky
//
// je namiste zde poznamenat ze pokud by se wave typ mel sirit na dalsi weby, tak zpracovat obecne
// asi jako samostatny pismenny podtyp b015
//



$wave_b-015_background-color: $color_background-9;
$wave_b-015_background-color_mobile-gradient-supplement: $color_background-8;

$wave_b-015_launcher-visual_padding-top: 10px;
$wave_b-015_launcher-visual_width-reduction: ($wave_b-015_launcher-visual_padding-top / 2 * 3) * 2;



.wave .b-015 {
	
	background-color: $wave_b-015_background-color;
	
	
	// prebarveni bloku launcheru v mobilni verzi
	// toto se netyka desktop - tam je vychozi stav resetovaci none, je treba zachovat
	.b-015__list-item--right {
		
		background-image:
			linear-gradient(to bottom,
							$wave_b-015_background-color_mobile-gradient-supplement 0%,
							$wave_b-015_background-color 60%);
		
		@include breakpoint($breakpoint_desktop) {
			background-image: none;
		}
	}
		
		// zmenseni vizualu lancheru
		/*.b-015__launcher-visual {
			
			// zde je klicove zachovani pomeru paddingu 2:3 odpovidajici pomeru vizualu 3:2
			width: ($b-015_launcher_height / 2 * 3) - $wave_b-015_launcher-visual_width-reduction;
			padding-top: $wave_b-015_launcher-visual_padding-top;
		}*/
			
			// prebarveni progressbaru prave hraneho poradu
			.b-015__launcher-info-timing-progressbar-progress {
				
				background-color: $color_wave_main;
			}
			
			// prebarveni schovavaciho gradientu prave hrane skladby
			.b-015__launcher-info-description-overflow-mask {
				
				background-image: linear-gradient(90deg, rgba($wave_b-015_background-color, 0), $wave_b-015_background-color);
			}
}