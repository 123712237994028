//
// Hledani - obecne vlastnosti vsech stranek
//



$hledani_selector_body: 'body.hledani';
$hledani_selector_body-hp: $pohoda_selector_body + '.page-hledani-hp';
$hledani_form_shadow-inner: inset 0 0 6px rgba(0, 0, 0, 0.1);
$hledani_form_shadow-outer: 0 0 6px rgba(0, 0, 0, 0.15);

$hledani_form_input_color: $color_background-6;


#{$hledani_selector_body} {

	// grid
	.l-grid--12-1 {
		overflow: visible; // kvuli custom-select v mobilnim pohledu
	}

	// custom select
	.custom-select {
		position: relative;
		width: 100%;

		@include breakpoint($breakpoint_desktop) {
			width: 12em;
		}
	}
	.form-type-select--sort.custom-select {
		@include breakpoint($breakpoint_desktop) {
			width: 10em;
		}
	}

		.custom-select select {
			display: none;
		}

		.select-selected {
			background-image: $file_icon_arrow_single_south_colored-decoration-main;
			background-position: right 10px center;
			background-repeat: no-repeat;
			background-size: auto 10px;

			padding-right: 2em;

			line-height: 2.5em;
			@include lineClamp(1);

			color: $hledani_form_input_color;

			border: 1px solid $color_divider;
			border-radius: 4px;
			box-shadow: $hledani_form_shadow-inner;
		}
		.form-type-select--sort .select-selected {
			background-size: auto 8px;

			line-height: 2em;

			background-color: $color_background-2;
			color: $color_background-9;

			border: initial;
			border-radius: initial;
			box-shadow: initial;
		}

		.select-selected.select-arrow-active {
			background-image: $file_icon_arrow_single_north_colored-decoration-main;
		}

		.select-selected,
		.select-items div {
			padding-left: 0.75em;

			width: 100%;

			border: 1px solid $color_divider;

			cursor: pointer;
			outline: none;

			@include no-select;

			transition: background-color 0.2s ease-in-out;
		}
		.form-type-select--sort .select-selected,
		.form-type-select--sort .select-items div {
			font-size: 14px;
		}

			.select-items {
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;

				max-height: 250px;
				overflow-y: auto;

				background-color: $color_background-1;

				border: 1px solid transparent;
				border-color: transparent $color_divider $color_divider $color_divider;
				border-radius: 4px;
				box-shadow: $hledani_form_shadow-outer;

				z-index: 99;
			}

				.select-items div {
					padding-top: 0.5em;
					padding-bottom: 0.5em;
					padding-right: 0.75em;

					line-height: 1.3;

					color: $color_background-6;

					border: 1px solid transparent;
					border-color: transparent transparent $color_divider transparent;
				}

				.select-items div:last-of-type {
					border: none;
				}

				.select-items div:hover{
					background-color: $color_background-3;
				}

				.same-as-selected {
					background-color: $color_background-2;
				}

				.select-items input[type=text] {
					background-image: $file_icon_search_colored-bg5;
					background-position: right 10px center;
					background-repeat: no-repeat;
					background-size: auto 16px;

					padding-top: 0.5em;
					padding-bottom: 0.5em;
					padding-left: 0.75em;
					padding-right: 2em;

					line-height: 1.3;

					width: 100%;
					height: 2.5em;

					//background-color: transparent;
					color: $hledani_form_input_color;

					border: none;
					border-bottom: 1px solid $color_divider;

					&:focus {
						border: none;
						border-bottom: 1px solid $color_divider;
						box-shadow: unset;
					}
				}


	.select-hide {
		display: none;
	}


	// views-filters
	.view-filters--hledani {
		margin-top: 2em;
		margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 4em;
		}

		form {

			.views-exposed-form {

				.views-exposed-widgets {}

				// block primary
				.views-exposed-widgets--primary {

					// fulltext
					.form__widget--fulltext {
						display: flex;
						flex-direction: row;
						align-items: stretch;
						justify-content: flex-start;

						margin-bottom: 1.5em;

						width: 100%;

						box-shadow: $hledani_form_shadow-inner;

						@include breakpoint($breakpoint_desktop) {
							margin-bottom: 4em;
						}

						// input
						.form-type-textfield {
							width: 100%;

							input[type=text] {
								font-size: 18px;

								padding-left: 0.5em;
								padding-right: 0.5em;

								width: 100%;
								height: 2.25em;

								background-color: transparent;
								color: $hledani_form_input_color;

								border: 1px solid $color_divider;
								border-right: none;
								border-radius: 4px 0 0 4px;

								@include breakpoint($breakpoint_desktop) {
									font-size: 22px;
									height: 2.75em;
								}

								&:focus {
									border: 1px solid $color_divider;
									border-right: none;
									box-shadow: unset;
								}
							}
						}

						// submit, reset
						.form-type--submit-button,
						.form-type--reset-button {

							input[type=submit],
							input[type=reset] {
								background-position: 50%;
								background-repeat: no-repeat;
								background-size: 18px 18px;

								font-size: 18px;
								text-indent: -9999px;

								margin-top: 0;
								padding: 1em;

								width: 38px;
								height: 2.25em;

								background-color: transparent;
								color: transparent;

								border: 1px solid $color_divider;
								border-left: none;
								border-radius: 0 4px 4px 0;

								cursor: pointer;

								@include breakpoint($breakpoint_desktop) {
									background-size: 22px 22px;
									font-size: 22px;
									width: 56px;
									height: 2.75em;
								}
							}
						}

						// submit
						.form-type--submit-button {

							input[type=submit] {
								background-image: $file_icon_search_colored-bg5;
							}
						}

						// reset
						.form-type--reset-button {
							display: none; // default hodnota

							input[type=reset] {
								background-image: $file_icon_close_colored-bg5;
								background-size: auto 0.5em;
							}
						}
					}

					// search vs. close
					.form__widget--fulltext-with-submit-button {

						// submit
						.form-type--submit-button {
							display: block;
						}

						// reset
						.form-type--reset-button {
							display: none;
						}
					}
					.form__widget--fulltext-with-reset-button {

						// submit
						.form-type--submit-button {
							display: none;
						}

						// reset
						.form-type--reset-button {
							display: block;
						}
					}

					// checkboxes
					.form__widget--checkboxes {

						// collapse vs. uncollapse
						&.collapse {

							// mobile: hide all after 5 items
							.form-item:nth-of-type(1n+6) {
								display: none;

								@include breakpoint($breakpoint_desktop) {
									display: block;
								}
							}

							// desktop: hide all after 10 items
							.form-item:nth-of-type(1n+11) {

								@include breakpoint($breakpoint_desktop) {
									display: none;
								}
							}
						}
					}
				}

				// block secondary
				.views-exposed-widgets--secondary {
					display: flex;
					flex-direction: column;

					margin-top: 1em;

					@include breakpoint($breakpoint_desktop) {
						flex-direction: row;
						flex-wrap: nowrap;
						align-items: center;
						justify-content: space-between;

						margin-top: 2em;
					}

					// selects
					.form__widget--selects {
						display: flex;
						flex-direction: column;

						@include breakpoint($breakpoint_desktop) {
							flex-direction: row;
							flex-wrap: nowrap;
							flex-grow: 1;
						}

						.form-type-select {
							margin-bottom: 0.5em;
							flex-grow: 1;

							@include breakpoint($breakpoint_desktop) {
								margin-right: 1em;
								margin-bottom: 0;
								max-width: 12em;
							}

							label {
								display: none;
							}

							select {
								appearance: none;

								background-image: $file_icon_arrow_single_south_colored-decoration-main;
								background-position: right 10px center;
								background-repeat: no-repeat;
								background-size: auto 10px;

								padding-left: 0.75em;
								padding-right: 2em;

								width: 100%;

								line-height: 2.5em;
								@include lineClamp(1);

								background-color: $color_background-0;
								color: $hledani_form_input_color;

								cursor: pointer;
								outline: none;

								border: 1px solid $color_divider;
								border-radius: 4px;
								box-shadow: $hledani_form_shadow-inner;

								&:focus {
									border: 1px solid $color_divider;
								}

								option {}
							}
						}

						// select: last
						.form-type-select:last-child {
							@include breakpoint($breakpoint_desktop) {
								//margin-right: 0; // tady nechceme, aby se nepotkalo s .form__widget--dates
							}
						}

						// select: theme
						.form-type-select--theme {
							@include breakpoint($breakpoint_desktop) {
								max-width: 12em;
							}
						}

						// select: show
						.form-type-select--show {
							@include breakpoint($breakpoint_desktop) {
								max-width: 24em;
							}
						}

						// select: author
						.form-type-select--author {
							@include breakpoint($breakpoint_desktop) {
								max-width: 12em;
							}
						}
					}

					// dates
					.form__widget--dates {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;

						@include breakpoint($breakpoint_desktop) {
							flex-shrink: 0;
						}

						.form-type-textfield {
							margin-right: 0.5em;
							flex-grow: 1;

							@include breakpoint($breakpoint_desktop) {
								max-width: 8em;
							}

							label {
								display: none;
							}

							input[type=text] {
								background-image: $file_icon_calendar_colored-main;
								background-position: right 10px center;
								background-repeat: no-repeat;
								background-size: auto 16px;

								padding-left: 0.75em;
								padding-right: 2em;

								line-height: 2.5em;
								@include lineClamp(1);

								color: $hledani_form_input_color;

								border: 1px solid $color_divider;
								border-radius: 4px;
								box-shadow: $hledani_form_shadow-inner;

								&:focus {
									border: 1px solid $color_divider;
									box-shadow: unset;
									box-shadow: $hledani_form_shadow-inner;
								}
							}
						}

						.form-type-textfield:last-child {
							margin-right: 0;
						}
					}
				}

				// block tertiary
				.views-exposed-widgets--tertiary {
					display: flex;
					flex-direction: column;
          align-items: center;

					margin-top: 1em;

					@include breakpoint($breakpoint_desktop) {
						flex-direction: row;
						flex-wrap: nowrap;
						align-items: center;
						justify-content: space-between;

						margin-top: 2em;
					}

					// button reset
					.button-simple--reset {
						@include link-style($color_text_decent, none, $color_text_decent, underline);
					}
				}
			}
		}
	}

	// views-filters: sort
	.view-filters--sort {
		margin-bottom: 0;

		form {

			.views-exposed-form {

				// block
				.views-exposed-widgets {

					@include breakpoint($breakpoint_desktop) {
					}

					// selects
					.form__widget--selects {

						@include breakpoint($breakpoint_desktop) {
						}

						.form-type-select {

							@include breakpoint($breakpoint_desktop) {
								max-width: 10em;
							}

							label {
								display: none;
							}

							select {
								appearance: none;

								background-image: $file_icon_arrow_single_south_colored-decoration-main;
								background-position: right 10px center;
								background-repeat: no-repeat;
								background-size: auto 8px;

								padding-left: 0.75em;
								padding-right: 2em;

								width: 100%;

								font-size: 14px;
								line-height: 1.5em;
								@include lineClamp(1);

								background-color: $color_background-2;
								color: $color_background-9;

								cursor: pointer;
								outline: none;

								border: none;
								//border-radius: 4px;
								//box-shadow: $hledani_form_shadow-inner;

								&:focus {
									border: 1px solid $color_divider;
								}

								option {}
							}
						}

						// select: last
						.form-type-select:last-child {
							@include breakpoint($breakpoint_desktop) {
								margin-right: 0;
							}
						}

						// select: sort
						.form-type-select--sort {
							@include breakpoint($breakpoint_desktop) {
								max-width: 10em;
							}
						}
					}
				}
			}
		}
	}


	// checkboxy barvy dle stanic
	/*.view-filters--hledani {

		form {

			.views-exposed-form {

				.views-exposed-widgets {

					// filtrovaci checkboxy
					.bef-checkboxes {
					}

						.mujrozhlas.form-item input[type='checkbox']:checked + label {
							background-color: $color_mujrozhlas_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_mujrozhlas_main;
						}

						.irozhlas.form-item input[type='checkbox']:checked + label {
							background-color: $color_irozhlas_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_irozhlas_main;
						}

						.radiozurnal.form-item input[type='checkbox']:checked + label {
							background-color: $color_radiozurnal_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_radiozurnal_main;
						}

						.dvojka.form-item input[type='checkbox']:checked + label {
							background-color: $color_dvojka_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_dvojka_main;
						}

						.vltava.form-item input[type='checkbox']:checked + label {
							background-color: $color_vltava_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_vltava_main;
						}

						.plus.form-item input[type='checkbox']:checked + label {
							background-color: $color_plus_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_plus_main;
						}

						.sport.form-item input[type='checkbox']:checked + label {
							background-color: $color_sport_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_sport_main;
						}

						.wave.form-item input[type='checkbox']:checked + label {
							background-color: $color_wave_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_wave_main;
						}

						.ddur.form-item input[type='checkbox']:checked + label {
							background-color: $color_d-dur_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_d-dur_main;
						}

						.jazz.form-item input[type='checkbox']:checked + label {
							background-color: $color_jazz_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_jazz_main;
						}

						.junior.form-item input[type='checkbox']:checked + label {
							background-color: $color_junior_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_junior_main;
						}

						.pohoda.form-item input[type='checkbox']:checked + label {
							background-color: $color_pohoda_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_pohoda_main;
						}

						.region.form-item input[type='checkbox']:checked + label {
							background-color: $color_region_main;
							color: $color_text_decent_inverted;
							border: 2px solid $color_region_main;
						}
				}
			}
		}
	}*/


	// pager
	.pager {
		height: 2.5em;

		.pager__item {

			&.pager__item--current {
				border-radius: 25px;
			}

			&.pager__item--previous,
			&.pager__item--next,
			&.pager__item--first,
			&.pager__item--last {
				width: 2.5em;
				border-radius: 100%;

				a::after {
					top: 0.75em;
					left: 0.75em;
					width: 1em;
					height: 1em;
				}
			}
			&.pager__item--disabled {
				//visibility: hidden;
				opacity: 0.1;
				pointer-events: none;
			}
		}

		// barvy jako .button-popup--open / .button-popup--close viz _hledani_button-popup.scss
		.pager__item--first,
		.pager__item--previous,
		.pager__item--next,
		.pager__item--last {
			background-color: $color_background-5;

			&:hover {
				background-color: $color_background-6;
			}
		}
	}


	// b-022c
	.b-022c {
		position: relative; // kvuli absolutnimu pozicovani .view-filters--sort
	}


	// results heading
	.results-heading {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		border-bottom: 1px solid $color_divider;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: -0.5em;
		}
	}

		.results-heading__stats {
			flex-grow: 1;

			margin-bottom: 0;
			padding-right: 10px;

			color: $color_background-5;

			font-size: 12px;
			line-height: 1.3;
			text-transform: uppercase;

			@include breakpoint($breakpoint_desktop) {
				padding-left: 10px;
				font-size: 15px;
			}

			strong {
				color: $color_background-7;
			}
		}

		.results-heading__sort {
			flex-shrink: 0;
		}

}
