//
// responzivni hodinove zaznamy
//

.audioarchive {
	position: relative;
	margin: 2em 15px 32px 15px;
	@include breakpoint($breakpoint_desktop) {
		margin-top: 3em;
	}
	// jednotliva hodina
	&__hour {
		// seda cara, ktera opticky oddeluje danou hodinu od dalsi
		&:after {
			content: '';
			border-bottom: 1px solid $color_divider;
			display: inline-block;
			width: 100%;
		}
		// posledni hodina je bez cary
		&:last-child {
			&:after {
				border-bottom: 0;
			}
		}
	}
	// hlavicka hodiny
	&__header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: -17px;
		@include breakpoint($breakpoint_tablet) {
			justify-content: space-between;
			margin: 10px 0 15px 60px;
			@media all and (-ms-high-contrast: none) {
				margin-left: 80px;
			}
		}
	}
	// indikator hodiny
	&__broadcast-hour {
		font-size: 2rem;
		line-height: 1.5;
		cursor: default;
		@include typography_font(medium);
		@include no-select;
		@include breakpoint($breakpoint_tablet) {
			font-size: 2.5rem;
		}
	}
	// indikator minut
	&__broadcast-minutes {
		font-size: .9rem;
		line-height: 2;
		@include typography_font(regular);
		@include breakpoint($breakpoint_tablet) {
			font-size: 1.2rem;
		}
		&:before {
			content: ':';
			margin-right: 1px;
		}
	}
	// grid vlastniho obsahu
	&__item {
		margin: 0 0 25px 0;
		display: flex;
		flex-wrap: wrap;
		&:last-child {
			margin-bottom: 10px;
		}
		// audio hraje
		&.is-playing {
			// zmena ikony play na pause
			.button.button-listaction {
				background-image: $file_button_action_pause;
			}
			// indikace nazvu prehravaneho poradu
			.audioarchive {
				&__link {
					text-decoration: underline;
					color: $color_background-6;
				}
			}
		}
		// audio je pauznute, ale indikuje se prehravany porad
		&.is-paused {
			// zmena ikony pause na play
			.button.button-listaction {
				background-image: $file_button_action_play;
			}
			// indikace nazvu pauznuteho poradu
			.audioarchive {
				&__link {
					text-decoration: underline;
					color: $color_background-6;
				}
			}
		}
	}
	// ikona evokujici audio, ktere lze ulozit
	&__broadcast-download {
		margin-right: 10px;
		background: $file_button_action_download no-repeat;
		background-size: 200% 100%;
		min-width: 28px;
		min-height: 28px;
		width: 28px;
		height: 28px;
		background-position: 0 0;
		border: 1px solid $color_divider;
		border-radius: 50%;
		cursor: pointer;
		&:hover{
			background-position: 100% 0;
		}
		@include breakpoint($breakpoint_tablet) {
			order: 1;
		}
	}
	// cas, kdy byl porad vysilan
	&__broadcast-time {
		display: flex;
		justify-content: flex-start;
		flex-basis: 100%;
		padding-right: 15px;
		margin-bottom: 2px;
		font-size: .95rem;
		color: $color_background-5;
		cursor: default;
		@include no-select;
		@include breakpoint($breakpoint_tablet) {
			justify-content: flex-end;
			flex-basis: 60px;
			font-size: 1.1rem;
		}
	}
	// nazev vysilaneho poradu
	&__show {
		display: inline-flex;
		align-items: flex-start;
		@include breakpoint($breakpoint_tablet) {
			max-width: calc(100% - 70px);
		}
	}
	// klikaci oblast na mobilu / oblast pro zamereni mysi
	&__play-area {
		cursor: pointer;
		display: flex;
		align-self: flex-end;
		&:active,
		&:hover {
			.button-listaction {
				&--play {
					background-position: 100%;
				}
			}
			.audioarchive {
				&__link {
					text-decoration: underline;
				}
			}
		}
	}
	// hyperlink vysilaneho poradu
	&__link {
		@include typography_font(medium);
		font-size: .95rem;
		margin-right: 4px;
		@include breakpoint($breakpoint_tablet) {
			font-size: 1.2rem;
		}
		// audio hraje
		&.is-current {
			text-decoration: underline;
			color: $color_background-6;
		}
	}
	// delka trvani poradu
	&__duration {
		@include typography_font(regular);
		font-size: .6875rem;
		white-space: nowrap;
		line-height: 2.2;
		@include no-select;
	}
	// textovy popis poradu
	&__description {
		font-size: .915rem;
		overflow-wrap: break-word;
		line-height: 1.4;
		color: $color_background-6;
		flex-basis: 100%;
		@include breakpoint($breakpoint_tablet) {
			font-size: 1rem;
			margin-top: 5px;
			margin-left: 60px;
			@media all and (-ms-high-contrast: none) {
				margin-left: 80px;
			}
		}
		&--unavailable {
			margin: 0;
			padding: 24px 15px;
			color: $color_background-5;
			background: $color_background-2;
			border: 5px solid $color_divider;
			text-align: center;
			display: inline-block;
			font-size: .88rem;
			@include no-select;
		}
	}
	// rozklikavatko pro dlouhe texty aka "vice"
	&__show-more-link {
		color: $color_decoration_main !important;
	}
	// tlacitko indikujici prehrani poradu
	&__button-play {
		width: 20px;
		height: 24px;
		margin-right: 5px;
		@include breakpoint($breakpoint_tablet) {
			margin-right: 10px;
		}
		a.button {
			width: 20px;
			height: 20px;
		}
	}
	// pozicovani kalendare
	.hasDatepicker {
		position: absolute;
		top: 74px;
		left: calc(50% - 180px);
		margin-top: -30px;
		padding: 30px;
		z-index: 11;
	}
	// volba stanic
	.mobilemenu {
		max-width: 100%;
		@include breakpoint($breakpoint_tablet) {
			max-width: 275px;
		}
		&__item {
			min-height: 26px;
			width: 100%;
			&:after {
				transition: right 200ms;
			}
			&:hover {
				@include breakpoint($breakpoint_desktop) {
					&:after {
						right: 20px;
					}
					// podtrzeni textace pro vyber stanice pri zamereni mysi na desktopu
					.mobilemenu__station-name {
						text-decoration: underline;
					}
				}
			}
		}
		&__station-name {
			min-height: 30px;
			display: flex;
			align-items: center;
			line-height: 2; // IE 11
		}
	}
	// korekce pozicovani dropdown obsahu
	.hidden-checkbox:checked ~ .mobilemenu {
		left: auto;
		right: 0;
	}
	// zmena ikonky na zaviratko
	input[type=checkbox]:checked + .station-toggle__toggler {
		&:after {
			background: $file_icon_close_colored-white no-repeat center transparent;
		}
	}
}

.section-zaznamy .page .main {
	padding: 0;
	@include breakpoint($breakpoint_desktop) {
		padding: 0 40px;
	}
}

// skryti elementu
#printButton {
	display: none;
}


// uprava pozicovani vyberu stanic
.audioarchive .hidden-checkbox:checked ~ .mobilemenu {
	top: 26px;
}
.patternlab {
	.mobilemenu {
		&__item {
			width: calc(100% - 80px);
		}
	}
}
