//
// Typography
//



// Font face - rozhlas regular
@font-face {
    font-family: 'rozhlas_regular';
    src: url('../fonts/rozhlas-regular-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/rozhlas-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/rozhlas-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/rozhlas-regular-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/rozhlas-regular-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/rozhlas-regular-webfont.svg#rozhlas_regular') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - rozhlas medium
@font-face {
    font-family: 'rozhlas_medium';
    src: url('../fonts/rozhlas-medium-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/rozhlas-medium-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/rozhlas-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/rozhlas-medium-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/rozhlas-medium-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/rozhlas-medium-webfont.svg#rozhlas_medium') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - rozhlas bold
@font-face {
    font-family: 'rozhlas_bold';
    src: url('../fonts/rozhlas-bold-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/rozhlas-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/rozhlas-bold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/rozhlas-bold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/rozhlas-bold-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/rozhlas-bold-webfont.svg#rozhlas_medium') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - junior black
@font-face {
    font-family: 'riffic_black';
    src: url('../fonts/riffic-black-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/riffic-black-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/riffic-black-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/riffic-black-webfont.svg#riffic_black') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - junior bold
@font-face {
    font-family: 'riffic_bold';
    src: url('../fonts/riffic-bold-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/riffic-bold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/riffic-bold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/riffic-bold-webfont.svg#riffic_bold') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - junior book
@font-face {
    font-family: 'riffic_book';
    src: url('../fonts/riffic-book-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/riffic-book-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/riffic-book-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/riffic-book-webfont.svg#riffic_book') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - junior light
@font-face {
    font-family: 'riffic_light';
    src: url('../fonts/riffic-light-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/riffic-light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/riffic-light-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/riffic-light-webfont.svg#riffic_light') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - junior medium
@font-face {
    font-family: 'riffic_medium';
    src: url('../fonts/riffic-medium-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/riffic-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/riffic-medium-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/riffic-medium-webfont.svg#riffic_medium') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - junior semibold
@font-face {
    font-family: 'riffic_semibold';
    src: url('../fonts/riffic-semibold-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/riffic-semibold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/riffic-semibold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/riffic-semibold-webfont.svg#riffic_semibold') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// Font face - junior ultrabold
@font-face {
    font-family: 'riffic_ultrabold';
    src: url('../fonts/riffic-ultrabold-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/riffic-ultrabold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/riffic-ultrabold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/riffic-ultrabold-webfont.svg#riffic_semibold') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

$font-default: 'Helvetica', 'Arial', 'Roboto', Sans-Serif; // default/fallback;
$font-regular: 'rozhlas_regular', $font-default; // custom font regular + fallback
$font-medium: 'rozhlas_medium', $font-default; // custom font medium + fallback
$font-bold: 'rozhlas_bold', $font-default; // custom font bold + fallback

$font-junior-black: 'riffic_black'; // custom font black
$font-junior-bold: 'riffic_bold'; // custom font bold
$font-junior-book: 'riffic_book'; // custom font book
$font-junior-light: 'riffic_light'; // custom font light
$font-junior-medium: 'riffic_medium'; // custom font medium
$font-junior-semibold: 'riffic_semibold'; // custom font semibold
$font-junior-ultrabold: 'riffic_ultrabold'; // custom font ultrabold

$letter-spacing: 0.03em; // ales posvecena hodnota


// vars @mixin typography_font($font)
/*body {
	--font-family-default: #{$font-default};
	--font-family-regular: #{$font-regular};
	--font-family-medium: #{$font-medium};
	--font-family-bold: #{$font-default};
	--font-weight-default: normal;
	--font-weight-regular: normal;
	--font-weight-medium: normal;
	--font-weight-bold: 700;

	&.radiopraha.ru { // pro .radiopraha.ru nahrazeni medium rezu za default bold kvuli rozdilne sirce rozhlas_medium vs. azbuka
		--font-family-medium: #{$font-default};
		--font-weight-medium: 700;
	}
}

@mixin typography_font($font) { // hodnoty: regular, medium, bold
	@if $font == 'regular' { // regular
		font-family: var(--font-family-regular);
		font-weight: var(--font-weight-regular);
	}
	@else if $font == 'medium' { // medium
		font-family: var(--font-family-medium);
		font-weight: var(--font-weight-medium);
	}
	@else if $font == 'bold' { // pro bold nemame rez, tak pouzijeme jako font-family: $font-default; + font-weight: 700;
		font-family: var(--font-family-bold);
		font-weight: var(--font-weight-bold);
	}
	@else { // fallback
		font-family: var(--font-family-default);
		font-weight: var(--font-weight-default);
	}
}*/

@mixin typography_font($font) { // hodnoty: regular, medium, bold
	@if $font == 'regular' { // regular
		font-family: $font-regular;
		font-weight: normal;
	}
	@else if $font == 'medium' { // medium
		font-family: $font-medium;
		font-weight: normal;
	}
	@else if $font == 'bold' { // bold;
		font-family: $font-bold;
		font-weight: normal;
	}
	@else { // fallback
		font-family: $font-default;
		font-weight: normal;
	}
}


// Vertical rhyth with Compass
@import "compass/typography/vertical_rhythm";

$base-font-size: 16px; // default 16px;
$base-line-height: 22px; // default 24px;
$rhythm-unit: "rem"; // default "em"

@include establish-baseline;
// visible debug baselines on body background
//body { @include debug-vertical-alignment; }



// Specific font sizes



// Headings
$fontsize_h1: 22px; // 24px
$fontsize_h1_desktop: 32px; // 44px, 38px

$fontsize_h2: 22px;
$fontsize_h2_desktop: 22px; // 34px

$fontsize_h2_node: 20px;
$fontsize_h2_node_desktop: 28px;

$fontsize_h3: 17px;
$fontsize_h3_desktop: 18px;

$fontsize_h3_node: 16px;
$fontsize_h3_node_desktop: 20px;

$fontsize_h4: 16px;
$fontsize_h4_desktop: 14px;

$fontsize_h5: 12px;
$fontsize_h5_desktop: 14px;



// Paragraph
$fontsize_paragraph: 16px;
$fontsize_paragraph_desktop: 16px;

$fontsize_paragraph_node: 16px;
$fontsize_paragraph_node_desktop: 19px;


// Menu Top
$fontsize_menu_top: 14px;
$fontsize_menu_top_desktop: 14px;

// Menu Main
$fontsize_menu_main: 20px;
$fontsize_menu_main_desktop: 19px;

// Menu Main Localization
$fontsize_menu_main_localization_desktop: 15px;

// Menu Main
$fontsize_menu_breadcrumb: 11px;
$fontsize_menu_breadcrumb_desktop: 13px;


// Tag
$fontsize_tag: 16px;
$fontsize_tag_desktop: 14px;
$fontsize_tag-rounded: 13px;
$fontsize_tag-rounded_desktop: 11px;



// Box specifics
$fontsize_box-heading: 16px; // 26px
$fontsize_box-heading_desktop: 15px; // 30px, 22px

$fontsize_box_petit_main: 12px;
$fontsize_box_petit_main_desktop: 14px;



// Author (napr. b-041a)
$fontsize_author_main: 16px;
$fontsize_author_main_desktop: 14px;



// li (napr. b-035)
$fontsize_li_main: 16px;
$fontsize_li_main_desktop: 18px;



// Forms
$fontsize_form_flat_button: 15px;
$fontsize_form_flat_button_desktop: 16px;

$fontsize_form_input_label: 12px;
$fontsize_form_input_label_desktop: 14px;



// Buttons
$fontsize_simple_button: 14px;
$fontsize_simple_button_desktop: 14px;



// Misc
$fontsize_broadcast_time-and-label: 14px;
$fontsize_broadcast_time-and-label_desktop: 20px;

@mixin typography_broadcast_time() {

	font-weight: normal;

	@include adjust-font-size-to($fontsize_broadcast_time-and-label);

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_broadcast_time-and-label_desktop);
	}
}

@mixin typography_broadcast_label() {

	font-weight: normal;
	font-family: $font-medium;

	@include adjust-font-size-to($fontsize_broadcast_time-and-label);

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_broadcast_time-and-label_desktop);
	}
}
