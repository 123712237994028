//
// B-041k Styles
//



$b-041l_image-width-basis: 7em;


.b-041l {
}

	.b-041l__list {
	}

		.b-041l__list-item {
			display: flex;
			flex-direction: row;

			margin-bottom: 2em;
		}

			.b-041l__block {
			}

			.b-041l__block--image {
				flex-basis: $b-041l_image-width-basis;
				flex-shrink: 0;
			}

				.b-041l__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-041l__image .button-play {
						position: absolute;
						bottom: 0.35em;
						left: 0.35em;

						width: 1.75em;
						height: 1.75em;

						// nechceme jako aktivni odkaz
						pointer-events: none;
						cursor: default;
					}


			.b-041l__block--description {
				flex-grow: 1;

				padding-left: 1em;
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041l__block--description h3 {
					margin-top: 0;
					margin-bottom: 0;

					font-size: 16px;
					line-height: 20px;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-041l__block--description h3 a {
						@include link-style($color_decoration_main, none, $color_decoration_main, none);
					}


				.b-041l__metainfo-row--timespan {
					margin-top: 0.25em;

					font-size: 14px;
					line-height: 18px;
				}

					.b-041l__metainfo-row-label {
						color: $color_text_paragraph;
					}

					.b-041l__metainfo-row-value {
						@include typography_font(medium);
						color: $color_text_accent;
					}



// vizualni debug - plab only
.patternlab .b-041l {
	max-width: 400px;
}
