//
// Box
//



.box {
	font-size: $base-font-size; // prebiji defaultni hodnoty pro node
	line-height: $base-line-height; // prebiji defaultni hodnoty pro node
}
