//
// Styles - Node Pageflow
//

$pageflow_font_size_p: .9rem;
$pageflow_font_size_p_tablet: 1rem;
$pageflow_font_size_p_desktop: 1.2rem;

$pageflow_breakpoint_desktop: 740px;


.node-type-pageflow {
}
	.node-type-pageflow .pf-container {
	}
		.node-type-pageflow .pf-container .pf-slide {
		}
			.node-type-pageflow .pf-container .pf-slide {
			}

			// prvni slide
			.node-type-pageflow .pf-container .pf-slide .pf-slide-start {
			}
				.node-type-pageflow .pf-container .pf-slide .pf-bg {
				}
					.node-type-pageflow .pf-container .pf-slide .pf-bg .pf-bg__image {
					}

					.node-type-pageflow .pf-container .pf-slide .pf-content {
					}

						// nadpis sekce
						.node-type-pageflow .pf-container .pf-slide .pf-content h1 {
						}

						// odstavec sekce
						.node-type-pageflow .pf-container .pf-slide .pf-content p {
							font-size: $pageflow_font_size_p;
							@include breakpoint($breakpoint_tablet) {
								font-size: $pageflow_font_size_p_tablet;
							}
							@include breakpoint($breakpoint_desktop) {
								font-size: $pageflow_font_size_p_desktop;
							}
						}
						// odstavec sekce - bold
						.node-type-pageflow .pf-container .pf-slide .pf-content p strong {
							@include typography_font(bold);
						}
						// odstavec sekce - hypertextovy odkaz
						.node-type-pageflow .pf-container .pf-slide .pf-content p a {
							color: #000;
							text-decoration: underline;
							&:hover {
								text-decoration: none;
							}
						}
						.node-type-pageflow .pf-container .pf-slide.text-white .pf-content p a {
							color: #ffff;
							text-decoration: underline;
							&:hover {
								text-decoration: none;
							}
						}
						// dark
						.node-type-pageflow .pf-container .pf-slide.text-white.pf-bg__gradientLeftNew:after {
							content: '';

							height: 100vh;
							width: 100%;
							display: block;
							position: absolute;

							background-image: linear-gradient(90deg, rgba(26, 26, 30, 0.5), transparent 100%);
						}

						@include breakpoint($pageflow_breakpoint_desktop) {
							.node-type-pageflow .pf-container .pf-slide.text-white.pf-bg__gradientLeftNew:after {
								background-image: linear-gradient(90deg, rgba(26, 26, 30, 0.7), transparent 50%);
							}
						}

						.node-type-pageflow .pf-container .pf-slide.text-white.pf-bg__gradientRightNew:after {
							content: '';

							height: 100vh;
							width: 100%;
							display: block;
							position: absolute;

							background-image: linear-gradient(270deg, rgba(26, 26, 30, 0.5), transparent 100%);
						}

						@include breakpoint($pageflow_breakpoint_desktop) {
							.node-type-pageflow .pf-container .pf-slide.text-white.pf-bg__gradientRightNew:after {
								background-image: linear-gradient(270deg, rgba(26, 26, 30, 0.7), transparent 50%);
							}
						}

						// light
						.node-type-pageflow .pf-container .pf-slide.pf-bg__gradientLeftNew:after {
							content: '';

							height: 100vh;
							width: 100%;
							display: block;
							position: absolute;

							background-image: linear-gradient(90deg, rgba(254, 254, 255, 0.5), transparent 100%);
						}

						@include breakpoint($pageflow_breakpoint_desktop) {
							.node-type-pageflow .pf-container .pf-slide.pf-bg__gradientLeftNew:after {
								background-image: linear-gradient(90deg, rgba(254, 254, 255, 0.7), transparent 50%);
							}
						}

						.node-type-pageflow .pf-container .pf-slide .pf-content--cols,
						.node-type-pageflow .pf-container .pf-slide .pf-img-author {
							z-index: 101;
						}

						.node-type-pageflow .pf-container .pf-slide.pf-bg__gradientRightNew:after {
							content: '';

							height: 100vh;
							width: 100%;
							display: block;
							position: absolute;

							width: 100%;
							height: 100%;

							background-image: linear-gradient(270deg, rgba(254, 254, 255, 0.5), transparent 100%);
						}

						@include breakpoint($pageflow_breakpoint_desktop) {
							.node-type-pageflow .pf-container .pf-slide.pf-bg__gradientRightNew:after {
								background-image: linear-gradient(270deg, rgba(254, 254, 255, 0.7), transparent 50%);
							}
						}

						.node-type-pageflow .pf-container .pf-slide .pf-content--cols,
						.node-type-pageflow .pf-container .pf-slide .pf-img-author {
							z-index: 101;
						}



/*
 * logo
 */
.node-type-pageflow {
	.pf-logo {
		display: block;

		width: 100%;
		height: 1.35em;

		//background-color: rgba(255, 255, 255, 0.2); // vizualni debug velikosti obalu

		background-position: left center;
		background-repeat: no-repeat;
		// kdyby se tu nekdo objevil z duvodu diveni se proc nefunguje v IE
		// pozicovani svg loga doleva, tak je potreba pridat konkretni vlastnost svg
		// explorer roztahuje "papir" obrazku
		// preserveAspectRatio="xMinYMid"


		// fallback korporatniho loga, pokud neni ovlivneno strankou pres tridu na body specificky
		// sirku klikaciho elementu nutno kontrolovat tez specificky dle pouziteho loga

		// default nastaveni pro korporatni logo
		// toto predpokladam nikdy prakticky nenastane - jinak je treba resit vert. zarovnani
		background-image: $file_logo_corporate_colored-white;

		@include breakpoint($breakpoint_desktop) {
			width: 17em; // default pro korporatni logo
			height: 2em;
		}

		// specificke rozmery pro specificka loga
		// loga jsou definovana obecne (cro-bg-images-logos) protoze jsou znovupouzitelna
		// velikost v hlavicce je ale pro potreby hlavicky pouze, proto zde v stylu .pf-logo primo
		@at-root .radiozurnal#{&} {
			background-image: $file_logo_radiozurnal_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 13.25em; // 215px
			}
		}
		@at-root .dvojka#{&} {
			background-image: $file_logo_dvojka_colored-white;
			height: 1.55em;// kvuli 'j' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 8.5em; // 132px
				height: 2.25em; // kvuli 'j' je treba vertikalne posunout
			}
		}
		@at-root .vltava#{&} {
			background-image: $file_logo_vltava_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 8em; // 126px
			}
		}
		@at-root .wave#{&} {
			background-image: $file_logo_wave_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 13.25em; // 212px
			}
		}
		@at-root .d-dur#{&} {
			background-image: $file_logo_d-dur_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 7.5em; // 120px
			}
		}
		@at-root .jazz#{&} {
			background-image: $file_logo_jazz_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 6.5em; // 104px
			}
		}
		@at-root .plus#{&} {
			background-image: $file_logo_plus_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 6.25em; // 100px
			}
		}
		@at-root .junior#{&} {
			background-image: $file_logo_junior_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 14.25em; // 227px
			}
		}
		@at-root .brno#{&} {
			background-image: $file_logo_brno_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 6.75em; // 107px
			}
		}
		@at-root .budejovice#{&} {
			background-image: $file_logo_budejovice_colored-white;
			height: 1.55em;// kvuli 'j' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 18.75em; // 301px
				height: 2.25em;// kvuli 'j' je treba vertikalne posunout
			}
		}
		@at-root .hradec#{&} {
			background-image: $file_logo_hradec_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 16.75em; // 268px
			}
		}
		@at-root .liberec#{&} {
			background-image: $file_logo_liberec_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 9.25em; // 149px
			}
		}
		@at-root .olomouc#{&} {
			background-image: $file_logo_olomouc_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 10.75em; // 173px
			}
		}
		@at-root .ostrava#{&} {
			background-image: $file_logo_ostrava_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 9.5em; // 153px
			}
		}
		@at-root .pardubice#{&} {
			background-image: $file_logo_pardubice_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 11.75em; // 188px
			}
		}
		@at-root .plzen#{&} {
			background-image: $file_logo_plzen_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 7.25em; // 117px
			}
		}
		/*@at-root .regina#{&} {
			background-image: $file_logo_regina_colored-white;
			height: 1.55em; // kvuli 'g' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 19em; // 304px
				height: 2.25em; // kvuli 'g' je treba vertikalne posunout
			}
		}*/
		@at-root .regina#{&} { // nove Radio DAB
			background-image: $file_logo_dab_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 18.5em; // 296px
			}
		}
		@at-root .sever#{&} {
			background-image: $file_logo_sever_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 7.75em; // 123px
			}
		}
		@at-root .vary#{&} {
			background-image: $file_logo_vary_colored-white;
			height: 1.55em; // kvuli 'y' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 14em; // 221px
				height: 2.25em; // kvuli 'y' je treba vertikalne posunout
			}
		}
		@at-root .vysocina#{&} {
			background-image: $file_logo_vysocina_colored-white;
			height: 1.55em; // kvuli 'y' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 10.5em; // 164px
				height: 2.25em; // kvuli 'y' je treba vertikalne posunout
			}
		}
		@at-root .zlin#{&} {
			background-image: $file_logo_zlin_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 5.75em; // 91px
			}
		}
		@at-root .strednicechy#{&} { // ma byt region, ale trida uz je obsazena
			background-image: $file_logo_region_colored-white;
			height: 1.55em; // kvuli 'y' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 8.5em; // 136px
				height: 2.25em; // kvuli 'y' je treba vertikalne posunout
			}
		}
		@at-root .regiony#{&} { // radio vaseho kraje
			background-image: $file_logo_regiony_colored-white;
			height: 1.55em; // kvuli 'g' a 'y' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 19.5em; // 311px
				height: 2.25em; // kvuli 'g' a 'y' je treba vertikalne posunout
			}
		}

		@at-root .nadacni-fond#{&} {
			background-image: $file_logo_nadacnifond_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 32.5em; // 520px
			}
		}
		@at-root .program#{&} {
			background-image: $file_logo_program_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 19em; // 304px
			}
		}
		@at-root .rada#{&} {
			background-image: $file_logo_rada_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 24.5em; // 393px
			}
		}
		@at-root .svet#{&} {
			background-image: $file_logo_svet_colored-white;

			@include breakpoint($breakpoint_desktop) {
				width: 16.25em; // 260px
			}
		}
		@at-root .sport#{&} {
			background-image: $file_logo_radiozurnalsport_colored-white;
			height: 1.55em; // kvuli 'p' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 16.75em; // 268px
				height: 2.25em; // kvuli 'p' je treba vertikalne posunout
			}
		}

		@at-root .radiopraha#{&} {
			background-image: $file_logo_radiopraha_colored-white;
			height: 1.55em; // kvuli 'g' a 'y' je treba vertikalne posunout

			@include breakpoint($breakpoint_desktop) {
				width: 26.75em; // 428px
				height: 2.25em; // kvuli 'g' a 'y' je treba vertikalne posunout
			}
		}

		// TODO dodefinovat dalsi, ale toho si vsimnes az ti to tu bude chybet...
	}

	.pf__logo-textcontent {
		color: $color_text_link_inverted;
		display: none;
	}
}



/*
 * 3d modely stadionu v pageflow
 */
.node-type-pageflow {

	.pf-bg--mesh {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	/*.pf-bg--mesh .pf-bg__image {
		background-image: url('fallback-img-url');
	}*/

	.pf-bg--mesh .pf-bg__mesh {
		position: relative;
		width: 100%;
		height: auto;
		padding-top: 100%; /* 1:1 Aspect Ratio */
		overflow: hidden;
	}

	.pf-bg--mesh .pf-bg__mesh .iframe__container {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	.pf-bg--mesh .pf-bg__mesh .iframe__container .iframe {
		width: 100%;
		height: 100%;
	}

	.pf-content--mesh {
		background-color: $color_background-9;
	}

	.pf-content--mesh .paragraph--desktop {
		display: none;
	}

	@include breakpoint($breakpoint_desktop) {
		.pf-content--mesh .paragraph--desktop {
			display: block;
		}
	}

	.pf-img-author--mesh {
		color: $color_text_accent;
	}
}
