//
// Liberec - HP
//



.liberec.page-liberec-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}
	
	
	
	// box Pivrncovi vtipy
	.b-007 {
		.b-007__list {
			.b-007a__list-item {
				margin-bottom: 0;
				
				.b-007__block--first {
					.b-007__image {
						color: #fff;
						
						a {
							display: none;
							
							&:first-child {
								display: block;
							}
						}
					}
				}
				
				.b-007__block--second {
					display: none;
				}
			}
		}
	}

}