//
// textarea
//


// obecny element textarea je definovan v html defaults pomoci selekce primo na tag <textarea>
.textarea {
	width: 100%;
}



// vizualni temata formularovych elementu

// modern
// primarni tema dle alesovo nahledu boxu

.textarea--theme-modern {
	
	background-color: $color_background-0;
	
	border: 1px solid $color_background-4;
	border-radius: 0.15em;
	
	padding-left: 0.25em;
	padding-right: 0.25em;
	
	outline: none;
	box-shadow: 0px 3px 3px $color_background-2 inset;
	
	line-height: 2em;
	
	transition: all 0.2s ease-in-out;
}
.textarea--theme-modern:focus {
	
	border: 1px solid $color_decoration_main;
	
	box-shadow: 0px 0px 5px 0px $color_decoration_main;
	
}