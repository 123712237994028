//
// Svatebky profil
//

$svatebky-custom-color: #edde58;
$svatebky-font-size: 1rem;
$svatebky-font-size-desktop: 1.25rem;


// mixiny
@mixin svatebky-mixin-mobile {
	margin: 0 10px -4px;
	flex-basis: calc(50% - 30px);
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
	&:nth-child(3) {
		flex-basis: 100%;
		margin-right: 15px;
		margin-left: 15px;
		padding-left: 25%;
		padding-right: 25%;
	}
}
@mixin svatebky-mixin-tablet {
	margin-left: 30px;
	margin-right: 30px;
	flex-basis: calc(33.33% - 60px);
	max-width: 200px;
	&:nth-child(3) {
		flex-basis: calc(33.33% - 60px);
		margin-right: 30px;
		margin-left: 30px;
		padding-left: 0;
		padding-right: 0;
	}
	&:nth-child(4),
	&:nth-child(5) {
		margin-top: -30px;
	}
}
@mixin svatebky-mixin-desktop {
	flex-basis: calc(33.33% - 100px);
	margin-left: 50px;
	margin-right: 50px;
	max-width: 240px;
	max-height: 320px; // pro IE
	&:nth-child(1) {
		margin-left: 0;
	}
	&:nth-child(3) {
		flex-basis: calc(33.33% - 100px);
		margin-left: 50px;
		margin-right: 0;
	}
	&:nth-child(4) {
		margin-top: -50px;
		margin-right: 0;
		margin-left: 21%;
	}
	&:nth-child(5) {
		margin-top: -50px;
		margin-right: 21%;
		margin-left: 0;
	}
}
@mixin svatebky-mixin-desktop-wide {
	max-width: 270px;
	flex-basis: calc(33.33% - 120px);
	margin-left: 60px;
	margin-right: 60px;
	&:nth-child(3) {
		flex-basis: calc(33.33% - 120px);
		margin-left: 60px;
		margin-right: 0;
	}
	&:nth-child(4) {
		margin-top: -80px;
		margin-left: 19%;
	}
	&:nth-child(5) {
		margin-top: -80px;
		margin-right: 19%;
	}
}


// profil
.b-100a {
	margin-top: 0;
	padding-top: 1.5em;
	@include breakpoint($breakpoint_tablet) {
		padding-top: 2em;
	}
	@include breakpoint($breakpoint_desktop) {
		padding-top: 0;
		margin-top: 40px;
	}
}

.b-100a:after {
	content: '';
	width: 100%;
	height: 1px;
	background-color: $color_divider;
	display: block;
	margin-top: 2em;
}

	.b-100a .b-100a__svatebky-profiles {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include breakpoint($breakpoint_desktop) {
			justify-content: space-between;
		}
	}

		.b-100a .b-100a__svatebky-profile-wrap {
			@include svatebky-mixin-mobile;

			@include breakpoint($breakpoint_tablet) {
				@include svatebky-mixin-tablet;
			}

			@include breakpoint($breakpoint_desktop) {
  				@include svatebky-mixin-desktop;
			}

			// extra siroky custom breakpoint
			@media only screen and (min-width: 1280px) {
  				@include svatebky-mixin-desktop-wide;
			}
		}


			.b-100a .b-100a__svatebky-profile-item {
				display: flex;
				flex-direction: column;
				border-top-left-radius: 50%;
				border-top-right-radius: 50%;
			}

				.b-100a h3 {
					margin: 0 auto;
					padding: 6px 27px;
					position: relative;
					top: -18px;
					text-align: center;
					background-color: $svatebky-custom-color;
					border-radius: 25px;
					z-index: 1;
					@include typography_font(regular);
					font-size: $svatebky-font-size;
					@include no-select;
					@include breakpoint($breakpoint_tablet) {
						top: -22px;
					}
					@include breakpoint($breakpoint_desktop) {
						padding: 8px 36px;
						top: -33px;
						font-size: $svatebky-font-size-desktop;
					}
				}

				.b-100a .b-100a__svatebky-profile-item img {
					border-radius: 50%;
				}
