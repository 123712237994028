//
// Bitva o Rozhlas - obecne vlastnosti vsech stranek
//



$bitvaorozhlas_selector_body: 'body.temata';
$bitvaorozhlas_selector_body-hp: $bitvaorozhlas_selector_body + '.page-historie-bitva-o-rozhlas';
$bitvaorozhlas_selector_body-pametnideska: $bitvaorozhlas_selector_body + '.page-historie-bitva-o-rozhlas-pametni-deska';
$bitvaorozhlas_selector_body-archiv: $bitvaorozhlas_selector_body + '.page-historie-bitva-o-rozhlas-archiv';


//#{$bitvaorozhlas_selector_body} // nema jednotnou tridu, ale jen prilis obecnou .temata
#{$bitvaorozhlas_selector_body-hp},
#{$bitvaorozhlas_selector_body-pametnideska},
#{$bitvaorozhlas_selector_body-archiv} {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_corporate_main, $color_corporate_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_corporate_main);
	}

	// box
	.box {

		// titulek
		h2 {
			color: $color_background-9;

			a {
				@include link-style ($color_text_link, none, $color_text_link, underline);
			}
		}
	}

}
