//
// B-005 Styles
//
// pozor, tenhle stylopis nebrat jako neco ukazkoveho
// jedna se o jeden z prvnich boxu ktery vznikl jeste neBEMovou metodou, selektory jsou vnorene a specificke
// nektera pravidla jsou zbytecna (h2)
//
// zanasim jeste nejaka pravidla ted navic co udelaj jeste vetsi bordel
// aneb styl potrebuje kompletni revizi
//
// a dokoncit sipku u readmore coz obnasi nejpis zmenu markupu..
//



.b-005 {

	h2 {
		text-transform: uppercase;
	}

	.b-005__list {

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;


		.b-005__list-item {

			// skryti poslednich 4 polozek pro mobile first
			// readmore posledni blok viditelny za vsech okolnosti
			/*&:nth-child(n+6) {

				display: none;
			}

			@include breakpoint($breakpoint_desktop) {

				&:nth-child(n+6) {

					display: block;
				}
			}

			&:last-child {

				display: block;
			}*/

			width: 50%;

			@include breakpoint($breakpoint_desktop) {
				width: 20%;
			}

			.image {
				position: relative;
			}

			.tag {

				position: absolute;
				bottom: 0;
				left: 0;


				// toto neni standardni tag multiline, je velmi pretizen pro potreby osob

				padding: 0.333em;
				padding-left: 0.5em;
				padding-right: 0.5em;

				@include typography_font(medium);

				background-color: rgba(64, 64, 64, 0.8);

				// TODO specifikovat jinde, nebo promennou..
				@include adjust-font-size-to(16px, 0.75);

				@include breakpoint($breakpoint_desktop) {

					// TODO specifikovat jinde, nebo promennou..
					//@include adjust-font-size-to(18px, 0.9);
					@include adjust-font-size-to(13px, 0.75);
				}
			}
		}

		.b-005__list-item--readmore {

			a {

				border: 1px solid $color_divider;

				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;

				text-align: center;

				@include link-style($color_decoration_main, none, $color_decoration_main, underline);

				// TODO specifikovat jinde, nebo promennou..
				@include adjust-font-size-to(16px, 0.75);

				@include breakpoint($breakpoint_desktop) {

					// TODO specifikovat jinde, nebo promennou..
					@include adjust-font-size-to(18px, 1);
				}
			}
			a:hover {
				background-color: $color_background-2;
			}

		}
	}
}



// pokud je v aside ma pravidla mobilu
.l-grid--4-1,
.l-grid--4-9 {
	.b-005 {
		.b-005__list {
			.b-005__list-item {
				// desktop s pravidly mobilu
				@include breakpoint($breakpoint_desktop) {
					width: 50%;
				}
			}
		}
	}
}
