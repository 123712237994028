//
// broadcast times styles
//



.broadcast-times {
	margin-top: 1em;
}
	
	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.broadcast-times h5 {
		
		margin-top: 1.5em;
		margin-bottom: 0.5em;
		
		color: $color_text_paragraph;
		
		@include adjust-font-size-to($fontsize_box_petit_main);
		
		@include breakpoint($breakpoint_desktop) {
			@include adjust-font-size-to($fontsize_box_petit_main_desktop);
		}
	}
	
	.broadcast-times__timetable {
		width: 100%;
	}
		
		.broadcast-times__timetable-row {
			border-top: 1px solid $color_divider;
		}
		.broadcast-times__timetable-row:last-child {
			border-bottom: 1px solid $color_divider;
		}
			
			.broadcast-times__timetable-item {
				padding: 0.25em 0;
			}
			
			.broadcast-times__timetable-item--time {
				
				@include adjust-font-size-to($fontsize_h5);
				
				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_h5_desktop);
				}
			}
			
			.broadcast-times__timetable-item--station {
				text-align: right;
			}
				
				.broadcast-times__station-tag {
					
					padding: 0.25em 0.5em;
					
					color: $color_text_accent_inverted;
					
					text-transform: uppercase;
					
					white-space: nowrap;
					
					@include adjust-font-size-to($fontsize_h5);
					
					@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
					
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($fontsize_h5_desktop);
					}
				}
	
	
	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.broadcast-times .button-box-flat--dropdown {
		margin-top: 1em;
	}
