//
// B-041k Styles - Audioplayer Metadata + Anchor Styles
//


$b-041k_padding: 20px;
$b-041k_ico_background-size: 200%;
$b-041k_border-width: 1px;


.b-041k {
	&__metadata {
		border: $b-041k_border-width solid $color_background-4;
		border-top: none;
		background-color: $color_background-0;
		padding: 10px;
		position: relative;
		max-width: calc(100% - 20px);
		margin-left: auto;
		margin-right: auto;
		@include breakpoint($breakpoint_desktop) {
			padding: 20px;
			max-width: 100%;
		}
	}
	&__wrap {
		@include breakpoint($breakpoint_tablet) {
			display: inline-block;
			max-width: calc(100% - 180px);
		}
	}
	&__item {
		line-height: 1;
		@include breakpoint($breakpoint_tablet) {
			line-height: 1.35;
		}
	}
	&__item span {
		font-size: 0.75rem !important;
		@include breakpoint($breakpoint_tablet) {
			font-size: 0.9rem !important;
		}
	}
	&__audio-title {
		font-size: 1rem;
		line-height: 1.2;
		color: $color_decoration_main;
		margin-bottom: 4px;
		@include breakpoint($breakpoint_tablet) {
			font-size: 1.35rem;
		}
	}
	&__availability-title,
	&__audio-length-title,
	&__description-title,
	&__availability-value,
	&__audio-length-value,
	&__description-value {
		color: $color_background-5 !important;
	}
	&__availability-value,
	&__audio-length-value,
	&__description-value,
	&__description-value > * {
		color: $color_background-9 !important;
		@include typography_font(medium);
		a {
			color: $color_decoration_main;
		}
	}
	&__link:link,
	&__link:visited {
		color: $color_decoration_main;
	}
	&__buttons {
		margin-top: 20px;
		position: relative;
		@include breakpoint($breakpoint_tablet) {
			margin-top: 0;
			position: absolute;
			right: 15px;
			bottom: 5px;
		}
		@include breakpoint($breakpoint_desktop) {
			bottom: 10px;
		}
	}
	&__buttons > * {
		margin-right: 4px;
	}
	&__anchor {
		font-size: .9rem;
		border: $b-041k_border-width solid $color_background-4;
		padding: $b-041k_padding;
		margin: 20px 0;
		width: 100%;
		max-width: 100%;
		min-height: 30px;
		display: flex;
		align-items: center;
		position: relative;
		@include breakpoint($breakpoint_tablet) {
			font-size: 1rem;
			margin: 40px 0;
			max-width: calc(390px - 2*#{$b-041k_padding} - 2*#{$b-041k_border-width});
		}
		@include breakpoint($breakpoint_desktop) {
			//margin-left: calc(4.375rem + 1.875rem);
			margin-left: $node_left-margin;
		}
		&:link,
		&:visited {
			color: $color_decoration_main;
		}
		&::after {
			content: '';
			background-image: $file_button_action_play;
			background-size: $b-041k_ico_background-size;
			background-repeat: no-repeat;
			background-position: 0;
			display: flex;
			width: 32px;
			height: 32px;
			position: absolute;
			right: 20px;
			top: 15px;
		}
		&:hover {
			&::after  {
				background-position: 100%;
			}
		}
	}
}

// embed
.soundmanager-embed {
	.b-041k {
		&__metadata {
			max-width: 100%;
		}
	}
}

// icon size
.audio-article-player {
	.button.button-listaction {
		width: 2.5rem;
		height: 2.5rem;
		@include breakpoint($breakpoint_tablet) {
			width: 2rem;
			height: 2rem;
		}
	}
}


// cesty za vodou
.view-cestyzavodou-hp {
	.b-041k {
		&__metadata {
			max-width: 100%;
		}
	}
}
