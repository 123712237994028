//
// B-099e Styles
//



.b-099e {
	max-width: 960px; // debug only
}

	.b-099e__dots-list {
		display: flex;
		align-items: center;
		justify-content: center;
	}

		.b-099e__dots-list-item {
			position: relative;
			
			width: 30px;
			height: 30px;

			border-radius: 10px;

			cursor: pointer;

			transition: all 0.3s ease;

			&::before {
				content: '';

				position: absolute;

				background-color: $color_background-4;

				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				width: 20px;
				height: 6px;

				border-radius: 10px;

				transition: all 0.3s ease;

				&:hover{
					background-color: $color_background-6;
				}
			}

			&:focus {

				&::before {
					background-color: $color_background-4;
				}
			}

			&:hover {

				&::before {
					background-color: $color_background-6;
				}
			}

			&.active {
				pointer-events: none;

				&::before {
					background-color: $color_background-5;
				}
			}

			// no dots if only one item exists
			&:first-child:last-child {
				display: none;
			}
		}


	.b-099e__subsection {
		overflow: hidden;
	}

		// list
		.b-099e__list {
			position: relative;

			display: flex;
			flex-direction: row;

			transition: transform 0.5s ease;

			.vertical & {
				flex-direction: column;
			}
		}

			// list: item
			.b-099e__list-item {
				flex-basis: 100%;
				flex-shrink: 0;

			}

			// list: item
			.b-099e__list-item:last-child {
				margin-bottom: 0;
			}

				// image
				.b-099e__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-099e__image .tag {
						position: absolute;
						bottom: 0.75em;
						left: 0;

						@include lineClamp(1);

						pointer-events: none;
						opacity: 0.9;

						@include breakpoint($breakpoint_desktop) {
							font-size: 18px;
							line-height: 1.3;
						}
					}





// vizualni debug - plab only
.patternlab .b-099e {

	@include breakpoint($breakpoint_desktop) {
		max-width: 960px;
	}
}
