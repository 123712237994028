//
// Asset
//



.asset,
.page-node .body .asset,
.b-047 .body .asset {

	//margin-top: 2em;

	@include breakpoint($breakpoint_desktop) {
		//margin-top: 3em;
	}

	h2 {

		margin-top: 0.3334em;
		margin-bottom: 0.3334em;

		padding-left: 0.5em;

		color: $color_background-6;

		@include typography_font(medium);
		text-transform: uppercase;
		//@include adjust-font-size-to($fontsize_box-heading, 1.25);
		font-size: $fontsize_box-heading;
		line-height: 1.2;

		a {
			@include link-style($color_background-6, none, $color_background-6, underline);
		}

		@include breakpoint($breakpoint_desktop) {

			//margin-top: 0.6667em;
			//margin-bottom: 0.6667em;

			//@include adjust-font-size-to($fontsize_box-heading_desktop, 1.3334);
			font-size: $fontsize_box-heading_desktop;
			line-height: 1.3;
		}
	}

	// titulek boxu, nebo-li prvni h2 boxu
	h2:first-of-type {
		margin-top: 0;
	}

	ul {
		list-style-image: none;
		li { list-style: none; }
	}

	.image {

		overflow: hidden;

		img {
			display: block;
			// tahle sranda je opustena
			//transition: transform 0.3s;
		}
		/*img:hover {
			transform: scale(1.1, 1.1);
		}*/
	}


	// pripad kdy je paragraph obalen cely odkazem (napriklad klikaci perexy)
	// bez dekorace
	a:hover > p {
		// hack s inline block nutny pro pretizeni rodicovske text-decorace ktera se nechova v css standardne
		// http://stackoverflow.com/questions/1823341/how-do-i-get-this-css-text-decoration-override-to-work
		display: inline-block;

		text-decoration: none;
		// zde by byl vyborny tenhle mixin, ale potrebuje specifikovat barvu
		// a color v css nema zadnou default / auto hodnotu a cimkoliv jinym by se prebila a ovlivnila
 		// @include link-style(auto, none, auto, none);
	}
}
