//
// B-009 Styles
//



$b-009_image-size: 8em;
$b-009_button-height: 4em;

$b-009_title_font-size_mobile: $fontsize_h3; // alesem undef, nechavame default pro mobile h3
$b-009_title_font-size_desktop: 22px;


.b-009 {
	
}
	
	.b-009__subsection {
		
		margin-top: 1em;
		
		padding: 1em;
		@include breakpoint($breakpoint_desktop) {
			padding: 1.25em;
		}
		
		background-color: $color_background-2;
		
	}
		.b-009__block {
		}
			
		.b-009__block--first {
			
			display: flex;
			flex-direction: row;
			
		}
			
			.b-009__title-area {
				
				// IE
				min-width: 1px;
				
				flex-grow: 1;
				
				display: flex;
				flex-direction: column;
				
				margin-right: 1em;
			}
				
				.b-009__broadcast-time {
					
					flex-grow: 1;
					
					margin: 0;
					
					color: $color_text_paragraph;
					
					@include adjust-font-size-to($fontsize_box_petit_main);
					line-height: 1.5em;
					
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($fontsize_box_petit_main_desktop);
					}
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-009__title-area > h3 {
					
					clear: left;
					margin: 0;
					
					@include adjust-font-size-to($b-009_title_font-size_mobile);
					
					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($b-009_title_font-size_desktop);
						line-height: 1.5em;
					}
				}
			
			
			.b-009__image {
				
				flex-shrink: 0;
				
				margin-top: -2em;
				
				width: $b-009_image-size;
				height: $b-009_image-size;
				
			}
		
		
		.b-009__block--second {
			clear: both;
			margin-top: 1em;
			
		}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-009__block--second p {
				
				display: inline-block;
				
				margin: 0;
				
				color: $color_text_paragraph;
				
			}
			
			.b-009__button-question {
				
				height: $b-009_button-height;
				
				margin-top: 1em;
			}
		
		
		.b-009__block--form-toggle {
		}
			
			.b-009__message {
				
				display: block;
				
				margin-top: 1em;
			}
		
		
		.b-009__block--form {
			
			margin-top: 1em;
		}
			
			.b-009__input {
				margin-bottom: 0.5em;
			}
			
			.b-009__button-confirm {
				
				margin-top: 1em;
				
				height: $b-009_button-height;
				
				@include breakpoint($breakpoint_desktop) {
					display: inline-block;
				}
			}





//
// B-009b Styles
//
.b--009b {}

	.b-009b__image img {
		display: block;
	}
	
	// obecne vlastnosti nadpisu prvniho elementu
	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.b-009__block--second h3 {
		margin: 0.5em 0;
	}





// vizualni debug - plab only
.patternlab .b-009 {
	max-width: 450px;
}