$dvanactka_text_color_main: $color_background-6;
$dvanactka_text_font_size_h2: 2rem;
$dvanactka_text_font_size_main: .9rem;
$dvanactka_text_font_size_perex: 1.2rem;

.dvojka-dvanactka {
}

	.dvojka-dvanactka h2 {
		font-size: $dvanactka_text_font_size_h2;
		text-transform: inherit;
		color: $color_text_accent;
		@include breakpoint($breakpoint_desktop) {
			padding-left: 0;
		}
	}

	.dvojka-dvanactka > p {
		font-size: $dvanactka_text_font_size_perex;
	}

	.dvojka-dvanactka .dvojka-dvanactka__list {
		display: flex;
		flex-wrap: wrap;
	}

		.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item {
			display: flex;
			flex-basis: 100%;
			margin: 0 0 20px 0;
			text-align: center;
			color: $dvanactka_text_color_main;
			font-size: $dvanactka_text_font_size_main;
			word-break: break-word; // ochranna pred pretecenenim u dlouhych url

			@include breakpoint($breakpoint_tablet) {
				flex-basis: 47%;
				margin-right: 6%;
			}

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 30%;
				margin-right: 5%;
			}
		}

		.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item:nth-child(2n) {

			@include breakpoint($breakpoint_tablet) {
				margin-right: 0;
			}

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 30%;
				margin-right: 5%;
			}
		}

		.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item:nth-child(3n) {

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

		.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item::before {
			content: '';
			display: inline-block;
			width: 10px;
			min-height: 150px;
			height: 100%;
			border: 1px solid $color_background-4;
			border-right: none;
		}

		.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item::after {
			content: '';
			display: inline-block;
			width: 10px;
			min-height: 150px;
			height: 100%;
			border: 1px solid $color_background-4;
			border-left: none;
		}

			.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item .dvojka-dvanactka__list-item-wrap {
				padding: 20px;
			}

			.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item .dvojka-dvanactka__list-item-wrap:first-child {
				flex-basis: 100%;
			}

				.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item .dvojka-dvanactka__list-item-wrap .dvojka-dvanactka__description {
					margin-top: 10px;
					margin-bottom: 10px;
				}

				.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item .dvojka-dvanactka__title {
					font-family: 'rozhlas_medium';
					color: $color_text_accent;
					text-transform: uppercase;
				}

				.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item .dvojka-dvanactka__link {
					color: $color_text_accent;
					text-decoration: underline;
				}

				.dvojka-dvanactka .dvojka-dvanactka__list .dvojka-dvanactka__list-item .dvojka-dvanactka__link:hover {
					text-decoration: none;
				}


// styly odvozene z factboxu - imitace vzhledu
.dvojka-dvanactka__information {
	display: block;
	clear: both;
	margin-top: 1em;
	margin-bottom: 1em;
	padding: .5em 10px;
	background-color: $color_background-5;
	color: $color_background-2;
  font-size: 14px;
  line-height: 24px;

	@include breakpoint($breakpoint_desktop) {
		margin: 1.5em -1.875rem;
		padding: 1em 1.5em;
    font-size: 16px;
    line-height: 26px;
	}

  h2 {
		@include typography_font(medium);
		margin-top: 10px;
		margin-top: 0.625rem;
		margin-bottom: 10px;
		margin-bottom: 0.625rem;
		padding-left: 0;
		color: $color_text_accent_inverted;

		@include breakpoint($breakpoint_desktop) {
			font-size: 24px;
    	line-height: 34px;
		}
	}

	p {
		margin-top: .625rem;
    margin-bottom: .625rem;
    font-size: 14px;
    line-height: 24px;
		@include breakpoint($breakpoint_desktop) {
			font-size: 16px;
	    line-height: 26px;
		}

		strong {
			@include typography_font(bold);
			font-weight: bold;
		}
	}

	a {
		color: $color_background-0;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.dvojka-dvanactka__audio {
	margin: 2em 0;

	.field-main-audio .file-audio {
		margin: 0;
	}
}
