//
// Digitalni radio - HP
//



.digital.page-digital-hp {

	/* prvni region .l-grid--12-1 */
	.content div.l-grid--12-1.item-first {
		// prvni box
		.box:first-of-type {
		}

		// posledni box
		.box:last-of-type {
		}
	}

	// zruseni defaultniho odsazeni boxu
	#otazky {
		margin-bottom: 0;
	}

	#otazky + div.box {
		margin-top: 0;
	}

	// skryti casti formulare
	#otazky .panel {
		display: none;
	}

}
