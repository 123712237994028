//
// Video wrapper
//



.video-wrapper {
	position: relative;
	height: 0;
}
.video-wrapper--16x9 {
	padding-bottom: 56.25%; // 16:9
	//padding-top: 25px;
}
	
	.video-wrapper__embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
