//
// Wave - Top podcasty
//



.wave.page-topleto {

	/* defaults */
	.main {
			background-color: transparent;
	}

	.box {
		h2 {
			color: $color_background-9;
		}

		h2 a {
			@include link-style($color_background-9, none, $color_background-9, underline);
		}
	}



	/* b-002g */
	#b002g7 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 1em;
		}

		.b-002g__list {
			@include breakpoint($breakpoint_desktop) {
				flex-wrap: wrap;
			}
		}

		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 30%;
				margin-bottom: 1em;
			}
		}
	}

	/* b-007a carousel */
	.b-007a {

		.b-007a__list-item {

			ul li:not(:first-child) {
				display: none;
			}

			@include breakpoint($breakpoint_desktop) {

				min-width: 80%;

				margin-bottom: $b-007a_marginbottom_item; // kvuli pretekajicimu boxu s H3 Title
			}
		}

		.b-007a__block--second {

			@include breakpoint($breakpoint_desktop) {

				max-width: 65%;

				padding: 0.5em;

				right: 3em;
				bottom: -1em;
			}
		}
	}
}
