//
// A-004e Styles
//



.a-004e {
	
	display: flex;
	flex-direction: row;
	flex-wrap: no-wrap;
}
	
	// vnoreny atom a tedy ne pres specifickou tridu
	.a-004e > .button-series {
		
		flex-grow: 1;
	}