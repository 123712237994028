//
// b-069 Styles
//
// box s dlazdicovym vypisem buttonu
// toto je kostra, samotne itemy jsou atomy button-box-flat--tile
//



.b-069 {
}

	.b-069__section {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		.b-069__block {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
			}
		}

		.b-069__block:not(:first-child) {
			margin-top: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 0;
			}
		}

		.b-069__block--primary {
			@include breakpoint($breakpoint_desktop) {
				padding-right: 1em;
			}
		}

		.b-069__block--secondary {
			@include breakpoint($breakpoint_desktop) {
				padding-left: 1em;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		// standardne ma box h2, h3 je napr. pro potreby Poplatku
		.b-069__block > h3:first-of-type {
			margin-top: 0;
			margin-bottom: 0.6667em; // stejna hodnota jako u h2 boxu
		}

			.b-069__list {
			}

				.b-069__list-item {
					margin-top: 1em;
				}

				.b-069__list-item:first-child {
					margin-top: 0;
				}



// b-069a
.b-069a {
	.b-069__block > h3:first-of-type {
		@include breakpoint($breakpoint_desktop) {
			font-size: 1.25em;
		}
	}

	.b-069__list-item {
		.button-box-flat {
			padding-top: 1em;
			padding-bottom: 1em;
			//background-color: $color_poplatek_main !important; // prebiji tridy bg-color-main, bg-color-add-1 a bg-color-add-2

			background-image: linear-gradient(45deg, $color_poplatek_main, $color_poplatek_add-1);

			.button-box-flat__label {
				@include typography_font(medium);
			}
		}
	}

	.b-069__block--primary {
		.b-069__list-item {
			.button-box-flat {
				@include breakpoint($breakpoint_desktop) {
					background-image: linear-gradient(45deg, $color_poplatek_main 25%, $color_poplatek_add-1);
				}
			}
		}
	}

	.b-069__block--secondary {
		.b-069__list-item {
			.button-box-flat {
				@include breakpoint($breakpoint_desktop) {
					background-image: linear-gradient(45deg, $color_poplatek_add-1 25%, $color_poplatek_add-2);
				}
			}
		}
	}
}



// b-069b
.b-069b {
	padding-bottom: 2em;
	border-bottom: 1px solid $color_divider;

	@include breakpoint($breakpoint_desktop) {
		padding-bottom: 3em;
	}

	.b-069__block > h3:first-of-type {
		@include breakpoint($breakpoint_desktop) {
			font-size: 1.6667em;
			@include typography_font(bold);
		}
	}

	.b-069__list-item {
		.button-box-flat {
			border: 1px solid $color_background-2;
			background-color: $color_background-1;

			@include link-style($color_poplatek_add-3, none, $color_poplatek_main, underline);

			padding-top: 1em;
			padding-bottom: 1em;
			padding-left: 0;

			//background-color: $color_poplatek_main !important; // prebiji tridy bg-color-main, bg-color-add-1 a bg-color-add-2
			//background-image: linear-gradient(45deg, $color_poplatek_main, $color_poplatek_add-1);
			//background-image: linear-gradient(90deg, $color_poplatek_add-3 5em, $color_background-1 5em);
			background-image: linear-gradient(90deg, $color_poplatek_main 5em, $color_background-1 5em);

			@include breakpoint($breakpoint_desktop) {
				min-height: 80px; // dvou radkovy
			}

			.button-box-flat__emblem {
				margin: 0 1.5em;
				width: 2em;
			}

			.button-box-flat__label {
				@include typography_font(medium);
				color: $color_poplatek_main;
				padding: 0 1.5em;
			}

			.button-box-flat__icon--arrow-single-east {
				background-image: $file_icon_arrow_single_east_colored-decoration-main;
			}
		}
	}
}





// vizualni debug - plab only
.patternlab .b-069 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 800px;
	}
}
