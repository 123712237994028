//
// Digitalni radio - obecne vlastnosti vsech stranek
//



.digital {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_digital_main, $color_digital_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_digital_main);
	}

	// stanicni nav
	.b-016__wrapper {
		margin: 0;

		h1,
		h2 {
			background-color: $color_background-0;
			//color: $color_background-9;
		}

		h1:before,
		h2:before {
			background-color: $color_digital_main;
		}

		.b-016 {
			background-color: $color_digital_main;

			.b-016__list {
				background-color: $color_digital_main;

				.b-016__list-item {

					a {
						//@include link-style($color_background-9, none, $color_background-9, none);
					}

					a.expanded,
					a:hover {
						background-color: $color_digital_main_darker;
					}
				}

				.b-016__list-more {
					background-color: $color_digital_main_darker;

					a:hover {
						background-color: $color_digital_main;
					}
				}
			}

		}
	}

}
