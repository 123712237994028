//
// Pohoda - obecne vlastnosti vsech stranek
//



$pohoda_selector_body: 'body.pohoda';
$pohoda_selector_body-hp: $pohoda_selector_body + '.page-pohoda-hp';
$pohoda_selector_body-kamery: $pohoda_selector_body + '.page-kamery';


#{$pohoda_selector_body} {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_pohoda_main, $color_pohoda_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
	&.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_pohoda_main);
	}

		// stanicni nav
	.b-016__wrapper {

		h1,
		h2 {
			background-color: $color_background-4;
			color: $color_background-8;
		}

		h1:before,
		h2:before {
			background-color: $color_background-2;
		}

		.b-016 {
			background-color: $color_background-2;

			.b-016__list {
				background-color: $color_background-2;

				.b-016__list-item {

					a {
						@include link-style($color_background-6, none, $color_background-0, none);
					}

					a.expanded {
						background-color: $color_pohoda_main;
						color: $color_background-4;
					}

					a.expanded:hover {
						color: $color_background-0;
					}
				}

				.b-016__list-more {
					background-color: $color_pohoda_main;

					a {
						@include link-style($color_background-4, none, $color_background-0, none);
					}

					a:hover {
						background-color: $color_pohoda_main_darker;
					}
				}
			}

		}
	}

}
