//
// Button
//



.button {

	@include breakpoint($breakpoint_desktop) {

	}
}



// wrapper pro zarovnani butonu zleva/stred/zprava
.button__wrapper {
	display: flex;
	flex-direction: column;

	margin-top: 1.5em;

	// left aligned button
	&.button__wrapper--aligned-left {
		.button {
			align-self: flex-start;
		}
	}

	// right aligned button
	&.button__wrapper--aligned-right {
		.button {
			align-self: flex-end;
		}
	}

	// center aligned button
	&.button__wrapper--aligned-center {
		.button {
			align-self: center;
		}
	}

	// stretch aligned button
	&.button__wrapper--aligned-stretch {
		.button {
			align-self: auto;
		}
	}

	.button {
		margin-top: 0;
	}
}
