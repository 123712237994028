//
// Styles - Node Type Profile
//



.node-type-profile {

	// grid
	// TODO: zmenit z .item-special na .nth-of-type--special
	.l-grid--12-1.nth-of-type--special {
	}


	// grid
	.l-grid--12-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--12-1 {
		}


	// grid
	.l-grid--8-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--8-1 {

			// title
			h1 {
				width: 100%;
				margin: 0;
				margin-top: 1em;
				font-size: 26px;
				line-height: 1.2;

				color: $color_text_accent;

				@include breakpoint($breakpoint_desktop) {
					float: right;
					width: 54%;
					margin: -.1em 0 0 .4em;
					font-size: 36px;
				}
			}

			// primary image
			.field-image-primary {

				@include breakpoint($breakpoint_desktop) {
					margin-top: 3em; // 48px
					width: 40%;
				}
			}

			// img metadata
			.img-metadata,
			.node-image-metadata--profile {
				font-size: $fontsize_h5_desktop;

				@include breakpoint($breakpoint_desktop) {
					font-size: $base-font-size;
					width: 40%;
				}
			}

			// employment
			.field-term-positions,
			.field-employment-web {
				@include typography_font(medium);
				color: $color_text_paragraph;
				border-bottom: 1px solid $color_background-2;
				padding: 0 0 .5em 0;
				font-size: 14px;

				@include breakpoint($breakpoint_desktop) {
					float: right;
					width: 54%;
					margin-left: 1em;
					padding: .5em 0 1em;
					font-size: 16px;
				}
			}

			.field-about-brief,
			.field-about-briefly {
				@include typography_font(medium);
				//padding: 1em 0;
				font-size: 18px;

				@include breakpoint($breakpoint_desktop) {
					float: right;
					width: 54%;
					margin-left: .8em;
					font-size: 20px;
					line-height: 1.5;
				}
			}

			// file audio
			.file-audio {
				margin-left: 0;
			}
		}


		// content-container
		.content-container {
			position: relative; // kvuli absolutnimu pozicobvani vertikalnich soc. buttonu
		}

			// vertical soc. buttons
			.content-1--1-1 {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;

					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: calc(100% - #{$node_left-margin});
				}
			}

			// perex, body
			.content-1--7-2 {
				position: relative;
				margin-top: 1em;

				@include breakpoint($breakpoint_desktop) {
					margin-top: 2em;
				}

				// motto, body
				.field-motto,
				.body {
					font-size: $base-font-size;
					line-height: 1.6em;

					color: $color_text_paragraph;

					margin-left: 0;
					padding-left: 0;

					@include breakpoint($breakpoint_desktop) {
						font-size: 20px;
						margin-left: 70px;
						padding-left: 30px;
					}
				}

				p {
					margin: 0.5em 0;
				}

				p:first-child {
					margin-top: 0;
				}
			}


		// main audio anchor, authors, bottom soc. buttons
		.content-2--8-1 {
		}

			// fotka autora - fallback
			.view-display-id-node_author_main.box {
				display: none; // opusteno
			}

			// jmeno autora - fallback
			.view-display-id-node_author_main_2.box {
				display: none; // opusteno
			}

			// expired audio
			.article-type--audio .audio-rights-expired {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}


	// grid - aside
	.l-grid--4-9.nth-of-type--1 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 8em;
		}
	}

		// content boxes, ad
		.content-1--4-9 {
		}


	// grid
	.l-grid--12-1.nth-of-type--2 {
	}

		// iRozhlas
		.content-2--12-1 {
		}


}
