//
// jazzmansky projekt - profil osoby
//

$background-jazz: #e5e4ea;


.box.jazzmani {
	position: relative;
	z-index: 1;
	margin-top: 0;
	padding-top: 0;
	padding-right: 10px;
	padding-bottom: 0;
	padding-left: 10px;
}

.box.jazzmani::before {
	content: '';
	position: absolute;
	background-color: $background-jazz;
	position: absolute;
	left: -200%;
	right: -200%;
	height: 100%;
	z-index: -1;
	top: 0;
}

	// horni a dolni padding pridavame pouze pri zobrazenem detailu jazzmana - odstrani se tim nechtene sede pozadi
	.box.jazzmani.detail-is-open {
		padding-top: 1em;
		padding-bottom: 3em;
		@include breakpoint($breakpoint_desktop) {
			padding-top: 3em;
			padding-bottom: 5em;
		}
	}

	.box.jazzmani .inner-content-wrapper {
		padding-top: 10px;
		@include breakpoint($breakpoint_desktop) {
			max-width: 780px; // podle graf. navrhu
			margin-left: auto;
			margin-right: auto;
			padding-top: 20px;
		}
	}

		.box.jazzmani .content-1-1 {
			display: flex;
			flex-direction: column;
			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
			}
		}

			// obal metadat
			.box.jazzmani .wrappper-jazzman-metadata {
				display: flex;
				flex-direction: column;
				@include breakpoint($breakpoint_desktop) {
					order: 1;
					margin-left: 1.5em;
				}
			}

			// obal obrazku
			.box.jazzmani .wrappper-jazzman-image {
				display: flex;
				flex-direction: column;
				flex-basis: 100%;
				@include breakpoint($breakpoint_desktop) {
					max-width: 300px;
				}
			}

			// nadpis
			.box.jazzmani .content-1-1 h2 {
				margin-bottom: 0;
				padding-left: 0;
				text-transform: unset;
				color: $color_background-9;
				font-size: 1.8rem;
			}

				// na jaky hudebni nastroj hraje
				.box.jazzmani .content-1-1 .field.field-employment-web {
					margin-bottom: 1em;
					color: $color_background-6;
					font-size: .9rem;
				}

				// kratky popisek / bio
				.box.jazzmani .content-1-1 .field.field-about-briefly {
					border-top: 1px solid $color_background-3;
					@include typography_font(medium);
				}

				// fotka jazzmana
				.box.jazzmani .content-1-1 .field-image-primary.no-main-audio {
					margin-top: 0;
					margin-bottom: 0;
					@include breakpoint($breakpoint_desktop) {
						padding-left: 0;
					}
				}

				// metadata fotky
				.box.jazzmani .content-1-1 .field-image-primary.no-main-audio + .img-metadata {
					font-size: .85rem;
					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

		// doplnujici informace / rozhovor s jazzmanem
		.box.jazzmani .content-1-2 {}

			.box.jazzmani .content-1-2 .block.block-social-buttons-vertical {
				top: auto !important; // soc. site
			}

			.box.jazzmani .content-1-2 .field.body {
					@include breakpoint($breakpoint_desktop) {
						margin-left: 70px;
						padding-right: 30px;
						padding-left: 30px;
					}
				}
