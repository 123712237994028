//
// Portal - HP
//


.portal.node-type-custom-hp,
.portal.page-portal-hp {

	// box
	.box {

		// titulek
		h2 {
			color: $color_background-6;

			a {
				@include link-style ($color_background-6, none, $color_background-6, underline);
			}
		}

		// box doprava - skryti
		&.b-102 {
			display: none;
		}

		// stanicni tagy
		&.b-002b,
		&.b-023a {
			.tag {
				text-transform: uppercase;
			}
		}
		/*&.b-002b {
			.tag {
				&:not(.bg-color-main-radiozurnal):not(.bg-color-main-dvojka):not(.bg-color-main-vltava):not(.bg-color-main-plus):not(.bg-color-main-wave):not(.bg-color-main-d-dur):not(.bg-color-main-jazz):not(.bg-color-main-junior):not(.bg-color-main-brno):not(.bg-color-main-budejovice):not(.bg-color-main-cb):not(.bg-color-main-hradec):not(.bg-color-main-kv):not(.bg-color-main-liberec):not(.bg-color-main-olomouc):not(.bg-color-main-ostrava):not(.bg-color-main-pardubice):not(.bg-color-main-plzen):not(.bg-color-main-regina):not(.bg-color-main-region):not(.bg-color-main-regiony):not(.bg-color-main-sever):not(.bg-color-main-strednicechy):not(.bg-color-main-vary):not(.bg-color-main-vysocina):not(.bg-color-main-zlin) {
					display: none;
				}
			}
		}*/
	}

	// box iRozhlas
	.b-002j {
		margin-top: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 2.5em;
		}
	}

	// region l-grid--8-1 item-first
	// nestandardni markup
	.l-grid--8-1.item-first {
		//
		.entity-paragraphs-item {}

		//
		.entity-paragraphs-item.box {

			// titulky, jsou nestandardne mimo element .box
			h2 {}

			.box {
				margin-top: 0;
			}
		}
	}
}
