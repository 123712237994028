//
// Budejovice - HP
//



.budejovice.page-budejovice-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}



	// Dropdown form
	// title
	.box-title {
		h2 {}
	}
	// buttons
	.button-box-flat-pisnickyproradost,
	.button-box-flat-melodiepovecera {
		@include breakpoint($breakpoint_desktop) {
			width: 50%;
		}
	}
	.button-box-flat-pisnickyproradost { 
		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
	}
	.button-box-flat-melodiepovecera { 
		@include breakpoint($breakpoint_desktop) {
			margin-top: 1.5em;
		}
	}
	// form Melodie podvecera
	.form-melodiepodvecera {
		display: none;
		
		margin-top: 0;
		
		h2 {
			display: none;
		}
	}

}