//
// Prix Bohemia Radio - obecne vlastnosti vsech stranek
//



// vars
// colors 2020
$prixbohemia_color_red: rgba(#c71b40, 1);
$prixbohemia_color_blue-dark: rgba(#000f37, 1);
$prixbohemia_color_yellow: rgba(#f6a100, 1);

// colors 2021
$prixbohemia_color_purple: rgba(#895ba1, 1);
$prixbohemia_color_blue-light: rgba(#5ebfeb, 1);
$prixbohemia_color_blue-dark: rgba(#000f37, 1);;



.prixbohemia {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_prixbohemia_main, $color_prixbohemia_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_prixbohemia_main);
	}

	// stanicni nav
	.b-016__wrapper {
		h1,
		h2 {
			background-color: $color_background-0;
			//color: $color_background-9;
			}

		h1:before,
		h2:before {
			background-color: $color_prixbohemia_main;
		}

		.b-016 {
			background-color: $color_prixbohemia_main;

			.b-016__list {
				background-color: $color_prixbohemia_main;

				.b-016__list-item {

					a {
						//@include link-style($color_background-9, none, $color_background-9, none);
					}

					a.expanded,
					a:hover {
						background-color: $color_prixbohemia_main_darker;
					}
				}

				.b-016__list-more {
					background-color: $color_prixbohemia_main_darker;

					a:hover {
						background-color: $color_prixbohemia_main;
					}
				}
			}

		}
	}

	// upravy vzhledu programu pro rocnik 2020
	.pbr-program-wrapper {
		.pbr-program {
			&__day {
				// obarveni zahlavi v rozbalenem stavu zalozky
				&.expanded {
					.pbr-program__header {
						background-color: $prixbohemia_color_blue-dark;
					}
				}
				.pbr-program {
					&__header {
						background-color: $prixbohemia_color_blue-light;
						transition: background-color .25s;
						&:hover {
							background-color: $prixbohemia_color_purple;
						}
						.field-pbr-date {
							@include breakpoint($breakpoint_desktop) {
								font-size: 1.75rem;
							}
						}
						.field-pbr-category {
							@include breakpoint($breakpoint_desktop) {
								font-size: 1.2rem;
							}
						}
					}
					&__content {
						// modifikace stylu tlacitka
						.field.field-pbr-button {
							justify-content: flex-start;
							.button-box-flat {
								border: 0;
								padding: 0;
								text-decoration: underline;
								font-size: .85rem;
							}
							.button-box-flat__icon--arrow-double-east {
								background-size: 8px 8px;
								margin-left: 5px;
							}
						}
						// uprava fontu u mista konani akce
						 .field-pbr-place {
							 @include typography_font(medium);
						}
						.field-pbr-time,
						.field-pbr-title {
							color: $prixbohemia_color_yellow !important;
							a {
								color: $prixbohemia_color_yellow !important;
							}
						}
					}
				}
			}
		}
	}
}
