//
// textarea
//


// obecny element textarea je definovan v html defaults pomoci selekce primo na tag <textarea>
textarea {
	background-color: $color_background-0;
	
	border: 1px solid $color_background-1;
	border-radius: 0.15em;
	
	padding-left: 0.25em;
	padding-right: 0.25em;
	
	outline: none;
	
	line-height: 2em;
	
	transition: all 0.2s ease-in-out;

	&:focus {
		
		border: 1px solid $color_decoration_main;
		
		box-shadow: 0px 0px 5px 0px $color_decoration_main;
		
	}
}
