//
// Button Pause - Launcher (b-015)
//



.button-pause--launcher-sport {
	position: relative;

	display: block;
	width: 4em;
	height: 4em;

	margin: 0;
	padding: 0;

	background-image: $file_button_pause_launcher-sport_colored-ed2e38;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 0;

	//transition: opacity 0.2s;
	transition: background-image 0.2s;

	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}

.button-pause--launcher-sport::after {
	content: '';

	position: absolute;
	width: 100%;
	height: 100%;

	background-image: $file_button_pause_launcher-sport_colored-bc1526;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 100%;

	opacity: 0;
}

.button-pause--launcher-sport:hover::after {
	opacity: 1;
	transition: opacity 0.2s;
}
