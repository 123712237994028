//
// Regiony (Radio vaseho kraje) - HP
//



.regiony.page-regiony-hp {
	
	/* h2 boxu */
	.box {
		h2 {
			color: $color_background-6;
		}
	}

	/* prvni region .l-grid--12-1 */
	.content div.l-grid--8-1.item-first {
		// prvni box
		.box:first-of-type {
		}

		// posledni box
		.box:last-of-type {
		}

		// barevnost promo boxu
		.b-031a-v2a:nth-of-type(1) {
			.b-031a-v2a__block--second.bg-color-main {
				background-color: $color_regiony_main !important;
			}
		}
		.b-031a-v2a:nth-of-type(2) {
			.b-031a-v2a__block--second.bg-color-main {
				background-color: $color_regiony_main_darker !important;
			}
		}
		.b-031a-v2a:nth-of-type(3) {
			.b-031a-v2a__block--second.bg-color-main {
				background-color: $color_background-8 !important;
			}
		}
		.b-031a-v2a:nth-of-type(4) {
			.b-031a-v2a__block--second.bg-color-main {
				background-color: $color_background-6 !important;
			}
		}
	}

}
