//
// Dvojka - obecne vlastnosti vsech stranek
//



.dvojka {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_dvojka_main, $color_dvojka_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_dvojka_main);
	}

	// box b-030 - simple ranking
	.b-030.simple-ranking {
		.b-030__item-info-timestamp {
			width: 25px;
			@include typography_font(medium);
		}
	}
}
