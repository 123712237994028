//
// 1968 - Akce
//



.domain-1968-rozhlas-cz.page-akce {

	// prvni box
	.b-031a-v2b {
		// prvni box leveho sloupce nepotrebuje default vertikalni odsazeni
		margin-top: 0em;
	}
}