//
// B-102 Styles
//



.b-102 {
	display: flex;
	flex-direction: column;
}
	
	.b-102 h2 {
	}
	
	.b-102__section-block {
		display: flex;
		flex-direction: column;
		
		padding: 1em;
		
		border: 1px solid $color_divider;
	}
	
	.b-102__table {
		border-bottom: 1px solid $color_divider;
	}
		
		.b-102__table-row:not(:last-child) {
		}
			
			.b-102__traffic-icon {
				width: 48px; // stara .png ikona
				height: 50px; // stara .png ikona
				
				padding: 0.5em 0; // kdy byl text kratky
			}
			
			.b-102__traffic-text {
				font-size: 0.75em;
				line-height: 1.25em;
				
				padding-left: 1em;
				padding-bottom: 1em;
				
				color: $color_text_paragraph;
				
				@include breakpoint($breakpoint_desktop) {
					padding-bottom: 1.5em;
				}
			}
	
	
	.b-102__section-block .button {
		display: inline-flex;
		align-self: center;
		
		margin-top: 1em;
		
		@include breakpoint($breakpoint_desktop) {
			margin-top: 1.25em;
		}
	}





// vizualni debug - plab only
.patternlab .b-102 {
	
	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}