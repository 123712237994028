//
// Styles JV - Top menu - iRozhlas
//



.jeziskova-vnoucata {

	.row, .row-main {
		position: relative;
		margin: 0;
		padding: 0;
	}
	.row:before, .row-main:before, .row:after, .row-main:after {
		content: '';
		display: table;
	}

	.row:after, .row-main:after {
		clear: both;
	}

	.row-main {
		margin: 0 auto;
		padding: 0 10px;
		max-width: 1200px;
	}

	@media (max-width: 767px) {
		.row-main {
			//max-width: 560px;
		}
	}

	.icon-svg {
		display: inline-block;
		vertical-align: middle;
		position: relative;
	}

	.icon-svg__svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		fill: currentColor;
		pointer-events: none;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}

	.icon-svg__fallback {
		position: absolute;
		top: -5000px;
		left: -5000px;
	}

	.ie8 .icon-svg__fallback {
		position: static;
		left: auto;
		top: auto;
	}

	.icon-svg:before {
		content: '';
		display: block;
	}

	.icon-svg--symbol--small {
		width: 14px;
	}

	.icon-svg--symbol--small:before {
		padding-top: 121.428571429%;
	}

	.m-top {
		position: relative;
		margin-bottom: 50px;
		background: #000;
		text-transform: uppercase;
		z-index: 1;
	}
	.m-top::before {
		content: "";
		position: absolute;
		left: -200%;
		right: -200%;
		height: 100%;
		background: #000;
		z-index: 0;
	}

	@media screen and (max-width: 991px) {
		.m-top {
			margin-bottom: 40px;
		}
	}

	.m-top__list {
		font-size: 0;
		letter-spacing: normal;
		margin-left: -7px;
	}
	.m-top__list > * {
		display: inline-block;
		vertical-align: top;
		width: auto;
		font-size: 13px;
		letter-spacing: 0.01em;
	}

	.m-top__item {
		margin-left: 1px;
	}

	.m-top__link:link, .m-top__link:visited {
		color: #fff;
		text-decoration: none;
		display: block;
		padding: 7px 9px;
		-webkit-transition: background .2s;
		transition: background .2s;
	}

	.m-top__link--icon,
	.m-top__link.m-top__link--icon {
		padding-left: 35px;
		position: relative;
	}

	.m-top__link--icon .icon-svg {
		position: absolute;
		top: 6px;
		left: 6px;
	}

	.m-top__link:hover, .m-top__link:focus {
		background: #333;
	}

}
