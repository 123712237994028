//
// B-052c Styles
//



$b-052c_item_fontsize_mobile: 16px;
$b-052c_item_fontsize_desktop: 18px;
$b-052c_item_background-arrow-size_desktop: 8px; // arrow desktop size
$b-052c_item_background-arrow-size_mobile: 6px; // arrow mobile size



.b-052c {
}

	.b-052c__title {
		// v plabu neni, ale v drupalu tato trida pritomna
	}

	.b-052c__list {

		//padding: 0 1em;
	}

		.b-052c__list-item {
			position: relative;

			padding: 0.8em 0;

			@include breakpoint($breakpoint_desktop) {
				padding: 0.5em 0;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-052c__list-item h3 {
				margin: 0;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-052c__list-item h3 a {
				position: relative;

				display: inline; // neni block kvuli specifickemu zalamovani
				width: 100%;

				padding-left: calc(2 * #{$b-052c_item_background-arrow-size_mobile}); // arrow mobile padding

				@include typography_font(medium);
				@include adjust-font-size-to($b-052c_item_fontsize_mobile);

				// arrow bg
				background-image: $file_icon_arrow_double_east_colored-decoration-main;
				background-repeat: no-repeat;
				background-position: left center;
				background-position-x: left; // IE safe positioning
				background-position-y: center;
				background-size: $b-052c_item_background-arrow-size_mobile $b-052c_item_background-arrow-size_mobile; // arrow mobile size

				@include breakpoint($breakpoint_desktop) {
					padding-left: calc(2 * #{$b-052c_item_background-arrow-size_desktop}); // arrow desktop padding

					@include adjust-font-size-to($b-052c_item_fontsize_desktop, 1.5);

					// arrow bg
					background-size: $b-052c_item_background-arrow-size_desktop $b-052c_item_background-arrow-size_desktop;  // arrow desktop size
				}
			}

			// oddelovaci pseudo linka...
			.b-052c__list-item:before {
				content: "";

				position: absolute;
				left: 0;
				bottom: 0;

				width: 100%;

				border-bottom: 1px solid $color_divider;
			}
			// ...neni na poslednim miste
			.b-052c__list-item:last-child:before {
				//content: initial; // nefunguje v IE
				display: none;
			}


		// first item
		.b-052c__list-item:first-child {
			padding-top: 0;
		}

		// last item
		.b-052c__list-item:last-child {
			padding-bottom: 0;
		}
