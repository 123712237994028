//
// Pseudo Exposed Filter
//


.radiobutton-filter {
	display: flex;
	flex-wrap: wrap;
	flex-basis: 100%;
	justify-content: center;

	margin-bottom: 1.5em;

	@include breakpoint($breakpoint_desktop) {
		margin-top: 1em;
		margin-bottom: 2.5em;
	}
}

	// item
	.radiobutton-filter__item {
		display: flex;
		flex-basis: 100%;

		@include breakpoint($breakpoint_desktop) {
			flex-basis: auto;
		}
	}

		// input
		.radiobutton-filter__item input {
			display: none;
		}

		// label: defaultni stav - nezaskrtnuty checkbox
		.radiobutton-filter__item label {
			font-size: $fontsize_paragraph;
			text-transform: inherit;

			color: $color_decoration_main;

			border-top: 1px solid $color_decoration_main;
			border-right: 1px solid $color_decoration_main;
			border-left: 1px solid $color_decoration_main;

			padding: 10px;

			width: 100%;

			cursor: pointer;
			@include no-select;

			@include breakpoint($breakpoint_desktop) {
				border-bottom: 1px solid $color_decoration_main;
				border-right: none;

				padding: 0.1em 2.25em;
			}
		}

		// lebel: zaskrtnuty checkbox
		.radiobutton-filter__item input[type=radio]:checked + label {
			color: $color_background-0;
			background-color: $color_decoration_main;
		}

		// label: posledni item
		.radiobutton-filter__item:last-of-type label {
			border-bottom: 1px solid $color_decoration_main;

			@include breakpoint($breakpoint_desktop) {
				border-right: 1px solid $color_decoration_main;
			}
		}
