//
// B-041e Styles
//



.b-041e {
	
	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
	
	.b-041e__list {
	}
		
		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-041e__list-item {
			
			margin-bottom: 1em;
		}
				
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-041e__list-item h3 {
				
				margin: 0;
				padding-top: 0.5em;
			}
			
			.b-041e__list-item .caption--public-from {
				
				padding-top: 0.5em;
			}
		
		
		.b-041e__list-item:last-child {
			
			margin-bottom: 0;
		}

