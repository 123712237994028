//
// Czech Radio - node id 8078144 (udalost Konference Plus)
//



.czechradio.page-node-8078144 {

	.l-grid--4-9.item-first,
  .l-grid--4-9.item-second {
		margin-top: 0;
	}

	.l-grid--12-1.item-first {
		margin-bottom: 0;
	}
}
