//
// Plus - node id 8077974 (udalost Konference Plus)
//



.plus.page-node-8077974 {

	.l-grid--4-9.item-first,
  .l-grid--4-9.item-second {
		margin-top: 0;
	}

	.l-grid--8-1.item-first {

		// dalsi ul po kliknuti na infinity scroll button
		.b-004f .b-004f__list:not(:first-child) {
			margin-top: 2em;
		}
	}

	.l-grid--12-1.item-first {
		margin-bottom: 0;
	}
}
