//
// B-006g Styles
//



//vars
$b-006g_button-open-close_size: 2.5em;

$b-006g_dropdown_height: 40px;

$b-006g_block_padding-vertical-desktop: 4.5em;
$b-006g_block_padding-horizontal-desktop: 8.5em;
$b-006g_block_padding-top-mobil: 2.5em;
$b-006g_block_padding-bottom-mobil: 1.5em;
$b-006g_block_padding-horizontal-mobil: 0;


.b-006g {
	overflow: hidden;
}

	// dropdown
	.b-006g__dropdown {
		display: flex;
		flex-direction: row;
		align-items: center;

		padding-left: 1em;
		padding-right: 2.5em;

		height: $b-006g_dropdown_height;

		background-color: $color_background-9;

		@include breakpoint($breakpoint_desktop) {
			height: $b-006g_dropdown_height;
		}

		.button-popup {
			margin-left: auto;

			&.button-popup--open {
				position: absolute;
				left: 0;
				right: 0;

				.button-popup__label {
					float: right;
				}
			}

			&.button-popup--close {
				display: none;

				position: absolute;
				left: 0;
				right: 0;

				@include breakpoint($breakpoint_desktop) {
					position: relative;
					left: auto;
					right: auto;
				}

				.button-popup__label {
					float: right;

					@include breakpoint($breakpoint_desktop) {
						float: none;
					}
				}
			}

			.button-popup__label {

				// opticka zmena velikosti k specifickemu zarovnani s nadpisem boxu H2
				width: $b-006g_button-open-close_size;
				height: $b-006g_button-open-close_size;

				background-image: $file_icon_close_colored-white;
			}
		}

		h3 {
			flex-grow: 1;

			color: $color_text_paragraph_inverted;

			margin-top: 0;
			margin-bottom: 0;

			padding-left: 0;
			padding-right: 0;

			font-size: 17px;
			line-height: 21px;
			text-align: left;

			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			/*position: relative;
			top: 0;
			transition: all 0.5s ease;*/

			@include breakpoint($breakpoint_desktop) {
				font-size: 19px;
				line-height: 23px;
			}
		}

			h3 a {
				@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
			}


		&.expanded {
			padding-left: 0;
			padding-right: 0;

			.button-popup {

				&.button-popup--open {
					display: none;
				}

				&.button-popup--close {
					display: block;
				}
			}

			h3 {
				display: none;
				/*top: -30px;
				opacity: 0;*/
			}
		}
	}

	.b-006g__subsection {

		position: relative;

		background-color: $color_background-9;

		&.collapsed {
			display: none;
		}

	}

		.b-006g__block {
			// neexituje vlastnost obecneho bloku
			// 1. blok je obsah (video a text)
			// 2. blok je wrapper tlacitek
		}

		.b-006g__block--content {

			display: flex;
			flex-direction: column;

			padding-top: calc(#{$b-006g_block_padding-top-mobil} - #{$b-006g_dropdown_height});
			padding-bottom: $b-006g_block_padding-bottom-mobil;

			//overflow: auto;

			@include breakpoint($breakpoint_desktop) {

				flex-direction: row;
				align-items: center;

				padding: $b-006g_block_padding-vertical-desktop $b-006g_block_padding-horizontal-desktop;
				padding-top: calc(#{$b-006g_block_padding-vertical-desktop} - #{$b-006g_dropdown_height});
			}
		}

			.b-006g__subblock--player {

				@include breakpoint($breakpoint_desktop) {
					flex-grow: 1;
				}
			}

			.b-006g__subblock--description {
				display: flex;
				flex-direction: column;
				align-items: center;

				@include breakpoint($breakpoint_desktop) {
					flex-basis: 35%; // dle Alese by melo byt 30%, ale na mensim breakpointu nevypada dobre
					flex-shrink: 1;

					justify-content: center;
					align-items: flex-start;

					margin-left: 2em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006g__subblock--description h3 {

					color: $color_text_paragraph_inverted;

					margin-top: 1.5em;
					margin-bottom: 1em;

					padding-left: 1em;
					padding-right: 1em;

					text-align: center;

					@include breakpoint($breakpoint_desktop) {
						font-size: 26px;
						line-height: 1.25em;

						padding-left: 0.5em;
						padding-right: 0;

						margin-top: 0;
						margin-bottom: 0;

						text-align: initial;
					}
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006g__subblock--description h3 a {
						@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
					}


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006g__subblock--description a {
					@include link-style($color_decoration_main, none, $color_decoration_main, underline);
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006g__subblock--description p,
					.b-006g__subblock--description a p {

						margin: 0;

						padding-left: 1em;
						padding-right: 1em;

						//color: $color_text_paragraph_inverted;
						// presunuto na vnorene <a>

						text-align: center;

						@include breakpoint($breakpoint_desktop) {
							margin-top: 0.25em;
							padding-left: 0.8em;
							padding-right: 0;

							text-align: initial;
						}
					}

					// muze byt i mimo <a>
					.b-006g .b-006__subblock--description p {
						color: $color_text_paragraph_inverted;
					}

					 .b-006__subblock--description p.b-006g__description {
						 margin-top: 0.5em;
					 }


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-006g__subblock--description .tag {
					background-color:$color_background-3;
					color: $color_text_accent;

					@include typography_font(regular);
					font-size: 13px;
					line-height: 13px;

					padding: 0.2em 0.4em;
					margin-top: 1em;
					margin-left: auto;
					margin-right: auto;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 2em;
						margin-left: 1em;
						margin-right: auto;
					}
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-006g__subblock--description .tag span {
						color: $color_text_accent;
					}


				.b-006g__description--time {
					color: $color_text_accent_inverted;

					@include typography_font(bold);
					font-size: 14px;
					line-height: 18px;
				}

				.b-006g__description--show {
					@include typography_font(regular);
					font-size: 14px;
					line-height: 18px;
				}
