//
// B-014a Styles
//



$b-014a_menu-bar-height_mobile: 3em; // bylo 2.5em
$b-014a_menu-bar-height_desktop: 4.5em; // bylo 3.5em
$b-014a_menu-bar-height-before_desktop: 100%; // dle Alese by melo byt 3.25em
$b-014a_fontsize_menu_top_more_mobile: 16px;
$b-014a_color-button-zive: rgba(#ed2e38, 1);



// hlavni flex
// logo - menu (list) - searchbar
.b-014a {
	position: relative;

	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	height: $b-014a_menu-bar-height_mobile;
	padding-left: $layout_main_horizontal-padding_mobile;
	//padding-right: $layout_main_horizontal-padding_mobile;
	padding-right: 0;

	background-color: $color_background-0;

	@include breakpoint($breakpoint_desktop) {
		height: $b-014a_menu-bar-height_desktop;
		padding-left: $layout_main_horizontal-padding_desktop;
		padding-right: $layout_main_horizontal-padding_desktop;

		//box-shadow: $shadow_b-014a;
	}
}

// nekonecny pruh (Martinovo reseni)
/*.b-014a::before {

	@include breakpoint($breakpoint_desktop) {
		content: "";

    position: absolute;
		left: -200%;
		right: -200%;

		height: 100%;

		background-color: $color_background-0;

		z-index: -1;
	}
}*/

// nekonecny pruh
.b-014a::before {

	@include breakpoint($breakpoint_desktop) {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100vw;
		//height: 100%;
		height: $b-014a_menu-bar-height-before_desktop;
		background-color: $color_background-0;

		z-index: -1;
	}

	// HACK: nez se poprve pohne desktop grid
	@media screen and (min-width: $layout_page_max-width) {
		left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
	}
}

// inverzni barevna varianta topmenu
.b-014a--inverted {
	background-color: $color_background-9;
}

.b-014a--inverted::before {

	@include breakpoint($breakpoint_desktop) {
		background-color: $color_background-9;
	}
}



	// logo
	.b-014a__logo {
		order: 1;

		margin-right: 2em;
		margin-right: auto; // ie fix: v ie11 se spatne interpretuje space-between, na desktopu o vice polozkach je to ok, na mobilu o dvou polozkach nebylo
		padding-right: 2em; // ie fix: nahrazujeme vyrazeny margin-right: 2em, viz vyse

		height: 100%; // Safari Fix: vertikalni zarovnani loga v menu (v Safari nefunguje vyska ditete 100%, pokud rodic nema definovanou vysku)

		@include breakpoint($breakpoint_desktop) {
			//margin-right: 4em;
			padding-right: 4em; // ie fix: nahrazujeme vyrazeny margin-right: 4em, viz vyse
		}
	}

		.b-014a__logo a {
			display: inline-block;

			//width: 6em; // default
			width: 8em; // 100let
			height: 100%;
			padding: 0;

			background-image: $file_logo_corporate_colored-73737f; // default
			//background-image: $file_logo_corporate-100let_colored-73737f; // 100let
			background-repeat: no-repeat;

			// idealni stav pozicovani loga
			// pretizeno viz desktop
			background-position: left center;

			@include breakpoint($breakpoint_tablet) {
				//width: 12em; // 100let
			}

			@include breakpoint($breakpoint_desktop) {
				//width: 8em; // default - puvodni
				width: 11em; // default
				//width: 16em; // 100let
				// jenze R v logu neni ve vertikalnim centru, takze pozicujeme na haluzni hodnotu rucne
				// aby sedelo na lince s polozkami menu
				background-position: left 48%;
			}

			// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
			.b-014a--inverted & {
				filter: brightness(200);
			}
		}





	// addons
	// zatim jen pro button Zive
	.b-014a__list--addons {
		order: 2;

		display: flex;
		flex-grow: 1;

		//margin-left: 1em;
		padding-left: 1em; // ie fix: nahrazujeme vyrazeny margin-left: 1em, viz vyse

		min-width: 0; // W3C: by default, flex items won't shrink below their minimum content size
		height: 100%;

		//display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;

		@include breakpoint($breakpoint_desktop) {
			order: 3;
			flex-grow: 0;
			//margin-left: 3em;
			padding-left: 3em; // ie fix: nahrazujeme vyrazeny margin-left: 3em, viz vyse
		}
	}

		// obecna polozka addons menu
		.b-014a__list--addons > li {
			height: 100%; // Safari Fix: vertikalni zarovnani polozky menu (v Safari nefunguje vyska ditete 100%, pokud rodic nema definovanou vysku)

			// button Zive
			&.b-014a__list-item--addons-live > a > span {
				background: $b-014a_color-button-zive;
			}
		}

			// a jeji vnitrni odkaz
			.b-014a__list--addons > li > a {
				position: relative; // kvuli absolutnimu pozicovani zobacku

				display: inline-flex;
				align-items: center;
				height: 100%;

				padding: 0 1em;

				white-space: nowrap;

				@include link-style ($color_text_paragraph, none, $color_text_accent, none);

				text-transform: uppercase;
				font-size: $fontsize_menu_top;

				@include breakpoint($breakpoint_desktop) {
					padding: 0 1em;

					font-size: $fontsize_menu_top_desktop;
				}

				span {
					background: $color_decoration_main;
					color: $color_text_accent_inverted;

					font-size: 10px;
					line-height: 14px;
					@include typography_font(medium);

					padding: 3px 7px;
					border-radius: 4px;
				}

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014a--inverted & {
					@include link-style ($color_text_paragraph_inverted, none, $color_text_accent, none);
				}
			}
			.b-014a__list--addons > li > a.expanded,
			.b-014a__list--addons > li > a:hover {
				background-color: $color_background-2;
			}
			/* Touch screen devices */
			@media (hover:on-demand) {
				.b-014a__list--addons > li > a:hover { background-color: transparent; }
				.b-014a__list--addons > li > a.expanded { background-color: $color_background-2; }
			}
			/* No hover support */
			@media (hover:none) {
				.b-014a__list--addons > li > a:hover { background-color: transparent; }
				.b-014a__list--addons > li > a.expanded { background-color: $color_background-2; }
			}
			// ico zobacek
			/*.b-014a__list--addons > li > a:before {
				content: "";

				position: absolute;
				bottom: -10px;
				left: calc(50% - 5px);

				width: 0;
				height: 0;

				background-color: $color_background-0;

				transform: rotate(45deg);

				transition: transform 0.2s ease 0s,
							width 0.2s ease 0s,
							height 0.2s ease 0s; // animace rotace zobacku just for teh lulz
			}
			.b-014a__list--addons > li > a.expanded:before {
				width: 10px;
				height: 10px;

				bottom: -5px;
			}*/




	// menu
	.b-014a__list {
		order: 3;

		min-width: 0; // W3C: by default, flex items won't shrink below their minimum content size

		height: 100%;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;

		@include breakpoint($breakpoint_desktop) {
			order: 2;
			flex-grow: 1;
			justify-content: space-around;
		}
	}

		// obecna polozka menu
		.b-014a__list > li {

			height: 100%; // Safari Fix: vertikalni zarovnani polozky menu (v Safari nefunguje vyska ditete 100%, pokud rodic nema definovanou vysku)
		}

			// a jeji vnitrni odkaz
			.b-014a__list > li > a {

				display: inline-flex;
				align-items: center;
				height: 100%;

				padding: 0 1em;

				white-space: nowrap;

				@include link-style ($color_text_paragraph, none, $color_text_accent, none);

				text-transform: uppercase;
				font-size: $fontsize_menu_top;

				@include breakpoint($breakpoint_desktop) {
					padding: 0 1em;

					font-size: $fontsize_menu_top_desktop;
					text-transform: initial;
				}

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014a--inverted & {
					@include link-style ($color_text_paragraph_inverted, none, $color_text_accent, none);
				}
			}
			.b-014a__list > li > a.expanded,
			.b-014a__list > li > a:hover {
				background-color: $color_background-2;
				color: $color_text_accent;

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				// musi se zde jeste dopretizit, protoze predchazejici & selektory maji prilis velkou vahu
				.b-014a--inverted & {
					color: $color_text_accent;
				}
			}
			/* Touch screen devices */
			@media (hover:on-demand) {
				.b-014a__list > li > a:hover { background-color: transparent; color: $color_text_paragraph; }
				.b-014a__list > li > a.expanded { background-color: $color_background-2; color: $color_text_accent; }
			}
			/* No hover support */
			@media (hover:none) {
				.b-014a__list > li > a:hover { background-color: transparent; color: $color_text_paragraph; }
				.b-014a__list > li > a.expanded { background-color: $color_background-2; color: $color_text_accent; }
			}


		// specificke vlastnosti polozky menu "vice"
		.b-014a__list-item--more {

			// HACK prebijime JS menu, az se upravi, bude treba zrusit
			// v primarnim menu je totiz "vice" videt vzdy
			//display: block !important;
		}

			.b-014a__list > li.b-014a__list-item--more > a {
				//font-size: $b-014a_fontsize_menu_top_more_mobile;

				@include breakpoint($breakpoint_desktop) {
					//font-size: $fontsize_menu_top_desktop;
				}
			}

			// ico Sipka (desktop)
			.b-014a__list-item--more > a::after {
				display: none;

				content: "";

				height: 100%;
				//margin-left: 1em;
				margin-left: 0.5em;
				width: 0.6em;

				background-repeat: no-repeat;
				background-position: center center;
				background-image: $file_icon_triangle_south;

				transition: transform 0.2s ease 0s; // animace rotace sipky just for teh lulz

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014a--inverted & {
					background-image: $file_icon_triangle_south_colored-white;
				}
			}
			.b-014a__list-item--more > a.expanded::after,
			.b-014a__list-item--more > a:hover::after {
				background-image: $file_icon_triangle_south_colored-black;
			}
			.b-014a__list-item--more > a.expanded::after {
				transform: rotate(180deg);
			}
			/* Touch screen devices */
			@media (hover:on-demand) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_triangle_south; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_triangle_south_colored-black; }
			}
			/* No hover support */
			@media (hover:none) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_triangle_south; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_triangle_south_colored-black; }
			}

			// ico Hamburger (mobile)
			.b-014a__list-item--more > a::before {
				display: block;

				content: "";

				height: 100%;
				margin-right: 0.5em;
				width: 0.75em;

				background-repeat: no-repeat;
				background-position: center center;
				background-image: $file_icon_hamburger_colored-73737f;

				transition: transform 0.2s ease 0s; // animace rotace hamburgeru just for teh lulz

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014a--inverted & {
					background-image: $file_icon_hamburger_colored-white;
				}
			}
			.b-014a__list-item--more > a.expanded::before,
			.b-014a__list-item--more > a:hover::before {
				background-image: $file_icon_hamburger_colored-black;
			}
			.b-014a__list-item--more > a.expanded::before {
				transform: rotate(180deg);
			}
			/* Touch screen devices */
			@media (hover:on-demand) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_hamburger_colored-73737f; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_hamburger_colored-black; }
			}
			/* No hover support */
			@media (hover:none) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_hamburger_colored-73737f; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_hamburger_colored-black; }
			}

				// puvodni drupal submenu - list vygenerovany vevnitr v "vice"
				// neni, nemelo by se do markupu vubec dostat, presto preventivne skryte
				.b-014a__list-item--more .b-014__list-more {
					display: none;
				}




	// searchbar
	.b-014a__searchbar {
		order: 4;

		display: none;
		flex-shrink: 0;
		position: relative;
		width: 3em;

		@include breakpoint($breakpoint_desktop) {
			display: block;
			width: 3.5em;
		}

		.form#search-form { display: none; } // skryti default Drupal search formu, proc je vubec v markupu?
	}

		.b-014a__searchbar-submit {
			display: inline-block;

			position: absolute;
			right: 0;

			padding: 0;
			height: 100%;
		}

			.b-014a__searchbar-submit-image {

				display: inline-block;

				//width: 1.5em;
				width: 1.35em;
				height: 100%;

				margin: 0 1em;

				background-image: $file_icon_search;
				background-repeat: no-repeat;
				background-position: right center;

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014a--inverted & {
					filter: brightness(200);
				}
			}
