//
// b-portal-udrzto-tabs Styles
//



.b-portal-udrzto-tabs {
	background-image: url('../images/extras/udrzto/bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;

	padding: 1em;

	@include breakpoint($breakpoint_desktop) {
		padding: 2em 3em;
	}
}

	// list
	.b-portal-udrzto-tabs__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	// item
	.b-portal-udrzto-tabs__list-item {
		position: relative;

		flex-basis: 50%;
		flex-grow: 1;
		max-width: 50%;

		padding: 0.5em;

		@include breakpoint($breakpoint_desktop) {
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 25%;
				flex-grow: 1;
				max-width: 25%;
			}
		}
	}

	// item: last
	.b-portal-udrzto-tabs__list-item:last-child {
		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}

		.b-portal-udrzto-tabs__list-item .b-portal-udrzto-tabs__image {
			@include breakpoint($breakpoint_desktop) {
				overflow: inherit;
			}
		}
		
			// hover effect
			.b-portal-udrzto-tabs__list-item .b-portal-udrzto-tabs__image img {
				@include breakpoint($breakpoint_desktop) {
					transition: transform 0.5s;
				}
			}
			.b-portal-udrzto-tabs__list-item:not(:first-child):hover .b-portal-udrzto-tabs__image img {
				@include breakpoint($breakpoint_desktop) {
					transform: scale(1.1);
				}
			}


		// overlay
		.b-portal-udrzto-tabs__overlay {
			display: flex;
			align-items: flex-end;
			justify-content: center;

			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}





// vizualni debug - plab only
.patternlab .b-portal-udrzto-tabs {

	@include breakpoint($breakpoint_desktop) {
		max-width: 755px;
	}
}
