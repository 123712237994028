//
// Radio Jazz - interaktivni fotografie jazzmanu
// upravuje default styly pro dany node, aby odpovidalo grafickemu navrhu
//

$background-jazz: #e5e4ea;


.jazz.page-node-8044337 {

	// pozadavek na fotku do plne sirky stranky
	.page {
		max-width: 100%;
		padding: 0;
	}

	// omezeni sirky + centrovani
	.page header,
	.page footer {
		max-width: 1250px;
		margin-left: auto;
		margin-right: auto;
	}

	// pozadavek na fotku do plne sirky stranky
	.main {
		padding: 0;
	}

	.view-content {
		padding: 0 10px;
	}

	// upravy nadpisu a zahlavi
	.item-first h1 {
		padding: 0 10px;
		position: relative;
		font-size: 2.4rem;
		@include breakpoint($breakpoint_desktop) {
			font-size: 2.8rem;
			padding-left: 0;
			padding-right: 0;
			margin-left: 25%;
			margin-right: 25%;
		}
	}
	h1 + h2.pane-title {
		margin: 0;
		padding: 0;
	}
	h2 {
		text-transform: inherit;
		color: $color_background-9;
		@include breakpoint($breakpoint_desktop) {
			font-size: 1.8rem;
		}
	}
	h2 a {
		color: $color_background-9 !important;
	}
	h1 + h2.pane-title + p {
		padding: 0 10px;
		margin-bottom: 3em;
		@include breakpoint($breakpoint_desktop) {
			padding-left: 0;
			padding-right: 0;
			margin-left: 25%;
			margin-right: 25%;
			margin-bottom: 5em;
	    display: inline-flex;
		}
	}

	// zuzeni kontejneru dle graf. navrhu
	section#b047,
	section#b0471,
	section#b004f,
	section#b022a,
	section#b007b {
		max-width: 780px; // podle graf. navrhu
		margin-left: auto;
		margin-right: auto;
	}

	section#b004f,
	section#b0471 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 5em;
		}
	}

	section#b022a {
		@include breakpoint($breakpoint_desktop) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	section#b0471 .field.field-perex p {
		@include typography_font(regular);
		font-size: 1em;
		@include breakpoint($breakpoint_desktop) {
			font-size: 1em;
		}
	}

	// podbarveni sekce
	section#b047 {
		position: relative;
		z-index: 1;
		margin-top: 0;
		padding-top: 4em; // prostor na button loadmore
		padding-bottom: 2em;
		@include breakpoint($breakpoint_desktop) {
			padding-top: 5em;
			padding-bottom: 3em;
		}
	}
	section#b047::before {
		content: '';
		position: absolute;
		background-color: $background-jazz;
		position: absolute;
		left: -200%;
		right: -200%;
		height: 100%;
		z-index: -1;
		top: 0;
	}

	// styly pro obsah nacitny loadmore buttonem
	// ul (seznam)
	.l-grid--12-1 .b-004__list.b-004f__list:not(:first-child) {
		margin-top: 2em;
	}
		// li (polozka seznamu)
		.l-grid--12-1 .b-004__list.b-004f__list:not(:first-child) .b-004__list-item.b-004f__list-item {
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 50%;
	    	max-width: 50%;
			}
		}

		// pozice loadmore buttonu
		#b004f .button.button-box-flat.button-box-flat--negative {
			position: relative;
			top: 30px;
			z-index: 2;
		}
}
