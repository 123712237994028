//
// Button Share Bubble
//



// wrapper action buttonu share s bublinou
.button-social--share-bubble__wrapper,
.button-listaction--share-bubble__wrapper {
	display: inline-block;

	position: relative;
}
	
	// napozicovani bubliny, neni vhodne resit v ramci atomu v plabu
	.button-social--share-bubble__wrapper .bubble-share,
	.button-listaction--share-bubble__wrapper .bubble-share {
		display: none;

		position: absolute;

		outline: none;
	}
	
	// napozicovani bubliny, neni vhodne resit v ramci atomu v plabu
	.button-social--share-bubble__wrapper .bubble-share {
		top: 45px;
		left: -9px;
	}
	
	// napozicovani bubliny, neni vhodne resit v ramci atomu v plabu
	.button-listaction--share-bubble__wrapper .bubble-share {
		top: 38px;
		left: -13px;

		@include breakpoint($breakpoint_tablet) { 
			top: 30px;
			left: -17px;
		}
	}



// vizualni debug - plab only
.patternlab .button-social--share-bubble__wrapper .bubble-share,
.patternlab .button-listaction--share-bubble__wrapper .bubble-share {
	display: inline-block;
}