//
// B-041g Styles
//



.b-041g {
	
	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
	
	.b-041g__list {
	}
			
		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-041g__list-item {
			
			margin-bottom: 1em;
		}
		
			.b-041g__list-item .image {
				
				position: relative;
			
			}
				
				//TODO: chybi ikona
				.b-041g__list-item .image .b-041g__ico--play-video {
					
					width: 4em;
					height: 3em;
					
					background-color: $color_background-4;
					
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					
					margin: auto;
				}
			
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-041g__list-item h3 {
				
				margin: 0;
				padding-top: 0.5em;
			}
			
			.b-041g__list-item .caption--public-from {
				
				padding-top: 0.5em;
			}
		
		
		.b-041g__list-item:last-child {
			
			margin-bottom: 0;
		}

