//
// Styles Junior - Pseudo Exposed filters
//



// vars
$junior-pseudo-filters_tab_border: 4px solid $color_decoration_main;
$junior-pseudo-filters_tab_border_mobile: 2px solid $color_decoration_main;



#{$junior_selector_body} {

	.radiobutton-filter {
	}

		// item
		.radiobutton-filter__item {
		}

			// input
			.radiobutton-filter__item input {}

			// label: defaultni stav - nezaskrtnuty checkbox
			.radiobutton-filter__item label {
				border: $junior-pseudo-filters_tab_border_mobile;
				border-bottom: none;

				padding: 10px;

				font-size: 15px;
				line-height: 24px;
				text-align: center;

				@include breakpoint($breakpoint_desktop) {
					border: $junior-pseudo-filters_tab_border;
					border-right: none;

					padding: 0.1em 2.25em;
				}
			}

			// lebel: zaskrtnuty checkbox
			.radiobutton-filter__item input[type=radio]:checked + label {}

						// label: posledni item
			.radiobutton-filter__item:last-of-type label {
				border-bottom: $junior-pseudo-filters_tab_border_mobile;

				@include breakpoint($breakpoint_desktop) {
					border: $junior-pseudo-filters_tab_border;
				}
			}

}
