//
// B-070 Styles
//



.b-070 {
}

	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.b-070 h3 {
		@include typography_font(bold);
		font-size: $fontsize_h2_node;
		line-height: calc(#{$fontsize_h2_node} + 10px);

		margin: 0;

		@include breakpoint($breakpoint_desktop) {
			font-size: $fontsize_h2_node_desktop;
			line-height: calc(#{$fontsize_h2_node_desktop} + 10px);
		}
	}

	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.b-070 p {
		font-size: $fontsize_paragraph_node;
		line-height: 1.6em;

		margin-top: 0.75em;
		margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			font-size: $fontsize_paragraph_node_desktop;
		}
	}



// vizualni debug - plab only
.patternlab {
	.b-070 {
		@include breakpoint($breakpoint_desktop) {
			max-width: 755px;
		}
	}
}
