//
// Radio Prague Int. - obecne vlastnosti vsech stranek
//



$radiopraha_selector_body: 'body.radiopraha';
$radiopraha_selector_body-hp: $radiopraha_selector_body + '.page-radiopraha-hp';


#{$radiopraha_selector_body} {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_radiopraha_main, $color_radiopraha_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_radiopraha_main);
	}

	// box
	.box {

		// titulek
		h2 {
			color: $color_background-6;

			a {
				@include link-style ($color_background-6, none, $color_background-6, underline);
			}
		}
	}

	// stanicni lista
	.b-015 {

		.b-015__list-item {}

		.b-015__list-item--left {}

		.b-015__list-item--right {

			.b-015__launcher-wrapper {

				.b-015__launcher {

					.b-015__launcher-info {

						.b-015__launcher-info-list {

							@include breakpoint($breakpoint_desktop) {
								align-items: flex-end;
							}

							.b-015__launcher-info-title {}

							.b-015__launcher-info-timing {

								.b-015__launcher-info-timing-timespan {
									margin-right: 0;
								}
							}
						}
					}

					.b-015__launcher-button {
						padding-top: 17.5px;
					}
				}
			}
		}
	}
}
