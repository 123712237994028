//
// B-008l Styles
//

.b-008l {
}

	.b-008l__tabs-wrapper {
		position: relative;
		min-height: 30px;
	}

		.b-008l__tabs {
			display: flex;
			flex-direction: column;

			position: absolute;
			top: 0;
			left: 0;

			z-index: 1;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;

				position: relative;

				z-index: auto;
			}
		}

			// zalozky
			.b-008l__tab-item {
				color: $color_background-6;
				background-color: $color_background-4;

				font-size: .85rem;
				padding: 4px 10px;

				@include no-select;
				cursor: pointer;

				order: 2;

				@include breakpoint($breakpoint_desktop) {
					border-right: 1px solid $color_background-0;
					order: 1;
				}

				.b-008l--inverted & {
					color: $color_text_accent_inverted;
					background-color: $color_background-5;
				}
			}

			.b-008l__tab-item:hover {
				color: $color_background-0;
				background-color: $color_background-6;

				.b-008l--inverted & {
					color: $color_background-6;
					background-color: $color_background-4;
				}
			}

			.b-008l__tab-item--selected {
				display: block;

				@include typography_font(medium);

				color: $color_text_paragraph_node;
				background-color: $color_background-2;

				order: 1;

				@include breakpoint($breakpoint_desktop) {
					cursor: default;
					pointer-events: none;
				}

				.b-008l--inverted & {
					color: $color_text_accent_inverted;
					background-color: $color_background-7;
				}
			}

			.b-008l__tab-item--selected:hover {
				color: $color_text_paragraph_node;
				background-color: $color_background-2;
			}

			.b-008l__tab-item--selected:after {
				display: block;
				content: '';

				background-image: $file_icon_arrow_single_south_colored-decoration-main;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 10px;

				width: 10px;
				height: 10px;

				margin-top: 7px;
				margin-left: 8px;

				float: right;

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}

			.b-008l__tab-item--opened:after {
				transform: rotate(180deg);
			}


	.b-008l__block {
		position: relative; // kvuli absolutnimu pozicovani checkboxu "jen vicedilne"
		padding: 1em;
		background-color: $color_background-2;

		.b-008l--inverted & {
			background-color: $color_background-7;
		}
	}

		// list
		.b-008l__list {
			display: flex;
			flex-direction: column;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				flex-wrap: wrap;
			}
		}

			// item
			.b-008l__list-item {
				margin-bottom: 1em;
				min-height: 1px; // IE
				//transition: margin-right .45s ease-out;

				@include breakpoint($breakpoint_desktop) {
					// trik jak 3 itemy radku + jejich margin roztahnout na 100%
					// ale ne pokud jich neni na radek plny pocet
					flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
					flex-grow: 1; // roztahne se pomoci grow do tretin
					max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
					max-width: calc(33.33% - 0.67em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
					// proc - 0.67em? protoze je to 1 * 2 / 3 viz (*)

					margin-right: 1em; // (*)
				}
			}

			.b-008l__list-item:nth-child(3n) {
				@include breakpoint($breakpoint_desktop) {
					margin-right: 0;
				}
			}

			.b-008l__list-item:nth-child(n+4) {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}

				// img
				.b-008l__image {
					position: relative;

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.badge {
						position: absolute;
						top: 0;
						right: 0;
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.tag:not(.tag-rounded) {
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.tag-rounded {
						position: absolute;
						max-inline-size: calc(100% - 20px);
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.tag-rounded.tag-rounded--top-left {
						bottom: 0;
						right: 0;
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.tag-rounded.tag-rounded--top-right {
						bottom: 0;
						left: 0;
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.tag-rounded.tag-rounded--bottom-left {
						top: 0;
						right: 0;
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.tag-rounded.tag-rounded--bottom-right {
						top: 0;
						left: 0;
					}

					.button.button-play {
						width: 40px;
						height: 40px;

						position: absolute;
						bottom: 15px;
						left: 15px;

						@include breakpoint($breakpoint_desktop) {
							width: 32px;
							height: 32px;

							bottom: 10px;
							left: 10px;
						}
					}
				}


			// title
			.b-008l__list-item h3 {
				margin-top: 0.5em;
				margin-bottom: 0;

				color: $color_text_paragraph_node;

				@include breakpoint($breakpoint_desktop) {
					font-size: 16px; // default je 18px;
				}

				.b-008l--inverted & {
					color: $color_text_link_inverted;
				}
			}

				.b-008l__list-item h3 a {
					@include link-style($color_text_paragraph_node, none, $color_text_paragraph_node, underline);

					.b-008l--inverted & {
						@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
					}
				}


		// readmore
		.b-008l .button-box-flat--readmore {
			@include breakpoint($breakpoint_desktop) {
				display: inline-flex;
			}
		}

		// pro sychr kdyby byl vlozen button s neinverzni tridou
		.b-008l--inverted .button-box-flat--readmore {
			@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
		}

		// pro sychr kdyby byl vlozen button s neinverzni tridou
		.b-008l--inverted .button-box-flat--readmore {
			background-color: transparent;
			border: 1px solid $color_text_link_inverted;
		}

			// pro sychr kdyby byl vlozen button s neinverzni tridou
			.b-008l--inverted .button-box-flat--readmore .button-box-flat__label {
				border-right-color: $color_text_link_inverted;
			}


		.b-008l--inverted .button-box-flat--readmore .button-box-flat__icon--arrow-double-east {
			background-image: $file_icon_arrow_double_east_colored-white;
		}


	// checkbox vicedilne
	.b-008l__checkbox {
		display: flex;
		align-items: center;

		position: absolute;
		right: 10px;
		top: 2px;
	}

	.b-008l__checkbox .label.label--checkbox-text {
		color: $color_background-6;
		@include no-select;
	}

	.b-008l__checkbox input[type="checkbox"] {
		top: 0;
	}

	// pozicovani checkboxu vicedilne / drupal markup
	.b-008l .view-filters {
		position: absolute;
		top: -32px;
		right: 0;

		@include breakpoint($breakpoint_desktop) {
			top: -32px;
		}
	}





// vizualni debug - plab only
.patternlab .b-008l {

	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		max-width: 750px;
	}
}
