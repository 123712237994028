//
// CRo Background Gradients
//


.bg-gradient-transparent-to-color-background-0 {
	background-image: linear-gradient(90deg, rgba($color_background-0, 0), $color_background-0);
}
.bg-gradient-transparent-to-color-background-1 {
	background-image: linear-gradient(90deg, rgba($color_background-1, 0), $color_background-1);
}
.bg-gradient-transparent-to-color-background-2 {
	background-image: linear-gradient(90deg, rgba($color_background-2, 0), $color_background-2);
}
.bg-gradient-transparent-to-color-background-3 {
	background-image: linear-gradient(90deg, rgba($color_background-3, 0), $color_background-3);
}
.bg-gradient-transparent-to-color-background-4 {
	background-image: linear-gradient(90deg, rgba($color_background-4, 0), $color_background-4);
}
.bg-gradient-transparent-to-color-background-5 {
	background-image: linear-gradient(90deg, rgba($color_background-5, 0), $color_background-5);
}
.bg-gradient-transparent-to-color-background-6 {
	background-image: linear-gradient(90deg, rgba($color_background-6, 0), $color_background-6);
}
.bg-gradient-transparent-to-color-background-7 {
	background-image: linear-gradient(90deg, rgba($color_background-7, 0), $color_background-7);
}
.bg-gradient-transparent-to-color-background-8 {
	background-image: linear-gradient(90deg, rgba($color_background-8, 0), $color_background-8);
}
.bg-gradient-transparent-to-color-background-9 {
	background-image: linear-gradient(90deg, rgba($color_background-9, 0), $color_background-9);
}



.bg-gradient-horizontal-color-main-to-color-add-1 {
	.corporate & { background-image: linear-gradient(90deg, $color_corporate_main, $color_corporate_add-1); }

	.radiozurnal & { background-image: linear-gradient(90deg, $color_radiozurnal_main, $color_radiozurnal_add-1); }
	.dvojka & { background-image: linear-gradient(90deg, $color_dvojka_main, $color_dvojka_add-1); }
	.vltava & { background-image: linear-gradient(90deg, $color_vltava_main, $color_vltava_add-1); }
	.plus & { background-image: linear-gradient(90deg, $color_plus_main, $color_plus_add-1); }
	.wave & { background-image: linear-gradient(90deg, $color_wave_main, $color_wave_add-1); }
	.d-dur & { background-image: linear-gradient(90deg, $color_d-dur_main, $color_d-dur_add-1); }
	.jazz & { background-image: linear-gradient(90deg, $color_jazz_main, $color_jazz_add-1); }
	.region & { background-image: linear-gradient(90deg, $color_region_main, $color_region_add-1); }
}



.bg-gradient-horizontal-transparent-to-color-main {
	.corporate & { background-image: linear-gradient(90deg, rgba($color_corporate_main, 0), $color_corporate_main); }

	.radiozurnal & { background-image: linear-gradient(90deg, rgba($color_radiozurnal_main, 0), $color_radiozurnal_main); }
	.dvojka & { background-image: linear-gradient(90deg, rgba($color_dvojka_main, 0), $color_dvojka_main); }
	.vltava & { background-image: linear-gradient(90deg, rgba($color_vltava_main, 0), $color_vltava_main); }
	.plus & { background-image: linear-gradient(90deg, rgba($color_plus_main, 0), $color_plus_main); }
	.wave & { background-image: linear-gradient(90deg, rgba($color_wave_main, 0), $color_wave_main); }
	.d-dur & { background-image: linear-gradient(90deg, rgba($color_d-dur_main, 0), $color_d-dur_main); }
	.jazz & { background-image: linear-gradient(90deg, rgba($color_jazz_main, 0), $color_jazz_main); }
	.region & { background-image: linear-gradient(90deg, rgba($color_region_main, 0), $color_region_main); }
	//body.regina & { background-image: linear-gradient(90deg, rgba($color_regina_main, 0), $color_regina_main); } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni
}



.bg-gradient-horizontal-transparent-to-color-main-darker {
	/*.corporate & { background-color: $color_corporate_main;}

	.radiozurnal & { background-color: $color_radiozurnal_main;}
	.dvojka & { background-color: $color_dvojka_main; }*/
	.vltava & { background-image: linear-gradient(90deg, rgba($color_vltava_main_darker, 0), $color_vltava_main_darker); }
	/*.plus & { background-color: $color_plus_main; }
	.wave & { background-color: $color_wave_main; }
	.d-dur & { background-color: $color_d-dur_main; }
	.jazz & { background-color: $color_jazz_main; }
	.region & { background-color: $color_region_main; }*/
}



.bg-gradient-vertical-60-color-add-3-to-color-main {
	.corporate & { background-image: linear-gradient(to bottom, $color_corporate_add-3 0%, $color_corporate_main 60%); }

	.radiozurnal & { background-image: linear-gradient(to bottom, $color_radiozurnal_add-3  0%, $color_radiozurnal_main 60%); }
	.dvojka & { background-image: linear-gradient(to bottom, $color_dvojka_add-3  0%, $color_dvojka_main 60%); }
	.vltava & { background-image: linear-gradient(to bottom, $color_vltava_add-3  0%, $color_vltava_main 60%); }
	.plus & { background-image: linear-gradient(to bottom, $color_plus_add-3  0%, $color_plus_main 60%); }
	.wave & { background-image: linear-gradient(to bottom, $color_wave_add-3  0%, $color_wave_main 60%); }
	.d-dur & { background-image: linear-gradient(to bottom, $color_d-dur_add-3  0%, $color_d-dur_main 60%); }
	.jazz & { background-image: linear-gradient(to bottom, $color_jazz_add-3  0%, $color_jazz_main 60%); }
	.junior & { background-image: linear-gradient(to bottom, $color_junior_add-3-medium  0%, $color_junior_main 60%); }
	.region & { background-image: linear-gradient(to bottom, $color_region_add-3  0%, $color_region_main 60%); }
	//body.regina & { background-image: linear-gradient(to bottom, $color_regina_add-3 0%, $color_regina_main 60%); } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni
	body.radiopraha & { background-image: linear-gradient(to bottom, $color_radiopraha_add-3 0%, $color_radiopraha_main 60%); } // nove Radio DAB, na vlastnim webu jina barevnost nez defaulni regionalni
}
