//
// D-dur - Skladatele
//



.d-dur.page-skladatele {

	// u samotne abecedy (bez filtru nad ni) nepotrebujeme margin-top
	.b-053a .b-053a__alphabet {
		margin-top: 0;
	}

	// samotny fulltext (bez filtru nad nim) nepotrebuje margin-top
	.view-filters form .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper {
		//margin-top: 0;
	}
}
