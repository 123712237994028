//
// D-dur - Aktuality
//



.d-dur.page-aktuality {
	
	// samotny fulltext (bez filtru nad nim) nepotrebuje margin-top
	.view-filters form .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper {
		margin-top: 0;
	}
	
	// docasne skryti sort-by u filtru
	.b-008d .views-widget-sort-by {
		display: none;
	}
}