//
// Radiozurnal Sport - HP
//



#{$sport_selector_body-hp} {

	/* prvni region .l-grid--12-1 */
	.l-grid--12-1.nth-of-type--1 {

		overflow: visible; // kvuli stinu .b-002j__list-item

		// prvni box
		.box:first-of-type {
		}

		// posledni box
		.box:last-of-type {
		}

		// b-002j - rozhovory
		.b-002j {
			h2 {}

			&__list {
				background-color: $color_sport_main;

				@include breakpoint($breakpoint_desktop) {
					background-color: transparent;
				}

				&-item {
					@include breakpoint($breakpoint_desktop) {
						display: flex;
						flex-direction: column;

						border-radius: 6px;
						overflow: hidden;

						position: relative;
						bottom: 0;

						box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

						transition: all 0.3s ease;
					}

					> h3 {
						color: $color_text_accent_inverted;

						@include breakpoint($breakpoint_desktop) {
							flex-grow: 1;
							padding: 0.75em 1em;
							height: 100%;
							background-color: $color_sport_main;
						}

						> a {
							@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);

							@include breakpoint($breakpoint_desktop) {
							}
						}
					}

					&:first-child {
						> h3 {
							@include breakpoint($breakpoint_desktop) {
								padding: 0.75em 1em;
							}
						}
					}

					&:hover {
						@include breakpoint($breakpoint_desktop) {
							bottom: 6px;
							opacity: 0.9;
						}
					}
				}
			}
		}
	}

	/* prvni region .l-grid--8-1 */
	.l-grid--8-1.nth-of-type--1 {

	}

	/* prvni region .l-grid--4-9 */
	.l-grid--4-9.nth-of-type--1 {

	}

	/* main */
	.main {
		padding-top: 2em;

		@include breakpoint($breakpoint_desktop) {
			padding-top: 2em;
		}
	}

}
