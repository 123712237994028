//
// B-033a Styles
//
// box pro stranku s kamerami
//



$b-033a_thumbnails_caption_font-size_mobile: 17px;
$b-033a_thumbnails_caption_font-size_desktop: $fontsize_h3_desktop;


$b-033a_listing_block_padding-horizontal_mobile: $layout_main_horizontal-padding_mobile;
$b-033a_listing_block_padding-horizontal_desktop: 2em;

$b-033a_subblock-heading_font-size_mobile: 14px;
$b-033a_subblock-heading_font-size_desktop: 16px;




@mixin b-033a_subblock-heading() {

	margin-top: 1.75em;
	margin-bottom: 0.75em;

	color: $color_decoration_main; // nasledne pretizeno specifickou barvou webu

	@include typography_font(medium);

	@include adjust-font-size-to($b-033a_subblock-heading_font-size_mobile);


	@include breakpoint($breakpoint_desktop) {

		margin-bottom: 1em;

		@include adjust-font-size-to($b-033a_subblock-heading_font-size_desktop);
	}
}




.b-033a {
}

	.b-033a__subsection--webcam {

		background-color: $color_background-2;
	}

		.b-033a__block--webcam {

			border-bottom: 1px solid $color_divider;
		}

			// responzivni iframe video wrapper
			.b-033a__webcam-player {

				position: relative;
				padding-bottom: 56.25%;
				height: 0;
				overflow: hidden;
			}

				// neBEMove zanoreni
				.b-033a__webcam-player iframe {

					position: absolute;
					top: 0;
					left: 0;

					width: 100%;
					height: 100%;
				}


			// nahledy dalsich kamer
			// * tento blok v pripade jedne kamery neni pritomen, resp. ma inline display: none
			.b-033a__webcam-thumbnail-list-wrapper {

				display: block; // *

				padding-bottom: 0.5em;
			}

				.b-033a__webcam-thumbnail-list {

					margin-top: 0.66667em;

					display: flex;
					// nahledy vzdy v radku jak v mobile tak v desktop
					// alespon zatim nez pribude nejaky touch slider system ala alda xmac
					flex-direction: row;

					@include breakpoint($breakpoint_desktop) {

						margin-top: 1.5em;
					}
				}

					// jeden nahledovy blok
					.b-033a__webcam-thumbnail-list-item {

						flex-basis: 33.3334%;
						overflow: hidden;

						margin-right: 1px;

						@include breakpoint($breakpoint_desktop) {
							margin-right: 2em;
						}
					}
					.b-033a__webcam-thumbnail-list-item:last-child {

						margin-right: 0;

						@include breakpoint($breakpoint_desktop) {
							margin-right: 0;
						}
					}

						// href obal
						.b-033a__webcam-thumbnail {

							@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
						}
						.b-033a__webcam-thumbnail.selected {

							// vlastnosti 'selected' definovany nize na detech
						}

							// z duvodu atomu chybejici BEM selektor
							.b-033a__webcam-thumbnail > h3 {

								margin: 0;
								margin-bottom: 5px;
								padding-left: 0.5em;
								padding-right: 0.5em;

								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;

								color: $color_text_paragraph;

								@include adjust-font-size-to($b-033a_thumbnails_caption_font-size_mobile);

								@include breakpoint($breakpoint_desktop) {
									@include adjust-font-size-to($b-033a_thumbnails_caption_font-size_desktop);
								}
							}
							// specifikace pokud je nadrazeny thumb 'selected'
							.b-033a__webcam-thumbnail.selected > h3 {

								// k pretizeni specifickou stanicni barvou
								color: $color_decoration_main;
							}

							.b-033a__webcam-thumbnail-image {

								position: relative; // priprava pro pseudotridni efekt selected nize
							}
							// specifikace pokud je nadrazeny thumb 'selected'
							.b-033a__webcam-thumbnail.selected > .b-033a__webcam-thumbnail-image > img {

								opacity: 0.3334;
							}
							.b-033a__webcam-thumbnail.selected > .b-033a__webcam-thumbnail-image::after {

								content: "";
								position: absolute;
								left: 0;
								top:0;

								width: 100%;
								height: 10px;

								// k pretizeni specifickou stanicni barvou
								background-color: $color_decoration_main;
							}


				// info nad carou
				// z duvodu atomu chybejici BEM selektor
				.b-033a__webcam-thumbnail-list-wrapper > .caption--info {

					margin-top: 0.25em;
					margin-left: 10px;
				}




		.b-033a__block--listing {

			display: flex;
			flex-direction: column;

			@include breakpoint($breakpoint_desktop) {

				flex-direction: row;
			}
		}

			// obecny sloupec
			.b-033a__listing-column {

				padding-left: $b-033a_listing_block_padding-horizontal_mobile;
				padding-right: $b-033a_listing_block_padding-horizontal_mobile;

				@include breakpoint($breakpoint_desktop) {

					flex-basis: 50%;

					padding-left: $b-033a_listing_block_padding-horizontal_desktop;
					padding-right: $b-033a_listing_block_padding-horizontal_desktop;
				}
			}

				// obecny subblok sloupce (ty sekce rozdelene nadpisy h5)
				.b-033a__subblock {
				}

					// obecny nadpis subbloku
					// z duvodu atomu chybejici BEM selektor
					.b-033a__subblock > h5 {

						@include b-033a_subblock-heading();
						// + misto k pretizeni specifickou stanicni barvou
					}


			// prvni sloupec - aktualni porad a "prave hraje"
			.b-033a__listing-column--now {
			}

				/*.b-033a__subblock {
				}*/

					// teplomer
					// pozor, jedna se o pretizeni vlozeneho atomu (neBEM)
					// timto zpusobem se proste zkrati na pomernou od oka strelenou velikost viz studie
					.b-033a__listing-column--now .progress-bar__duration {

						width: 66.6667%;

						@include breakpoint($breakpoint_desktop) {

							width: 100%; // atom default
						}
					}

					// prave hrana skladba
					// z duvodu atomu chybejici BEM selektor
					.b-033a__listing-column--now .song-title {

						margin-top: -0.5em; // zanoreni pod margin predchazejici h5 - systemovejsi prebiti zde
						margin-bottom: 0.5em;

						@include breakpoint($breakpoint_desktop) {

							margin-top: 1em;
							margin-bottom: 1.5em;
						}
					}


			// kousek nadchazejiciho programu
			.b-033a__listing-column--following {
			}

				.b-033a__subblock--schedule-readmore {

					margin-top: 1em;
					margin-bottom: 1.5em;

					@include breakpoint($breakpoint_desktop) {

						margin-bottom: 1em; //  k tomuto nedojde, ale neni potreba takovy rozpal jako u mobile
					}
				}

					// z duvodu atomu chybejici BEM selektor
					.b-033a__subblock--schedule-readmore .button-box-flat--readmore {

						display: inline-flex; // nepotrebujeme roztahovani buttonu na 100% width rodice
					}




	.b-033a__subsection--nav-webcam {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {

			padding-top: 1.5em;

			flex-direction: row;
		}
	}

		.b-033a__nav-webcam-item {

			margin-top: 1.5em;

			@include breakpoint($breakpoint_desktop) {

				margin-top: 0;
				margin-right: 0.5em;
			}
		}

			.b-033a__nav-webcam-item .button-box-flat {

				display: inline-flex; // nepotrebujeme roztahovani buttonu na 100% width rodice
			}
