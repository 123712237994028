//
// Button action from article list
//


$fontsize_listactionbuttons: 14px;



.button-listaction {
	
	display: inline-block;
	width: 3em;
	height: 3em;
	
	margin: 0;
	padding: 0;
	
	background-size: 200%;
	background-position: 0;
	
	@include link-style($color_decoration_main, none, $color_decoration_main, underline);
	
	@include breakpoint($breakpoint_tablet) { 
		width: 2rem;
		height: 2rem;
	}
}
// spriteatlas hovering
.button-listaction:hover {
	background-position: 100%;
}
	
	.button-listaction__label {
		
		// cely span popisek je ve vychozim stavu skryt a slouzi jen jako fallback pro obrazek
		display: none;
		
		align-items: center;
	
		height: 100%;
		margin-left: 100%;
		padding-left: 0.5em;
	
		font-size: $fontsize_listactionbuttons;
	}
	
	.button-listaction__label span {
		white-space: nowrap;
	}





//@include link-style($color_decoration_main, none, $color_decoration_main, underline);
// specificke varianty buttonu
//

.button-listaction--play {
	
	background-image: $file_button_action_play;
}


.button-listaction--share {
	
	background-image: $file_button_action_share;
}


.button-listaction--download {
	
	background-image: $file_button_action_download;
	
	border: 1px solid $color_background-4;
	border-radius: 50%;
}


.button-listaction--podcast {
	
	background-image: $file_button_action_podcast;
	
	border: 1px solid $color_background-4;
	border-radius: 50%;
}


.button-listaction--podcast-label .button-listaction__label {
	display: flex;
}
