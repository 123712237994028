//
// Rada - Dozorci komise
//



.rada.page-dozorcikomise {
	.rada-custom p {
		font-size: 19px;
		line-height: 1.6em;
	}
	// fulltext nechceme
	.view-filters form .views-exposed-form .views-exposed-widgets .views-submit-button-wrapper {
		display: none;
	}
}
