//
// Dvojka - Historie zlocinu
//



.dvojka.page-node-6945272 {

	// box Mapa
	.box.historiezlocinu-map {
		margin-bottom: 2em;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 3em;
		}

		iframe {
			max-width: 100%;
		}
	}
}
