//
// B-041m Styles
//



.b-041m {
}
	
	.b-041m__list {
		//margin-top: -0.5em; // kvuli .b-041m__list-item-link
	}
		
		// ovalujici link celeho itemu
		.b-041m__list-item-link {
			display: block;
			
			//padding-top: 0.5em;
			//padding-bottom: 0.5em;
			
			padding-bottom: 1em;
		}
		.b-041m__list-item-link:last-child {
			padding-bottom: 0;
		}
		.b-041m__list-item-link:hover {
			text-decoration: none;
			
			.button {
				text-decoration: none;
				opacity: 0.8;
			}
		}
		
		
		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-041m__list-item {
			display: flex;
			flex-direction: row;
		}
			
			.b-041m__block--image {
				flex-shrink: 0;
				
				max-width: 5em;
				
				@include breakpoint($breakpoint_desktop) {
					max-width: 7em;
				}
					
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.image {
					position: relative;
				}
			}
			
			
			.b-041m__block--description {
				padding-left: 0.75em;
				padding-right: 0.75em;
				
				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041m__block--description h3 {
					margin: 0;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041m__block--description p {
					margin-top: 0.5em;
					margin-bottom: 0.5em;
					
					color: $color_text_paragraph;
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041m__block--description .button {
					display: inline-flex;
					background-color: $color_decoration_main;
					color: $color_text_accent_inverted;
					
					//margin-top: 0.5em;
					
					border: none;
					
					.button-box-flat__icon.button-box-flat__icon--arrow-double-east {
						background-image: $file_icon_arrow_double_east_colored-white;
					}
				}





// vizualni debug - plab only
.patternlab .b-041m {
	max-width: 450px;
}