//
// Portal - Vyber
//



.portal.page-vyber {

	// box
	.box {

		// box Tip pro dnesni Den
		&.b-004f {
			.b-004f__list-item:nth-child(odd):last-child {
				padding-right: 0
			}
		}

		// stanicni tagy
		&.b-002b,
		&.b-023a {
			.tag {
				text-transform: none;
			}
		}

		/* b-002g je specifickja varianta, ktera ma vice radku */
	  /* potrebuje wrapovani a tim padem poresit koretkni velikost itemu a jeho odrazeni */
		&.b-002g {
			.b-002g__list {
				@include breakpoint($breakpoint_desktop) {
					flex-wrap: wrap;
				}
			}
			.b-002g__list-item {
				@include breakpoint($breakpoint_desktop) {
					// trik jak 3 itemy radku + jejich margin roztahnout na 100%
					// ale ne pokud jich neni na radek plny pocet
					flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
					flex-grow: 1; // roztahne se pomoci grow do tretin
					max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
					max-width: calc(33.33% - 1.34em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
					// proc - 1.34em? protoze je to 2 * 2 / 3 viz (*)

					margin-left: 2em; // (*)
					margin-bottom: 2em;
				}
				&:nth-child(3n-2) {
					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}
				&:nth-last-child(-n+3) {
					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0;
					}
				}
			}
		}
	}

}
