//
// Wave News - originální koncept Wave News používaný i na dalších webech
//

// vyhledove zrefaktoruju a prevedu do _b-047.scss


.page-wavenews,
.wave,
.neviditelna,
.poplatek,
.radiopraha {

	.b-047 {

		.b-047__list-item {
			position: relative;
			padding-top: 1em;
			//padding: 2em 0 4em 0;
			//border-top: 1px solid $color_background-4;
		}
		// oddelujici linka
		.b-047__list-item::after {
			content: "";

			display: block;
			position: relative;
			height: 1px;

			margin-top: 3em;
			margin-bottom: 1em;

			border-bottom: 1px solid $color_background-4;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 4em;
				margin-bottom: 1em;
				//margin-right: -55%; // pres celou sirku, ale kolidovalo s aside obsahem
			}
		}
		.b-047__list-item:first-child {
			padding-top: 0;
		}
		.b-047__list-item:last-child::after {
			display: none;
		}

		h2.b-047__title {
			font-size: 1.75rem;
			font-size: $fontsize_h2_node;
			line-height: calc(#{$fontsize_h2_node} + 10px);
			line-height: 1.875rem;
			text-transform: none;
			color: $color_text_accent;
			padding: 0;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h2_node_desktop;
				line-height: calc(#{$fontsize_h2_node_desktop} + 10px);
			}

			a:link,
			a:visited {
				color: $color_text_accent;
			}
		}

		.field-entity-show-wrapper {
			display: inline-block;
		}

		.field-entity-show {
			margin: 0 0 .5em 0;
			display: inline-block;
			//color: $color_decoration_main;
			color: $color_text_paragraph;

			a {
				@include link-style($color_decoration_main, none, $color_decoration_main, underline);
			}

			@include breakpoint($breakpoint_desktop) {
				display: inline-block;
				border-left: 1px solid #aaa;
				padding: 0 0 0 0.625em;
				height: 1em;
				line-height: 1em;
				margin: .5em 0;
			}
		}

		.field-modified {
			@include breakpoint($breakpoint_desktop) {
				margin: .5em 0 1.65em;
			}
		}

		.block-social-buttons {
			float: right;
			margin-bottom: 1em;
			margin-top: -1em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 1em;
				margin-top: 0;
			}
		}

		.content-1-3 {
			position: relative;
		}

		.b-041a {
			position: absolute;
			top: 20em;
			left: 105%;
			width: 50%;
		}

		div.wysiwyg-asset .contextual-links-wrapper {
			display: none;
		}
	}
}

.wave.page-wavenews,
.neviditelna.page-neviditelna-hp {

	.b-047 {
		//margin-top: 0;

		.view-content ul:first-child .b-047__list-item:first-child {
			//border: none;
		}
	}

	/*.b-041a {
		margin-top: 4em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 25em;
		}
	}*/

	// pouze prvni tohoto typu
	//.l-grid--4-9 .box:first-of-type {
	.l-grid--4-9 .box:first-child {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 25em;
		}
	}
}

.wave,
.neviditelna {

	// linka oddeluji node wave news od dalsiho vypisu
	// a linka po nacteni dalsich wavenews infinity scrollerem
	&.node-type-article,
	&.page-wavenews .b-047__list:not(:first-child) {

		// oddelujici linka
		.b-047__list-item:first-child::before {
			content: "";

			display: block;
			position: relative;
			height: 1px;

			margin-top: 3em;
			margin-bottom: 3em;

			border-top: 1px solid $color_background-4;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 3em;
				margin-bottom: 3em;
				//margin-right: -55%; // pres celou sirku, ale kolidovalo s aside obsahem
			}
		}
	}

	&.node-type-article {
		.b-047 {
			margin-top: 0;
		}
	}

	.b-047 {

		.block-social-buttons {
			position: static;
		}
	}
}
