//
// B-002g Styles
//


.b-002g {

}

	.b-002g__list {
		@include breakpoint($breakpoint_desktop) {
			display: flex;
		}
	}

	.b-002g__list-item {
		flex-grow: 1;

		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 0;

			margin-left: 2em;

			&:first-child {
				margin-left: 0;
			}
		}
	}

		.b-002g__block {
			position: relative;
		}

		.b-002g__image {
		}

			// ikonove priznaky
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002g__image .badge {
				position: absolute;
				top: 0;
				right: 0;
			}

			// buttonove priznaky
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002g__image .button-video-play-box {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}


			.b-002g__overlay {
				position: absolute;
				bottom: 0;
				left: 10px;
				right: 10px;
			}

		.b-002g__list-item h3 {
			margin: 0;
			padding: 0.5em 0 0 0;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-002g__list-item p {
			margin-top: 0.75em;
			margin-bottom: 0;

			color: $color_text_paragraph;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-002g__list-item a p {
			@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
		}
