//
// B-008j Styles
//
// krome dlasich variant b008 blizka podobnost s nejakymi variantami b004
// ale primo odvozovano z nich neni
//



.b-008j {
}
	
	.b-008j__list {
		
		display: flex;
		flex-direction: column;
		
		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
		
		.b-008j__list-item {
			
			margin-bottom: 2em;
			
			// IE
			min-height: 1px;
			
			@include breakpoint($breakpoint_desktop) {
				
				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 1em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1em? protoze je to 1.5 * 2 / 3 viz (*)
				
				margin-right: 1.5em; // (*)
				
				// v desktop variante flexuje vlastni obsah tez
				// nutne pro zachovavani vysky polozky vzhledem k sousedni
				display: flex;
				flex-direction: column;
			}
		}
		
		.b-008j__list-item:nth-child(3n) {
			
			@include breakpoint($breakpoint_desktop) {
				
				margin-right: 0;
			}
		}
			
			.b-008j__block--image {
				// IE
				min-height: 1px;
			}
				
				.b-008j__image {
					position: relative;
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008j__image .tag {
						
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
					}
			
			
			.b-008j__block--description {
				
				padding: 0.5em;
				
				background-color: $color_background-2;
				
				
				@include breakpoint($breakpoint_desktop) {
					
					// pro desktop se snazi rust aby dorostl pripadne vetsiho souseda na radku
					flex-grow: 1;
					
					// a flexuje vlastni deti aby bylo uskutecneno odsazeni smerem dolu
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
			}
				
				.b-008j__subblock--content {
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008j__subblock--content h3 {
						
						margin: 0;
					}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008j__subblock--content a p {
						
						margin-top: 0.5em;
						margin-bottom: 0.5em;
						
						color: $color_text_paragraph;
						@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
					}
				
				
				.b-008j__subblock--meta {
					
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					
					padding-top: 0.5em;
					
					border-top: 1px solid $color-background-4;
				}
					
					.b-008j__meta-wrapper {
					}
						
						.b-008j__meta-line {
							@include adjust-font-size-to($fontsize_box_petit_main);
							
							@include breakpoint($breakpoint_desktop) {
								@include adjust-font-size-to($fontsize_box_petit_main_desktop);
							}
						}
							
							.b-008j__meta-label {
								color: $color_text_paragraph;
							}
							
							.b-008j__meta-value {
							}
					
					
					.b-008j__button-play-wrapper {
						margin-left: 2em;
					}
	
	
	
	
	.b-008j__buttons-wrap {
		text-align: center;
	}
		
		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-008j__buttons-wrap .button-box-flat-small {
			
			display: inline-flex;
			
			margin-top: 3em;
		}