//
// B-099 Styles
//



.b-099 {
	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	//max-width: 400px;
}

	.b-099__image {
	}

		.b-099__imagefake {
			background-color: $color_background-4;
		}

		.b-099__imagefake:hover {
			background-color: $color_background-4_alpha-low;
		}

			.b-099__desc-wrap {
				display: flex;
				justify-content: center;
				align-items: center;

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
				.b-099__desc-txt {
					text-transform: uppercase;
					color: $color_background-0;
					font-size: 2em;
				}
				



//
// B-099a Styles
//
.b-099a {
}

	.b-099a__image {
	}

		.b-099a__imagefake {
			@include aspect-ratio(4, 1);
		}



//
// B-099b Styles
//
.b-099b {
}

	.b-099b__image {
	}

		.b-099b__imagefake {
			@include aspect-ratio(16, 9);
		}



//
// B-099c Styles
//
.b-099c {
}

	.b-099c__image {
	}

		.b-099c__backgroundimage {
			background-color: $color_background-3;
			background-repeat: no-repeat;
			background-size: 100%;

			@include aspect-ratio(3.75, 1);
		}

			.b-099c__content {
				display: flex;
				justify-content: center;
				align-items: center;

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
				.b-099c__button {
					display: flex;
					flex-wrap: nowrap;
					align-items: center;

					background-color: rgba(#000f37, 0.8);

					padding: 0.5em 1.5em;
					
					font-size: 16px;

					@include breakpoint($breakpoint_desktop) {
						padding: 0.75em 2em;

						font-size: 24px;
					}
					
					.b-099c__content:hover & {
						background-color: rgba(#000d31, 0.8);
					}
				}

					.b-099c__button__label {
						color: $color_background-0;
					}

					.b-099c__button__icon {
						flex-shrink: 0;

						width: 11px;
						height: 11px;

						margin-left: 6px;
						margin-top: 2px;

						background-image: $file_icon_arrow_double_east_colored-white;
						background-repeat: no-repeat;
						background-position: left center;

						@include breakpoint($breakpoint_desktop) {
							width: 15px;
							height: 15px;

							margin-top: 4px;
						}
					}


// zelena vlna
.b-099c--zelenavlna-mapa {

	.b-099c__backgroundimage {
		background-image: url('../images/bg/zelenavlna/zelenavlna_button-map.png');

		@include aspect-ratio(3.75, 1);
	}
		
		.b-099c__content {
			.b-099c__button {
				background-color: rgba(#009645, 0.8);
			}
		}

		.b-099c__content:hover {
			.b-099c__button {
				background-color: rgba(#00873e, 0.8);
			}
		}
}