//
// Dvojka - HP
//



.dvojka.page-dvojka-hp {

	#b002g7 {
		.b-002g__list {
			@include breakpoint($breakpoint_desktop) {
				flex-wrap: wrap;
			}
		}
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				flex-basis: 45%;
				margin-bottom: 1em;
			}
			&:nth-child(odd) {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}
		}
	}

}
