//
// Custom Selectors
//


// uvodni text formulare
%pre-instructions {
	font-size: .9em;

	color: $color_text_paragraph;

	margin-top: 0;
	padding-bottom: 1em;
	strong {
		@include typography_font(bold);
	}
}

// styly odesilaciho tlacitka tlacitka a hlasky u formu
%form-submit-button-and-disclaimer {
	display: flex;
	flex-wrap: wrap;
	@include breakpoint($breakpoint_tablet) {
		flex-wrap: nowrap;
		margin-top: .2em;
	}
	input[type="submit"] {
		flex-basis: 100%;
		@include breakpoint($breakpoint_tablet) {
			flex-basis: auto;
			margin-right: 1em;
			height: 50px;
			align-self: center;
		}
	}
	p {
		font-size: .9em;
		flex-basis: 100%;
		align-self: center;
		margin-top: .5em;
		margin-bottom: 0;
		color: $color_text_paragraph;
		@include breakpoint($breakpoint_tablet) {
			margin-top: 0;
		}
	}
	a {
		color: $color_decoration_main;
	}
}


// box, o kterem vime, ze bude umisten v side
%form-submit-button-and-disclaimer-side {
	@include breakpoint($breakpoint_desktop) {
		flex-wrap: wrap !important;
		margin-top: 0;
		// submit tlacitko ma byt az na konci formulare
		input[type="submit"] {
			order: 2;
		}
		p {
			margin-top: 0;
			margin-bottom: 1em !important;
		}
	}
}

//
%date-field {
	.date-combo {
		margin: 0;

		label,
		.description {
			display:none;
		}

		label {
			background-image: $file_icon_calendar_colored-white;
			background-color: $color_decoration_main;
			background-size: 55%;
			background-repeat: no-repeat;
			background-position: 50% center;
			margin-right: 10px;
		}
	}
}

// zmenseni poli formu na desktopu na 2 policka
%form-field-size-responsive-half {
	flex-basis: 100%;

	@include breakpoint($breakpoint_desktop) {
		flex-basis: 40%;
		flex-grow: 1;
	}
}
// doplneni marginu u polozek, co jsou na leve strane
%form-mr {
	@include breakpoint($breakpoint_desktop) {
		margin-right: 0.5em;
	}
}

// checkbox - uprava vzhledu
%form-checkbox {
	color: $color_text_paragraph;
	@include no-select;
	display: flex;
	align-items: flex-start;

	// checkbox input
	input[type="checkbox"] {
		margin-right: .25em;
		margin-top: 1px;
	}

	// checkbox label
	label {
		text-transform: inherit;
	}
}

// radiobutton - uprava vzhledu
%form-radio {
	color: $color_text_paragraph;
	@include no-select;
	display: flex;
	align-items: center;

	// radio input
	input[type="radio"] {
		margin-right: .5em;
	}

	// radio label
	label {
		text-transform: inherit;
	}
}
