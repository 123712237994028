//
// Hledani - B-014a Styles
//



$hledani_b-014a_menu-bar-height_mobile: 3em; // bylo 2.5em
$hledani_b-014a_menu-bar-height_desktop: 4.5em; // bylo 4em

#{$hledani_selector_body} {

	.b-014a {
		height: $hledani_b-014a_menu-bar-height_mobile;
		padding-left: $layout_main_horizontal-padding_mobile;
		padding-right: 0;

		background-color: $color_portal_main;

		@include breakpoint($breakpoint_desktop) {
			height: $hledani_b-014a_menu-bar-height_desktop;
			padding-left: $layout_main_horizontal-padding_desktop;
			padding-right: $layout_main_horizontal-padding_desktop;
		}
	}
	// nekonecny pruh
	.b-014a::before {
		background-color: $color_portal_main;
	}



	// logo
	.b-014a__logo {
	}

		.b-014a__logo a {
			background-image: $file_logo_corporate_colored-bg2; // default
			//background-image: $file_logo_corporate-100let_colored-bg2; // 100let
		}





	// addons
	// zatim jen pro Portal s buttonem Zive
	/*.b-014a__list--addons {
		display: flex;

		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}*/





	// menu
	.b-014a__list {
		@include breakpoint($breakpoint_desktop) {
			justify-content: space-between; // kvuli zarovnani po skryti .b-014a__searchbar
		}
	}

		// obecna polozka menu
		.b-014a__list > li {
		}

			// a jeji vnitrni odkaz
			.b-014a__list > li > a {
				@include link-style ($color_background-2, none, $color_text_accent, none);
			}
			.b-014a__list > li > a.expanded,
			.b-014a__list > li > a:hover {
				background-color: $color_background-2;
				color: $color_text_accent;
			}
			/* Touch screen devices */
			@media (hover:on-demand) {
				.b-014a__list > li > a:hover { background-color: transparent; color: $color_background-2; }
				.b-014a__list > li > a.expanded { background-color: $color_background-2; color: $color_text_accent; }
			}
			/* No hover support */
			@media (hover:none) {
				.b-014a__list > li > a:hover { background-color: transparent; color: $color_background-2; }
				.b-014a__list > li > a.expanded { background-color: $color_background-2; color: $color_text_accent; }
			}


		// specificke vlastnosti polozky menu "vice"
		.b-014a__list-item--more {
			// HACK prebijime JS menu, az se upravi, bude treba zrusit
			// v primarnim menu je totiz "vice" videt vzdy
			//display: block !important;
		}

			.b-014a__list-item--more > a {
			}

			// ico Sipka (desktop)
			.b-014a__list-item--more > a::after {
				background-image: $file_icon_triangle_south_colored-bg2;

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014a--inverted & {
					background-image: $file_icon_triangle_south_colored-bg2;
				}
			}
			.b-014a__list-item--more > a.expanded::after,
			.b-014a__list-item--more > a:hover::after {
				background-image: $file_icon_triangle_south_colored-black;
			}
			/* Touch screen devices */
			@media (hover:on-demand) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_triangle_south_colored-bg2; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_triangle_south_colored-black; }
			}
			/* No hover support */
			@media (hover:none) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_triangle_south_colored-bg2; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_triangle_south_colored-black; }
			}

			// ico Hamburger (mobile)
			.b-014a__list-item--more > a::before {
				background-image: $file_icon_hamburger_colored-bg2;

				// varianta pro inverzni hlavicku (modifikator na nejvyssim rodici)
				.b-014a--inverted & {
					background-image: $file_icon_hamburger_colored-bg2;
				}
			}
			.b-014a__list-item--more > a.expanded::before,
			.b-014a__list-item--more > a:hover::before {
				background-image: $file_icon_hamburger_colored-black;
			}
			/* Touch screen devices */
			@media (hover:on-demand) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_hamburger_colored-bg2; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_hamburger_colored-black; }
			}
			/* No hover support */
			@media (hover:none) {
				.b-014a__list > li > a:hover::before { background-image: $file_icon_hamburger_colored-bg2; }
				.b-014a__list > li > a.expanded::before { background-image: $file_icon_hamburger_colored-black; }
			}





	// searchbar
	.b-014a__searchbar {
	}

		.b-014a__searchbar-submit {
		}

			.b-014a__searchbar-submit-image {
				background-image: $file_icon_search_colored-bg2;
			}
}
