//
// B-045b Styles
//



$b-045b-font-size: .85rem;
$b-045b-logo-height-mobile: 24px;
$b-045b-logo-height-desktop: 30px;
$b-045b-logo-magnifier-mobile: 4px;
$b-045b-logo-magnifier-desktop: 4px;


.b-045b {
}

	// list
	.b-045b__ul {
		display: flex;
		flex-wrap: wrap;
	}

	.b-045b__ul[hidden] {
		display: none;
	}

		// item
		.b-045b__list-item {
			display: flex;
			list-style-type: none;
			width: 100%;

			@include breakpoint($breakpoint_tablet) {
				width: 49%;
				margin-right: 1%;
				margin-bottom: 2%;
			}

			@include breakpoint($breakpoint_desktop) {
				width: 32%;
				margin-right: 2%;
			}
		}

		.b-045b__list-item:nth-of-type(2n) {
			@include breakpoint($breakpoint_tablet) {
				margin-right: 0;
				margin-left: 1%;
			}
		}

		.b-045b__list-item:nth-of-type(2n) {
			@include breakpoint($breakpoint_desktop) {
				margin-right: 2%;
				margin-left: 0;
			}
		}

		.b-045b__list-item:nth-of-type(3n) {
			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

		.b-045b__list-item:last-of-type {
			border-bottom: 1px solid $color_divider;
			@include breakpoint($breakpoint_tablet) {
				border-bottom: 0;
			}
		}

			// obal
			.b-045b__wrap {
				width: 100%;
				padding: 20px;
				border: 1px solid $color_divider;
				border-bottom: none;

				@include breakpoint($breakpoint_tablet) {
					padding: 24px;
					border-bottom: 1px solid $color_divider;
				}

				@include breakpoint($breakpoint_desktop) {
					padding: 32px;
				}
			}

				// logo
				.b-045b__logo {
					display: block;
					background-repeat: no-repeat;
					height: $b-045b-logo-height-mobile;

					@include breakpoint($breakpoint_desktop) {
						height: $b-045b-logo-height-desktop;
					}
				}

				// loga celoplosnych stanic
				.b-045b__logo--radiozurnal {
					background-image: $file_logo_radiozurnal_main;
				}

				.b-045b__logo--dvojka {
					background-image: $file_logo_dvojka_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'j'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'j'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				.b-045b__logo--vltava {
					background-image: $file_logo_vltava_main;
				}

				.b-045b__logo--plus {
					background-image: $file_logo_plus_main;
				}

				.b-045b__logo--radiowave {
					background-image: $file_logo_wave_main;
				}

				.b-045b__logo--d-dur {
					background-image: $file_logo_d-dur_main;
				}

				.b-045b__logo--jazz {
					background-image: $file_logo_jazz_main;
				}

				.b-045b__logo--radiojunior {
					background-image: $file_logo_junior_main;
				}

				.b-045b__logo--radiojuniorplus {
					background-image: $file_logo_juniorplus_main;
				}

				.b-045b__logo--radiojunior {
					background-image: $file_logo_junior_main;
				}

				.b-045b__logo--pohoda {
					background-image: $file_logo_pohoda_main;
				}

				.b-045b__logo--archiv {
					background-image: $file_logo_retro_main;
				}

				.b-045b__logo--sport {
					background-image: $file_logo_sport_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'p'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'p'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				.b-045b__logo--radiozurnal-sport {
					background-image: $file_logo_radiozurnalsport_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'p'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'p'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				// loga regionalnich stanic
				.b-045b__logo--brno {
					background-image: $file_logo_brno_main;
				}

				.b-045b__logo--cb {
					background-image: $file_logo_budejovice_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'j'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'j'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				.b-045b__logo--hradec {
					background-image: $file_logo_hradec_main;
				}

				.b-045b__logo--kv {
					background-image: $file_logo_vary_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'y'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'y'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				.b-045b__logo--liberec {
					background-image: $file_logo_liberec_main;
				}

				.b-045b__logo--olomouc {
					background-image: $file_logo_olomouc_main;
				}

				.b-045b__logo--ostrava {
					background-image: $file_logo_ostrava_main;
				}

				.b-045b__logo--pardubice {
					background-image: $file_logo_pardubice_main;
				}

				.b-045b__logo--plzen {
					background-image: $file_logo_plzen_main;
				}

				/*.b-045b__logo--regina {
					background-image: $file_logo_regina_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'g'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'g'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}*/

				.b-045b__logo--regina { // nove Radio DAB
					background-image: $file_logo_dab_main;
				}

				.b-045b__logo--strednicechy {
					background-image: $file_logo_strednicechy_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'y'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'y'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				.b-045b__logo--sever {
					background-image: $file_logo_sever_main;
				}

				.b-045b__logo--vysocina {
					background-image: $file_logo_vysocina_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'y'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'y'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				.b-045b__logo--zlin {
					background-image: $file_logo_zlin_main;
				}

				.b-045b__logo--cro7 {
					background-image: $file_logo_radiopraha_main;
					height: calc(#{$b-045b-logo-height-mobile} + #{$b-045b-logo-magnifier-mobile}); // zvetseni logotypu kvuli 'y'
					margin-bottom: -$b-045b-logo-magnifier-mobile; // dorovnani rozdilu mezery po zvetseni logotypu

					@include breakpoint($breakpoint_desktop) {
						height: calc(#{$b-045b-logo-height-desktop} + #{$b-045b-logo-magnifier-desktop}); // zvetseni logotypu kvuli 'y'
						margin-bottom: -$b-045b-logo-magnifier-desktop; // dorovnani rozdilu mezery po zvetseni logotypu
					}
				}

				// popisek stanice
				.b-045b__description {
					margin-bottom: -5px;
				}

				.b-045b__description:after {
					display: block;
					content: '';
					height: 1px;

					width: 100%;
					margin-bottom: 20px;

					background: $color_divider;
				}

				.b-045b__description p {
						margin-top: 16px;
						margin-bottom: 12px;

						@include typography_font(regular);
						font-size: 1rem;
						line-height: 1.4;

						color: $color_text_paragraph;
				}

				// kamery
				.b-045b__camera-wrap {
					width: 100%;
					float: left;
					margin-bottom: 20px;
				}

					.b-045b__camera-wrap a {
						color: $color_decoration_main;
						font-size: $b-045b-font-size;
						display: block;
						margin-bottom: 5px;
					}

						// list
						.b-045b__camera-wrap ul {}

							// item
							.b-045b__camera {
								width: calc(33% - 1px);
								float: left;
								margin-right: 1px;
								transition: opacity 150ms;
							}

							.b-045b__camera:hover {
								opacity: .8;
							}
							.b-045b__camera:last-child {
								margin-right: 0;
							}

								.b-045b__camera img {
									height: auto;
								}


				// audioarchiv
				.b-045b__archive {}

					.b-045b__archive-title {
						@include typography_font(medium);
						margin-bottom: 5px;
					}

					// list
					.b-045b__archive ul {}

						// item
						.b-045b__archive ul li {}

							.b-045b__archive ul li a {
								color: $color_decoration_main;
								font-size: $b-045b-font-size;
								line-height: 1.9;
							}

					// streamy
					.b-045b__stream {}

						// title
						.b-045b__stream-title {
							@include typography_font(medium);
							margin-top: 24px;
							margin-bottom: 5px;
						}

						// ul
						.b-045b__stream-list {
							display: none;
						}

						// li
						.b-045b__stream-item {
							display: inline-block;
							margin-bottom: 8px;
							margin-right: 4px;
							background-color: $color_decoration_main;
						}

						// link
						.b-045b__stream-item a {
							padding: 2px 4px;
							color: $color_text_link_inverted;
							font-size: 85%;
						}

						// link
						.b-045b__stream ul li:not(.b-045b__stream-item) a {
							color: $color_decoration_main;
							font-size: $b-045b-font-size;
							line-height: 1.9;
						}

						// dropdown button
						.b-045b__stream-toggler {
							margin-top: 8px;
							margin-bottom: 8px;
							display: inline-flex;
							align-items: center;
							color: $color_background-6;
							text-transform: none;
							font-size: .875em;
							cursor: pointer;
							@include no-select;
							&:hover {
								text-decoration: underline;
							}
							&::after {
								content: '';
								margin-left: 8px;
								background-image: $file_icon_arrow_single_south_colored-decoration-main;
								background-repeat: no-repeat;
								display: inline-block;
								width: 12px;
								height: 12px;
							}
							&.dropdown-is-active {
								// rotace ikony
								&::after {
									transform: rotate(180deg);
								}
								// zobrazeni skryte casti obsahu
								& + .b-045b__stream-list {
									display: block;
								}
							}
						}

// specifika
// Radio Junior Pisnicky
.b-045b__list-item.webik .player-b-045b__headline {
	display: inline-block;
	width: calc(100% - 35px);
}
.b-045b__list-item.webik .player-b-045b__link {
	display: flex;
}
.b-045b__list-item.webik .player-b-045b__headline {
	display: flex;
	flex-direction: column;
	justify-content: center;
	white-space: nowrap;
}
.b-045b__list-item.webik .player-b-045b__metadata-and-progressbar {
	display: none;
}
.b-045b__list-item.webik .player-b-045b__play {
	min-width: 32px;
}
