//
// b-114 styles
//



// margins
$b-114_margin-vertical_mobile: 4px;
$b-114_margin-horizontal_mobile: 4px;
$b-114_margin-vertical_desktop: 8px;
$b-114_margin-horizontal_desktop: 8px;

// paddings
$b-114_padding-vertical_mobile: 10px;
$b-114_padding-horizontal_mobile: 10px;
$b-114_padding-vertical_desktop: 15px;
$b-114_padding-horizontal_desktop: 15px;

// column size
$b-114-left-column_size_mobile: 60px;
$b-114-left-column_size_desktop: 70px;

// launcher title
$b-114-launcher-title_font-size_mobile: 18px;
$b-114-launcher-title_font-size_desktop: 24px;

// launcher subtitle
$b-114-launcher-subtitle_font-size_mobile: 12px;
$b-114-launcher-subtitle_font-size_desktop: 13px;

// launcher message
$b-114-launcher-message_font-size_mobile: 14px;
$b-114-launcher-message_font-size_desktop: 18px;

// launcher timing
$b-114-launcher-timing_font-size_mobile: 9px;
$b-114-launcher-timing_font-size_desktop: 11px;

// launcher progressbar
$b-114_launcher_timing-progressbar-height_desktop: 8px;
$b-114_launcher_timing-progressbar-height_mobile: 5px;

// launcher following
$b-114-launcher-following_font-size_mobile: 12px;
$b-114-launcher-following_font-size_desktop: 13px;


.b-114 {
}

	// subsection
	.b-114__subsection {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

		// launcher
		.b-114__launcher {
			flex-shrink: 0;
			flex-grow: 1;
			width: 100%;

			//margin-top: $b-114_margin-vertical_mobile;
			padding: $b-114_padding-vertical_mobile $b-114_padding-horizontal_mobile;

			background-color: $color_background-2;

			transition: all 0.3s ease-out;

			@include breakpoint($breakpoint_desktop) {
				width: 67%;

				//margin-top: $b-114_margin-vertical_desktop;
				padding: $b-114_padding-vertical_desktop $b-114_padding-horizontal_desktop;
			}
		}

		.b-114__launcher:hover { cursor: pointer; } // nove spousteci cela plocha launcheru

			// launcher block
			.b-114__launcher-block {
			}

			// launcher block primary
			.b-114__launcher-block--primary {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
			}

				.b-114__launcher-button-play,
				.b-114__launcher-button-pause {
					flex-shrink: 0;
					width: $b-114-left-column_size_mobile;
					margin-right: $b-114_margin-vertical_mobile * 2;

					@include breakpoint($breakpoint_desktop) {
						width: $b-114-left-column_size_desktop;
						margin-right: $b-114_margin-vertical_desktop * 2;
					}

					.button-play,
					.button-pause {
						width: $b-114-left-column_size_mobile;
						height: $b-114-left-column_size_mobile;

						@include breakpoint($breakpoint_desktop) {
							width: $b-114-left-column_size_desktop;
							height: $b-114-left-column_size_desktop;
						}
					}

					// button pause: effect
					.button-pause {
						border-radius: 50%;
						transform: scale(1);
						box-shadow: 0 0 0 0 rgba($color_decoration_main, 1);
						animation: b-114-pause-pulse 2s infinite;

						// button animation
						@keyframes b-114-pause-pulse {
							0% {
								transform: scale(0.95);
								box-shadow: 0 0 0 0 rgba($color_decoration_main, 0.7);
							}
							70% {
								transform: scale(1);
								box-shadow: 0 0 0 10px rgba($color_decoration_main, 0);
							}
							100% {
								transform: scale(0.95);
								box-shadow: 0 0 0 0 rgba($color_decoration_main, 0);
							}
						}
					}
				}

				.b-114__launcher-content {
					flex-grow: 1;
				}

					.b-114__launcher-title {
						font-size: $b-114-launcher-title_font-size_mobile;
						line-height: 1.2;
						@include typography_font(medium);
						@include lineClamp(2);

						@include breakpoint($breakpoint_desktop) {
							font-size: $b-114-launcher-title_font-size_desktop;
						}
					}

					.b-114__launcher-subtitle {
						margin-top: $b-114_margin-vertical_mobile;

						font-size: $b-114-launcher-subtitle_font-size_mobile;
						line-height: 1.2;
						@include lineClamp(2);

						color: $color_text_decent;

						@include breakpoint($breakpoint_desktop) {
							font-size: $b-114-launcher-subtitle_font-size_desktop;
						}
					}

					.b-114__launcher-message {
						font-size: $b-114-launcher-message_font-size_mobile;
						line-height: 1.2;

						color: $color_text_decent;

						@include breakpoint($breakpoint_desktop) {
							font-size: $b-114-launcher-message_font-size_desktop;
						}

						a {
							@include link-style($color_text_paragraph, underline, $color_text_link, underline);
						}
					}


			// launcher block secondary
			.b-114__launcher-block--secondary {
			}

				.b-015__launcher-timing {
					display: flex;
					flex-direction: row;
					align-items: center;

					margin-top: $b-114_margin-vertical_mobile;

					@include breakpoint($breakpoint_desktop) {
						margin-top: $b-114_margin-vertical_desktop;
					}
				}

					.b-015__launcher-timing-timespan {
						flex-shrink: 0;

						width: $b-114-left-column_size_mobile + $b-114_margin-vertical_mobile;

						margin-right: $b-114_margin-vertical_mobile;

						font-size: $b-114-launcher-timing_font-size_mobile;
						line-height: 1.2;
						@include typography_font(medium);

						color: $color_text_decent;

						@include breakpoint($breakpoint_desktop) {
							width: $b-114-left-column_size_desktop + $b-114_margin-vertical_desktop;
							margin-right: $b-114_margin-vertical_desktop;

							font-size: $b-114-launcher-timing_font-size_desktop;
							@include typography_font(regular);
						}
					}

					.b-114__launcher-timing-progressbar {
						flex-grow: 1;

						display: block;
						height: $b-114_launcher_timing-progressbar-height_mobile;
						margin-top: 0.1em;

						background-color: $color_background-3;

						@include breakpoint($breakpoint_desktop) {
							height: $b-114_launcher_timing-progressbar-height_desktop;
						}
					}

						.b-114__launcher-timing-progressbar-duration {
						}

						.b-114__launcher-timing-progressbar-progress {
							display: block;
							height: $b-114_launcher_timing-progressbar-height_mobile;

							background-color: $color_decoration_main; // default

							// progress
							width: 33%;
							max-width: 100%;

							@include breakpoint($breakpoint_desktop) {
								height: $b-114_launcher_timing-progressbar-height_desktop;
							}
						}


				.b-114__launcher-now-playing {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					align-items: center;

					margin-top: $b-114_margin-vertical_mobile * 2;
					//margin-left: -$b-114_padding-horizontal_mobile * 2;
					padding: 0.5em;


					background-color: $color_background-9; // default
					background-color: $color_corporate_main;
					color: $color_text_accent_inverted;

					box-shadow: $shadow_light;

					@include breakpoint($breakpoint_desktop) {
						margin-top: $b-114_margin-vertical_desktop * 1.5;
						margin-bottom: -$b-114_padding-vertical_desktop + ($b-114_padding-vertical_desktop / 1.5);
						margin-left: -$b-114_padding-horizontal_desktop + (-$b-114_padding-horizontal_desktop / 1.5);
					}

					// default hide
					margin-top: 0;
					padding: 0 0.5em;
					height: 0;
					transform: translateX(25%);
					opacity: 0;

					transition: all 0.3s ease-out;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
						margin-bottom: 0;
					}

					// show
					&.show {
						margin-top: $b-114_margin-vertical_mobile * 2;
						height: 22px;

						padding-left: $b-114_padding-horizontal_desktop;
						transform: translateX(0);
						opacity: 1;

						@include breakpoint($breakpoint_desktop) {
							margin-top: $b-114_margin-vertical_desktop * 1.5;
							//margin-bottom: -$b-114_padding-vertical_desktop + ($b-114_padding-vertical_desktop / 1.5);
							height: 30px;
						}
					}

					a {
						color: $color_text_accent_inverted;
					}
				}

					.b-114__launcher-now-playing-icon {
						flex-shrink: 0;

						display: inline-block;

						width: 14px;
						height: 14px;

						margin-right: 0.5em;

						background-image: $file_icon_note_double_colored-white;
						background-repeat: no-repeat;
						background-position: left center;
					}

					.b-114__launcher-now-playing-text {
						flex-grow: 1;

						font-size: 12px;
						line-height: 1.3;
						@include lineClamp(1);

						@include breakpoint($breakpoint_desktop) {
							font-size: 13px;
						}
					}


				// following
				.b-114__launcher-following {
					margin-top: $b-114_margin-vertical_mobile * 2;

					font-size: $b-114-launcher-following_font-size_mobile;
					line-height: 1.2;
					@include lineClamp(2);

					@include breakpoint($breakpoint_desktop) {
						margin-top: $b-114_margin-vertical_desktop;
						font-size: $b-114-launcher-following_font-size_desktop;
					}
				}

					.b-114__launcher-following-label {
						color: $color_text_decent;
					}

					.b-114__launcher-following-title {
						color: $color_text_accent;
					}


		// visual
		.b-114__visual {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: block;

				flex-shrink: 0;
				flex-grow: 1;

				width: 33%;
			}
		}

			// image
			.b-114__image {
				position: relative;

				width: 100%;
				height: 100%;

				img {
					position: absolute;

					width: 100%;
					height: 100%;

					object-fit: cover;
          			object-position: center;
				}
			}





// vizualni debug - plab only
.patternlab .b-114 {
	max-width: 420px;

	@include breakpoint($breakpoint_desktop) {
		max-width: 780px;
		margin-left: 25px;
	}
}
