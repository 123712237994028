//
// Presspektivy - obecne vlastnosti vsech stranek
//



.presspektivy {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_presspektivy_main, $color_presspektivy_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_presspektivy_main);
	}

	// stanicni nav
	.b-016__wrapper {

		h1,
		h2 {
			background-color: $color_background-0;
			//color: $color_background-9;
		}

		h1:before,
		h2:before {
			background-color: $color_presspektivy_main;
		}

		.b-016 {
			background-color: $color_presspektivy_main;

			/* barevnost listy a odkazu */
			.b-016__list {
				background-color: $color_presspektivy_main;

				.b-016__list-item {

					a {
						//@include link-style(#1a1a1e, none, #1a1a1e, none);
					}

					a.expanded,
					a:hover {
						background-color: $color_presspektivy_main_darker;
					}
				}

				.b-016__list-more {
					background-color: $color_presspektivy_main_darker;

					a:hover {
						background-color: $color_presspektivy_main;
					}
				}
			}

		}
	}

	.b-007a__list-item {

		ul li:not(:first-child) {
			display: none;
		}

		@include breakpoint($breakpoint_desktop) {
			min-width: 80%;
		}
	}

	// box
	.box {

		// titulek
		h2 {
			color: $color_presspektivy_main;
			a {
				@include link-style ($color_presspektivy_main, none, $color_presspektivy_main, underline);
			}
		}
	}

}
