//
// B-021 Styles
//



.b-021 {
	margin-top: 15px;

	@include breakpoint($breakpoint_desktop) {
		margin-top: 20px;
	}
}

	.b-021__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@include breakpoint($breakpoint_desktop) {}
	}

		.b-021__list-item {
			background-image: $file_icon_arrow_single_east;
			background-repeat: no-repeat;
			background-position: right center;
			background-size: 4px auto;

			padding-right: 10px;
			margin-right: 5px;

			color: $color_background-6;

			font-size: $fontsize_menu_breadcrumb;
			@include typography_font(medium);
			text-transform: uppercase;
			white-space: nowrap;

			@include breakpoint($breakpoint_desktop) {
				background-size: 4px auto;

				padding-right: 15px;
				margin-right: 10px;

				font-size: $fontsize_menu_breadcrumb_desktop;
				@include typography_font(regular);
			}
		}

		.b-021__list-item:last-child {
			background-image: none;

			padding-right: 0;
			margin-right: 0;
		}

			// polozka menu
			.b-021__list-item a {

				display: inline-block;
				padding: 0;

				@include link-style ($color_portal_main, none, $color_portal_main, underline);

				@include breakpoint($breakpoint_desktop) {
					padding: 0;
				}
			}
