//
// B-052b Styles
//



.b-052b {
}

	.b-052b__subsection {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}

		background-color: $color_background-2;
	}

		.b-052b__block--first {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-basis: 25%;
				flex-shrink: 0;

				min-width: 150px;

				padding: 1.25em;

				background-color: $color_background-4;
			}
		}

		.b-052b__block--second {

			padding: 1em;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: column;
				flex-basis: 80%;

				padding: 1em;
			}

		}

			.b-052b__list,
			.b-052b .b-052b__block--second ul,
			.b-052b .b-052b__block--second p {
				margin-top: 0;
				margin-bottom: 1.75em;

				@include breakpoint($breakpoint_desktop) {
					margin-bottom: 1em;
				}
			}

				.b-052b__list .b-052b__list-item,
				.b-052b .b-052b__block--second li {
					list-style-type: disc;

					@include typography_font(medium);

					margin-left: 1em;
					margin-top: 0.5em;
					margin-bottom: 0.5em;

					@include breakpoint($breakpoint_desktop) {
						/*white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;*/
					}
				}

				.b-052b__list-item:last-child,
				.b-052b .b-052b__block--second li:last-child {
					margin-bottom: 0;
				}

				.b-052b__list-item:nth-of-type(1n+5),
				.b-052b .b-052b__block--second li:nth-of-type(1n+5) {

					@include breakpoint($breakpoint_desktop) {
						display: none;
					}
				}


			/*.b-052b .b-052b__block--second li a {
				@include link-style($color_decoration_main, none, $color_decoration_main, underline);

				.b-052b .b-052b__block--second p a {
					@include link-style($color_text_link, underline, $color_text_link, underline);
				}*/


			// readmore
			.b-052b__block--second .button-box-flat {
				background-color: $color_program_main;

				@include breakpoint($breakpoint_desktop) {
					//display: inline-flex;
					align-self: flex-start;

					margin-top: auto;
					//margin-right: auto;
				}
			}



// plab only
.patternlab .b-052b {
	max-width: 755px;
}
