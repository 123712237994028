//
// B-030 Styles
//
// playlisty - spolecne vlastnosti pro maly (aside) i velky (stanice/playlisty)
// vychazi z predpokladu ze velky playlist se v mobile stane tim samym jako maly, jen ma prvky navic
//



// zde px naschval aby se dalo pouzivat k odpaleni stejne vzdalenosti u elementu s ruznym pismem
$b-030_timestamp_width: 50px;
$b-030b_timestamp_width_desktop: 70px;
$b-030b_timestamp_padding-left_desktop: 8px;

$b-030_font-size_mobile: 15px;
$b-030b_font-size_desktop: 20px; // font varianty b ma desktop velky

$b-030_item-row_padding: 10px;

$b-030_item_background-arrow-size: 14px;



.b-030 {
}

	.b-030__subsection--list {
	}

		.b-030__list {
		}

			.b-030__list-item {

				color: $color_text_paragraph;
			}
			.b-030__list-item:nth-child(2n) {

				background-color: $color_background-1;
			}

				// obalovaci odkaz jen pro varianty s rozklikavacimi polozkami
				// nestylovat tedy jako layoutovy prvek ale pouze vizualne
				.b030__list-item-row-info-wrapper {

					display: block;

					@include link-style($color_text_paragraph, none, $color_text_paragraph, none);

					background-image: $file_icon_arrow_single_south_colored-decoration-main;
					background-repeat: no-repeat;
					background-position: center right 0.75em;
					background-position-x: right 0.75em; // IE safe positioning
					background-position-y: center;
					background-size: $b-030_item_background-arrow-size $b-030_item_background-arrow-size;
				}
				.b030__list-item-row-info-wrapper.active {

					background-image: $file_icon_arrow_single_north_colored-decoration-main;
				}

					.b-030__item-row--info {

						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: flex-start;

						padding: $b-030_item-row_padding;

						// obecne vlastnosti textu
						@include adjust-font-size-to($b-030_font-size_mobile);
					}

						.b-030__item-info-timestamp {

							box-sizing: content-box;

							flex-shrink: 0;
							width: $b-030_timestamp_width;
						}

						.b-030__item-info-wrapper {

							// prostor pro sipku
							padding-right: 2em; // pro variantu c resetovano modifikatorem

							flex-grow: 1;
						}

							.b-030__item-info-title {
								color: $color_text_accent;
								@include typography_font(medium);
							}

							.b-030__item-info-title-subtitle-separator {
							}

							.b-030__item-info-subtitle {
							}



				.b-030__item-row--actions {

					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;

					//height: 2.75em;

					padding: $b-030_item-row_padding;
					padding-bottom: $b-030_item-row_padding * 1.5;
				}

					.b-030__item-actions-search {

						display: flex;
						flex-direction: row;
						align-items: center;

						padding-left: $b-030_timestamp_width;
					}

						.b-030__item-actions-search-label {

							padding-right: 1em;

							color: $color_background-5;

							@include adjust-font-size-to($fontsize_box_petit_main);

							@include breakpoint($breakpoint_desktop) {
								font-size: $fontsize_box_petit_main_desktop;
							}
						}

						.b-030__item-actions-search-services {

							display: inline-flex;
						}

							// atom
							.b-030__item-actions-search-services .button
							{
								margin-right: 0.25em;
							}



	.b-030__subsection--buttons {

		margin-top: 1em;

		@include breakpoint($breakpoint_desktop) {
			text-align: right;
		}
	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-030__subsection--buttons .button-box-flat--readmore {

			@include breakpoint($breakpoint_desktop) {
				display: inline-flex;
				width: auto;
			}
		}





//
// B-030a Styles
//

// vizualni debug - plab only
// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
.patternlab .b-030a {

	max-width: 500px;
}






//
// B-030b Styles
//

// neBEM
// deti by mohly byt b-030b__ jak byva zvykem u ostatnich variaci boxu, ale tento vyuziva sdilene molekuly obsahu
.b-030b {

	.b-030__subsection--list {
	}

		// nadpis jedntliveho dne
		// pritomno pouze ve variante b
		.b-030__list-caption {

			margin-top: 2em;
			margin-bottom: 0.5em;

			padding-left: 0.5em;

			color: $color_text_paragraph;

			@include adjust-font-size-to($fontsize_box_petit_main);

			@include breakpoint($breakpoint_desktop) {

				padding-left: 1em;

				@include adjust-font-size-to($fontsize_box_petit_main_desktop);
			}
		}

		.b-030__list {
		}

			.b-030__item-row--info {

				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($b-030b_font-size_desktop);
				}
			}

				.b-030__item-info-timestamp {

					@include breakpoint($breakpoint_desktop) {

						width: $b-030b_timestamp_width_desktop;

						padding-left: $b-030b_timestamp_padding-left_desktop;
					}
				}


			.b-030__item-row--actions {
			}

				.b-030__item-actions-search {

					@include breakpoint($breakpoint_desktop) {

						padding-left: $b-030b_timestamp_width_desktop + $b-030b_timestamp_padding-left_desktop;
					}
				}


	// podplaylistovy button
	.b-030__subsection--buttons {

		margin-top: 2em;
	}
}






//
// B-030c Styles
//

// vizualni debug - plab only
// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
.patternlab .b-030c {

	max-width: 500px;
}

// neBEM
// deti by mohly byt b-030c__ jak byva zvykem u ostatnich variaci boxu, ale tento vyuziva sdilene molekuly obsahu
.b-030c {

	.b-030__item-info-wrapper {

		padding-right: 0; // reset mista kolem sipky, varianta c sipky nema
	}
}
