//
// Plus - obecne vlastnosti vsech stranek
//



.plus {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_plus_main, $color_plus_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_plus_main);
	}



	/* pouze na strankach playlisty a program */
	&.page-playlisty,
	&.page-program {
		.b-038__navbar {
			background-color: $color_background-6; // defaultne se prebiji barvou stanice samo dle rodice
		}
	}



	/* wrapper buttonu Vsechny zpravy z ... */
	&.page-kamery,
	&.page-spolecnost,
	&.page-kultura,
	&.page-hudba {
		.view-footer {
			@include breakpoint($breakpoint_desktop) {
				display: block;
				margin: 0 auto;
				margin-top: 2em;
				text-align: center;
			}

			a {
				@include breakpoint($breakpoint_desktop) {
					display: inline-flex;
				}
			}
		}
	}



}
