//
// B-033b Styles
//



.b-033b {
	
}
	
	.b-033b h2 {
	
		display: none;
	}
	
	.b-033b__subsection {
		
		position: relative;
		
		background-color: $color_background-9;
		
	}
	
	// zaviraci tlacitko X
	.b-033b__subsection .button-popup--close {
		
		position: absolute;
		top: 0;
		right: 0;
		
		@include breakpoint($breakpoint_desktop) {
		
			
		}
	}
		
		.b-033b__subsection .button-popup--close .button-popup__label {
		
			width: 2em;
			height: 2em;
			
			background-image: $file_icon_close_colored-white;
			
			@include breakpoint($breakpoint_desktop) {
				
				width: 2.5em;
				height: 2.5em;
			}
		}
		
		
		.b-033b__block {
			// neexituje vlastnost obecneho bloku
			// 1. blok je obsah (video a text)
			// 2. blok je wrapper tlacitek
		}
		
		.b-033b__block--content {
			
			display: flex;
			flex-direction: column;
			
			padding-top: 2em;
			padding-bottom: 1.5em;
			
			overflow: auto;
			
			@include breakpoint($breakpoint_desktop) {
				
				flex-direction: row;
				
				padding: 3em 6em;
			}
			
		}
			
			.b-033b__subblock--player {
				
				@include breakpoint($breakpoint_desktop) {
					
					flex-grow: 1;
				}
			}
			
			.b-033b__subblock--description {
				
				@include breakpoint($breakpoint_desktop) {
					
					flex-basis: 40%;
					flex-shrink: 1;
					
					display: flex;
					flex-direction: column;
					justify-content: center;
					
					margin-left: 2em;
				}
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-033b__subblock--description h3 {
					
					color: $color_text_paragraph_inverted;
					
					margin-top: 1.5em;
					margin-bottom: 0.5em;
					
					padding-left: 1em;
					padding-right: 1em;
					
					//color: $color_text_accent_inverted;
					// presunuto na vnorene <a>
					
					text-align: center;
					
					@include breakpoint($breakpoint_desktop) {
						
						margin-top: 0;
						margin-bottom: 0;
						
						padding-left: 0.5em;
						padding-right: 0.5em;
						
						font-size: 1.75em;
						text-align: initial;
					}
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-033b__subblock--description h3 a {
						
						@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
					}
				
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-033b__subblock--description a {
					
					@include link-style($color_text_paragraph_inverted, none, $color_text_paragraph_inverted, none);
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-033b__subblock--description p,
					.b-033b__subblock--description a p {
						
						margin: 0;
						
						padding-left: 1em;
						padding-right: 1em;
						
						//color: $color_text_paragraph_inverted;
						// presunuto na vnorene <a>
						
						text-align: center;
						
						@include breakpoint($breakpoint_desktop) {
							
							margin-top: 0.75em;
							
							text-align: initial;
						}
					}
					
					// muze byt i mimo <a>
					.b-033b__subblock--description p {
						
						color: $color_text_paragraph_inverted;
					}