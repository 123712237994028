//
// B-038d Styles
//



.b-038d {
}

	.b-038d__list {
		padding: 0 1em;

		background: $color_background-1;
	}

		.b-038d__list-item {
			padding: 1em 0;

			border-bottom: 1px solid $color_divider;

			&:last-child {
				border-bottom: none;
			}
		}

		.b-038d__item-datetime {
			display: inline-block;

			margin-bottom: 0.25em;

			color: $color_text_paragraph;

			font-size: 13px;
			line-height: 1.3;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-038d__list-item h3 {
			margin: 0;
			margin-bottom: 0.25em;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-038d__list-item p {
			margin: 0;

			color: $color_text_paragraph;
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-038d__list-item a p {
				@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
			}





// vizualni debug - plab only
.patternlab .b-038d {

	@include breakpoint($breakpoint_desktop) {
		max-width: 500px;
	}
}
