//
// Styles - Node Type Recipe
//



.node-type-recipe {

	// grid
	// TODO: zmenit z .item-special na .nth-of-type--special
	.l-grid--12-1.nth-of-type--special {
	}


	// title
	// TODO: v sablone zanorit do .l-grid--12-1.nth-of-type--1 (obrazkovy) a .l-grid--8-1.nth-of-type--1 (standardni/audio) a prepsat styly
	h1.recipe-type {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		margin-bottom: 0.5em;

		line-height: 1.8rem;

		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			padding-right: 25px; // viz .l-grid--8-1

			line-height: 2.5rem; // 3rem
		}

		&.article-type--image {
			@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}



	// grid
	.l-grid--12-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--12-1 {
		}


	// grid
	.l-grid--8-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--8-1 {

			.node-block--date-show {
				.node-block__block--show {
					@include breakpoint($breakpoint_desktop) {
						padding-left: 0;
						border-left: none;
					}
				}
			}

			.file-audio {
				margin-left: 0;
			}
		}


		// content-container
		.content-container {
			position: relative; // kvuli absolutnimu pozicobvani vertikalnich soc. buttonu
		}

			// vertical soc. buttons
			.content-1--1-1 {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;

					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: calc(100% - #{$node_left-margin})px;
				}
			}

			// perex, body
			.content-1--7-2 {
			}

				// recipe metadata
				.recipe-metadata {
					background-color: $color_background-1;
					border: 1px solid $color_background-4;

					margin-top: 0.5em;
					padding: 1em;

					font-size: 12px;
					line-height: 1.2;

					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: 13px;
						line-height: 1.3;

						margin-left: $node_left-margin;
					}

					a {
						@include link-style($color_decoration_main, none, $color_decoration_main, underline);
					}
				}

				// image metadata: title
				.recipe-difficulty,
				.recipe-portions,
				.recipe-time,
				.recipe-course,
				.recipe-author,
				.recipe-spacer {
					display: inline-block;
					color: $color_background-6; // o stupen svetlejsi nez $color_text_paragraph;
					margin-bottom: 0.35em;
				}

					// recipe: label
					.recipe-label {
						margin-right: 0.25em;
						color: $color_text_paragraph_node;
					}

					// recipe - authoring: item
						.recipe-item {
							//color: $color_background-6; // o stupen svetlejsi nez $color_text_paragraph;
					}


				// recipe: spacer
				.recipe-spacer {
					margin-top: 0.5em;
					margin-bottom: 0.5em;
				}

				// recipe: spacer comma
				.recipe-spacer--comma {
					display: inline-block;
					margin-right: 0.25em;
				}

				// recipe: comma
				.recipe-comma {
					margin-right: 0.25em;
				}


		// main audio anchor, authors, bottom soc. buttons
		.content-2--8-1 {
		}

			// fotka autora - fallback
			.view-display-id-node_author_main.box {
				display: none; // opusteno
			}

			// jmeno autora - fallback
			.view-display-id-node_author_main_2.box {
				display: none; // opusteno
			}

			// expired audio
			.article-type--audio .audio-rights-expired {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}


	// grid - aside
	.l-grid--4-9.nth-of-type--1 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 8em;
		}
	}

		// content boxes, ad
		.content-1--4-9 {
		}

}
