//
// Clearfix
//



.clearfix {
	//overflow: auto;
}

.clearfix::after {
	clear: both;
	content: "";
	display: table;
}

.cleaner {
	clear: both;
	height: 0;
}