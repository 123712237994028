//
// Prix Bohemia Radio - program schedule
//


// vars
$prixbohemiaradio-this-year-color: #4d6fb6;
$color_corporate_main: rgba(#000f37, 1);
$color_buy: rgba(#C10034, 1);
$prixbohemiaradio-ico-shopping: url('../images/icon/prixbohemiaradio/ico_shopping_cart.svg');
$prixbohemiaradio-ico-arrow-down: url('../images/icon/prixbohemiaradio/ico_arr_single_down_white.svg');

.pbr-program {
	min-width: 300px; // 320px - (2 x 10px padding)
	max-width: 620px;

	// rozklikávací hlavička
	.pbr-program__day {
		.pbr-program__header {
			position: relative;
			background-color: $prixbohemiaradio-this-year-color;
			&:hover,
			&.active {
				background-color: $color_corporate_main;
				cursor: pointer;
			}
			&:after {
				content: '';
				background: $prixbohemiaradio-ico-arrow-down no-repeat center;
				background-size: 16px 20px;
				position: absolute;
				right: 10px;
				top: 10px;
				width: 20px;
				height: 20px;

				&:hover {
					transform: rotate(180deg);
				}
			}
			// datum události
			.field-pbr-date {
				margin-bottom: 10px;
				padding: 10px;
				font-size: 1.188rem;
				line-height: 1.2;
				@include typography_font(medium);
				color: $color_background-0;
			}
			// kategorie události
			.field-pbr-category {
				color: $color_background-0;
				position: absolute;
				top: 7px;
				right: 0;
				display: flex;
				flex-direction: row;
				align-items: center;
				font-size: 0.825rem;
				padding-right: 40px;

				&:after {
					content: '';
					margin-left: 13px;
					border-left: 1px solid $color_background-0;
					width: 1px;
					height: 28px;
					opacity: 0.5;
				}
			}
		}
		// detailní program daného dne
		.pbr-program__content-wrapper {
			display: none;
			.pbr-program__content {
				border: 1px solid $color_background-4;
				padding: 10px;
				margin-top: 10px;
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 20px;
				}

				// čas od do
				.field-pbr-time {
					grid-area: range;
					color: $prixbohemiaradio-this-year-color;

					.field-pbr-time__from {
						padding-right: 10px;
					}
				}
				// název pořadu
				.field-pbr-title {
					grid-area: program;
					color: $prixbohemiaradio-this-year-color;
					@include typography_font(medium);

					a {
						@include link-style ($prixbohemiaradio-this-year-color, none, $prixbohemiaradio-this-year-color, none);
					}
				}
				// textový popis
				.field-pbr-description {
					grid-area: description;
					@include typography_font(medium);
					font-size: 0.75rem;
					line-height: 1.5;
					color: $color_background-6;

					a {
						@include link-style ($color_background-6, none, $color_background-6, none);
					}
				}
				// button na obsah
				.field-pbr-button {
					grid-area: button;
					display: flex;
					justify-content: flex-end;
				}
				// místo konání
				.field-pbr-place {
					grid-area: place;
					padding: 4px 0 8px 0;
					@include clearfix;
				}
				.field-pbr-location {
					float: left;
				}
				.field-pbr-address {
					&:before {
						content: '/';
						margin: 0 4px;
					}
				}
				.field-pbr-location,
				.field-pbr-address {
					font-size: 0.875rem;
					line-height: 1.2;
				}
				// cena vstupenky
				.field-pbr-price {
					grid-area: price;
					color: $color_background-6;
					padding-right: 30px;
					position: relative;
					min-height: 30px;
					display: inline-block;
					font-size: 0.875rem;
					@include clearfix;

					.field-pbr-price__wrap {
						background-color: $color_background-3;
						padding: 3px 8px;
					}
					.field-pbr-price__label {
						margin-right: 4px;
					}
					// odkaz pro nákup vstupenky
					.field-pbr-link {
						&:after {
							content: '';
							position: absolute;
							right: 0;
							top: 0;
							width: 30px;
							height: 100%;
							background: $prixbohemiaradio-ico-shopping no-repeat center $color_buy;
							background-size: 20px 20px;
							@include transition(background 200ms ease-in-out);
						}
						&:hover {
							&:after {
								background: $prixbohemiaradio-ico-shopping no-repeat center lighten($prixbohemiaradio-this-year-color, 12%);
								background-size: 20px 20px;
							}
						}
						a {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							z-index: 1;
							font-size: 0;
							text-indent: -9999px;
						}
					}
				}
			}
		}
		&.expanded {
			.pbr-program__header {
				background-color: $color_corporate_main;
				&:after {
					content: '';
					-ms-transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
	}
}

.pbr-program-wrapper {
	@include breakpoint($breakpoint_desktop) {
		h2 {
			margin-left: calc(4.375rem + 1.875rem);
		}
	}
}

.pbr-program-wrapper,
.year-1968 {
	.pbr-program {
		@include breakpoint($breakpoint_desktop) {
			margin-left: calc(4.375rem + 1.875rem); // součet margin a padding boxu b-047
		}
		// rozklikávací hlavička
		.pbr-program__day {
			.pbr-program__header {

				&:after {
					@include breakpoint($breakpoint_desktop) {
						top: 17px;
					}
				}
				// datum události
				.field-pbr-date {
					@include breakpoint($breakpoint_desktop) {
						font-size: 1.438rem;
						line-height: 1.45;
					}
				}
				// kategorie události
				.field-pbr-category {
					@include breakpoint($breakpoint_desktop) {
						top: 13px;
						font-size: 0.9375rem;
					}
				}
			}
			// detailní program daného dne
			.pbr-program__content-wrapper {
				.pbr-program__content {
					@include breakpoint($breakpoint_desktop) {
						align-items: center;
						display: grid;
						grid-template-columns: 130px calc(100% - 150px);
						grid-column-gap: 20px;
						grid-template-areas:
										"range program"
										". description"
										". button"
										". place"
										". price";
					}
					// čas od do
					.field-pbr-time {
						@include breakpoint($breakpoint_desktop) {
							font-size: 1.188rem;
						}
						.field-pbr-time__from {
							@include breakpoint($breakpoint_desktop) {
								padding-right: 16px;
							}
						}
					}
					// název pořadu
					.field-pbr-title {
						@include breakpoint($breakpoint_desktop) {
							font-size: 1.125rem;
						}
					}
					// textový popis
					.field-pbr-description {
						@include breakpoint($breakpoint_desktop) {
							line-height: 1.54;
							font-size: 0.875rem;
						}
					}

					.field-pbr-location,
					.field-pbr-address {
						@include breakpoint($breakpoint_desktop) {
							font-size: 1.188rem;
						}
					}
					// cena vstupenky
					.field-pbr-price {
						@include breakpoint($breakpoint_desktop) {
							display: inline-table;
						}
					}
				}
			}
		}
	}
}
