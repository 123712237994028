//
// Button Play - Launcher (b-015)
//



// launcher Default
.button-play--launcher {
	position: relative;

	display: block;
	width: 3.5em;
	height: 3.5em;

	margin: 0;
	padding: 0;

	background-image: $file_button_play_launcher;
	background-size: 200%;
	background-position: 0;

	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}

.button-play--launcher::after {
	content: '';

	position: absolute;
	width: 100%;
	height: 100%;

	background-image: $file_button_play_launcher;
	background-size: 200%;
	background-position: 100%;

	opacity: 0;
}

.button-play--launcher:hover::after {
	opacity: 1;
	transition: opacity 0.2s;
}



// launcher Sport
.button-play--launcher-sport {
	position: relative;

	display: block;
	width: 4em;
	height: 4em;

	margin: 0;
	padding: 0;

	background-image: $file_button_play_launcher-sport_colored-ed2e38;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 0;

	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}

.button-play--launcher-sport::after {
	content: '';

	position: absolute;
	width: 100%;
	height: 100%;

	background-image: $file_button_play_launcher-sport_colored-bc1526;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 100%;

	opacity: 0;
}

.button-play--launcher-sport:hover::after {
	opacity: 1;
	transition: opacity 0.2s;
}



// launcher Sport Live
.button-play--launcher-live-sport {
	display: block;
	width: 3.75em; // default 4em
	height: 3.75em; // default 4em

	margin: 0;
	padding: 0;

	background-image: $file_button_play_launcher-live-sport_colored-white;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 0;

	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}

.button-play--launcher-live-sport:hover {
	@include breakpoint($breakpoint_desktop) {
		opacity: 0.8;
		transition: opacity 0.2s;
	}
}



// launcher Junior / Pohoda Live
.button-play--launcher-live-junior,
.button-play--launcher-live-pohoda {
	display: block;
	width: 3.75em; // default 4em
	height: 3.75em; // default 4em

	margin: 0;
	padding: 0;

	background-image: $file_button_play_launcher-live-pohoda_colored-main;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 0;

	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}

.button-play--launcher-live-junior:hover,
.button-play--launcher-live-pohoda:hover {
	@include breakpoint($breakpoint_desktop) {
		opacity: 0.8;
		transition: opacity 0.2s;
	}
}



// launcher EQ
.button-eq--launcher-live {
	display: block;
	width: 3.75em; // default 4em
	height: 3.75em; // default 4em

	margin: 0;
	padding: 0;

	background-image: $file_button_eq_launcher-live_colored-white;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 0;

	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}

.button-eq--launcher-live:hover{
	@include breakpoint($breakpoint_desktop) {
		opacity: 0.8;
		transition: opacity 0.2s;
	}
}



// launcher mujRozhlas
.button-play--launcher-mujrozhlas {
	display: block;
	width: 16.25em;
	height: 3.75em;

	margin: 0;
	padding: 0;

	background-image: $file_button_play_launcher-mujrozhlas_colored-white;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 0;

	// cely span popisek je ve vychozim stavu skryt tridou 'hidden' a slouzi jen jako fallback pro obrazek
	.button__label {
	}
}

.button-play--launcher-mujrozhlas:hover {
	@include breakpoint($breakpoint_desktop) {
		opacity: 0.8;
		transition: opacity 0.2s;
	}
}
