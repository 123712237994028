//
// B-028b styles
//


.b-028b {
	// layout sloupcu
	&__subsection {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		@include breakpoint($breakpoint_desktop) {
			flex-wrap: nowrap;
		}
	}
	// layout sloupcu
	&__subblock {
		width: 49%;
		margin-right: 2%;
		&:nth-child(2n) {
			margin-right: 0;
		}
		@include breakpoint($breakpoint_desktop) {
			width: 23.5%;
			margin-right: 2%;
			&:nth-child(2) {
				margin-right: 2%;
			}
			&:nth-child(4) {
				margin-right: 0;
			}
		}
	}
	&__list-item {
		list-style-type: none;
		margin-bottom: 30px;
		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 20px;
		}
	}
	&__block-image {
		position: relative;
	}
	&__block-text {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}
	&__item {
		@include typography_font(medium);
		color: $color_text_paragraph;
		margin-bottom: .3em;
		a {
			font-size: .94rem;
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}
	}
	&__name {
		font-size: .94rem;
	}
	&__item-description {
		@include typography_font(regular);
		font-size: .9rem;
		color: $color_background-5;
		margin: 0;
		line-height: 1.2;
	}
	&__overlay {
		color: $color_background-9;
		background-color: $color_background-2;
		@include breakpoint($breakpoint_desktop) {
			background-color: $color_background-0_alpha-low;
			position: absolute;
			bottom: 0;
			left: 10px;
		}
	}
	&__overlay-content {
		h3 {
			margin: 0;
			font-size: 1.5rem;
			padding: 15px 10px;
			@include breakpoint($breakpoint_desktop) {
				font-size: 1.6rem;
				padding: 25px 30px;
			}
		}
	}
}


// skryti nadpisu u sdilitka
.b-028b .b-032b h2 {
	display: none;
}


// vizualni debug - plab only
.patternlab .b-028b {
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez main sloupec (v desktop)
		max-width: 750px;
	}
}
