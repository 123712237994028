//
// Cesty za vodou B-014a Styles
//



$cestyzavodou_logo_corporate_colored-9e9ea9: url('../images/logo/rozhlas_colored-white.svg'); // seda, napr. v hlavni nav liste
$cestyzavodou_b-014_menu-bar-background-color: $color_cestyzavodou_main;


.cestyzavodou {

	// nav
	.b-014a {
		background-color: unset;
	}

	// nekonecny pruh hlavni navigace
	.b-014a::before {
		background-color: $cestyzavodou_b-014_menu-bar-background-color;
		opacity: 0.85;
	}



		// logo
		.b-014a__logo {
		}

			.b-014a__logo a {
				background-image: $cestyzavodou_logo_corporate_colored-9e9ea9;
			}



		// menu
		.b-014a__list {
		}

			// obecna polozka menu
			//.b-014a__list-item {
			.b-014a__list > li {
			}

				// a jeji vnitrni odkaz
				//.b-014a__list-item > a {
				.b-014a__list > li > a {
					@include link-style ($color_text_link_inverted, none, $color_text_link_inverted, none);
				}
				.b-014a__list > li > a.expanded,
				.b-014a__list > li > a:hover {
					@include link-style ($color_text_link, none, $color_text_link, none);
				}


		// specificke vlastnosti polozky menu "vice"
		.b-014a__list-item--more {

		}

			.b-014a__list-item--more > a {
			}
			.b-014a__list-item--more > a::after {
				background-image: $file_icon_triangle_south_colored-white;
			}
			.b-014a__list-item--more > a.expanded::after,
			.b-014a__list-item--more > a:hover::after {
				background-image: $file_icon_triangle_south_colored-black;
			}
			.b-014a__list-item--more > a.expanded::after {
			}

				// puvodni drupal submenu - list vygenerovany vevnitr v "vice"
				// neni, nemelo by se do markupu vubec dostat, presto preventivne skryte
				.b-014a__list-item--more .b-014__list-more {
				}



		// searchbar
		.b-014a__searchbar {
		}

			.b-014a__searchbar-submit {
			}

				.b-014a__searchbar-submit-image {
					background-image: $file_icon_search_colored-white;
			}


}
