//
// B-101 Styles
//



.b-101 {
	display: flex;
	flex-direction: column;
}

	.b-101 h2 {
	}

	.b-101__section-block {
		display: flex;
		flex-direction: column;

		padding: 1em 0;

		border: 1px solid $color_divider;
	}
		.b-101__list {
			display: flex;
		}

			.b-101__list-item {
				flex-grow: 1;

				width: 25%;
			}

				.b-101__weather-day,
				.b-101__weather-ico,
				.b-101__weather-value {
					display: block;

					color: $color_text_paragraph;

					padding: 0 5px; // v px kvuli rozdilnym velikostem fontu (nevychazi z base size)

					font-size: 0.75em;
					text-align: center;

					@include breakpoint($breakpoint_desktop) {
						font-size: 0.8em;
						padding: 0 10px; // v px kvuli rozdilnym velikostem fontu (nevychazi z base size)
					}

					& strong {
						@include typography_font(bold);
						color: $color_text_accent;
					}
				}

				.b-101__weather-day {
					font-size: 0.9em;

					@include breakpoint($breakpoint_desktop) {
						font-size: 0.95em;
					}
				}

				.b-101__weather-block {
				}

					.b-101__weather-ico {
						color: #519fd7;

						font-size: 2em;

						padding: 15px 5px; // v px kvuli rozdilnym velikostem fontu (nevychazi z base size)
					}


			.b-101__list-item:not(:last-child) .b-101__weather-block {
				border-right: 1px solid $color_divider;
			}


	.b-101__section-block .button {
		display: inline-flex;
		align-self: center;

		margin-top: 1em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 1.25em;
		}
	}





// vizualni debug - plab only
.patternlab .b-101 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
