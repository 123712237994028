//
// Regiony (Radio vaseho kraje) - obecne vlastnosti vsech stranek
//



.regiony {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_regiony_main, $color_regiony_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_regiony_main);
	}

	// Event - Pochoutkový Rok
	&.page-node-7542424 {
		// odsazení top-proma
		.b-031a-v2b:nth-of-type(1) {
			margin-top: 0;
			margin-bottom: 2em;
		}

	}

	// header
	.b-015 {
		.b-015__list-item--right {
			display: none; // prehravac a addons nechceme
		}
	}

}
