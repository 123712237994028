//
// Bitva o Rozhlas - Hrdinove
//



// font Roboto Slab
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');
$font-inmemoriam: 'Roboto Slab', 'Helvetica', 'Arial', Sans-Serif; // custom font + fallback



#{$bitvaorozhlas_selector_body-pametnideska} {

	// kotvy v2
	.radiobutton-filter--pametnideska {
		margin-top: 2em;
		margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 3em;
			margin-bottom: 0;
		}

		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}
	}

	.b-047 {
		padding-top: 2em;
		margin-top: 2em;

		border-top: 1px solid $color_divider;

		@include breakpoint($breakpoint_desktop) {
			padding-top: 3em;
			margin-top: 3em;
			margin-left: 4.375rem;
			margin-right: 4.375rem;
		}

		// title
		.b-047__title {
			font-family: $font-inmemoriam;
			font-weight: bold;
			font-size: 30px;
			text-align: center;
		}

		// body node
		.field.body {

			@include breakpoint($breakpoint_desktop) {
				margin-left: 0;
				padding: 0;
			}

			font-size: $base-font-size; // prebiji defaultni hodnoty pro node
			line-height: $base-line-height; // prebiji defaultni hodnoty pro node

			// list
			.box-inmemoriam__list,
			> ul {
				padding-left: 0;
				margin: 15px 0 35px 0;

				text-align: center;

				// item
				.box-inmemoriam__list-item,
				> li {
					left: initial; // reset default hodnot z _node.scss
					width: auto; // reset default hodnot z _node.scss

					//display: block;
					display: inline-block; // nakonec takto i na mobilu

					padding: 4px 10px;
					margin: 0;

					font-family: $font-inmemoriam;
					font-size: 16px;

					//color: $color_text_paragraph;
					color: $color_background-6;
					list-style: none outside none;

					@include breakpoint($breakpoint_desktop) {
						display: inline-block;
					}

					> a {
						font-family: $font-inmemoriam;
						@include link-style($color_text_link, underline, $color_text_link, underline);
					}
				}
			}

			// button readmore
			/*> .box-inmemoriam__readmore {

				> a {
					color: #a9a9a9;

					font-size: 20px;
					text-transform: uppercase;
				}
			}*/
		}
	}

	// button readmore
	.button__wrapper {
		margin-top: 0;
	}

}
