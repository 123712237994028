//
// Zelenavlna - obecne vlastnosti vsech stranek
//



.zelenavlna {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_zelenavlna_main, $color_zelenavlna_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_zelenavlna_main);
	}

	// stanicni nav
	.b-016__wrapper {

		h1,
		h2 {
			//background-color: $color_background-0;
			//color: $color_background-9;
		}

		h1:before,
		h2:before {
			background-color: $color_background-8;
		}

		.b-016 {
			background-color: $color_background-8;

			/* barevnost listy a odkazu */
			.b-016__list {
				background-color: $color_background-8;

				.b-016__list-item {

					a {
						//@include link-style($color_background-9, none, $color_background-9, none);
					}

					a.expanded {
						background-color: $color_background-6;
					}

					a:hover,
					a.expanded:hover {
						background-color: $color_zelenavlna_main;
					}
				}

				.b-016__list-more {
					background-color: $color_background-6;

					a:hover {
						background-color: $color_zelenavlna_main;
					}
				}
			}

		}
	}

	// box
	.box {

		// titulek
		h2 {
			//color: $color_background-8;
			a {
				//@include link-style ($color_background-8, none, $color_background-8, underline);
			}
		}
	}

}
