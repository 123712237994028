//
// B-051a Styles
//



.b-051a {
}

	// list
	.b-051a__list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 2em;
	}

		// item
		.b-051a__list-item {
			margin-bottom: 2em;
			display: flex;
			flex-direction: column;
			@include breakpoint($breakpoint_tablet) {
				flex-basis: calc(50% - 10px);
			}
		}

		// item: item pro dvousloupcovy layout
		.b-051a__list-item:nth-child(2n) {
			@include breakpoint($breakpoint_tablet) {
				margin-left: 20px;
			}
		}

		// item: posledni item pro jednosloupcovy layout na mobilu - zamezuje zbytecnym mezeram mezi boxy
		.b-051a__list-item:last-child {
			margin-bottom: 0;
		}

		// item: predposledni item pro dvousloupcový layout - zamezuje zbytecnym mezeram mezi boxy
		.b-051a__list-item:nth-last-child(2) {
			@include breakpoint($breakpoint_tablet) {
				margin-bottom: 0;
			}
		}

			// block
			.b-051a__block {
			}

			// block: image
			.b-051a__block--image {

			}

				.b-051a__image {
					width: 100%;
				}

					.b-051a__image-logo {
						width: 100%;
						height: 100%;
					}

						.b-051a__image-logo span {
							display: none;
						}


			// block: description wrap
			.b-051a__block--description-wrap {
				display: flex;
				flex-wrap: wrap;
				margin-top: 10px;
			}

				// block: description
				.b-051a__block--description {
					padding-right: 20px;
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					width: calc(100% - 70px);
					border-right: 1px solid $color_divider;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-051a__block--description h3 {
						font-size: 20px;
						line-height: 1.3;

						margin-top: 0;
						margin-bottom: 0.25em;

						@include breakpoint($breakpoint_desktop) {
							font-size: 18px;
							line-height: 1.3;

							margin-top: 0;
							margin-bottom: 0.25em;
						}
					}


						.b-051a__subblock--description {
							/*display: flex;
							flex-direction: row;
							flex-wrap: nowrap;*/
						}

							.b-051a__description--text p {
								font-size: 16px;
								line-height: 1.4;

								margin: 0;

								color: $color_text_paragraph;

								@include breakpoint($breakpoint_desktop) {
									font-size: 15px;
									line-height: 1.4;
								}
							}

				.b-051a__block--icons {
					margin-left: 10px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
				}

					.b-051a__block--icons .button {
						margin-top: 10px;
					}

					.b-051a__block--icons .button:first-child {
						margin-top: 0;
					}

// vizualni debug - plab only
.patternlab {
	.b-051a {
		@include breakpoint($breakpoint_desktop) {
			max-width: 755px;
		}
	}
}


//
// b-051a Printer Styles
//

@media print {

	.b-051a {
		@include breakpoint($breakpoint_desktop) {
			max-width: 100%;
		}
	}

		.b-051a__navbar.bg-color-main {
			background-color: transparent;
		}

			.b-051a__navbar-item--previous a,
			.b-051a__navbar-item--next a {
				display: none;
			}

			.b-051a__navbar-item--calendar {
				background-color: transparent;
				color: $color_text_accent;

				box-shadow: none;
			}


		.b-051a__links {
			display: none;
		}

		.b-051a__list-item--present {
			 background-color: transparent;
		}

			.b-051a__list-item--present .b-051a__block--timing {
				flex-wrap: no-wrap;
				flex-direction: row;
				align-items: flex-start;
			}

				.b-051a__list-item--past .b-051a__info--time {
					color: $color_text_accent;
				}

				.b-051a__launcher-info-timing-progressbar {
					display: none;
				}

					.b-051a__launcher-info-timing-progressbar-progress {
						display: none;
					}


				.b-051a__image {
					display: none;
				}

				.b-051a__actions {
					display: none;
				}

					// odkaz na archiv poradu, leze z api, ale neni v zadani
					.b-051a__actions .cro-buttons-archive-h12 {
						display: none;
					}

						.b-051a__list-item--present .b-051a__actions .cro-buttons-archive-h12 {
							display: none;
						}
}
