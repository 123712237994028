//
// A-002 Styles
//



.a-002 {
	position: relative;
}

// vertikalni linka
.a-002::before {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	width: 10px;

	background-color: $color_decoration_main; // defaultni barva, v drupalu bude pretizena barvou stanice
}

	.a-002__block,
	.a-002__list {
		padding: 0.25em 0;
		margin-left: 1.5em;
	}

		.a-002__list-item,
		.a-002__row {
			margin: 0;

			font-size: 14px !important;
			line-height: 1.6;

			color: $color_text_paragraph;
		}

		.a-002__row {
		}

		.a-002__list-item {
			@include typography_font(medium);
			list-style: disc !important;
			margin-left: 1em;
		}


			.a-002__list-item,
			.a-002__row strong {
				color: $color_text_accent;
			}


		.a-002__voting {
			margin-bottom: 0.5em;
			max-width: 7em;
		}

			.a-002__voting svg {
				display: block;
			}

				// default
				// 0%
				.a-002__voting svg,
				.a-002__voting--0 svg {

					.svg-voting-1a,
					.svg-voting-2a,
					.svg-voting-3a,
					.svg-voting-4a,
					.svg-voting-5a { fill: $color_background-4; }

					.svg-voting-1b,
					.svg-voting-2b,
					.svg-voting-3b,
					.svg-voting-4b,
					.svg-voting-5b { fill: transparent; }
				}

				// 10%
				.a-002__voting--10 svg {

					.svg-voting-1b { fill: $color_decoration_main; }
				}

				// 20%
				.a-002__voting--20 svg {

					.svg-voting-1a { fill: $color_decoration_main; }
				}

				// 30%
				.a-002__voting--30 svg {

					.svg-voting-1a,
					.svg-voting-2b { fill: $color_decoration_main; }
				}

				// 40%
				.a-002__voting--40 svg {

					.svg-voting-1a,
					.svg-voting-2a { fill: $color_decoration_main; }
				}

				// 50%
				.a-002__voting--50 svg {

					.svg-voting-1a,
					.svg-voting-2a,
					.svg-voting-3b { fill: $color_decoration_main; }
				}

				// 60%
				.a-002__voting--60 svg {

					.svg-voting-1a,
					.svg-voting-2a,
					.svg-voting-3a { fill: $color_decoration_main; }
				}

				// 70%
				.a-002__voting--70 svg {

					.svg-voting-1a,
					.svg-voting-2a,
					.svg-voting-3a,
					.svg-voting-4b { fill: $color_decoration_main; }
				}

				// 80%
				.a-002__voting--80 svg {

					.svg-voting-1a,
					.svg-voting-2a,
					.svg-voting-3a,
					.svg-voting-4a { fill: $color_decoration_main; }
				}

				// 90%
				.a-002__voting--90 svg {

					.svg-voting-1a,
					.svg-voting-2a,
					.svg-voting-3a,
					.svg-voting-4a,
					.svg-voting-5b { fill: $color_decoration_main; }
				}

				// 100%
				.a-002__voting--100 svg {

					.svg-voting-1a,
					.svg-voting-2a,
					.svg-voting-3a,
					.svg-voting-4a,
					.svg-voting-5a { fill: $color_decoration_main; }
				}





// vizualni debug - plab only
.patternlab .a-002 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}
