//
// B-039c Styles
//
// 



.b-039c {
}

	// list
	.b-039c__list {
		display: flex;
		flex-wrap: wrap;
		
	}

		// item
		.b-039c__list-item {
			// trik jak 2 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 49%; // toto neni polovina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do petin
			max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(50% - 0.25em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 0.25em? protoze je to 0.5 * 1 / 2 viz (*)

			margin-right: 0.5em; // (*)
			margin-bottom: 0.5em;

			@include breakpoint($breakpoint_desktop) {
				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 0.34em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.341em? protoze je to 0.5 * 2 / 3 viz (*)

				margin-right: 0.5em; // (*)
			}
			
		}

		// item: posledni
		.b-039c__list-item:last-child {
			margin-right: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}
	
		// item: kazdy druhy
		.b-039c__list-item:nth-child(2n+2) {
			margin-right: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0.5em;
			}
		}

		// item: kazdy treti
		.b-039c__list-item:nth-child(3n+3) {

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}
		
		// item: posledni dva
		.b-039c__list-item:nth-last-child(-n+2) {
			margin-bottom: 0;
		}

		// item: posledni tri
		.b-039c__list-item:nth-last-child(-n+3) {

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}





// vizualni debug - plab only
.patternlab .b-039c {
	
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}
