//
// Wave B-016
//
// wave-specific prekrucovani stanicniho menu
//
// je namiste zde poznamenat ze pokud by se wave typ mel sirit na dalsi weby, tak zpracovat obecne
// asi jako samostatny pismenny podtyp b016
//



$wave_b-016_background-color: $color_wave_main;
$wave_b-016_color_decoration-supplement: $color_background-9;



.wave .b-016 {

	// prebarveni a zmena odrazeni
	.b-016__list {

		background-color: $wave_b-016_background-color;

		/*padding-left: 28px;
		padding-right: 28px;*/
	}

		// zmena pisma
		.b-016__list-item a {

			@include link-style ($color_text_accent_inverted, none, $color_text_accent_inverted, none);
		}

		// prebarveni dekoraci a podmenu
		.b-016__list-item a.active::after,
		.b-016__list-item a.expanded,
		.b-016__list-item a:hover,
		.b-016__list-more {

			background-color: $wave_b-016_color_decoration-supplement;
		}

			.b-016__list-item a.active::after {
				/*bottom: -5px;
				height: 10px;
				left: 10px;
				width: calc(100% - 2 * 10px);*/
			}

			// aktivni polozka podmenu
			.b-016__list-more .b-016__list-item a:hover {

				background-color: $wave_b-016_background-color;
			}
}
