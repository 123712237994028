//
// Region (strednicechy) - HP
//



.strednicechy.page-region-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}