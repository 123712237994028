//
// H2
//



h2 {
	@include typography_font(bold);

	@include adjust-font-size-to($fontsize_h2);
	@include rhythm(3, 0, 0, 2, $fontsize_h2);

	color: $color_decoration_main;

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_h2_desktop);
		@include rhythm(3, 0, 0, 2, $fontsize_h2_desktop);
	}
}
