//
// Neviditelna - obecne vlastnosti vsech pardubickych stranek
//



// vars
$neviditelna-bg-desktop: url('../images/bg/neviditelna/neviditelna_desktop.jpg');
$neviditelna-bg-mobile: url('../images/bg/neviditelna/neviditelna_mobile.jpg');

$neviditelna-file_icon_socialsite: url('../images/icon/neviditelna/ico_soc_site.svg');
$neviditelna-file_icon_office: url('../images/icon/neviditelna/ico_urady.svg');
$neviditelna-file_icon_police: url('../images/icon/neviditelna/ico_policie.svg');
$neviditelna-file_icon_operator: url('../images/icon/neviditelna/ico_operator.svg');
$neviditelna-file_icon_shop: url('../images/icon/neviditelna/ico_obchody.svg');
$neviditelna-file_icon_radio: url('../images/icon/neviditelna/ico_rozhlas.svg');

$neviditelna-file_icon_socialsite-hover: url('../images/icon/neviditelna/ico_soc_site-hover.svg');
$neviditelna-file_icon_office-hover: url('../images/icon/neviditelna/ico_urady-hover.svg');
$neviditelna-file_icon_police-hover: url('../images/icon/neviditelna/ico_policie-hover.svg');
$neviditelna-file_icon_operator-hover: url('../images/icon/neviditelna/ico_operator-hover.svg');
$neviditelna-file_icon_shop-hover: url('../images/icon/neviditelna/ico_obchody-hover.svg');
$neviditelna-file_icon_radio-hover: url('../images/icon/neviditelna/ico_rozhlas-hover.svg');

$neviditelna-icon_socialsite_width: 5em;
$neviditelna-icon_socialsite_height: 2.5em;

$neviditelna-icon_office_width: 5em;
$neviditelna-icon_office_height: 2.5em;

$neviditelna-icon_police_width: 4em;
$neviditelna-icon_police_height: 3.5em;

$neviditelna-icon_operator_width: 3.5em;
$neviditelna-icon_operator_height: 3.5em;

$neviditelna-icon_shop_width: 3em;
$neviditelna-icon_shop_height: 3.5em;

$neviditelna-icon_radio_width: 4.5em;
$neviditelna-icon_radio_height: 3.5em;

$neviditelna-icon_hover_width: 6.75em;
$neviditelna-icon_hover_height: 6.75em;



// init
.header__promo-wrapper {
	display: none;
}


.neviditelna {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_radiozurnal_main, $color_radiozurnal_main_darker); // vychazi z barev RZ



	// init
	.header__promo-wrapper.header__promo-wrapper--neviditelna {
		display: block;
	}

	// header
	// after: bg
	.header__promo-wrapper  {
		position: relative;
	}
	.header__promo-wrapper--neviditelna::after  {
		content: "";

		background-image: $neviditelna-bg-mobile;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: scroll;
		background-position: center top;

		position: fixed;
		top: 0;
		left: 0;

		width: 100vw;
		height: 100vh;

		z-index: -2;

		@include breakpoint($breakpoint_desktop) {
			background-image: $neviditelna-bg-desktop;
			background-size: cover;
			background-attachment: fixed;

			position: fixed;
		}
	}

	// station header
	.b-015 {
		display: none;
	}

	// stanicni nav
	.b-016__wrapper {
		margin: 0;

		h1,
		h2 {
			display: none; // nechceme

			background-color: #baccec;
			color: #1a1a1e;

			max-width: 65%; // max sirka pro mobil, aby se nezalomilo

			@include breakpoint($breakpoint_desktop) {
				max-width: 350px; // max sirka pro dektop

				margin-bottom: 8px; // 0.5em z basefont 16px
			}
		}

		h1::before,
		h2::before {
			background-color: $color_radiozurnal_main;
		}

		.b-016 {
			//flex-basis: 0; // aby newrapoval (opusteno, chybovalo v IE)

			/* barevnost listy a odkazu */
			background-color: $color_radiozurnal_main;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 8px; // 0.5em z basefont 16px
			}

			/* barevnost listy a odkazu */
			.b-016__list {
				background-color: $color_radiozurnal_main;

				overflow: visible;

				.b-016__list-item {

					position: relative;

					a {
						@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);

						@include breakpoint($breakpoint_desktop) {
							min-width: 135px;

							padding: 0.4em 12px;

							@include typography_font(medium);
							font-size: 13px;
							line-height: 28px;
							text-transform: uppercase;
							text-align: center;
						}
					}

					a.expanded {
						background-color: $color_radiozurnal_main_darker;
					}

					a:hover {
						background-color: transparent;
					}

					a.active {
						display: inline-block;
					}

					a.active::after {
						display: none;
					}
				}

				// hover
				.b-016__list-item:not(.item-disabled):hover::before {
					@include breakpoint($breakpoint_desktop) {

					}
				}

				// active circle
				.b-016__list-item:not(.item-disabled).active-trail::before {
					@include breakpoint($breakpoint_desktop) {
						content: "";

						width: $neviditelna-icon_hover_width;
						height: $neviditelna-icon_hover_height;

						position: absolute;
						top: -72px;
						left: calc(50% - #{$neviditelna-icon_hover_width} / 2);

						background-color: $color_radiozurnal_main;
						border-radius: 100%;

						z-index: -2;
					}
				}

				.b-016__list-item.item-socialnisite::after {
					@include breakpoint($breakpoint_desktop) {
						content: "";

						width: $neviditelna-icon_socialsite_width;
						height: $neviditelna-icon_socialsite_height;

						position: absolute;
						top: -40px;
						left: calc(50% - #{$neviditelna-icon_socialsite_width} / 2);

						background-image: $neviditelna-file_icon_socialsite;
						background-repeat: no-repeat;
					}
				}
				.b-016__list-item:not(.item-disabled).item-socialnisite.active-trail::after {
					@include breakpoint($breakpoint_desktop) {
						background-image: $neviditelna-file_icon_socialsite-hover;
					}
				}

				.b-016__list-item.item-urady::after {
					@include breakpoint($breakpoint_desktop) {
						content: "";

						width: $neviditelna-icon_office_width;
						height: $neviditelna-icon_office_height;

						position: absolute;
						top: -40px;
						left: calc(50% - #{$neviditelna-icon_office_width} / 2);

						background-image: $neviditelna-file_icon_office;
						background-repeat: no-repeat;
					}
				}
				.b-016__list-item:not(.item-disabled).item-urady.active-trail::after {
					@include breakpoint($breakpoint_desktop) {
						background-image: $neviditelna-file_icon_office-hover;
					}
				}

				.b-016__list-item.item-policie::after {
					@include breakpoint($breakpoint_desktop) {
						content: "";

						width: $neviditelna-icon_police_width;
						height: $neviditelna-icon_police_height;

						position: absolute;
						top: -55px;
						left: calc(50% - #{$neviditelna-icon_police_width} / 2);

						background-image: $neviditelna-file_icon_police;
						background-repeat: no-repeat;
					}
				}
				.b-016__list-item:not(.item-disabled).item-policie.active-trail::after {
					@include breakpoint($breakpoint_desktop) {
						background-image: $neviditelna-file_icon_police-hover;
					}
				}

				.b-016__list-item.item-operator::after {
					@include breakpoint($breakpoint_desktop) {
						content: "";

						width: $neviditelna-icon_operator_width;
						height: $neviditelna-icon_operator_height;

						position: absolute;
						top: -52px;
						left: calc(50% - #{$neviditelna-icon_operator_width} / 2);

						background-image: $neviditelna-file_icon_operator;
						background-repeat: no-repeat;
					}
				}
				.b-016__list-item:not(.item-disabled).item-operator.active-trail::after {
					@include breakpoint($breakpoint_desktop) {
						background-image: $neviditelna-file_icon_operator-hover;
					}
				}

				.b-016__list-item.item-obchody::after {
					@include breakpoint($breakpoint_desktop) {
						content: "";

						width: $neviditelna-icon_shop_width;
						height: $neviditelna-icon_shop_height;

						position: absolute;
						top: -55px;
						left: calc(50% - #{$neviditelna-icon_shop_width} / 2);

						background-image: $neviditelna-file_icon_shop;
						background-repeat: no-repeat;
					}
				}
				.b-016__list-item:not(.item-disabled).item-obchody.active-trail::after {
					@include breakpoint($breakpoint_desktop) {
						background-image: $neviditelna-file_icon_shop-hover;
					}
				}

				.b-016__list-item.item-rozhlas::after {
					@include breakpoint($breakpoint_desktop) {
						content: "";

					width: $neviditelna-icon_radio_width;
						height: $neviditelna-icon_radio_height;

						position: absolute;
						top: -52px;
						left: calc(50% - #{$neviditelna-icon_radio_width} / 2);

						background-image: $neviditelna-file_icon_radio;
						background-repeat: no-repeat;
					}
				}
				.b-016__list-item:not(.item-disabled).item-rozhlas.active-trail::after {
					@include breakpoint($breakpoint_desktop) {
						background-image: $neviditelna-file_icon_radio-hover;
					}
				}

				// disabled item has class item-disabed
				.b-016__list-item.item-disabled a,
				.b-016__list-item.item-disabled::after {
					opacity: 0.5;
				}
				.b-016__list-item.item-disabled a {
					pointer-events: none;
					cursor: default;
				}

				.b-016__list-more {
					background-color: $color_radiozurnal_main_darker;

					a:hover {
						background-color: $color_radiozurnal_main;
					}
				}
			}

		}
	}

	// proklikavaci header promo
	.header__promo--desktop {
		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: block;
			flex-basis: 100%;
		}
	}
	.header__promo--mobile {
		display: block;
		flex-basis: 100%;

		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}
	.header__promo--desktop img,
	.header__promo--mobile img {
		display: block;
	}
	.header__promo--desktop img {
		width: 581px;
		height: 265px;
	}

	.main {
		background-color: $color_background-1;

		//padding-top: 3em;
		padding-top: 0.1px;
		margin-top: 0;

	}
	// FIX: kdyz .main nema .clearfix
	.main::after {
		clear: both;
		content: "";
		display: table;
	}

	footer {
		background-color: $color_background-1;
	}

	&.page-neviditelna-hp,
	&.page-node {
		.file.file-audio.file-audio-mpeg {
			background-color: $color_background-0;
		}
	}



	// b-041a v aside
	.l-grid--4-9 .b-041a__block--description {
		padding-left: 0;
	}
}



.neviditelna.page-node.node-type-article,
.neviditelna.page-neviditelna-hp {

	// specificke stanicni barveni v nodu plneho zneni
	@include station_element-coloring_node-type-article($color_radiozurnal_main); // vychazi z barev RZ
}



/* ??? */
.b-047 .file {
	margin-bottom: 1.125rem;
}
