//
// Styles JV
//


// vars
$jv-font: 'Roboto Condensed', Sans-Serif;
$jv-green: #2aa94a;



.jeziskova-vnoucata {

	font-family: $jv-font;

	ul, ol {
		margin-top: 0;
		margin-bottom: 0;
	}

	//
	// $font-family: 'rozhlas_medium'; fix
	//
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
		font-weight: bold;
	}

	// node, wavenews
	&.page-node,
	.b-047 {

		.field-perex {
			font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
			font-weight: bold;

			a {
				font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
				font-weight: bold;
			}
		}

		.body {
			strong {
				font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
				font-weight: bold;
			}

			> h3 {
				font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
				font-weight: bold;
			}

			// citace
			.citation > em,
			.citation > strong {
				font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
				font-weight: bold;
			}

			// ctete take
			.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-article > h2 > a,
			.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-event > h2 > a,
			.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-recipe > h2 > a,
			.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-serial > h2 > a,
			.wysiwyg-asset.asset-type-article.display-wysiwyg_asset .node-show > h2 > a {
				font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
				font-weight: bold;
			}
			p:not(.cteteTake) a:not(.button) {
				font-family: $jv-font; // prebiji font-family: "rozhlas_medium"; defaultni typografie
				font-weight: bold;
			}
		}
	}



	.container {
		padding-left: 10px;
		padding-right: 10px;
		position: relative;
	}
	>.container {
		min-height: auto;
	}

	.cro {
		margin-bottom: 40px;
	}

	.top-header {
		position: relative;
		padding: 0;
		color: #FFF;

		max-height: 433px;
		overflow: hidden;

		.bx-wrapper {
			margin-bottom: 0;
			border: 0;
			-webkit-box-shadow: 0;
			-moz-box-shadow: 0;
			box-shadow: 0;
		}
		> img {
			width: 100%;
		}
		.logo {
			position: absolute;
			top: 22px;
			left: 25px;
			width: 81px;
			@media all and (max-width: 550px) {
				width: 60px;
				top: 11px;
				left: 11px;
			}
		}
		.shares {
			position: absolute;
			top: 22px;
			right: 25px;
			width: 160px;
			height: 50px;
			@media all and (max-width: 330px) {
				width: 55px;
				height: 23px;
				top: 10px;
				right: 13px;
			}
			.share-item {
				width: 43px;
				height: 43px;
				float: right;
				background-size: 86px 43px;
				background-position: left;
				@media all and (max-width: 330px) {
					width: 21px;
					height: 21px;
					background-size: 42px 21px;
					background-position: left;
				}
				&.navbar-toggle {
					background: #FFF;
					display: block;
					margin: 0 8px 0 0;
					border-radius: 0;
					span {
						background: #555250;
					}
					&:hover {
						background: #555250;
						span {
							background: #FFF;
						}
					}
				}
				&#facebook {
					margin-right: 8px;
					background-image: $jv-btn-fb-sprite;
				}
				&#instagram {
					background-image: $jv-btn-insta-sprite;
				}
				&:hover {
					background-position: 43px 0;
				}
			}
		}

		.title {
			position: absolute;
			bottom: 0;
			text-align: center;
			height: 152px;
			width: 100%;
			margin: 0;
			background: $jv-hp-top-bg-overlay repeat-x;
			&.bg-green {
				background: rgba($jv-green, 0.5);
			}

			@media all and (max-width: 991px) {
				height: 119px;
			}
			@media all and (max-width: 750px) {
				height: 96px;
			}
			@media all and (max-width: 600px) {
				height: 80px;
			}
			@media all and (max-width: 550px) {
				height: 60px;
			}
			h1 {
				font-size: 72px;
				line-height: 80px;
				font-weight: bold;
				margin-top: 20px;
				margin-bottom: 0;
				margin-left: auto;
				margin-right: auto;
				color: #fff;
				@media all and (max-width: 991px) {
					font-size: 60px;
					line-height: 70px;
					margin-top: 25px; // neni h3
				}
				@media all and (max-width: 750px) {
					font-size: 45px;
					line-height: 55px;
					margin-top: 20px; // neni h3
				}
				@media all and (max-width: 600px) {
					font-size: 35px;
					line-height: 54px;
					margin-top: 15px; // neni h3
				}
				@media all and (max-width: 550px) {
					font-size: 24px;
					line-height: 34px;
					margin-top: 15px; // neni h3
				}
			}
			h3 {
				font-size: 28px;
				line-height: 38px;
				font-weight: bold;
				margin-top: 0;
				@media all and (max-width: 991px) {
					font-size: 17px;
					line-height: 27px;
					display: none;
				}
				@media all and (max-width: 750px) {
					font-size: 15px;
					line-height: 25px;
					display: none;
				}
				@media all and (max-width: 600px) {
					font-size: 13px;
					line-height: 23px;
					display: none;
				}
				@media all and (max-width: 550px) {
					font-size: 11px;
					line-height: 21px;
					display: none;
				}
			}
		}
	}

	// header v2
	.top-header-v2 {
		position: relative; // kvuli absolutnimu pozicovani .shares (u nove hlavicky .top-header-v2)

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		padding: 40px 0px 20px 0px;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: nowrap;

			padding: 40px 25px 20px 25px;
		}

		// logo
		.logo {
			flex-grow: 1;
			margin-bottom: 20px;

			@include breakpoint($breakpoint_desktop) {
				flex-grow: 0;
				max-width: 667px; // original 667px x 100px
				margin-right: 40px;
				margin-bottom: 0;
			}
		}

		// banner
		.banner {

			@include breakpoint($breakpoint_desktop) {
				max-width: 400px; // original 400px x 100px
			}
		}
	}

	// navbar - header v2
	.navbar-header {
		position: relative;

		// shares - pouze na mobilnim viewportu
		.shares {
			position: absolute;
			top: -4px;
			left: 0;
			width: 94px;
			height: 50px;
			z-index: 1;
			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
			.share-item {
				width: 43px;
				height: 43px;
				float: right;
				background-size: 86px 43px;
				background-position: left;
				&#facebook {
					margin-right: 8px;
					background-image: $jv-btn-fb-sprite;
				}
				&#instagram {
					background-image: $jv-btn-insta-sprite;
				}
				&:hover {
					background-position: 43px 0;
				}
			}
		}
	}

	#content {
		margin-top: 30px;
	}

	.menu.f-right {
		margin: 60px auto 30px auto;
	}

	// footer
	.b-017 {
		margin: 0;
		background-color: transparent;
	}

		#footer-cro-logo {
			text-align: center;
			margin-bottom: 30px;
			p {
				margin: 5px 0;
			}
			img {
				max-width: 125px;
			}
		}



	//
	// Casova osa b-200 a jeji itemy
	//
	.b-200 {

		// resi odsazeni pro nacteni dalsi varky Infinity Scroll Buttonem
		/*.b-200__section:not(:first-child) {
			margin-top: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 3em;
			}
		}*/

		// itemy
		.b-200a,
		.b-200b,
		.b-200c,
		.b-200d {

			// block
			.b-200a__block,
			.b-200b__block,
			.b-200c__block,
			.b-200d__block {
				background-color: $color_background-2;
			}

				// titulek
				.b-200a__block h3,
				.b-200b__block h3,
				.b-200c__block h3,
				.b-200d__block h3 {
					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0.2em;

						font-size: 20px;
						line-height: 24px;
					}
				}

				// timestamp
				.b-200a__timestamp,
				.b-200b__timestamp,
				.b-200c__timestamp,
				.b-200d__timestamp {
					@include breakpoint($breakpoint_desktop) {
						font-size: 14px;
						line-height: 18px;
					}
				}
			}

		// audio item
		.b-200b {
			.b-041k {
				br {
					display: none; // nechtenny <br>
				}
			}
		}
	}



	//
	// Fixy
	//
	.b-016__wrapper {
		h1,
		h2 {
			display: none;
		}
	}



	//
	// bootstrap fixes
	//

	// .container - viewport reset
	.container {
		@media (min-width: 1200px) {
			//width: auto;
		}
		@media (min-width: 992px) {
			width: auto;
		}
		@media (min-width: 768px) {
			width: auto;
		}
	}

	// fix class CRo .badge vs Bootstrap .badge
	.badge {

		// CRo _badge.scss
		/*display: inline-block;
		width: 1.5em;
		height: 1.5em;
		margin: 0.5em 0.5em 0 0;
		background-position: center;
		background-repeat: no-repeat;*/

		// Bootstrap bootstrap.min.css
		/*display: inline-block;
		min-width: 10px;
		padding: 3px 7px;
		font-size: 12px;
		font-weight: bold;
		color: #fff;
		line-height: 1;
		vertical-align: middle;
		white-space: nowrap;
		text-align: center;
		background-color: #777;
		border-radius: 10px;*/

		// RESET
		display: inline-block;
		min-width: auto;
		padding: 0;
		font-size: inherit;
		line-height: inherit;
		font-weight: normal;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
		color: transparent;
		background-color: transparent;
		border-radius: 0;
	}

	// fix class CRo .pager vs Bootstrap .pager
	.pager {
		margin-top: 2em;
	}

	.pager li {

		// Bootstrap bootstrap.min.css
		/*display: inline;*/

		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: flex;
		}

		&.pager__item--current,
		&.pager__item--next,
		&.pager__item--previous {
			display: flex;

		}
	}

	.pager li > a,
	.pager li > span {

		// Bootstrap bootstrap.min.css
		/*display: inline-block;
		padding: 5px 14px;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 15px;*/

		display: flex;
		padding: 0;
		background-color: transparent;
		border: none;
		border-radius: 0;
	}

	.pager li > a:hover, .pager li > a:focus {

		// Bootstrap bootstrap.min.css
		/*text-decoration: none;
		background-color: #eee;*/

		background-color: transparent;
	}



	//
	// Buttony + Progress bar
	//

	// na hp vyzaduje upravu .main a preneseni jeho pravidel na jeho deti
	/*&.page-pribehy-jeziskovavnoucata-hp .main {
		padding: 0;

		.b-200 {
			padding: 0 10px;

			@include breakpoint($breakpoint_desktop) {
				padding: 0 40px;
			}
		}
	}*/

	// na hp uprava gridu kvuli pretekajicimu .buttons-progress__wrapper
	&.page-pribehy-jeziskovavnoucata-hp .l-grid--12-1.item-first {
		overflow: visible;
	}

	// wrapper
	.buttons-progress__wrapper {
		position: relative; // kvuli absolutnimu pozicovani .shares (u nove hlavicky .top-header-v2)

		margin: 0 -25px;

		@include breakpoint($breakpoint_desktop) {
			//margin: 0 -40px; // kvuli nove hlavicce .top-header-v2)
		}

		// buttony
		.row.main-links {
			margin-top: 30px;
			.btn {
				background-color: #169eda;
				font-size: 28px;
				font-weight: bold;
				width: 100%;
				color: white;
				border-radius: 0;
				padding: 18px 10px;
				@media all and (max-width: 1200px) {
					font-size: 16px;
				}
				@media all and (max-width: 767px) {
					margin-bottom: 10px;
					font-size: 22px;
				}
				span {
					font-size: 33px;
					line-height:  0;
					@media all and (max-width: 1200px) {
						font-size: 21px;
					}
					@media all and (max-width: 767px) {
						font-size: 27px;
					}
				}
				&:hover {
					color: $color_background-7;
				}
			}
		}

		// progress bar
		#progress {
			padding: 0 15px;

			.progress {
				height: auto;
				border-radius: 0;
				margin-top: 30px;

				.progress-bar {
					text-align: center;
					height: 60px;
					line-height: 60px;
					font-weight: bold;
					font-size: 20px;
					color: white;
					@media all and (max-width: 1200px) {
						font-size: 16px;
					}
					@media all and (max-width: 767px) {
						font-size: 18px;
					}
					@media all and (max-width: 600px) {
						width: 100% !important;
					}
					.percentage {
						float: none;
						color: #08384e;
						font-size: 28px;
						margin-right: 10px;
						@media all and (max-width: 1200px) {
							font-size: 24px;
						}
						@media all and (max-width: 767px) {
							font-size: 26px;
						}
					}
					.separator,
					.number {
						@media (max-width: 992px) {
							display: none;
						}
						@media all and (max-width: 767px) {
							display: none;
						}
						@media all and (max-width: 600px) {
							display: inline;
						}
					}
				}
			}
		}

		// shares pouze na desktopovem viewportu
		// v novem umisteni v markupu kvuli nove hlavicce .top-header-v2
		.shares {
			display: none; // mobil ne

			@include breakpoint($breakpoint_desktop) {
				display: block; // desktop ano

				position: absolute;
				right: 25px;
				bottom: -90px;

				width: 94px;
				height: 50px;
			}
			.share-item {
				width: 43px;
				height: 43px;
				float: right;
				background-size: 86px 43px;
				background-position: left;
				&#facebook {
					margin-right: 8px;
					background-image: $jv-btn-fb-sprite-grey;
				}
				&#instagram {
					background-image: $jv-btn-insta-sprite-grey;
				}
				&:hover {
					background-position: 43px 0;
				}
			}
		}
	}

	// player fixes
	.file-audio {
		.sm2-bar-ui {
			// uprava velikosti ikon play/pause
			.sm2-button-element.sm2-play-pause-wrapper .sm2-button-bd {
				width: 60px;
				height: 60px;
				min-width: 60px;
				min-height: 60px;
				@include breakpoint($breakpoint_desktop) {
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
				} 
			}
			// uprava pozice duration
			.sm2-time-wrapper {
				margin-left: 80px;
				@include breakpoint($breakpoint_desktop) {
					margin-left: 70px;
				}
			}
			// uprava delky progressbaru
			.sm2-progress {
				@include breakpoint($breakpoint_desktop) {
					max-width: calc(100% - 155px)
				}
			}
			// uprava pozice progressbaru
			.sm2-controls-wrapper {
				margin-left: 20px;
			} 
		}
	}
	// sirka metadat
	&.page-pribehy-jeziskovavnoucata-hp .b-041k__metadata {
		max-width: 100%;
	}



	/*
	 * docasna hlavicka rijen 2019 - begin
	 *
	 * markuv v /sites/all/themes/custom/e7/templates/region/region--header--jeziskovavnoucata.tpl.php
	 */
	.top-header-v2 { display: none; }
	.top-header-v3 {
		margin: 35px 0;

		@include breakpoint($breakpoint_desktop) {
			margin: 20px 25px;
		}

		.logo {
			display: flex;
			flex-direction: column;
			align-items: center;

			padding: 20px 30px;

			background-color: #529fd7;

			img {
				max-width: 667px;
			}
		}

		.top-header-v3__meta-wrapper {
			margin: 30px 0;

			.top-header-v3__meta-para {
				display: block;

				font-size: 18px;
				line-height: 24px;
				text-align: center;

				color: #529fd7;

				@include breakpoint($breakpoint_desktop) {
					font-size: 24px;
					line-height: 30px;
				}
			}

			.top-header-v3__meta-para--perex {
				font-weight: bold;
			}
		}
	}

	.buttons-progress__wrapper {

		.row {

			&.main-links,
			&#progress {
				display: none;
			}
		}
	}
	/*
	 * docasna hlavicka rijen 2019 - end
	 */

}
