//
// Styles JV - Vars & Files
//



// Icons
$jv-logo-cro: url('../images/jeziskova_vnoucata/logo_CRo.svg');
$jv-logo-jv: url('../images/jeziskova_vnoucata/logo_jeziskovavnoucata.svg');
$jv-icons-svg: url('../images/jeziskova_vnoucata/icons-svg.svg');
$jv-btn-fb-sprite: url('../images/jeziskova_vnoucata/btn_fb_sprite.svg');
$jv-btn-insta-sprite: url('../images/jeziskova_vnoucata/btn_insta_sprite.svg');
$jv-btn-fb-sprite-grey: url('../images/jeziskova_vnoucata/btn_fb_sprite_grey.svg');
$jv-btn-insta-sprite-grey: url('../images/jeziskova_vnoucata/btn_insta_sprite_grey.svg');
$jv-hp-top-bg-overlay: url('../images/jeziskova_vnoucata/hp-top_bg_overlay.png');

// Slides
$jv-slide-1: url('../images/jeziskova_vnoucata/sl1.jpg');
$jv-slide-2: url('../images/jeziskova_vnoucata/sl2.jpg');
$jv-slide-3: url('../images/jeziskova_vnoucata/sl3.jpg');
