//
// Radiozurnal Sport - header
//



// desktop vertical breakpoints
$sport_header_breakpoint_desktop-vert-mid: $b-113_breakpoint_desktop-vert-mid;
$sport_header_breakpoint_desktop-vert-low: $b-113_breakpoint_desktop-vert-low;

$sport_header: ("radiozurnal-sport", ".sport", "../images/bg/sport/sport_desktop.jpg", "100%", 3, 1, 2, 4);


#{$sport_selector_body-hp} {

	// init
	.header__visual-wrapper.header__visual-wrapper--#{nth($sport_header, 1)},  // list: campaign name
	.header__visual-wrapper.header__visual-wrapper--#{nth($sport_header, 1)}::before { // list: campaign name
		display: block;
	}

	header {
		display: flex; // kvuli razeni elementu uvnitr
		flex-direction: column;
	}

	// top nav
	.b-014a {
		order: 1;
	}

	// top nav bubble
	.b-014b {
		order: 2;
	}

	// header wrapper
	.header__wrapper {
		order: 3;

		position: relative;

		display: flex; // kvuli razeni elementu uvnitr
		flex-direction: column;
	}

		// visual header
		.header__visual-wrapper {
			order: #{nth($sport_header, 5)}; // list: visual order
		}

		// station header
		.b-015__holder {
			order: #{nth($sport_header, 6)}; // list: player order
		}

		// station nav
		.b-016__wrapper {
			order: #{nth($sport_header, 7)}; // list: nav order
		}

		// button next-floor
		.button__wrapper--next-floor {
			order: #{nth($sport_header, 8)}; // list: nav order
		}


	// top nav
	.b-014a {
	}

	// top subnav
	.b-014b {
	}

	// header wrapper
	.header__wrapper {
		min-height: calc(100vh - 48px); // 100% - topnav (bylo 40px)

		@include breakpoint($breakpoint_desktop) {
			min-height: calc(100vh - 72px); // 100% - topnav (bylo 56px)
		}
	}

		// visual header
		.header__visual-wrapper {
			&.header__visual-wrapper--#{nth($sport_header, 1)} { // list: campaign name
				flex-grow: 1;

				display: flex;
				flex-direction: column;
			}
		}

			// visual header image
			.header__visual-image {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				width: 100vw;
				height: #{nth($sport_header, 4)}; // list: bg height

				//background-color: rgba($color_sport_main, 0); // overlay barva 0%

				// HACK: nez se poprve pohne desktop grid
				@media screen and (min-width: $layout_page_max-width) {
					left: calc(((100vw - #{$layout_page_max-width}) / 2) * -1);
				}
			}

				// visual header image list
				.header__visual-image-list {
					position: relative;

					width: 100%;
					height: 100%;

					z-index: -1;
				}

				// visual header image list: fallback bg
				.header__visual-image-list::before {
					content: '';

					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;

					background-image: url('#{nth($sport_header, 3)}'); // list: url
					background-repeat: no-repeat;
					background-size: cover;
					background-attachment: scroll;
					background-position: left top;
				}

				// visual header image list: gradient
				.header__visual-image-list::after {
					content: '';

					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;

					// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
					//background-image: linear-gradient(to top, $color_radiozurnal_main, rgba(#ed2e38, 0) 40%); // do stransparentni
					background-image: linear-gradient(to top, $color_radiozurnal_main, rgba($color_background-9, 0.3) 40%); // do cerne 30%
				}

					// visual header image list item
					.header__visual-image-list-item {
						content: '';

						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center top;
						}
					}


				// visual header image legal
				.header__visual-image-legal {
					position: absolute;
					bottom: -20px;
					right: 0;

					padding: 4px 8px;
					height: 20px;

					font-size: 10px;
					line-height: 1.3;

					color: $color_background-5;

					transition: opacity 0.3s ease-in-out;

					&.invisible {
						opacity: 0;
					}
				}


			// visual header content
			.header__visual-content {
				position: relative;

				flex-grow: 1;

				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
			}

				// visual header block
				.header__visual-block {
					//margin-top: 1em;
					margin-top: 10px;

					@include breakpoint($breakpoint_desktop) {
						//margin-top: 2em;
						margin-top: 10px;
					}
				}

					// visual header block: player
					.header__visual-block--player {
						flex-grow: 1;

						display: flex;
						flex-direction: column;
						justify-content: center;

						width: 80%;
						margin-left: auto;

						@include breakpoint($breakpoint_tablet) {
							width: 50%;
							margin-right: 0;
						}

						@include breakpoint($breakpoint_desktop) {
							width: 35%;
							margin-right: 175px;
						}

						// desktop mid height
						@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
							width: 33%;
						}

						// desktop low height
						@include breakpoint($b-113_breakpoint_desktop-vert-low) {
							width: 33%;
						}
					}

					// visual header block: ad
					.header__visual-block--ad {
						position: relative;

						width: calc(100% - (2 * 20px)); // odecten margin-left, margin-right
						max-width: 580px;
						@include aspect-ratio(580, 193);

						margin-top: calc(10px + 30px); // defaultmargin + .b-099e__dots-list height
						margin-left: 20px;
						margin-right: 20px;

						background-color: $color_background-9_alpha-high;
						border-radius: 6px;
						box-shadow: $shadow_light;

						//overflow: hidden;

						@include breakpoint($breakpoint_desktop) {
							width: 100%;

							margin-left: 0;
							margin-right: 0;
						}

						// b-099d, b-099e
						.b-099d,
						.b-099e {
							position: absolute;
							top: 0;
							left: 0;

							margin-top: 0;
						}

						.b-099d {
							height: 100%;
							border-radius: 6px;
							overflow: hidden;
						}

						.b-099e {
							&__dots-list {
								position: absolute;
								top: -30px;
								left: 50%;
								transform: translateX(-50%);
							}

							&__subsection {
								border-radius: 6px;
							}
						}
					}

					// b-099d--link, b-099e--link
					.header__visual-block--ad-link {
						@include breakpoint($breakpoint_desktop) {
							transition: transform 0.2s ease, box-shadow 0.2s ease;
						}
					}

					// b-099d--link: hover, b-099e--link: hover
					.header__visual-block--ad-link:hover {
						@include breakpoint($breakpoint_desktop) {
							transform: scale(1.01);
							box-shadow: $shadow_popup;
						}
					}


		// button next-floor
		.button__wrapper--next-floor {
			margin-top: 0;

				@include breakpoint($breakpoint_desktop) {
				margin-top: 0.5em; // default 1.5em
			}

			.button-box-flat--loadmore {
				.button-box-flat__section {
					.button.button-box-flat {
						margin: 0;
						background-color: transparent;
					}
				}
			}
		}


		// station header
		.b-015 {
		}

		// station nav
		.b-016__wrapper {
		}


	// main
	.main {
		padding-top: 0.1px;
		margin-top: 0;
	}

	// clearfix
	.main::after {
		clear: both;
		content: '';
		display: table;
	}

}
