//
// B-042 Styles
//



.b-042 {
	position: relative;
}
	
	.b-042__list {
	}
		
		.b-042__list-item {
		}
		
			.b-042__block {
				position: relative;
			}
				
				.b--042_image {
				}
				
				.b-042__list-item .b-042__overlay {
					position: absolute;
					bottom: 0;
					left: 10px;
					
					width: 90%;
					
					background-color: $color_background-8_alpha-low;
				
					@include breakpoint($breakpoint_desktop) {
						right: 0.5em;
					}
				}
	
	
	.b-042__list-item .b-042__overlay-content {
		padding: 0.5em;
		
		h3 {
			color: $color_decoration_main;
			
			margin: 0;
		}
		
		p {
			color: $color_text_accent_inverted;
			
			margin: 0 0 0.25em 0;
		}
	}