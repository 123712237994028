//
// Infinity Scroll Button
//


// load more button / infinity scroll button
.button-box-flat--loadmore.pager--infinite-scroll {
	position: relative;

	display: flex;
	justify-content: center;

	margin-top: 4em;

	height: 4.25em;

	@include breakpoint($breakpoint_desktop) {
		margin-top: 4em;
		margin-left: 0;
		margin-right: 0;

		height: 3.5em;
	}

	.button-box-flat__doubleline {
		display: none;
	}

	.button-box-flat {
		position: relative;
	}

	.button-box-flat__label-nest {
		position: absolute;
		top: -2em;
		left: -2.25em;

		width: 9em;

		color: $color_text_paragraph;

		@include breakpoint($breakpoint_desktop) {
			left: -3.5em;
			width: 10.5em;
		}
	}

	// throbber
	.ajax-progress.ajax-progress-throbber {
		display: flex; // default je display: none viz _shame.scss
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		.throbber {
			box-sizing: content-box; // viz _button/box-flat.scss

			width: 3em; // viz _button/box-flat.scss
			height: 3em; // viz _button/box-flat.scss

			padding: 0.75em; // viz _button/box-flat.scss
			margin: 0 1em 0 1em; // viz _button/box-flat.scss

			@include adjust-font-size-to($fontsize_form_flat_button); // viz _button/box-flat.scss`

			background-color: $color_background-6_alpha-low;
			background-image: url('../images/extras/throbber/throbber.svg');
			background-position: center;
			background-repeat: no-repeat;
			//background-size: calc(100% - 0.75em * 2); // 0.75em padding
			background-size: calc(100% - 3em);

			@include breakpoint($breakpoint_desktop) {
				width: 2.5em; // viz _button/box-flat.scss
				height: 2.5em; // viz _button/box-flat.scss

				padding: 0.5em; // viz _button/box-flat.scss

				@include adjust-font-size-to($fontsize_form_flat_button_desktop); // viz _button/box-flat.scss

				//background-size: calc(100% - 0.5em * 2); // 0.5em padding
				background-size: calc(100% - 2em);
			}
		}

		.message { display: none;}
	}


}



// addon - přidá k default variantě double-linku
.wave.page-wavenews,
.neviditelna.page-node .b-047,
.jeziskova-vnoucata {

	// left & right lines k infinity scroll buttonu
	.button-box-flat--loadmore.pager--infinite-scroll {

		// cely jen na desktopu
		@include breakpoint($breakpoint_desktop) {
			height: 7.5em;
			overflow: hidden;
			//margin-right: -55%; //pokud ma byt pres celou sirku

			// left & right lines
			.button-box-flat::before,
			.button-box-flat::after {
				content: "";

				position: absolute;

				border-color: $color_background-4;
				border-style: solid;
				border-width: 1px 0;

				display: block;

				width: 100em;
				height: 0.75em;

				top: 20px;
			}
			// left lines
			.button-box-flat::before {
				right: 70px;
			}
			// right lines
			.button-box-flat::after {
				left: 70px;
			}
		}
	}

}



// specifika konkrétních umístění
.wave.page-wavenews,
.neviditelna.page-node .b-047 {
	.button-box-flat--loadmore.pager--infinite-scroll {
		@include breakpoint($breakpoint_desktop) {
			margin-right: -55%; //pokud ma byt pres celou sirku
		}
	}
}

.jeziskova-vnoucata {
	.button-box-flat--loadmore.pager--infinite-scroll {
		.button-box-flat {
			border-radius: 0; // reset bootstrap.min.css
		}
		.button-box-flat__label-nest {
			display: none;
		}
	}
}
