/*
 * PBR - Přihlašovací formuulář - upload page
 */

.prixbohemia .page.pbr-upload {

  h2.pbr-upload__title {
    margin: 1em 0;
    @include typography_font(bold);
    text-transform: uppercase;
    color: $color_corporate_main;
  }

  strong {
    @include typography_font(medium);
  }


}
