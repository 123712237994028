//
// B-041k Styles - Audioplayer Metadata + Anchor Styles
//


$b-041k_padding: 20px;
$b-041k_ico_background-size: 200%;
$b-041k_border-width: 1px;


.b-041k {
	&__metadata {
		border: $b-041k_border-width solid $color_divider;
		border-top: none;
		background-color: $color_background-0;
		padding: 10px;
		position: relative;
		@include breakpoint($breakpoint_tablet) {
			padding: 20px;
		}
	}
	&__wrap {
		@include breakpoint($breakpoint_tablet) {
			display: inline-block;
			max-width: calc(100% - 180px);
		}
	}
	&__item {
		line-height: 1;
		@include breakpoint($breakpoint_tablet) {
			line-height: 1.35;
		}
	}
	&__audio-title {
		color: $color_decoration_main;
		font-size: 1rem;
		line-height: 1.2;
		margin-bottom: 4px;
		@include breakpoint($breakpoint_tablet) {
			font-size: 1.35rem;
		}
	}
	&__availability-title,
	&__audio-length-title,
	&__description-title,
	&__availability-value,
	&__audio-length-value,
	&__description-value,
	&__description-value > * {
		font-size: 0.75rem;
		color: $color_background-5;
		@include breakpoint($breakpoint_tablet) {
			font-size: 0.9rem;
		}
		a {
			color: $color_decoration_main;
		}
	}
	&__availability-value,
	&__audio-length-value,
	&__description-value {
		color: $color_background-9;
	}
	&__link:link,
	&__link:visited {
		color: $color_decoration_main;
	}
	&__buttons {
		margin-top: 20px;
		@include breakpoint($breakpoint_tablet) {
			margin-top: 0;
			position: absolute;
			right: 15px;
			bottom: 15px;
		}
	}
	&__buttons > * {
		margin-right: 4px;
	}
	&__anchor {
		border: $b-041k_border-width solid $color_divider;
		padding: $b-041k_padding;
		margin: 20px 0;
		width: 100%;
		max-width: calc(100% - 2*#{$b-041k_padding} - 2*#{$b-041k_border-width});
		min-height: 30px;
		display: flex;
		align-items: center;
		position: relative;
		@include breakpoint($breakpoint_tablet) {
			margin: 40px 0;
			max-width: calc(370px - 2*#{$b-041k_padding} - 2*#{$b-041k_border-width});
		}
		@include breakpoint($breakpoint_desktop) {
			margin-left: calc(4.375rem + 1.875rem);
		}
		&:link,
		&:visited {
			color: $color_decoration_main;
		}
		&::after {
			content: '';
			background-image: $file_button_action_play;
			background-size: $b-041k_ico_background-size;
			background-repeat: no-repeat;
			background-position: 0;
			display: flex;
			width: 32px;
			height: 32px;
			position: absolute;
			right: 20px;
			top: 15px;
		}
		&:hover {
			&::after  {
				background-position: 100%;
			}
		}
	}
}

// barva titulku u budoucich a expirovanych audii
.future + .b-041k,
.expired + .b-041k {
	&__audio-title {
		color: $color_background-6;
	}
}



// metadata label
// default label 'foto:' nechceme, text stejne odstranuje cro_soundmanager.module, ale nechava obal a s nim i margin
.b-041k .b-041k__metadata .b-041k__description-value .img-metadata__label {
	display: none;
}
