//
// B-038 Styles
//


// timing block flex basis
$b-038_timing_block-width-basis: 10em;

// progress bar
$b-038_timing-progressbar-height-desktop: 0.35em;
$b-038_timing-progressbar-height-mobile: 0.5em;

// font sizes, putting this into _typography.scss?
$fontsize_program_calendar: 14px;
$fontsize_program_calendar_desktop: 16px;

$fontsize_program_links: 14px;
$fontsize_program_links_desktop: 14px;

$fontsize_program_date: 12px;
$fontsize_program_date_desktop: 14px;

$fontsize_program_time: 18px;
$fontsize_program_time_desktop: 18px;

$fontsize_actionbuttons: 14px;



.b-038 {
}

	.b-038 h2 {
		display: none;

		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			display: block;

			font-size: $fontsize_h1_desktop;
			@include typography_font(bold);

			padding-left: 0;
			margin-bottom: 0.75em;
		}
	}

	.b-038__navbar-wrapper {
		position: relative;
	}

	.b-038__navbar-wrapper.fixed {
		position: fixed;
		top: 0;
		left: 0;

		width: 100%;

		margin: 0;

		box-shadow: $shadow_bubble;
		transition: top 0.35s ease;
		z-index: 99;

		.b-038__navbar {
			margin: 0;
			opacity: 0.95;
		}
	}

	.b-038__navbar-wrapper.fixed.delayed {
		top: 0;
	}

	.b-038__navbar-wrapper.scrolled-down.fixed.delayed {
		top: -48px; // vyska navbar na mobilnim viewportu

		@include breakpoint($breakpoint_desktop) {
			top: 0;
		}
	}

	.b-038__navbar {

		display: flex;
		align-items: stretch;

		padding: 0.5em 0;
		margin-bottom: 2.5em;

		background-color: $color_background-6; // prebiji se barvou stanice samo dle rodice
	}

		.b-038__navbar-item {
			flex-grow: 1;
		}

		.b-038__navbar-item--previous {
		}

		.b-038__navbar-item--next {
		}

			.b-038__navbar-item a {
				display: block;
			}

			.b-038__navbar-item--previous a {
				float: right;
			}

			.b-038__navbar-item--previous a,
			.b-038__navbar-item--next a {

				width: 100%;
				height: 100%;
			}
			.b-038__navbar-item--previous a::after,
			.b-038__navbar-item--next a::after {

				content: "";

				display: block;

				width: 100%;
				height: 100%;

				height: 2em; // Safari Fix
				@include breakpoint($breakpoint_desktop) {
					height: 2.2em; // Safari Fix
				}

				background-image: $file_icon_arrow_single_east_colored-white;
				background-repeat: no-repeat;
				background-position: center left 0.75em;
				background-position-x: left 0.75em; // IE safe positioning
				background-position-y: center;
				background-size: 0.75em 0.75em;
			}

			.b-038__navbar-item--previous a::after {

				transform: rotate(180deg);
			}

			.b-038__navbar-item--next a::after {
			}

				.b-038__navbar-item a span {
					display: none;
				}


		.b-038__navbar-item--calendar {
			flex-grow: 0;

            min-width: 50%; // fixni velikost okna s datumem

			padding: 0.25em 1em;

			background-color: $color_background-2;

			font-size: $fontsize_program_calendar;

			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;

			@include breakpoint($breakpoint_desktop) {
				min-width: 35%; // fixni velikost okna s datumem

				font-size: $fontsize_program_calendar_desktop;
			}
		}

			.b-038__navbar-item--calendar a {
				text-align: center;

				@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
			}

			.b-038__navbar-item--calendar strong {
				@include typography_font(medium);

				color: $color_text_accent;
			}

				.b-038__navbar-item--calendar strong:before {
					background-image: $file_icon_calendar_colored-black;
					background-repeat: no-repeat;

					content: "";

					display: inline-block;

					position: relative;
					top: 0.15em;

					width: 1.25em;
					height: 1.25em;

					margin-right: 0.5em;
					margin-left: 0.25em;
			}


	.b-038__links {
		display: flex;

		margin-bottom: 1em;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 1.5em;
		}
	}

		.b-038__links-item {
			flex-grow: 1;

			font-size: $fontsize_program_links;

			padding: 0 0.5em;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_program_links_desktop;
			}
		}

		.b-038__links-item a {
			display: inline-block;

			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}

		.b-038__links-item--previous {
		}

			.b-038__links-item--previous a {
				padding-right: 1.25em;

				background-image: $file_icon_arrow_single_north_colored-decoration-main;
				background-repeat: no-repeat;
				background-position: 100% 9px;
				background-size: 0.75em auto;
			}

				.listing-past .b-038__links-item--previous a {
					background-image: $file_icon_arrow_single_south_colored-decoration-main;
				}


		.b-038__links-item--archiv {
			text-align: right;
		}

			.b-038__links-item--archiv a {
				padding-right: 1em;

				background-image: $file_icon_arrow_single_east_colored-decoration-main;
				background-repeat: no-repeat;
				background-position: 100% 6px;
				background-size: auto 0.75em;
			}


	.b-038__info--date {
		display: inline-block;

		font-size: $fontsize_program_date;

		color: $color_text_paragraph;

		padding-left: 0.5em;
		padding-bottom: 1em;

		@include breakpoint($breakpoint_desktop) {
			font-size: $fontsize_program_date_desktop;

			padding-bottom: 0.5em;
		}
	}

	.b-038__list {
		margin-bottom: 2em;
	}

		.b-038__list-item {
			display: flex;
			flex-direction: column;

			padding: 1em;

			border-top: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				align-items: flex-start;

			}
		}

		.b-038__list-item--present {
			 background-color: $color_background-2;
		}

			.b-038__block {
			}

			.b-038__block--timing {
				display: flex;
				flex-wrap: no-wrap;
				flex-direction: row;
				align-items: flex-start;
				justify-content: space-between;

				@include breakpoint($breakpoint_desktop) {
					//flex-basis: $b-038_timing_block-width-basis;
					flex-shrink: 0;

					max-width: $b-038_timing_block-width-basis;
					//width: $b-038_timing_block-width-basis; // fix pro chybejici nebo mensi obrazky
				}
			}

			.b-038__list-item--present .b-038__block--timing {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: normal;

				@include breakpoint($breakpoint_desktop) {
					//flex-basis: $b-038_image-width-basis;
					//flex-shrink: 0;
				}
			}

				.b-038__info--time {
					font-size: $fontsize_program_time;

					@include breakpoint($breakpoint_desktop) {
						width: em(48, stripUnits($fontsize_program_time_desktop));
						font-size: $fontsize_program_time_desktop;
					}
				}

				.b-038__list-item--past .b-038__info--time {
					color: $color_background-6_alpha-mid;
				}

				.b-038__launcher-info-timing-progressbar {
					flex-grow: 1;

					display: block;
					height: $b-038_timing-progressbar-height-mobile;

					margin-left: 1em;

					background-color: $color_background-0;

					@include breakpoint($breakpoint_desktop) {
						height: $b-038_timing-progressbar-height-desktop;
					}
				}

					.b-038__launcher-info-timing-progressbar-progress {
						display: block;
						height: $b-038_timing-progressbar-height-mobile;

						background-color: $color_background-6; // prebiji se barvou stanice samo dle rodice

						// progress
						width: 33%;

						@include breakpoint($breakpoint_desktop) {
							height: $b-038_timing-progressbar-height-desktop;
						}
					}


				.b-038__image {
					display: none;

					@include breakpoint($breakpoint_desktop) {
						display: block;

						position: relative;

						width: 6em;

						margin-top: 0;
						margin-left: 1em;
					}

					// fix stridajicich se vice fotek
					.persons {
						height: 71px;

						li {
							width: 100%;
						}
					}
				}

				.b-038__list-item--present .b-038__image {

					@include breakpoint($breakpoint_desktop) {
						width: 100%;

						margin-top: 0.5em;
						margin-left: 0;
					}

					// fix stridajicich se vice fotek
					.persons {
						height: 93px;
					}
				}


			.b-038__block--description {
				flex-grow: 1;

				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-038__block--description h3 {
					margin-top: 1em;
					margin-bottom: 0.5em;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-038__block--description p {
					margin-top: 0.5em;
					margin-bottom: 1.0em;

					color: $color_text_paragraph;
				}

				.b-038__block--description.caption--public-after {
					.caption__icon {
						width: 1em;
						height: 1em;

						background-image: $file_icon_timer;

						margin-top: 0.25em;
					}

					.caption__label {
						padding-left: 0.5em;

						@include breakpoint($breakpoint_desktop) {
							@include adjust-font-size-to($fontsize_box_petit_main);
						}
					}
				}

				.b-038__actions {
				}

				.b-038__actions a {
					color: $color_decoration_main;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-038__actions .button-listaction {
						margin-right: 0.25em;
					}

					// odkaz na archiv poradu, leze z api, ale neni v zadani
					.b-038__actions .cro-buttons-archive-h12 {
						display: block;

						margin-top: -1em;

						font-size: $fontsize_actionbuttons;
					}

						.b-038__list-item--present .b-038__actions .cro-buttons-archive-h12 {
							display: none;
						}


					// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
					.b-038__actions .button-play {
						margin-top: 1em;
					}

						.b-038__actions .button-play .button__label {
							// zobrazeni popisku (stylovani ve vychozim stavu skryteho popisku je vlastnosti atomu)
							display: flex;
						}

							.b-038__actions .button-play .button__label span {
								// zobrazeni popisku (stylovani ve vychozim stavu skryteho popisku je vlastnosti atomu)
								white-space: nowrap;
							}

					// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
					.b-038__actions .button-listaction--podcast {
					}

						.b-038__actions .button-listaction--podcast .button-listaction__label {
							// zobrazeni popisku (stylovani ve vychozim stavu skryteho popisku je vlastnosti atomu)
							display: flex;
							align-items: center;

							height: 100%;
							margin-left: 100%;
							padding-left: 0.5em;

							font-size: $fontsize_actionbuttons;
						}


	.button-box-flat--loadmore {
	}



//
// B-038 Printer Styles
//

@media print {

	.b-038 {
		@include breakpoint($breakpoint_desktop) {
			max-width: 100%;
		}
	}

		.b-038__navbar.bg-color-main {
			background-color: transparent;
		}

			.b-038__navbar-item--previous a,
			.b-038__navbar-item--next a {
				display: none;
			}

			.b-038__navbar-item--calendar {
				background-color: transparent;
				color: $color_text_accent;

				box-shadow: none;
			}


		.b-038__links {
			display: none;
		}

		.b-038__list-item--present {
			 background-color: transparent;
		}

			.b-038__list-item--present .b-038__block--timing {
				flex-wrap: no-wrap;
				flex-direction: row;
				align-items: flex-start;
			}

				.b-038__list-item--past .b-038__info--time {
					color: $color_text_accent;
				}

				.b-038__launcher-info-timing-progressbar {
					display: none;
				}

					.b-038__launcher-info-timing-progressbar-progress {
						display: none;
					}


				.b-038__image {
					display: none;
				}

				.b-038__actions {
					display: none;
				}

					// odkaz na archiv poradu, leze z api, ale neni v zadani
					.b-038__actions .cro-buttons-archive-h12 {
						display: none;
					}

						.b-038__list-item--present .b-038__actions .cro-buttons-archive-h12 {
							display: none;
						}
}


// vizualni debug - plab only
.patternlab .b-038 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 750px;
	}
}
