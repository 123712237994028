//
// B-020 Styles
//



.b-020 {

	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	max-width: 400px;

}

	.b-020__subsection {

		padding: 1em;

		background-color: $color_background-2;

	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-020 .broadcast-times {

			margin-top: 0;
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-020 .broadcast-times h5 {

				display: none;
			}

    .b-020__no-broadcast-message {
      display: block;
      padding: 0.25em 1em;

      color: $color_text_accent_inverted;
      background-color: $color_decoration_alert;

      @include adjust-font-size-to($fontsize_box_petit_main);

      @include breakpoint($breakpoint_desktop) {
        @include adjust-font-size-to($fontsize_box_petit_main_desktop);
      }
    }
