//
// B-013 Styles
//



.b-013 {
}

	.b-013__subsection {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}

		// ciste debug default barva pozadi pro zobrazeni samostatneho atomu, pokud
		// nema rodicovsky obal, ktery jej kontextove obarvi tridou .bg-color-main
		background-color: $color_background-6;
	}

		.b-013__block--first {

			@include breakpoint($breakpoint_desktop) {

				flex-basis: 40%;
				flex-shrink: 0;
			}
		}

			// TODO k zamysleni: stridani formatu obrazku
			// otazka je je-li toto reseni spravne, pripadne jestli je to vubec v tomhle pripade potreba
			.b-013__image--3x2 {

				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}
			.b-013__image--16x9 {

				display: block;

				position: relative; // kvuli absolutnimu pozicovani stitku

				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-013__image--16x9 .tag {

					position: absolute;
					bottom: 0;
					left: 10px;

					max-inline-size: calc(100% - 20px);
				}


		.b-013__block--second {

			padding: 0.75em;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: column;
				flex-basis: 60%;

				padding: 1em;
				//margin-top: 1em;
			}

		}

			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-013__block--second > .tag {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;

					align-self: flex-start;

					margin-bottom: 1em;
				}
			}

			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-013__block--second > h3 {

				margin: 0;

				// TODO
				a {
					@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
				}
			}

			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-013__block--second > a {

				@include link-style($color_background-2, none, $color_background-2, underline);

				// TODO
				p {

					display: inline-block;
					margin: 0.25em 0 0 0;

					// docasne, ay probehne Alesuv pixel-final-tunning-polish, dame pryc
					line-height: 1.4em;
				}
			}

			// clearfix
			.b-013__block--second > a:after {
				clear: both;
				content: "";
				display: table;
			}

			// readmore
			.b-013__block--second .button-box-flat--readmore {
				margin-top: 1em;

				background-color: transparent;
				border-color: $color_text_link_inverted;

				@include breakpoint($breakpoint_desktop) {
					//display: inline-flex;
					align-self: flex-start;

					margin-top: auto;
					//margin-right: auto;
				}
			}

				.b-013__block--second .button-box-flat--readmore .button-box-flat__label {
					border-right-color: $color_text_link_inverted;

					@include breakpoint($breakpoint_desktop) {
						padding: 0.25em 0.5em;
					}
				}


			.b-013__block--second .button-box-flat--readmore .button-box-flat__icon--arrow-double-east {
				background-image: $file_icon_arrow_double_east_colored-white;
			}


		// tag obecne
		// je na dvou pozicich desktop vs mobil
		.b-013__block--first .tag,
		.b-013__block--second .tag {
			background-color: $color_decoration_main;
		}



//
// B-013a Styles
//



.b-013a {
}





//
// B-013b Styles
//



.b-013b {
}

	.b-013__subsection .caption {
		margin-bottom: 0.5em;
	}

		.b-013__subsection .caption .caption__icon {

			background-image: $file_icon_sandtimer_colored-white;
		}

		.b-013__subsection .caption .caption__label {
			color: $color_text_accent_inverted;
		}


	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	.b-013b .button-play {
		background-image: $file_button_play_colored-white;

		margin-top: 1em;

		.button__label {
			// zobrazeni popisku (stylovani ve vychozim stavu skryteho popisku je vlastnosti atomu)
			display: flex;
		}
	}

	.b-013b .button-play::after {
		background-image: $file_button_play_colored-white;
	}





//
// B-013c Styles
//



.b-013c {
}

	// opusteno, readmore je uz v defaultni b-013
	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	/*.b-013c .button-box-flat--readmore {

		margin-top: 1em;

		display: inline-flex;

		background-color: transparent;
	}*/


//
// B-013d Styles
//



.b-013d {
}

	// margin pro delsi texty, aby nezasahovaly do tlacitka
	.b-013d .b-013__block.b-013__block--second p {
		margin-bottom: .25em;
	}

	// ochranne zony tlacitka dle graf. navrhu
	.b-013d .box.b-019.b-019c {
		margin-top: 1em;
		margin-bottom: 3px;
		@include breakpoint($breakpoint_desktop) {
			align-self: flex-start;
    	margin-top: auto;
		}
	}

	// uprava velikosti ikony
	.b-013d .b-019__button--subscribe .button-listaction {
		width: 2.5em;
		height: 2.5em;
	}
	
	// barva textu odkazu u buttonu Odebirat
	.b-013d .b-019__button--subscribe .button-listaction__label .button-listaction__label-nest {
		color: $color_background-0;
	}
