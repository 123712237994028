//
// b-wave-zkouskovy-profiles Styles
//



//vars
$wave-zkouskovy-profiles_color_background_tag: rgba(#f3999a, 0.9); // ruzova - epocha 1
$wave-zkouskovy-profiles_color_background_tag-epoch-2: rgba(#ecbb0e, 1); // zlata - epocha 2


// default, epocha 1 - 2020
.b-wave-zkouskovy-profiles {
}

	// list
	.b-wave-zkouskovy-profiles__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@include breakpoint($breakpoint_desktop) {
			flex-wrap: nowrap;
		}
	}

	// item
	.b-wave-zkouskovy-profiles__list-item {
		position: relative;

		// trik jak 3 itemy radku + jejich margin roztahnout na 100%
		// ale ne pokud jich neni na radek plny pocet
		flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
		flex-grow: 1; // roztahne se pomoci grow do tretin
		max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
		max-width: calc(33.33% - 0.6667px); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
		// proc - 0.6667px? protoze je to 2 * 1px / 3 viz (*)

		margin-right: 1px; // (*)
		//margin-bottom: 1px;
		margin-bottom: auto;

		// IE
		min-height: 1px;

		@include breakpoint($breakpoint_desktop) {
			flex-basis: initial; // reset
			flex-grow: initial; // reset
			max-width: none; // reset

			//margin-bottom: 0;
			margin-bottom: auto;
		}
	}

	// item: last
	.b-wave-zkouskovy-profiles__list-item:last-child {
		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}

	// item: every 3th
	.b-wave-zkouskovy-profiles__list-item:nth-child(3n) {
		margin-right: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-right: 1px;
		}
	}

	// item: mobile positioning / 2nd
	.b-wave-zkouskovy-profiles__list-item:nth-child(2) {
		margin-top: 1.5em;
		margin-bottom: 1px;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
			margin-bottom: auto;
		}
	}
	// item: mobile positioning / 4th
	.b-wave-zkouskovy-profiles__list-item:nth-child(4) {
		margin-top: -1.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
	}
	// item: mobile positioning / 6th
	.b-wave-zkouskovy-profiles__list-item:nth-child(6) {
		margin-top: -1.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
	}

	// item: desktop positioning / every 2nd
	.b-wave-zkouskovy-profiles__list-item:nth-child(2n) {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 1em;
		}
	}

		// hover effect
		.b-wave-zkouskovy-profiles__list-item .b-wave-zkouskovy-profiles__image img {
			@include breakpoint($breakpoint_desktop) {
				transition: transform 0.5s;
			}
		}
		.b-wave-zkouskovy-profiles__list-item:hover .b-wave-zkouskovy-profiles__image img {
			@include breakpoint($breakpoint_desktop) {
				transform: scale(1.1);
			}
		}

		// overlay
		.b-wave-zkouskovy-profiles__overlay {
			display: flex;
			align-items: flex-end;
			justify-content: center;

			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.tag {
				margin-bottom: 80px;

				max-inline-size: calc(100% - 20px);

				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				@include typography_font(medium);
				font-size: 14px;

				color: $color_text_accent_inverted;
				// defaultni barva stitku, bude pretizena barvou stanice
				//background-color: $color_background-3_alpha-low;
				background-color: $wave-zkouskovy-profiles_color_background_tag;

				@include breakpoint($breakpoint_desktop) {
					font-size: 16px;
				}

				/*a {
					@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
				}*/

				span {
					color: $color_text_accent_inverted;
				}

			}
		}
		.b-wave-zkouskovy-profiles__overlay:hover {
			background-color: $color_background-0_alpha-high;
		}



// epocha 2 - 2021
.b-wave-zkouskovy-profiles {

	&--epoch-2 {

		// overlay
		.b-wave-zkouskovy-profiles__overlay {
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.tag {
				background-color: $wave-zkouskovy-profiles_color_background_tag-epoch-2;
			}
		}
	}
}





// vizualni debug - plab only
.patternlab .b-wave-zkouskovy-profiles {

	@include breakpoint($breakpoint_desktop) {
		max-width: 755px;
	}
}
