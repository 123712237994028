//
// Radiozurnal Sport - obecne vlastnosti vsech stranek
//



$sport_selector_body: 'body.sport';
$sport_selector_body-hp: $sport_selector_body + '.page-sport-hp';
$sport_selector_body-kamery: $sport_selector_body + '.page-kamery';
$sport_selector_body-player-embed: $sport_selector_body + '.page-player-embed';


#{$sport_selector_body} {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_sport_main, $color_sport_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_sport_main);
	}

	// station nav
	.b-016__wrapper {
		//display: none;
		z-index: 1; // FIX: ve FF prekryval .header__visual-image
	}

}
