//
// B-056 Styles
//



.b-056 {
}

	// h2: default
	.b-056 h2 {
	}

	// section
	.b-056__section {
		display: flex;
	}

		// list
		.b-056__list {
			display: flex;
			flex-direction: column;
			flex-grow: 1;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				flex-wrap: wrap;
			}
		}

			// item
			.b-056__list-item {
				margin-bottom: 1.5em;
				min-height: 1px; // IE

				@include breakpoint($breakpoint_desktop) {
					// trik jak 3 itemy radku + jejich margin roztahnout na 100%
					// ale ne pokud jich neni na radek plny pocet
					flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
					flex-grow: 1; // roztahne se pomoci grow do tretin
					max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
					max-width: calc(33.33% - 1.33em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
					// proc - 1.33em? protoze je to 2 * 2 / 3 viz (*)

					margin-right: 2em; // (*)
				}
			}

			// item: every 3th
			.b-056__list-item:nth-child(3n) {
				@include breakpoint($breakpoint_desktop) {
					margin-right: 0;
				}
			}

			// item: last 3 items
			.b-056__list-item:nth-last-child(-n+3) {
				@include breakpoint($breakpoint_desktop) {
					margin-bottom: 0;
				}
			}

			// list: last item
			.b-056__list-item:last-child {
				margin-bottom: 0;

				@include breakpoint($breakpoint_desktop) {
					margin-right: 0;
				}
			}


			/**
			 * default - open/close
			 */

			// item: not first 1 items - mobile, desktop
			.b-056__list-item:not(:nth-child(-n+1)) {
				display: none;
				@include breakpoint($breakpoint_desktop) {
					display: block;
				}
			}

			// item: not first 3 items - desktop
			.b-056__list-item:not(:nth-child(-n+3)) {
				@include breakpoint($breakpoint_desktop) {
					display: none;
				}
			}


				// block
				.b-056__block {
					display: flex;
				}

					// subblock: first
					.b-056__subblock--first {
						margin-right: 0.75em;
					}

						// image
						.b-056__image {
							max-width: 6.75em; // fallback
						}

						// image: 1:1
						.b-056__image--square {
							width: 4.5em;
							height: 4.5em;
						}

						// image: 3:2
						.b-056__image--landscape-3x2 {
							width: 6.75em;
							height: 4.5em;
						}


					// subblock: second
					.b-056__subblock--second {
					}

						// metadata
						.b-056__metadata {
							margin-bottom: 0.5em;
							color: $color_text_paragraph;
						}

						// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
						.b-056__subblock--second h3 {
							margin: 0;
						}

			// dropdown: container
			.b-056__dropdown-container {
				flex-shrink: 0;
				margin-left: 0.5em;
				min-width: 1.5em;
			}

			// dropdown: button
			.b-056__dropdown-container .button-dropdown {
			}
