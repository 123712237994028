//
// Input
//


// obecny input je definovan v html defaults pomoci selekce primo na tag <input>
input {
}

// vizualni temata formularovych elementu

// modern
// primarni tema dle alesovo nahledu boxu

input[type="text"],
input[type="password"],
input[type="email"] {
	
	border: 1px solid $color_background-1;
	background: white;
	padding-left: 0.25em;
	padding-right: 0.25em;
	outline: none;
	line-height: 2em;
	transition: all 0.2s ease-in-out;
	width: 100%;

	&:focus {
		border: 1px solid $color_decoration_main;
		box-shadow: 0px 0px 5px 0px $color_decoration_main;
	}
}



//
// Chceckbox
//

input[type="checkbox"] {
	
	width: auto;
	position: relative;
  	top: 0.15em;
	margin-right: 0.5em;
}

//
// Submit
//

input[type="submit"] {

	margin-top: 0;
	padding: 1em;
	border: 0;
	text-transform: uppercase;
	color: $color_text_accent_inverted;
	background-color: $color_decoration_success;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
}
