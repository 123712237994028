//
// Pohoda - B-015 Styles
//


$pohoda_b-015_launcher_height_mobile: 45px;
$pohoda_b-015_launcher_height_desktop: 60px;

$pohoda_b-015_logo-wrapper_height_mobile: $pohoda_b-015_launcher_height_mobile;
$pohoda_b-015_logo-wrapper_height_desktop: $pohoda_b-015_launcher_height_desktop;

$pohoda_b-015_launcher_button-play-live-size_mobile: 55px;
$pohoda_b-015_launcher_button-play-live-size_desktop: 70px;

$pohoda_b-015_launcher_button-play-mujrozhlas-size_mobile: 120px;
$pohoda_b-015_launcher_button-play-mujrozhlas-size_desktop: 260px;

$pohoda_b-015_launcher_button-eq-live-size_mobile: 16px;
$pohoda_b-015_launcher_button-eq-live-size_desktop: 22px;



#{$pohoda_selector_body} {

	// holder s puvodnimi parametry kdyz je b-015 position fixed
	.b-015__holder {
		//margin-bottom: 8px; // preneseno z .b-015
		margin-bottom: 6px; // preneseno z .b-015
		height: $pohoda_b-015_launcher_height_mobile;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 6px; // preneseno z .b-015
			height: $pohoda_b-015_launcher_height_desktop
		}
	}

		.b-015 {
			position: relative;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			//margin-bottom: 8px; // preneseno z .b-016__wrapper
			margin-bottom: 0; // preneseno do .b-015__holder

			height: $pohoda_b-015_logo-wrapper_height_mobile;

			// ciste debug default barva pozadi pro zobrazeni samostatneho atomu nebo navigace, pokud
			// nema rodicovsky obal, ktery jej kontextove obarvi tridou .bg-color-main
			background-color: $color_pohoda_main;

			transition: box-shadow 0.35s ease, opacity 0.35s ease, top 0.35s ease;

			z-index: 1; // FIX: ve FF prekryval .header__visual-image

			@include breakpoint($breakpoint_desktop) {
				margin-top: 0; // preneseno do .b-015__holder
				height: $pohoda_b-015_logo-wrapper_height_desktop;
			}

			// nav je ukotvena
			&.fixed {
				position: fixed;
				top: 0;

				width: 100%;
				max-width: $layout_page_max-width;

				box-shadow: $shadow_station-header;

				opacity: 0.95;
				z-index: 100; // z-index: 1; kolidovalo s b-006h__dropdown expanded (1), kolidovalo s .datepicker (99), kolidovalo s .bubble-subscribe (99)

				&.delayed {
					top: 0;

					&.scrolled-down {
						top: -48px; // vyska navbar na mobilnim viewportu

						@include breakpoint($breakpoint_desktop) {
							top: 0;
						}
					}
				}
			}
		}

			// obecne vlastnosti sloupce
			.b-015__list-item {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
			}

			// levy sloupec - misto pro logo
			.b-015__list-item--left {
				flex-grow: 0;

				height: $pohoda_b-015_logo-wrapper_height_mobile;

				@include breakpoint($breakpoint_desktop) {
					height: $pohoda_b-015_logo-wrapper_height_desktop;
				}
			}

				.b-015__logo-wrapper {
					margin-top: 0;
					margin-left: $layout_main_horizontal-padding_mobile;
					margin-right: 0;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
						margin-left: $layout_main_horizontal-padding_desktop;
						margin-right: 0;
					}
				}

					.b-015__logo {
						display: block;

						width: 5em; // 80px
						height: $pohoda_b-015_launcher_height_mobile;

						background-position: left center;
						background-repeat: no-repeat;

						@include breakpoint($breakpoint_desktop) {
								width: 6.5em; // 104px
								height: $pohoda_b-015_launcher_height_desktop;
						}
					}

						.b-015__logo-textcontent {
							color: $color_text_link_inverted;
							display: none;
						}


			// pravy sloupec - launcher prehravace
			.b-015__list-item--right {
				flex-grow: 1;
				display: flex;
			}

				// wrapper pro launcher
				.b-015__launcher-wrapper {
					flex-grow: 1;

					display: flex;
					flex-direction: row;
					justify-content: flex-end;

					padding: 0 $layout_main_horizontal-padding_mobile;

					@include breakpoint($breakpoint_desktop) {
						padding: 0 $layout_main_horizontal-padding_desktop;
					}
				}

						.b-015__launcher {
							flex-grow: 1;
							max-width: 100%;

							display: flex;
							flex-direction: row;

							height: $pohoda_b-015_launcher_height_desktop;

							@include breakpoint($breakpoint_desktop) {
								flex-grow: 1;
							}
						}

							.button-play--launcher-live {
								width: $pohoda_b-015_launcher_button-play-live-size_mobile;
								height: $pohoda_b-015_launcher_height_mobile;

								@include breakpoint($breakpoint_desktop) {
									width: $pohoda_b-015_launcher_button-play-live-size_desktop;
									height: $pohoda_b-015_launcher_height_desktop;
								}
							}

							// eq: svg
							.button-eq--launcher-live {
								width: $pohoda_b-015_launcher_button-eq-live-size_mobile;
								height: $pohoda_b-015_launcher_height_mobile;

								@include breakpoint($breakpoint_desktop) {
									width: $pohoda_b-015_launcher_button-eq-live-size_desktop;
									height: $pohoda_b-015_launcher_height_desktop;
								}
							}

							.button-play--launcher-mujrozhlas {
								display: none; // na mobilu nechceme, neveslo by se
								width: $pohoda_b-015_launcher_button-play-mujrozhlas-size_mobile;
								height: $pohoda_b-015_launcher_height_mobile;
								margin-right: 0.5em;

								@include breakpoint($breakpoint_desktop) {
									display: block;
									width: $pohoda_b-015_launcher_button-play-mujrozhlas-size_desktop;
									height: $pohoda_b-015_launcher_height_desktop;
									margin-right: 5em;
								}
							}


							// docasne skryti player launcheru
							.button-play--launcher-live-pohoda { display: none; }
							.button-play--launcher-mujrozhlas {
								display: block; // na mobilu chceme, vejde se
								margin-right: 0;
								@include breakpoint($breakpoint_desktop) {
									margin-right: 0;
								}
							}


}
