//
// Dvojka - node id 8100029 (udalost At zije divadlo! At ziji knizky!)
//



.dvojka.page-node-8100029 {

	.l-grid--4-9.item-first,
  .l-grid--4-9.item-second {
		margin-top: 0;
	}

	.l-grid--8-1.item-second {

		.box.b-oprojektu {

			h2.pane-title {}

			.block__content {

				.field.body {
					padding: 0;
					margin: 0;

					p:first-of-type {
						margin-top: 0;
					}
				}
			}
		}
	}

	.l-grid--12-1.item-first {
		margin-bottom: 0;

		// b-005
		.b-005 {
			.b-005__list {
				.b-005__list-item {
					img {
						transition: transform 0.5s;
					}
				}
				.b-005__list-item:hover {
					img {
						transform: scale(1.1);
					}
				}
				.b-005__list-item:nth-child(n+6) {
					display: block;
				}
			}
		}
	}
}
