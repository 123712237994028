//
// B-041f Styles
//



$b-041f_image-width-basis: 8em;


.b-041f {
}

	.b-041f h2 {
		text-transform: uppercase;
	}

		.b-041f__list {
		}

			.b-041f__list-item {
				display: flex;
				flex-direction: column;

				padding: 1em;
				margin-bottom: 0.25em;

				background-color: $color_background-2;

				text-align: center;

				@include breakpoint($breakpoint_desktop) {
					flex-direction: row;

					padding: 0;
					margin-bottom: 1.25em;

					background-color: transparent;

					text-align: left;
				}
			}

				.b-041f__block {
				}

				.b-041f__block--image {
					padding: 1em 1em 0 1em;

					@include breakpoint($breakpoint_desktop) {
						flex-basis: $b-041f_image-width-basis;
						flex-shrink: 0;

						padding: 0;
					}
				}

					.b-041f__image {
						position: relative;
					}


				.b-041f__block--description {
					flex-grow: 1;

					@include breakpoint($breakpoint_desktop) {
						padding-left: 1em;
					}
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-041f__block--description h3 {
						margin-top: 1em;
						margin-bottom: 0;

						@include breakpoint($breakpoint_desktop) {
							margin-top: 0;
						}
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-041f__block--description p {
						margin-top: 0.5em;
						margin-bottom: 1em;

						color: $color_text_paragraph;

						@include breakpoint($breakpoint_desktop) {
							margin-bottom: 0.5em;
						}
					}

					.b-041f__actions {
					}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-041f__actions .button-listaction {
							margin-right: 0.25em;

							width: 4em;
							height: 4em;

							@include breakpoint($breakpoint_desktop) {
								width: 2em;
								height: 2em;
							}
						}
