//
// sub
//



sub {
	font-size: 75%;
	line-height: 0;

	position: relative;
	top: 0.25em;

	vertical-align: baseline;
}
