//
// Brno - HP
//



.brno.page-brno-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}