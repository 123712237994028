//
// Layout (grid)
//



// debug - vizualizace gridu
//@include sgs-change('debug', true);



body {
	@include add-grid(4);
	@include add-grid(8 at $breakpoint_tablet);
	@include add-grid(12 at $breakpoint_desktop);

	@include add-gutter(0);

	@include background-grid($color: $grid);
}



// tvorba univerzalnich gridovych class
// pokud se nejedna o celou sirku (12 pro desktop) nasleduji vzdy v paru resp. skupine,
// ktera dohromady cely radek uzavre.
// odvisi od toho delene odsazeni sloupcu



/**
 * 1/1
 */

// full
.l-grid--12-1 {
	clear: both;
	overflow: hidden;
}



/**
 * 1/2
 */

// left
.l-grid--6-1 {
	clear: both;
	@include grid-span(4, 1);

	@include breakpoint($breakpoint_tablet) {
		@include grid-span(4, 1);

		// parove odsazeni leveho sloupce
		padding-right: $layout_grid_column-gutter_tablet / 2;
	}

	@include breakpoint($breakpoint_desktop) {
		@include grid-span(6, 1);
		clear: both;

		// parove odsazeni leveho sloupce
		padding-right: $layout_grid_column-gutter_desktop / 2;
	}
}

// right
.l-grid--6-7 {
	clear: both;
	@include grid-span(4, 1);

	@include breakpoint($breakpoint_tablet) {
		@include grid-span(4, 5);

		// parove odsazeni praveho sloupce
		padding-left: $layout_grid_column-gutter_tablet / 2;
	}

	@include breakpoint($breakpoint_desktop) {
		@include grid-span(6, 7);

		// parove odsazeni praveho sloupce
		padding-left: $layout_grid_column-gutter_desktop / 2;
	}
}



/**
 * 1/3
 */

// left
.l-grid--4-1 {
	clear: both;
	@include grid-span(4, 1);

	@include breakpoint($breakpoint_tablet) {
		@include grid-span(8, 1);
	}

	@include breakpoint($breakpoint_desktop) {
		@include grid-span(4, 1);
		clear: both;

		// parove odsazeni leveho sloupce
		padding-right: $layout_grid_column-gutter_desktop / 2;
	}
}

// middle
.l-grid--4-5 {
	clear: both;
	@include grid-span(4, 1);

	@include breakpoint($breakpoint_tablet) {
		@include grid-span(8, 1);
	}

	@include breakpoint($breakpoint_desktop) {
		@include grid-span(4, 5);

		// parove odsazeni leveho sloupce
		padding-right: $layout_grid_column-gutter_desktop / 2;
	}
}

// right
.l-grid--4-9 {
	clear: both;
	@include grid-span(4, 1);

	@include breakpoint($breakpoint_tablet) {
		@include grid-span(8, 1);
	}

	@include breakpoint($breakpoint_desktop) {
		@include grid-span(4, 9);

		// parove odsazeni praveho sloupce
		padding-left: $layout_grid_column-gutter_desktop / 2;
	}
}



/**
 * 2/3
 */

// left
.l-grid--8-1 {
	clear: both;
	@include grid-span(4, 1);

	@include breakpoint($breakpoint_tablet) {
		@include grid-span(8, 1);
	}

	@include breakpoint($breakpoint_desktop) {
		@include grid-span(8, 1);
		clear: both;

		// parove odsazeni leveho sloupce
		padding-right: $layout_grid_column-gutter_desktop / 2;
	}
}

// right
.l-grid--8-5 {
	clear: both;
	@include grid-span(4, 1);

	@include breakpoint($breakpoint_tablet) {
		@include grid-span(8, 1);
	}

	@include breakpoint($breakpoint_desktop) {
		@include grid-span(8, 5);

		// parove odsazeni praveho sloupce
		padding-left: $layout_grid_column-gutter_desktop / 2;
	}
}
