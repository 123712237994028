//
// cestyzavodou - HP
//



$cestyzavodou_region-wrapper-background-color-1: rgba(#ffffff, 1); // white
$cestyzavodou_region-wrapper-background-color-2: rgba(#f7f7f7, 1); // light grey
$cestyzavodou_region-wrapper-background-color-3: rgba(#e5e4ea, 1); // medium grey


.cestyzavodou.page-cestyzavodou-hp {

	// main
	.main {
		background-color: transparent;
	}

	// regionl-grid--12-1 obecne
	.l-grid--12-1 {
		overflow: visible; // kvuli barevnym pruhum
	}

	// pro tento web filtry nepotrebujeme, skryty kvuli (ne)roztahovani layoutu
	.view-filters {
		display: none;
	}

	// patra/wrappery obecne
	.region-wrapper {
		position: relative;

		padding-top: 0.1px; // collapsing fix
		padding-bottom: 0.1px; // collapsing fix

		// nekonecny pruh obecne
		&::before {
			content: "";

			position: absolute;
			left: -200%;
			right: -200%;

			height: 100%;

			z-index: -1;
		}

		// box - pseudo node
		.box.view-cestyzavodou-hp {

			@include breakpoint($breakpoint_desktop) {
				max-width: 750px;

				margin-right: auto;
				margin-left: auto;
			}

			// title
			.views-field-title h2 {
				margin-bottom: 1em;
				padding: 0;

				color: $color_text_accent;

				font-size: $fontsize_h1;
				line-height: 1.8rem;

				text-transform: none;

				@include breakpoint($breakpoint_desktop) {
					font-size: $fontsize_h1_desktop;
					line-height: 3rem;
				}
			}

			// perex
			.views-field-field-perex {
				@include typography_font(medium);
				font-size: $fontsize_paragraph_node;
				line-height: 1.6em;

				margin-top: 20px;
				margin-top: 1.25rem;
				margin-bottom: 20px;
				margin-bottom: 1.25rem;

				@include breakpoint($breakpoint_desktop) {
					font-size: $fontsize_paragraph_node_desktop;
				}

				p {
					font-size: $fontsize_paragraph_node;
					line-height: 1.6em;

					margin-top: 20px;
					margin-top: 1.25rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_paragraph_node_desktop;
					}
				}

				strong {
					@include typography_font(bold);
				}

				a {
					@include typography_font(medium);
					@include link-style($color_text_link, none, $color_text_link, none);
					border-bottom: 1px solid $color_background-9;

					&:hover {
						color: $color_background-6;
						border-bottom: 1px solid $color_background-6;
					}
				}

				ul {
					list-style: disc;

					li {
						margin-left: 1em;
					}
				}

				ol {
					list-style: decimal;

					li {
						margin-left: 1.5em;
					}
				}
			}

			// paragraph
			.views-field-body {
				font-size: 1rem;
				line-height: 1.6em;
				color: $color_text_paragraph_node;

				@include breakpoint($breakpoint_desktop) {
					font-size: 1.2rem;
					//margin-left: $node_left-margin;
					//padding: 0 $node_padding;
				}

				p {
					font-size: $fontsize_paragraph_node;
					line-height: 1.6em;

					margin-top: 20px;
					margin-top: 1.25rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_paragraph_node_desktop;
					}
				}

				strong {
					@include typography_font(medium);
					color: $color_text_accent;
				}

				.file-audio {
					margin-left: 0;

					@include breakpoint($breakpoint_desktop) {
						margin: 1.75em -1.875rem;
					}
				}

				/* obecna h2 v node - ovlivnuje vsechny h2 kdekoliv v node, tzn. v primo body, v assetech, apod. */
				h2 {
					clear: both;
					font-size: $fontsize_h2;
					text-transform: none;
					padding: 0;
					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h2_desktop;
					}
				}

				/* h2 primo v body node - mela by ovlivnit jen h2 primo v body clanku */
				> h2 {
					font-size: $fontsize_h2_node;
					line-height: calc(#{$fontsize_h2_node} + 10px);
					margin-top: 40px;
					margin-top: 2.5rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h2_node_desktop;
						line-height: calc(#{$fontsize_h2_node_desktop} + 10px);
					}
				}

				/* obecna h3 v node - ovlivnuje vsechny h3 kdekoliv v node, tzn. v primo body, v assetech, apod. */
				h3 {
					clear: both;
					font-size: $fontsize_h3;
					transform: none;
					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h3_desktop;
					}
				}

				/* h3 primo v body node - mela by ovlivnit jen h3 primo v body clanku */
				> h3 {
					@include typography_font(medium);
					font-size: $fontsize_h3_node;
					line-height: calc(#{$fontsize_h3_node} + 10px);
					margin-top: 40px;
					margin-top: 2.5rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h3_node_desktop;
						line-height: calc(#{$fontsize_h3_node_desktop} + 10px);
					}
				}

				h4 {
					clear: both;
					font-weight: normal;
					font-size: $fontsize_h4;
					transform: none;
					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h4_desktop;
					}
				}

				p:not(.cteteTake) a {
					@include typography_font(medium);
					@include link-style($color_text_link, none, $color_text_link, none);
					border-bottom: 1px solid $color_background-9;

					&:hover {
						color: $color_background-6;
						border-bottom: 1px solid $color_background-6;
					}

					strong {
						color: unset;
					}
				}

				ul {
					list-style: disc;

					li {
						margin-left: 1em;
					}
				}

				ol {
					list-style: decimal;

					li {
						margin-left: 1.5em;
					}
				}
			}

			// more
			.views-field-path .button {
				margin-top: 2em;

				@include breakpoint($breakpoint_desktop) {
					width: 50%;
				}
			}

			// audio player
			/*.audio-article-player .b-041k .b-041k__metadata {
				max-width: 100%;
			}*/
		}

		// prvni box v patre
		.box:last-child {
			margin-top: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 4em;
			}
		}

		// posledni box v patre
		.box:last-child {
			margin-bottom: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 6em;
			}
		}
	}

	// 1. patro
	.region-wrapper--level-00 {

		// nekonecny pruh
		&::before {
			background-color: $cestyzavodou_region-wrapper-background-color-3;
		}
	}

	// 2. patro
	.region-wrapper--level-01 {

		// nekonecny pruh
		&::before {
			background-color: $cestyzavodou_region-wrapper-background-color-2;
		}
	}

	// 3. patro
	.region-wrapper--level-02 {

		// nekonecny pruh
		&::before {
			background-color: $cestyzavodou_region-wrapper-background-color-1;
		}
	}

	// 4. patro
	.region-wrapper--level-03 {

		// nekonecny pruh
		&::before {
			background-color: $cestyzavodou_region-wrapper-background-color-3;
		}
	}

	// 5. patro
	.region-wrapper--level-04 {

		// nekonecny pruh
		&::before {
			background-color: $cestyzavodou_region-wrapper-background-color-2;
		}
	}

	// 6. patro
	.region-wrapper--level-05 {

		// nekonecny pruh
		&::before {
			background-color: $cestyzavodou_region-wrapper-background-color-1;
		}
	}

	// audioplayer korekce
	.file-audio .sm2-playlist-wrapper {
		display: none;
	}
}
