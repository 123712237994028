//
// page-playlisty
//



.page-playlisty {

	// datepicker
	.b-038__navbar-wrapper {

		//position: relative;

		#datepicker {
			position: absolute;

			top: 4.5em;
			right: 0;
			left: 0;
			padding: 30px;
			margin-top: -30px;

			text-align: center;

			z-index: 99;

			.ui-datepicker {
				margin: 0 auto;
			}
		}
	}


	// odsazeni praveho sloupce
	.l-grid--4-9 {

		@include breakpoint($breakpoint_desktop) {
			margin-top: 8em;
		}
	}
}
