//
// Button Popup
//



// struktura obecneho popup tlacitka
.button-popup {
	
	display: inline-block;
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-popup:hover {
	//background-color: $color_background-1;
}
	
	.button-popup__label {
	}
		
		.button-popup__label-nest {
			
			display: none;
		}



// zaviraci tlacitko X
.button-popup--close {
	
	.button-popup__label {
		
		width: 4em;
		height: 4em;
		
		background-image: $file_icon_close_colored-519fd7;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 0.85em;
	}
}



// otviraci tlacitko +
.button-popup--open {
	
	.button-popup__label {
		
		width: 4em;
		height: 4em;
		
		background-image: $file_icon_close_colored-519fd7;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 0.85em;
		
		transform: rotate(45deg); // vychazime z ikony X
	}
}



// upscale tlacitko
.button-popup--upscale {
	
	.button-popup__label {
		
		width: 4em;
		height: 4em;
		
		background-image: $file_icon_upscale_colored-white;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 0.85em;
	}
}