//
// Plus - HP
//



.plus.page-plus-hp {

	/* wrappers */
	.hp-wrapper-left.item-first,
	.hp-wrapper-left.item-second,
	.hp-wrapper-right.item-first {
		//margin-top: 2em;
	}

	.hp-wrapper,
	.hp-wrapper-2 {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}

	}


	/* defaults */
	.box {
		h2 {
			//color: $color_text_paragraph;
		}
		h2 a {
			//@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
		}
	}



	/* prvni box v prvnim regionu .l-grid--12-1  */
	div.l-grid--12-1:first-of-type { // nechceme <header> ani <footer>

		// prvni box
		.box:first-of-type {
		}
	}



	/* prvni box v prvnim regionu .l-grid--4-1  */
	.hp-wrapper-left.item-first .l-grid--4-1 {

		// prvni box
		.box:first-of-type {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 2em;
			}
		}
	}



	/* prvni box v prvnim regionu .l-grid--8-5  */
	.hp-wrapper-left.item-first .l-grid--8-5 {

		// prvni box
		.box:first-of-type {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 2em;
			}
		}
	}



	/* prvni box v prvnim regionu .l-grid--8-1  */
	.hp-wrapper-right.item-first .l-grid--8-1 {

		// prvni box
		.box:first-of-type {
			@include breakpoint($breakpoint_desktop) {
				//margin-top: 2em;
			}
		}
	}



	/* prvni box v prvnim regionu .l-grid--4-9  */
	.hp-wrapper-right.item-first .l-grid--4-9 {

		// prvni box
		nav.box:first-of-type { // prvni box je <nav>, tak aby se neaplikovalo na dalsi elementy, napr. <div>
			@include breakpoint($breakpoint_desktop) {
				// nepotrebuje default vertikalni odsazeni
				margin-top: 2em;
			}
		}
	}



	/* obecne fixy boxu b-002g */

	/* b-002g o jednom itemu */
	.b-002g--items-1 {
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}

	/* b-002g o dvou itemech */
	.b-002g--items-2 {
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
			}
		}
	}

	/* b-002g o trech itemech */
	.b-002g--items-3 {
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				width: 33%;
			}
		}
	}



	/* b-002g v l-grid--8-5 a l-grid--8-1 */
	.l-grid--8-5 .b-002g:not(:first-of-type),
	.l-grid--8-1 .b-002g:not(:first-of-type) {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
		}
	}



	/* b-002e selfpromo - prvni box */
	/*.b-002e {
		margin-top: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
	}*/



	/* b-006d video carousel */
	.hp-wrapper-right .l-grid--8-1 .box.b-006d {
		margin-top: 2em;
		margin-bottom: 3em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
			margin-bottom: 3em;
		}
	}



	/* b-006c video tydne */
	.hp-wrapper-left .l-grid--8-5 .box.b-006c {
		position: relative; // kvuli absolutnimu pozicovani oddelujici linky na mobilu

		margin-top: 3em;
		margin-bottom: 6em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
			margin-bottom: 3em;
		}
	}
	/* b-006c video tydne - mobile bottom line */
	.hp-wrapper-left .l-grid--8-5 .box.b-006c::after {
		display: block;

		content: "";

		background: $color_background-3;

		position: absolute;
		//top: 0;
		bottom: -3em;
		left: 0;
		right: 0;

		width: 100%;
		height: 2px;

		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}



	/* b-038c programove tipy */
	.hp-wrapper-left.item-first .l-grid--4-1 .b-038c {
		// skryje vsechny krome prvniho
		.b-038c__list-item:not(:first-child)  {
			display: none;
		}
		.b-038c__list-item  {
			border-bottom: none;
		}
	}



	/* b-008i radiokniha */
	.hp-wrapper-left.item-first .l-grid--4-1 .b-008i {
		.b-008i__list {
			display: block; // default je flex, ale tady je jen jeden item, takze ney resit flex-basis itemu je lepsi toto a je to tak i ie11 kompatibilni
		}
	}



	/* b-005 - plus pripravuji */
	.b-005 {
		//margin-top: 4em;
		//margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			//margin-top: 4em;
			//margin-bottom: 4em;
		}

		h2 {
			//color: $color_text_paragraph;
		}

		h2 a {
			//@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
		}
	}



	/* boxy ve wrapperu .hp-wrapper */
	.hp-wrapper {

		/* b-002g - box o jednom itemu */
		.b-002g.b-002g--items-1 {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
			}
		}

		/* b-042 - udalost (strida se s doporucujeme) */
		.b-042 {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
				margin-left: 2em;
			}

			.b-042__overlay-content h3 {
				color: $color_plus_main;
			}

			.b-042__overlay-content h3 a {
				@include link-style($color_plus_main, none, $color_plus_main, underline);
			}
		}

		/* b-004m - doporucujeme (strida se s udalosti) */
		.b-004m {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
				margin-left: 2em;
			}
		}
	}



	/* boxy ve wrapperu .hp-wrapper-2 */
	.hp-wrapper-2 {

		/* b-007a carousel */
		.b-007a {
			@include breakpoint($breakpoint_desktop) {
				/*flex-basis: 60%;
				flex-grow: 1;
				max-width: 65.5%; // fallback pro calc()
				max-width: calc(67% - 1em);*/

				width: 65.5%; // fallback pro calc()
				width: calc(67% - 1em);

				margin-right: 2em;
			}

			.b-007a__list-item {

				ul li:not(:first-child) {
					display: none;
				}

				@include breakpoint($breakpoint_desktop) {

					min-width: 80%;

					margin-bottom: $b-007a_marginbottom_item; // kvuli pretekajicimu boxu s H3 Title
				}
			}

			.b-007a__block--second {

				@include breakpoint($breakpoint_desktop) {

					max-width: 65%;

					padding: 0.5em;

					right: 3em;
					bottom: -1em;
				}
			}
		}

		/* b-002g o jednom itemu */
		.b-002g.b-002g--items-1 {
			@include breakpoint($breakpoint_desktop) {
				/*flex-basis: 30%;
				flex-grow: 1;
				max-width: 31.5%; // fallback pro calc()
				max-width: calc(33% - 1em);*/

				width: 31.5%; // fallback pro calc()
				width: calc(33% - 1em);
			}
		}
	}



	/* boxy v regionu .l-grid--8-1 */
	.l-grid--8-1 {

		/* b-002g o trech itemech */
		.b-002g--items-3 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 2em;
			}

			// dalsi ul po kliknuti na infinity scroll button
			.b-002g__list:not(:first-child) {
				margin-top: 2em;
			}
		}
	}



	/* clear fixed region wrapper */
	.hp-wrapper-left.clearfix,
	.hp-wrapper-right.clearfix {
		display: inline-block;
		overflow: unset;
	}



	/* #b002g7 je specifickja varianta pro infinite scroll verzi, ktera ma vice radku */
	/* potrebuje wrapovani a tim padem poresit koretkni velikost itemu a jeho odrazeni */
	/* #b002g7 - docasne puvodni portal */
	/* #b002g1 - novy portal */
		#b002g7,
		#b002g1 {
		.b-002g__list {
			@include breakpoint($breakpoint_desktop) {
				flex-wrap: wrap;
			}
		}
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 1.34em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1.34em? protoze je to 2 * 2 / 3 viz (*)

				margin-left: 2em; // (*)
				margin-bottom: 2em;
			}
			&:nth-child(3n-2) {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}
		}
	}
}
