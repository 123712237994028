//
// B-044b Styles - nutno zrevidovat, netusim jak vzniklo
//


.b-044b {
}

	// obecny seznam
	.b-044b__list {
	}

		// obecny element
		.b-044b__list-item {
			margin-bottom: 2em;
		}

			.b-044b__block {
				position: relative;
			}

				.b-044b__image {
					position: relative;
				}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-044b__image .badge {
						position: absolute;
						top: 0;
						right: 0;
					}
					.b-044b__list--following .b-044b__image .badge {
						display: none; // u malych itemu nechceme viz tiket NOV-1846
					}

					.b-044b__overlay {
						position: absolute;
						bottom: 0;
						left: 10px;
						right: 10px;
					}


			.b-044b h3 {
				margin: .5em 0 0;
			}

		.b-044b__list-item--list-following {
			display: flex;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
			}
		}

			.b-044b__image {
				flex-basis: 6.68em;
				flex-shrink: 0;
			}

			.b-044b__list--following h3 {
				flex-grow: 1;
				margin: 0 0 0 .8em;
			}





// vizualni debug - plab only
.patternlab .b-044b {

	@include breakpoint($breakpoint_desktop) {
		max-width: 800px;
	}
}
