//
// B-051b Styles
// hlasovi asistenti
//


// image block flex basis
$b-051b_image_block-size-basis: 5.5em;
$b-051b_image_block-size-basis_desktop: 3.5em;


.b-051b {
}

	// list
	.b-051b__list {
		margin-bottom: 2em;
		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 3em;
		}
	}

		// item
		.b-051b__list-item {
			display: flex;
			flex-direction: column;

			padding: 20px 0;

			border-top: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;

				padding: 10px 0;
			}
		}

		// item: first
		.b-051b__list-item:first-child,
		.b-051b__list-item:first-of-type {
			padding-top: 0;
			border-top: none;
		}

			// block
			.b-051b__block {
			}

			// block: image
			.b-051b__block--image {
				flex-shrink: 0;

				max-width: $b-051b_image_block-size-basis;
				max-height: $b-051b_image_block-size-basis;

				width: $b-051b_image_block-size-basis; // fix pro chybejici nebo mensi obrazky
				height: $b-051b_image_block-size-basis; // fix pro chybejici nebo mensi obrazky

				@include breakpoint($breakpoint_desktop) {
					max-width: $b-051b_image_block-size-basis_desktop;
					max-height: $b-051b_image_block-size-basis_desktop;

					width: $b-051b_image_block-size-basis_desktop; // fix pro chybejici nebo mensi obrazky
					height: $b-051b_image_block-size-basis_desktop; // fix pro chybejici nebo mensi obrazky
				}
			}

				.b-051b__image {
					width: 100%;
					height: 100%;

					border-radius: 5px;
				}

					.b-051b__image-logo {
						width: 100%;
						height: 100%;
					}

						.b-051b__image-logo span {
							display: none;
						}


			// block: description
			.b-051b__block--description {
				display: flex;
				flex-direction: column;
				flex-grow: 1;

				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
					padding-right: 5em;
					overflow: hidden;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-051b__block--description h3 {
					font-size: 20px;
					line-height: 1.3;

					margin-top: 0.5em;
					margin-bottom: 0.25em;

					@include breakpoint($breakpoint_desktop) {
						font-size: 18px;
						line-height: 1.3;

						margin-bottom: 0.25em;

						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}


					.b-051b__subblock--description {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						margin-bottom: 8px;
						@include breakpoint($breakpoint_desktop) {
							margin-bottom: 0;
						}
					}

						.b-051b__description--text {
							font-size: 16px;
							line-height: 1.4;

							margin-top: 0;
							margin-bottom: 0;

							color: $color_text_paragraph;

							@include breakpoint($breakpoint_desktop) {
								font-size: 15px;
								line-height: 1.4;

								margin-top: 0;
							}
						}

						.b-051b__description--text-accented {
							flex-basis: 100%;

							font-size: 16px;
							line-height: 1.4;

							margin-top: 5px;
							margin-bottom: 0;

							color: $color_background-9;

							@include breakpoint($breakpoint_desktop) {
								line-height: 1.4;
							}
						}

// PL only
.patternlab {
	.b-051b {
		max-width: 755px;
	}
}

//
// b-051b Printer Styles
//

@media print {

	.b-051b {
		@include breakpoint($breakpoint_desktop) {
			max-width: 100%;
		}
	}

		.b-051b__navbar.bg-color-main {
			background-color: transparent;
		}

			.b-051b__navbar-item--previous a,
			.b-051b__navbar-item--next a {
				display: none;
			}

			.b-051b__navbar-item--calendar {
				background-color: transparent;
				color: $color_text_accent;

				box-shadow: none;
			}


		.b-051b__links {
			display: none;
		}

		.b-051b__list-item--present {
			 background-color: transparent;
		}

			.b-051b__list-item--present .b-051b__block--timing {
				flex-wrap: no-wrap;
				flex-direction: row;
				align-items: flex-start;
			}

				.b-051b__list-item--past .b-051b__info--time {
					color: $color_text_accent;
				}

				.b-051b__launcher-info-timing-progressbar {
					display: none;
				}

					.b-051b__launcher-info-timing-progressbar-progress {
						display: none;
					}


				.b-051b__image {
					display: none;
				}

				.b-051b__actions {
					display: none;
				}

					// odkaz na archiv poradu, leze z api, ale neni v zadani
					.b-051b__actions .cro-buttons-archive-h12 {
						display: none;
					}

						.b-051b__list-item--present .b-051b__actions .cro-buttons-archive-h12 {
							display: none;
						}
}
