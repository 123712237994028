//
// DFP - Double Click
//



.dfp-tag-placeholder {
	iframe {
		max-width: 100%; // zamezi pretekani, nutno doresit
		
		display: block; // kvuli zarovnani na stred na mobilnim viewportu
		margin: auto; // kvuli zarovnani na stred na mobilnim viewportu
		
		@include breakpoint($breakpoint_desktop) {
			margin: inherit; // reset pro desktopovy viewport
		}
	}
}
