//
// a-008 Styles
//


$a-008_block-image_max-width: 6em;
$a-008_block_margin_horizontal: 1.5em;
$a-008_button-play_size: 32px;



.a-008 {
	position: relative;
}

// vertikalni linka
.a-008::before {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	width: 10px;

	background-color: $color_decoration_main; // defaultni barva, v drupalu muze byt pretizena barvou stanice
}

	// link
	.a-008__link {
		display: block;
	}

	// link: hover
	.a-008__link:hover {
		text-decoration: none;
	}

		// subsection
		.a-008__subsection {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			//align-items: stretch;
		}

			// block
			.a-008__block {
				margin-left: $a-008_block_margin_horizontal;
			}

			// block: content
			.a-008__block--content {
				position: relative;
				flex-grow: 1;
			}

				// flag
				.a-008__flag {
					margin-bottom: 0.5em;

					font-size: 14px;
					line-height: 1.3;

					color: $color_text_paragraph;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0.25em;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.a-008__block--content h3 {
					margin: 0;

					font-size: 20px;
					line-height: 1.3;

					color: $color_text_paragraph_node;

					@include breakpoint($breakpoint_desktop) {
						font-size: 24px;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.a-008__block--content p {
					margin-top: 0.5em;
					margin-bottom: 0;

					font-size: 14px;
					line-height: 1.3;

					color: $color_text_paragraph;

					@include breakpoint($breakpoint_desktop) {
						font-size: 16px;
					}
				}


			// block: image
			.a-008__block--image {
				flex-shrink: 0;

				display: flex;
				flex-direction: column;
				justify-content: space-between;

				max-width: $a-008_block-image_max-width;
			}

				// image: link
				.a-008__image {
					position: relative;
					overflow: hidden;
				}

					// image: img
					.a-008__image img {
						display: block;
						transition: transform 0.3s;
					}

					// image: img:hover
					.a-008__link:hover .a-008__image img {
						transform: scale(1.1);
					}

					// button play
					.a-008__image .button.button-play {
						width: $a-008_button-play_size;
						height: $a-008_button-play_size;

						position: absolute;
						top: calc(50% - #{$a-008_button-play_size} / 2);
						left: calc(50% - #{$a-008_button-play_size} / 2);
					}

					// button play: hover
					.a-008__link:hover .a-008__image .button.button-play {
						background-position: 100%;
					}

				// logo
				.a-008__logo {
					margin-top: 0.5em;
				}

					// logo: label
					.a-008__logo-label {
						display: none;
					}


				//logo: mujrozhlas
				.a-008__logo--mujrozhlas {
					background-image: $file_logo_mujrozhlas_colored-main;
					background-repeat: no-repeat;

					margin-left: auto;

					width: 4.5em;
					height: 0.75em;
				}





// vizualni debug - plab only
.patternlab {
	.a-008 {
		@include breakpoint($breakpoint_desktop) {
			max-width: 755px;
		}
	}
}
