//
// B-036 Styles
//



.b-036 {
}

	.b-036 h2 {
		text-transform: uppercase;
	}

	// list
	.b-036__list {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		// item
		.b-036__list-item {
			margin-bottom: 1em;
			min-height: 1px; // IE

			@include breakpoint($breakpoint_desktop) {
				// trik jak 3 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 30%; // toto neni tretina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 32%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(33.33% - 0.67em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.67em? protoze je to 1 * 2 / 3 viz (*)

				margin-right: 1em; // (*)
			}
		}

		// item: every 3th
		.b-036__list-item:nth-child(3n) {
			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

		// item: last 3 items
		.b-036__list-item:nth-last-child(-n+3) {
			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}

			.b-036__block--second {
				padding: 1em;
			}

			.b-036__image {
				position: relative;
			}

			// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
			.b-036 h3 {
				margin: 0;
				padding-top: .5em;

			}


		.b-036__list-item:last-child {
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}


	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	.b-036 .button-box-flat {
		margin-top: 3em;

		@include breakpoint($breakpoint_desktop) {
			display: inline-flex;
			float: right;
		}
	}





//
// B-036a Styles
//

.b-036__block--second {
	display: flex;
	flex-direction: column;

	padding: 0.5em 0em;
}

	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	.b-036a h3 {
		margin-top: 0.25em;
		padding-top: 0em;
	}

	// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
	.b-036a p {
		display: inline-block;

		margin-top: 0.25em;
		margin-bottom: 0.25em;
	}

	.b-036a .b-036__block--second .b-036__paragraph--first,
	.b-036a .b-036__block--second > p:nth-of-type(1) {
		color: $color_text_accent;
	}

		.b-036a .b-036__block--second .b-036__paragraph--first a,
		.b-036a .b-036__block--second > p:nth-of-type(1) a {
			@include typography_font(medium);
			@include link-style($color_text_link, underline, $color_text_paragraph_node, underline);
		}

	.b-036a .b-036__block--second .b-036__paragraph--second,
	.b-036a .b-036__block--second > p:nth-of-type(2) {
		color: $color_text_paragraph;
	}

		.b-036a .b-036__block--second .b-036__paragraph--second a,
		.b-036a .b-036__block--second > p:nth-of-type(2) a {
			@include typography_font(medium);
			@include link-style($color_text_link, underline, $color_text_paragraph, underline);
		}


	.b-036a .b-036__block--second .b-036__link--first,
	.b-036a .b-036__block--second .b-036__link--first p,
	.b-036a .b-036__block--second > a:nth-of-type(1),
	.b-036a .b-036__block--second > a:nth-of-type(1) p {
		@include link-style($color_text_accent, none, $color_text_accent, underline);
	}

	.b-036a .b-036__block--second .b-036__link--second,
	.b-036a .b-036__block--second .b-036__link--second p,
	.b-036a .b-036__block--second > a:nth-of-type(2),
	.b-036a .b-036__block--second > a:nth-of-type(2) p {
		@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
	}




//
// B-036b Styles
//



.b-036b__list-item {
	background-color: $color_background-2;
}

	.b-036b__block--second {
		padding: 2em;
	}

		// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
		.b-036b__image .tag {
			position: absolute;
			bottom: 0;
			left: 10px;

			max-inline-size: calc(100% - 20px);

			padding: 0.2em 0.5em;

			text-transform: uppercase;
		}

		// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
		.b-036b h3 {
			font-size: 1.5rem;

			margin: 0;
			padding-top: 0em;
		}

		// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
		.b-036b p {
			margin-top: 0.5em;
			margin-bottom: 0.5em;

			color: $color_background-6;
	}
