//
// B-200 Styles
//
// kostra casove osy
// samotne itemy jsou b-200a, b-200b, b-200c, apod.
//



$b-200a_border_color: rgba(#85b52d, 1); // Clanek - zelena
$b-200b_border_color: rgba(#529fd7, 1); // Audio - modra
$b-200c_border_color: rgba(#d52d3c, 1); // Video - cervena
$b-200d_border_color: rgba(#facc39, 1); // Social - zluta



.b-200 {
}

	.b-200__section {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

	.b-200__list {
		@include breakpoint($breakpoint_desktop) {
			width: 50%;
		}
	}

	.b-200__list--primary {
		@include breakpoint($breakpoint_desktop) {
			padding-right: 1em;
		}
	}

	.b-200__list--secondary {
		@include breakpoint($breakpoint_desktop) {
			padding-left: 1em;
		}
	}

		.b-200__list-item {
		}

		// prvni box v prvnim sloupci v ose nema klasicky margin-top tridy box na desktoupu ani na mobilu
		/*.b-200__list:first-child .b-200__list-item:first-child .box {
			margin: 0;

			@include breakpoint($breakpoint_desktop) {
				margin: 0;
			}
		}
		// prvni box v druhem sloupci v ose nema klasicky margin-top tridy box na mobilu
		:not(.b-200__list:first-child) .b-200__list-item:first-child .box {

			@include breakpoint($breakpoint_desktop) {
				margin: 0;
			}
		}*/





//
// B-200a, B-200b, B-200c, B-200d Styles
//
// itemy casove osy - spolecne styly
// kostra casove osy je b-200
//
.b-200a,
.b-200b,
.b-200c,
.b-200d {
}

	.b-200a__block,
	.b-200b__block,
	.b-200c__block,
	.b-200d__block {
		padding: 1em;

		background-color: $color_background-3;
	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-200a__block h3,
		.b-200b__block h3,
		.b-200c__block h3,
		.b-200d__block h3 {
			margin: 0;
		}

		.b-200a__timestamp,
		.b-200b__timestamp,
		.b-200c__timestamp,
		.b-200d__timestamp {
			display: inline-block;

			margin-bottom: 1em;

			font-size: 12px;
			line-height: 16px;

			color: $color_text_paragraph;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-200a__block p,
		.b-200b__block p,
		.b-200c__block p,
		.b-200d__block p {
			margin: 0; // reset

			color: $color_text_paragraph;
			@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
		}

		// readmore
		.b-200a__block .button-box-flat-small,
		.b-200b__block .button-box-flat-small,
		.b-200c__block .button-box-flat-small,
		.b-200d__block .button-box-flat-small {
			display: inline-flex;

			margin-top: 0.75em;
		}





//
// B-200a Styles
//
// item casove osy - Clanek
// kostra casove osy je b-200
//
.b-200a {
}

	.b-200a__block {
		border-top: 8px solid $b-200a_border_color;
	}

		.b-200a__image {
			margin-bottom: 0.75em;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-200a__block p {
		}





//
// B-200b Styles
//
// item casove osy - Audio
// kostra casove osy je b-200
//
.b-200b {
}

	.b-200b__block {
		border-top: 8px solid $b-200b_border_color;
	}

		.b-200b__image {
		}

		// pouze placeholder, v Drupalu bude audio player
		.b-200b__player--placeholder {
			padding: 2em;

			background-color: $color_background-6;
			color: $color_text_accent_inverted;

			text-align: center;
		}





//
// B-200c Styles
//
// item casove osy - Video
// kostra casove osy je b-200
//
.b-200c {
}

	.b-200c__block {
		border-top: 8px solid $b-200c_border_color;
	}

		.b-200c__subblock--player {
		}





//
// B-200d Styles
//
// item casove osy - Social
// kostra casove osy je b-200
//
.b-200d {
}

	.b-200d__block {
		position: relative;

		border-top: 8px solid $b-200d_border_color;
	}

		.b-200d__badges {
			position: absolute;
			top: 0;
			right: 0;

		}





// vizualni debug - plab only
.patternlab .b-200 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 800px;
	}
}

.patternlab .b-200a,
.patternlab .b-200b,
.patternlab .b-200c,
.patternlab .b-200d {

	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}

.patternlab .b-200 .b-200a,
.patternlab .b-200 .b-200b,
.patternlab .b-200 .b-200c,
.patternlab .b-200 .b-200d {

	@include breakpoint($breakpoint_desktop) {
		max-width: auto;
	}
}
