//
// page-program
//



.page-program {

	// datepicker
	.b-038__navbar-wrapper {

		//position: relative;

		.datepicker {
			position: absolute;

			top: 4.5em;
			right: 0;
			left: 0;
			padding: 30px;
			margin-top: -30px;

			text-align: center;

			z-index: 99;

			.ui-datepicker {
				margin: 0 auto;
			}
		}
	}


	// odsazeni praveho sloupce
	.l-grid--4-9 {

		@include breakpoint($breakpoint_desktop) {
			//margin-top: 8em;
		}
	}
}



//
// Program Print Styles
//

@media print {

	/* header & footer */
	.page-program header.l-grid--12-1,
	.page-program footer.l-grid--12-1 {
		display: none;
	}

	/* main */
	.page-program .l-grid--8-1 {
		clear: none;
		float: none;
		margin-left: 0;
		margin-right: 0;
		padding-right: 0;
		width: 100%;
	}

	/* aside */
	.page-program .l-grid--4-9 {
		display: none
	}
}
