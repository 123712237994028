//
// SOCR - HP
//



.socr.page-socr-hp,
.prso.page-prso-hp {

  .b-007a {

    .b-007a__list-item {

      ul li:not(:first-child) {
        display: none;
      }

      @include breakpoint($breakpoint_desktop) {

        min-width: 80%;

        margin-bottom: $b-007a_marginbottom_item; // kvuli pretekajicimu boxu s H3 Title
      }
    }

    .b-007a__block--second {

      @include breakpoint($breakpoint_desktop) {

        max-width: 65%;

        padding: 0.5em;

        right: 3em;
        bottom: -1em;
      }
    }
  }

}
