//
// Styles JV - Exposed filters
//



// vars
$jv-view-filters_tab_border: none;
$jv-view-filters_tab_padding-horizontal-left_desktop: 2em;
$jv-view-filters_tab_padding-horizontal-right_desktop: 0.75em;
$jv-view-filters_tab_padding-horizontal-left_mobile: 2em;
$jv-view-filters_tab_padding-horizontal-right_mobile: 0em;
$jv-view-filters_tab_padding-vertical_mobile: 0.25em;
$jv-view-filters_tab_padding-vertical_desktop: 0.10em;
$jv-view-filters_tab_margin-horizontal-right_desktop: 1.5em;
$jv-view-filters_tab_font-size: 15px;
$jv-view-filters_tab_line-height: 24px;

$jv-view-filters_search-submit-button_width_mobile: 38px;
$jv-view-filters_search-submit-button_width_desktop: 56px;
$jv-view-filters_search-submit_background-icon-size_mobile: 18px;
$jv-view-filters_search-submit_background-icon-size_desktop: 22px;

$jv-view-filters_tab-1_label_before_background-color: rgba(#85b52d, 1); // Clanek - zelena
$jv-view-filters_tab-2_label_before_background-color: rgba(#facc39, 1); // Social - zluta
$jv-view-filters_tab-3_label_before_background-color: rgba(#d52d3c, 1); // Video - cervena
$jv-view-filters_tab-4_label_before_background-color: rgba(#529fd7, 1); // Audio - modra



.jeziskova-vnoucata {

	@mixin jv-view-filters_tab() {
		position: relative;

		display: block;

		border: $jv-view-filters_tab_border;
		border-bottom: none; // mobile specificke

		padding: $jv-view-filters_tab_padding-vertical_mobile $jv-view-filters_tab_padding-horizontal-right_mobile $jv-view-filters_tab_padding-vertical_mobile $jv-view-filters_tab_padding-horizontal-left_mobile;

		background-color: white;

		font-size: $jv-view-filters_tab_font-size;
		line-height: $jv-view-filters_tab_line-height;
		text-align: left;
		white-space: nowrap;

		color: $color_text_accent;
		@include link-style($color_text_link, none, $color_text_link, none);

		cursor: pointer;


		@include breakpoint($breakpoint_desktop) {

			padding: $jv-view-filters_tab_padding-vertical_desktop $jv-view-filters_tab_padding-horizontal-right_desktop $jv-view-filters_tab_padding-vertical_desktop $jv-view-filters_tab_padding-horizontal-left_desktop;
			margin-right: $jv-view-filters_tab_margin-horizontal-right_desktop;
			//border-right: none;
			border-bottom: $jv-view-filters_tab_border;
			//margin: 0 -1px 0 0;
		}
	}
	@mixin jv-view-filters_tab--before() {
		content: "";

		position: absolute;
		top: 8px;
		left: 6px;

		width: 15px;
		height: 15px;

		background-color: $color_background-9;

		@include breakpoint($breakpoint_desktop) {
			top: 6px;
		}
	}
	@mixin jv-view-filters_tab--lastchild() {

		border-bottom: $jv-view-filters_tab_border;

		@include breakpoint($breakpoint_desktop) {
			//border-right: $view-filters_tab_border;
		}
	}
	@mixin jv-view-filters_tab--active() {

		opacity: 0.8;
	}
	@mixin jv-view-filters_tab--checked() {

		background-color: $color_background-7;

		color: $color_background-0;
		@include link-style($color_background-0, none, $color_background-0, none);
	}



	// tady se na vahu selektoru kasle
	// s markupem se stejne v teto fazi neda hnout, tak at v tom neni uplnej bordel klidne zanoruju
	.view-filters {

		//margin-bottom: 1em;
		margin-bottom: -1.5em;


		// nema svoji tridu na kterou by se dalo obecne chytit
		// zaroven je potreba resetovat zde pravidla z 05_html_elements
		// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
		form {

			// reset default vlastnosti
			padding: 0;
			background-color: transparent;


			.views-exposed-form {

				.views-exposed-widgets {


					// reset D
					// obecne jak pro radu checkboxu, tak pro sekci search inputu a submitu
					.views-exposed-widget {

						float: initial;
						clear: both;
						padding: 0;
					}



					/* nevyuzita struktura obalovaci > views-widget > form-type-select > form-checkboxes */
					// filtrovaci checkboxy
					.bef-checkboxes {

						display: flex;
						flex-direction: column;

						@include breakpoint($breakpoint_desktop) {

							flex-direction: row;
							flex-wrap: wrap;
						}

						/* obecny - checkbox item */
						.form-item {

							input[type='checkbox'] {

								// reset default vlastnosti z default 05_html_elements
								// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
								margin: 0;
								position: initial;
								top: auto;
								width: auto;

								// stylovani
								display: none;
							}

							input[type='checkbox'] + label {

								// reset default vlastnosti z default 05_html_elements
								// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
								@include typography_font(regular);
								text-transform: none;

								// stylovani
								@include jv-view-filters_tab();
							}
							/* last */
							&.form-item:last-child input[type='checkbox'] + label {

								@include jv-view-filters_tab--lastchild();
							}
							/* active */
							input[type='checkbox'] + label:active,
							input[type='checkbox']:checked + label:active {

								@include jv-view-filters_tab--active();
							}
							/* checked */
							input[type='checkbox']:checked + label {

								@include jv-view-filters_tab--checked();
							}
							/* before */
							input[type='checkbox'] + label:before {

								@include jv-view-filters_tab--before();
							}
							/* checked before */
							input[type='checkbox']:checked + label:before {

								background-color: white;
							}
						}
						/* obecny - checkbox item - barveni */
						.form-item:nth-child(2) {

							/* before */
							input[type='checkbox'] + label:before {

								background-color: $jv-view-filters_tab-1_label_before_background-color;
							}
							/* checked before */
							input[type='checkbox']:checked + label:before {

								//background-color: white;
							}
						}
						.form-item:nth-child(3) {

							/* before */
							input[type='checkbox'] + label:before {

								background-color: $jv-view-filters_tab-2_label_before_background-color;
							}
							/* checked before */
							input[type='checkbox']:checked + label:before {

								//background-color: white;
							}
						}
						.form-item:nth-child(4) {

							/* before */
							input[type='checkbox'] + label:before {

								background-color: $jv-view-filters_tab-3_label_before_background-color;
							}
							/* checked before */
							input[type='checkbox']:checked + label:before {

								//background-color: white;
							}
						}
						.form-item:nth-child(5) {

							/* before */
							input[type='checkbox'] + label:before {

								background-color: $jv-view-filters_tab-4_label_before_background-color;
							}
							/* checked before */
							input[type='checkbox']:checked + label:before {

								//background-color: white;
							}
						}
						/* specificke "vse" */
						.form-item.form-item-all {

							a {

								@include jv-view-filters_tab();
							}
							a:active {

								@include jv-view-filters_tab--active();
							}
							/* before */
							a:before {

								@include jv-view-filters_tab--before();
							}
							/* checked before */
							&.highlight a:before {

								background-color: white;
							}

							&.highlight a {
								background-color: $color_background-7;
								color: $color_background-0;
							}
						}
						.form-item.form-item-edit-field-term-theme-all--checked {

							a {

								@include jv-view-filters_tab--checked();
							}
						}
					}




					// kombo search inputu a submitu
					.views-submit-button-wrapper {

						display: none; // na webu JV nechceme
						//display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: stretch;

						margin-top: 1.5em;


						// search input
						.views-widget {

							flex-grow: 1;
							width: auto;


							@include breakpoint($breakpoint_desktop) {

								flex-grow: 0;
								width: 30%;
							}


							.form-item-combine {

								width: 100%;



								// input editbox
								input[type='text'] {

									// reset default vlastnosti z default 05_html_elements
									// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
									border: 0;
									box-shadow: none;

									// stylovani
									width: 100%;
									height: 2.25em;
									padding-left: 0.5em;

									font-size: 18px;

									background-color: $color_background-2;


									@include breakpoint($breakpoint_desktop) {

										height: 2.25em;

										font-size: 22px;
									}

									&:focus {
										border: 0;
										box-shadow: none;
									}
								}
							}
						}


						// submit
						.views-submit-button {

							padding: 0;

							input[type='submit'] {

								// reset default vlastnosti z default 05_html_elements
								// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
								// - zde netreba vse je nasledne specificky prebito

								// stylovani
								width: $jv-view-filters_search-submit-button_width_mobile;
								height: 2.25em;

								margin-top: 0;
								padding: 1em;

								border: 0;

								text-indent:-9999px;
								color: transparent;
								// IE schovavani textu
								//font-size: 0;
								//line-height: 0;

								font-size: 18px;

								background-color: $color_background-2;

								background-image: $file_icon_search_colored-bg5;
								background-repeat: no-repeat;
								background-position: center center;
								background-size: $jv-view-filters_search-submit_background-icon-size_mobile $jv-view-filters_search-submit_background-icon-size_mobile;

								cursor: pointer;


								@include breakpoint($breakpoint_desktop) {

									width: $jv-view-filters_search-submit-button_width_desktop;
									height: 2.25em;

									font-size: 22px;

									background-size: $jv-view-filters_search-submit_background-icon-size_desktop $jv-view-filters_search-submit_background-icon-size_desktop;
								}
							}
							// TODO pseudohover nedefinovany nikde jak ma vypadat
							input:hover {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}


}
