//
// B-062 Styles
//



.b-062 {
}
	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.b-062 > h2 {
		display: none;
	}

	.b-062__block {

		.b-062--ico &   {
			background-repeat: no-repeat;
			background-position: top left;
			background-size: 3em auto;

			padding-left: 4.5em;

			@include breakpoint($breakpoint_desktop) {
				background-size: 2.5em auto;
				padding-left: 4em;
			}
		}

		.b-062--ico-enter &   {
			background-image: $file_icon_enter_colored-main;
		}

		.b-062--ico-form &   {
			background-image: $file_icon_form_colored-main;
		}

		.b-062--ico-mail &   {
			background-image: $file_icon_mail_colored-main;
		}

		.b-062--ico-new-user &   {
			background-image: $file_icon_new-user_colored-main;
		}

		.b-062--ico-pay-card &   {
			background-image: $file_icon_pay-card_colored-main;
		}
	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-062__block h3 {
			margin: 0;
			margin-bottom: 0.25em;

			@include breakpoint($breakpoint_desktop) {
				padding-bottom: 0; // na desktopu nepotřebujeme
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-062__block p {
			margin: 0;
			color: $color_text_paragraph;

			@include breakpoint($breakpoint_desktop) {
			}
		}

		.b-062__contact {
			display: flex;
			flex-direction: column;

			margin-top: 0.25em;

			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-direction: row;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-062__block .button-bean,
		.b-062__block .contact--data-box,
		.b-062__block .contact--email,
		.b-062__block .contact--phone-number {
			margin-top: 0.75em;
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-062__block .contact--data-box:not(:last-child),
		.b-062__block .contact--email:not(:last-child),
		.b-062__block .contact--phone-number:not(:last-child) {
			margin-right: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 2em;
			}
		}
