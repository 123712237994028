//
// audioplayer s pevnym ukotvenim
//

$color_background_player_custom_alpha: rgba($color_background-0, .9);
$color_background_player_shadow: 0px 0px 40px -5px rgba(0,0,0,.35);

$custom_breakpoint: 1250px;

.audioplayer-floating {
	display: flex;
	position: fixed;
	width: 100%;
	max-width: 708px;
	z-index: 1;
	transition: all 333ms;
	bottom: -100%;
	transform: translate(0, 120%);
	@include breakpoint($breakpoint_tablet) {
		transform: translate(calc(50vw - 50%), 120%);
	}
	@include breakpoint($custom_breakpoint) {
		transform: translate(calc(50% - 125px), 120%);
	}
	// audio se prehrava
	&.is-playing {
		z-index: 999;
		bottom: 0;
		transform: translate(0, 0);
		@include breakpoint($breakpoint_tablet) {
			transform: translate(calc(50vw - 50%), 0);
		}
		@include breakpoint($custom_breakpoint) {
			transform: translate(calc(50% - 125px), 0);
		}
	}
	// obal playeru
	&__wrap {
		background-color: $color_background_player_custom_alpha;
		border-top: 1px solid $color_divider;
		box-shadow: $color_background_player_shadow;
		width: 100%;
		padding: 10px;
		@include breakpoint($breakpoint_tablet) {
			padding: 20px;
			border: 1px solid $color_divider;
			border-radius: 5px 5px 0 0;
		}
	}
	// skryti html5 elementu audio
	&__hidden {
		display: none;
	}
	// zahlavi prehravace
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid $color_divider;
		padding-bottom: 3px;
		margin-bottom: 10px;
		@include breakpoint($breakpoint_desktop) {
			padding-bottom: 15px;
			margin-bottom: 20px;
		}
	}
	// vlastni telo prehravace
	&__main {
		width: 100%;
	}
	// nazev audia
	&__audio-title {
		color: $color_decoration_main;
		font-size: .95rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include breakpoint($breakpoint_tablet) {
			font-size: 1.385rem;
			line-height: 1.3;
		}
	}
	// obal tlacitek
	&__buttons {	
	}
	// tlacitko sdileni
	&__button-share {
		width: 38px;
		height: 38px;
		background: $file_button_action_share left center no-repeat;
		background-size: 76px 38px;
		cursor: pointer;
		@include breakpoint($breakpoint_tablet) {
			width: 32px;
			height: 32px;
			background-size: 64px 32px;
		}
		&:hover {
			background-position: 100%;
		}
	}
	// tlacitko prehrani
	&__play-ico {
		float: left;
		margin-right: 14px;
		min-width: 60px;
		width: 60px;
		height: 60px;
		background: $file_button_play 0 0 no-repeat;
		background-size: 120px 60px;
		cursor: pointer;
		border-radius: 50%;
		-webkit-tap-highlight-color: transparent;
		// prehravani audia je pauznuto
		&--show-pause {
			background-image: $file_button_action_pause;
		}
		@include breakpoint($breakpoint_desktop) {
			margin-right: 30px;
			min-width: 50px;
			width: 50px;
			height: 50px;
			background-size: 100px 50px;
		}
		&:hover {
			background-position: -60px 0;
			@include breakpoint($breakpoint_desktop) {
				background-position: -50px 0;
			}
		}
		&:focus {
			outline: none;
		}
	}
	// obal pro delku audia, datum
	&__metadata {
		display: flex;
		line-height: 1;
		margin-bottom: 10px;
		font-size: .75rem;
		color: $color_background-8;
		position: relative;
		top: 22px;
		@include no-select;
		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
			margin-bottom: 8px;
			top: 0;
		}
	}
	// delka audia
	&__duration {
		&:after {
			content: '|';
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	// datum vysilani
	&__date {
	}
	// obal progressbaru
	&__progressbar-wrap {
		width: calc(100% - 80px);
		float: left;
		position: relative;
		top: 22px;
		@include breakpoint($breakpoint_desktop) {
			width: calc(100% - 260px);
			top: 0;
		}
	}
	// progressbar pozadi
	// volume pozadi
	&__progressbar-bg,
	&__volume-bg {
		width: 100%;
		height: 12px;
		background-color: $color_background-2;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		@include breakpoint($breakpoint_desktop) {
			height: 8px;
		}
	}
	&__volume-bg,
	&__volume-track {
		width: calc(100% - 50px);
		max-width: 100%;
		float: left;
		-webkit-tap-highlight-color: transparent;
	}
	// progressbar popredi
	// volume popredi
	&__progressbar-track,
	&__volume-track {
		height: 12px;
		background-color: $color_decoration_main;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		@include breakpoint($breakpoint_desktop) {
			height: 8px;
		}
	}
	// obal ovladani hlasitosti
	&__volume-wrap {
		display: none;
		@include breakpoint($breakpoint_desktop) {
			display: inline-block;
			width: 160px;
			margin-left: 20px;
		}
	}
	// ikona hlasitosti
	&__volume-ico {
		margin: 0 10px;
		float: left;
		position: relative;
		bottom: 5px;
		width: 24px;
		height: 22px;
		background: $file_icon_badge_audio_colored-main center no-repeat;
		background-size: 24px 22px;
		cursor: pointer;
		&--mute {
			filter: grayscale(100%);
		}
	}
}

// PatternLab only
.patternlab {
	.audioplayer-floating {
		// pouze pro PL, sirka se bude nastavovat dynamicky
		&__volume-track,
		&__progressbar-track {
			width: 75%;			
		}
		&__wrap {
			width: calc(100% - 20px);
		}
	}
}
