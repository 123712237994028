//
// Broadcast Schedule Following
//
// jednoduchy vypis z programu
//



.broadcast-schedule-following {
}
	
	.broadcast-schedule-following__list {
		
		display: flex;
		flex-direction: column;
	}
		
		.broadcast-schedule-following__list-item {
			
			margin-bottom: 0;
			
			display: flex;
			flex-direction: row;
			align-items: baseline;
			
			@include breakpoint($breakpoint_desktop) {
				
				margin-bottom: 0.3334em;
			}
		}
			
			// cas vysilani
			.broadcast-schedule-following__time {
				
				flex-shrink: 0;
				
				display: inline-block;
				width: 3.5em;
				
				@include typography_broadcast_time();
				
				@include breakpoint($breakpoint_desktop) {
					
					width: 3.25em;
				}
			}
			
			// nazev relace
			// z duvodu atomu chybejici BEM selektor
			.broadcast-schedule-following__list-item h5 {
				
				display: inline-block;
				
				@include typography_broadcast_label();
			}