//
// Vary - obecne vlastnosti vsech stranek
//



.vary {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_region_main, $color_region_main_darker);

	/* skryti odkazu na kamery z headeru */
	.b-015__services_list-item:nth-child(3),
	.b-015__services_link--kamery {
		display: none;
	}

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_region_main);
	}
}
