//
// Button Store
//



.button-store {
	
	background-position: center;
	background-repeat: no-repeat;

	background-size: 135px 40px; // pozor, nekde specificky pretezovano dle obrazku

	display: block;

	margin: 0;
	padding: 0;


	// toto si pak pretizi specificke pouziti
	width: 135px;
	height: 40px;

	background-color: $color_background-9;


	span {
		display: none;
	}
}
.button-store:hover {
	background-color: $color_background-9_alpha-low; // tj $color_background-9 s opacitou 0.8
}


.button-store-rounded {
	background-color: transparent;
	width: 3rem;
	height: 3rem;
	min-width: 3rem;
	min-height: 3rem;
	background-size: 200%;
	background-position: 0;
}

.button-store-rounded:hover {
	background-color: transparent;
	background-position: 100%;
}


//
// specificke varianty
//

// apple
.button-store--apple {
	background-image: $file_button_store_apple;
}

.button-store--apple-rounded {
	background-image: $file_button_store_apple_rounded;
}

// google
.button-store--google {
	background-image: $file_button_store_google;
}

.button-store--google-rounded {
	background-image: $file_button_store_google_rounded;
}


// amazon
.button-store--amazon-rounded {
	background-image: $file_button_store_amazon_rounded;
} 