//
// page-podcasty
//



.page-podcasty .main {

	h1 {
		color: $color_text-accent;
	}

	.box {
		//margin-top: 0;

		&.b-050a {
			margin-bottom: 2em;
		}
	}

	.b-050__metainfo-row {
		line-height: 1em;
		margin-top: .3em;

		@include breakpoint($breakpoint_desktop) {
			line-height: 1.2em;
		}
	}



	.bubble-subscribe__wrapper::before {
		left: .5em;
	}

	.b-019__button--subscribe {
		margin-top: .5em;
	}
}