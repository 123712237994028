//
// filter checkboxes styles
//



.filter-checkboxes {
	
	display: flex;
	flex-direction: column;
	
	margin-top: 1em;
	margin-bottom: 2em;
	
	@include breakpoint($breakpoint_desktop) {
		
		flex-direction: row;		
	}
}
	
	// TODO z duvodu atomu zatim ne pres specifickou tridu
	.filter-checkboxes .button-box-flat--dropdown {
		
		@include adjust-font-size-to($fontsize_form_flat_button_desktop);
		
		@include breakpoint($breakpoint_desktop) {
		
		}
	}
		
		.filter-checkboxes .button-box-flat__icon--arrow-south {
			
			// TODO chybejici ikona (ve variables je dosazena opacna a otocena viz. _button-box-flet.scss)
			background-image: $file_button_icon_arrow_east;
	
			@include breakpoint($breakpoint_desktop) {
						
				transform: none;
			}
		}
		
	
	.filter-checkboxes .filter-checkboxes__list {
		
		background-color: $color_background-2;
		
		padding: 1em;
		
		@include breakpoint($breakpoint_desktop) {
			
			display: flex;
			flex-direction: row;
			align-items: center;
			
			padding: 0 1.5em;
		}
	}
	
		.filter-checkboxes .filter-checkboxes__list-item {
			
			margin-bottom: 0.5em;
		
			@include breakpoint($breakpoint_desktop) {
			
				margin-right: 2em;
				margin-bottom: 0;
			}
		}
		
		.filter-checkboxes .filter-checkboxes__list-item:last-child {
		
			margin-bottom: 0;
		
			@include breakpoint($breakpoint_desktop) {
			
				margin-right: 0;
			}
			
		}
		
			.filter-checkboxes .filter-checkboxes__list-item label {
				
				@include adjust-font-size-to($fontsize_form_input_label_desktop);
				
			}