//
// B-031 Styles
//



.b-031 {
}
	.b-031__image {
	}
		.b-031__imagefake {
			background-color: $color_background-4;
		}

		.b-031__imagefake:hover {
			background-color: $color_background-4_alpha-low;
		}

			.b-031__desc-wrap {
				display: flex;
				justify-content: center;
				align-items: center;

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
				.b-031__desc-txt {
					color: $color_background-0;

					text-transform: uppercase;
					font-size: 1.25em;
				}






//
// B-031a Styles
//



.b-031a {
}
	.b-031a__image {
	}
		.b-031a__imagefake--mobile {
			@include aspect-ratio(2, 1);

			max-width: 320px;

			@include breakpoint($breakpoint_desktop) {
				@include aspect-ratio(31, 8);

				display: none;
			}
		}

		.b-031a__imagefake--desktop {
			@include aspect-ratio(2, 1);

			display: none;

			@include breakpoint($breakpoint_desktop) {
				@include aspect-ratio(31, 8);

				display: block;
				max-width: 620px;
			}
		}





//
// B-031b Styles
//



.b-031b {
}
	.b-031b__image {
	}
		.b-031b__imagefake {
			@include aspect-ratio(2, 1);

			max-width: 320px;
		}





//
// B-031c Styles
//



.b-031c {
	margin: 0;
}
	.b-031c__image {
	}
		.b-031c__imagefake {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				@include aspect-ratio(6, 1);

				display: block;
				max-width: 960px;
			}
		}
