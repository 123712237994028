//
// Broadcast label and description
//
// konstrukce nazvu poradu a popisu
//



.broadcast-label-description {
}
	
	// atomy v molekule, neBEMove stylovani
	.broadcast-label-description h4 {
		
		margin-top: 0.25em;
		margin-bottom: 0;
		
		@include typography_broadcast_label();
		
		@include breakpoint($breakpoint_desktop) {
			
			margin-top: 0.5em;
			margin-bottom: 0.5em;
		}
	}
		
		.broadcast-label-description h4 a {
		}
	
	
	// atomy v molekule, neBEMove stylovani
	.broadcast-label-description p {
		
		margin-top: 0;
		
		color: $color_text_paragraph;
		
		@include breakpoint($breakpoint_desktop) {
			
			margin-top: 0.5em;
		}
	}