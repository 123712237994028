//
// Cesty za vodou - obecne vlastnosti vsech stranek
//



$cestyzavodou_bg_header_desktop: url('../images/bg/cestyzavodou/cestyzavodou_header_desktop.jpg');
$cestyzavodou_bg_header_mobile: url('../images/bg/cestyzavodou/cestyzavodou_header_mobile.jpg');
$cestyzavodou_logo_desktop: url('../images/logo/cestyzavodou/logo_cestyzavodou.svg');


// duplicity s _b-016.scss
$cestyzavodou_b-016_menu-bar-height_mobile: 75px;
$cestyzavodou_b-016_menu-bar-height_desktop: 140px;
$cestyzavodou_b-016_menu-logo-size_desktop: 75px;
$cestyzavodou_b-016_menu-logo-size_mobile: 45px;
$cestyzavodou_b-016_menu-logo-margin-left_desktop: 40px;
$cestyzavodou_b-016_menu-logo-margin-right_desktop: 30px;
$cestyzavodou_b-016_menu-logo-margin-left_mobile: 10px;
$cestyzavodou_b-016_menu-logo-margin-right_mobile: 20px;



.cestyzavodou {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_cestyzavodou_main, $color_cestyzavodou_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_cestyzavodou_main);
	}


	// bg
	// before po prihlaseni kolidovalo s adminimal menu
	&::after {
		content: "";

		width: 100vw;
		height: 100vh;

		position: absolute;
		top: 0;
		left: 0;

		background-image: $cestyzavodou_bg_header_mobile;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;

		z-index: -2;

		@include breakpoint($breakpoint_desktop) {
			background-image: $cestyzavodou_bg_header_desktop;
		}
	}
	// bg fixed
	&.fixed::after {
		position: fixed;
		top: calc(-100vh + #{$cestyzavodou_b-016_menu-bar-height_mobile});

		//top: 0;
		//height: calc(#{$cestyzavodou_b-016_menu-bar-height_mobile});

		z-index: 98; // z-index: 99 ma .b-016__wrapper.fixed

		@include breakpoint($breakpoint_desktop) {
			top: calc(-100vh + #{$cestyzavodou_b-016_menu-bar-height_desktop});

			//top: 0;
			//height: calc(#{$cestyzavodou_b-016_menu-bar-height_desktop});

			//background-position: 50% 43%;
		}
	}



	header {
		position: relative;

		//@include transition(all 200ms ease-in-out);
		//transition: all 0.5s ease;

		@include breakpoint($breakpoint_desktop) {
			//margin-bottom: 3em;
		}

		// header bg
		.header__promo {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			padding-bottom: $cestyzavodou_b-016_menu-bar-height_mobile;

			width: 100%;
			//height: calc(100vh - #{$cestyzavodou_b-016_menu-bar-height_mobile});
			height: calc(100vh - #{$b-014a_menu-bar-height_mobile});

			@include breakpoint($breakpoint_desktop) {
				padding-bottom: $cestyzavodou_b-016_menu-bar-height_desktop;

				//height: calc(100vh - #{$cestyzavodou_b-016_menu-bar-height_desktop});
				height: calc(100vh - #{$b-014a_menu-bar-height_desktop});

			}
		}

			.header__logo {
				max-width: 685px;
				//max-height: 105px;

				min-width: 185px;
				//min-height: 28px;

				//width: 80%;
				//height: 80%;

				margin: 50px;

				/*background-image: $cestyzavodou_logo_desktop;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: 100% 100%;*/

				@include breakpoint($breakpoint_desktop) {
					margin-top: 350px;
				}

				/*&:hover {
					@include breakpoint($breakpoint_desktop) {
						background-image: $cestyzavodou_logo_fx_desktop;
					}
				}*/

				span {
					display: none;
				}
			}

	}



	// hp vs podstranky
	// nav ma byt vzdy ve stavu fixed u podstranek
	// a podle toho se musi nastavit i dalsi elementy

	// stanicni nav wrapper
	&:not(.page-cestyzavodou-hp) {

		// nav nechceme
		.b-014a,
		.b-014b {
			display: none;
		}

		// bg fixed
		// je automaticky pro podstranky
		&::after {
			position: fixed;
			top: calc(-100vh + #{$cestyzavodou_b-016_menu-bar-height_mobile});

			//top: 0;
			//height: calc(#{$cestyzavodou_b-016_menu-bar-height_mobile});

			z-index: 98; // z-index: 99 ma .b-016__wrapper.fixed

			@include breakpoint($breakpoint_desktop) {
				top: calc(-100vh + #{$cestyzavodou_b-016_menu-bar-height_desktop});

				//top: 0;
				//height: calc(#{$cestyzavodou_b-016_menu-bar-height_desktop});

				//background-position: 50% 43%;
			}
		}

		header {
			margin-bottom: 3em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 2em;
			}

			.header__promo {
				height: $cestyzavodou_b-016_menu-bar-height_mobile;

				@include breakpoint($breakpoint_desktop) {
					height: $cestyzavodou_b-016_menu-bar-height_desktop;
				}

				.header__logo {
					display: none
				}
			}
		}

		// stanicni nav wrapper
		// je automaticky pro podstranky
		.b-016__wrapper {
			position: fixed;
			top: 0;
			bottom: unset;

			z-index: 99;

			@include breakpoint($breakpoint_desktop) {
				max-width: $layout_page_max-width;
				margin: auto;
			}

			/*.nav__logo {
				//display: block;

				width: $cestyzavodou_b-016_menu-logo-size_mobile;
				height: $cestyzavodou_b-016_menu-logo-size_mobile;

				margin-left: $cestyzavodou_b-016_menu-logo-margin-left_mobile;
				margin-right: $cestyzavodou_b-016_menu-logo-margin-right_mobile;

				@include breakpoint($breakpoint_desktop) {
					width: $cestyzavodou_b-016_menu-logo-size_desktop;
					height: $cestyzavodou_b-016_menu-logo-size_desktop;

					margin-left: $cestyzavodou_b-016_menu-logo-margin-left_desktop;
					margin-right: $cestyzavodou_b-016_menu-logo-margin-right_desktop;
				}
			}*/
		}
	}



	// main
	.main {
		margin-top: 0;
	}

	//infoblock
	.cestyzavodou-footer {
		border-top: 1px solid $color_background-4;
		color: $color_background-6;
		text-align: center;
		padding: 2rem 0;
		font-size: 1.1rem;
		margin-top: 3rem;
		margin-bottom: -4em;

		@include breakpoint($breakpoint_desktop) {
			padding: 3rem;
			margin-top: 6rem;
			margin-bottom: -6em;
		}
		p:first-child {
			margin-top: 0;
		}
		p:last-child {
			margin: 0;
		}
	}
}
