//
// B-008m Styles
//
// blizka podobnost s boxem b008h
//



.b-008m {
	margin-top: 0;
}

	.b-008m__subsection {
	}

		.b-008m__list {
			@include breakpoint($breakpoint_desktop) {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
			}
		}

			.b-008m__list-item {
				flex-grow: 1;
				padding-top: 1.2em;
				border-top: 1px solid $color_divider;
				@include breakpoint($breakpoint_desktop) {
					margin-right: 2em;
					margin-bottom: 2em;
					flex-basis: 47%;
					padding-top: 0;
					border-top: 0;
				}
			}

			.b-008m__list-item:first-child {
				border-top: 0;
				padding-top: 0;
			}

			.b-008m__list-item:nth-child(2n) {
				@include breakpoint($breakpoint_desktop) {
					margin-right: 0;
				}
			}

				.b-008m__block--image {
				}

				.b-008m__block--image:hover + .b-008m__block--divided .b-008m__button-play-wrapper .button-play {
					background-position: 100%;
				}

					.b-008m__image {
					}


				.b-008m__block--fullwidth {
					display: flex;
					flex-wrap: wrap;
					flex-basis: 100%;
					padding-left: .5em;
					padding-right: .5em;
					padding-bottom: 1.2em;
					@include breakpoint($breakpoint_desktop) {
						padding: 0;
					}
				}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-008m__block--fullwidth a:not(.button) {
							flex-basis: 100%;
							line-height: 1; // oprava poskakovani pri hoveru
						}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-008m__block--fullwidth a p {
							flex-basis: 100%;
							margin-top: 0;
							margin-bottom: 8px;
							color: $color_text_paragraph;
							font-size: .85rem;
							line-height: 1.4;
							@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
							@include breakpoint($breakpoint_desktop) {
								margin-top: 2px;
								font-size: 1rem;
							}
						}

						.b-008m__block--fullwidth .button.button-box-flat--readmore {

							display: inline-flex;
						}

				.b-008m__block--divided {
					display: inline-flex;
					padding-top: .65em;
					padding-right: .5em;
					padding-left: .5em;
					@include breakpoint($breakpoint_desktop) {
						padding-left: 0;
						padding-right: 0;
					}
				}

				// hover stav
				.b-008m__block--divided:hover h3 {
					text-decoration: underline;
				}

				.b-008m__block--divided:hover .button.button-play {
					background-position: 100%;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008m__block--divided h3 {
						margin: 0;
						line-height: 1.2;
						font-size: .9rem;
						@include breakpoint($breakpoint_desktop) {
							font-size: 1.1rem;
						}
					}
					.b-008m__block--divided h3 + a {
						margin-left: 6px;
						line-height: 1.2;
						font-size: .7rem;
						@include breakpoint($breakpoint_desktop) {
							font-size: .9rem;
						}
					}

					.b-008m__button-play-wrapper {
						margin-right: 6px;
						@include breakpoint($breakpoint_desktop) {
							margin-right: 12px;
						}
					}

					.b-008m__button-play-wrapper .button.button-play {
						width: 20px;
						height: 20px;
					}
