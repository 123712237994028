//
// B-068 Styles
//


// vars
//$breakpoint_desktop: 0; //debug
$b-068_padding_mobile: 1em;
$b-068_padding_desktop: 1.25em;
$b-068_image-width_mobile: 30%;
$b-068_image-height_desktop: 12em;



.b-068 {
}

	// subsection
	.b-068__subsection {
		padding: $b-068_padding_mobile;

		background-color: $color_background-2;
		border-radius: 8px;

		@include breakpoint($breakpoint_desktop) {
			padding: $b-068_padding_desktop;
		}
	}

		// block
		.b-068__block {
		}

		// block: description
		.b-068__block--description {
			display: flex;
			flex-direction: row;

			@include breakpoint($breakpoint_desktop) {
			}
		}

			// subblock
			.b-068__subblock {
			}

			// subblock: description
			.b-068__subblock--description {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@include breakpoint($breakpoint_desktop) {
				}
			}

			// metadata
			.b-068__metadata {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

				// metadata: tag
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-068__metadata .tag:not(.tag-rounded) {
					margin-bottom: 0.75em;
					margin-right: 0.5em;
					//padding: 2px 4px;
					padding: 0 4px;

					@include typography_font(medium);
					font-size: 12px;
					//line-height: 12px;
					line-height: 16px;
					text-transform: uppercase;

					@include breakpoint($breakpoint_desktop) {
						margin-right: 0.75em;
						font-size: 13px;
						//line-height: 13px;
						line-height: 17px;
					}
				}

					// iRozhlas
					.b-068.irozhlas .b-068__metadata .tag:not(.tag-rounded) {

						span {
							//animation: width-fix 1ms;
						}

						span:first-letter {
							text-transform: lowercase;
						}

						// Firefox Fix (chybna sirka)
						// https://stackoverflow.com/questions/41425083/css-first-letter-glitch-in-firefox
						// https://stackoverflow.com/questions/7548255/is-there-a-css-workaround-for-firefox-bug-inline-block-first-letter-with-cha/7553176#7553176
						/*@keyframes width-fix {
							from { padding-right: 0.1px; }
							to { padding-right: 0; }
						}*/
					}


				// metadata: info
				.b-068__metadata-info {
					margin-bottom: 0.75em;

					color: $color_text_paragraph;

					font-size: 12px;
					line-height: 1.3;

					@include breakpoint($breakpoint_desktop) {
						font-size: 13px;
					}
				}


				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-068__subblock--description > h3 {
					margin: 0;

					font-size: 16px;
					line-height: 1.3;

					@include breakpoint($breakpoint_desktop) {
						font-size: 22px;
					}

					// TODO
					a {
						@include link-style($color_text_accent, none, $color_text_accent, underline);
					}
				}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-068__subblock--description > a:not(.button) {

					@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
				}

				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-068__subblock--description > p,
				.b-068__subblock--description > a:not(.button) > p {
					display: inline-block;
					margin: 0.5em 0 1em 0;
					@include lineClamp(3);
					color: $color_text_paragraph;

					@include breakpoint($breakpoint_desktop) {
						margin: 0.5em 0 1em 0;
						font-size: 15px; // default je 16px
					}
				}

				// caption
				.b-068__subblock--description .caption {
					align-items: center; // prebiji defaultni hodnoty z /01_patternlab/07_defaults/_caption.scss
					margin-bottom: 0.5em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 1em;
					}

					.caption__icon {
						width: 0.75em; // prebiji defaultni hodnoty z /01_patternlab/07_defaults/_caption.scss
						height: 0.75em; // prebiji defaultni hodnoty z /01_patternlab/07_defaults/_caption.scss
					}
				}

				// buton-audio-archive
				.b-068__subblock--description .button-simple--audio-archive {
					margin-bottom: 1em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0;
					}
				}


			// subblock: image
			.b-068__subblock--image {
				flex-shrink: 0;
				margin-left: 1em;
				width: $b-068_image-width_mobile;

				@include breakpoint($breakpoint_desktop) {
					margin-left: 1.5em;
					width: auto;
				}
			}

				// image
				.b-068__image {

					@include breakpoint($breakpoint_desktop) {
						height: $b-068_image-height_desktop;
					}

					a {
						display: block;

						width: 100%;
						height: 100%;
					}

					img {
						width: 100%;
						height: auto;
						transition: transform 0.3s;

						@include breakpoint($breakpoint_desktop) {
							width: auto;
							height: 100%;
						}
					}
				}

				// image: hover
				.b-068__image:hover {
					img {
						@include breakpoint($breakpoint_desktop) {
							transform: scale(1.1);
						}
					}
				}


		// block: episode
		.b-068__block--episode {
			margin-top: 1em;

			@include breakpoint($breakpoint_desktop) {
			}
		}

			// episode: list
			.b-068__episode-list {
				position: relative; // kvuli gradientu
			}

				// episode: item
				.b-068__episode-list-item {
					margin-bottom: 0.75em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0.5em;
						font-size: 15px; // default je 16px
						line-height: 1.3;
					}
				}

				// episode item: first 7 visible
				/*.b-068__episode-list-item:nth-child(n+8) {
					@include breakpoint($breakpoint_desktop) {
						display: none;
					}
				}*/

				// episode item: last-child
				.b-068__episode-list-item:last-child {
					margin-bottom: 0;
				}

					// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
					.b-068__episode-list-item h4 {
						margin: 0;
						color: $color_background-5;

						span:not(.button-play) {
							@include lineClamp(2);
						}

						// span: last-child
						span:last-child {
							@include breakpoint($breakpoint_desktop) {
								/*display: -webkit-box;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;*/

								/*white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;*/
							}
						}

						// TODO
						a {
							display: flex; // pro potreby ikony play
							@include link-style($color_text_link, none, $color_text_link, underline);
						}

							// ico play
							.button.button-play {
								flex-shrink: 0;

								width: 20px;
								height: 20px;

								margin-right: 0.5em;
							}
					}


			// gradient
			.b-068__episode-list::after {
				content: '';

				position: absolute;
				top: 0;
				left: 0;
				left: -#{$b-068_padding_mobile};

				width: 100%;
				width: calc(100% + 2 * #{$b-068_padding_mobile});
				height: 100%;

				// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
				//background-image: linear-gradient(to top, $color_background-2, transparent 75%);
				background-image: linear-gradient(to top, $color_background-2, rgba(#ffffff, 0) 75%); // list: gradient color

				pointer-events: none; // click skrz

				@include breakpoint($breakpoint_desktop) {
					left: -#{$b-068_padding_desktop};
					width: calc(100% + 2 * #{$b-068_padding_desktop});
				}
			}

			// readmore
			.b-068__block--episode .button-simple--readmore {
				margin-top: 0.5em;

				@include breakpoint($breakpoint_desktop) {
					//margin-top: 0;
				}
			}


// placeholder
.b-068--placeholder {

	.b-068__subsection {
		pointer-events: none;
		animation: placeholder-loading 3s infinite;

		// relative
		.b-068__block--description .b-068__subblock--description .b-068__metadata .tag,
		.b-068__block--description .b-068__subblock--description .b-068__metadata .b-068__metadata-info,
		.b-068__block--description .b-068__subblock--description h3,
		.b-068__block--description .b-068__subblock--description > p,
		.b-068__block--description .b-068__subblock--description > a:not(.button) > p,
		.b-068__block--description .b-068__subblock--description .caption,
		.b-068__block--description .b-068__subblock--description .button,
		.b-068__block--description .b-068__subblock--image .b-068__image,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span.button,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span:not(.button),
		.b-068__block--episode > .button {
			position: relative;
		}

		// hidden
		.b-068__block--description .b-068__subblock--description .b-068__metadata .tag,
		.b-068__block--description .b-068__subblock--description .b-068__metadata .b-068__metadata-info,
		.b-068__block--description .b-068__subblock--description h3,
		.b-068__block--description .b-068__subblock--description > p,
		.b-068__block--description .b-068__subblock--description > a:not(.button) > p,
		.b-068__block--description .b-068__subblock--description .caption,
		.b-068__block--description .b-068__subblock--description .button,
		.b-068__block--description .b-068__subblock--image .b-068__image,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span.button,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span:not(.button),
		.b-068__block--episode > .button {
			visibility: hidden;
		}

		// safari fix: line-clamp vs. visibilty hidden
		.b-068__block--description .b-068__subblock--description > p,
		.b-068__block--description .b-068__subblock--description > a:not(.button) > p {
			max-height: 4.125em ; // line-height 1.375em * 3 row
		}
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span:not(.button) {
			max-height: 2.75em; // line-height 1.375em * 2 row
		}

		// no-data fallback
		.b-068__block--description .b-068__subblock--description .b-068__metadata .tag,
		.b-068__block--description .b-068__subblock--description .b-068__metadata .b-068__metadata-info,
		.b-068__block--description .b-068__subblock--description h3,
		.b-068__block--description .b-068__subblock--description > p,
		.b-068__block--description .b-068__subblock--description > a:not(.button) > p,
		.b-068__block--description .b-068__subblock--description .caption,
		.b-068__block--description .b-068__subblock--description .button,
		/*.b-068__block--description .b-068__subblock--image .b-068__image,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span.button,*/
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span:not(.button),
		.b-068__block--episode > .button {
			min-width: 25px;
			min-height: 15px;
		}
		.b-068__block--description .b-068__subblock--description h3,
		.b-068__block--description .b-068__subblock--description > a:not(.button),
		.b-068__block--description .b-068__subblock--description > p,
		.b-068__block--description .b-068__subblock--image .b-068__image,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span:not(.button) {
			width: 100%;
		}
		.b-068__block--description .b-068__subblock--image .b-068__image {

			@include breakpoint($breakpoint_desktop) {
				//width: 18em; // 3:2
				//height: 12em; // 3:2

				width: 12em; // 1:1
				height: 12em; // 1:1
			}
		}
		.b-068__block--description .b-068__subblock--image .b-068__image:before {
			content: '';
			display: block;
			//padding-top: 66.66%; // 3:2
			padding-top: 100%; // 1:1

			@include breakpoint($breakpoint_desktop) {
				content: initial;
				display: initial;
				padding-bottom: initial;
			}
		}
		.b-068__block--description .b-068__subblock--image .b-068__image a {
			position: absolute;

			@include breakpoint($breakpoint_desktop) {
				position: initial;
			}
		}

		// after
		.b-068__block--description .b-068__subblock--description .b-068__metadata .tag::after,
		.b-068__block--description .b-068__subblock--description .b-068__metadata .b-068__metadata-info::after,
		.b-068__block--description .b-068__subblock--description h3::after,
		.b-068__block--description .b-068__subblock--description > p::after,
		.b-068__block--description .b-068__subblock--description > a:not(.button) > p::after,
		.b-068__block--description .b-068__subblock--description .caption::after,
		.b-068__block--description .b-068__subblock--description .button::after,
		.b-068__block--description .b-068__subblock--image .b-068__image::after,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span.button::after,
		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span:not(.button)::after,
		.b-068__block--episode > .button::after {
			content: '';

			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			background-color: $color_background-3;
			border-radius: 4px;

			visibility: visible;
		}

		.b-068__block--episode .b-068__episode-list .b-068__episode-list-item h4 a span.button::after {
			background-image: none;
			border-radius: 100%;
			opacity: 1;
		}

		// animation
		@keyframes placeholder-loading {
			0% {
				opacity: 0.3;
			}
			25% {
				opacity: 0.7;
			}
			50% {
				opacity: 0.7;
			}
			75% {
				opacity: 0.7;
			}
			100% {
				opacity: 0.3;
			}
		}
	}
}





// vizualni debug - plab only
.patternlab .b-068 {

	@include breakpoint($breakpoint_desktop) {
		max-width: 770px;
	}
}
