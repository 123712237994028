//
// B-002j Styles
//



$b-002j_font-size_h3_desktop: 16px;
$b-002j_line-height_h3_desktop: 20px;

$b-002j_font-size_h3_primary_mobile: 19px;
$b-002j_line-height_h3_primary_mobile: 23px;

$b-002j_font-size_h3_secondary_mobile: 14px;
$b-002j_line-height_h3_secondary_mobile: 18px;



.b-002j {
}

	// stitek iRozhlas
	.b-002j__link {
		display: none;

		background-image: $file_logo_irozhlas_colored-white;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 60px auto;
		background-color: $color_irozhlas_main;

		width: 70px;
		height: 20px;

		@include breakpoint($breakpoint_desktop) {
			display: block;

			background-size: 85px auto;

			width: 100px;
			height: 25px;
		}

		h2 {
			display: none;
		}
	}

	// tag iRozhlas misto h2
	.b-002j > .tag__logo--irozhlas {
		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: inline-block;
		}
	}

	.b-002j__list {
		display: flex;
		flex-direction: column;

		padding-bottom: 10px;
		background-color: $color_background-2;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;

			padding-bottom: 0;
			background-color: transparent;
		}
	}

		.b-002j__list-item {
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 0;

			border-top: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
				flex-basis: 25%;

				margin-left: 0;
				margin-right: 2em;
				margin-bottom: 0;

				border-top: none;
			}
		}

		// specificke upravy pro banner item a jeho deti
		.b-002j__list-item--banner {
			border-top: none;

			.b-002j__image {
				display: block;
			}

				.b-002j__image-link {
					display: block;
				}
		}

		// specificke upravy pro prvni item a jeho deti
		.b-002j__list-item:first-child {
			margin-left: 0;
			margin-right: 0;

			border-top: none;

			@include breakpoint($breakpoint_desktop) {
				margin-left: 0;
				margin-right: 2em;
			}

			.b-002j__image {
				display: block;
			}

				.b-002j__image-link {
					display: block;
				}

				.b-002j__image .badge {
					position: absolute;
					top: 0;
					right: 10px;
				}

				.b-002j__image .tag {
					position: absolute;
					bottom: 0;
					left: 10px;

					max-inline-size: calc(100% - 20px);

					margin-top: 0;

					&--irozhlas {
						display: inline-block;

						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}
				}
		}

		// specificke upravy pro posledni item a jeho deti
		.b-002j__list-item:last-child {
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}

			&:not(.b-002j__list-item--banner) {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: list-item;
				}
			}
		}

		// specificke upravy pro prvni a neposledni item a jeho deti
		.b-002j__list-item:first-child:not(:last-child) {

			.b-002j__image .button-video-play-box {
				display: flex;
			}
		}

			.b-002j__block {
				position: relative;
			}

				.b-002j__image {
					position: relative;

					display: flex;
					justify-content: space-between;
					align-items: flex-end;

					@include breakpoint($breakpoint_desktop) {
						display: block;
					}
				}

					.b-002j__image-link {
						display: none;

						@include breakpoint($breakpoint_desktop) {
							display: block;
						}
					}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002j__image .badge {
						position: relative;
						margin-right: 0;
						margin-left: auto;

						@include breakpoint($breakpoint_desktop) {
							position: absolute;
							top: 0;
							right: 10px;
						}
					}

					// buttonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002j__image .button-video-play-box {
						display: none;

						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;

						@include breakpoint($breakpoint_desktop) {
							display: flex;
						}
					}

					// tag
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002j__image .tag {
						position: relative;

						margin-top: 0.5em;

						@include breakpoint($breakpoint_desktop) {
							position: absolute;
							bottom: 0;
							left: 10px;

							max-inline-size: calc(100% - 20px);

							margin-top: 0;
						}

						&--irozhlas {
							display: none;
						}
					}


			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002j__list-item h3 {
				font-size: $b-002j_font-size_h3_secondary_mobile;
				line-height: $b-002j_line-height_h3_secondary_mobile;

				margin: 0;
				padding: 10px 0;

				@include breakpoint($breakpoint_desktop) {
					font-size: $b-002j_font-size_h3_desktop;
					line-height: $b-002j_line-height_h3_desktop;

					padding: 10px 0 0 0;
				}
			}
			.b-002j__list-item:first-child h3 {
				font-size: $b-002j_font-size_h3_primary_mobile;
				line-height: $b-002j_line-height_h3_primary_mobile;

				padding: 10px;

				@include breakpoint($breakpoint_desktop) {
					font-size: $b-002j_font-size_h3_desktop;
					line-height: $b-002j_line-height_h3_desktop;

					padding: 10px 0 0 0;
				}
			}


// varianta kdy itemy jsou jednotlive boxy, viz RZ hp
.b-002j__wrapper {
	display: flex;
	flex-direction: column;

	@include breakpoint($breakpoint_desktop) {
		flex-direction: row;
		flex-wrap: wrap;
	}

	> h2 {
		flex-basis: 100%;
	}

	.b-002j {
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 0;

			// trik jak 4 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 20%; // toto neni ctvrtina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do tretin
			max-width: 24%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(25% - 1.5em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 1.5em? protoze je to 2 * 3 / 4 viz (*)

			margin-right: 2em; // (*)
		}

		// h2
		& > h2 {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: inline-block;
				visibility: hidden;
			}
		}

		// tag iRozhlas misto h2
		& > .tag__logo--irozhlas {
			display: none;

			@include breakpoint($breakpoint_desktop) {
				display: inline-block;
				visibility: hidden;
			}
		}

		.b-002j__list {
			display: block; // ma jen jeden item, tak netreba flexovat, navic vadi ie11
			padding: 0;
		}

			.b-002j__list-item {
				margin: 0 10px;

				@include breakpoint($breakpoint_desktop) {
					margin: 0;
				}
			}

				.b-002j__block {
				}

					.b-002j__image {
						position: relative;

						display: flex;
						justify-content: space-between;
						align-items: flex-end;

						@include breakpoint($breakpoint_desktop) {
							display: block;
						}
					}

						.b-002j__image-link {
							display: none;

							@include breakpoint($breakpoint_desktop) {
								display: block;
							}
						}

						.b-002j__image .badge {
							position: relative;
							right: 0;

							margin-right: 0;
							margin-left: auto;

							@include breakpoint($breakpoint_desktop) {
								position: absolute;
								top: 0;
								right: 10px;
							}
						}

						.b-002j__image .tag {
							position: relative;
							left: 0;

							margin-top: 0.5em;

							@include breakpoint($breakpoint_desktop) {
								position: absolute;
								bottom: 0;
								left: 10px;

								max-inline-size: calc(100% - 20px);

								margin-top: 0;
							}

							&--irozhlas {
								display: none;
							}
						}


				h3 {
					font-size: $b-002j_font-size_h3_secondary_mobile;
					line-height: $b-002j_line-height_h3_secondary_mobile;

					margin: 0;
					padding: 10px 0;

					@include breakpoint($breakpoint_desktop) {
						font-size: $b-002j_font-size_h3_desktop;
						line-height: $b-002j_line-height_h3_desktop;

						padding: 10px 0 0 0;
					}
				}


			// specificke upravy pro prvni a posledni item a jeho deti
			.b-002j__list-item:first-child:last-child {
				display: list-item;
				border-top: 1px solid $color_divider;

				@include breakpoint($breakpoint_desktop) {
					border-top: none;
				}
			}
	}

	.b-002j--banner {
		.b-002j__list {
		}

			.b-002j__list-item {
			}

				.b-002j__block {
				}

					.b-002j__image {
						display: block;
					}

						.b-002j__image-link {
							display: block;
						}
	}

	.b-002j:first-child {
		& > h2 {
			display: inline-block;

			@include breakpoint($breakpoint_desktop) {
				visibility: visible;
			}
		}

		& > .tag__logo--irozhlas {
			visibility: hidden;

			@include breakpoint($breakpoint_desktop) {
				visibility: visible;
			}
		}

		.b-002j__list {
		}

			.b-002j__list-item {
				margin: 0;
			}

				.b-002j__block {
				}

					.b-002j__image {
						display: block;
					}

						.b-002j__image-link {
							display: block;
						}

						.b-002j__image .badge {
							position: absolute;
							top: 0;
							right: 10px;
						}

						.b-002j__image .button-video-play-box {
							display: flex;
						}

						.b-002j__image .tag {
							position: absolute;
							bottom: 0;
							left: 10px;

							max-inline-size: calc(100% - 20px);

							margin-top: 0;

							&--irozhlas {
								display: inline-block;

								@include breakpoint($breakpoint_desktop) {
									display: none;
								}
							}
						}


				h3 {
					font-size: $b-002j_font-size_h3_primary_mobile;
					line-height: $b-002j_line-height_h3_primary_mobile;

					padding: 10px;

					@include breakpoint($breakpoint_desktop) {
						font-size: $b-002j_font-size_h3_desktop;
						line-height: $b-002j_line-height_h3_desktop;

						padding: 10px 0 0 0;
					}
				}


			// specificke upravy pro prvni a posledni item a jeho deti
			.b-002j__list-item:first-child:last-child {
				border-top: none;
			}
	}

	.b-002j:last-child {
		margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}

		.b-002j__list {
			padding-bottom: 10px;

			@include breakpoint($breakpoint_desktop) {
				padding-bottom: 0;
			}
		}
	}

	/*.b-002j:not(.b-002j--banner):nth-child(4n) {
		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: block;
		}

	}*/

	.b-002j:nth-child(4n) .b-002j__list-item:not(.b-002j__list-item--banner) {
		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: block;
		}

	}
}
