//
// B-041d Styles
//



.b-041d {
	
	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	@include breakpoint($breakpoint_desktop) {
		max-width: 400px;
	}
}

	.b-041d__list {
	}
		
		.b-041d__list-item {
			
			margin-bottom: 1em;
		}
		
		.b-041d__list-item:last-child {
			
			margin-bottom: 0;
		}
			
			.b-041d__list-item .b-041d__image {
			}
		
			.b-041d__list-item h3 {
			
				margin: 0;
				padding: 0.25em 0;
		
				@include breakpoint($breakpoint_desktop) {
					
					padding: 0.5em 0;
				}
			}