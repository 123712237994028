//
// B-061 Styles
//



.b-061 {
}

	.b-061__block {
		background-color: $color_background-1;
		padding: 1em;
	}

	.b-061__description--top {
		margin-top: 0;
		margin-bottom: 1em;

		color: $color_text_paragraph;
	}

	.b-061__code {
		margin-top: 0;
		margin-bottom: 0.25em;

		@include typography_font(medium);
		font-size: 22px;
	}

	.b-061__phone-description {
		display: inline-block;
		margin-top: 0.75em;
		color: $color_text_paragraph;
	}

	.b-061__phone-number {
		background-color: $color_background-7;
		color: $color_text_accent_inverted;

		@include typography_font(medium);
		font-size: 40px;
		text-align: center;

		padding: 0.5em;
	}

	.b-061__description--bottom {
		margin-top: 1em;
		margin-bottom: 0;

		color: $color_text_paragraph;
	}

	.b-061__readmore {
		font-size: 14px;
		@include link-style ($color_decoration_main, none, $color_decoration_main, none);
	}





// vizualni debug - plab only
.patternlab .b-061 {
	max-width: 400px;
}
