//
// jQuery kalendar
//


.ui-datepicker {
	border: 0 !important;
	padding: 15px;
	border-radius: 0;
	box-shadow: $shadow_bubble;
	background-color: $color_background-0 !important;
	position: relative; // slouzi k pozicovani pseudoelementu vytvarejici "zobacek" - pouze u programu a zaznamu
	th {
		padding: .5em .3em;
	}
	td {
		padding: 0;
	}
	.ui-datepicker-div {
		background-color: $color_background-0;
	}
	// hlavicka
	.ui-datepicker-header {
		background: transparent;
		border: 0;
		padding: 4px 0 10px 0;
		// nazev mesice a roku
		.ui-datepicker-month,
		.ui-datepicker-year {
			@include typography_font(medium);
			font-size: .9rem;
			text-transform: uppercase;
			color: $color_background-8;
			@include no-select;
		}
		// sipka vlevo
		.ui-datepicker-prev {
			&.ui-state-hover {
				border: 0;
				top: 2px;
				left: 2px;
				background-image: none;
				background-color: transparent;
			}
			.ui-icon-circle-triangle-w {
				background: $file_button_icon_arrow_east_colored-blue no-repeat center transparent;
				background-size: 18px 18px;
				transform: rotate(180deg);
			}
			&:not(.ui-state-disabled) {
				opacity: .7;
				transition: opacity 150ms;
				border: 0;
				cursor: pointer;
				background-color: transparent;
				background-image: none;
				&.ui-state-hover {
					opacity: 1;
				}
			}
		}
		// sipka vpravo
		.ui-datepicker-next {
			&.ui-state-hover {
				border: 0;
				top: 2px;
				right: 2px;
				background-image: none;
				background-color: transparent;
			}
			.ui-icon-circle-triangle-e {
				background: $file_button_icon_arrow_east_colored-blue no-repeat center transparent;
				background-size: 18px 18px;
			}
			&:not(.ui-state-disabled) {
				opacity: .7;
				transition: opacity 150ms;
				border: 0;
				cursor: pointer;
				background-color: transparent;
				background-image: none;
				&.ui-state-hover {
					opacity: 1;
				}
			}
		}
	}
}
.ui-datepicker-calendar {
	// nazvy dnu v tydnu
	& thead tr th {
		@include typography_font(medium);
		font-size: .8rem;
		text-transform: uppercase;
		color: $color_background-8;
		background-color: transparent;
		max-width: 26px;
		margin: auto;
		@include no-select;
	}
	// jednotlive dny
	& tbody tr td {
		.ui-state-default {
			@include typography_font(medium);
			display: flex;
			justify-content: center;
			align-items: center;
			background: transparent;
			border: 0;
			text-align: center;
			font-size: .75rem;
			color: $color_background-6;
			width: 24px;
			height: 24px;
			margin: 3px auto;
			@include no-select;
		}
		&:not(.ui-state-disabled) > a {
			transition: background-color 150ms ease-out, color 150ms;
			border-radius: 50%;
			// zamereni mysi aktivnich dnu
			&:hover {
				color: $color_background-0;
				background-color: $color_decoration_main;
				@include typography_font(medium);
			}
		}
		// den, ktery mam aktualne zobrazeny
		&.ui-datepicker-current-day > a {
			color: $color_background-0;
			background-color: $color_decoration_main;
			@include typography_font(medium);
		}
		// oznaceni dnesniho dne
		&.ui-datepicker-today > a {
			@include typography_font(medium);
			color: $color_background-8;
			background-color: $color_background-3;
			&:hover {
				color: $color_background-0;
				text-decoration: none;
			}
		}
	}
}

.page-playlisty,
.page-program,
.page-zaznamy {
	.ui-datepicker {
		// stin pro pseudoelemet vytvarejici "zobacek"
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -10px;
			left: calc(50% - 10px);
			width: 20px;
			height: 20px;
			background-color: $color_background-0;
			transform: rotate(45deg);
			box-shadow: $shadow_bubble;
			z-index: -1;
		}
		// skouzi ke kryti nechteneho stinu
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: -10px;
			left: calc(50% - 10px);
			width: 20px;
			height: 20px;
			background-color: $color_background-0;
			transform: rotate(45deg);
		}
		// hlavicka
		.ui-datepicker-header {
			// nazev mesice a roku
			.ui-datepicker-month,
			.ui-datepicker-year {
				font-size: 1.12rem;
			}
		}
	}
}

.patternlab {
	.ui-datepicker {
		margin: 50px auto 0 auto;
	}
}
