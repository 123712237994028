//
// Wave - HP
//



.wave.page-wave-hp {
	
	/* regions gray design bg */
	
	.main {
		position: relative;

		z-index: 0;
	}
	
	.hp-wrapper-left,
	.hp-wrapper-right,
	.l-grid--4-1,
	.l-grid--4-9,
	.l-grid--12-1 {
		position: relative;
	}
	
	// left, right desktop bg
	.hp-wrapper-left::after,
	.hp-wrapper-right::after {
		display: none;
		
		@include breakpoint($breakpoint_desktop) {
			display: block;
			
			content: "";
			
			background: $color_background-3;
			
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			
			width: 133.3333%;
			height: 100%;
			
			z-index: -1;
		}
	}
	
	// left desktop bg
	.hp-wrapper-left::after {
		@include breakpoint($breakpoint_desktop) {
			margin-left: -100%;
			margin-right: 0;
		}
	}
	
	// right desktop bg
	.hp-wrapper-right::after {
		@include breakpoint($breakpoint_desktop) {
			margin-left: 66.6666%;
			margin-right: 0;
		}
	}
	
	// left, right mobile bg
	.l-grid--4-1::after,
	.l-grid--4-9::after {
		content: "";
		
		background: $color_background-3;
		
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		
		width: auto;
		height: 100%;
		
		margin-left: -1em;
		margin-right: -1em;
		
		z-index: -1;
		
		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}
	
	// bottom bg
	.button-box-flat--loadmore.pager--infinite-scroll::after {
		display: block;
		
		content: "";
		
		background: $color_background-3;
		
		position: absolute;
		top: 50%;
		bottom: 0;
		left: -50%;
		right: 0;
		
		width: 200%;
		height: 3000%;
		
		z-index: -1;
	}
	
	
	// left, right bg grid
	.hp-wrapper-left,
	.hp-wrapper-right {
		.l-grid--4-1,
		.l-grid--4-9 {
			h2 {
				color: $color_text_paragraph;
			}
			h2 a {
				@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
			}
			padding-bottom: 2em;
			
			@include breakpoint($breakpoint_desktop) {
				padding-bottom: 3em;
			}
			
			.box {
				margin-top: 2em;
				
				@include breakpoint($breakpoint_desktop) {
					margin-top: 3em;
				}
			}
		}
		
		.l-grid--8-5,
		.l-grid--8-1 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 1em;
			}
			
			.box {
				margin-top: 1em;
				
				@include breakpoint($breakpoint_desktop) {
					margin-top: 1em;
				}
			}
		}
	}
	
	
	// left bg grid
	.hp-wrapper-left {
		.l-grid--4-1 {
			@include breakpoint($breakpoint_desktop) {
				padding-right: 2.5em;
			}
			
			.box {
				margin-top: 2em;
				
				@include breakpoint($breakpoint_desktop) {
					margin-top: 3em;
				}
			}
		}
		
		.l-grid--8-5 {
			@include breakpoint($breakpoint_desktop) {
				padding-left: 2.5em;
			}
			
			.box {
				margin-top: 1em;
				
				@include breakpoint($breakpoint_desktop) {
					margin-top: 1em;
				}
			}
		}
	}
	
	// right bg grid
	.hp-wrapper-right {
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				padding-left: 2.5em;
			}
		}
		
		.l-grid--8-1 {
			@include breakpoint($breakpoint_desktop) {
				padding-right: 2.5em;
			}
			
			.box {
				margin-top: 1em;
				
				@include breakpoint($breakpoint_desktop) {
					margin-top: 1em;
				}
			}
		}
	}
	
	/* wrappers */
	.hp-wrapper-left.item-first,
	.hp-wrapper-left.item-second,
	.hp-wrapper-right.item-first {
		margin-top: 2em;
	}
	
	.hp-wrapper,
	.hp-wrapper-2 {
		display: flex;
		flex-direction: column;
		
		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
		
	}
	
	
	
	/* defaults */
	.box {
		h2 {
			color: $color_text_paragraph;
		}
		h2 a {
			@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
		}
	}
	
	
	
	/* obecne fixy boxu b-002g */
	
	/* b-002g o jednom itemu */
	.b-002g--items-1 {
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}
	
	/* b-002g o dvou itemech */
	.b-002g--items-2 {
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
			}
		}
	}
	
	/* b-002g o trech itemech */
	.b-002g--items-3 {
		.b-002g__list-item {
			@include breakpoint($breakpoint_desktop) {
				width: 33%;
			}
		}
	}
	
	
	
	/* cro-carouselVideo video carousel */
	.hp-wrapper-right .l-grid--8-1 .box.b-006d {
		margin-top: 0;
		margin-bottom: 3em;
		
		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
			margin-bottom: 3em;
		}
		
		h2 {
			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
		}
	}
	
	
	
	/* b-006c video tydne */
	.hp-wrapper-left .l-grid--8-5 .box.b-006c {
		position: relative; // kvuli absolutnimu pozicovani oddelujici linky na mobilu
		
		margin-top: 3em;
		margin-bottom: 6em;
		
		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
			margin-bottom: 3em;
		}
		
		h2 {
			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
		}
	}
	/* b-006c video tydne - mobile bottom line */
	.hp-wrapper-left .l-grid--8-5 .box.b-006c::after {
		display: block;
		
		content: "";
		
		background: $color_background-3;
		
		position: absolute;
		//top: 0;
		bottom: -3em;
		left: 0;
		right: 0;
		
		width: 100%;
		height: 2px;
		
		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}
	
	
	
	/* b-030c playlisty */
	.hp-wrapper-left.item-second .l-grid--4-1 .box.b-030c {
		//button Cely playlist
		.b-030__subsection--buttons {
			display: none;
		}
	}
	
	
	
	/* b-005 - wave pripravuji */
	.b-005 {
		margin-top: 4em;
		margin-bottom: 0;
		
		@include breakpoint($breakpoint_desktop) {
			margin-top: 4em;
			margin-bottom: 4em;
		}
		
		h2 {
			color: $color_text_paragraph;
		}
		
		h2 a {
			@include link-style($color_text_paragraph, none, $color_text_paragraph, underline);
		}
	}
	
	
	
	/* boxy ve wrapperu .hp-wrapper */
	.hp-wrapper {
		
		/* b-002g - box o jednom itemu */
		.b-002g.b-002g--items-1 {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
			}
		}
		
		/* b-042 - udalost (strida se s doporucujeme) */
		.b-042 {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
				margin-left: 2em;
			}
			
			.b-042__overlay-content h3 {
				color: $color_wave_main;
			}
			
			.b-042__overlay-content h3 a {
				@include link-style($color_wave_main, none, $color_wave_main, underline);
			}
		}
		
		/* b-004m - doporucujeme (strida se s udalosti) */
		.b-004m {
			@include breakpoint($breakpoint_desktop) {
				width: 50%;
				margin-left: 2em;
			}
		}
	}
	
	
	
	/* boxy ve wrapperu .hp-wrapper-2 */
	.hp-wrapper-2 {
		
		/* b-007a carousel */
		.b-007a {
			@include breakpoint($breakpoint_desktop) {
				/*flex-basis: 60%;
				flex-grow: 1;
				max-width: 65.5%; // fallback pro calc()
				max-width: calc(67% - 1em);*/
				
				width: 65.5%; // fallback pro calc()
				width: calc(67% - 1em);
				
				margin-right: 2em;
			}
			
			.b-007a__list-item {
				
				ul li:not(:first-child) {
					display: none;
				}
				
				@include breakpoint($breakpoint_desktop) {
					
					min-width: 80%;
					
					margin-bottom: $b-007a_marginbottom_item; // kvuli pretekajicimu boxu s H3 Title
				}
			}
				
			.b-007a__block--second {
			
				@include breakpoint($breakpoint_desktop) {
					
					max-width: 65%;
				
					padding: 0.5em;
					
					right: 3em;
					bottom: -1em;
				}
			}
		}
		
		/* b-002g o jednom itemu */
		.b-002g.b-002g--items-1 {
			@include breakpoint($breakpoint_desktop) {
				/*flex-basis: 30%;
				flex-grow: 1;
				max-width: 31.5%; // fallback pro calc()
				max-width: calc(33% - 1em);*/
				
				width: 31.5%; // fallback pro calc()
				width: calc(33% - 1em);
			}
		}
	}
	
	
	
	/* boxy v regionu .l-grid--12-1 */
	.l-grid--12-1 {
		
		/* b-002g o trech itemech */
		.b-002g--items-3 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 2em;
			}
			
			// dalsi ul po kliknuti na infinity scroll button
			.b-002g__list:not(:first-child) {
				margin-top: 1em;
			}
		}
	}
	
	
	
	/* clear fixed region wrapper */
	.hp-wrapper-left.clearfix,
	.hp-wrapper-right.clearfix {
		display: inline-block;
		overflow: unset;
	}
}