//
// footer map styles
//



$b-017_map_vertical-padding: 1.5em;
$b-017_map_list_vertical-padding: 1em;



.footer-map {

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	padding: $b-017_map_vertical-padding $b-017_child-section-horizontal-padding_mobile;

	@include breakpoint($breakpoint_desktop) {
		padding: $b-017_map_vertical-padding $b-017_child-section-horizontal-padding_desktop;
	}
}

	.footer-map__block {

		flex: 1;

		padding-left: 0.5em;
		padding-right: 0.5em;

		@include breakpoint($breakpoint_desktop) {

			flex-basis: 0;

			padding: 0;
		}
	}

		.footer-map__list {
			margin-top: $b-017_map_list_vertical-padding;
			margin-bottom: $b-017_map_list_vertical-padding;
		}

			.footer-map__title {
				padding-bottom: 1em;
				@include typography_font(medium);
				color: $color_text_accent_inverted;
			}

			.footer-map__item {
				color: $color_text_accent_inverted;

				margin-bottom: 0.5em;
			}


		.footer-map__list--social-buttons {

			display:flex;
			flex-flow:row wrap;

			margin-bottom: 0;
		}

			.footer-map__title {
				flex-basis: 100%;
			}

			.footer-map__item--social-button {

				margin-right: 0.5em;
				margin-bottom: 0.5em;

				// tohle uz je vnitrek atomu buttonu socialni site
				// proto zanorena obecna trida nenavazujici na .footer-map
				.button-social {

					width: 3.5em;
					height: 3.5em;

					@include breakpoint($breakpoint_desktop) {

						width: 2.5em;
						height: 2.5em;
					}
				}
			}

				.footer-map__item-link {
					@include link-style($color_text_paragraph_inverted, none, $color_text_paragraph_inverted);
				}


			// zalomeni na druhy radek, prozatim FF only reseni
			.footer-map__item--social-button:nth-child(4n) {
				page-break-after: always; // CSS 2.1
				break-after: always; // nova syntax
			}
			// posledni soc ikona
			.footer-map__item--social-button:last-child {
				margin-right: 0;
			}




	// prvni dvousloupec (v desktop), co se spoji v dve polozky vertikalne (mobile)
	.footer-map__block:nth-child(1) {
		flex: 1;

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex: 2;
			flex-direction: row;
		}
	}

		// prvni sloupec prvniho bloku
		.footer-map__list--b1:nth-child(1) {

			@include breakpoint($breakpoint_desktop) {
				flex: 1;
			}
		}
		// druhy sloupec prvniho bloku
		.footer-map__list--b1:nth-child(2) {

			@include breakpoint($breakpoint_desktop) {
				flex: 1;
			}
		}


	// druhy sloupec
	.footer-map__block:nth-child(2) {
	}
	// treti sloupec - social sekce
	.footer-map__block:nth-child(3) {

		// velka sirka socico sekce pred flex distribuci
		// zajisti na mobilu nutne zalomeni na vlastni radek
		flex-basis: 100%;

		@include breakpoint($breakpoint_desktop) {
			flex-basis: 0;
		}
	}
