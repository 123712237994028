//
// H3 a + info text as single link
//



h3 {
	.h3a-inner-link {
		@include typography_font(regular);
		font-size: 0.75rem;
		@include breakpoint($breakpoint_desktop) {
			font-size: 0.85rem;
		}
	}
}
