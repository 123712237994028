//
// label
//



label {
	
	text-transform: uppercase;
	
	
	@include adjust-font-size-to($fontsize_form_input_label);
	
	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_form_input_label_desktop);
	}
	
}