//
// Tabs (d7 default markup)
//
// zalozky v plnem zneni pro upravu a procesy pokud je uzivatel prihlaseny
//



$tabs_item-padding: 0.5em 1em;



// zakladni Drupal stylovani prenesene do Patternlabu, na kterem se bude stavet dal
.tabs {
	
	a {
		background-color: #eee;
	}
	a.active {
		background-color: #ccc;
		
		.element-invisible {
			clip: rect(1px, 1px, 1px, 1px);
			height: 1px;
			overflow: hidden;
			position: absolute !important;
		}
	}
	a:hover, a:focus {
		background-color: #bbb;
	}
}
.tabs--primary {
	border-bottom: 1px solid #bbb;
	margin-bottom: 1em;
	
	a {
		padding: 0.3em 0.8em;
	}
}

.links--inline {
	list-style-type: none;
	padding: 0;
	
	li {
		float: left;
		margin-right: 1em;
		
		a {
			display: block;
		}
	}
}
.links--inline::after {
	clear: both;
	content: "";
	display: table;
}



//
// stylovani pretizenim
//
// tady se na vahu selektoru kasle
// s markupem se stejne v teto fazi neda hnout, tak at v tom neni uplnej bordel klidne zanoruju
.tabs {
	
	a {
		background-color: $color_background-2;
		
		border: 1px solid $color_background-4;
		border-bottom: 0;
		
		@include link-style($color_decoration_main, none, $color_decoration_main, none);
	}
	a.active {
		background-color: $color_background-6;
		
		border: 1px solid $color_background-6;
		border-bottom: 0;
		
		@include link-style($color_text_link_inverted, none, $color_text_link_inverted, none);
	}
	a:hover, a:focus {
		background-color: $color_background-4;
		
		border: 1px solid $color_background-4;
		border-bottom: 0;
		
		@include link-style($color_decoration_main, none, $color_decoration_main, none);
	}
}
.tabs--primary {
	border-bottom: 1px solid $color_background-4;
	
	a {
		padding: $tabs_item-padding;
	}
}

.links--inline {
	
	li {
		
		// mobile first - vsechno je schovane, pouze aktivni zalozka je videt
		display: block;
		margin-right: 0;
		float: initial;
		
		// desktop - vsechny taby videt
		@include breakpoint($breakpoint_desktop) {
			display: inline-block;
			margin-right: 1em;
			float: left;
		}
	}
}