//
// Button Series
//



// struktura obecneho series tlacitka
.button-series {

	display: flex;

	padding: 0.5em;

	border: 1px solid $color_background-4;
	background-color: $color_background-0_alpha-mid;

	@include link-style($color_decoration_main, none, $color_decoration_main, underline);


	@include adjust-font-size-to($fontsize_form_flat_button);

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_form_flat_button_desktop);
	}

}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-series:hover {
	background-color: $color_background-0;
}

	// label - muze byt pozicovan jak napravo tak nalevo od ikony
	// nasleduji modifikatory
	.button-series__label {

		flex-grow: 1;

		display: flex;
		align-items: center;

		padding: 0.5em;


		text-align: center;
	}
	.button-series__label--backward {

		text-align: left;
		padding-left: 1em;

		border-left: 1px solid $color_background-4;
	}
	.button-series__label--forward {

		text-align: right;
		padding-right: 1em;

		border-right: 1px solid $color_background-4;
	}

		.button-series__label-nest {

			flex-grow: 1;

			display: block;
		}

			.button-series__label-complement {

				display: none;

				@include breakpoint($breakpoint_desktop) {

					display: inline;
				}
			}


	.button-series__icon {

		flex-shrink: 0;

		width: 1em;

		background-size: 14px 14px;
		background-repeat: no-repeat;
	}
	.button-series__icon--arrow-single-west {

		margin-left: 0.5em;
		margin-right: 1em;

		background-image: $file_icon_arrow_single_east_colored-decoration-main;
		background-position: right center;

		// TODO chybejici ikona (ve variables je dosazena opacna)
		transform: rotate(180deg);
	}
	.button-series__icon--arrow-single-east {

		margin-left: 1em;
		margin-right: 0.5em;

		background-image: $file_icon_arrow_single_east_colored-decoration-main;
		background-position: left center;
	}





//
// inverzni varianta tlacitka
//

.button-series--negative {

	border: 0;
	background-color: $color_background-5;

	@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);


	.button-series__icon--arrow-single-west {
		background-image: $file_icon_arrow_single_east_colored-white;
	}
	.button-series__icon--arrow-single-east {
		background-image: $file_icon_arrow_single_east_colored-white;
	}
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-series--negative:hover {
	background-color: $color_background-6;
}
