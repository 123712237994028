//
// Shame - aneb kdyz nevim kam s tim..
//



// anonymous -  domain filteru ve views
.form-item-rozhlas-solr-domain-source { display: none; }

// jruz - RWD youtube
div.media-youtube-video {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;

	width: 100%;
	height: auto;
}

iframe.media-youtube-player {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

div.media-facebook-video iframe {
	display: block;
}

// jmar - Expired audio in a-004p
.a-004p .a-004b__list {
	padding-left: 0;
}

.a-004b__link--expired {
	position: relative;

	.a-004b__iterator {
		position: absolute;
		top: 1em;
		left: 1em;
	}

	.audio-rights-expired {
		margin: 0;
		//width: 99.6%;
		width: 100%;
		padding-left: 2.6em;
	}

}

// mvyb - ajax throbber
.ajax-progress.ajax-progress-throbber {
	display: none;
}



/* main */
.main {
	padding-top: 0.1px;
}

/* cro-embed__child */
.cro-embed__child {
	overflow: hidden; // margin collapsing prevent - https://stackoverflow.com/questions/19718634/how-to-disable-margin-collapsing

	> .box:first-of-type {
		margin: 0;
	}
}



// editacni buttony u prihlasenych
// nakonec i u neprihlasenych, viz login screen
.logged-in .tabs.tabs--primary.links--inline,
.tabs.tabs--primary.links--inline {
	margin-top: 2em;
	margin-bottom: 2em;

	border: none;

	@include breakpoint($breakpoint_desktop) {
		width: 66.66667%;
	}

	a,
	a.active,
	a:hover {
		border: none;
	}
}

// pager
ul.pager {
	width: 100%;

	@include breakpoint($breakpoint_desktop) {
		margin-top: 2em;
	}
}


//
// mvla - reseni pretekajiciho pseudoelementu hlavniho menu v chrome
//
// prevzato z rakouskeho roku a zobecneno, snad s takhle zasadnim pravidlem nebudou potize, resp
// snad nebude nutne na strance nikdy horizontalne scrollovat. zatim jedine funkcni reseni omezeneho designu menu
//
// zaroven reseni pro druhy typ chyby, mobilni zarizeni overflow u body maji tendenci ignorovat
// je treba bud udelat vnitrni obalovaci div, nebo definovat i u tagu html, snad to bude stacit
// http://stackoverflow.com/questions/14270084/overflow-xhidden-doesnt-prevent-content-from-overflowing-in-mobile-browsers
//

html, body {
	overflow-x: hidden;
}
body {
	overflow-y: hidden;
}


// O stanici - Teploměr
.b-027 {
	font-size: em(13px);

	@include breakpoint($breakpoint_tablet) {
		font-size: $base-font-size;
	}

	.b-027__column-name {
		min-width: 54%;

		@include breakpoint($breakpoint_tablet) {
			min-width: 45%;
		}
	}

	.b-027__item-bar {
		min-width: 50%;

		@include breakpoint($breakpoint_tablet) {
			min-width: 40%;
		}
	}

}


// b-018 na strance poradu typu pouze podcast (ma v page tridu podcast)
.page.podcast {

	.b-018 {

		.b-018__timespan,
		.button-box-flat--readmore,
		.b-018__block--no-broadcast,
		.broadcast-times {
			display: none;
		}
	}
}





//
// mvla - plne zneni, vzdy dostatecna vyska pri malem obsahu aby se vesly oba elementy sidebaru
//
// zaveseno primo na grid, pretizeni veci z node-article, kde musi byt uklid tohoto zohlednen
//

// opusteno, protoze rusime duvod proc jsme to potrebovali (vertikalni podvivnosti v aside)
/*.node-type-article {

	.inner-content-wrapper {

		// main article body column
		.l-grid--8-1 {

			@include breakpoint($breakpoint_desktop) {

				min-height: 1200px;
			}
		}

		// sidebar column
		.l-grid--4-9 {

			.sidebar-1-1, .sidebar-1-2 {
				@include breakpoint($breakpoint_desktop) {

					height: auto;
				}
			}
		}
	}
}*/


// minimalni vyska clanku v desktop viewportu, fix aby se neusekavaly vertikalni soc buttony
.node-type-article {

	.inner-content-wrapper {

		// main article body column
		.l-grid--8-1 {

			@include breakpoint($breakpoint_desktop) {

				min-height: 785px;
			}
		}
	}
}


// logged-in
.logged-in .contextual-links-region {

	// clearfix
	&::after {
		clear: both;
		content: "";
		display: table;
	}
}

// pozor, melo negativni dopad na dalsi segmenty
.logged-in .l-grid--12-1 {
	overflow: visible;

	// HACK: resi margin collapsing po nastaveni overflow na visible
	padding-top: 0.1px;
	padding-bottom: 0.1px;
}

// serial content is moved to the right if it's not set like this
.logged-in.node-type-serial .l-grid--12-1,
.logged-in.node-type-serialpart .l-grid--12-1,
.logged-in.node-type-event .l-grid--12-1 {
	//overflow: hidden;
}

/* hotfix, nutno vyresit systemove */
.wysiwyg-asset .contextual-links-wrapper {
	display: none;
}



//
// header
//

header {

	@include breakpoint($breakpoint_desktop) {
		position: relative; // kvuli absolutnimu pozicovani header .b-001c__placeholder
	}
}


//
// topnav, nav vs. js cro_responsivelist.js
//

.junior .b-014a__list-item,
.junior .b-014b__list-item,
.junior .b-016__list-item {
	opacity: 0; // u juniora default, odkryje js kdyz je nav ready
}



//
// H1 ve stanicni navigaci + jazykove mutace
//

/* barevna specifika */
.wave {
	.b-016__wrapper {
		h1:before,
		h2:before {
			background-color: $color_wave_main;
		}

		.b-016 {
			background-color: $color_wave_main;
		}
	}
}
.neviditelna {
	.b-016__wrapper {
		h1:before,
		h2:before {
			background-color: $color_radiozurnal_main;
		}

		.b-016 {
			background-color: $color_radiozurnal_main;
		}
	}
}
.digital {
	.b-016__wrapper {
		h1,
		h2 {
			background-color: $color_background-0;
		}
		h1:before,
		h2:before {
			background-color: $color_digital_main;
		}

		.b-016 {
			background-color: $color_digital_main;
		}
	}
}
.program {
	.b-016__wrapper {
		h1,
		h2 {
			background-color: $color_background-0;
			color: $color_text_accent;

			a {
				@include link-style($color_text_link, none, $color_text_link, underline);
			}
		}
		h1:before,
		h2:before {
			//background-color: $color_background-3;
			background-color: $color_background-9;
		}

		/*.b-016 {
			background-color: $color_background-3;

			.b-016__list .b-016__list-item--more {
				> a {
					// ico hamburger
					//background-image: $file_icon_hamburger_colored-555561;

					&:hover,
					&.expanded {
						// ico hamburger
						//background-image: $file_icon_hamburger_colored-white;
					}
				}
			}
		}*/
	}
}

.b-016__wrapper {
	position: relative; // misto .b016__list

	display: flex;
	//justify-content: flex-end; // misto space-between, viz dite h1

	//margin-top: 8px; // 0.5em z 16px // opusteno, preneseno do .b-015

	h1,
	h2 {
		flex-shrink: 0;

		position: relative;
		display: inline-block;
		overflow: hidden;

		margin-top: 0;
		//margin-right: auto; // zarovnani doleva vs justify-content: flex-end jeho rodice
		margin-bottom: 0;
		//margin-left: 0; // prebiji hodnotu pro h1 v node (.node-type-article .page h1)

		padding: 8px 10px;
		padding-right: 20px; // kvuli sikmemu efektu

		//width: auto; // prebiji hodnotu pro h1 v node (.node-type-article .page h1)
		max-width: 150px; // omezení proti extrémě dlouhým textům

		background-color: $color_background-4;
		color: $color_text_accent;

		a {
			@include link-style($color_text_link, none, $color_text_link, underline);
		}

		@include typography_font(regular);
		font-size: 20px;
		line-height: 24px;
		white-space: nowrap;
		text-transform: unset; // prebiji hodnotu pro h2

		@include breakpoint($breakpoint_desktop) {
			padding: 8px 40px;
			max-width: 350px; // omezení proti extrémě dlouhým textům
			font-size: 19px;
		}
	}

	h1:before,
	h2:before {
		position: absolute;
		top: -10px;
		right: -40px;

		transform: rotate(-15deg);

		display: block;
		content: '';

		width: 50px;
		height: 50px;

		background-color: $color_background-9;
	}

	h1 span,
	h2 span {
		position: relative;
		display: block;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		z-index: 1;
	}

	.b-016 {
		display: flex; // ie fix
		//justify-content: flex-end;
		flex-grow: 1;
		//flex: 1; // flex-grow: 1, flex-shrink: 1, flex-basis: 0% // nefunguje s ie

		margin-top: 0;
		margin-bottom: 0;

		.b-016__list {
			flex-grow: 1; // ie11 fix
			position: inherit; // prebiji hodnoty _b-016.scss, tady chceme nejblizsiho relativne pozicovanyho parenta az .b-016__wrapper

			height: $b-016_menu-bar-height_mobile;

			@include breakpoint($breakpoint_desktop) {
				height: $b-016_menu-bar-height_desktop;
			}
		}

		.b-016__list--localization {
			flex-grow: 0;

			@include breakpoint($breakpoint_desktop) {
				// odsazeni vsech polozek menu z leva
				// pro desktop primo ovlivneno vztahem sirky page > main
				//padding-left: $layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop;
				padding-left: 0;
				padding-right: $layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop;
			}
		}

			// link - lokalizace
			.b-016__link--localization {
				display: flex;
				align-items: center;

				height: 100%;

				@include breakpoint($breakpoint_desktop) {
					font-size: $fontsize_menu_main_localization_desktop;
					//@include typography_font(medium);
					text-transform: uppercase;
				}
			}

				// label itemu
				.b-016__link-label {
					display: none;

					@include breakpoint($breakpoint_desktop) {
						display: inline;
						margin-right: 10px;
					}
				}

				// ikona itemu
				.b-016__link-emblem {
				}

				// ikona itemu - jazyk
				.b-016__link-emblem--localization {
					background-repeat: no-repeat;
					background-size: 100% 100%; // kvuli deformaci

					width: 20px;
					height: 15px;

					@include breakpoint($breakpoint_desktop) {
						width: 30px;
						height: 20px;
					}
				}
				.b-016__list-item--czech .b-016__link-emblem--localization {
					background-image: $file_flag_cze
				}
				.b-016__list-item--english .b-016__link-emblem--localization {
					background-image: $file_flag_eng;
				}


			.b-016__list-item a.active {
				display: none;
			}


		.b-016__list-more {
			top: auto;
			left: auto; // opusteno, potrebujem jen kdyz nema byt mobilni nav rozbalena pres celou sirku
			left: 0; // nova hodnota viz vyse
			right: 0;
			width: auto;
			padding: 0.5em 0;

			@include breakpoint($breakpoint_desktop) {
				left: auto; // jen v pripade ze na mobilu left: 0;
			}
		}
	}

	// pokud neni prvni dite, tzn. je pred nim h1
	.b-016:not(:first-child) {
		.b-016__list:not(.b-016__list--localization) {
			@include breakpoint($breakpoint_desktop) {
				padding-left: ($layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop) / 2; // kvuli sikmemu efektu
			}
		}
	}
}



/* sledujte nas */
// plosna zmena, je zatim tady protoze hrozi navrat k puvodni myslence
.b-032 .b-032b__list,
.b-032b .b-032b__list {
	display: flex; // opustena myslenka ze v moblni verzi je misto vsech soc. buttonu jeden s bublinou
}
.b-032 .button-social--share-bubble__wrapper,
.b-032b .button-social--share-bubble__wrapper {
	display: none; // opustena myslenka ze v moblni verzi je misto vsech soc. buttonu jeden s bublinou
}
// bgcol varianta
.b-032 .button-social--facebook.button-social--bgcol,
.b-032b .button-social--facebook.button-social--bgcol  {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_facebook; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--instagram.button-social--bgcol,
.b-032b .button-social--instagram.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_instagram; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--twitter.button-social--bgcol,
.b-032b .button-social--twitter.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_twitter; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--x.button-social--bgcol,
.b-032b .button-social--x.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_x; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--googleplus.button-social--bgcol,
.b-032b .button-social--googleplus.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_googleplus; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--youtube.button-social--bgcol,
.b-032b .button-social--youtube.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_youtube; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--linkedin.button-social--bgcol,
.b-032b .button-social--linkedin.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_linkedin; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--rss.button-social--bgcol,
.b-032b .button-social--rss.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_rss; // opustena myslenka jine barvy ikon na desktopu
	}
}
// bgcol varianta
.b-032 .button-social--newsletter.button-social--bgcol,
.b-032b .button-social--newsletter.button-social--bgcol {
	@include breakpoint($breakpoint_desktop) {
		background-color: $color_extsub_social_newsletter; // opustena myslenka jine barvy ikon na desktopu
	}
}



/*
 * widget forecast
 */
#forecast.w-forecast {
	margin-top: 2em;

	@include breakpoint($breakpoint_desktop) {
  	margin-top: 3em;
	}

	// pozicovani v aside gridu
	.l-grid--4-1 &,
	.l-grid--4-9 & {

		// button pollen
		.button-box-flat--pollen {
			//margin: 0 -10px;

			@include breakpoint($breakpoint_desktop) {
		 		//margin: 0 -15px;
			}
		}
	}

	// button pollen
	.button-box-flat--pollen {

		// hover
		&:hover {
			text-decoration: none;
		}

		// label
		.button-box-flat__label {
			padding: 0;
			padding-right: 0.5em;

			text-align: left;

			.button-box-flat__label-nest {
				line-height: 1.3;
				text-shadow: 1px 1px 4px rgba(6, 27, 38, 0.5);
				//text-shadow: none;
			}
		}

		// prvni label
		.button-box-flat__label--name {
			flex-basis: 20%;
			flex-grow: 0;
			margin-right: 0.5em;

			.button-box-flat__label-nest {
				@include typography_font(regular);
				font-size: 12px;

				@include breakpoint($breakpoint_desktop) {
			 		font-size: 13px;
				}
			}
		}

		// posledni label
		.button-box-flat__label--value {
			position: relative;

			flex-grow: 1;
			margin-right: 0;
			overflow: hidden;

			.button-box-flat__label-nest {
				@include typography_font(bold);
				font-size: 13px;
				text-transform: uppercase;
				white-space: nowrap;

				@include breakpoint($breakpoint_desktop) {
			 		font-size: 14px;
				}
			}
		}

		// posledni label: gradient
		.button-box-flat__label--value:after {
			content: "";

			position: absolute;
			top: 0;
			bottom: 0;
			left: 75%;
			right: 0;

			background-image: linear-gradient(90deg, rgba($color_decoration_main, 0), $color_decoration_main);
		}

		// ico
		.button-box-flat__icon {
			&.button-box-flat__icon--arrow-double-east {}
		}
	}
}



// pswp - light box
.pswp__caption__center {
	margin-top: 0.25em;
	font-size: 14px;
	max-width: 100%;
	text-align: center;

	@include breakpoint($breakpoint_desktop) {
		font-size: 16px;
	}

	.img-metadata,
	.img-metadata .img-metadata__title,
	.img-metadata .img-metadata__spacer,
	.img-metadata .img-metadata__label,
	.img-metadata .img-metadata__author,
	.img-metadata .img-metadata__source {
		color: $color_text_accent_inverted;

		a {
			@include link-style($color_text_link_inverted, underline, $color_text_link_inverted, none);
		}
	}
}



/* MR player */
.page-node .mujRozhlasPlayer .mrp-holder {
	max-width: initial;
}



/* ZV mapa skryti pocasi */
#trafficTabs-tables .ui-corner-all {
	display: none;
}



/* 3d modely stadionu v node */
.page-node .body .html-embed .iframe__container {
	position: relative;
	width: 100%;
	height: auto;
	padding-top: 100%;
	overflow: hidden;
	/*box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);*/

	@include breakpoint($breakpoint_desktop) {
		width: calc(100% + (1.875rem * 2));
		height: calc(100% + (1.875rem * 2));
		margin-left: -1.875rem;
		margin-right: -1.875rem;
	}

	> iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: #f7f7f8;
		border: 1px solid #d6d6de;
		transition: background-color 0.5s ease;
	}

	&:hover > iframe {
		background-color: #efeff2;
	}
}



/* play ico */
body:not(.junior) .box .button-video-play-box { display: none; }



/* chrome Ctete take asset wrapper fix */
.readbox-with-readbox-asset-wrapper { min-height: 0.1px; }



/* skryti stitku Tydenik Rozhlas v bigpromu na Portale */
.portal .b-002b .tag.bg-color-main-program { display: none; }



/* social buttons top */
.page-node .block-social-buttons--top,
.page-node .node-block__block--social-buttons-top { display: none; }



/* titulek stareho typu assetu ctete take */
.page-node .body .cteteTake > strong { display: none; }



/* chyba u assetoveho audioprehravace --- */
.field-name-field-audio-duration { display: none; }
/* nadacni fond form */
#cro-nadacni-fond-potvrzeni-entityform-edit-form .field-name-field-audio-duration { display: block; }



/* node 8084853 / Kondolenční kniha pro rodinu Karla Gotta */
.page-node-8084853 .node-authors { display: none; }
.page-node-8084853 #otazky .panel .kontakt .icons { margin-right: 0; }
.page-node-8084853 #otazky .panel .kontakt .icons .shareb.fb { display: none; }



/* Na stezce Ceskem - reportaz 101726 */
.page-node-8770195 .l-grid--4-9.item-first .livereport .live { max-height: 750px; overflow-y: scroll; }



/* Letni reporter RZ - reportaz 74509 */
.page-node-8233689 .l-grid--4-9.item-first .livereport .live { max-height: 1800px; overflow-y: scroll; }



/* 21 tvari roku */
.page-node-8640651.node-type-serial h1.page-type--serial { display: none; }



/* 22 tvari roku */
.page-node-8883375.node-type-serial h1.page-type--serial { display: none; }



/* stadiony */
.page-node-8546975 .b-100b .b-100b__episode .b-100b__episode-wrap .b-100b__episode-item .b-100b__episode-available { background-color: #ed2e38; color: #fff; }



/* /lide b-002g zabraneni prokliku */
.page-lide .l-grid--8-1.item-first .b-002g:first-of-type .b-002g__list .b-002g__list-item > a { pointer-events: none; cursor: default; }



/* pochoutkovy rok - h1, perex */
.page-node-5996952 .l-grid--8-1.nth-of-type--1 h1 {
	color: #000;
	font-size: 1.375em;
	line-height: 1.8rem;
	margin: 1em 0 0.5em 0;

}
.page-node-5996952 .l-grid--8-1.nth-of-type--1 h1 {

	@include breakpoint($breakpoint_desktop) {
		font-size: 2em;
		line-height: 2.5rem;
	}
}
.page-node-5996952 .l-grid--8-1.nth-of-type--1 .field-perex {
	margin: 0;
	padding: 0;
}



/* b-099d v node - promo */
.page-node .content-1--7-2 .b-099d {
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
	transition: opacity 0.3s ease-out;

	&:hover {
		opacity: 0.9;
	}
}



// ?? prosim strucny popis proc, kde, atd. at muzu pripadne opravit
.b-024 .button { margin-top: 0.5em; }
.b-008b p { margin-bottom: 0; }
