//
// B-113 styles
//



// desktop vertical breakpoints
$b-113_breakpoint_desktop-vert-mid: (min-width $breakpoint_desktop) (max-height 900px);
$b-113_breakpoint_desktop-vert-low: (min-width $breakpoint_desktop) (max-height 800px);

// margins
$b-113_margin-vertical_mobile: 4px;
$b-113_margin-horizontal_mobile: 4px;
$b-113_margin-vertical_desktop: 8px;
$b-113_margin-horizontal_desktop: 8px;

// paddings
$b-113_padding-vertical_mobile: 10px;
$b-113_padding-horizontal_mobile: 10px;
$b-113_padding-vertical_desktop: 15px;
$b-113_padding-horizontal_desktop: 15px;

// column size
$b-113-left-column_size_mobile: 45px;
$b-113-left-column_size_desktop: 75px;
$b-113-left-column_size_desktop-mid: 60px;
$b-113-left-column_size_desktop-low: $b-113-left-column_size_mobile;

// heading bar
$b-113-heading-bar_background-color: rgba($color_sport_main, 0.8);
$b-113-heading-bar_height_mobile: 25px;
$b-113-heading-bar_height_desktop: 40px;
$b-113-heading-bar_height_desktop-mid: 30px;
$b-113-heading-bar_height_desktop-low: $b-113-heading-bar_height_mobile;

// heading description
$b-113-heading-description_font-size_mobile: 12px;
$b-113-heading-description_font-size_desktop: 20px;
$b-113-heading-description_font-size_desktop-mid: 16px;
$b-113-heading-description_font-size_desktop-low: $b-113-heading-description_font-size_mobile;

// heading visual
$b-113-heading-visual_size_mobile: 75px;
$b-113-heading-visual_size_desktop: 125px;
$b-113-heading-visual_size_desktop-mid: 100px;
$b-113-heading-visual_size_desktop-low: $b-113-heading-visual_size_mobile;

// launcher title
$b-113-launcher-title_font-size_mobile: 18px;
$b-113-launcher-title_font-size_desktop: 32px;
$b-113-launcher-title_font-size_desktop-mid: 24px;
$b-113-launcher-title_font-size_desktop-low: $b-113-launcher-title_font-size_mobile;

// launcher subtitle
$b-113-launcher-subtitle_font-size_mobile: 15px;
$b-113-launcher-subtitle_font-size_desktop: 24px;
$b-113-launcher-subtitle_font-size_desktop-mid: 18px;
$b-113-launcher-subtitle_font-size_desktop-low: $b-113-launcher-subtitle_font-size_mobile;

// launcher message
$b-113-launcher-message_font-size_mobile: 14px;
$b-113-launcher-message_font-size_desktop: 18px;
$b-113-launcher-message_font-size_desktop-mid: 16px;
$b-113-launcher-message_font-size_desktop-low: $b-113-launcher-message_font-size_mobile;

// launcher eq
$b-113-launcher-eq_size_mobile: 10px;
$b-113-launcher-eq_size_desktop: 20px;
$b-113-launcher-eq_size_desktop-mid: 15px;
$b-113-launcher-eq_size_desktop-low: $b-113-launcher-eq_size_mobile;

// shedule
$b-113-shedule-list-item_font-size_mobile: 17px;
$b-113-shedule-list-item_font-size_desktop: 28px;
$b-113-shedule-list-item_font-size_desktop-mid: 20px;
$b-113-shedule-list-item_font-size_desktop-low: $b-113-shedule-list-item_font-size_mobile;


.b-113 {
}

	// heading list
	.b-113__heading-list {
		position: relative;
		height: $b-113-heading-visual_size_mobile;

		@include breakpoint($breakpoint_desktop) {
			height: $b-113-heading-visual_size_desktop;
		}

		// desktop mid height
		@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
			height: $b-113-heading-visual_size_desktop-mid;
		}

		// desktop low height
		@include breakpoint($b-113_breakpoint_desktop-vert-low) {
			height: $b-113-heading-visual_size_desktop-low;
		}
	}

	// heading list: bar
	.b-113__heading-list::before {
		content: '';

		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		height: $b-113-heading-bar_height_mobile;

		background-color: $b-113-heading-bar_background-color;

		@include breakpoint($breakpoint_desktop) {
			height: $b-113-heading-bar_height_desktop;
		}

		// desktop mid height
		@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
			height: $b-113-heading-bar_height_desktop-mid;
		}

		// desktop low height
		@include breakpoint($b-113_breakpoint_desktop-vert-low) {
			height: $b-113-heading-bar_height_desktop-low;
		}
	}

		// heading list item
		.b-113__heading-list-item {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}


	// heading
	.b-113__heading {
		//position: relative;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-end;

		//z-index: 0;
	}

	/*.b-113__heading:before {
		content: '';

		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		height: $b-113-heading-bar_height_mobile;

		background-color: $b-113-heading-bar_background-color;

		z-index: -1;

		@include breakpoint($breakpoint_desktop) {
			height: $b-113-heading-bar_height_desktop;
		}

		// desktop mid height
		@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
			height: $b-113-heading-bar_height_desktop-mid;
		}

		// desktop low height
		@include breakpoint($b-113_breakpoint_desktop-vert-low) {
			height: $b-113-heading-bar_height_desktop-low;
		}
	}*/

		.b-113__heading-description {
			flex-grow: 1;

			display: flex;
			flex-direction: column;
			justify-content: center;

			padding-left: $b-113_padding-horizontal_mobile;
			padding-right: $b-113_padding-horizontal_mobile;

			height: $b-113-heading-bar_height_mobile;

			font-size: $b-113-heading-description_font-size_mobile;
			line-height: 1.2;

			color: $color_text_accent_inverted;

			overflow: hidden;

			@include breakpoint($breakpoint_desktop) {
				padding-left: $b-113_padding-horizontal_desktop;
				padding-right: $b-113_padding-horizontal_desktop;

				height: $b-113-heading-bar_height_desktop;

				font-size: $b-113-heading-description_font-size_desktop;
			}

			// desktop mid height
			@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
				height: $b-113-heading-bar_height_desktop-mid;
				font-size: $b-113-heading-description_font-size_desktop-mid;
			}

			// desktop low height
			@include breakpoint($b-113_breakpoint_desktop-vert-low) {
				height: $b-113-heading-bar_height_desktop-low;
				font-size: $b-113-heading-description_font-size_desktop-low;
			}
		}

			.b-113__heading-description-text {
				@include lineClamp(1);
			}

		.b-113__heading-visual {
			flex-shrink: 0;

			width: $b-113-heading-visual_size_mobile;
			height: $b-113-heading-visual_size_mobile;

			@include breakpoint($breakpoint_desktop) {
				width: $b-113-heading-visual_size_desktop;
				height: $b-113-heading-visual_size_desktop;
			}

			// desktop mid height
			@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
				width: $b-113-heading-visual_size_desktop-mid;
				height: $b-113-heading-visual_size_desktop-mid;
			}

			// desktop low height
			@include breakpoint($b-113_breakpoint_desktop-vert-low) {
				width: $b-113-heading-visual_size_desktop-low;
				height: $b-113-heading-visual_size_desktop-low;
			}
		}


	// launcher
	.b-113__launcher {
		margin-top: $b-113_margin-vertical_mobile;
		padding: $b-113_padding-vertical_mobile $b-113_padding-horizontal_mobile;

		background-color: $color_background-0_alpha-low;

		transition: all 0.3s ease-out;

		@include breakpoint($breakpoint_desktop) {
			margin-top: $b-113_margin-vertical_desktop;
			padding: $b-113_padding-vertical_desktop $b-113_padding-horizontal_desktop;
		}

		// desktop mid height
		@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
			margin-top: $b-113_margin-vertical_desktop / 2;
		}

		// desktop low height
		@include breakpoint($b-113_breakpoint_desktop-vert-low) {
			margin-top: $b-113_margin-vertical_desktop / 3;
		}
	}

	.b-113__launcher:hover { cursor: pointer; } // nove spousteci cela plocha launcheru

		// launcher block
		.b-113__launcher-block {
		}

		// launcher block primary
		.b-113__launcher-block--primary {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
		}

			.b-113__launcher-button-play,
			.b-113__launcher-button-pause {
				flex-shrink: 0;
				width: $b-113-left-column_size_mobile;
				margin-right: $b-113_margin-vertical_mobile * 2;

				@include breakpoint($breakpoint_desktop) {
					width: $b-113-left-column_size_desktop;
					margin-right: $b-113_margin-vertical_desktop * 2;
				}

				// desktop mid height
				@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
					width: $b-113-left-column_size_desktop-mid;
				}

				// desktop low height
				@include breakpoint($b-113_breakpoint_desktop-vert-low) {
					width: $b-113-left-column_size_desktop-low;
				}

				.button-play--launcher-sport,
				.button-pause--launcher-sport {
					width: $b-113-left-column_size_mobile;
					height: $b-113-left-column_size_mobile;

					@include breakpoint($breakpoint_desktop) {
						width: $b-113-left-column_size_desktop;
						height: $b-113-left-column_size_desktop;
					}

					// desktop mid height
					@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
						width: $b-113-left-column_size_desktop-mid;
						height: $b-113-left-column_size_desktop-mid;
					}

					// desktop low height
					@include breakpoint($b-113_breakpoint_desktop-vert-low) {
						width: $b-113-left-column_size_desktop-low;
						height: $b-113-left-column_size_desktop-low;
					}
				}

				// button pause: effect
				.button-pause--launcher-sport {
					border-radius: 6px;
					transform: scale(1);
					box-shadow: 0 0 0 0 rgba($color_sport_main, 1);
					animation: b-113-pause-pulse 2s infinite;

					@include breakpoint($breakpoint_desktop) {
						border-radius: 10px;
					}

					// desktop mid height
					@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
						border-radius: 8px;
					}

					// desktop low height
					@include breakpoint($b-113_breakpoint_desktop-vert-low) {
						border-radius: 6px;
					}

					// button animation
					@keyframes b-113-pause-pulse {
						0% {
							transform: scale(0.95);
							box-shadow: 0 0 0 0 rgba($color_sport_main, 0.7);
						}
						70% {
							transform: scale(1);
							box-shadow: 0 0 0 10px rgba($color_sport_main, 0);
						}
						100% {
							transform: scale(0.95);
							box-shadow: 0 0 0 0 rgba($color_sport_main, 0);
						}
					}
				}
			}

			.b-113__launcher-content {
				flex-grow: 1;
			}

				.b-113__launcher-title {
					font-size: $b-113-launcher-title_font-size_mobile;
					line-height: 1.2;
					@include typography_font(medium);
					@include lineClamp(2);

					color: $color_sport_main;

					@include breakpoint($breakpoint_desktop) {
						font-size: $b-113-launcher-title_font-size_desktop;
					}

					// desktop mid height
					@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
						font-size: $b-113-launcher-title_font-size_desktop-mid;
					}

					// desktop low height
					@include breakpoint($b-113_breakpoint_desktop-vert-low) {
						font-size: $b-113-launcher-title_font-size_desktop-low;
					}
				}

				.b-113__launcher-subtitle {
					margin-top: $b-113_margin-vertical_mobile;

					font-size: $b-113-launcher-subtitle_font-size_mobile;
					line-height: 1.2;
					@include lineClamp(2);

					color: $color_text_paragraph;

					@include breakpoint($breakpoint_desktop) {
						font-size: $b-113-launcher-subtitle_font-size_desktop;
					}

					// desktop mid height
					@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
						font-size: $b-113-launcher-subtitle_font-size_desktop-mid;
					}

					// desktop low height
					@include breakpoint($b-113_breakpoint_desktop-vert-low) {
						font-size: $b-113-launcher-subtitle_font-size_desktop-low;
					}
				}

				.b-113__launcher-message {
					font-size: $b-113-launcher-message_font-size_mobile;
					line-height: 1.2;

					color: $color_text_paragraph;

					@include breakpoint($breakpoint_desktop) {
						font-size: $b-113-launcher-message_font-size_desktop;
					}

					// desktop mid height
					@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
						font-size: $b-113-launcher-message_font-size_desktop-mid;
					}

					// desktop low height
					@include breakpoint($b-113_breakpoint_desktop-vert-low) {
						font-size: $b-113-launcher-message_font-size_desktop-low;
					}

					a {
						@include link-style($color_text_paragraph, underline, $color_text_link, underline);
					}
				}


		// launcher block secondary
		.b-113__launcher-block--secondary {
		}

			.b-113__launcher-now-playing {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;

				margin-top: 0.5em;
				padding: 0.5em;

				margin-left: -$b-113_padding-horizontal_mobile * 2;
				margin-right: -$b-113_padding-horizontal_mobile;

				background-color: $color_background-9; // default
				color: $color_text_accent_inverted;

				box-shadow: $shadow_light;

				@include breakpoint($breakpoint_desktop) {
					margin-bottom: -$b-113_padding-vertical_desktop + ($b-113_padding-vertical_desktop / 1.5);
					margin-left: -$b-113_padding-horizontal_desktop + (-$b-113_padding-horizontal_desktop / 1.5);
					margin-right: -$b-113_padding-horizontal_desktop + ($b-113_padding-horizontal_desktop / 1.5);
				}

				// default hide
				margin-top: 0;
				padding: 0 0.5em;
				height: 0;
				transform: translateX(25%);
				opacity: 0;

				transition: all 0.3s ease-out;

				@include breakpoint($breakpoint_desktop) {
					margin-bottom: 0;
				}

				// show
				&.show {
					margin-top: 0.5em;
					height: 28px;
					transform: translateX(0);
					opacity: 1;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: -$b-113_padding-vertical_desktop + ($b-113_padding-vertical_desktop / 1.5);
						height: 32px;
					}
				}

				a {
					color: $color_text_accent_inverted;
				}
			}

				.b-113__launcher-now-playing-icon {
					flex-shrink: 0;

					display: inline-block;

					width: 14px;
					height: 14px;

					margin-right: 0.5em;

					background-image: $file_icon_note_double_colored-white;
					background-repeat: no-repeat;
					background-position: left center;
				}

				.b-113__launcher-now-playing-text {
					flex-grow: 1;

					font-size: 12px;
					line-height: 1.3;
					@include lineClamp(1);

					@include breakpoint($breakpoint_desktop) {
						font-size: 13px;
					}
				}


	// shedule list
	.b-113__shedule-list {
	}

		.b-113__shedule-list-item {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			margin-top: $b-113_margin-vertical_mobile;
			padding: ($b-113_padding-vertical_mobile / 1.5) $b-113_padding-horizontal_mobile;

			font-size: $b-113-shedule-list-item_font-size_mobile;
			line-height: 1.2;

			background-color: $color_background-7_alpha-low;
			color: $color_text_accent_inverted;

			@include breakpoint($breakpoint_desktop) {
				margin-top: $b-113_margin-vertical_desktop;
				padding: ($b-113_padding-vertical_desktop / 1.5) $b-113_padding-horizontal_desktop;
				font-size: $b-113-shedule-list-item_font-size_desktop;
			}

			// desktop mid height
			@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
				margin-top: $b-113_margin-vertical_desktop / 2;
				font-size: $b-113-shedule-list-item_font-size_desktop-mid;
			}

			// desktop low height
			@include breakpoint($b-113_breakpoint_desktop-vert-low) {
				margin-top: $b-113_margin-vertical_desktop / 3;
				font-size: $b-113-shedule-list-item_font-size_desktop-low;
			}
		}

		.b-113__shedule-list-item:nth-child(2) {
			background-color: $color_background-7_alpha-mid;
			color: $color_background-0_alpha-mid;
		}

		.b-113__shedule-list-item:nth-child(3) {
			background-color: $color_background-7_alpha-high;
			color: $color_background-0_alpha-high;
		}

		.b-113__shedule-list-item::nth-child(n+3) {
			display: none;
		}

			.b-113__shedule-time {
				flex-shrink: 0;
				width: $b-113-left-column_size_mobile;
				margin-right: $b-113_margin-vertical_mobile * 2;
				@include typography_font(medium);

				@include breakpoint($breakpoint_desktop) {
					width: $b-113-left-column_size_desktop;
					margin-right: $b-113_margin-vertical_desktop * 2;
				}

				// desktop mid height
				@include breakpoint($b-113_breakpoint_desktop-vert-mid) {
					width: $b-113-left-column_size_desktop-mid;
				}

				// desktop low height
				@include breakpoint($b-113_breakpoint_desktop-vert-low) {
					width: $b-113-left-column_size_desktop-low;
				}
			}

			.b-113__shedule-description {
				flex-grow: 1;
				@include lineClamp(1);
			}





// vizualni debug - plab only
.patternlab .b-113 {
	margin-left: 50px;

	@include breakpoint($breakpoint_desktop) {
		max-width: 420px;
	}
}
