//
// Wave - Spolecnost
//



.wave.page-spolecnost {
	
	/* grid */
	.l-grid--6-1.item-first {
		margin-right: 0;
		padding-right: 0;
		width: 100%;
		
		@include breakpoint($breakpoint_desktop) {
			padding-right: 1em; // kvuli sdilenym boxum b-004i s b-044a
			
			margin-right: -100%;
			padding-right: 1em;
			width: 50%;
		}
	}
	.l-grid--6-7.item-first {
		padding-left: 0;
		width: 100%;

		@include breakpoint($breakpoint_desktop) {
			padding-left: 1em; // kvuli sdilenym boxum b-004i s b-044a
			
			padding-left: 1em;
			width: 50%;
		}
	}
	
	
	
	/* b-004i - deli se s Wave News */
	.l-grid--6-7.item-first .b-004i {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
		
		.b-004__list.b-004i__list {
		}
			.b-004i__list-item {
				@include breakpoint($breakpoint_desktop) {
					flex-basis: 100%;
					max-width: 100%;
					
					margin-bottom: 0;
				}
			}
			
			.b-004i__list-item:nth-child(2n+1) {
				@include breakpoint($breakpoint_desktop) {
					padding-right: 0;
				}
			}
	}
	
	
	
	/* b-044a wave news carousel - deli se s b-004i */
	.b-044a {
		margin-top: 0;
		margin-bottom: 1.25em;
		
		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
			margin-bottom: 0;
		}
		
		.b-044a__list-item {
			
			@include breakpoint($breakpoint_desktop) {
				
				min-width: 80%;
			}
		}
	}



}