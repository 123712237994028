//
// B-028a styles
//


$b-028a_fontsize_list: 14px;
$b-028a_fontsize_list_desktop: 16px;
$b-028a_lineheight_list: 19px;
$b-028a_lineheight_list_desktop: 20px;



.b-028a {

	//padding: 0 $layout_main_horizontal-padding_mobile;

	@include breakpoint($breakpoint_desktop) {
		//padding: 0;
	}
}

	.b-028a__subsection {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@include breakpoint($breakpoint_desktop) {
			flex-wrap: nowrap;
		}
	}

		.b-028a__subblock {
			width: 50%;

			@include breakpoint($breakpoint_desktop) {
				width: 25%;
				//min-width: 187px; // main sloupec je 750px
			}
		}

			.b-028a__list {
				margin-right: 2em;
				margin-bottom: 2em;

				font-size: $b-028a_fontsize_list;
				line-height: $b-028a_lineheight_list;

				@include breakpoint($breakpoint_desktop) {
					margin-right: 1.5em;
					margin-bottom: 1em;

					font-size: $b-028a_fontsize_list_desktop;
					line-height: $b-028a_lineheight_list_desktop;
				}
			}

				.b-028a__title {
					@include typography_font(medium);

					color: $color_background-9;

					margin-bottom: 0.75em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0.5em;
					}
				}

				.b-028a__item {
					@include typography_font(regular);

					color: $color_background-6;

					margin-bottom: 1em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0.5em;
					}
				}

					.b-028a__item a {
						@include link-style($color_decoration_main, none, $color_decoration_main, underline);
					}

	.b-028a .b-032b {
		margin-top: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 2em;
		}
	}

		.b-028a .b-032b h2 {
			display: none;
		}





// vizualni debug - plab only
.patternlab .b-028a {

	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez main sloupec (v desktop)
		max-width: 750px;
	}
}
