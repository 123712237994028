//
// B-032b Styles
//



.b-032b {
}

	.b-032b h2 {
		text-transform: uppercase;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 0.5em;
		}
	}

	// seznam socialnich buttonu v desktop verzi
	.b-032b__list {
		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	.b-032b__list-item {
		margin-right: 0.5em;
		margin-bottom: 0.5em;
	}

	.b-032b__list-item .button-social {
		width: 3em;
		height: 2em;
	}

	// button s bublinou v mobilni verzi
	.b-032b .button-social--share-bubble__wrapper {
		display: block;

		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}
