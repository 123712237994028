//
// Button Popup
//



// struktura obecneho popup tlacitka
.button-dropdown {

	display: inline-block;
	background-color: $color_background-2;
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-dropdown:hover {
	background-color: $color_background-1;
}

	.button-dropdown__label {
	}

		.button-dropdown__label-nest {

			display: none;
		}



// zaviraci tlacitko X
.button-dropdown--close {

	.button-dropdown__label {

		width: 1.5em;
		height: 1.5em;

		background-image: $file_icon_arrow_single_north_colored-decoration-main;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 0.85em;
	}
}



// otviraci tlacitko +
.button-dropdown--open {

	.button-dropdown__label {

		width: 1.5em;
		height: 1.5em;

		background-image: $file_icon_arrow_single_south_colored-decoration-main;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 0.85em;
	}
}
