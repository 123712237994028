//
// B-041b Styles
// B-041c Styles
//


// POZOR: duplicita s b-022


$b-041b_image-width-basis: 14em;
$b-041c_image-width-basis: 14em;



.b-041b,
.b-041c {
}
	
	.b-041b__list,
	.b-041c__list {
	}
		
		.b-041b__list-item,
		.b-041c__list-item {
			display: flex;
			flex-direction: column;
			
			margin-bottom: 1em;
			
			@include breakpoint($breakpoint_desktop) {
				
				flex-direction: row;
				
				margin-bottom: 2em;
			}
		}
			
			.b-041b__block,
			.b-041c__block {
			}
			
			.b-041b__block--image,
			.b-041c__block--image {
				
				@include breakpoint($breakpoint_desktop) {
					flex-basis: $b-041b_image-width-basis;
					flex-shrink: 0;
				}
			}
				
				.b-041b__image,
				.b-041c__image {
					position: relative;
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-041b__image .tag,
					.b-041c__image .tag {
						position: absolute;
						bottom: 0;
						left: 10px;
						
						max-inline-size: calc(100% - 20px);
					}
			
			
			.b-041b__block--description,
			.b-041c__block--description {
				flex-grow: 1;
				
				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041b__block--description h3,
				.b-041c__block--description h3 {
					margin-top: 1em;
					margin-bottom: 0;
					
					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
					}
				}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-041b__block--description p,
				.b-041c__block--description p {
					margin-top: 0.5em;
					margin-bottom: 0.5em;
					
					color: $color_text_paragraph;
				}
				
				.b-041b__actions,
				.b-041c__actions {
				}
					
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-041b__actions .button-listaction,
					.b-041c__actions .button-listaction {
						margin-right: 0.25em;
					}
