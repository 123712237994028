//
// Dvojka - O puvodu prijmeni
//



.page-node.page-node-7231789 {
  .view-filters {
    .views-exposed-form {
      .views-exposed-widgets {
        .views-submit-button-wrapper {
          display: flex;
        }
      }
    }
  }
}