//
// Exposed filters Styles
//



// vars
$view-filters_tab_border: 2px solid $color_decoration_main;
$view-filters_tab_border-radius: 25px;

$view-filters_tab_padding-horizontal_mobile: 1.5em;
$view-filters_tab_padding-horizontal_desktop: 1.25em;
$view-filters_tab_padding-vertical_mobile: 0.25em;
$view-filters_tab_padding-vertical_desktop: 0.10em;
$view-filters_tab_font-size: 15px;
$view-filters_tab_line-height: 24px;

$view-filters_search-submit-button_width_mobile: 38px;
$view-filters_search-submit-button_width_desktop: 56px;
$view-filters_search-submit_background-icon-size_mobile: 18px;
$view-filters_search-submit_background-icon-size_desktop: 22px;

$view-filters_form-padding: 20px;
$view-filters_form-spacing: 1em;

$view-filters_button_cancel: $color_background-6;

$view-filters_map-world_color_africa: rgba(#ff6474, 1);
$view-filters_map-world_color_antarctica: rgba(#33bedb, 1);
$view-filters_map-world_color_asia: rgba(#f8e43e, 1);
$view-filters_map-world_color_australia: rgba(#79bb5c, 1);
$view-filters_map-world_color_europe: rgba(#ff9b45, 1);
$view-filters_map-world_color_north-america: rgba(#9f79e8, 1);
$view-filters_map-world_color_south-america: rgba(#6c96ca, 1);


// mixins
@mixin view-filters_tab() {
	display: block;

	border: $view-filters_tab_border;
	border-radius: $view-filters_tab_border-radius;

	padding: $view-filters_tab_padding-vertical_mobile $view-filters_tab_padding-horizontal_mobile;
	margin-right: 0.5em;
	margin-bottom: 0.5em;

	background-color: white;

	font-size: $view-filters_tab_font-size;
  line-height: $view-filters_tab_line-height;
	text-align: center;

	color: $color_decoration_main;
	@include link-style($color_decoration_main, none, $color_decoration_main, none);

	@include no-select();
	cursor: pointer;


	@include breakpoint($breakpoint_desktop) {
		padding: $view-filters_tab_padding-vertical_desktop $view-filters_tab_padding-horizontal_desktop;
		//margin-right: 0.75em;
		//margin-bottom: 0.75em;
	}
}
@mixin view-filters_tab--lastchild() {
	margin-right: 0;
	margin-bottom: 0;

	@include breakpoint($breakpoint_desktop) {
	}
}
@mixin view-filters_tab--active() {
	opacity: 0.8;
}
@mixin view-filters_tab--checked() {
	background-color: $color_decoration_main;

	color: $color_background-0;
	@include link-style($color_background-0, none, $color_background-0, none);
}
@mixin view-filters_tab--disabled() {
	/*color: $color_background-7;
	@include link-style($color_background-7, none, $color_background-7, none);
	border-color: $color_background-7;*/

	cursor: default;
  pointer-events: none;
	opacity: 0.33;
}


// tady se na vahu selektoru kasle
// s markupem se stejne v teto fazi neda hnout, tak at v tom neni uplnej border klidne zanoruju
.view-filters {
	margin-bottom: 1em;


	// nema svoji tridu na kterou by se dalo obecne chytit
	// zaroven je potreba resetovat zde pravidla z 05_html_elements
	// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
	form {
		// reset default vlastnosti
		padding: 0;
		background-color: transparent;


		.views-exposed-form {

			.views-exposed-widgets {

				.views-exposed-widget {
					width: 100%; // IE fix
				}


				// reset D
				// obecne jak pro radu checkboxu, tak pro sekci search inputu a submitu
				.views-exposed-widget {
					float: initial;
					clear: both;
					padding: 0;
				}



				/* nevyuzita struktura obalovaci > views-widget > form-type-select > form-checkboxes */
				// filtrovaci checkboxy
				// filtrovaci radiobuttony
				.bef-checkboxes,
				.bef-select-as-radios {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					@include breakpoint($breakpoint_desktop) {
					}

					/* obecny - checkbox item */
					.form-item {

						@include breakpoint($breakpoint_desktop) {
						}

						input[type='checkbox'],
						input[type='radio'] {

							// reset default vlastnosti z default 05_html_elements
							// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
							margin: 0;
							position: initial;
							top: auto;
							width: auto;

							// stylovani
							display: none;
						}

						input[type='checkbox'] + label,
						input[type='radio'] + label {

							// reset default vlastnosti z default 05_html_elements
							// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
							@include typography_font(regular);
							text-transform: none;

							// stylovani
							@include view-filters_tab();
						}
						/* disabled */
						&.disabled {
							input[type='checkbox'] + label,
							input[type='radio'] + label,
							input[type='checkbox'] + label:active,
							input[type='radio'] + label:active,
							input[type='checkbox']:checked + label:active,
							input[type='radio']:checked + label:active {
								@include view-filters_tab--disabled();
							}
						}
						/* last */
						&.form-item:last-child input[type='checkbox'] + label,
						&.form-item:last-child input[type='radio'] + label {
							@include view-filters_tab--lastchild();
						}
						/* active */
						input[type='checkbox'] + label:active,
						input[type='radio'] + label:active,
						input[type='checkbox']:checked + label:active,
						input[type='radio']:checked + label:active {
							@include view-filters_tab--active();
						}
						/* checked */
						input[type='checkbox']:checked + label,
						input[type='radio']:checked + label {
							@include view-filters_tab--checked();
						}

						// selectors.scss nastavuji vlastnost display: flex; pro item, to zde nechceme
						// vztahuje se pouze pro variantu s radiobuttony
						&.form-type-radio {
							display: block;
						}
					}
					/* specificke "vse" */
					.form-item.form-item-all {

						a {

							@include view-filters_tab();
						}
						a:active {

							@include view-filters_tab--active();
						}

						&.highlight a {
							background-color: $color_decoration_main;
							color: $color_background-0;
						}
					}
					.form-item.form-item-edit-field-term-theme-all--checked {

						a {

							@include view-filters_tab--checked();
						}
					}
				}




				// kombo search inputu a submitu
				.views-submit-button-wrapper {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: stretch;

					margin-top: 1em;

					width: 100%; // IE fix

					@include breakpoint($breakpoint_desktop) {
						//margin-top: 0.75em;
					}


					// search input
					.views-widget {
						flex-grow: 1;
						width: auto;


						@include breakpoint($breakpoint_desktop) {
							flex-grow: 0;
							width: 30%;
						}


						.form-item-combine {
							width: 100%;



							// input editbox
							input[type='text'] {
								// reset default vlastnosti z default 05_html_elements
								// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
								border: 0;
								box-shadow: none;

								// stylovani
								width: 100%;
								height: 2.25em;
								padding-left: 0.5em;

								font-size: 18px;

								background-color: $color_background-2;


								@include breakpoint($breakpoint_desktop) {
									height: 2.25em;
									font-size: 22px;
								}

								&:focus {
									border: 0;
									box-shadow: none;
								}
							}
						}
					}


					// submit
					.views-submit-button {
						padding: 0;

						input[type='submit'] {

							// reset default vlastnosti z default 05_html_elements
							// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
							// - zde netreba vse je nasledne specificky prebito

							// stylovani
							width: $view-filters_search-submit-button_width_mobile;
							height: 2.25em;

							margin-top: 0;
							padding: 1em;

							border: 0;

							text-indent: -9999px;
							color: transparent;
							// IE schovavani textu
							//font-size: 0;
							//line-height: 0;

							font-size: 18px;

							background-color: $color_background-2;

							background-image: $file_icon_search_colored-bg5;
							background-repeat: no-repeat;
							background-position: center center;
							background-size: $view-filters_search-submit_background-icon-size_mobile $view-filters_search-submit_background-icon-size_mobile;

							cursor: pointer;


							@include breakpoint($breakpoint_desktop) {
								width: $view-filters_search-submit-button_width_desktop;
								height: 2.25em;

								font-size: 22px;

								background-size: $view-filters_search-submit_background-icon-size_desktop $view-filters_search-submit_background-icon-size_desktop;
							}
						}
						// TODO pseudohover nedefinovany nikde jak ma vypadat
						input:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

// odrazeni v ruznych pripadech
.b-050e .view-filters, // portal/podcasty
.b-004f .view-filters, // temata/priroda
.page-node-7969971 .b-022a .view-filters, // node 7969971 - vylety
.page-node-8767933 .b-022a .view-filters { // node 8767933 - cesta okolo ceska
	margin-bottom: 2em;

	@include breakpoint($breakpoint_desktop) {
		margin-bottom: 3em;
	}
}



// v Node nechceme
// nove kvuli boxum b-022a na strance poradu chceme
.page-node:not(.node-type-show) {
	.view-filters {
		.views-exposed-form {
			.views-exposed-widgets {
				.views-submit-button-wrapper {
					display: none;
				}
			}
		}
	}
}



// varianta selectbox + fulltext + mapa cr/world
// zatim omezeno na konkretni node, kazda varianta (checkboxy + fulltext, selectbox + fulltext) bude muset mit svoji tridu
// 7969971 vylety
// 8767933 cesta okolo ceska
.page-node-7969971,
.page-node-8767933 {

	.view-filters {

		form {
			// reset default vlastnosti
			padding: 1em;
			background-color: $color_background-2;


			.views-exposed-form {

				.views-exposed-widgets {
					margin-bottom: 0;

					@include breakpoint($breakpoint_desktop) {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						//align-items: center; // kdyz nema select label tak nechceme
						justify-content: space-between;
					}

					.views-exposed-widget {
						width: auto;
					}


					// reset D
					// obecne jak pro radu selectu, tak pro sekci search inputu a submitu
					.views-exposed-widget {

						@include breakpoint($breakpoint_desktop) {
							flex-shrink: 0;

							//width: 100%;

							float: none;
							clear: none;
							padding: 0;

							width: 50%;
						}

						label {
							display:  block;

							@include typography_font(regular);
							text-transform: none;

							margin-bottom: 0.5em;
						}

						.views-widget {

							.form-type-select {

								select {
									width: 100%;

									@include breakpoint($breakpoint_desktop) {

										width: auto;
										min-width: 75%;
									}
								}
							}
						}
					}


					.views-submit-button-wrapper {
						display: flex;

						@include breakpoint($breakpoint_desktop) {
							justify-content: flex-end;
							margin-top: 0;
						}

						.views-widget {

							@include breakpoint($breakpoint_desktop) {
								width: auto;
								margin-left: 2em;
							}

							.form-item-combine {

								input[type=text] {
									background-color: $color_background-0;
								}
							}
						}

						.views-submit-button {

								input[type=submit] {
									background-color: $color_background-0;
								}
						}

						.views-submit-button {

							@include breakpoint($breakpoint_desktop) {
								width: auto;
							}
						}
					}
				}

				// mapa cr
				.views-exposed-map {
					display: none;

					@include breakpoint($breakpoint_desktop) {
						display: block;

						margin-top: 1.5em;
						//padding-top: 1.5em; // jen s variantou s borderem, ales border nechce

						//border-top: 1px solid $color_background-4; // ales border nechce
					}
				}
			}


			.views-exposed-widgets.clearfix::after {
				display: none;
			}
		}
	}
}



// varianta mapa cr/world
// zatim omezeno na konkretni node
// 7969971 vylety
// 5997828 zapisnik zahranicnich zpravodaju
// 8767933 cesta okolo ceska
.page-node-7969971,
.page-node-5997828,
.page-node-8767933 {

	.view-filters {

		form {
			// reset default vlastnosti
			//padding: 0;


			.views-exposed-form {

				// mapa cr
				.views-exposed-map {
					display: none;

					@include breakpoint($breakpoint_desktop) {
						display: block;

						margin-top: 1.5em;
						//padding-top: 1.5em; // jen s variantou s borderem, ales border nechce

						//border-top: 1px solid $color_background-4; // ales border nechce
					}
				}

					.views-exposed-map svg {
						display: block;
					}

						.views-exposed-map svg .map-region {
							fill: $color_region_main;
						}

						.views-exposed-map svg .map-link:hover .map-region {
							fill: $color_region_main_darker;
						}

						.views-exposed-map svg .map-link--selected .map-region {
							fill: $color_region_add-1;
						}

						.views-exposed-map svg .map-region,
						.views-exposed-map svg .map-city,
						.views-exposed-map svg .map-city-text {
							fill-rule: evenodd;
						}

						.views-exposed-map svg .map-city {
							fill: $color_background-4;
						}

						.views-exposed-map svg .map-city-text {
							fill: $color_text_accent_inverted;
						}


				// mapa sveta
				.views-exposed-map-world {
					//display: none;

					//border: 1px solid $color_divider; // ales nakonec nechce

					@include breakpoint($breakpoint_desktop) {

						//display: block;

						//margin-top: 1.5em; // jen s variantou se selectboxem a fulltextem
						//padding-top: 1.5em; // jen s variantou s borderem, ales border nechce

						//border-top: 1px solid $color_background-4; // ales border nechce
					}
				}

					.views-exposed-map-world svg {
						display: block;
					}

						.views-exposed-map-world svg .group__background rect {
							fill: $color_background-4;
						}

						.views-exposed-map-world svg .group__background path {
							fill: $color_background-1;
						}

						.views-exposed-map-world svg .group__continents {
							fill: $color_background-4;
						}

						.views-exposed-map-world svg .group__continents .map-world-link .continent--africa {
							fill: $view-filters_map-world_color_africa
						}

						.views-exposed-map-world svg .group__continents .map-world-link .continent--antarctica {
							fill: $view-filters_map-world_color_antarctica;
						}

						.views-exposed-map-world svg .group__continents .map-world-link .continent--asia {
							fill: $view-filters_map-world_color_asia;
						}

						.views-exposed-map-world svg .group__continents .map-world-link .continent--australia {
							fill: $view-filters_map-world_color_australia;
						}

						.views-exposed-map-world svg .group__continents .map-world-link .continent--europe {
							fill: $view-filters_map-world_color_europe;
						}

						.views-exposed-map-world svg .group__continents .map-world-link .continent--north-america {
							fill: $view-filters_map-world_color_north-america;
						}

						.views-exposed-map-world svg .group__continents .map-world-link .continent--south-america {
							fill: $view-filters_map-world_color_south-america;
						}

						.views-exposed-map-world svg .group__continents .map-world-link:hover .continent {
							fill: $color_background-9;
						}

						.views-exposed-map-world svg .group__continents .hover-zone {
							fill: transparent;
							opacity: 0;
						}

						.views-exposed-map-world svg .group__continents .hover-zone:hover {
							cursor: pointer;
						}
			}


			.views-exposed-widgets.clearfix::after {
				display: none;
			}
		}
	}
}



// checkboxy + varianta mapa cr/world
// zatim omezeno na konkretni node, kazda varianta (checkboxy) bude muset mit svoji tridu
// 5997828 zapisnik zahranicnich zpravodaju
.page-node-5997828 {

	.view-filters {

		form {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-exposed-widget {

						.views-widget {

							.form-type-select {

								.form-checkboxes {

									/* nevyuzita struktura obalovaci > views-widget > form-type-select > form-checkboxes */
									// filtrovaci checkboxy
									.bef-checkboxes {
									}

										/* obecny - checkbox item */
										.form-item {

											// border collapse hack - po zalomeni u desktopu
											@include breakpoint($breakpoint_desktop) {
											}

											input[type='checkbox'] {
											}

											input[type='checkbox'] + label {

												@include breakpoint($breakpoint_desktop) {
												}
											}
											/* last */
											&.form-item:last-child input[type='checkbox'] + label {
											}
											/* active */
											input[type='checkbox'] + label:active,
											input[type='checkbox']:checked + label:active {
											}
											/* checked */
											input[type='checkbox']:checked + label {
											}
										}
										/* barvy dle svetadilu mapy */
										.form-item:nth-of-type(2) input[type='checkbox'] + label {

											color: $view-filters_map-world_color_africa;
											border: 2px solid $view-filters_map-world_color_africa;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(2) input[type='checkbox']:checked + label {

											background-color: $view-filters_map-world_color_africa;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(3) input[type='checkbox'] + label {

											color: $view-filters_map-world_color_antarctica;
											border: 2px solid $view-filters_map-world_color_antarctica;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(3) input[type='checkbox']:checked + label {

											background-color: $view-filters_map-world_color_antarctica;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(4) input[type='checkbox'] + label {

											color: $view-filters_map-world_color_asia;
											border: 2px solid $view-filters_map-world_color_asia;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(4) input[type='checkbox']:checked + label {

											background-color: $view-filters_map-world_color_asia;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(5) input[type='checkbox'] + label {

											color: $view-filters_map-world_color_australia;
											border: 2px solid $view-filters_map-world_color_australia;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(5) input[type='checkbox']:checked + label {

											background-color: $view-filters_map-world_color_australia;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(6) input[type='checkbox'] + label {

											color: $view-filters_map-world_color_europe;
											border: 2px solid $view-filters_map-world_color_europe;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(6) input[type='checkbox']:checked + label {

											background-color: $view-filters_map-world_color_europe;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(7) input[type='checkbox'] + label {

											color: $view-filters_map-world_color_south-america;
											border: 2px solid $view-filters_map-world_color_south-america;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(7) input[type='checkbox']:checked + label {

											background-color: $view-filters_map-world_color_south-america;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(8) input[type='checkbox'] + label {

											color: $view-filters_map-world_color_north-america;
											border: 2px solid $view-filters_map-world_color_north-america;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(8) input[type='checkbox']:checked + label {

											background-color: $view-filters_map-world_color_north-america;
											color: $color_text_link_inverted;
										}
										/* specificke "vse" */
										.form-item.form-item-all {

											a {
											}
											a:active {
											}

											&.highlight a {

											}
										}
										.form-item.form-item-edit-field-term-theme-all--checked {

											a {
											}
										}


								}
							}
						}
					}
				}

				.views-exposed-widgets.clearfix::after {
					display: none;
				}
			}
		}
	}
}





// varianta filtru s automaticky formatovanymi poli
.views-exposed-form-formatted {
	label, select {
		@include no-select;
	}
	&__toggle-area {
		display: none;
		&.is-expanded {
			display: block;
		}
	}
	// radek
	&__row {
		@include breakpoint($breakpoint_tablet) {
			display: flex;
			flex-wrap: wrap;
		}
		// modifikator
		&--border-bottom {
			border-bottom: 1px solid $color_background-4;
		}
	}
	// dva sloupce
	&__two-cols {
		@include breakpoint($breakpoint_tablet) {
			flex-basis: 45%;
			flex-grow: 1;
			max-width: calc(50% - .5*#{$view-filters_form-spacing});
			margin-right: $view-filters_form-spacing;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
	// tri sloupce
	&__three-cols {
		@include breakpoint($breakpoint_tablet) {
			flex-basis: 30%;
			flex-grow: 1;
			max-width: calc(33.3% - .33*#{$view-filters_form-spacing});
			margin-right: $view-filters_form-spacing;
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
	// roztazeni inputu do 100% sirky rodice
	.views-widget {
		width: 100%;
		@include clearfix;
		select {
			width: 100%;
		}
	}
	// spodni cast formulare
	&__bottom-area {
		display: flex;
		flex-wrap: wrap;
		margin-top: $view-filters_form-spacing;
		&.is-expanded {
			margin-top: 0;
		}
	}
	// informacni hlaska
	&__filter-message {
		display: flex;
		order: 2;
		margin-bottom: $view-filters_form-spacing;
		font-size: 15px;
		line-height: 1;
		color: $color_background-6;
		&::before {
			content: '';
			margin-right: 5px;
			min-width: 14px;
			width: 14px;
			height: 14px;
			background-image: $file_icon_info;
			background-repeat: no-repeat;
		}
		@include breakpoint($breakpoint_tablet) {
			align-items: center;
			order: 1;
		}
	}
	// toggle button
	&__button-show-more {
		display: flex;
		justify-content: flex-end;
		order: 1;
		cursor: pointer;
		font-size: 90%;
		color: $color_background-6;
		width: 100%;
		margin-bottom: $view-filters_form-spacing;
		@include no-select;
		@include breakpoint($breakpoint_tablet) {
			width: auto;
			flex-grow: 1;
			order: 1;
		}
		&::before {
			content: '';
			display: inline-block;
			width: 24px;
			height: 24px;
			background: $file_icon_arrow_single_south_colored-decoration-main no-repeat 50%;
			background-size: 12px 12px;
			transform: rotate(180deg);
			transition: transform .25s ease-out;
		}
		&.is-closed {
			&::before {
				transform: rotate(0deg);
			}
		}
	}
	// tlacitka
	&__buttons {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: flex-end;
		@include breakpoint($breakpoint_desktop) {
			flex-basis: 70%;
			max-width: 70%;
		}
		& > div,
		& > div input{
			width: 100%;
			@include breakpoint($breakpoint_tablet) {
				width: auto;
			}
		}
		.views-exposed-widget:not(.views-submit-button) {
			margin-bottom: $view-filters_form-padding;
			@include breakpoint($breakpoint_tablet) {
				margin-right: $view-filters_form-padding;
				margin-bottom: 0;
			}
		}
		input#edit-reset {
			background-color: $view-filters_button_cancel;
		}
	}
	// wrapper
	&__wrapper {
		width: 100%;
		order: 3;
		@include breakpoint($breakpoint_tablet) {
			display: flex;
			flex-wrap: wrap;
		}
	}
	// hledani receptu
	&__search {
		@include breakpoint($breakpoint_desktop) {
			flex-basis: calc(30% - #{$view-filters_form-padding});
			flex-grow: 1;
			max-width: 30%;
			margin-right: $view-filters_form-padding;
		}
		input {
			padding-left: .75em;
			padding-right: 2.5em;
			background-image: $file_icon_search;
			background-repeat: no-repeat;
			background-position: center right .5em;
			background-size: 1.5em 1.5em;
			line-height: 3em;
		}
	}
	// zarovnani ikon u autocomplete
	input.form-autocomplete {
		background-position: 100% 12px;
	}
}
