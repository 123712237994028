//
// Program - vypis 6 poradu
//


.b-038e {
	h2 {
		color: $color_background-6;
	}
	.main {
		padding: 0;
		max-width: 100%;
		&__block {
			display: flex;
			margin-top: 20px;
			margin-bottom: 20px;
			@include breakpoint($breakpoint_tablet) {
				margin-top: 50px;
				margin-bottom: 50px;
			}
			@include breakpoint($breakpoint_desktop) {
				margin-top: 70px;
				margin-bottom: 70px;
			}
		}
		&__block-info {
			color: $color_background-0;
			background-color: $color_background-5;
			font-size: .75rem;
			position: relative;
			top: 69px;
			display: inline-block;
			height: 20px;
			line-height: 1.5;
			padding: 0 4px 0 6px;
			@include no-select;
			@include breakpoint($breakpoint_tablet) {
				top: 78px;
			}
			@include breakpoint($breakpoint_desktop) {
				top: 97px;
			}
			&:after {
				content: '';
				position: absolute;
				right: -10px;
				top: 0;
				width: 0;
				height: 0;
				border-bottom: 20px solid $color_background-5;
				border-right: 10px solid transparent;
			}
		}
		&__station {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			flex-shrink: 0;
			@include breakpoint($breakpoint_tablet) {
				width: 100%;
			}
			&--radiozurnal {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_radiozurnal_main;
				}
			}
			&--dvojka {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_dvojka_main;
				}
			}
			&--plus {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_plus_main;
				}
			}
			&--vltava {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_vltava_main;
				}
			}
			&--radiowave {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_wave_main;
				}
			}
			&--d-dur {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_d-dur_main;
				}
			}
			&--jazz {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_jazz_main;
				}
			}
			&--cro7,
			&--archiv,
			&--radiojunior {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_corporate_main;
				}
			}
			&--regional-menu {
				display: none;
				@include breakpoint($breakpoint_tablet) {
					display: flex;
				}
				.main__station-name {
					background-color: $color_background-3;
					color: $color_background-6;
					text-transform: lowercase;
					font-size: .85rem;
					@include typography_font(bold);
					&:first-letter {
						text-transform: uppercase;
					}
				}
				.toggle-station {
					color: $color_background-0;
					cursor: pointer;
					background: $file_logo_R_white no-repeat 20px center $color_region_main;
					background-size: 24px 24px;
					line-height: 1.2;
					position: relative;
					@include no-select;
					&:hover {
						background-color: darken($color_region_main, 4%);
					}
					&:before {
						content: '';
						background-color: $color_background-0;
						display: inline-block;
						width: 100%;
						height: 1px;
						opacity: .5;
						position: absolute;
						bottom: 0;
					}
					&:after {
						content: '';
						background-color: $color_background-0;
						border: 1px solid $color_background-5;
						width: 12px;
						height: 12px;
						display: inline-block;
						position: absolute;
						top: 17px;
						right: 20px;
					}
					&__wrap {
						padding: 12px 40px 12px 60px;
						position: relative;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						&:before {
							content: '';
							opacity: 0;
							position: absolute;
							top: 23px;
							right: 27px;
							width: 7px;
							height: 2px;
							background-color: $color_background-0;
							transform: rotate(45deg);
							z-index: 1;
							transition: opacity 250ms ease-in-out;
						}
						&:after {
							content: '';
							opacity: 0;
							position: absolute;
							top: 21px;
							right: 18px;
							width: 13px;
							height: 2px;
							background-color: $color_background-0;
							transform: rotate(-45deg);
							z-index: 1;
							transition: opacity 250ms ease-in-out;
						}
					}
					&__station-name {
						color: $color_background-0;
						width: calc(100% - 36px);
						text-transform: initial;
						line-height: 1.6;
						@include typography_font(bold);
						font-size: .9rem;
						&:after {
							content: '';
							display: table;
							clear: both;
						}
					}
					&__station-now-playing {
						display: none;
						width: calc(100% - 36px);
						text-transform: initial;
						@include typography_font(regular);
						font-size: .75rem;
					}
				}
				// checkbox hack
				input[type=checkbox]:checked + .toggle-station {
					background-color: darken($color_region_main, 10%);
					&:after {
						content: '';
						background-color: transparent;
						border: 0;
						transform: scale(0);
						opacity: 0;
					}
				}
				input[type=checkbox]:checked + .toggle-station .toggle-station__wrap::before,
				input[type=checkbox]:checked + .toggle-station .toggle-station__wrap::after {
					opacity: 1;
				}
			}
			&--regional {
				.main__station-name,
				.broadcast-timespan-progress .progress-bar .progress-bar__duration .progress-bar__position {
					background-color: $color_region_main;
				}
			}
		}
		&__station-name {
			display: none;
			padding: 4px 10px 1px 10px;
			margin: -15px 0 26px 0;
			color: $color_background-0;
			@include typography_font(bold);
			background-color: $color_corporate_main;
			text-transform: uppercase;
			font-size: .95rem;
			letter-spacing: .95px;
			@include no-select;
			@include breakpoint($breakpoint_tablet) {
				margin: -36px 0 26px 0;
			}
			&--no-label {
				margin: 14px 0 0 0;
				@include breakpoint($breakpoint_tablet) {
					margin: 10px 0 0 0;
				}
			}
			&--actual {
				margin: 0;
				@include breakpoint($breakpoint_tablet) {
					margin: -36px 0 0 0;
				}
			}
		}
		&__station-program {
			margin: 10px 0 0 0;
			padding: 10px 0;
			position: relative;
			@include breakpoint($breakpoint_tablet) {
				padding: 10px;
			}
			&:before{
				content: '';
				display: inline-block;
				width: 100%;
				height: 1px;
				background: $color_background-3;
				position: absolute;
				top: 0;
				left: 0;
			}
			&--actual {
				background-color: $color_background-2;
				margin: 0 -10px -10px -10px;
				padding: 10px;
				@include breakpoint($breakpoint_tablet) {
					margin: 0 0 -10px 0;
				}
				&:before {
					content: '';
					height: 0;
				}
				&:after {
					content: '';
					display: inline-block;
					width: 100%;
					height: 1px;
					background: $color_background-3;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			&--unavailable {
				color: $color_background-5;
				background: $color_background-2;
				border: 5px solid $color_divider;
				text-align: center;
				display: inline-block;
				padding: 24px 0;
				font-size: .88rem;
			}
			.button.button-play {
				width: 3rem;
				height: 3rem;
				@include breakpoint($breakpoint_desktop) {
					width: 2.7rem;
					height: 2.7rem;
				}
			}
		}
		&__program_preview_image {
			margin: -10px -10px 0 -10px;
			width: calc(100% + 20px);
			max-width: inherit;
		}
		&__time {
			margin-left: 1px;
			font-size: .9rem;
			.broadcast-timespan-progress {
				&__time {
					font-size: .9rem;
				}
			}
		}
		&__name {
			font-size: .9rem;
			@include typography_font(medium);
			line-height: 1.55;
		}
		&__countdown {
			position: relative;
			padding-left: 20px;
			font-size: .75rem;
			color: $color_background-6;
			&:before {
				position: absolute;
				content: '';
				width: 14px;
				height: 14px;
				top: 5px;
				left: 0;
				display: inline-block;
				background: $file_icon_timer;
			}
		}
		&__description {
			color: $color_background-6;
			font-size: .9rem;
			line-height: 1.5;
		}
		&__showmore {
			font-size: .8rem;
			color: $color_decoration_main;
		}
		&__buttons {
			display: flex;
			justify-content: space-between;
			font-size: .85rem;
			line-height: 1;
			align-items: flex-end;
			margin-top: 10px;
			margin-bottom: 4px;
			@include breakpoint($breakpoint_desktop) {
				font-size: .88rem;
			}
		}
		&__archive {
			font-size: .8rem;
			color: $color_decoration_main;
			width: auto;
			margin: 8px 0 0 0;
			display: inline-block;
			padding-left: 25px;
			background: $file_icon_badge_audio_colored-main no-repeat;
			background-position: 0 center;
			background-size: 16px 16px;
		}
	}
	// modifikace
	.progress-bar__duration {
		background-color: $color_background-0;
	}
	// odsazeni tlacitka pro zobrazeni celeho programu
	.button.button-box-flat--program {
		margin-top: 25px;
		@include breakpoint($breakpoint_desktop) {
			margin-top: 50px;
		}
	}
}
