//
// eu cookie banner
//
// funkcne odvozeno od rscr _common_eu_cookie_banner.inc
// https://phpfashion.com/jak-na-souhlas-s-cookie-ve-zkurvene-eu
//


$eu_cookies_wrapper_padding_mobile: 1em;
$eu_cookies_text_fontsize: $fontsize_h4_desktop;



.eu-cookies {
	
	// styly tohoto elementu nejsou design, ale funkce
	// nadrazenost nasledujicimu "nadbytecnemu" wrapperu je schvalne kvuli display:block a naslednemu JS schovavani
	
	//display: block;
	// default, ale vychozi je skryto
	// pro vizualizaci v plabu zapnuto specifickym stylopisem v 90_plab_only
	display: none;
	
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 3000;
}
	
	.eu-cookies__wrapper {
		
		display: flex;
		flex-direction: column;
		
		padding: $eu_cookies_wrapper_padding_mobile;
		
		background-color: $color_background-9;
		
		@include breakpoint($breakpoint_desktop) {
			
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			
			padding: 0;
		}
	}
		
		.eu-cookies__text {
			
			margin-bottom: $eu_cookies_wrapper_padding_mobile;
			
			color: $color_text_paragraph_inverted;
			
			@include adjust-font-size-to($eu_cookies_text_fontsize, 0.75);
			
			
			@include breakpoint($breakpoint_desktop) {
				
				margin: 0;
				
				padding-left: 2em;
				padding-right: 2em;
			}
		}
			
			.eu-cookies__readmore-link {
				
				padding-left: 0.25em;
				
				@include link-style($color_text_accent_inverted, underline, $color_text_accent_inverted, underline);
			}
		
		
		// z duvodu atomu neBEMova selekce
		.eu-cookies .button {
			
			display: inline-block; // vicemene fallback pro flex, aby se nejak zarovnalo..
			width: auto;
		}