//
// B-006b Styles
//



.b-006b {
}

	.b-006b__list {

		// oba listy zaroven sami flexuji vlastni obsah
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {

			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-006b__list-item {

			margin-bottom: 1.5em;

			@include breakpoint($breakpoint_desktop) {

				//flex-basis: 50%; // ie11 se nelibi
				width: 50%;

				margin-bottom: 2em;
			}
		}

		// posledni
		.b-006b__list-item:last-child {
			margin-bottom: 0;
		}

		// predposledni
		.b-006b__list-item:nth-last-child(2) {
			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 0;
			}
		}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006b__list-item h3 {

				margin: 0;
				padding-top: 0.5em;
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006b__list-item a p {

				margin-top: 0.5em;
				margin-bottom: 0;

				color: $color_text_paragraph;
				@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
			}

			// muze byt i mimo <a>
			.b-006b__list-item p {
				margin-top: 0.5em;
				margin-bottom: 0;

				color: $color_text_paragraph;
			}


		.b-006b__list-item:nth-child(odd) {

			@include breakpoint($breakpoint_desktop) {

				padding-right: 1em;
			}
		}

		.b-006b__list-item:nth-child(even) {

			@include breakpoint($breakpoint_desktop) {

				padding-left: 1em;
			}
		}


	.b-006b__buttons-wrap {

		text-align: center;

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.button-box-flat-small {

			display: inline-flex;

			margin-top: 3em;
		}
	}
