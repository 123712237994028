//
// page-kamery
//



.page-kamery {

	.box.b-033a {
		// prvni box leveho sloupce nepotrebuje default vertikalni odsazeni
		//margin-top: 0;
	}

	// webcam thumbnails
	.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
		background-image: $file_thumbnail_webcam-default;
		background-repeat: no-repeat;
		background-size: 100% auto;

		// aspect ratio
		position: relative;
		width: 100%;
		//padding-top: 100%; // 1:1 Aspect Ratio
		padding-top: 56.25%; // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
		//padding-top: 75%; // 4:3 Aspect Ratio (divide 3 by 4 = 0.75)
		//padding-top: 66.66%; // 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)

		// aspect ration: img positioning
		> img {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
	}

	// specifikace pokud je nadrazeny thumb 'selected'
	.b-033a__webcam-thumbnail.selected > .b-033a__webcam-thumbnail-image {
		//opacity: 0.3334;

		> img {
			opacity: 1; // reset puvodniho reseni, viz. _b-033a.scss
		}
	}

	.b-033a__webcam-thumbnail.selected > .b-033a__webcam-thumbnail-image::after {
		height: 100%;

		// k pretizeni specifickou stanicni barvou
		background-color: unset !important; // reset

		// k pretizeni specifickou stanicni barvou
		background-image: linear-gradient(to bottom, $color_decoration_main 0 10px, rgba($color_background-0, 0.6666) 10px 100%); // 1 - 0.3334 = 0.6666
	}


	// studia - celoplosne
	&.dvojka {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-dvojka;
		}
	}

	&.vltava {
		.b-033a__webcam-thumbnail-list-item:nth-child(1) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-vltava;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(2) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-vltava;
		}
	}

	&.plus {
		.b-033a__webcam-thumbnail-list-item:nth-child(1) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-plus;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(2) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-plus;
		}
	}

	&.wave {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-wave;
		}
	}

	&.junior {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-junior;
		}
	}

	// studia - regionalni
	&.brno {
		.b-033a__webcam-thumbnail-list-item:nth-child(1) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-brno;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(2) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-brno;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(3) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-zlin;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(4) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-zlin;
		}
	}

	&.budejovice {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-budejovice;
		}
	}

	&.liberec {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-liberec;
		}
	}

	&.hradec {
		.b-033a__webcam-thumbnail-list-item:nth-child(1) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-hradec;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(2) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-hradec;
		}
	}

	&.olomouc {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-olomouc;
		}
	}

	&.ostrava {
		.b-033a__webcam-thumbnail-list-item:nth-child(1) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-ostrava;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(2) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-ostrava;
		}
	}

	&.plzen {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-plzen;
		}
	}

	&.regina {
		.b-033a__webcam-thumbnail-list-item:nth-child(1) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-dab;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(2) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-dab;
		}
	}

	&.sever {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-sever;
		}
	}

	&.strednicechy {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-region;
		}
	}

	&.vary {
		.b-033a__webcam-thumbnail-list-item .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-vary;
		}
	}

	&.zlin {
		.b-033a__webcam-thumbnail-list-item:nth-child(1) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-01_webcam-zlin;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(2) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-zlin;
		}
		.b-033a__webcam-thumbnail-list-item:nth-child(3) .b-033a__webcam-thumbnail-image {
			background-image: $file_thumbnail-02_webcam-brno;
		}
	}


}
