//
// 1968 - HP
//


$domain-1968_region-wrapper-background-color-1: $color_background-0; // white
$domain-1968_region-wrapper-background-color-2: $color_background-2; // light grey
$domain-1968_region-wrapper-background-color-3: $color_background-7; // dark grey
$domain-1968_region-wrapper-background-color-4: rgba(#dee3f6, 1); // light blue
$domain-1968_region-wrapper-background-color-5: rgba(#ca003a, 1); // dark red

$domain-1968_region-wrapper-background-image-1: url('../images/bg/1968/1968_bg-twitter.jpg');
$domain-1968_region-wrapper-background-image-2: url('../images/bg/1968/1968_bg-aplikace.png');

$domain-1968_box-h2-color-1: $color_background-2; // light grey
$domain-1968_box-h2-color-2: $color_text_accent; // black
$domain-1968_box-h2-color-3: $color_decoration_main; // main
$domain-1968_box-h2-color-4: rgba(#ca003a, 1); // dark red

$domain-1968_page_max-width: 1170px; // tj 1250px - 80px (.main left+right padding)

.domain-1968-rozhlas-cz.page-1968-hp { // docasne, nazev tridy nemuze byt 1968

	// main
	.main {
		background-color: transparent;
	}

	// shoutbox
	#shoutbox {
		.button.expand {
			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
		}
	}

	// regionl-grid--12-1 obecne
	.l-grid--12-1 {
		overflow: visible; // kvuli barevnym pruhum
	}

	// pro tento web filtry nepotřebujeme, skryty kvůli (ne)roztahování layoutu
	.view-filters {
		display: none;
	}

	// patra/wrappery obecne
	.region-wrapper {
		position: relative;

		padding-top: 0.1px; // collapsing fix
		padding-bottom: 0.1px; // collapsing fix

		// nekonecny pruh obecne
		&::before {
			content: "";

			position: absolute;
			left: -200%;
			right: -200%;

			height: 100%;

			z-index: -1;
		}

		// box obecne
		.box {
			> h2 {
				margin-bottom: 1.5em;
				padding: 0;

				text-align: center;
			}
		}

		// prvni box v patre
		.box:first-child {
			margin-top: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 3em;
			}
		}

		// posledni box v patre
		.box:last-child {
			margin-bottom: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 5em;
			}
		}

		// box - pseudo node
		.box.box-node {

			@include breakpoint($breakpoint_desktop) {
				max-width: 750px;

				margin-right: auto;
				margin-left: auto;
			}

			// title
			.views-field-title h2 {
				margin-bottom: 1em;
				padding: 0;

				color: $color_text_accent;

				font-size: $fontsize_h1;

				text-transform: none;

				@include breakpoint($breakpoint_desktop) {
					font-size: $fontsize_h1_desktop;
				}
			}

			// perex
			.views-field-field-perex {
				@include typography_font(medium);
				font-size: 1em;
				line-height: 1.6em;

				@include breakpoint($breakpoint_desktop) {
					font-size: 1.2rem;
					//margin-left: $node_left-margin;
					//padding: 0 $node_padding;
				}

				p {
					margin-top: 20px;
					margin-top: 1.25rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;
				}

				strong {
					@include typography_font(bold);
				}

				a {
					@include typography_font(medium);
					@include link-style($color_text_link, none, $color_text_link, none);
					border-bottom: 1px solid $color_background-9;

					&:hover {
						color: $color_background-6;
						border-bottom: 1px solid $color_background-6;
					}
				}

				ul {
					list-style: disc;

					li {
						margin-left: 1em;
					}
				}

				ol {
					list-style: decimal;

					li {
						margin-left: 1.5em;
					}
				}
			}

			// paragraph
			.views-field-body {
				font-size: 1rem;
				line-height: 1.6em;
				color: $color_text_paragraph_node;

				@include breakpoint($breakpoint_desktop) {
					font-size: 1.2rem;
					//margin-left: $node_left-margin;
					//padding: 0 $node_padding;
				}

				p {
					margin-top: 20px;
					margin-top: 1.25rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;
				}

				strong {
					@include typography_font(medium);
					color: $color_text_accent;
				}

				.file-audio {
					margin-left: 0;

					@include breakpoint($breakpoint_desktop) {
						margin: 1.75em -1.875rem;
					}
				}

				/* obecna h2 v node - ovlivnuje vsechny h2 kdekoliv v node, tzn. v primo body, v assetech, apod. */
				h2 {
					clear: both;
					font-size: $fontsize_h2;
					text-transform: none;
					padding: 0;
					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h2_desktop;
					}
				}

				/* h2 primo v body node - mela by ovlivnit jen h2 primo v body clanku */
				> h2 {
					font-size: $fontsize_h2_node;
					line-height: calc(#{$fontsize_h2_node} + 10px);
					margin-top: 40px;
					margin-top: 2.5rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h2_node_desktop;
						line-height: calc(#{$fontsize_h2_node_desktop} + 10px);
					}
				}

				/* obecna h3 v node - ovlivnuje vsechny h3 kdekoliv v node, tzn. v primo body, v assetech, apod. */
				h3 {
					clear: both;
					font-size: $fontsize_h3;
					transform: none;
					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h3_desktop;
					}
				}

				/* h3 primo v body node - mela by ovlivnit jen h3 primo v body clanku */
				> h3 {
					font-size: $fontsize_h3_node;
					line-height: calc(#{$fontsize_h3_node} + 10px);
					margin-top: 40px;
					margin-top: 2.5rem;
					margin-bottom: 20px;
					margin-bottom: 1.25rem;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h3_node_desktop;
						line-height: calc(#{$fontsize_h3_node_desktop} + 10px);
					}
				}

				h4 {
					clear: both;
					font-size: $fontsize_h4;
					transform: none;
					color: $color_text_accent;

					@include breakpoint($breakpoint_desktop) {
						font-size: $fontsize_h4_desktop;
					}
				}

				p:not(.cteteTake) a {
					@include typography_font(medium);
					@include link-style($color_text_link, none, $color_text_link, none);
					border-bottom: 1px solid $color_background-9;

					&:hover {
						color: $color_background-6;
						border-bottom: 1px solid $color_background-6;
					}

					strong {
						color: unset;
					}
				}

				ul {
					list-style: disc;

					li {
						margin-left: 1em;
					}
				}

				ol {
					list-style: decimal;

					li {
						margin-left: 1.5em;
					}
				}
			}

			// more
			.views-field-path .button {
				margin-top: 2em;

				@include breakpoint($breakpoint_desktop) {
					width: 50%;
				}
			}
		}
	}

	// 1. patro - YT video
	.region-wrapper--level-00 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-2;
		}

		// box
		.box {

			@include breakpoint($breakpoint_desktop) {
				max-width: 800px;

				margin-right: auto;
				margin-left: auto;
			}

			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-2;
				a {
					@include link-style ($domain-1968_box-h2-color-2, none, $domain-1968_box-h2-color-2, none);
				}
			}
		}
	}

	// 2. patro - casova osa
	.region-wrapper--level-01 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-1;
		}

		// box
		.box {

			@include breakpoint($breakpoint_desktop) {
				//max-width: 800px;

				//margin-right: auto;
				//margin-left: auto;
			}

			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-3;
				a {
					@include link-style ($domain-1968_box-h2-color-3, none, $domain-1968_box-h2-color-3, none);
				}
			}

			> h2 {
				margin: 0;
				padding-bottom: 2em;

				border-bottom: 1px solid $color_background-2;
			}
		}
	}

	// 3. patro - promo + trojbox
	.region-wrapper--level-02 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-3;
		}

		// box
		.box {
			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-1;
				a {
					@include link-style ($domain-1968_box-h2-color-1, none, $domain-1968_box-h2-color-1, none);
				}
			}
		}
	}

	// 4. patro - program
	.region-wrapper--level-03 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-1;
		}

		// box
		.box {
			@include breakpoint($breakpoint_desktop) {
				max-width: 620px; // default max sirka ditete .pbr-program z _prixbohemia_program.scss

				margin-right: auto;
				margin-left: auto;
			}

			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-2;
				a {
					@include link-style ($domain-1968_box-h2-color-2, none, $domain-1968_box-h2-color-2, none);
				}
			}

			.pbr-program {
				margin-left: 0; //reset hodnot _prixbohemia_program.scss

				//@include breakpoint($breakpoint_desktop) {
					margin-right: auto;
					margin-left: auto;
				//}
			}
		}
	}

	// 5. patro - zatim neni
	.region-wrapper--level-04 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-1;
		}
	}

	// 6. patro - appka
	.region-wrapper--level-05 {
		@include breakpoint($breakpoint_desktop) {
			min-height: 530px; // vyska ilustracniho obrazku
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-5;
		}

		// bg obrazek
		&::after {
			@include breakpoint($breakpoint_desktop) {
				content: "";

				width: 366px;
				height: 530px;

				position: absolute;
				top: 0;
				right: 0;

				background-image: $domain-1968_region-wrapper-background-image-2;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				//background-size: cover;
				background-size: 100%;
				//background-attachment: fixed;

				z-index: -1;
			}
		}

		// box
		.box {
			@include breakpoint($breakpoint_desktop) {
				max-width: 800px;

				margin-right: auto;
				margin-left: auto;
			}

			> h2,
			p {
				color: $domain-1968_box-h2-color-1;

				a {
					@include link-style ($domain-1968_box-h2-color-1, underline, $domain-1968_box-h2-color-1, underline);
				}
			}

			> h2 {
				margin-bottom: 3em;
			}

			p {
				text-align: center;

				@include breakpoint($breakpoint_desktop) {
					max-width: 652px;
					text-align: left;
				}
			}

			p:first-of-type {
				@include typography_font(medium);

				@include breakpoint($breakpoint_desktop) {
					font-size: 18px;
					line-height: 28px;
				}
			}

			.button__wrapper {
				flex-direction: row;
				justify-content: center;

				@include breakpoint($breakpoint_desktop) {
					justify-content: flex-start;
				}
			}

				.button-store {
				}

				.button-store--apple {
					margin-right: 1em;
				}

				.button-store--google {
				}
		}

		// box .clearfix
		.box::after {
			clear: both;
			content: "";
			display: table;
		}
	}

	// 7. patro - fotogalerie karusel + button
	.region-wrapper--level-06 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-2;
		}

		// box
		.box {
			@include breakpoint($breakpoint_desktop) {
				max-width: 800px;

				margin-right: auto;
				margin-left: auto;
			}

			> h2 {
				color: $domain-1968_box-h2-color-4;
				a {
					@include link-style ($domain-1968_box-h2-color-4, none, $domain-1968_box-h2-color-4, none);
				}
			}
		}

		.button__wrapper {
			.button-box-flat {
				margin-top: 0;
				margin-bottom: 0;

				background-color: $domain-1968_box-h2-color-4;
			}
		}

	}

	// 8. patro - hrdinove
	.region-wrapper--level-07 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-4;
		}

		// box
		.box {
			@include breakpoint($breakpoint_desktop) {
				max-width: 800px;

				margin-right: auto;
				margin-left: auto;
			}

			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-2;
				a {
					@include link-style ($domain-1968_box-h2-color-2, none, $domain-1968_box-h2-color-2, none);
				}
			}

			ul li h3 {
				text-align: center;
			}
		}
	}

	// 9. patro -jak jsme zili
	.region-wrapper--level-08 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-1;
		}

		// box
		.box {
			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-2;
				a {
					@include link-style ($domain-1968_box-h2-color-2, none, $domain-1968_box-h2-color-2, none);
				}
			}
		}
	}

	// 10. patro - twitter
	.region-wrapper--level-09 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-2;
			z-index: -2;
		}

		// bg obrazek
		&::after {
			@include breakpoint($breakpoint_desktop) {
				content: "";

				width: 100vw;
				height: 100vh;
				min-height: 1000px;

				position: absolute;
				top: 0;
				left: -40px; // .main padding-left

				background-image: $domain-1968_region-wrapper-background-image-1;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: cover;
				//background-size: 100%;
				background-attachment: fixed;

				z-index: -1;
			}

			// HACK: nez se poprve pohne desktop grid
			//@media screen and (min-width: $domain-1968_page_max-width) {
			@media screen and (min-width: $layout_page_max-width) {
				left: calc(((100vw - #{$domain-1968_page_max-width}) / 2) * -1);
			}
		}

		// box
		.box {
			text-align: center;

			@include breakpoint($breakpoint_desktop) {
				max-width: 620px;

				margin-right: auto;
				margin-left: auto;
			}

			> h2 {
				color: $domain-1968_box-h2-color-2;
				a {
					@include link-style ($domain-1968_box-h2-color-2, none, $domain-1968_box-h2-color-2, none);
				}

				@include breakpoint($breakpoint_desktop) {
					color: $domain-1968_box-h2-color-1;
					a {
						@include link-style ($domain-1968_box-h2-color-1, none, $domain-1968_box-h2-color-1, none);
					}
				}
			}
		}
	}

	// 11. patro - kdo nam vladl
	.region-wrapper--level-10 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-5;
		}

		// box
		.box {
			@include breakpoint($breakpoint_desktop) {
				max-width: 800px;

				margin-right: auto;
				margin-left: auto;
			}

			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-1;
				a {
					@include link-style ($domain-1968_box-h2-color-1, none, $domain-1968_box-h2-color-1, none);
				}
			}

			ul li h3 {
				text-align: center;
			}
		}
	}

	// 12. patro - vyrocni rozhlasova hra (audioplayer)
	.region-wrapper--level-11 {
		max-width: 620px;
		margin-left: auto;
		margin-right: auto;
		// audio player fix
		.b-041k .b-041k__metadata {
			max-width: 100%;
		}
		.file-audio .sm2-playlist-wrapper {
			display: none;
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-1;
		}
	}

	// 14. patro - mapa
	.region-wrapper--level-13 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-2;
		}

		// box
		.box {

			@include breakpoint($breakpoint_desktop) {
				//max-width: 800px;

				margin-right: auto;
				margin-left: auto;
			}

			> h2,
			h3,
			p {
				color: $domain-1968_box-h2-color-2;
				a {
					@include link-style ($domain-1968_box-h2-color-2, none, $domain-1968_box-h2-color-2, none);
				}
			}
		}
	}

	// 15. patro - O projektu
	.region-wrapper--level-14 {
		#oprojektu {
			max-width: 680px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1em;
		}
	}

	// 16. patro - partneri
	.region-wrapper--level-15 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-1;
		}
	}

	// 14. patro - mapa
	.region-wrapper--level-13 {
		@include breakpoint($breakpoint_desktop) {
		}

		// nekonecny pruh
		&::before {
			background-color: $domain-1968_region-wrapper-background-color-2;
		}

		#map_wrapper.box {
			h2 {
				margin-bottom: 1.5em;
				padding: 0;
				text-align: center;
			}

		}
	}

	// footer
	.b-017 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
	}




}
