//
// B-033c Styles
//



// vizualni debug - plab only
// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
.patternlab .b-033c {
	
	max-width: 800px;
}
	
	.b-033c__list {
	}
		
		.b-033c__list-item {
		}

