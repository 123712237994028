//
// Styles - Node Type Atlas
//



.node-type-atlas {

	// grid
	// TODO: zmenit z .item-special na .nth-of-type--special
	.l-grid--12-1.nth-of-type--special {
	}


	// title
	// TODO: v sablone zanorit do .l-grid--12-1.nth-of-type--1 (obrazkovy) a .l-grid--8-1.nth-of-type--1 (standardni/audio) a prepsat styly
	/*h1.article-type--obojzivelnici,
	h1.article-type--psi,
	h1.article-type--ptaci,
	h1.article-type--rostliny,
	h1.article-type--savci,*/
	h1.article-type {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		margin-bottom: 0.5em;

		line-height: 1.8rem;

		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			padding-right: 25px; // viz .l-grid--8-1

			line-height: 2.5rem; // 3rem
		}
	}



	// grid
	.l-grid--12-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--12-1 {
		}


	// grid
	.l-grid--8-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--8-1 {

			.file-audio {
				margin-left: 0;
			}

			// article date
			.field-modified {
				display: none; // nechceme
			}

			// show - v totmo pripade znasilneno na tridu a celed (nebo vedecke zarazeni)
			.field-entity-show {
				display: inline-block;

				//width: calc(100% - 2.75em - 1.5em); // kvuli soc. buttonu na mobilu

				margin-bottom: 12px;

				color: $color_text_paragraph;

				a {
					@include link-style($color_decoration_main, none, $color_decoration_main, underline);
				}

				@include breakpoint($breakpoint_desktop) {
					display: inline-block;

					width: auto;

					//padding-left: 0.625em;
					margin-bottom: 16px;

					line-height: 1em;

					//border-left: 1px solid $color_background-4;
				}

				// label and separator
				.category-label,
				.category-separator {
					font-size: 13px;
				}
			}
		}


		// content-container
		.content-container {
			position: relative; // kvuli absolutnimu pozicobvani vertikalnich soc. buttonu
		}

			// vertical soc. buttons
			.content-1--1-1 {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;

					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: calc(100% - #{$node_left-margin});
				}
			}

			// perex, body
			.content-1--7-2 {
			}


		// main audio anchor, authors, bottom soc. buttons
		.content-2--8-1 {
		}

			// fotka autora - fallback
			.view-display-id-node_author_main.box {
				display: none; // opusteno
			}

			// jmeno autora - fallback
			.view-display-id-node_author_main_2.box {
				display: none; // opusteno
			}

			// expired audio
			.article-type--audio .audio-rights-expired {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}


	// grid - aside
	.l-grid--4-9.nth-of-type--1 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 8em;
		}
	}

		// content boxes, ad
		.content-1--4-9 {
		}


	// grid
	.l-grid--12-1.nth-of-type--2 {
	}

		// iRozhlas
		.content-2--12-1 {
		}


}



// atlas rostlin
.page.atlas-type--rostliny {

	// primary image/audio
	.content-1--8-1 {

		.field-image-primary.no-main-audio {
			border: 6px solid $color_background-4;
			border-radius: 6px;

			width: 100%;

			margin: 0;
			//padding-top: 100%; // 1:1 Aspect Ratio
			padding-top: 66.66%; // 3:2 Aspect Ratio
			//padding-top: 75%; // 4:3 Aspect Ratio
			//padding-top: 62.5%; // 8:5 Aspect Ratio
			//padding-top: 56.25%; // 16:9 Aspect Ratio

			position: relative;

			@include breakpoint($breakpoint_desktop) {
				border: 8px solid $color_background-4;
				border-radius: 8px;

				padding-top: 56.25%; // 16:9 Aspect Ratio
			}

			img {
				display: block;
				margin: auto;

				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				width: auto;
				height: 100%;
			}
		}
	}
}
