//
// Broadcast timespan with progress bar atom
//
// konstrukce casu a teplomeru
//



.broadcast-timespan-progress {
	
	display: flex;
	flex-direction: row;
}
	
	.broadcast-timespan-progress__time {
		
		flex-grow: 0;
		
		margin-right: 0.75em;
		
		@include typography_broadcast_time();
	}
