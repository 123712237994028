//
// body
//



body {
	@include typography_font(regular);
	letter-spacing: $letter-spacing;
	font-size: $base-font-size;
}
