//
// B-080 Styles
//
// krome dlasich variant b008 blizka podobnost s nejakymi variantami b004
// ale primo odvozovano z nich neni
//



.b-080 {
}

	// datepicker
	.b-080 .datepicker {}

	// list
	.b-080__list {
	}

		// item
		.b-080__list-item {
			padding: 1.5em 1em;
			border-bottom: 1px solid $color_divider;

			@include breakpoint($breakpoint_desktop) {
			}
		}

		// item: prvni
		.b-080__list-item:first-child {
			padding-top: 0;
		}

		// item: posledni
		.b-080__list-item:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}

			// block
			.b-080__block {
			}

			// block: heading
			.b-080__block--heading {
				//margin-bottom: 0.25em;
				margin-bottom: 0.5em;
			}

				// button: wrapper
				.b-080__button-play-wrapper {
					margin-bottom: 1em;
				}

					// button: play
					.b-080__button-play-wrapper .button-play {
						width: 1.25em;
						height: 1.25em;
					}

					// button: play hover
					.b-080__button-play-wrapper .button-play:hover {
						//text-decoration: none;
					}

						// button: play label
						.b-080__button-play-wrapper .button-play .button__label {
							display: flex;
						}

							// button: play label-meta
							.button__label-meta {
								white-space: nowrap;
							}

								// button: play label-date
								.b-080__button-play-wrapper .button-play .button__label .button__label-date {
									@include typography_font(medium);
									font-size: 19px;
									line-height: 1.3;
								}

								// button: play label-lenght
								.b-080__button-play-wrapper .button-play .button__label .button__label-lenght {
									@include typography_font(regular);
									font-size: 13px;
									line-height: 1.3;
								}


				// h3: heading
				.b-080__block--heading > h3 {
					margin: 0;

					/*@include typography_font(regular);
					font-size: 16px;
					line-height: 1.3;*/
				}


			// block: context
			.b-080__block--context {
			}

				// context list
				.b-080__context-list {
				}

					// context item
					.b-080__context-list-item {
						padding: 0.25em 0;
					}

						// tag
						.b-080__context-list-item .tag {
							display: inline;
							float: left;

							padding: 0; // reset default value
							margin-right: 0.25em;

							@include typography_font(regular);
							font-size: 16px;
							line-height: 1.3;

							background-color: transparent; // reset default value
							color: $color_text_accent;

							span {
								display: inline;
								color: $color_text_accent;
							}

							a {
								display: inline;
								@include link-style($color_text_link, none, $color_text_link, underline);
							}

						}

						// h3: context
						.b-080__context-list-item > h3 {
							margin: 0;

							@include typography_font(regular);
							font-size: 16px;
							line-height: 1.3;

							color: $color_background-6;

							a {
								@include link-style($color_background-6, none, $color_background-6, underline);
							}
						}
