//
// Program - Program
//



.program.page-program {

	.main {
		margin-top: 0;
	}

	.b-013c {
		max-width: 755px;
		margin: auto;

		.b-013__subsection {
			background-color: $color_background-2 !important; // prebiji tridu .bg-color-main, proto !important

			.b-013__block--second > h3 a {
				@include link-style($color_text_accent, none, $color_text_accent, underline);
			}

			.b-013__block--second > a {
				@include link-style($color_background-5, none, $color_background-5, underline);
			}

			// readmore
			.b-013__block--second .button-box-flat--readmore {
				background-color: $color_background-0;
				border-color: $color_background-4;

				@include link-style($color_decoration_main, none, $color_decoration_main, underline);
			}

				.b-013__block--second .button-box-flat--readmore .button-box-flat__label {
					border-right-color: $color_background-4;
				}


			.b-013__block--second .button-box-flat--readmore .button-box-flat__icon--arrow-double-east {
				background-image: $file_icon_arrow_double_east_colored-decoration-main;
			}

		}
	}

}
