//
// B-010 Styles
//



.b-010 {
	
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
		max-width: 500px;
	}
}
	
	.b-010__list {
	}
		
		.b-010__list-item {
			
			padding: 1em;
			
			&:nth-child(2n+1) {
				background: $color_background-2;
			}
			
			&:nth-child(2n) {
				background: $color_background-4;
			}
		}
			
			// play button obal
			.b-010__item-button-wrapper {
				float: left;
				margin-right: 0.5em;
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-010__item-button-wrapper .button-play
				{
					width: 1.25em;
					height: 1.25em;
				}
			
			
			// title link obal
			.b-010__item-title {
			}
				
				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-010__item-title h3 {
					
					margin: 0;
				}
			
			
			.b-010__item-datetime {
				
				clear: left;
				
				display: inline;
				margin-right: 0.25em;
				
				@include adjust-font-size-to($fontsize_box_petit_main);
				
				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_box_petit_main_desktop);
				}
			}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-010__list-item p {
				
				display: inline;
				margin: 0.25em 0 0 0;
				
				color: $color_text_paragraph;
			}