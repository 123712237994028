//
// B-112 styles
//


// vars
$b-112_img_width: 60px;
$b-112_padding: 15px;
$b-112_custom_font_size: 1.03rem;


.b-112 {
}

	.b-112 .b-112__section-block {
		padding: $b-112_padding/2;
		display: flex;
		flex-wrap: wrap;
		background-color: $color_background-2;
	}

	.b-112 .b-112__link {
		display: flex;
	}

	.b-112 .b-112__link:hover {
		text-decoration: none;
	}

	.b-112 .b-112__link:hover .b-112__right p {
		text-decoration: underline;
	}

	.b-112 .b-112__left {
		max-width: $b-112_img_width;
		flex-basis: $b-112_img_width;
		margin-right: $b-112_padding;
	}

	.b-112 .b-112__right {
		flex-basis: calc(100% - #{$b-112_img_width} - #{$b-112_padding});
	}

	.b-112 .b-112__player {
		margin-top: $b-112_padding/2;
		flex-basis: 100%;
		background-color: $color_background-0;
	}

		.b-112 .b-112__left > img {
			min-width: $b-112_img_width;
			display: block;
		}

		.b-112 .b-112__right > h3 {
			margin-top: 10px;
			margin-bottom: 0;
			padding-left: 0;
			font-size: .85rem;
			text-transform: none;
			color: $color_background-6;
			@include typography_font(light);
		}

		.b-112 .b-112__right > p {
			margin-top: 2px;
			margin-bottom: 2px;
			font-size: $b-112_custom_font_size;
			color: $color_text_accent;
		}
