.quiz-wrapper-d {
	position: relative;
	clear: both;
	width: 100%;
	max-width:620px;
	min-height: 32px;
	margin-bottom: 10px;
	overflow: hidden;
	padding: 20px;
	background: $color_background-2 url("https://aplikace.rozhlas.cz/kvizy/sites/all/libraries/cro_kvizy/r-loader.gif") 50% 50% no-repeat;
	color: $color_background-6;
}

.quiz-wrapper-d {

	&.loaded {
		background: $color_background-2
	}

	.quiz {
		position: relative;
		clear: both;
		width: inherit
	}

	.quiz-frame {
		position: relative;
		width: inherit;
		background-color: $color_background-2;
	}

	.quiz-frame .perex {
		float: left
	}

	.quiz-frame .front-image {
		float: left
	}

	.quiz-frame .text {
		float: left;
		width: 310px;
		margin-left: 20px
	}

	.quiz-frame h2 {
		margin: 0;
		line-height: 1.4;
		color: $color_background-9;
	}

	.quiz-frame p {
		margin-bottom: 0
	}

	.quiz-frame .button {
		float: right;
		margin-right: 0;
		padding: 4px 11px;
		cursor: pointer;
		color: #fff;
		border: 0 none transparent;
		background: $color_corporate_add-2;
		box-shadow: none;
		text-shadow: none
	}

	.test-share {
		float: left;
	}

	.quiz-frame .button.results {
		background: $color_decoration_success;
	}

	.quiz-frame .button.retry {
		color: $color_corporate_add-2;
		border: 1px solid $color_divider;
		background: #fff;
		width: 150px;
		display: block;
		float: right;
		text-align: center;
	}

	.quiz-frame .button.start {
		display: inline-block;
		float: none;
		padding: 10px 30px;
		background: $color_corporate_add-2;
	}

	.quiz-frame .button.results:hover,
	.quiz-frame .button.retry:hover,
	.quiz-frame .button:hover {
		color: #fff;
		background: #999
	}

	.quiz-frame .button.disabled {
		display: none;
		cursor: default
	}

	.quiz-frame .button.disabled:hover {
		opacity: .6
	}

	.quiz-frame .audio {
		background: transparent
	}

	.quiz-frame .progress {
		font-size: 20px;
		@include typography_font(regular);
		line-height: 23px;
		float: left;
		color: #00305b
	}

	.quiz-frame .progress span {
		display: inline-block
	}

	.quiz-frame .progress .progress-text {
		font-size: 16px;
		@include typography_font(regular);
		margin-right: 10px;
		color: $color_background-6;
	}

	.quiz-bottom {
		float: left;
		clear: both;
		width: 100%;
		margin-top: 20px;
		padding-top: 18px;
		border-top: 1px dotted #ccc
	}

	.button-center {
		width: 100%;
		text-align: center
	}

	.answers {
		float: left;
		clear: left;
		width: 100%;
		min-height: 80px
	}

	.result {
		min-height: 150px
	}

	.question h2 {
		float: left
	}

	.question .title-text {
		float: left;
		margin: 0 0 15px
	}

	.progressbar-wrapper {
		width: 115px;
		height: 8px;
		border: 1px #999 solid;
		background-color: #fff;
		margin-left: 10px;
		zoom: 0.8;
	}

	.progressbar {
		float: left;
		width: 0;
		height: 8px;
		background-color: #999
	}

	.answer {
		float: left;
		clear: left;
		min-height: 26px;
		margin-bottom: 5px;
		cursor: pointer
	}

	.answer.vertical {
		clear: none;
		width: auto;
		text-align: center
	}

	.answer.vertical input {
		float: none;
		clear: none;
		margin: 19px 13px 19px 0
	}

	.answer.vertical .answer-text {
		float: none;
		clear: left
	}

	.answer.vertical .answer-result {
		margin: -35px 0
	}

	.answer input {
		margin-top: 6px
	}

	.answer-text,
	.answer-result,
	.answer input {
		font-size: 18px;
		line-height: 24px;
		float: left;
		margin-right: 10px;
		color: $color_text_paragraph;
	}

	.answer-text {
		max-width: 500px
	}

	.answer-result {
		width: 26px;
		height: 26px;
		margin: 0 0 0 10px;
		padding-left: 0;
		background: transparent url("https://aplikace.rozhlas.cz/kvizy/sites/all/libraries/cro_kvizy/quiz_smiley_green.png") 0 0 no-repeat
	}

	.answer img {
		border: 3px #fff solid;
		border-radius: 3px;
		float: left;
		clear: none
	}

	.answer img:hover {
		border: 3px #999 solid
	}

	.answer.correct {
		color: #008200
	}

	.answer.correct img {
		border: 3px #71afc4 solid
	}

	.answer.incorrect {
		color: #cf1a1a
	}

	.answer.disabled {
		cursor: default
	}

	.answer.disabled img:hover {
		border: 3px #fff solid
	}

	.answer.disabled.correct img:hover {
		border: 3px #71afc4 solid
	}

	.answer.disabled.incorrect img {
		border: 3px #ed165a solid
	}

	.answer.disabled.incorrect img:hover {
		border: 3px #ed165a solid
	}

	.correct .answer-text,
	.incorrect .answer-text {
		font-weight: bold
	}

	.incorrect .answer-result {
		background: transparent url("https://aplikace.rozhlas.cz/kvizy/sites/all/libraries/cro_kvizy/quiz_smiley_red.png") 0 0 no-repeat
	}

	.quiz-face {
		position: absolute;
		z-index: 10000;
		right: 0;
		width: 100px;
		height: 100px;
		opacity: .7;
		background: transparent
	}

	.answer-hint-wrapper {
		display: block;
		float: left;
		clear: left;
		margin-top: 20px;
		padding-top: 7px;
		background: transparent url("https://aplikace.rozhlas.cz/kvizy/sites/all/libraries/cro_kvizy/bubble_form_arrow_white_up.png") 30px 0 no-repeat
	}

	.answer-hint {
		font-size: 12px;
		float: left;
		padding: 10px;
		color: #000;
		border-radius: 3px;
		background: #fff
	}

	.loader {
		width: 100%;
		height: 32px;
		background: transparent url("https://aplikace.rozhlas.cz/kvizy/sites/all/libraries/cro_kvizy/r-loader.gif") 50% 50% no-repeat
	}

	.question-image {
		float: left;
		clear: left;
		margin: 0 0 15px
	}

	.countdown {
		font-size: 16px;
		display: block;
		float: right;
		width: 90px;
		height: 90px;
		color: #036;
		-webkit-border-radius: 100px;
		border-radius: 100px;
		background-color: #ca003a
	}

	.countdown input[type='text'] {
		color: #036;
		box-shadow: none
	}

	.quiz-fb-button {
		line-height: 22px;
		background: transparent url('https://aplikace.rozhlas.cz/kvizy/sites/all/libraries/cro_kvizy/socico_22_fb.png') no-repeat 0 50%;
		height: 22px;
		display: block;
		padding-left: 29px;
		margin-bottom: 15px;
		font-size: 15px;
		float: left;
		clear: left
	}

	.quiz-fb-button:hover {
		background-image: url('https://aplikace.rozhlas.cz/kvizy/sites/all/libraries/cro_kvizy/socico_22_fb_over.png')
	}

	.quiz-charts-wrapper {
		margin-bottom: 30px;
	}

	.quiz-charts-row {
		margin-bottom: 10px;
		font-size: 17px;
	}

	.quiz-charts-row > td {
		padding-bottom: 12px;
	}


	.quiz-charts-row .quiz-charts-percentage {
		width: 20%;
	}
	.quiz-charts-row .quiz-charts-vizu {
		width: 30%;
	}

	.quiz-charts-title {
		padding-right: 20px;
		line-height: 1.4;
	}

	.quiz-charts-vizu div {
		height: 20px;
		background-color: $color_corporate_add-2;
	}

	.quiz-chart-title {
		padding:0 !important;
		margin: 0 !important;
		margin-bottom: 10px !important;
	}


	.result-text p {
		padding-top: 0 !important;
		margin-top: 0 !important;
	}

	.quiz-charts-percentage {
		text-align: right;
	}

	@include breakpoint($breakpoint_tablet) {

		.progressbar-wrapper {
			margin-left: 30px;
			zoom: 1;
		}


	}
}
