//
// Svitis - HP
//



.svitis.page-svitis-hp {

	.b-004f {

		// odrazeni nove sady po nacteni infinity srcoll buttonem
		.b-004f__list:not(:first-of-type) {

			@include breakpoint($breakpoint_desktop) {
				margin-top: 2em;
			}
		}
	}
}
