//
// Zlin - HP
//



.zlin.page-zlin-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}