//
// B-008a Styles
//
// blizka podobnost s boxem b008e - zahodno zvazit zda zmeny nezanaset spolecne
// protoze ale dalsi varianty b008 tyto podobnosti nesdili, doslo u b008 k specifickemu roztristeni stylopisu
//



.b-008a {
}

	.b-008a__list {

		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}
	}

		.b-008a__list-item {

			margin-bottom: 2em;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 1.5em;
				margin-bottom: 0;
			}
		}
		.b-008a__list-item:last-child {

			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			.b-008a__block--image {
			}

				.b-008a__image {
					position: relative;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008a__block--image .tag {
					position: absolute;
					bottom: 0;
					left: 10px;

					max-inline-size: calc(100% - 20px);
				}


			.b-008a__block--description {
				display: flex;
				flex-direction: row;
			}

				.b-008a__subblock {

					flex-grow:1;

					padding-top: 0.75em;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008a__block--description .caption--available-till {

						margin-bottom: 0.5em; // presunuto z margin-top z elemntu niz
						margin-right: 0.75em;
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-008a__block--description h3 {

						margin-top: 0; // hodnota 0.5em presunuta do margin-bottom elementu nad nim .caption--available-till, protoze pokud neni, nechceme margin
						margin-bottom: 0.25em;

						padding-right: 0.75em;
					}


				.b-008a__button-play-wrapper-pipe {

					margin-top: 0.75em;
					padding-left: 0.75em;

					border-left: 1px solid $color_divider;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-008a__button-play-wrapper-pipe .button-play {
					//width: 3em;
					//height: 3em;
				}



// varianta kdy itemy jsou jednotlive boxy, viz RZ hp
.b-008a__wrapper {
	display: flex;
	flex-direction: column;

	@include breakpoint($breakpoint_desktop) {
		flex-direction: row;
		flex-wrap: wrap;
	}

	> h2 {
		flex-basis: 100%;
	}

	.b-008a {
		margin-top: 0;
		margin-bottom: 2em;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 0;

			// trik jak 4 itemy radku + jejich margin roztahnout na 100%
			// ale ne pokud jich neni na radek plny pocet
			flex-basis: 20%; // toto neni ctvrtina, ale vychozi velikost ktera se roztahne
			flex-grow: 1; // roztahne se pomoci grow do tretin
			max-width: 24%; // (fallback pro no-calc pro nasledujici radek)
			max-width: calc(25% - 1.125em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
			// proc - 1.125em? protoze je to 1.5 * 3 / 4 viz (*)

			margin-right: 1.5em; // (*)
		}

		.b-008a__list {
			display: block; // ma jen jeden item, tak netreba flexovat, navic vadi ie11
		}
	}

	.b-008a:last-child {
		margin-bottom: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-right: 0;
		}
	}
}


// varianta se 2 polozkami
.l-grid--8-1 {
	.b-008a {
		.b-008a__list {
			.b-008a__list-item {
				@include breakpoint($breakpoint_desktop) {
					width: 50%;
				}
			}
		}
	}
}

// varianta se 4 polozkami
.l-grid--12-1 {
	.b-008a {
		.b-008a__list {
			.b-008a__list-item {
				@include breakpoint($breakpoint_desktop) {
					width: 25%;
				}
			}
		}
	}
}

// varianta se 1 polozkou, resp napr. varinat na hp RZ, 4x ul s 1x li
.l-grid--12-1 {
	.b-008a {
		.b-008a__list {
			.b-008a__list-item:first-child:last-child {
				@include breakpoint($breakpoint_desktop) {
					width: 100%;
				}
			}
		}
	}
}
