//
// Celorozhlasovy program
//


.program-container {
	position: relative;
	margin-top: 2em;
	@include breakpoint($breakpoint_desktop) {
		margin-top: 3em;
	}
	.header {
		display: flex;
		justify-content: center;
		color: $color_background-6;
		margin: 10px 0;
		margin: 0 auto 18px auto;
		position: relative;
		&__wrap {
			display: flex;
			position: relative;
		}
		&__print {
			position: absolute;
			right: 10px;
			top: 15px;
			display: none;
			color: $color_background-5;
			font-size: .75rem;
			transition: color 100ms ease-in-out;
			@include no-select;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
			}
			&:after {
				content: '';
				background: $file_button_action_print no-repeat;
				width: 24px;
				height: 24px;
				background-size: 24px 24px;
				display: inline-block;
				margin-left: 7px;
			}
			&:hover {
				color: darken($color_background-5, 25%);
			}
		}
		&__arrow-left {
			background: $file_icon_arrow_single_east no-repeat center transparent;
			background-size: 10px 10px;
			position: absolute;
			top: 23px;
			left: 0;
			width: 30px;
			height: 30px;
			cursor: pointer;
			transform: rotate(180deg);
			@include breakpoint($breakpoint_tablet) {
				display: none;
			}
			&--large-screen {
				display: none;
				@include breakpoint($breakpoint_tablet) {
					display: flex;
					left: -40px;
					transition: opacity 250ms ease-in-out;
					opacity: .6;
					z-index: 1;
				}
				&:hover {
					opacity: 1;
				}
			}
		}
		&__arrow-right {
			background: $file_icon_arrow_single_east no-repeat center transparent;
			background-size: 10px 10px;
			position: absolute;
			top: 23px;
			right: 0;
			width: 30px;
			height: 30px;
			cursor: pointer;
			@include breakpoint($breakpoint_tablet) {
				display: none;
			}
			&--large-screen {
				display: none;
				@include breakpoint($breakpoint_tablet) {
					display: flex;
					right: -40px;
					transition: opacity 250ms ease-in-out;
					opacity: .6;
					z-index: 1;
				}
				&:hover {
					opacity: 1;
				}
			}
		}
		&__item {
			text-align: center;
			flex-direction: column;
			text-decoration: none;
			&:nth-child(2),
			&:nth-child(6) {
				display: none;
				@include breakpoint($breakpoint_tablet) {
					display: flex;
				}
			}
			&:hover .header__day-name {
				text-decoration: underline;
			}
		}
		&__day-name {
			color: $color_background-5;
			font-size: .75rem;
		}
		&__day-date {
			color: $color_decoration_main;
			font-size: 1.2rem;
			@include typography_font(bold);
			word-spacing: -2px;
			padding: 5px 12px;
			&:hover {
				color: darken($color_decoration_main, 12%);
			}
			@include breakpoint($breakpoint_tablet) {
				font-size: 1.3rem;
				padding: 5px 20px;
			}
			&--actual {
				border: 1px solid $color_divider;
				color: $color_background-9 !important;
				padding: 5px 8px 5px 10px;
				display: flex;
				align-items: center;
				&:after {
					content: '';
					background: $file_icon_calendar_colored-black no-repeat 10px;
					background-size: 20px 20px;
					width: 30px;
					height: 20px;
					display: inline-block;
				}
			}
		}
	}
	.subheader {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 25px;
		&__item {
			margin: 0 10px;
			text-align: center;
			position: relative;
			color: $color_decoration_main;
			font-size: .92rem;
			&:hover {
				color: darken($color_decoration_main, 12%);
			}
			&:nth-child(1) {
				margin-left: 0;
			}
			&:nth-child(4) {
				margin-right: 0;
			}
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				&:after {
					content: '';
					display: inline-block;
					background-color: darken($color_background-4, 10%);
					position: absolute;
					width: 7px;
					height: 1px;
					bottom: 11px;
					right: -13px;
				}
			}
		}
	}
	.station-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		align-self: center;
		font-size: .8rem;
		margin: 0;
		cursor: default;
		@include no-select;
		@include breakpoint($breakpoint_tablet) {
			align-items: flex-end;
			//margin: -44px 0 0 0;
		}
		@include breakpoint($breakpoint_desktop) {
			//margin: -44px 0 -20px 0;
		}
		&__formation {
			&--print {
				display: none;
				@include breakpoint($breakpoint_tablet) {
					display: flex;
				}
			}
		}
		&__print {
			@include breakpoint($breakpoint_tablet) {
				display: flex;
				align-items: center;
				padding-bottom: 0.5em;
				border-bottom: 4px solid $color_background-7;
				&::after {
					content: "";
					display: inline-block;
					background-image: $file_icon_print_colored-555561;
					background-repeat: no-repeat;
					background-position: center;
					background-size: auto 21px;
					margin-left: 0.5em;
					width: 28px;
					height: 26px;
				}
				&--eco {
					margin-left: 2em;
					border-color: #76ba00;
					&::after {
						background-image: $file_icon_print-eco_colored-555561;
						background-size: auto 24px;
						width: 50px;
					}
				}
				span {
					color: $color_text_paragraph;
					font-size: 1em;
				}
			}
		}
		&__previous {
			display: flex;
			background: $color_background-3;
			height: 24px;
			z-index: 0;
		}
		&__previous-arrow {
			display: flex;
			flex-basis: 40px;
			background: $file_icon_arrow_single_east_colored-white no-repeat center transparent;
			background-size: 8px 8px;
			width: 24px;
			height: 24px;
			transform: rotate(180deg);
			@include breakpoint($breakpoint_tablet) {
				width: 20px;
				margin-right: -10px;
			}
		}
		&__toggle {
			display: flex;
			align-items: center;
			padding: 0 0 0 10px;
			margin: 0 10px;
			color: $color_background-0;
			background-color: $color_background-5;
			text-decoration: none;
			text-transform: lowercase;
			text-align: center;
			font-size: .85rem;
			cursor: pointer;
			transition: background-color 120ms ease-in-out;
			@include breakpoint($breakpoint_tablet) {
				display: none;
			}
			&:first-letter {
				text-transform: uppercase;
			}
			&:after {
				content: '';
				background: $file_icon_arrow_single_east_colored-white no-repeat center transparent;
				background-size: 8px 8px;
				display: inline-block;
				width: 13px;
				height: 8px;
				padding-left: 16px;
				transform: rotate(90deg);
			}
			&:hover {
				background-color: darken($color_background-5, 20%);
			}
		}
		&__stations-hidden {
			display: none;
			width: 200px;
			height: 100px;
			color: white;
			text-align: center;
			text-transform: capitalize;
		}
		&__next {
			display: flex;
			text-align: right;
			justify-content: flex-end;
			background: $color_background-3;
			height: 24px;
			z-index: 0;
			&:hover {
				text-decoration: none;
				cursor: default;
			}
		}
		&__next-arrow {
			flex-basis: 40px;
			background: $file_icon_arrow_single_east_colored-white no-repeat center transparent;
			background-size: 8px 8px;
			width: 24px;
			height: 24px;
			@include breakpoint($breakpoint_tablet) {
				width: 20px;
				margin-left: -10px;
			}
		}
		&__link {
			line-height: 2;
			color: $color_background-0;
			display: none;
			white-space: nowrap;
			padding: 0 10px;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
	// mobile menu is opened, close icon shows
	input[type=checkbox]:checked + .station-button__toggle {
		&:after {
			background: $file_icon_close_colored-white no-repeat center transparent;
		}
	}
	.active {
		background-color: $color_background-5;
		cursor: pointer !important;
		transition: background-color 120ms ease-in-out;
		&:hover {
			background-color: darken($color_background-5, 20%);
			text-decoration: none;
		}
	}
	.today-date-print-only {
		display: none;
	}
	.main {
		padding: 0;
		max-width: 100%;
		&__block {
			display: flex;
			margin-top: 20px;
			margin-bottom: 20px;
			@include breakpoint($breakpoint_tablet) {
				margin-top: 50px;
				margin-bottom: 50px;
			}
			@include breakpoint($breakpoint_desktop) {
				margin-top: 70px;
				margin-bottom: 70px;
			}
		}
		&__block-info {
			color: $color_background-0;
			background-color: $color_background-5;
			font-size: .75rem;
			position: relative;
			top: 69px;
			display: inline-block;
			height: 20px;
			line-height: 1.5;
			padding: 0 4px 0 6px;
			@include no-select;
			@include breakpoint($breakpoint_tablet) {
				top: 78px;
			}
			@include breakpoint($breakpoint_desktop) {
				top: 97px;
			}
			&:after {
				content: '';
				position: absolute;
				right: -10px;
				top: 0;
				width: 0;
				height: 0;
				border-bottom: 20px solid $color_background-5;
				border-right: 10px solid transparent;
			}
		}
		&__station {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			flex-shrink: 0;
			@include breakpoint($breakpoint_tablet) {
				margin-right: 20px;
				width: calc(33% - 10px);
			}
			@include breakpoint($breakpoint_desktop) {
				width: calc(25% - 15px);
			}
			&--regional-menu {
				display: none;
				@include breakpoint($breakpoint_tablet) {
					display: flex;
				}
				.main__station-name {
					background-color: $color_background-3;
					color: $color_background-6;
					text-transform: lowercase;
					font-size: .85rem;
					@include typography_font(medium);
					&:first-letter {
						text-transform: uppercase;
					}
				}
				.toggle-station {
					color: $color_background-0;
					cursor: pointer;
					background: $file_logo_R_white no-repeat 20px center $color_region_main;
					background-size: 24px 24px;
					line-height: 1.2;
					position: relative;
					@include no-select;
					&:hover {
						background-color: darken($color_region_main, 4%);
					}
					&:before {
						content: '';
						background-color: $color_background-0;
						display: inline-block;
						width: 100%;
						height: 1px;
						opacity: .5;
						position: absolute;
						bottom: 0;
					}
					&:after {
						content: '';
						background-color: $color_background-0;
						border: 1px solid $color_background-5;
						width: 12px;
						height: 12px;
						display: inline-block;
						position: absolute;
						top: 17px;
						right: 20px;
					}
					&__wrap {
						padding: 12px 40px 12px 60px;
						position: relative;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						&:before {
							content: '';
							opacity: 0;
							position: absolute;
							top: 23px;
							right: 27px;
							width: 7px;
							height: 2px;
							background-color: $color_background-0;
							transform: rotate(45deg);
							z-index: 1;
							transition: opacity 250ms ease-in-out;
						}
						&:after {
							content: '';
							opacity: 0;
							position: absolute;
							top: 21px;
							right: 18px;
							width: 13px;
							height: 2px;
							background-color: $color_background-0;
							transform: rotate(-45deg);
							z-index: 1;
							transition: opacity 250ms ease-in-out;
						}
					}
					&__station-name {
						color: $color_background-0;
						width: calc(100% - 36px);
						text-transform: initial;
						line-height: 1.6;
						@include typography_font(medium);
						font-size: .9rem;
						&:after {
							content: '';
							display: table;
							clear: both;
						}
					}
					&__station-now-playing {
						display: none;
						width: calc(100% - 36px);
						text-transform: initial;
						@include typography_font(regular);
						font-size: .75rem;
					}
				}
				// checkbox hack
				input[type=checkbox]:checked + .toggle-station {
					background-color: darken($color_region_main, 10%);
					&:after {
						content: '';
						background-color: transparent;
						border: 0;
						transform: scale(0);
						opacity: 0;
					}
				}
				input[type=checkbox]:checked + .toggle-station .toggle-station__wrap::before,
				input[type=checkbox]:checked + .toggle-station .toggle-station__wrap::after {
					opacity: 1;
				}
			}
		}
		&__station-name {
			padding: 4px 10px 1px 10px;
			margin: -15px 0 26px 0;
			color: $color_background-0;
			@include typography_font(bold);
			background-color: $color_corporate_main;
			text-transform: uppercase;
			font-size: .95rem;
			letter-spacing: .95px;
			@include no-select;
			@include breakpoint($breakpoint_tablet) {
				margin: -36px 0 26px 0;
			}
			&--no-label {
				margin: 14px 0 0 0;
				@include breakpoint($breakpoint_tablet) {
					margin: 10px 0 0 0;
				}
			}
			&--actual {
				margin: 0;
				@include breakpoint($breakpoint_tablet) {
					margin: -36px 0 0 0;
				}
			}
		}
		&__station-program {
			margin: 10px 0 0 0;
			padding: 10px 0;
			position: relative;
			@include breakpoint($breakpoint_tablet) {
				padding: 10px;
			}
			&:before{
				content: '';
				display: inline-block;
				width: 100%;
				height: 1px;
				background: $color_background-3;
				position: absolute;
				top: 0;
				left: 0;
			}
			&--actual {
				background-color: $color_background-2;
				margin: 0 -10px -10px -10px;
				padding: 10px;
				@include breakpoint($breakpoint_tablet) {
					margin: 0 0 -10px 0;
				}
				&:before {
					content: '';
					height: 0;
				}
				&:after {
					content: '';
					display: inline-block;
					width: 100%;
					height: 1px;
					background: $color_background-3;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			&--unavailable {
				color: $color_background-5;
				background: $color_background-2;
				border: 5px solid $color_divider;
				text-align: center;
				display: inline-block;
				padding: 24px 0;
				font-size: .88rem;
			}
		}
		&__program_preview_image {
			margin: -10px -10px 0 -10px;
			width: calc(100% + 20px);
			max-width: inherit;
		}
		&__time {
			margin-left: 1px;
			font-size: .9rem;
			.broadcast-timespan-progress {
				&__time {
					font-size: .9rem;
				}
			}
		}
		&__name {
			font-size: .9rem;
			@include typography_font(medium);
			line-height: 1.55;
		}
		&__countdown {
			position: relative;
			padding-left: 20px;
			font-size: .75rem;
			color: $color_background-6;
			&:before {
				position: absolute;
				content: '';
				width: 14px;
				height: 14px;
				top: 5px;
				left: 0;
				display: inline-block;
				background: $file_icon_timer;
			}
		}
		&__description {
			color: $color_background-6;
			font-size: .9rem;
			line-height: 1.5;
		}
		&__showmore {
			font-size: .8rem;
			color: $color_decoration_main;
		}
		&__buttons {
			display: flex;
			justify-content: space-between;
			font-size: .85rem;
			line-height: 1;
			align-items: flex-end;
			margin-top: 10px;
			margin-bottom: 4px;
			@include breakpoint($breakpoint_desktop) {
				font-size: .88rem;
			}
		}
		&__archive {
			font-size: .8rem;
			color: $color_decoration_main;
			width: auto;
			margin: 8px 0 0 0;
			display: inline-block;
			padding-left: 25px;
			background: $file_icon_badge_audio_colored-main no-repeat;
			background-position: 0 center;
			background-size: 16px 16px;
		}
		.b-111 {
			@include breakpoint($breakpoint_tablet) {
				max-width: 685px;
				margin: 0 auto;
			}
		}
	}
}


// Datepicker styles
.program {
	.program-container {
		.hasDatepicker {
			position: absolute;
		    top: 79px;
		    left: calc(50% - 180px);
		    padding: 30px;
		    margin-top: -30px;
		    z-index: 11;
		}
	}
}


/* layout */
.program.page-program {
	.l-grid--12-1 {
		overflow: visible;
	}
}


// Print styles
@media print {
	.program.page-program {

		/* header & footer */
		header.l-grid--12-1,
		footer.l-grid--12-1 {
			display: none;
		}

		/* page*/
		.page {
			max-width: 100%;
		}

		/* main */
		.main:not(#mainSection) {
			clear: none;
			float: none;
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}

		.program-container {
			margin-top: 0;
		}

		#mainSection {
			display: flex;
			flex-direction: column;
		}

		.do-not-print,
		.header,
		#calendarDiv,
		.subheader,
		.station-button,
		#admin-menu,
		.webeditor-navbar,
		&.admin-menu.adminimal-menu::before,
		.main__block-info,
		.progress-bar,
		.b-011b,
		.b-013c,
    .b-111 {
			display: none !important;
		}
		.main {
			&__block {
				margin-top: 0;
				margin-bottom: 25px;
			}
			&__block::last-child {
				margin-bottom: 0;
			}
			&__station-program,
			&__station-program--actual {
				margin: 0;
				color: $color_background-9 !important;
				background-color: transparent !important;
				&::before,
				&::after {
					display: none;
				}
			}
			&__station-program {
				margin-top: 5px;
				padding: 5px;
				border-top: 1px solid $color_divider; // pseudoelementy tisk ignoruje
			}
			&__station {
				width: calc(25% - 5px) !important;
				margin-right: 5px !important
			}
			&__station:last-child {
				margin-right: 0 !important
			}
			&__station-name {
				margin: 0 !important;
				color: $color_background-9 !important;
				background-color: transparent !important;
				font-size: 14px !important;
				@include typography_font(bold);
			}
			&__description {
				font-size: 10px !important;
				color: $color_background-9 !important;
				background-color: transparent !important;
			}
			&__time,
			&__time .broadcast-timespan-progress__time {
				font-size: 12px !important;
				line-height: 1.3;
				color: $color_background-9 !important;
				background-color: transparent !important;
			}
			&__name {
				font-size: 12px !important;
				color: $color_background-9 !important;
				background-color: transparent !important;
			}
			&__block-info {
				top: -20px !important;
				font-size: 1.6rem !important;
				font-size: 10px !important;
				text-decoration: underline !important;
				&:first-letter {
					text-transform: uppercase !important;
				}
				&:after {
					display: none !important;
				}
			}
		}
		// hides scrollbar when printing
		.main::-webkit-scrollbar {
			width: 0 !important;
		}
	}

	.today-date-print-only {
		/*display: inline-block !important;
		font-size: 1.4rem;
		line-height: 2;
		width: 100%;
		color: $color_background-9;
		&:after {
			content: '';
			display: table;
			clear: both;
		}*/
		display: inline !important;
		margin: 0 auto;
		padding: 5px 10px;
		//background-color: $color_background-9;
		//color: $color_text_accent_inverted;
	}

	.main__archive,
	.main__buttons,
	.main__countdown,
	.main__showmore {
		display: none !important;
	}
}

// Patternlab Only Styles
.patternlab {
	.main {
		&__station-program {
			&--actual {
				margin: 0 0 -10px 0;
			}
		}
		&__station-name--actual + img {
			display: none;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
			}
		}
	}
}

@media print {
	.patternlab {
		.program-container {
			color: $color_background-9 !important;
			.header,
			.subheader,
			.main__block-info,
			.patternlab-only-selfpromo {
				display: none !important;
			}
		}
	}
}
