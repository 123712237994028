//
// B-006c Styles
//



// vizualni debug - plab only
// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
.patternlab .b-006c {
	
	max-width: 800px;
}
	
	.b-006c__list {
	}
		
		// obecny item (vlastnosti v toku vlevo i vpravo)
		.b-006c__list-item {
			
			margin-bottom: 1em;
		}
				
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-006c__list-item h3 {
				
				margin: 0;
				padding-top: 0.5em;
			}
			
			.b-006c__list-item .caption--public-from {
				
				padding-top: 0.5em;
			}
		
		
		.b-006c__list-item:last-child {
			
			margin-bottom: 0;
		}

