//
// Styles - Node Default
//



.page-node,
.b-047, .box.b-047 {

	// messages
	.messages {
		margin-top: $node_messages_margin_mobile;
		margin-bottom: $node_messages_margin_mobile;

		@include breakpoint($breakpoint_desktop) {
			margin-top: $node_messages_margin;
			margin-bottom: $node_messages_margin;
		}
	}

	// content wrapper
	.inner-content-wrapper {
		position: relative;
		overflow: visible; // musi byt kvulio pretekajicimu hlavnimu obrazku na mobilu a soc. buttonu

		// clearfix
		&::after {
			clear: both;
			content: "";
			display: table;
		}
	}



	// content container
	.content-container {

		@include breakpoint($breakpoint_desktop) {
			min-height: 162px; // docasny fix - min. vyska jako absolutne pozicovanych vert. soc. button
		}

	}



		/*
		 * Datum publikace / porad
		 */

		.node-block--date-show {
			display: flex;
			flex-direction: column;

			position: relative; // kvuli soc. buttonu na mobilu

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				flex-wrap: nowrap;

				margin-bottom: 16px;
			}

			// datum publikace
			.node-block__block--date {
				width: calc(100% - 56px - 24px); // kvuli soc. buttonu na mobilu

				color: $color_text_paragraph;

				background-image: $timer-bg;
				background-repeat: no-repeat;
				background-position: left 3px;
				background-size: auto 10px;

				margin-bottom: 8px;
				padding: 0 0 0 1.25em;

				font-size: 12px;
				line-height: 1.25em;

				@include breakpoint($breakpoint_desktop) {
					display: inline-block;

					width: auto;

					background-position: left center;
					background-size: auto 13px;

					padding: 0 0.75em 0 1.25em;
					margin-bottom: 16px;
					margin-bottom: 0;

					font-size: 16px;
				}
			}

			// porad
			.node-block__block--show {
				display: inline-block;

				//width: calc(100% - 56px - 24px); // kvuli soc. buttonu na mobilu // soc. buttony nove nejsou

				color: $color_text_paragraph;

				margin-bottom: 12px;

				font-size: 16px;
				line-height: 1.25em;

				a {
					@include link-style($color_decoration_main, none, $color_decoration_main, underline);
				}

				a,
				span {
					float: left;
				}

				@include breakpoint($breakpoint_desktop) {
					display: inline-block;
					width: auto;

					padding-left: 0.75em;
					margin-bottom: 16px;
					margin-bottom: 0;

					font-size: 16px;

					border-left: 1px solid $color_background-4;
				}
			}

			// soc. buttons top
			.node-block__block--social-buttons-top{
				display: none; // TODO: dat pryc z sablony
			}
		}

		// soc. buttons top OLD - nekde jeste zustalo v sablone
		.block-social-buttons--top {
			display: none; // TODO: dat pryc z sablony
		}



		// perex
	.field-perex {
		@include typography_font(medium);
		font-size: $fontsize_paragraph_node;
		line-height: 1.6em;

		@include breakpoint($breakpoint_desktop) {
			font-size: $fontsize_paragraph_node_desktop;

			margin-left: $node_left-margin;
			padding: 0 $node_padding;
		}

		> p {
			font-size: $fontsize_paragraph_node;
			line-height: 1.6em;

			margin-top: 20px;
			margin-top: 1.25rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_paragraph_node_desktop;
			}
		}

		strong {
			@include typography_font(bold);
		}

		a:not(.button) {
			@include typography_font(medium);
			@include link-style($color_text_link, underline, $color_background-6, underline);
		}

		> ul,
		> ul ul,
		> ul ul ul,
		> .a-002 ul,
		> .a-002 ul ul,
		> .a-002 ul ul ul  {
			margin-top: 20px;
			margin-top: 1.25rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			list-style: disc;
			margin-left: 1em;

			li {
				list-style: inherit; // reset _box.scss (tyka se b-047)
				//margin-left: 1em;

				// FIX: floating overlap
				position: relative;
				left: 1em;

				width: calc(100% - 1em);

				a:not(.button) {
					@include link-style($color_text_link, underline, $color_background-6, underline);
				}
			}

			ul {
				list-style: circle;

				ul {
					list-style: square;
				}
			}

			ol {
				list-style: decimal;

				ol {
					list-style: lower-roman;

					ol {
					list-style: lower-alpha;
					}
				}
			}
		}

		> ol,
		> ol ol,
		> ol ol ol,
		> .a-002 ol,
		> .a-002 ol ol,
		> .a-002 ol ol ol {
			margin-top: 20px;
			margin-top: 1.25rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			list-style: decimal;
			margin-left: 1em;

			li {
				list-style: inherit; // reset _box.scss (tyka se b-047)
				//margin-left: 1.5em;

				// FIX: floating overlap
				position: relative;
				left: 1.5em;

				width: calc(100% - 1.5em);

				a:not(.button) {
					@include link-style($color_text_link, underline, $color_background-6, underline);
				}
			}

			ol {
				list-style: lower-roman;

				ol {
					list-style: lower-alpha;
				}
			}

			ul {
				list-style: disc;

				ul {
					list-style: circle;

					ul {
					list-style: square;
					}
				}
			}
		}
	}

	// body
	.body,
	.body .body-collapsible-container {
		//@include typography_font(regular);
		font-size: $fontsize_paragraph_node;
		line-height: 1.6em;

		color: $color_text_paragraph_node;

		@include breakpoint($breakpoint_desktop) {
			font-size: $fontsize_paragraph_node_desktop;

			margin-left: $node_left-margin;
			padding: 0 $node_padding;
		}

		> p {
			font-size: $fontsize_paragraph_node;
			line-height: 1.6em;

			margin-top: 20px;
			margin-top: 1.25rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_paragraph_node_desktop;
			}
		}

		// strong obecny
		strong {
			@include typography_font(medium);
		}

		// strong jako primy potomek p
		> p > strong {
			color: $color_text_accent;
		}

		.file-audio {
			margin-left: 0;

			@include breakpoint($breakpoint_desktop) {
				margin: 1.75em -1.875rem;
			}
		}

		/* obecna h2 v node - ovlivnuje vsechny h2 kdekoliv v node, tzn. primo v body, v assetech, apod. */
		h2 {
			clear: both;

			font-size: $fontsize_h2;
			text-transform: none;

			padding: 0;

			color: $color_text_accent;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h2_desktop;
			}
		}

		/* h2 primo v body node - mela by ovlivnit jen h2 primo v body clanku */
		> h2 {
			clear: inherit; // aby se zalamovalo pokud obteka dalsi element, napr. obrazek

			font-size: $fontsize_h2_node;
			line-height: calc(#{$fontsize_h2_node} + 10px);

			margin-top: 40px;
			margin-top: 2.5rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h2_node_desktop;
				line-height: calc(#{$fontsize_h2_node_desktop} + 10px);
			}
		}

		/* obecna h3 v node - ovlivnuje vsechny h3 kdekoliv v node, tzn. v body, v assetech, apod. */
		/*h3 {
			clear: both;

			font-size: $fontsize_h3;
			transform: none;

			color: $color_text_accent;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h3_desktop;
			}
		}*/

		/* h3 primo v body node - mela by ovlivnit jen h3 primo v body clanku */
		> h3 {
			clear: inherit; // aby se zalamovalo pokud obteka dalsi element, napr. obrazek

			font-size: $fontsize_h3_node;
			line-height: calc(#{$fontsize_h3_node} + 10px);

			margin-top: 40px;
			margin-top: 2.5rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h3_node_desktop;
				line-height: calc(#{$fontsize_h3_node_desktop} + 10px);
			}
		}

		h4 {
			clear: both;

			font-size: $fontsize_h4;
			transform: none;

			color: $color_text_accent;

			@include breakpoint($breakpoint_desktop) {
				font-size: $fontsize_h4_desktop;
			}
		}

		//p:not(.cteteTake) a:not(.button) {
		> p:not(.cteteTake) > a:not(.button),
		> p:not(.cteteTake) > strong > a:not(.button) {
			@include typography_font(medium);
			@include link-style($color_text_link, underline, $color_background-6, underline);

			strong {
				color: unset;
			}
		}

		> ul,
		> ul ul,
		> ul ul ul,
		> .a-002 ul,
		> .a-002 ul ul,
		> .a-002 ul ul ul  {
			margin-top: 20px;
			margin-top: 1.25rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			list-style: disc;
			margin-left: 1em;

			li {
				list-style: inherit; // reset _box.scss (tyka se b-047)
				//margin-left: 1em;

				// FIX: floating overlap
				position: relative;
				left: 1em;

				width: calc(100% - 1em);

				a:not(.button) {
					@include typography_font(medium);
					@include link-style($color_text_link, underline, $color_background-6, underline);
				}
			}

			ul {
				list-style: circle;

				ul {
					list-style: square;
				}
			}

			ol {
				list-style: decimal;

				ol {
					list-style: lower-roman;

					ol {
					list-style: lower-alpha;
					}
				}
			}
		}

		> ol,
		> ol ol,
		> ol ol ol,
		> .a-002 ol,
		> .a-002 ol ol,
		> .a-002 ol ol ol {
			margin-top: 20px;
			margin-top: 1.25rem;
			margin-bottom: 20px;
			margin-bottom: 1.25rem;

			list-style: decimal;
			margin-left: 1em;

			li {
				list-style: inherit; // reset _box.scss (tyka se b-047)
				//margin-left: 1.5em;

				// FIX: floating overlap
				position: relative;
				left: 1.5em;

				width: calc(100% - 1.5em);

				a:not(.button) {
					@include typography_font(medium);
					@include link-style($color_text_link, underline, $color_background-6, underline);
				}
			}

			ol {
				list-style: lower-roman;

				ol {
					list-style: lower-alpha;
				}
			}

			ul {
				list-style: disc;

				ul {
					list-style: circle;

					ul {
					list-style: square;
					}
				}
			}
		}

		// table: container
		> .container__table {
			overflow-x: auto;
		}

			// table
			> table,
			> .container__table > table {
				margin: 1.5em 0;

				font-size: 12px;
				line-height: 1.3;

				border-collapse: collapse;
				border: 1px solid $color_divider;

				@include breakpoint($breakpoint_desktop) {
					font-size: 14px;
				}

				th,
				td {
					padding: 1em;
					text-align: center;
					border: 1px solid $color_divider;
				}

				th {
					background-color: $color_background-1;
				}
			}
	}



	// image primary
	.field-image-primary {
		position: relative; // kvuli absolutnimu pozicovani listy .mujrozhlas-audio
		margin: 4px -0.625rem;

		// tag rounded
		.tag-rounded {
			position: absolute;
			max-inline-size: calc(100% - 20px);

			padding-top: 0.25em;
			padding-bottom: 0.25em;

			//@include typography_font(regular);
			//@include adjust-font-size-to($fontsize_tag);

			@include breakpoint($breakpoint_desktop) {
				padding-top: 0.5em;
				padding-bottom: 0.5em;

				@include adjust-font-size-to($fontsize_tag_desktop);
			}
		}
		// left - top/bottom
		.tag-rounded.tag-rounded--top-left,
		.tag-rounded.tag-rounded--bottom-left {
			right: $layout_main_horizontal-padding_mobile;

			padding-left: 0.75em;
			padding-right: 0.25em;

			@include breakpoint($breakpoint_desktop) {
				right: 0;

				padding-left: 1.25em;
				padding-right: 0.75em;
			}
		}
		// right - top/bottom
		.tag-rounded.tag-rounded--top-right,
		.tag-rounded.tag-rounded--bottom-right {
			left: $layout_main_horizontal-padding_mobile;

			padding-left: 0.25em;
			padding-right: 0.75em;

			@include breakpoint($breakpoint_desktop) {
				left: 0;

				padding-left: 0.75em;
				padding-right: 1.25em;
			}
		}
		// top - left/right
		.tag-rounded.tag-rounded--top-left,
		.tag-rounded.tag-rounded--top-right {
			bottom: 0;
		}
		// bottom - left/right
		.tag-rounded.tag-rounded--bottom-left,
		.tag-rounded.tag-rounded--bottom-right {
			top: $croplayer_mujrozhlas_banner_height;
		}

		@include breakpoint($breakpoint_desktop) {
			margin: 0;
		}
	}



	// image
	img {
		display: block;
	}



	//
	// Metadata underneath a image in node
	//
	.node-image-metadata {
		display: inline-block;
		margin-top: 0.25em;
		font-size: 14px;

		@include breakpoint($breakpoint_desktop) {
			font-size: 16px;
		}
	}

	.node-image-metadata__title {
		color: $color_text_paragraph;
	}

	.node-image-spacer {
		margin-right: 0.25em;
	}

	.node-image-metadata.node-field-author {
		font-size: 0.875em;
	}

	.audio-rights-expired,
	.audio-rights-future {
		position: relative;
		background: $color_background-2;
		padding: 0 1em;
		min-height: 2.8em;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			height: 2em;
			width: 2em;
			top: 0.5em;
			left: 0.9em;
		}

		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
			min-height: 3em;
		}

		h3 {
			margin: 0 1em 0 2.9em;
			padding: 0.5em 0;
			color: $color_text_paragraph;
			@include typography_font(regular);
			@include breakpoint($breakpoint_desktop) {
				padding: 1em 0;
			}
		}

		p {
			font-size: 0.85rem;
			margin: 0.5em 1em 0 3.6em;
			padding: 0 0 1em;
			color: $color_background-6;
			clear: both;
			line-height: 1.2;

			@include breakpoint($breakpoint_desktop) {
				font-size: 0.9rem;
			}
		}

		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}
	}

	.audio-rights-expired--asset,
	.audio-rights-future--asset {
		&:before {
			height: 1.7em;
			width: 1.7em;
		}
	}
	.audio-rights-expired__title {

		> .filename {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			float: left;
			max-width: calc(100% - 7em);
		}

		> .title-end {
			float:left;
			width: 6.5em;
			margin: 0 0 0 0.2em;
			text-align: left;
		}

		& + p {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.audio-rights-expired {
		p {
			display: none;
		}

		&:before {
			background: $file_icon_audio_expired top no-repeat;
			background-size: 32px;
		}
	}

	.audio-rights-future {
		&:before {
			background: $file_icon_audio_future top no-repeat;
			background-size: 32px;
		}

		p {
			color: darken($color_background-5, 2%);
		}
	}


	// Social buttons inline
	.top-1-0,
	.content-1-0,
	.content-1-1 {
		position: relative;
	}
	.block-social-buttons,
	.node-block__block--social-buttons {
		@include breakpoint($breakpoint_desktop) {
			display: none;
		}
	}
	// top
	.block-social-buttons--top,
	.node-block__block--social-buttons-top {
		position: absolute;
		right: 0;
		bottom: 8px;
	}
	// bottom
	.block-social-buttons--bottom,
	.node-block__block--social-buttons-bottom {
		margin-top: 2em;
	}

	// Social buttons vertical
	.l-grid--8-1 {

		.content-1-2,
		.content-1-3 {
			position: relative;
		}

		.block-social-buttons-vertical {
			position: absolute;
			top: 0;

			z-index: 1;

			&.fixed {
				position: fixed;
				top: 0;
			}

			&.bottom {
				bottom: 0;
				top: unset;
			}
		}
	}

	.node-trianle {
		background: url(../images/icon/ico_triangle.svg) top left no-repeat;
		@include typography_font(bold);
		font-size: 0.8rem;
		height: $node_padding;
		margin-top: 38px;
		display: block;
		color: $color_text_paragraph;

		span {
			padding: 0.625rem;
			line-height: 0.625rem;
			display: inline-block;
			margin-left: $node_padding;
			background: $color_background-2;
		}

		@include breakpoint($breakpoint_desktop) {
			float: left;
			width: $node_left-margin;
			height: 4.347rem;
			background-size: 100%;

			span {
				background: none;
				padding: 0;
				color: white;
				transform: rotate(-45deg);
				margin: 2.35rem 0 0 0;
				text-align: center;
				width: 5.4rem;
				display: block;
			}
		}
	}

	.node_attachment {
		padding: 0.625rem 1rem;
		border: 1px solid #d6d6d8;
		border-width: 1px 1px 0 1px;
		display: block;
		clear: both;
		overflow: hidden;

		@include breakpoint($breakpoint_desktop) {
			padding: 1.25rem;
			margin-left: $node_left-margin;
		}

		&:last-child {
			border-width: 1px 1px 1px 1px;
		}

		.node_attachment__icon {
			width: 3.625em;
			height: 3.625em;
			background-image: url(../images/button/btn_download_sprite.svg);
			background-size: 200%;
			background-position: 0;
			float: left;
			border: 1px solid $color_background-4;
			border-radius: 50%;
		}

		&:hover .node_attachment__icon {
				background-position: 100%;
		}

		.node_attachment__data {
			float: left;
			margin-left: 0.625em;
			max-width: 70%;


			@include breakpoint($breakpoint_desktop) {
				margin-left: 1em;
				max-width: 85%;
			}
		}

		.node_attachment__name {
			color: $color_decoration_main;

			@include breakpoint($breakpoint_desktop) {
				font-size: 1.375em;
				margin: 0.3rem 0 0.2em 0;
			}
		}

		.node_attachment__meta {
			font-size: 0.8em;
			color: $color_text_paragraph;

			@include breakpoint($breakpoint_desktop) {
				font-size: 1em;
			}
		}
	}

	.views-row-last .node_attachment {
		border-width: 1px;
	}
}





/**
 * Box - Souvisejici
 */
.page-node .b-041i {

	@include breakpoint($breakpoint_desktop) {
		margin-top: 5em;
		margin-left: $node_left-margin;
		//padding-left: $node_padding;
	}

	h2 {
		@include breakpoint($breakpoint_desktop) {

			margin-bottom: 1.5em;
			font-size: $fontsize_h2; // 22px
		}
	}

	.b-041i__list {

		.b-041i__list-item {

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				margin-bottom: 2em;
			}

			h3 {
				margin-top: 1rem;
				margin-bottom: 0.5rem;
			}

			.b-041i__date {
				color: $color_text_paragraph;
				font-size: $fontsize_h4_desktop; // 14px

				display: none;
			}
		}
	}
}



//
// Social icons - vertical
//

.block-social-buttons-vertical .social-icons {
	float: left;
	margin-bottom: 0;
	display: none;

	@include breakpoint($breakpoint_desktop) {
		display: block;
	}

	li {
		display: block;

		margin: 0;
		margin-top: 0.5em;
		//margin-left: 0.625em;
		padding: 0;

		line-height: 0;

		/*&:first-child {
			margin-top: 0;
		}*/
	}

	a {
		display: block;

		width: 2.875em;
		height: 2.875em;

		background-color: $node_color_text_lighter;

		span {
			display: block;

			height: 2.875em;
			width: 2.875em;

			background-repeat: no-repeat;
			background-position: center center;
			background-repeat: no-repeat;

			&:hover {
				transition: 0.5s;
			}
		}
	}

	.facebook-icon {
		background-image: $file_button_social_facebook;
		background-size: 30%;

		&:hover {
			background-color: $color_extsub_social_facebook;
		}
	}

	.twitter-icon {
		background-image: $file_button_social_twitter;
		background-size: 50%;

		&:hover {
			background-color: $color_extsub_social_twitter;
		}
	}

	.x-icon {
		background-image: $file_button_social_x;
		background-size: 50%;

		&:hover {
			background-color: $color_extsub_social_x;
		}
	}

	.googleplus-icon {
		background-image: $file_button_social_googleplus;
		background-size: 70%;

		&:hover {
			background-color: $color_extsub_social_googleplus;
		}
	}

	.linkedin-icon {
		background-image: $file_button_social_linkedin;
		background-size: 45%;

		&:hover {
			background-color: $color_extsub_social_linkedin;
		}
	}

	&::after {
		clear: both;
		content: "";
		display: table;
	}
}



/*
 * Autori, Zdroje
 */

.node-block--authors {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	clear: both; // prevence pokud je predchazejici floatovany asset

	margin-top: 1.5em;

	font-size: 1em;
	color: $color_text_paragraph;

	@include breakpoint($breakpoint_desktop) {
		margin-left: $node_left-margin;
	}

	a {
		@include link-style($color_decoration_main, none, $color_decoration_main, underline);
	}

	.block__label--authors,
	.block__label--comma,
	.block__label--sources {
		margin-right: 0.5em;
	}

	.block__label--spacer {
		margin: 0 0.5em;
	}
}



/**
 * Tagy
 */

.node-block--tags {
	clear: both; // prevence pokud je predchazejici floatovany asset
	margin-top: 1.5em;

	@include breakpoint($breakpoint_desktop) {
		margin-left: $node_left-margin;
	}

	.block__label--tags { display: none; }

	.node-block__list--tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		background-image: $file_icon_tag;
		background-size: 2em;
		background-repeat: no-repeat;
		background-position: left center;
		padding-left: 1.5em;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
		}

		.node-block__list-item--tags {
			margin-left: 1.5em;
			font-size: 1em;

			a {
				@include link-style($color_decoration_main, none, $color_decoration_main, underline);
			}
		}

		.node-block__list-item--tags:first-child {
			//margin-left: 0;
			//padding-left: 3em;
		}
	}
}



.page-o-stanici,
.node-type-article,
.node-type-profile,
.page-node-o-poradu,
.page-user {

	.pane-title, h2 {
		margin: 0.5em 0;
		padding-left: 0.5em;
		text-transform: uppercase;
	}
}



// Fix for debugging tool
.krumo-root {
	img {
		width: auto;
	}
}





//
// Assets
//

// Image - big
.wysiwyg-asset.image__big_img {

}

// Image - small left
.wysiwyg-asset.image__small_img_left {

	@include breakpoint($breakpoint_tablet) {
		float: left;
		width: 50%;
		padding-right: 1em;
	}
}

// Image - small right
.wysiwyg-asset.image__small_img_right {

	@include breakpoint($breakpoint_tablet) {
		float: right;
		width: 50%;
		padding-left: 1em;
	}
}


// yellow alert icon on white semi-transparent background
.node-type-article {
	.expired {
		.field-main-audio {
			.audio-rights-expired.audio-article {
				&:before {
					background: $file_icon_audio_expired_yellow center no-repeat;
				}
			}
		}
	}
}

// player messages
.node-type-article {
	.has-main-audio {
		.audio-article-player {
			.audio-article {
				h3 {
					font-size: 0.85em;
					padding: 0;
					@include breakpoint($breakpoint_desktop) {
						font-size: 1.0625em;
						padding: 0.5em 0;
					}
				}
			}
		}
	}
}

// serial audio player
.node-type-serial {
	.audio-serial-player {
		&.future,
		&.expired {
			.audio-rights-expired,
			.audio-rights-future {
				background-color: $color_background_player_custom_alpha;
				width: calc(100% - 20px);
				margin-left: 10px;
				margin-right: 10px;
				margin-bottom: 0;
				display: flex;
				@include breakpoint($breakpoint_desktop) {
					width: 100%;
					margin-left: 0;
					margin-right: 0;
				}
				& > p {
					display: flex !important;
					align-items: center;
					margin-top: 1px;
					padding-bottom: 0;
					min-height: 54px;
					font-size: 0.85rem;
					color: $color_background-6;
					@include breakpoint($breakpoint_desktop) {
						font-size: 0.95rem;
					}
				}
				&.nojs {
					p {
						display: block; // zobrazeni hlasky, ktera je jinak skryta
					}
				}
			}
		}
		// future audio
		.audio-rights-future {
			&:before {
				content: '';
				background: $file_ico_audio_future_green top no-repeat;
				background-size: 30px;
			}
			&__title {
				padding-top: 8px;
				padding-bottom: 0 !important;
			}
			& > p {
				margin-top: 0;
			}
		}
		// expired audio
		&.expired {
			.audio-rights-expired {
				&:before {
					background: $file_icon_audio_expired_yellow center no-repeat;
				}
			}
		}
	}
}
.page-node.soundmanager-embed {
	// future audio
	.audio-rights-future {
		&:before {
			background: $file_ico_audio_future_green top no-repeat;
		}
	}
}





//
// Node Print Styles
//

@media print {

	/* header & footer */
	.page-node header.l-grid--12-1,
	.page-node footer.l-grid--12-1 {
		display: none;
	}

	/* main */
	.page-node .l-grid--8-1 {
		clear: none;
		float: none;
		margin-left: 0;
		margin-right: 0;
		padding-right: 0;
		width: 100%;
	}

	/* aside */
	.page-node .l-grid--4-9 {
		display: none;
	}

	/* unwanted elements */
	.page-node .field-main-audio,
	.page-node .field-main-video,
	.page-node .asset-type-audio,
	.page-node .asset-type-video,
	.page-node .asset-type-links,
	.page-node .l-grid--12-1 .view,
	.page-node .block-social-buttons,
	.page-node .block-social-buttons-vertical,
	.page-node .cteteTake,
	.page-node .node_attachment,
	.page-node .a-004p {
		display: none !important;
	}

	.page-node .field-perex,
	.page-node .field.body,
	.page-node .node-authors {
		margin-left: 0;
	}
}





/*
 * Pokud nema hlavni obrazek/audio/video
 */
.page-node.no-main-media,
.b-047.no-main-media, .box.b-047.no-main-media {

	.field-perex {

		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
	}

	.field-perex,
	.body {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			padding: 0;
		}
	}
}






/*
 * Pokud nema vert. soc. buttony
 */
.page-node.no-social-icons,
.b-047.no-social-icons, .box.b-047.no-social-icons {

	.content-container {

		@include breakpoint($breakpoint_desktop) {
			min-height: initial; // prebiji docasny fix - min. vyska jako absolutne pozicovanych vert. soc. button
		}

		.content-1--1-1 {
			display: none;
		}
	}

	.field-perex,
	.body {

		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
			padding: 0;
		}
	}
}
