//
// B-044a Styles
//



.b-044a {
}

	.b-044a h2 {
		
		text-transform: uppercase;
	}
	
	.b-044a__subsection {
		
		position: relative;
	}
		
		.b-044a__list {
			
			display: flex;
			flex-direction: row;
			
			position: relative;
			
			overflow: hidden;
		}
		
		// gradient
		.b-044a__list.b-044a__list--start::before,
		.b-044a__list.b-044a__list--middle::before,
		.b-044a__list.b-044a__list--middle::after,
		.b-044a__list.b-044a__list--end::before,
		
		.b-044a__list.cro-carouselStart::before,
		.b-044a__list.cro-carouselMiddle::before,
		.b-044a__list.cro-carouselMiddle::after,
		.b-044a__list.cro-carouselEnd::before {
			
			content: "";
			
			display: block;
			
			position: absolute;
			right: 0;
			
			width: 12.5%;
			height: 100%;
			
			//background-image: linear-gradient(90deg, rgba(0, 128, 158, 0), #fff);
			@extend .bg-gradient-transparent-to-color-background-0;
			 
			z-index: 2;
			
			@include breakpoint($breakpoint_desktop) {
				
				display: none;
			}
		}
		
		.b-044a__list.b-044a__list--middle::after,
		.b-044a__list.b-044a__list--end::before,
		
		.b-044a__list.cro-carouselMiddle::after,
		.b-044a__list.cro-carouselEnd::before {
			
			right: unset;
			left: 0;
			
			transform: rotate(180deg);
		}
			
			.b-044a__list-item {
				
				position: relative;
				
				// start
				//right: 0;    // first
				
				// middle
				//right: 70%;  // second (+70)
				//right: 150%; // third  (+80)
				//right: 230%; // fourth (+80)
				//right: 310%; // fifth  (+80)
				
				// end
				//right: 380%; // last   (+70)
				
				min-width: 80%;
				
				background-color: $color_background-6;
				
				@include breakpoint($breakpoint_desktop) {
					
					// start
					//right: 0;     // first
					
					// middle
					//right: 39%;   // second (+39)
					//right: 83.5%; // third  (+44.5)
					//right: 128%;  // fourth (+44.5)
					
					//last
					//right: 167%;  // last   (+39)
					
					//min-width: 44.5%;
					min-width: 44.45%;
				}
			}
			
			.b-044a__list.b-044a__list--start .b-044a__list-item {
				
				right: 0; // first
				
				@include breakpoint($breakpoint_desktop) {
					
					right: 0; // first
				}
			}
			
			.b-044a__list.b-044a__list--middle .b-044a__list-item {
				
				right: 70%; // second (+70)
				
				@include breakpoint($breakpoint_desktop) {
					
					right: 39%; // second (+39)
				}
			}
			
			.b-044a__list.b-044a__list--end .b-044a__list-item {
				
				right: 380%; // last (+70)
				
				@include breakpoint($breakpoint_desktop) {
					
					right: 167%; // last (+42.75-7.5)
				}
			}
			
				.b-044a__block--second {
					
					padding: 1em;
				}
					
				.b-044a__image {
					
					position: relative;
				}
				
				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-044a h3 {
					
					margin: 0;
				}
				
				// TODO prime stylovani vnorenych atomu, jak zvykem nemaji specificke tridy
				.b-044a h3 a {
					@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, none);
				}
				
				
			.b-044a__list-item:last-child {
			}
		
		
		.b-044a__block--slider-buttons {
			
			display: none;
			
			@include breakpoint($breakpoint_desktop) {
				
				display: flex;
				flex-direction: row;
				justify-content: center;
				
				position: absolute;
				
				width: 100%;
				height: 100%;
				
				top: 0;
				left: 0;
				
				pointer-events: none;
				
				align-items: center;
				justify-content: space-between;
				
				z-index: 3;
			}
		}
			
			// oba buttony
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-044a__block--slider-buttons .button-box-flat {
				// obnoveni neklikatelnosti rodice
				pointer-events: auto;
			}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-044a__block--slider-buttons .button-box-flat--slider-left {
				
				margin-right: 1em;
				
				@include breakpoint($breakpoint_desktop) {
					margin: 0;
					margin-left: -0.5em;
				}
			}
			
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-044a__block--slider-buttons .button-box-flat--slider-right {
				
				@include breakpoint($breakpoint_desktop) {
					margin-right: -0.5em;
				}
			}





// vizualni debug - plab only
.patternlab .b-044a__list-item {
	
	position: relative;
}
.patternlab .b-044a__list-item:nth-child(odd)::before,
.patternlab .b-044a__list-item:nth-child(even)::before {
	
	content: "";
	
	position: absolute;
	
	width: 100%;
	height: 100%;
	
	opacity: 0.5;
	
	z-index: 1;
}
.patternlab .b-044a__list-item:nth-child(odd)::before {
	
	background-color: $color_radiozurnal_main;
}
.patternlab .b-044a__list-item:nth-child(even)::before {
	
	background-color: $color_vltava_main;
}