//
// Pardubice - HP
//



.pardubice.page-pardubice-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}