//
// H4
//



h4 {
	@include typography_font(medium);

	@include adjust-font-size-to($fontsize_h4);
	@include rhythm(2, 0, 0, 1, $fontsize_h4);
}
