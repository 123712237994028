//
// Button Box Flat
//



// struktura obecneho box-flat tlacitka
.button-box-flat {
	display: flex;
	align-items: center;

	padding: 0.5em;

	border: 1px solid $color_background-4;
	background-color: $color_background-0;

	@include link-style($color_decoration_main, none, $color_decoration_main, underline);


	@include adjust-font-size-to($fontsize_form_flat_button);

	@include breakpoint($breakpoint_desktop) {
		@include adjust-font-size-to($fontsize_form_flat_button_desktop);
	}

}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-box-flat:hover {
	//background-color: $color_background-0_alpha-low;
	opacity: 0.8;
}

	.button-box-flat__emblem {
		flex-shrink: 0;

		width: 2em;
		height: 2em;

		margin-left: 0.75em;
		margin-right: 0.75em;

		background-repeat: no-repeat;
		background-position: left center;
	}

	.button-box-flat__label {
		flex-grow: 1;

		display: flex;
		align-items: center;

		padding: 0.5em;

		// standardni 0.5em + 0.5em od obalu
		// aby bylo misto zprava i zleva stejne pro buttony co nemaji sirku 100% a maji nejaky "auto" obal (trifork)
		padding-right: 1em;

		border-right: 1px solid $color_background-4;

		text-align: center;
	}

		.button-box-flat__label-nest {
			flex-grow: 1;
			display: block;
		}


	.button-box-flat__icon {
		flex-shrink: 0;

		width: 1em;
		height: 2em;

		margin-left: 0.75em;
		margin-right: 0.25em;

		background-repeat: no-repeat;
		background-position: left center;
	}

		.button-box-flat__icon-textcontent {
			display: none;
		}





// small varianta
.button-box-flat.button-box-flat-small {
	padding: 0 0.5em;
}

	.button-box-flat.button-box-flat-small .button-box-flat__label {
		padding: 0;
		border-right: none;
	}

	.button-box-flat.button-box-flat-small .button-box-flat__icon {
		height: 1em;
		margin-right: 0;
	}





// inverzni varianta obecneho tlacitka
.button-box-flat--negative {
	border: 0;
	background-color: $color_background-6;

	@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-box-flat--negative:hover {
	//background-color: $color_background-6_alpha-low;
	opacity: 0.8;
}





// transparentni varianta obecneho tlacitka
.button-box-flat--transparent {
	background-color: transparent;
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-box-flat--transparent:hover {
	//background-color: $color_background-6_alpha-low;
	opacity: 0.8;
}





// inverzni transparentni varianta obecneho tlacitka
.button-box-flat--transparent-negative {
	background-color: transparent;
	//background-color: $color_background-6; // debug only

	@include link-style($color_text_accent_inverted, none, $color_text_accent_inverted, underline);

}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-box-flat--negative:hover {
	//background-color: $color_background-6_alpha-low;
	opacity: 0.8;
}





// varianta obecneho tlacitka bez sipky
.button-box-flat--noarrow {

	.button-box-flat__label {
		padding-right: 0.5em;
		border-right: none;
	}
}
// TODO docasne vizualni reseni nedefinovaneho hover efektu
.button-box-flat--noarrow:hover {
	//background-color: $color_background-6_alpha-low;
	opacity: 0.8;
}



// specificke dekorace
// struktura tlacitka je emblem - label - ikona
// vizual emblemu a ikony je obecny, autonomni, znovupouzitelny, nenavazany na konkretni specificke tlacitko

.button-box-flat__emblem--r {
	background-image: $file_logo_R_main;

	width: 1.75em;
	height: 2em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}
	.button-box-flat--negative .button-box-flat__emblem--r {
		background-image: $file_logo_R_white;
	}


.button-box-flat__emblem--podcast {
	background-image: $file_icon_podcast_colored-main;

	width: 2.5em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--program {
	background-image: $file_icon_calendar_colored-white;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--enter {
	background-image: $file_icon_enter_colored-white;

	width: 2.5em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--form {
	background-image: $file_icon_form_colored-white;

	width: 2.5em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--radio {
	background-image: $file_icon_radio_colored-white;

	width: 2.5em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;

	position:relative;
	top: -5px;
}

.button-box-flat__emblem--mail {
	background-image: $file_icon_mail_colored-white;

	width: 2.5em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--new-user {
	background-image: $file_icon_new-user_colored-white;

	width: 2.5em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--pay-card {
	background-image: $file_icon_pay-card_colored-white;

	width: 2.5em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--localization {
	//background-color: $color_background-0; // debug only

	width: 4em;
	height: 2em;

	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.button-box-flat__emblem--localization-cze {
	background-image: $file_flag_cze;
}

.button-box-flat__emblem--localization-eng {
	background-image: $file_flag_eng;
}

.button-box-flat__emblem--localization-ger {
	background-image: $file_flag_ger;
}

.button-box-flat__emblem--localization-esp {
	background-image: $file_flag_esp;
}

.button-box-flat__emblem--localization-fra {
	background-image: $file_flag_fra;
}

.button-box-flat__emblem--localization-rus {
	background-image: $file_flag_rus;
}


.button-box-flat__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-decoration-main;
}
// obecna negativni varianta ikony
.button-box-flat--negative .button-box-flat__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-white;
}
// obecna transparentni varianta ikony
.button-box-flat--transparent .button-box-flat__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-decoration-main;
}
// obecna transparentni negativni varianta ikony
.button-box-flat--transparent-negative .button-box-flat__icon--arrow-double-east {
	background-image: $file_icon_arrow_double_east_colored-white;
}

.button-box-flat__icon--arrow-south {
	background-image: $file_icon_arrow_double_east_colored-decoration-main;

	// TODO chybejici ikona (ve variables je dosazena opacna)
	transform: rotate(90deg);
}

.button-box-flat__icon--arrow-single-south {
	background-image: $file_icon_arrow_single_south_colored-decoration-main;
}
	.expanded .button-box-flat__icon--arrow-single-south,
	.button-box-flat__icon--arrow-single-south.expanded {

		// TODO chybejici ikona north, tak otacime a zarovnavame
		background-position: right center;
		transform: rotate(180deg);
	}
// obecna negativni varianta ikony
.button-box-flat--negative .button-box-flat__icon--arrow-single-south {
	background-image: $file_icon_arrow_single_south_colored-white;
}

.button-box-flat__icon--arrow-single-east {
	background-image: $file_icon_arrow_single_east_colored-decoration-main;

	// TODO cele tohle barveni svg je haluzni hack
	//filter: invert(0.5) sepia(1) hue-rotate(145deg);
}
// obecna negativni varianta ikony
.button-box-flat--negative .button-box-flat__icon--arrow-single-east {
	background-image: $file_icon_arrow_single_east_colored-white;

	// TODO cele tohle barveni svg je haluzni hack
	//filter: none;
}

// slider buttony maji tenke sipky
.button-box-flat--slider-right .button-box-flat__icon--arrow-single-east {
	background-image: $file_button_icon_arrow_east;
}

.button-box-flat--slider-left .button-box-flat__icon--arrow-single-west {
	background-image: $file_button_icon_arrow_west;

	// TODO chybejici ikona (ve variables je dosazena opacna)
	transform: rotate(180deg);
}



// specificke modifikatory obecneho buttonu - neboli specificka tlacitka
// obcas neBEMove pretezovani protoze classovat to dle typu boxu by bylo silenstvi

// readmore
.button-box-flat--readmore {
}

// dropdown
.button-box-flat--dropdown {

	.button-box-flat__label {
		padding: 0.15em 0.5em;
		border-right: 0;
		text-align: left;
	}

}

// station-camera
.button-box-flat--station-camera {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;

	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;

	@include breakpoint($breakpoint_desktop) {
		padding-right: 0.5em;
	}

	.button-box-flat__label {
		display: block;
		text-align: left;

		padding-right: 1em;

		border-right: 0;

		overflow: hidden;

		@include adjust-font-size-to($fontsize_h3);

		.button-box-flat__label-station {
			display: block;

			@include typography_font(medium);
			font-size: 16px;
			line-height: 20px;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.button-box-flat__label-show {
			display: block;

			font-size: 14px;
			line-height: 18px;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.button__image {
		flex-shrink: 0;

		width: 120px;

		img {
			display: block;
		}
	}

	.button-box-flat__icon {
		display: none;

		@include breakpoint($breakpoint_desktop) {
			display: block;

			width: 1em;
			height: 1em;
		}
	}
}

// station-logo
.button-box-flat--station-logo {

	.button-box-flat__label {
		display: block;
		text-align: left;

		padding-right: 1em;

		@include adjust-font-size-to($fontsize_h3);

		.button-box-flat__label-station {
			display: block;

			@include typography_font(medium);
			font-size: 16px;
			line-height: 20px;
		}
		.button-box-flat__label-description {
			display: block;

			font-size: 14px;
			line-height: 18px;

			margin-top: 0.25em;
		}
	}

	.button-box-flat__emblem {
		width: auto;
		height: 1.25em;
		margin-left: 0;
		margin-right: 0.75em;

		.button-box-flat__label-station {
			display: none;
		}
	}
	.button-box-flat__emblem--radiozurnal {
		background-image: $file_logo_radiozurnal_colored-white;
	}
	.button-box-flat__emblem--dvojka {
		background-image: $file_logo_dvojka_colored-white;
	}
	.button-box-flat__emblem--vltava {
		background-image: $file_logo_vltava_colored-white;
	}
	.button-box-flat__emblem--jazz {
		background-image: $file_logo_jazz_colored-white;
	}
	.button-box-flat__emblem--plus {
		background-image: $file_logo_plus_colored-white;
	}
	.button-box-flat__emblem--wave {
		background-image: $file_logo_wave_colored-white;
	}
	.button-box-flat__emblem--radiopraha {
		background-image: $file_logo_radiopraha_colored-white;
	}
}

// podcast
.button-box-flat--podcast {

	.button-box-flat__label {
		padding-right: 1em;
		text-align: left;
		@include adjust-font-size-to($fontsize_h3);
	}
}

// no-ico
.button-box-flat--noico,
.button-box-flat--noico-dropdown {

	.button-box-flat__label {
		//padding: 1em;
		//@include typography_font(medium);
		//@include adjust-font-size-to($fontsize_h3);
	}
}
.button-box-flat--negative.button-box-flat--noico,
.button-box-flat--negative.button-box-flat--noico-dropdown {

	background-color: $color_decoration_main;
}

	.button-box-flat--negative.button-box-flat--noico:hover,
	.button-box-flat--negative.button-box-flat--noico-dropdown:hover {
		//background-color: $color_decoration_main;
		//opacity: 0.8;
	}

// program
.button-box-flat--program {

	.button-box-flat__label {
		padding-right: 1em;
		text-align: left;
		@include adjust-font-size-to($fontsize_h3);
	}
}
.button-box-flat--negative.button-box-flat--program {
	background-color: $color_corporate_main;
}

	.button-box-flat--negative.button-box-flat--program:hover {
		//background-color: $color_corporate_main;
		//opacity: 0.8;
	}

// poplatek
.button-box-flat--tile {
	border-radius: 6px;

	.button-box-flat__emblem {
		margin-top: 0;
		margin-bottom: 0;
	}

	.button-box-flat__label {
		padding-right: 1em;
		text-align: left;
		@include adjust-font-size-to($fontsize_h3);
		border-right: 0;
	}

	.button-box-flat__icon {
		width: 0.65em;

		margin-left: 0.75em;
		margin-right: 0.75em;
	}
}
.button-box-flat--negative.button-box-flat--poplatek {
	background-color: $color_corporate_main;
}

	.button-box-flat--negative.button-box-flat--program:hover {
		//background-color: $color_corporate_main;
		//opacity: 0.8;
	}

// localization
.button-box-flat--localization {

	.button-box-flat__label {
		padding-right: 1em;
		text-align: left;
		@include adjust-font-size-to($fontsize_h3);
	}
}
.button-box-flat--negative.button-box-flat--localization {
	background-color: $color_corporate_main;
}
	.button-box-flat--negative.button-box-flat--localization:hover {
		//background-color: $color_corporate_main;
		//opacity: 0.8;
	}

// sipky pro karusely
.button-box-flat--slider-left,
.button-box-flat--slider-right {
	width: 3em;
	height: 3em;

	padding: 0.75em;
	box-sizing: content-box;

	background-color: $color_background-6_alpha-mid;

	.button-box-flat__icon {
		width: 100%;
		height: 100%;

		margin: 0;

		background-position: center center;
	}

	@include breakpoint($breakpoint_desktop) {
		width: 2em;
		height: 2em;

		padding: 0.5em;
	}
}

// nacist dalsi
.button-box-flat--loadmore {
	text-align: center;

	.button-box-flat__label {
		display: inline-block;
		color: $color_text_paragraph;
		border: 0;
		padding: 0.75em;
		@include adjust-font-size-to($fontsize_h3);
	}

	.button-box-flat__section {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.button-box-flat__doubleline {

			@include breakpoint($breakpoint_desktop) {
				flex-grow: 1;

				height: 0.75em;

				border-color: $color_background-4;
				border-style: solid;
				border-width: 1px 0 1px 0;
			}
		}

		.button.button-box-flat {
			box-sizing: content-box;

			width: 3em;
			height: 3em;

			padding: 0.75em;
			margin: 0 1em 0 1em;

			background-color: $color_background-6_alpha-mid;

			.button-box-flat__icon {
				width: 100%;
				height: 100%;

				margin: 0;

				background-image: $file_button_icon_arrow_east_colored-white;
				transform: rotate(90deg);
				background-position: center center;
			}

			@include breakpoint($breakpoint_desktop) {
				width: 2.5em;
				height: 2.5em;

				padding: 0.5em;
			}
		}

		// TODO docasne vizualni reseni nedefinovaneho hover efektu
		.button-box-flat:hover {
			background-color: $color_background-6_alpha-low;
		}
	}
}
