//
// Radio Junior - obecne vlastnosti vsech stranek
//


$junior_selector_body: 'body.junior';
$junior_selector_body-hp: $junior_selector_body + '.page-junior-hp';
$junior_selector_body-kamery: $junior_selector_body + '.page-kamery';

$junior_letter-spacing: 0.06em;

$junior_box_radius: 8px;
$junior_badge_radius: 8px;
$junior_button_radius: 25px;
$junior_input_radius: 25px;

$junior_b-015_radius: 20px;
$junior_b-015-launcher-visual-img_radius: 5px;
$junior_b-015-launcher-info-timing-progressbar_radius: 5px;

$junior_b-016_radius: 20px;
$junior_b-016-item_radius: 10px;

$junior_main_radius: 25px;

$junior_b-015_background-color: $color_background-9;
$junior_b-015_background-color_mobile-gradient-supplement: $color_background-8;

$junior_b-015_launcher-visual_padding-top: 10px;
$junior_b-015_launcher-visual_width-reduction: ($junior_b-015_launcher-visual_padding-top / 2 * 3) * 2;

// Box specifics
$junior_fontsize_box-heading: 26px;
$junior_fontsize_box-heading_desktop: 22px;



#{$junior_selector_body} {
	position: relative;

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_junior_main, $color_junior_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
	&.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_junior_main);
	}



	/**
	 * fonty
	 *
	 * $font-junior-black
	 * $font-junior-ultrabold
	 * $font-junior-bold
	 * $font-junior-semibold
	 * $font-junior-medium
	 * $font-junior-book
	 * $font-junior-light
	 */

	// fonty: obecne
	letter-spacing: $junior_letter-spacing;
	//font-kerning: auto;
	//text-rendering: auto;

	.main {
		font-family: $font-junior-book;
	}

	h1 {
		font-family: $font-junior-semibold;
	}

	h2,
	h3,
	h4,
	h5 {
		font-family: $font-junior-medium;
	}

	/*ol > li,
	ul >li {
		font-family: $font-junior-book;
	}*/


	// fonty: launcher
	.b-015 {

		.b-015__list-item--right {

			.b-015__launcher-wrapper {

				.b-015__launcher {

					.b-015__launcher-info {

						.b-015__launcher-info-list {

							.b-015__launcher-info-title {
								font-family: $font-junior-semibold;
							}

							.b-015__launcher-info-timing {

								.b-015__launcher-info-timing-timespan {
									font-family: $font-junior-book;
								}
							}

							.b-015__launcher-info-description {
								font-family: $font-junior-book;
							}
						}
					}
				}

				.b-015__services_list {
					display: none;

					.b-015__services_list-item {
						font-family: $font-junior-book;
					}
				}
			}
		}
	}

	// fonty: nav
	.b-016__wrapper {

		&.b-016__wrapper--title-navigation {
			> h1,
			> h2 {
				font-family: $font-junior-semibold;
				font-size: 18px;
			}
		}

		.b-016__list > .b-016__list-item > a,
		.b-016__list-more > .b-016__list-item > a {
			font-family: $font-junior-semibold;
			font-size: 18px;
		}
	}

	// fonty: box, asset
	.asset,
	.wysiwyg-asset,
	.box:not(.b-014a):not(.b-014b):not(.b-017) {
		font-family: $font-junior-book;

		/*div,
		span {
			font-family: $font-junior-book;
		}*/

		strong {
			font-family: $font-junior-medium;
		}

		h2 {
			font-family: $font-junior-semibold;
		}

		h3 {
			font-family: $font-junior-semibold;
		}

		p {
			font-family: $font-junior-book;
		}

		ol > li,
		ul >li {
			font-family: $font-junior-book;
		}
	}

	// b-006h
	.b-006h {

		.b-006h__dropdown {}

		.b-006h__subsection {

			.b-006h__block--content {

				.b-006h__subblock--player {}

				.b-006h__subblock--description {

					.b-006h__meta {

						.b-006h__meta--time { font-family: $font-junior-medium; }

						.b-006h__meta--show { font-family: $font-junior-book; }
					}
				}
			}
		}
	}

	// b-028a
	.b-028a {

		.b-028a__subsection {

			.b-028a__subblock {

				.b-028a__list {

					.b-028a__title {
						font-family: $font-junior-medium;
					}

					.b-028a__item {
						font-family: $font-junior-medium;
					}
				}
			}
		}
	}

	// b-030b
	.b-030b {

		.b-030__subsection--list {

			.b-030__list {

				.b-030__list-item {}
			}

			.goToTopButton {
				font-family: $font-junior-book;
			}
		}
	}

	// b-038
	.b-038 {

		.b-038__navbar-wrapper {

			.b-038__navbar {

				.b-038__navbar-item {

					&.b-038__navbar-item--previous {}

					&.b-038__navbar-item--calendar {}

					&.b-038__navbar-item--next {}
				}
			}

			.datepicker.hasDatepicker {

				.ui-datepicker {}
			}
		}

		.listing {

			.b-038__links {

				.b-038__links-item {
					font-family: $font-junior-book;
				}
			}

			.b-038__list {

				.b-038__list-item {

					.b-038__block {

						&.b-038__block--timing {

							.b-038__info--time {
								font-family: $font-junior-book;
							}

							.b-038__launcher-info-timing-progressbar {}

							.b-038__image {}
						}

						&.b-038__block--description {

							h3 {

								a {}
							}

							p {}

							.b-038__actions {

								.cro-buttons-archive-h12 {
									font-family: $font-junior-book;
								}

								.button-play {

									.button__label {

										.button__label-nest {
											font-family: $font-junior-medium;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// fonty: b-110
	.b-110 {

		.b-110__section {}

			.b-110__block--calendar {}

				.b-110__calendar-number {
					font-family: $font-junior-semibold;
				}


			.b-110__block--description {}

				.b-110__description-sublock {

					.button-simple {

						.button-simple__label {

							.button-simple__label-nest {
								font-family: $font-junior-medium;
							}
						}

						.button-simple__icon--arrow-double-east {}
					}
				}

					.b-110__calendar-name {
						font-family: $font-junior-semibold;
					}
	}

	// studio embed
	.b-junior-studio-embed {

		// iframe
		.video-wrapper__embed {
		}

		// button simple
		.button-simple {

			.button-simple__label {
				font-family: $font-junior-semibold;
			}
		}
	}

	// fonty: tag
	.tag {
		font-family: $font-junior-book;

		&.tag-multiline {
			font-family: $font-junior-medium;
		}
	}
	.b-005 .b-005__list .b-005__list-item .tag {
		font-family: $font-junior-medium;
	}

	// fonty: badge
	.badge {
		font-family: $font-junior-medium;
	}

	// fonty: button-simple
	.button-simple {

		.button-simple__label-nest__label {

			.button-simple__label-nest {
				font-family: $font-junior-bold;
			}
		}
	}

	// fonty: button-box-flat
	.button-box-flat {

		.button-box-flat__label {

			.button-box-flat__label-nest {
				font-family: $font-junior-bold;
			}
		}
	}

	// fonty: button-box-flat--loadmore
	.button-box-flat--loadmore {

		.button-box-flat__label {

			.button-box-flat__label-nest {
				font-family: $font-junior-book;
			}
		}
	}

	// fonty: form
	form {

		label {
			font-family: $font-junior-medium;
		}

		input.form-text,
		textarea.form-textarea {
		}

		input.form-submit {
			font-family: $font-junior-bold;
		}
	}

	// fonty: view-filters
	.view-filters {

		form {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-exposed-widget {

						.views-widget {

							.bef-checkboxes {

								.form-item {

									&.form-item-all {
										a {
											font-family: $font-junior-medium;
										}
									}

									input[type="checkbox"] + label {
										font-family: $font-junior-medium;
									}
								}
							}
						}

						&.views-submit-button-wrapper {

							.views-widget {

								.form-item {

									input.form-text {
										font-family: $font-junior-book;
									}
								}
							}

							.views-exposed-widget {

								&.views-submit-button {
									input.form-submit {}
								}
							}
						}
					}
				}
			}
		}
	}

	//fonty: pseudo exposed filtry
	.radiobutton-filter {

		.radiobutton-filter__item {

			label {
				font-family: $font-junior-medium
			}
		}
	}

	// fonty: node
	&.page-node {

		h1 {
			font-family: $font-junior-semibold; // bylo $font-junior-medium
		}

		&.node-type-profile {

			.field-employment-web,
			.field-term-positions {
				font-family: $font-junior-medium;
			}

			.field-about-brief,
			.field-about-briefly {
				font-family: $font-junior-medium;
			}
		}

		.field-modified {
			.field-date-updated {
				font-family: $font-junior-book;
			}
		}

		//.field-entity-show-wrapper {
			.field-entity-show {
				font-family: $font-junior-medium;
				font-size: 13px;
			}
		//}

		.field.field-perex {
			p {
				font-family: $font-junior-book;
			}

			strong {
				font-family: $font-junior-semibold;
			}

			a,
			p:not(.cteteTake) a:not(.button) {
				font-family: $font-junior-medium;
			}
		}

		.field.body {
			p {
				font-family: $font-junior-light;
			}

			.citation > em {
				font-family: $font-junior-medium;
			}

			.citation > strong {
				font-family: $font-junior-book;
			}

			ol > li,
			ul > li {
				font-family: $font-junior-light;
			}

			strong {
				font-family: $font-junior-medium;
			}

			a,
			p:not(.cteteTake) a:not(.button) {
				font-family: $font-junior-medium;
			}
		}

		.node-block--authors {
			font-family: $font-junior-medium;

			a {
				font-family: $font-junior-medium;
			}

			.block__label--authors,
			.block__label--spacer,
			.block__label--sources  {
				font-family: $font-junior-light;
			}
		}
	}



	/**
	 * barvy
	 */
	//color: $color_junior_add-2-dark;

	/*a {
		@include link-style ($color_junior_add-2-dark, none, $color_junior_add-2-dark, underline);
	}*/

	// barvy: modra
	.bg-color-admin-junior-blue:not(.b-002b__block--banner) {

		.tag:not(.tag-multiline) {
			background-color: $color_junior_add-3-medium !important;
		}
	}
	&.page-junior-hp {

		.bg-color-admin-junior-blue {

			.b-004d__subblock {
				background-color: $color_junior_add-3-dark !important;
			}
		}
	}
	&.page-node {

		.bg-color-admin-junior-blue {

			.field-entity-show {
				background-color: $color_junior_add-3-medium !important;
			}

			.field-perex {
				background-color: $color_junior_add-3-light !important;
			}
		}
	}

	// barvy: zelena
	.bg-color-admin-junior-green:not(.b-002b__block--banner) {

		.tag:not(.tag-multiline) {
			background-color: $color_junior_add-4-medium !important;
		}
	}
	&.page-junior-hp {

		.bg-color-admin-junior-green {

			.b-004d__subblock {
				background-color: $color_junior_add-4-dark !important;
			}
		}
	}
	&.page-node {

		.bg-color-admin-junior-green {

			.field-entity-show {
				background-color: $color_junior_add-4-medium !important;
			}

			.field-perex {
				background-color: $color_junior_add-4-light !important;
			}
		}
	}

	// barvy: zluta
	.bg-color-admin-junior-yellow:not(.b-002b__block--banner) {

		.tag:not(.tag-multiline) {
			background-color: $color_junior_add-5-medium !important;
		}
	}
	&.page-junior-hp {

		.bg-color-admin-junior-yellow {

			.b-004d__subblock {
				background-color: $color_junior_add-5-dark !important;
			}
		}
	}
	&.page-node {

		.bg-color-admin-junior-yellow {

			.field-entity-show {
				background-color: $color_junior_add-5-medium !important;
			}

			.field-perex {
				background-color: $color_junior_add-5-light !important;
			}
		}
	}

	// barvy: oranzova
	.bg-color-admin-junior-orange:not(.b-002b__block--banner) {

		.tag:not(.tag-multiline) {
			background-color: $color_junior_add-6-medium !important;
		}
	}
	&.page-junior-hp {

		.bg-color-admin-junior-orange {

			.b-004d__subblock {
				background-color: $color_junior_add-6-dark !important;
			}
		}
	}
	&.page-node {

		.bg-color-admin-junior-orange {

			.field-entity-show {
				background-color: $color_junior_add-6-medium !important;
			}

			.field-perex {
				background-color: $color_junior_add-6-light!important;
			}
		}
	}

	// barvy: cervena
	.bg-color-admin-junior-red:not(.b-002b__block--banner) {

		.tag:not(.tag-multiline) {
			background-color: $color_junior_add-7-medium !important;
		}
	}
	&.page-junior-hp {

		.bg-color-admin-junior-red {

			.b-004d__subblock {
				background-color: $color_junior_add-7-dark !important;
			}
		}
	}
	&.page-node {

		.bg-color-admin-junior-red {

			.field-entity-show {
				background-color: $color_junior_add-7-medium !important;
			}

			.field-perex {
				background-color: $color_junior_add-7-light !important;
			}
		}
	}

	// barvy: ruzova
	.bg-color-admin-junior-pink:not(.b-002b__block--banner) {

		.tag:not(.tag-multiline) {
			background-color: $color_junior_add-8-medium !important;
		}
	}
	&.page-junior-hp {

		.bg-color-admin-junior-pink {

			.b-004d__subblock {
				background-color: $color_junior_add-8-dark !important;
			}
		}
	}
	&.page-node {

		.bg-color-admin-junior-pink {

			.field-entity-show {
				background-color: $color_junior_add-8-medium !important;
			}

			.field-perex {
				background-color: $color_junior_add-8-light !important;
			}
		}
	}

	// barvy: fialova
	.bg-color-admin-junior-purple:not(.b-002b__block--banner) {

		.tag:not(.tag-multiline) {
			background-color: $color_junior_add-9-medium !important;
		}
	}
	&.page-junior-hp {

		.bg-color-admin-junior-purple {

			.b-004d__subblock {
				background-color: $color_junior_add-9-dark !important;
			}
		}
	}
	&.page-node {

		.bg-color-admin-junior-purple {

			.field-entity-show {
				background-color: $color_junior_add-9-medium !important;
			}

			.field-perex {
				background-color: $color_junior_add-9-light !important;
			}
		}
	}

	// barvy: form
	form {
		background-color: $color_junior_add-2;
	}
	.view-filters > form {
		background-color: transparent;
	}

	// barvy: node
	&.page-node {

		&.node-type-article h1.article-type,
		&.node-type-page h1.page-type,
		&.node-type-recipe h1.recipe-type,
		&.page-node-o-poradu h1.show-type,
		&.node-type-atlas h1.article-type,
		&.node-type-profile .l-grid--8-1.item-first h1, // proc nema tridu jako u article?!
		&.node-type-serial .l-grid--12-1.item-first h1, // proc nema tridu jako u article?!
		&.node-type-serialpart .l-grid--12-1.item-first h1, // proc nema tridu jako u article?!
		&.node-type-event .l-grid--12-1.item-first h1, // proc nema tridu jako u article?! // fallback stare sablony
		&.node-type-event h1.event-title { // nova sablona

			color: $color_junior_main;
		}

		&.node-type-event .l-grid--8-1.item-first .content-1--7-2 .body-collapsible--collapsed {

			// gradient
			.body-collapsible-container::after {
				// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
				//background-image: linear-gradient(to top, $color_junior_add-1, transparent 50%);
				background-image: linear-gradient(to top, $color_junior_add-1, rgba(#ffffff, 0) 50%); // list: gradient color
			}
		}
	}



	/**
	 * zaoblene rohy
	 */

	// stanicni lista
	.b-015 {

		@include breakpoint($breakpoint_desktop) {
			border-radius: 0 0 $junior_b-015_radius $junior_b-015_radius;
		}

		.b-015__list-item--right {

			.b-015__launcher-visual {

				img {
					border-radius: $junior_b-015-launcher-visual-img_radius;
				}
			}

			.b-015__launcher-info {

				.b-015__launcher-info-list {

					.b-015__launcher-info-timing {

						.b-015__launcher-info-timing-progressbar {
							border-radius: $junior_b-015-launcher-info-timing-progressbar_radius;
							overflow: hidden;
						}
					}
				}
			}
		}
	}

	// stanicni nav
	.b-016__wrapper {

		.b-016 {

			.b-016__list {

				.b-016__list-item {

					a.expanded {
						border-radius: $junior_b-016-item_radius $junior_b-016-item_radius 0 0;
					}
				}

				.b-016__list-more {
					border-radius: $junior_b-016-item_radius;
					overflow: hidden;
				}
			}
		}
	}

	// navigace s titulkem
	.b-016__wrapper.b-016__wrapper--title-navigation {

		h1,
		h2 {

			@include breakpoint($breakpoint_desktop) {
				border-radius: $junior_b-016-radius 0 0 $junior_b-016-radius;
			}
		}

		.b-016 {

			@include breakpoint($breakpoint_desktop) {
				border-radius: 0 $junior_b-016_radius $junior_b-016_radius 0;
			}
		}
	}

	// navigace bez titulku
	.b-016__wrapper.b-016__wrapper--navigation {

		h1,
		h2 {
			display: none;
		}

		.b-016 {

			@include breakpoint($breakpoint_desktop) {
				border-radius: $junior_b-016_radius;
			}
		}
	}

	// footer
	.b-017 {
		
		@include breakpoint($breakpoint_desktop) {
			border-radius: $junior_b-015_radius;
		}
	}

	// main
	.main {

		@include breakpoint($breakpoint_desktop) {
			border-radius: $junior_main_radius $junior_main_radius 0 0;
		}
	}

	// button-box-flat
	.button-box-flat {
		border-radius: $junior_button_radius;
	}

	// badge
	.badge-audio-play,
	.badge-serial-play {
		border-radius: $junior_badge_radius;
	}

	// a-005
	.a-005 {

		.a-005__list {

			.a-005__list-item {

				.button-subscribe {
					border-radius: $junior_button_radius;
				}

				.button-subscribe:not(:hover) {}

				.button-subscribe:not(:hover):before {}
			}
		}
	}

	// b-001b
	.b-001b {

		.b-001b__list {
			border-radius: $junior_box_radius;
			overflow: hidden;
		}
	}

	// b-002g
	.b-002g {

		.b-002g__list {

			.b-002g__list-item {

				.b-002g__block {

					.image {
						border-radius: $junior_box_radius;
					}
				}
			}
		}
	}

	// b-004c
	.b-004c {

		//.b-004c__list,
		.b-004c__list--column-following {

			//.b-004c__list-item,
			.b-004c__list-item--column-following {

				.b-004__block.b-004c__block--image {

					.image {

						img {
							border-radius: $junior_box_radius;
						}
					}
				}
			}
		}
	}

	// b-004d
	.b-004d {

		.b-004d__list {

			.b-004d__list-item {

				.b-004__block {

					.image {

						img {
							border-radius: $junior_box_radius;
						}
					}
				}
			}
		}
	}

	// b-004f
	.b-004f {

		.b-004f__list {

			.b-004f__list-item {

				.image {

					img {
						border-radius: $junior_box_radius;
					}
				}
			}
		}
	}

	// b-004m
	.b-004m {

		.b-004m__list {

			.b-004m__list-item {

				.b-004__block {

					.image {

						img {
							border-radius: $junior_box_radius $junior_box_radius 0 0;
						}
					}

					h3 {
						border-radius: 0 0 $junior_box_radius $junior_box_radius;
					}
				}
			}
		}
	}

	// b-005
	.b-005 {

		.b-005__list {
			border-radius: $junior_box_radius;
			overflow: hidden;

			.b-005__list-item {

				.image {

					.tag {
						border-radius: 0 $junior_box_radius 0 0;
					}
				}
			}
		}
	}

	// b-006b
	.b-006b {

		.b-006b__list {

			.b-006b__list-item {

				.b-006b__subblock--player {
					border-radius: $junior_box_radius;
					overflow: hidden;
				}
			}
		}
	}

	// b-006c
	.b-006c {

		.b-006c__list {

			.b-006c__list-item {

				.b-006c__subblock--player {
					border-radius: $junior_box_radius;
					overflow: hidden;
				}
			}
		}
	}

	// b-006h
	.b-006h {

		.b-006h__dropdown {
			border-radius: $junior_box_radius $junior_box_radius;

			&.expanded {
				border-radius: $junior_box_radius $junior_box_radius 0 0;

				@include breakpoint($breakpoint_desktop) {
					border-radius: 0 $junior_box_radius 0 0;
				}
			}
		}

		.b-006__subsection {
			border-radius: 0 0 $junior_box_radius $junior_box_radius;;
			overflow: hidden;

			@include breakpoint($breakpoint_desktop) {
				border-radius: $junior_box_radius;
			}
		}
	}

	// b-008d
	.b-008d {

		.b-008d__list {

			.b-008d__list-item {

				.b-008d__block--image {

					.b-008d__image {
						border-radius: $junior_box_radius $junior_box_radius 0 0;
					}
				}

				.b-008d__block--description {
					border-radius: 0 0 $junior_box_radius $junior_box_radius;
				}
			}
		}
	}

	// b-008i
	.b-008i {

		.b-008i__list {

			.b-008i__list-item {

				.b-008i__block--image {

					.b-008i__image {
						border-radius: $junior_box_radius $junior_box_radius 0 0;
					}
				}

				.b-008i__block--description {
					border-radius: 0 0 $junior_box_radius $junior_box_radius;
				}
			}
		}
	}

	// b-018
	.b-018 {

		.b-018__subsection {
			border-radius: $junior_box_radius;

			.b-018__block.b-018__block--description {

				img {
					border-radius: $junior_box_radius;
				}
			}

			.button-box-flat--readmore {}

			.b-018__block.b-018__block--no-broadcast {}

			.broadcast-times {}
		}
	}

	// b-019a
	.b-019a {
		border-radius: $junior_button_radius;
	}

	// b-020
	.b-020 {

		.b-020__subsection {
			border-radius: $junior_box_radius;
		}
	}

	// b-022
	.b-022 {

		.b-022__list {

			.b-022__list-item {

				.b-022__block--image {

					.image {

						img {
							border-radius: $junior_box_radius;
						}
					}
				}
			}
		}
	}

	// b-022a
	.b-022a {

		.b-022a__list {

			.b-022a__list-item {

				.b-022__block--image {

					.image {

						img {
							border-radius: $junior_box_radius;
						}
					}
				}
			}
		}
	}

	// b-023a
	.b-023a {

		.b-023__list {

			.b-023a__list-item {

				border-radius: $junior_box_radius;
				overflow: hidden;

				.b-023__block {

					&.b-023__block--description {

						.b-023a__image {

							img {
								border-radius: $junior_box_radius;
							}
						}
					}
				}
			}
		}
	}

	// b-023d
	.b-023d {

		.b-023d__list {

			.b-023d__list-item {

				border-radius: $junior_box_radius;
				overflow: hidden;

				.b-023__block {

					&.b-023d__block--description {

						.b-023d__image {

							img {
								border-radius: $junior_box_radius;
							}
						}
					}
				}
			}
		}
	}

	// b-030b
	.b-030b {

		.b-038__navbar-wrapper {

			.b-038__navbar {
				border-radius: $junior_box_radius;

				.b-038__navbar-item {

					&.b-038__navbar-item--calendar {
						border-radius: $junior_box_radius;
					}
				}
			}

			&.fixed .b-038__navbar {
				border-radius: 0;
			}

			.hasDatepicker {

				.ui-datepicker {
					border-radius: $junior_box_radius;
				}
			}
		}

		.b-030__subsection--list {

			.b-030__list {

				.b-030__list-item {}

				.b-030__list-item:nth-child(2n) {
					border-radius: $junior_box_radius;
					overflow: hidden;
				}
			}
		}
	}

	// b-030c
	.b-030c {

		.b-030__subsection--list {

			.b-030__list {

				.b-030__list-item {}

				.b-030__list-item:nth-child(2n) {
					border-radius: $junior_box_radius;
				}
			}
		}
	}

	// b-031a-v2
	.b-031a-v2 {

		.b-031a-v2__subsection {
			border-radius: $junior_box_radius;
		}
	}

	// b-033
	.b-033a {
		border-radius: $junior_box_radius;
		overflow: hidden;

		.b-033a__subsection.b-033a__subsection--webcam {

			.b-033a__block--webcam {}

			.b-033a__block--listing {

				.b-033a__listing-column.b-033a__listing-column--now {

					.b-033a__subblock {

						.broadcast-timespan-progress {

							.progress-bar {
								border-radius: $junior_box_radius;
								overflow: hidden;
							}
						}
					}
				}

				.b-033a__listing-column.b-033a__listing-column--following {}
			}
		}
	}

	// b-036ac
	.b-036a {

		.b-036__list {

			.b-036__list-item {

				.b-036__block {

					&.b-036__block--first {

						.b-036a__image {

							img {
								border-radius: $junior_box_radius;
							}
						}
					}
				}
			}
		}
	}

	// b-038
	.b-038 {

		.b-038__navbar-wrapper {

			.b-038__navbar {
				border-radius: $junior_box_radius;

				.b-038__navbar-item {

					&.b-038__navbar-item--calendar {
						border-radius: $junior_box_radius;
					}
				}
			}

			&.fixed .b-038__navbar {
				border-radius: 0;
			}

			.datepicker.hasDatepicker {

				.ui-datepicker {
					border-radius: $junior_box_radius;
				}
			}
		}

		.listing {

			.b-038__list {

				.b-038__list-item {

					.b-038__block {

						.b-038__launcher-info-timing-progressbar {
							border-radius: $junior_box_radius;
							overflow: hidden;
						}

						.b-038__image {

							img {
								border-radius: $junior_box_radius;
							}
						}
					}
				}
			}
		}
	}

	// b-041h
	.b-041h {

		.b-041h__list {

			.b-041h__list-item {

				.b-041h__block--image {

					img {
						border-radius: $junior_box_radius;
					}
				}
			}
		}
	}

	// studio embed
	.b-junior-studio-embed {

		// iframe
		.video-wrapper__embed {
			border-radius: $junior_box_radius $junior_box_radius 0 0;
			overflow: hidden;
		}

		// button simple
		.button-simple {
			border-radius: 0 0 $junior_box_radius $junior_box_radius;
		}
	}

	// trifork
	.trifork {

		.trifork__list {

			.trifork__list-item:hover {
				border-radius: $junior_box_radius;
			}
		}
	}

	// pager
	.pager {

		.pager__item {

			&.pager__item--current {
				border-radius: $junior_button_radius;

				@include breakpoint($breakpoint_desktop) {
					border-radius: initial;
				}
			}

			&.pager__item--previous,
			&.pager__item--next,
			&.pager__item--first,
			&.pager__item--last {
				border-radius: $junior_button_radius;
			}
		}
	}

	// fonty: button-box-flat--loadmore
	.button-box-flat--loadmore {

		.button-box-flat__section {

			.button-box-flat__doubleline {

				&:first-of-type {
					border-radius: $junior_box_radius 0 0 $junior_box_radius;
				}

				&:last-of-type {
					border-radius: 0 $junior_box_radius $junior_box_radius 0;
				}
			}
		}
	}

	// form
	form {
		border-radius: $junior_box_radius;

		input.form-text,
		textarea.form-textarea {
			border-radius: $junior_box_radius;
		}

		input.form-submit {
			border-radius: $junior_button_radius;
		}

		.plupload_wrapper {
			border-radius: $junior_box_radius;
			overflow: hidden;
		}
	}

	// view-filters
	.view-filters {

		form {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-exposed-widget {

						.views-widget {

							.bef-checkboxes {

								.form-item {

									&.form-item-all {
										a {
											//border-radius: $junior_input_radius;

											@include breakpoint($breakpoint_desktop) {
												border-width: 4px;
											}
										}
									}

									input[type="checkbox"] + label {
										//border-radius: $junior_input_radius;

										@include breakpoint($breakpoint_desktop) {
											border-width: 4px;
										}
									}
								}
							}
						}

						&.views-submit-button-wrapper {

							.views-widget {
								border-radius: $junior_input_radius 0  0 $junior_input_radius;
								overflow: hidden;
							}

							.views-exposed-widget {

								&.views-submit-button {
									input.form-submit {
										border-radius: 0 $junior_input_radius $junior_input_radius 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// pseudo exposed filtr
	.radiobutton-filter {

		.radiobutton-filter__item {

			&:first-child label {
				border-radius: $junior_input_radius $junior_input_radius 0 0;

				@include breakpoint($breakpoint_desktop) {
						border-radius: $junior_input_radius 0 0 $junior_input_radius;
				}
			}

			&:last-child label {
				border-radius: 0 0 $junior_input_radius $junior_input_radius;

				@include breakpoint($breakpoint_desktop) {
					border-radius: 0 $junior_input_radius $junior_input_radius 0;
				}
			}
		}
	}

	// dfp
	/*.dfp-tag-wrapper {

		iframe {
			border-radius: $junior_box_radius;
		}
	}*/

	// iframe
	iframe {

		// zatim jen video embed
		&.video-wrapper__embed {
			border-radius: $junior_box_radius;
			overflow: hidden;
		}
	}

	// node
	&.page-node {

		// img-primary
		.field.field-image-primary {

			&:not(.has-main-audio) {

				img {
					@include breakpoint($breakpoint_desktop) {
						border-radius: $junior_box_radius;
					}
				}
			}

			&.has-main-audio {

				img {
					border-radius: $junior_box_radius $junior_box_radius 0 0;
				}
			}
		}

		// perex
		.field.field-perex {
			border-radius: $junior_box_radius;
		}

		// body
		.field.body {

			// img
			.field.field-image,
			.field-type-image,
			.asset-type-image {

				img {
					border-radius: $junior_box_radius;
				}
			}

			// iframe
			iframe {
				border-radius: $junior_box_radius;
			}

			// galerie
			.photogallery-asset-wrapper {

				.photoswipe-gallery {

					.field.field-image-photos.field-type-image {
						.cro-show-all {
							border-radius: $junior_box_radius;
						}
					}
				}
			}

			// ctete take
			.asset.a-001 {

				h2 {
					text-transform: none;
				}

				.a-001__block {
					border-radius: $junior_box_radius;
					overflow: hidden;
				}
			}

			// expirovane/budouci audio
			.asset-type-audio {

				.audio-rights-expired,
				.audio-rights-future {
					border-radius: $junior_box_radius;
				}
			}

			// factbox
			.factbox {
				border-radius: $junior_box_radius;
			}

			// souvisejici prispevky
			.related_articles {
				border-radius: $junior_box_radius;
			}
		}

		// priloha ke stazeni
		.node-attachment {

			.node_attachment {
				border-radius: $junior_box_radius;
				overflow: hidden;
			}
		}

		// box anotace, tabulka metadat v udalosti
		.event-annotation,
		.event-metadata {

			@include breakpoint($breakpoint_desktop) {
				border-radius: $junior_box_radius;
			}
		}
	}



	/**
	 * default
	 */

	// bg color
	&::before {
		content: "";

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		background-color: $color_junior_add-1;

		z-index: -3;
	 }

	 // stanicni lista
	.b-015 {

		.b-015__list-item--right {}

			// zmenseni vizualu lancheru
			.b-015__launcher-visual {

				// zde je klicove zachovani pomeru paddingu 2:3 odpovidajici pomeru vizualu 3:2
				width: ($b-015_launcher_height / 2 * 3) - $junior_b-015_launcher-visual_width-reduction;
				padding-top: $junior_b-015_launcher-visual_padding-top;

				img {}
			}

			.b-015__launcher-info {}

				.b-015__launcher-info-list {
					justify-content: space-between;
					padding-top: $junior_b-015_launcher-visual_padding-top;
					padding-bottom: $junior_b-015_launcher-visual_padding-top;
				}

					.b-015__launcher-info-title {}

					.b-015__launcher-info-timing {}

						.b-015__launcher-info-timing-progressbar {
							height: 0.5em;
						}

							.b-015__launcher-info-timing-progressbar-progress {
								height: 0.5em;
								background-color: $color_junior_add-1;
							}
	}

	// stanicni nav
	.b-016__wrapper {
		margin: 0;

		h1,
		h2 {
			background-color: $color_junior_main;
			color: $color_background-0;

			a {
				@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);
			}
		}

		h1:before,
		h2:before {
			background-color: $color_background-0;
		}

		.b-016 {
			background-color: $color_background-0;

			.b-016__list {
				padding-right: 25px; // bezpecna zona kvuli kulatym rohum bubliny

				.b-016__list-item {

					a {
						@include link-style ($color_junior_main, none, $color_background-0, none);
					}

					a.expanded,
					a.expanded:hover {
						background-color: $color_background-2;
						@include link-style ($color_junior_main, none, $color_junior_main, none);
					}

					a:hover/*,
					a.expanded:hover*/ {
						background-color: $color_junior_main;
					}
				}

				.b-016__list-more {
					background-color: $color_background-2;

					@include breakpoint($breakpoint_desktop) {
						margin-right: -1em; // bezpecna zona kvuli kulatym rohum bubliny
						padding: 1em; // bezpecne zony kvuli kulatym rokum bubliny
					}

					a:hover {
						background-color: $color_junior_main;
					}
				}
			}

		}
	}

	// a-005
	.a-005 {

		.a-005__list {

			.a-005__list-item {
				margin-right: 0.5em;
				margin-bottom: 0.5em;

				.button-subscribe {
					border: none;
				}

				.button-subscribe:not(:hover) {
					background-color: $color_background-0;
				}

				.button-subscribe:not(:hover):before {}
			}
		}
	}

	// b-001b
	.b-001b {

		.b-001b__list {

			.b-001b__list-item {
				background-color: $color_background-0;
			}
		}
	}

	// b-004c
	.b-004c {

		//.b-004c__list,
		.b-004c__list--column-following {

			//.b-004c__list-item,
			.b-004c__list-item--column-following {

				.b-004__block.b-004c__block--image {
					margin-bottom: 1.5em;

					@include breakpoint($breakpoint_desktop) {
						max-width: 14em;
					}
				}
			}
		}
	}

	// box b-005
	.b-005 {

		.b-005__list {

			.b-005__list-item {

				.image {

					.tag {
						background-color: $color_junior_main-tag_alpha-low;
					}
				}
			}
		}
	}

	// b-008d
	.b-008d {

		.b-008d__list {

			.b-008d__list-item {

				.b-008d__block--image {

					.b-008d__image {}
				}

				.b-008d__block--description {
					background-color: $color_background-0;

					.b-008d__subblock--content {

						.caption.caption--available-till {
							margin-bottom: 0.5em;

							.caption__icon {}

							.caption__label {}
						}

						h3 {

							a {}
						}

						a {

							p {}
						}
					}

					.b-008d__subblock--meta {

						.b-008d__meta-wrapper {}

						.b-008d__button-play-wrapper {

							.button-play {}
						}
					}
				}
			}
		}
	}

	// b-008i
	.b-008i {

		.b-008i__list {

			.b-008i__list-item {

				.b-008i__block--image {

					.b-008i__image {}
				}

				.b-008i__block--description {
					background-color: $color_background-0;

					.b-008i__subblock--content {

						h3 {

							a {}
						}

						a {

							p {}
						}
					}

					.b-008i__subblock--meta {

						.b-008i__meta-wrapper {}

						.b-008i__button-play-wrapper {

							.button-play {}
						}
					}
				}
			}
		}
	}

	// b-018
	.b-018 {

		.b-018__subsection {
			background-color: $color_background-0;
		}
	}

	// b-019a
	.b-019a {
		background-color: $color_background-1;

		.b-019__button--subscribe {

			.button-box-flat--podcast-dropdown {}
		}

		.b-019a__list {
			background-color: transparent;

			.b-019a__list-item {

				a::before {}
			}
		}
	}

	// b-020
	.b-020 {

		.b-020__subsection {
			background-color: $color_background-0;
		}
	}

	// b-022a
	.b-022a {

		.b-022a__list {

			.b-022a__list-item {

				.b-022__block--image {

					.image {}
				}

				.b-022a__block--description {

					.b-022a__description-row {
						display: none;

						.tag {}

						.b-022__timestamp {

							.b-022__timestamp--date {}
							.b-022__timestamp--time {}
						}
					}
				}
			}
		}
	}

	// b-023a
	.b-023a {

		.b-023__list {

			.b-023a__list-item {
				background-color: $color_background-0;
			}
		}
	}

	// b-023d
	.b-023d {

		.b-023d__list {

			.b-023d__list-item {
				background-color: $color_background-0;

				.b-023__block {

					&.b-023d__block--description {

						.b-023d__image {

							img {}

							.tag {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	// b-030b
	.b-030b {

		.b-030__subsection--list {

			.b-030__list {

				.b-030__list-item {}

				.b-030__list-item:nth-child(2n) {
					background-color: $color_background-0;
				}
			}

			.goToTopButton {
				display: inline-block;
				margin-top: 2em;
			}
		}
	}

	// b-030c
	.b-030c {

		.b-030__subsection--list {

			.b-030__list {

				.b-030__list-item {}

				.b-030__list-item:nth-child(2n) {
					background-color: $color_background-0;
				}
			}
		}
	}

	// b-038__navbar
	.b-030b,
	.b-038 {

		.b-038__navbar-wrapper {

			.b-038__navbar {

				.b-038__navbar-item {

					&.b-038__navbar-item--previous,
					&.b-038__navbar-item--next {
						a::after {
							background-image: $file_icon_arrow_single-junior_west_colored-white;
						}
					}

					&.b-038__navbar-item--calendar {}
				}
			}
		}
	}

	// b-038
	.b-038 {

		.listing {

			.b-038__list {

				.b-038__list-item {
					border-color: $color_background-6_alpha-mid;

					.b-038__block {

						&.b-038__block--description {

							.b-038__actions {

								.button-play {

									@include link-style($color_junior_add-3-dark, none, $color_junior_add-2-light, underline);
								}
							}
						}
					}
				}

				.b-038__list-item--present {
					background-color: $color_background-0;

					.b-038__block {

						.b-038__launcher-info-timing-progressbar {
							background-color: $color_background-3;
						}

						.b-038__image {}
					}
				}
			}
		}
	}

	// b-031a-v2
	.b-031a-v2 {

		.b-031a-v2__subsection {

			.b-031a-v2__block--first {}

			.b-031a-v2__block--second {
				background-color: $color_background-0;
			}
		}

		&.b-031a-v2--inverted {

				.b-031a-v2__subsection {

					.b-031a-v2__block--second {
						background-color: $color_junior_main_darker;
					}
				}
		}
	}

	// b-033
	.b-033a {

		.b-033a__subsection.b-033a__subsection--webcam {

			background-color: $color_background-0;
		}
	}

	// b-041h
	.b-041h {

		.b-041h__list {

			.b-041h__list-item {

				.b-041h__block--image {

					.image {

						.button-play {
							background-image: $file_button_play_launcher_colored-fff3dd;
							transition: opacity 0.2s;

							&:hover {
								opacity: 1;
							}

							&::after {
								background-image: $file_button_play_launcher_colored-fff3dd;
							}
						}
					}
				}
			}
		}
	}

	// b-110
	.b-110 {

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: -1.5em;
		}

		.b-110__section {

			.b-110__block--calendar {

				.b-110__calendar-number {
					top: 10px;

					@include breakpoint($breakpoint_desktop) {
						top: 5px;
					}
				}
			}

			.b-110__block--description {
				border-top: 1px solid $color_background-6_alpha-mid;
				border-bottom: 1px solid $color_background-6_alpha-mid;

				.b-110__description-sublock {

					.b-110__calendar-name {
						color: $color_junior_main;
					}

					.button-simple {
						@include link-style($color_junior_main, none, $color_junior_main, underline);

						.button-simple__label {

							.button-simple__label-nest {}
						}

						.button-simple__icon--arrow-double-east {
							background-image: $file_icon_arrow_single-junior_west_colored-0a9ae4;
							background-position: left 5px;

							margin-left: 0.25em;

							width: 0.45em;
						}
					}
				}
			}
		}
	}

	// studio embed
	.b-junior-studio-embed {

		// iframe
		.video-wrapper__embed {
		}

		// button simple
		.button-simple {
			display: flex;
			justify-content: flex-end;

			padding: 0.25em 0.75em;

			background-color: $color_junior_main;

			.button-simple__label {
				flex-grow: 0;
			}

			.button-simple__icon--arrow-double-east {
				//background-image: $file_icon_arrow_double_east_colored-white;
				background-image: $file_icon_arrow_single-junior_west_colored-white;
				background-size: 5px;
				background-position: left center;

				width: 5px;
				height: 10px;
			}
		}
	}

	// trifork
	.trifork {
		background: transparent; // prebiji puvodni gradient

		.trifork__list {

			.trifork__list-item {

				a {

					h3 {}

					p {}

					.trifork__button-readmore {

						.button-box-flat--readmore {

							.button-box-flat__label {

								.button-box-flat__label-nest {
									color: $color_text_accent_inverted;
								}
							}

							.button-box-flat__icon--arrow-double-east {}
						}
					}
				}
			}
		}
	}

	// pager
	.pager {

		.pager__item {

			&.pager__item--current {}

			&.pager__item--previous,
			&.pager__item--next,
			&.pager__item--first,
			&.pager__item--last {

				a::after {
					top: 1em;
					left: 1em;
					width: 1.5em;
					height: 1.5em;
				}
			}
		}
	}

	// view-filters
	.view-filters {

		form {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-exposed-widget {

						&.views-submit-button-wrapper {

							.views-widget {

								.form-item {
									&.form-type-textfield {

										input.form-text {
											background-color: $color_background-0;
											padding-left: 0.75em;

											@include breakpoint($breakpoint_desktop) {
												padding-left: 1em;
											}
										}
									}
								}
							}

							.views-exposed-widget {

								&.views-submit-button {
									input.form-submit {
										background-color: $color_background-0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// page
	.page {
		background-color: $color_junior_add-1;

		@include breakpoint($breakpoint_desktop) {
			background-color: transparent;
		}
	}

		// main
		.main {
			background-color: $color_junior_add-1;
		}

			// asset & box h2
			.asset,
			.box {

				h2 {
					color: $color_junior_main;
					@include adjust-font-size-to($junior_fontsize_box-heading, 1.25);
					text-transform: none;

					a {
						@include link-style($color_junior_main, none, $color_junior_main, underline);
					}

					@include breakpoint($breakpoint_desktop) {
						@include adjust-font-size-to($junior_fontsize_box-heading_desktop, 1.3334);
					}
				}
			}

			// box
			.box {

				h2 {
					text-transform: none;
				}

				h3 {}

				p {}
			}

				// button-box-flat
				.button-box-flat {

					.button-box-flat__label {
						border-right: none;
						padding: 0.25em 0.5em;

						.button-box-flat__label-nest {
							text-transform: uppercase;
						}
					}

					.button-box-flat__icon--arrow-double-east {
						margin-left: 0;
					}

					&:not(.button-box-flat-small) .button-box-flat__icon--arrow-double-east {
						margin-right: 0.5em;
					}
				}

				// button-box-flat, button-box-flat--readmore, button-box-flat--transparent-negative
				.button-box-flat,
				.button-box-flat--readmore,
				.button-box-flat--transparent-negative,
				.button-box-flat.button-box-flat--readmore.button-box-flat--transparent-negative { // + silny selektor
					//justify-content: center;

					border: none;
					background-color: $color_junior_add-7-dark;

					@include link-style($color_text_link_inverted, none, $color_text_link_inverted, underline);

					.button-box-flat__label {
						//flex-grow: initial;

						.button-box-flat__label-nest {}
					}

					.button-box-flat__icon--arrow-double-east {
						//background-image: $file_icon_arrow_double_east_colored-white;
						background-image: $file_icon_arrow_single-junior_west_colored-white;
						width: 6px;
					}

					&.button-box-flat-small {

						.button-box-flat__label {
							padding: 0.25em 0.5em 0.25em 0.25em;
						}
					}
				}

				// button-box-flat--podcast-dropdown
				.button-box-flat--podcast-dropdown {
					border: none;
					background-color: $color_background-0;

					@include link-style($color_decoration_main, none, $color_decoration_main, underline);

					.button-box-flat__label {

						.button-box-flat__label-nest {}
					}

					.button-box-flat__icon--arrow-single-south {}
				}

				// infinity scroll button
				.button-box-flat--loadmore {

					.button-box-flat__label {}

					.button-box-flat__section {

						.button-box-flat__doubleline {
							border: none;
							background-color: $color_background-6_alpha-mid;
							height: 3px;
						}

						.button.button-box-flat {
							width: 3em;
							height: 3em;

							padding: 1em;

							@include breakpoint($breakpoint_desktop) {
								width: 2em;
								height: 2em;

								padding: 1em;
							}

								.button-box-flat__icon {}
						}
					}
				}

				// button-play
				.button-play {
					background-image: $file_button_play_colored-0a9ae4;
					transition: opacity .2s;

					&:hover {
						opacity: 0.85;
					}

					&::after {
						background-image: $file_button_play_colored-0a9ae4;
					}
				}

				// tag
				.tag:not(.tag-multiline) {
					padding: 0.25em 0.5em;

					text-transform: uppercase;

					color: $color_text_accent;
					background-color: $color_junior_add-3-medium;

					span {
						color: $color_text_accent;
					}

					a {
						@include link-style ($color_text_accent, none, $color_text_accent, underline);

						span {
							color: $color_text_accent;
						}
					}
				}

				// badge
				.badge-audio-play,
				.badge-serial-play {
					padding: 3px 8px;
					background-color: $color_junior_badge_alpha-low;
					@include link-style($color_junior_main, none, $color_junior_main, none);
				}

				.badge-audio-play {
					&:after {
						background-image: $file_icon_badge_audio-play_colored-0a9ae4;
					}
				}

				.badge-serial-play {
					&:after {
						background-image: $file_icon_badge_serial-play_colored-0a9ae4;
					}
				}


	// node
	&.page-node {

		.content-1--1-1 {
			display: none; // soc. buttony nechceme, tak ani region
		}

		&.node-type-article,
		&.node-type-page,
		&.node-type-recipe,
		&.page-node-o-poradu,
		&.node-type-atlas {

			// modified, show, top social buttons
			.content-1-1 {
				display: initial;
				flex-direction: initial;
				justify-content: initial;

				min-height: initial;
			}

			// primary image/audio
			.content-1-2 {
			}

			// vertical soc. buttons, perex, body
			.content-1-3,
			.content-1--7-2 {

				.field.field-perex,
				.field.body,
				.file-audio,
				.b-041k__anchor,
				.asset.a-005,
				.node-block--authors,
				.b-022 {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}
			}

			// main audio anchor, authors, bottom soc. buttons
			.content-1-4,
			.content-2--8-1 {

				.file-audio {

					@include breakpoint($breakpoint_desktop) {
						//margin-left: 1.875rem; // 6.25rem - 4,375rem
						margin-left: 0;
					}
				}

				.b-041k__anchor {

					@include breakpoint($breakpoint_desktop) {
						//margin-left: 1.875rem; // 6.25rem - 4,375rem
						margin-left: 0;
					}
				}

				.asset.a-005 {

					@include breakpoint($breakpoint_desktop) {
						//margin-left: 1.875rem; // 6.25rem - 4,375rem
						margin-left: 0;
					}
				}

				.node-block--authors {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

				.a-005 {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

				.b-022 {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}
			}
		}

		&.node-type-serial,
		&.node-type-serialpart,
		&.node-type-event {

			// modified, show, top social buttons
			.content-1-0 {
				display: initial;
				flex-direction: initial;
				justify-content: initial;

				min-height: initial;
			}

			// vertical soc. buttons, primary image/audio, serial pager, serial link, perex, body
			.content-1-1,
			.content-1--7-2 {

				.field.field-image-primary {

					@include breakpoint($breakpoint_desktop) {
						padding-left: 0;
					}
				}

				.field.field-perex,
				.field.body {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

				.file-audio {

					@include breakpoint($breakpoint_desktop) {
						//margin-left: 1.875rem; // 6.25rem - 4,375rem
						margin-left: 0;
					}
				}

				.b-041k__anchor {

					@include breakpoint($breakpoint_desktop) {
						//margin-left: 1.875rem; // 6.25rem - 4,375rem
						margin-left: 0;
					}
				}

				.node-block--authors {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

				.a-004b {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

				.a-005 {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

				.b-022 {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}

				.event-annotation,
				.event-metadata {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}
			}

			// main audio anchor
			.content-1-2 {
			}

			// authors, bottom soc. buttons
			.content-1-3 {
			}


			// ...
			.content-1-4 {
			}
		}

		&.node-type-profile {

			// modified, show, top social buttons
			.content-1-0 {
				display: initial;
				flex-direction: initial;
				justify-content: initial;

				min-height: initial;
			}

			// h1, primary image/audio, field-employment-web
			.content-1-1 {

				.field.field-employment-web,
				.field.field-term-positions {
					border-bottom: 1px solid $color_background-6_alpha-mid;
				}

				.field.field-about-briefly {
				}

				.field.field-image-primary {

					@include breakpoint($breakpoint_desktop) {
						padding-left: 0;
					}
				}
			}

			// perex, body
			.content-1-2 {

				.field.field-perex,
				.field.body {

					@include breakpoint($breakpoint_desktop) {
						margin-left: 0;
					}
				}
			}
		}

		// soc. buttons
		.block-social-buttons--top,
		.block-social-buttons--bottom,
		.block-social-buttons-vertical {
			display: none;
		}

		// date of modified
		.field-modified {
			width: auto;
		}

		// show
		//.field-entity-show-wrapper {
		.field-entity-show {
				background-color: $color_junior_add-3-medium; // default
				color: $color_junior_add-2-dark;

				width: auto;

				padding: 0.5em 0.5em;

				text-transform: uppercase;

				border: none;

				a {
						@include link-style ($color_junior_add-2-dark, none, $color_junior_add-2-dark, none);
				}
			}
		//}

		// perex
		.field.field-perex {
			background-color: $color_junior_add-3-light; // default

			padding-top: 0.1px; // margin collapsing
			padding-bottom: 0.1px; // margin collapsing
			margin-top: 1.5em;
			margin-bottom: 1.5em;

			@include breakpoint($breakpoint_desktop) {
				margin-top: 1.5em;
				margin-bottom: 1.5em;
			}

			p {
				margin-left: 1.25rem;
				margin-right: 1.25rem;

				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}

		// body
		.field.body {

			.wysiwyg-asset {

				&.asset-type-audio {

					.audio-rights-expired,
					.audio-rights-future {
						background-color: $color_background-0;
					}
				}
			}
		}

		.related_articles {
			background-color: $color_background-0;
		}

		.node-attachment {

			.node_attachment {
				border-color: $color_background-6_alpha-mid;

				.node_attachment__icon {
					border-color: $color_background-6_alpha-mid;
				}
			}
		}
	}

}
