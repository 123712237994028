//
// B-001a Styles
//
// markup tohoto boxu je stejny (velmi podobny) s b001b
// zmeny jsou na urovni stylu
// promenne jsou sdilene
//



$b-001_item_padding_horizontal: 0.75em;

$b-001_item_fontsize_mobile: 20px;
$b-001_item_fontsize_desktop: 20px;



.b-001a {
}
	
	.b-001a__title {
		// v plabu neni, ale v drupalu tato trida pritomna
	}
	
	.b-001a__list {
	}
		
		.b-001a__list-item {
			
			&:nth-child(2n+1) {
				background: $color_background-2;
			}
			&:nth-child(2n) {
				background: $color_background-3;
			}
		}
			
			.b-001a__link {
				
				display: block;
				width: 100%;
				
				padding: 0.8em $b-001_item_padding_horizontal;
				
				@include adjust-font-size-to($b-001_item_fontsize_mobile);
				
				@include breakpoint($breakpoint_desktop) {
					
					padding: 0.25em $b-001_item_padding_horizontal;
					
					@include adjust-font-size-to($b-001_item_fontsize_desktop);
				}
			}





// vizualni debug - plab only
.patternlab .b-001a {
	
	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}