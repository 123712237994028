//
// tabulky
//

$table-padding: 4px 12px;


.html-embed table,
.patternlab table {
	border: 1px solid $color_divider;
	table-collapse: collapse;
	// text uvozujici tabulku
	caption {
		padding: 4px 0;
	}
	thead {
		// bunka zahlavi tabulky
		th {
			padding: $table-padding;
			background-color: $color_background-2;
			border: 1px solid $color_divider;
			@include typography_font(medium);
			font-size: $base-font-size;
		}
	}
	tbody {
		// radek
		tr {

		}
		// bunka tabulky
		td {
			padding: $table-padding;
			border: 1px solid $color_divider;
			@include typography_font(regular);
		}
	}
}
