//
// B-037 Styles
//



.b-037 {
}

	.b-037__subsection {
		padding: 0.75em;
		background-color: $color_background-2;
	}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-037__subsection p {
			color: $color_text_paragraph_node;
		}

		.b-037__subsection p:first-of-type {
			margin-top: 0;
		}

		.b-037__subsection p:last-of-type {
			margin-bottom: 0;
		}

		// image metadata
		.b-037__subsection .img-metadata {
			margin-top: 0.25em;
			line-height: 1.3;
		}


		.b-037__subsection .img-metadata__title {
			color: $color_text_paragraph;
		}

		.b-037__subsection .img-metadata__spacer {
		}

		.b-037__subsection .img-metadata__spacer--pipe {
		}

		.b-037__subsection .img-metadata__spacer--comma {
		}

		.b-037__subsection  .img-metadata__author {
		}

		.b-037__subsection  .img-metadata__source {
		}

		.b-037__subsection  .img-metadata__licence {
		}





// vizualni debug - plab only
.patternlab {

	.b-037 {

		@include breakpoint($breakpoint_desktop) {
			max-width: 400px;
		}
	}
}
