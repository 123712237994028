//
// Ostrava - HP
//



.ostrava.page-ostrava-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}