//
// B-032a Styles
//



$b-032a_button-close_size: 4.3334em;



.b-032a {

	position: relative; // pro ucely pozicovani zaviraciho tlacitka

	margin: 0; // reset default marginu obecneho boxu
	padding: 1.25em;

	box-shadow: $shadow_popup;
}
// vizualni debug - plab only
// tenhle box stejne neni urceny na 100% sirku
.patternlab .b-032a {

	@include breakpoint($breakpoint_desktop) {

		max-width: 60%;
	}
}

	// vnoreny atom a tedy ne pres specifickou tridu
	.b-032a > h2 {

		margin-top: 0;
		padding-left: 0;

		padding-right: $b-032a_button-close_size / 2;

		color: $color_text_paragraph;
	}

	// vnoreny atom a tedy ne pres specifickou tridu
	.b-032a .button-popup--close {

		position: absolute;
		right: 0;
		top: 0;
	}

		.b-032a .button-popup__label {

			// opticka zmena velikosti k specifickemu zarovnani s nadpisem boxu H2
			width: $b-032a_button-close_size;
			height: $b-032a_button-close_size;
		}


	.b-032a__subsection {
	}

		// vnoreny atom a tedy ne pres specifickou tridu
		.b-032a__subsection > h3 {

			margin-bottom: 0.5em;
			margin-top: 1em;
		}

		.b-032a__block--share {
		}

			// vnoreny atom a tedy ne pres specifickou tridu
			.b-032a__block--share > h4 {

				margin-top: 1.5em;
				margin-bottom: 0.5em;
			}

			.b-032a__share-buttons {

				display: flex;
				flex-direction: row;
			}

			// vnoreny atom a tedy ne pres specifickou tridu
			.b-032a__share-buttons > .button-social {

				width: 3.25em;
				height: 3.25em;

				margin-right: 0.5em;

				@include breakpoint($breakpoint_desktop) {

					width: 2.75em;
					height: 2.75em;

					margin-right: 0.6667em;
				}
			}
			.b-032a__share-buttons > .button-social:last-child {
				margin-right: 0em;
			}


		.b-032a__block--embed {
		}

			// vnoreny atom a tedy ne pres specifickou tridu
			.b-032a__block--embed > h4 {
				margin-top: 1.5em;
				margin-bottom: 0.5em;
			}
