//
// Hledani - Button Popup Styles
//


#{$hledani_selector_body} {

	// zaviraci tlacitko X
	.button-popup--close {

		.button-popup__label {

			width: 31px;
			height: 31px;

			background-color: $color_background-5;
			background-image: $file_icon_close_colored-white;
			background-size: 8px;

			border-radius: 50%;
		}
	}

	.button-popup--close:hover {

		.button-popup__label {
			background-color: $color_background-6;
			transition: background-color 0.2s;
		}
	}



	// otviraci tlacitko +
	.button-popup--open {

		.button-popup__label {

			width: 31px;
			height: 31px;

			background-color: $color_background-5;
			background-image: $file_icon_close_colored-white;
			background-size: 8px;

			border-radius: 50%;
		}
	}

	.button-popup--open:hover {

		.button-popup__label {
			background-color: $color_background-6;
			transition: background-color 0.2s;
		}
	}


}
