//
// Shoutbox
//



/* default */
#shoutbox {
	position: relative;
	//z-index: 99;

	.shoutbox-underlay {
		background: rgba(0, 0, 0, 0.75) none repeat scroll 0 0;
	}

	.shoutbox-title {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		
		padding: 10px 10px;
		
		font-size: 20px;
		line-height: 26px;

		background-color: $color_background-9;
		color: $color_text_accent_inverted;

		span {
			flex-grow: 1;
			display: inline-block;
			padding-right: 20px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.button {
			flex-shrink: 0;
		}
	}

	.shoutbox-title .button {
		flex-shrink: 0; 
		
		background-image: $file_icon_upscale_colored-white;
		background-size: auto auto;
		background-position: 0 0;
	}

	.shoutbox-title .button.contract {
		background-image: $file_icon_close_colored-white;
		background-size: auto auto;
	}

	.new-messages,
	.pagination button {
		width: auto;
	}

	.login-header {
		background-color: $color_background-5;
		color: $color_text_accent_inverted;
	}

	.messages {
		width: auto;
		padding: 10px;
		margin: 0;
		border: none;
	}

	.text-wrapper {
		font-size: 14px;
		line-height: 1.2em;
	}

	.author-name {
		white-space: unset;
	}

	.shoutbox-wrapper .shoutbox-widget {
		background: $color_background-2;
		padding-bottom: 10px;
	}

	.shoutbox-wrapper {
		transition: width .5s ease, margin-left .5s ease, margin-right .5s ease,
		top .5s ease, bottom .5s ease, left .5s ease, right .5s ease, position .5s ease;
	}

	// zavreny
	.shoutbox-wrapper.normal {
		width: 100%;
		height: 46px;

		overflow: hidden;

		@include breakpoint($breakpoint_desktop) {
			height: auto;
			overflow: unset;
		}

		.messages {
			max-width: 100%;
			max-height: 0;

			@include breakpoint($breakpoint_desktop) {
				max-height: 850px;
			}
		}
	}

	// otevreny
	.shoutbox-wrapper.wide {
		width: 100%;
		margin: 0 !important;

		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		z-index: 100; // nektere fixed nav maji 99 a 98 bg

		@include breakpoint($breakpoint_desktop) {
			width: 200%;
			margin: 0 0 0 -100% !important;

			position: unset;
			top: unset;
			bottom: unset;
			left: unset;
			right: unset;

			overflow: unset;
		}

		.shoutbox-widget {
			width: 100%;
			height: 100%;
		}

		.messages {
			max-width: 100%;
			max-height: calc(100% - 80px); // 100% viewportu - vyska hlavicky

			@include breakpoint($breakpoint_desktop) {
				max-height: 850px;
			}
		}
	}

	.shoutbox-wrapper .shout .admin .author .name {
		padding-right: 2em;
	}

}
// clearfix
#shoutbox::after {
	clear: both;
	content: "";
	display: table;
}
#shoutbox.expanded { // classu expanded dava e7.behaviors.js
	@include breakpoint($breakpoint_desktop) {
		position: relative;
		z-index: 99;
	}
}


// vltava
.vltava {
	#shoutbox {
		.login-header {
			background-color: $color_vltava_main;
			color: $color_text_accent_inverted;
		}

		.shoutbox-wrapper .shout .admin .author .name {
			color: $color_vltava_main;
		}

		.shoutbox-wrapper .shout .admin .author {
			background-image: $file_logo_R_vltava;
		}

		.shoutbox-wrapper .message-admin .button.action-reject {
			color: $color_vltava_main;
		}

		.shoutbox-wrapper .message-admin .button.action-hide {
			color: $color_vltava_main;
		}

		.shoutbox-wrapper .message.editor.not-published .message-root {
			border: 2px solid $color_vltava_main;
		}
	}
}



// plus
.plus {
	#shoutbox {
		.login-header {
			background-color: $color_plus_main;
			color: $color_text_accent_inverted;
		}

		.shoutbox-wrapper .shout .admin .author .name {
			color: $color_plus_main;
		}

		.shoutbox-wrapper .shout .admin .author {
			background-image: $file_logo_R_plus;
		}

		.shoutbox-wrapper .message-admin .button.action-reject {
			color: $color_plus_main;
		}

		.shoutbox-wrapper .message-admin .button.action-hide {
			color: $color_plus_main;
		}

		.shoutbox-wrapper .message.editor.not-published .message-root {
			border: 2px solid $color_plus_main;
		}
	}
}



// wave
.wave {
	#shoutbox {
		.login-header {
			background-color: $color_wave_main;
			color: $color_text_accent_inverted;
		}

		.shoutbox-wrapper .shout .admin .author .name {
			color: $color_wave_main;
		}

		.shoutbox-wrapper .shout .admin .author {
			background-image: $file_logo_R_wave;
			
			.name {
				padding-right: 2em;
			}
		}

		.shoutbox-wrapper .message-admin .button.action-reject {
			color: $color_wave_main;
		}

		.shoutbox-wrapper .message-admin .button.action-hide {
			color: $color_wave_main;
		}

		.shoutbox-wrapper .message.editor.not-published .message-root {
			border: 2px solid $color_wave_main;
		}
	}
}



// digital
.digital {
	#shoutbox {
		.login-header {
			background-color: $color_digital_main;
			color: $color_text_accent_inverted;
		}

		.shoutbox-wrapper .shout .admin .author .name {
			color: $color_digital_main;
		}

		.shoutbox-wrapper .shout .admin .author {
			background-image: $file_logo_R_digital;
		}

		.shoutbox-wrapper .message-admin .button.action-reject {
			color: $color_digital_main;
		}

		.shoutbox-wrapper .message-admin .button.action-hide {
			color: $color_digital_main;
		}

		.shoutbox-wrapper .message.editor.not-published .message-root {
			border: 2px solid $color_digital_main;
		}
	}
}



// regiony - liberec, hradec, pardubice
.brno,
.budejovice,
.hradec,
.liberec,
.pardubice,
.plzen,
.olomouc,
.ostrava,
.sever,
.vary,
.vysocina,
.zlin {
	#shoutbox {
		.login-header {
			background-color: $color_region_main;
			color: $color_text_accent_inverted;
		}
		.shoutbox-wrapper .shout .admin .author .name {
			color: $color_region_main;
		}

		.shoutbox-wrapper .shout .admin .author {
			background-image: $file_logo_R_region;
		}

		.shoutbox-wrapper .shout .admin .author .name {
			color: $color_region_main;
		}

		.shoutbox-wrapper .message-admin .button.action-reject {
			color: $color_region_main;
		}

		.shoutbox-wrapper .message-admin .button.action-hide {
			color: $color_region_main;
		}

		.shoutbox-wrapper .message.editor.not-published .message-root {
			border: 2px solid $color_region_main;
		}
	}
}
