//
// B-002d Styles
//



.b-002d {
}

	.b-002d__list {
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint_desktop) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

		.b-002d__list-item {
			margin-bottom: 2em;

			// IE
			min-height: 1px;

			@include breakpoint($breakpoint_desktop) {
				// trik jak 4 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 20%; // toto neni ctvrtina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do tretin
				max-width: 24%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(25% - 1.5em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 1.5em? protoze je to 2 * 3 / 4 viz (*)

				margin-right: 2em; // (*)
			}
		}
		.b-002d__list-item:nth-child(4n) {

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}
		.b-002d__list-item:last-child {
			margin-bottom: 0;

			@include breakpoint($breakpoint_desktop) {
				margin-right: 0;
			}
		}

			.b-002d__block {
			}

				.b-002d__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002d__image .tag {
						position: absolute;
						bottom: 0;
						left: 10px;

						max-inline-size: calc(100% - 20px);
					}

					// ikonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002d__image .badge {
						position: absolute;
						top: 0;
						right: 0;
					}

					// buttonove priznaky
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-002d__image .button-video-play-box {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}


			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002d__list-item h3 {
				margin: 0;
				padding: 0.5em 0.5em 0 0.5em;

				@include breakpoint($breakpoint_desktop) {
					padding: 0.5em 0 0 0;
				}
			}
