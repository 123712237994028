//
// Digital - B-040a Styles
//



.digital.page-dobadabova {

	.b-040a {

		h2 {
			text-transform: uppercase;
		}

		.b-040a__list {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.b-040a__list-item {

				// trik jak 2 itemy radku + jejich margin roztahnout na 100%
				// ale ne pokud jich neni na radek plny pocet
				flex-basis: 49%; // toto neni polovina, ale vychozi velikost ktera se roztahne
				flex-grow: 1; // roztahne se pomoci grow do petin
				max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
				max-width: calc(50% - 0.75em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
				// proc - 0.75em? protoze je to 1.5 * 1 / 2 viz (*)

				margin-right: 1.5em; // (*)
				margin-bottom: 2em;

				&:nth-child(2n) {

					margin-right: 0;
				}

				> p,
				> h3 {
					font-size: 16px;
					line-height: 1.3;
					@include typography_font(medium);
					text-align: center;
					text-transform: uppercase;

					//color: $color_background-9;
					color: $color_decoration_main;

					margin-top: 0;
					margin-bottom: 0;
				}

				@include breakpoint($breakpoint_desktop) {
					// trik jak 6 itemu radku + jejich margin roztahnout na 100%
					// ale ne pokud jich neni na radek plny pocet
					flex-basis: 16%; // toto neni petina, ale vychozi velikost ktera se roztahne
					flex-grow: 1; // roztahne se pomoci grow do petin
					max-width: 16%; // (fallback pro no-calc pro nasledujici radek)
					max-width: calc(16.66% - 1.25em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
					// proc - 1.25em? protoze je to 1.5 * 5 / 6 viz (*)

					margin-right: 1.5em; // (*)
					margin-bottom: 2em;

					&:nth-child(2n) {

						margin-right: 1.5em;
					}

					&:nth-child(6n) {

						margin-right: 0;
					}
				}

				.b-040a__icon-container {
					position: relative;
					padding-top: 100%; /* 1:1 Aspect Ratio */
					overflow: hidden;

					width: 100%;
					height: auto;

					.b-040a__icon {
						background-position: center;
						background-repeat: no-repeat;

						position: absolute;
						top: 0;
						left: 0;

						width: 100%;
						height: 100%;
					}
				}
			}

			.b-040a__list-item--sound {
				.b-040a__icon {
					background-image: $file_icon_dab-sound_colored-main;
					background-size: auto 45%;
				}
			}
			.b-040a__list-item--selection {
				.b-040a__icon {
					background-image: $file_icon_dab-selection_colored-main;
					background-size: auto 50%;
				}
			}
			.b-040a__list-item--display {
				.b-040a__icon {
					background-image: $file_icon_dab-display_colored-main;
					background-size: auto 35%;
				}
			}
			.b-040a__list-item--signal {
				.b-040a__icon {
					background-image: $file_icon_dab-signal_colored-main;
					background-size: auto 40%;
				}
			}
			.b-040a__list-item--energy {
				.b-040a__icon {
					background-image: $file_icon_dab-energy_colored-main;
					background-size: auto 50%;
				}
			}
			.b-040a__list-item--cover {
				.b-040a__icon {
					background-image: $file_icon_dab-cover_colored-main;
					background-size: auto 40%;
				}
			}
		}
	}



	// pokud je v aside ma pravidla mobilu
	.l-grid--4-1,
	.l-grid--4-9 {
		.b-040a {
			.b-040a__list {
				.b-040a__list-item {

					// desktop s pravidly mobilu
					@include breakpoint($breakpoint_desktop) {

						// trik jak 2 itemy radku + jejich margin roztahnout na 100%
						// ale ne pokud jich neni na radek plny pocet
						flex-basis: 49%; // toto neni polovina, ale vychozi velikost ktera se roztahne
						flex-grow: 1; // roztahne se pomoci grow do petin
						max-width: 49%; // (fallback pro no-calc pro nasledujici radek)
						max-width: calc(50% - 0.75em); // ale ne uplne, tam kde je volne misto zafunguje tahle zarazka
						// proc - 0.75em? protoze je to 1.5 * 1 / 2 viz (*)

						margin-right: 1.5em; // (*)
						margin-bottom: 2em;

						&:nth-child(2n) {

							margin-right: 0;
						}
					}
				}
			}
		}
	}

}