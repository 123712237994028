//
// Cesty za 1968vodou B-016 Styles
//



//$b-016_color_submenu_overlay: rgba(0, 0, 0, 0.25);
//$b-016_menu-bar-height_mobile: auto; // bylo 2.75em, proc?
//$b-016_menu-bar-height_desktop: auto;

$cestyzavodou_b-016_menu-bar-height_desktop: 140px;
$cestyzavodou_b-016_menu-bar-height_mobile: 75px;
$cestyzavodou_b-016_menu-bar-background-color: $color_cestyzavodou_main;

$cestyzavodou_b-016_menu-item-vertical-padding_desktop: 58px;
$cestyzavodou_b-016_menu-item-vertical-padding_mobile: 1.6em;

$cestyzavodou_b-016_menu-logo-size_desktop: 75px;
$cestyzavodou_b-016_menu-logo-size_mobile: 45px;

$cestyzavodou_b-016_menu-logo-margin-left_desktop: 40px;
$cestyzavodou_b-016_menu-logo-margin-right_desktop: 30px;
$cestyzavodou_b-016_menu-logo-margin-left_mobile: 10px;
$cestyzavodou_b-016_menu-logo-margin-right_mobile: 20px;


// odsazeni mobilnich polozek menu je primo odvisle od paddingu mainu, zarovna se zleva shodne
$b-016_menu-item-horizontal-padding_mobile: $layout_main_horizontal-padding_mobile;
$b-016_menu-item-horizontal-padding_desktop: 12px;

$cestyzavodou_fontsize_menu_main_desktop: 22px;
$cestyzavodou_fontsize_menu_main_mobile: 16px;

$cestyzavodou_logo_mono_desktop: url('../images/logo/cestyzavodou/logo_cestyzavodou_mono.svg');


.cestyzavodou {

	// stanicni nav wrapper
	.b-016__wrapper {
		justify-content: unset;

		position: absolute;
		bottom: 0;

		margin-top: 0;
		margin-bottom: 0;

		width: 100%;

		@include breakpoint($breakpoint_desktop) {
		}

		// nav fixed
		&.fixed {
			position: fixed;
			top: 0;
			bottom: unset;

			z-index: 99;

			@include breakpoint($breakpoint_desktop) {
				max-width: $layout_page_max-width;
				margin: auto;
			}

			/*.nav__logo {
				//display: block;

				width: $cestyzavodou_b-016_menu-logo-size_mobile;
				height: $cestyzavodou_b-016_menu-logo-size_mobile;

				margin-left: $cestyzavodou_b-016_menu-logo-margin-left_mobile;
				margin-right: $cestyzavodou_b-016_menu-logo-margin-right_mobile;

				@include breakpoint($breakpoint_desktop) {
					width: $cestyzavodou_b-016_menu-logo-size_desktop;
					height: $cestyzavodou_b-016_menu-logo-size_desktop;

					margin-left: $cestyzavodou_b-016_menu-logo-margin-left_desktop;
					margin-right: $cestyzavodou_b-016_menu-logo-margin-right_desktop;
				}
			}*/

			/*.b-016__wrapper-inner {
				// 100% - logo a jeho marginy
				width: calc(100% - #{$cestyzavodou_b-016_menu-logo-size_mobile} - #{$cestyzavodou_b-016_menu-logo-margin-left_mobile} - #{$cestyzavodou_b-016_menu-logo-margin-right_mobile});

				@include breakpoint($breakpoint_desktop) {
					// 100% - logo a jeho marginy
					width: calc(100% - #{$cestyzavodou_b-016_menu-logo-size_desktop} - #{$cestyzavodou_b-016_menu-logo-margin-left_desktop} - #{$cestyzavodou_b-016_menu-logo-margin-right_desktop});
				}
			}*/

			// nekonecny pruh stanicni navigace
			.b-016::before {
				box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.25);

				@include breakpoint($breakpoint_desktop) {
					box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.25);
				}
			}
		}

		h1,
		h2 {
			display: none;
		}

		/*.nav__logo {
			//display: none;

			flex-grow: 0;
			flex-shrink: 0;
			align-self: center;

			width: 0;
			height: 0;

			margin-left: 0;
			margin-right: 0;

			background-image: $cestyzavodou_logo_mono_desktop;
			background-repeat: no-repeat;
			background-position: 0 0;

			-moz-transition:  all 0.5s ease;
			-webkit-transition:  all 0.5s ease;
			-o-transition: all 0.5s ease;
			transition: all 0.5s ease;

			@include breakpoint($breakpoint_desktop) {
			}

			span {
				display: none;
			}
		}*/



		// wrapper aby .b-016 mohla mit flex-wrap: nowrap a pritom se dobre pocitala sirka cro_responsivelist.js
		.b-016__wrapper-inner {
			// flex-grow: 1; // nefungovalo uplne korektne, nahrazeno width
			flex-shrink: 1;

			// neobsahuje logo
			width: 100%;
		}



		.b-016 {
      background-color: unset;
		}

		// nekonecny pruh stanicni navigace
		.b-016::before {
			content: "";

			position: absolute;
			left: -200%;
			right: -200%;

			height: 100%;

			background-color: $cestyzavodou_b-016_menu-bar-background-color;
			opacity: 0.85;

			z-index: -1;
		}

			.b-016__list,
			.b-016:not(:first-child) .b-016__list { // pretizeni _shame.scss pravidla
				display: flex;
				justify-content: center;

				background-color: unset;

				height: $cestyzavodou_b-016_menu-bar-height_mobile;

				@include breakpoint($breakpoint_desktop) {
					height: $cestyzavodou_b-016_menu-bar-height_desktop;

					// odsazeni vsech polozek menu z leva
					// pro desktop primo ovlivneno vztahem sirky page > main
					padding-left: $layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop;
					padding-right: $layout_main_horizontal-padding_desktop - $b-016_menu-item-horizontal-padding_desktop;
				}
			}

			.b-016__list.cro_responsivelist {
				flex-wrap: nowrap;

				height: $cestyzavodou_b-016_menu-bar-height_mobile;

				@include breakpoint($breakpoint_desktop) {
					height: $cestyzavodou_b-016_menu-bar-height_desktop;
				}
			}

				.b-016__list:not(.b-016__list-more) > .b-016__list-item:not(.b-016__list-item--more) {
					@include breakpoint($breakpoint_desktop) {
						display: flex;
						align-items: center;
					}
				}

				.b-016__list:not(.b-016__list-more) > .b-016__list-item:not(:first-child)::before {
					@include breakpoint($breakpoint_desktop) {
						content: "|";
						color: $color_text_accent_inverted;
						padding: $cestyzavodou_b-016_menu-item-vertical-padding_desktop $b-016_menu-item-horizontal-padding_desktop;
					}
				}

					// polozka menu
					.b-016__list-item a {
						padding: $cestyzavodou_b-016_menu-item-vertical-padding_mobile $b-016_menu-item-horizontal-padding_mobile;
						font-size: $cestyzavodou_fontsize_menu_main_mobile;
						text-transform: uppercase;

						@include link-style ($color_text_accent_inverted, none, $color_text_accent_inverted, none);

						@include breakpoint($breakpoint_desktop) {
							padding: $cestyzavodou_b-016_menu-item-vertical-padding_desktop $b-016_menu-item-horizontal-padding_desktop;
							font-size: $cestyzavodou_fontsize_menu_main_desktop;
							text-transform: uppercase;

							@include link-style ($color_text_accent_inverted, none, $color_text_accent_inverted, underline);
						}
					}

					//.b-016  .b-016__list-item a.expanded,
					.b-016 .b-016__list-item a:not(.expanded):hover {
						@include breakpoint($breakpoint_desktop) {
							background-color: unset;
						}
					 }

					 // pretizeni _shame.scss pravidla
					.b-016 .b-016__list-item a.active {
						display: block; // nechceme skryvat aktivni polozku, protoze nemame h1 v nav
					}
					.b-016__list-item a.active::after {
						display: none;
					}

					// Vice...
					.b-016__list-item--more {
					}

						// button
						.b-016__list-item--more a {
						}

							// seznam podmenu
							.b-016__list-more {
								// default skryto
								display: none;
							}

								// polozka submenu
								.b-016__list-more .b-016__list-item {
								}

									.b-016__list-more .b-016__list-item a {
										// vyplnit cele li - muze byt sirsi, protoze je roztazene ve sloupci dle nejsirsiho
										width: 100%;

										padding: 0.5em 1em;

										@include breakpoint($breakpoint_desktop) {
											padding: 0.5em $b-016_menu-item-horizontal-padding_desktop;

											// polozky na jeden radek at jsou jakkoliv dlouhe
											white-space: nowrap;
										}
									}
									.b-016__list-more .b-016__list-item a:hover {
									}

	}

}
