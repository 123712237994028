//
// epizody serialu s volitelnym poctem sloupcu pomoci modifikatoru
//


$custom-color: rgba(237,222,88, 0.9);
$custom-color-pink: rgba(243,153,154, 0.8);
$custom-color-pink-hover: rgba(243,153,154, 1);
$font-size: .9rem;
$font-size-desktop: 1.2rem;
$font-size-availability: .75rem;

$episode-margin-desktop-two-items: 16px; // grid 2 itemu / radek - default bez modifikatoru
$episode-margin-desktop-three-items: 3.5%; // grid 3 itemu / radek - modifikator .b-100b--three-rows
$episode-margin-desktop-four-items: 2%; // grid 4 itemu / radek - modifikator .b-100b--four-rows


// epizody
.b-100b {

}

	.b-100b .b-100b__episode {
		display: flex;
		flex-wrap: wrap;
	}

		.b-100b .b-100b__episode-wrap {
			flex-basis: calc(50% - 8px);
			margin-right: $episode-margin-desktop-two-items;
			margin-bottom: $episode-margin-desktop-two-items;
			position: relative;
		}

		.b-100b .b-100b__episode-wrap:nth-child(2n) {
			margin-right: 0;
		}

		.b-100b .b-100b__episode-wrap:hover a.b-100b__episode-item .b-100b__episode-name-wrap h3,
		.b-100b .b-100b__episode-wrap:hover a.b-100b__episode-item .b-100b__episode-name-wrap .tag {
			text-decoration: underline;
			background-color: $color_background-8;
		}

			.b-100b .b-100b__episode-item {
				display: flex;
			}
				.b-100b .b-100b__episode-item img {
					display: block;
				}

			.b-100b .b-100b__episode-name-wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0px;
				right: 0;
				bottom: 0;
				left: 0;
				overflow: hidden;
			}

				.b-100b .b-100b__episode-name-wrap h3,
				.b-100b .b-100b__episode-name-wrap .tag {
					color: $color_background-0;
					margin: 0;
					padding: 5px 10px;
					max-width: 90%;
					background-color: $color_background-8_alpha-low;
					@include typography_font(medium);
					font-size: $font-size;
					line-height: 1.2;
					transition: background-color .4s;
					text-align: center;
					@include no-select;
					@include breakpoint($breakpoint_desktop) {
						font-size: $font-size-desktop;
					}
				}

					.b-100b .b-100b__episode-name-wrap h3 a,
					.b-100b .b-100b__episode-name-wrap .tag a {
						@include typography_font(medium);
						font-size: $font-size;
						line-height: 1.2;
						color: $color_background-0;
					}

			.b-100b .b-100b__episode-available {
				margin: 0;
				padding: 5px 10px;
				position: absolute;
				bottom: 0;
				width: 100%;
				background-color: $custom-color;
				text-transform: uppercase;
				text-align: center;
				font-size: $font-size-availability;
				@include no-select;
				@include breakpoint($breakpoint_desktop) {
					padding: 3px 10px;
				}
			}
			.b-100b .b-100b__desktop-only {
				display: none;
				@include breakpoint($breakpoint_desktop) {
					display: inline-block;
				}
			}


			// modifikator .b-100b--three-rows
			// grid: 3 itemy / radek na desktopovem breakpointu
			.b-100b.b-100b--three-rows {}

				.b-100b.b-100b--three-rows .b-100b__episode-wrap {
					@include breakpoint($breakpoint_desktop) {
						flex-basis: 31%; // 31 * 3 = 93, zbyva 7% pro tri itemy, ale mame pouze dve mezery, jelikoz mezera 3n prvku je 0, coz vychazi na 3.5% margin, soucet 100%
						margin-right: $episode-margin-desktop-three-items;
						margin-bottom: $episode-margin-desktop-three-items;
					}
				}

				.b-100b.b-100b--three-rows .b-100b__episode-wrap:nth-child(2n) {
					@include breakpoint($breakpoint_desktop) {
						margin-right: $episode-margin-desktop-three-items;
					}
				}

				.b-100b.b-100b--three-rows .b-100b__episode-wrap:nth-child(3n) {
					@include breakpoint($breakpoint_desktop) {
						margin-right: 0;
					}
				}


			// modifikator .b-100b--four-rows
			// grid: 4 itemy / radek na desktopovem breakpointu
			.b-100b.b-100b--four-rows {}

				.b-100b.b-100b--four-rows .b-100b__episode-wrap {
					@include breakpoint($breakpoint_desktop) {
						flex-basis: 23.5%; // 23.5 * 4 = 94, zbyva 6% pro ctyri itemy, ale mame pouze tri mezery, jelikoz merera 4n prvku je 0, coz vychazi na 2% margin, součet 100%
						margin-right: $episode-margin-desktop-four-items;
						margin-bottom: $episode-margin-desktop-four-items;
					}
				}

				.b-100b.b-100b--four-rows .b-100b__episode-wrap:nth-child(2n) {
					@include breakpoint($breakpoint_desktop) {
						margin-right: $episode-margin-desktop-four-items;
					}
				}

				.b-100b.b-100b--four-rows .b-100b__episode-wrap:nth-child(4n) {
					@include breakpoint($breakpoint_desktop) {
						margin-right: 0;
					}
				}


// uprava barevnosti pro Wave Zkouskovy

	// nadpis epizody
	.page-zkouskovy .b-100b .b-100b__episode-name-wrap h3,
	.page-zkouskovy .b-100b .b-100b__episode-name-wrap .tag {
		color: $color_text_accent;
		background-color: $custom-color-pink;
		@include typography_font(bold);
	}

	// nadpis epizody :hover
	.page-zkouskovy .b-100b__episode-wrap:hover a.b-100b__episode-item .b-100b__episode-name-wrap h3,
	.page-zkouskovy .b-100b__episode-wrap:hover a.b-100b__episode-item .b-100b__episode-name-wrap .tag {
		background-color: $custom-color-pink-hover;
	}

	// dostupnost epizody
	.page-zkouskovy .b-100b .b-100b__episode-available {
		color: $color_text_link_inverted;
		background-color: $color_background-7_alpha-mid;
	}
