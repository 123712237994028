//
// Styles - Node Type Show
//



.node-type-show {
}






//
// Styles - Node About Show
//




.page-node-o-poradu {

	// title
	// TODO: v sablone zanorit do .l-grid--12-1.nth-of-type--1 (obrazkovy) a .l-grid--8-1.nth-of-type--1 (standardni/audio) a prepsat styly
	h1.show-type {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		margin-bottom: 0.5em;

		line-height: 1.8rem;

		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			padding-right: 25px; // viz .l-grid--8-1

			line-height: 2.5rem; // 3rem
		}

		&.article-type--image {
			@include breakpoint($breakpoint_desktop) {
				width: 100%;
			}
		}
	}



	// grid
	.l-grid--12-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--12-1 {
		}


	// grid
	.l-grid--8-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--8-1 {

			.file-audio {
				margin-left: 0;
			}
		}


		// content-container
		.content-container {
			position: relative; // kvuli absolutnimu pozicobvani vertikalnich soc. buttonu
		}

			// vertical soc. buttons
			.content-1--1-1 {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;

					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: calc(100% - #{$node_left-margin});
				}
			}

			// perex, body
			.content-1--7-2 {
			}


		// main audio anchor, authors, bottom soc. buttons
		.content-2--8-1 {
		}

			// fotka autora - fallback
			.view-display-id-node_author_main.box {
				display: none; // opusteno
			}

			// jmeno autora - fallback
			.view-display-id-node_author_main_2.box {
				display: none; // opusteno
			}

			// expired audio
			.article-type--audio .audio-rights-expired {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}


	// grid - aside
	.l-grid--4-9.nth-of-type--1 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 8em;
		}
	}

		// content boxes, ad
		.content-1--4-9 {
		}


	// grid
	.l-grid--12-1.nth-of-type--2 {
	}


}
