//
// B-035 Styles
//



.b-035 {
	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	max-width: 400px;
}

	.b-035 h2 {
		text-transform: uppercase;

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: 0.5em;
		}
	}

	.b-035__block {
		background: $color_background-2;
		padding: 0.75em 1.25em 1.25em 1.25em ;
	}

		.b-035__list {
		}

			.box ul .b-035__list-item {
				list-style-type: disc;
				margin-left: 1em;
				font-size: $fontsize_li_main;

				@include breakpoint($breakpoint_desktop) {
					font-size: $fontsize_li_main_desktop;
				}

				a {
					padding: 0.75em 0 0.75em 0.25em;
					width: 100%;
					display: block;
				}
			}

		.b-035 .button {
			display: inline-flex;
			margin-top: 1.25em;
		}
