//
// page-porady
//



.page-porady {
	
	// skryti celeho listovaciho obrazkoveho boxu poradu
	// mozna docasne, mozna jindy jinak..
	.b-023d {
		
		display: none;
		
		@include breakpoint($breakpoint_desktop) {
			display: block;
		}
	}
}