//
// B-022 Styles
//



$b-022_image-width-basis: 14em;


.b-022 {
}

	.b-022__list {
	}

		.b-022__list-item {
			display: flex;
			flex-direction: column;

			margin-bottom: 1em;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: row;
				margin-bottom: 2em;
			}
		}

		.b-022__list-item:last-child {
			margin-bottom: 0;
		}

			.b-022__block {
			}

			.b-022__block--image {

				@include breakpoint($breakpoint_desktop) {
					flex-basis: $b-022_image-width-basis;
					flex-shrink: 0;
				}
			}

				.b-022__image {
					position: relative;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__image .tag:not(.tag-rounded) {
						position: absolute;
						bottom: 0;
						left: 10px;

						max-inline-size: calc(100% - 20px);
					}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__image .tag-rounded {
						position: absolute;
						max-inline-size: calc(100% - 20px);
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__image .tag-rounded.tag-rounded--top-left {
						bottom: 0;
						right: 0;
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__image .tag-rounded.tag-rounded--top-right {
						bottom: 0;
						left: 0;
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__image .tag-rounded.tag-rounded--bottom-left {
						top: 0;
						right: 0;
					}
					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__image .tag-rounded.tag-rounded--bottom-right {
						top: 0;
						left: 0;
					}


			.b-022__block--description {
				flex-grow: 1;

				@include breakpoint($breakpoint_desktop) {
					padding-left: 1em;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022__block--description h3 {
					margin-top: 1em;
					margin-bottom: 0;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
					}
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022__block--description p {
					margin-top: 0.5em;
					margin-bottom: 0.5em;

					color: $color_text_paragraph;
				}

				.b-022__actions {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022__actions .button-listaction {
						margin-right: 0.25em;
					}





//
// B-022a Styles
//


.b-022a {
}

	.b-022a__list {
	}

		.b-022a__list-item {
		}

			.b-022a__block {
			}

			.b-022a__block--image {
			}

				.b-022a__image {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022a__image .tag:not(.tag-rounded) {
						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}


			.b-022a__block--description {
				padding: 0 10px;
				padding-top: 5px;

				@include breakpoint($breakpoint_desktop) {
					padding: 0;
					padding-left: 1em;
				}
			}

				.b-022a__description-row {
					display: flex;
					align-items: center;

					margin-bottom: 0.25em;

					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0.5em;
					}
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022a__description-row .tag:not(.tag-rounded){
						display: none;

						@include breakpoint($breakpoint_desktop) {
							display: block;

							margin-right: 1em;
						}
					}

					.b-022a__description-row .b-022__timestamp {
						color: $color_text_paragraph;
						font-size: 12px;
					}

						.b-022a__description-row .b-022__timestamp--date {
							margin-right: 0.75em;
						}

						.b-022a__description-row .b-022__timestamp--time {
						}


				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022a__block--description h3 {
					margin-top: 0;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022a__block--description p {
				}

				.b-022a__actions {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022a__actions .button-listaction {
					}





//
// B-022b Styles
//


.b-022b {
}

	.b-022b__timestamp {
		border-bottom: 1px solid $color_background-9;
		//color: $color_text_accent;

		padding-bottom: 0.25em;
		margin-bottom: 2em;

		font-size: 14px;
		line-height: 1.3;
	}

	.b-022b__list {
	}

		.b-022b__list-item {
			border-bottom: 1px solid $color_divider;
			padding-bottom: 1.5em;
			margin-bottom: 2em;
		}

			.b-022b__block {
			}

			.b-022b__block--image {
				margin-right: 0;
				margin-bottom: 1em;

				@include breakpoint($breakpoint_desktop) {
					flex-basis: 12em;
					margin-right: 1em;
					margin-bottom: 0;
				}
			}

				.b-022b__image {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022b__image .tag:not(.tag-rounded) {
						@include breakpoint($breakpoint_desktop) {
							display: none;
						}
					}


			.b-022b__block--description {
				padding: 0 10px;

				@include breakpoint($breakpoint_desktop) {
					padding: 0;
				}
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022b__block--description h3 {
					margin-top: 0;
				}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-022b__block--description p {
				}

				.b-022b__actions {
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022b__actions .button-simple--readmore {

						@include breakpoint($breakpoint_desktop) {
						}
					}





//
// B-022a Styles
//



$b-022c_image-width-basis_mobile: 30%;
$b-022c_image-width-basis_desktop: 10em;


.b-022c {
}

	.b-022c__title {
		margin-bottom: 2.5em;
		padding: 0 10px 5px 0;

		color: $color_background-5;

		font-size: 12px;
		line-height: 1.3;
		text-transform: uppercase;

		border-bottom: 1px solid $color_divider;

		@include breakpoint($breakpoint_desktop) {
			padding-left: 10px;
			font-size: 15px;
		}
	}

	 .b-022c__title-results {
		 color: $color_background-7;
	 }


	.b-022c__list {
	}

		.b-022c__list-item {
			display: flex;
			flex-direction: column;

			margin-bottom: 1.5em;

			@include breakpoint($breakpoint_desktop) {
				flex-direction: column;
				margin-bottom: 2.5em;
			}
		}

			.b-022c__block {
			}

			.b-022c__block--heading {
				margin-bottom: 0.5em;
			}

				.b-022c__metadata {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022c__metadata .tag:not(.tag-rounded) {
						flex-shrink: 0;

						margin-bottom: 0.5em;
						margin-right: 0.5em;
						//padding: 2px 4px;
						padding: 0 4px;

						@include typography_font(medium);
						font-size: 12px;
						//line-height: 12px;
						line-height: 16px;
						text-transform: uppercase;

						@include breakpoint($breakpoint_desktop) {
							margin-right: 0.75em;
							font-size: 13px;
							//line-height: 13px;
							line-height: 17px;
						}
					}

						// iRozhlas
						.b-022c__list-item.irozhlas .b-022c__metadata .tag:not(.tag-rounded) {

							span {
								//animation: width-fix 1ms;
							}

							span:first-letter {
								text-transform: lowercase;
							}

							// Firefox Fix (chybna sirka)
							// https://stackoverflow.com/questions/41425083/css-first-letter-glitch-in-firefox
							// https://stackoverflow.com/questions/7548255/is-there-a-css-workaround-for-firefox-bug-inline-block-first-letter-with-cha/7553176#7553176
							/*@keyframes width-fix {
								from { padding-right: 0.1px; }
								to { padding-right: 0; }
							}*/
						}


					.b-022c__metadata .b-022c__show-label {
						margin-bottom: 0.5em;
						margin-right: 0.5em;
						padding-right: 0.5em;

						border-right: 1px solid $color_divider;

						@include breakpoint($breakpoint_desktop) {
							border-right: 2px solid $color_divider;
						}
					}

					.b-022c__metadata .b-022c__show-label:last-child {
						border-right: none;
					}

						.b-022c__metadata .b-022c__show-label a {
							@include link-style($color_background-5, none, $color_background-5, underline);
						}


					.b-022c__metadata .b-022c__show-label,
					.b-022c__metadata .b-022c__timestamp {
						color: $color_background-5;
						font-size: 12px;
						line-height: 1.3;

						@include breakpoint($breakpoint_desktop) {
							font-size: 15px;
						}
					}

					.b-022c__metadata .b-022c__timestamp {
						display: flex;
						align-items: center;

						margin-bottom: 0.5em;
					}

						.b-022c__metadata .b-022c__timestamp-date {
							margin-right: 0.25em;
						}

						.b-022c__metadata .b-022c__timestamp-time {
						}


					// serial label
					.b-022c__serial-label {
						@include typography_font(regular);
						color: $color_background-5;

						margin-top: 0;
						margin-bottom: 0.75em;

						font-size: 11px;
						line-height: 1.3;

						@include breakpoint($breakpoint_desktop) {
							font-size: 13px;
							margin-bottom: 0.5em;
						}
					}

						.b-022c__serial-label a {
							@include link-style($color_background-5, none, $color_background-5, underline);
						}


						// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022c__block--heading h3 {
						margin-top: 0;
						margin-bottom: 0;
					}


			.b-022c__block--content {
				display: flex;
				flex-direction: row;

				@include breakpoint($breakpoint_desktop) {
					flex-direction: row;
				}
			}

				.b-022c__subblock--image {
					order: 2;
					flex-basis: $b-022c_image-width-basis_mobile;
					flex-shrink: 0;

					@include breakpoint($breakpoint_desktop) {
						order: 1;
						flex-basis: $b-022c_image-width-basis_desktop;
					}
				}

					.b-022c__image {
						img {
							@include breakpoint($breakpoint_desktop) {
								transition: transform 0.3s;
							}
						}
					}

					.b-022c__image:hover {
						img {
							@include breakpoint($breakpoint_desktop) {
								transform: scale(1.1);
							}
						}
					}

					// fallback: img with no src
					.b-022c__image {
						width: 100%;
						z-index: 0;

						@include breakpoint($breakpoint_desktop) {
							width: 10em; // 3:2
							height: 6.67em; // 3:2
						}

						&:before {
							content: '';
							display: block;
							padding-bottom: 66.66%; // 3:2

							@include breakpoint($breakpoint_desktop) {
								content: initial;
								display: initial;
								padding-bottom: initial;
							}
						}

						a {
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;

								&[src=""] {
									display: none;
								}
							}
						}

						&::after {
							content: '';

							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;

							background-color: $color_corporate_main;
							background-image: $file_logo_R_white;
							background-position: 50%;
							background-repeat: no-repeat;
							background-size: 25%;

							z-index: -1;

							@include breakpoint($breakpoint_desktop) {
								transition: transform 0.3s;
							}
						}

						&:hover::after {
							@include breakpoint($breakpoint_desktop) {
								transform: scale(1.1);
							}
						}
					}


				.b-022c__subblock--description {
					order: 1;
					flex-grow: 1;
					padding-right: 1em;

					@include breakpoint($breakpoint_desktop) {
						order: 2;
						padding-left: 1em;
						padding-right: 0;
					}
				}

					// TODO z duvodu atomu zatim ne pres specifickou tridu
					.b-022c__subblock--description p {
						margin-top: 0;
						margin-bottom: 0.5em;

						color: $color_text_paragraph;

						@include breakpoint($breakpoint_desktop) {
						}

						// pro potreby "zvyraznene" v Hledani
						strong {
							@include typography_font(medium);
							color: $color_text_accent;
						}
					}

					.b-022c__actions {
					}

						// TODO z duvodu atomu zatim ne pres specifickou tridu
						.b-022c__actions .button-simple--readmore {

							@include breakpoint($breakpoint_desktop) {
							}
						}


		// placeholder
		.b-022c__list-item--placeholder {
			pointer-events: none;
			animation: placeholder-loading 3s infinite;

			.b-022c__block--heading {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}

			.b-022c__block--content {}

			// relative
			.b-022c__block--heading .b-022c__metadata .tag,
			.b-022c__block--heading .b-022c__metadata .b-022c__show-label,
			.b-022c__block--heading .b-022c__metadata .b-022c__timestamp,
			.b-022c__block--heading .b-022c__serial-label,
			.b-022c__block--heading h3,
			.b-022c__block--content .b-022c__subblock--image .b-022c__image,
			.b-022c__block--content .b-022c__subblock--description p,
			.b-022c__block--content .b-022c__subblock--description .b-022__actions .button {
				position: relative;
			}

			// hidden
			.b-022c__block--heading .b-022c__metadata .tag,
			.b-022c__block--heading .b-022c__metadata .b-022c__show-label,
			.b-022c__block--heading .b-022c__metadata .b-022c__timestamp,
			.b-022c__block--heading .b-022c__serial-label,
			.b-022c__block--heading h3,
			.b-022c__block--content .b-022c__subblock--image  .b-022c__image,
			.b-022c__block--content .b-022c__subblock--description p,
			.b-022c__block--content .b-022c__subblock--description .b-022__actions .button {
				visibility: hidden;
			}

			// no-data fallback
			.b-022c__block--heading .b-022c__metadata .tag,
			.b-022c__block--heading .b-022c__metadata .b-022c__show-label,
			.b-022c__block--heading .b-022c__metadata .b-022c__timestamp,
			.b-022c__block--heading .b-022c__serial-label,
			.b-022c__block--heading h3,
			.b-022c__block--content .b-022c__subblock--image  .b-022c__image,
			.b-022c__block--content .b-022c__subblock--description p,
			/*.b-022c__block--content .b-022c__subblock--description .b-022__actions .button*/ {
				min-width: 25px;
				min-height: 15px;
			}
			.b-022c__block--heading h3,
			.b-022c__block--content .b-022c__subblock--image  .b-022c__image {
				width: 100%;
			}
			.b-022c__block--content .b-022c__subblock--image  .b-022c__image {

				@include breakpoint($breakpoint_desktop) {
					width: 10em; // 3:2
					height: 6.67em; // 3:2
				}
			}
			.b-022c__block--content .b-022c__subblock--image  .b-022c__image:before {
				content: '';
				display: block;
				padding-bottom: 66.66%; // 3:2

				@include breakpoint($breakpoint_desktop) {
					content: initial;
					display: initial;
					padding-bottom: initial;
				}
			}
			.b-022c__block--content .b-022c__subblock--image  .b-022c__image a {
				position: absolute;

				@include breakpoint($breakpoint_desktop) {
					position: initial;
				}
			}

			// after
			.b-022c__block--heading .b-022c__metadata .tag::after,
			.b-022c__block--heading .b-022c__metadata .b-022c__show-label::after,
			.b-022c__block--heading .b-022c__metadata .b-022c__timestamp::after,
			.b-022c__block--heading .b-022c__serial-label::after,
			.b-022c__block--heading h3::after,
			.b-022c__block--content .b-022c__subblock--image .b-022c__image::after,
			.b-022c__block--content .b-022c__subblock--description p::after,
			.b-022c__block--content .b-022c__subblock--description .b-022__actions .button::after {
				content: '';

				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				background-color: $color_background-2;
				border-radius: 4px;

				visibility: visible;
			}

			.b-022c__block--content .b-022c__subblock--description .b-022__actions .button::after {
				border-radius: 100%;
			}

			// animation
			@keyframes placeholder-loading {
				0% {
					opacity: 0.3;
				}
				25% {
					opacity: 0.7;
				}
				50% {
					opacity: 0.7;
				}
				75% {
					opacity: 0.7;
				}
				100% {
					opacity: 0.3;
				}
			}
		}
