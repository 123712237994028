//
// filter tabs search styles
//



.filter-checkboxes-tabs-search {
	
	//background-color: $color_background-2;
    
	display: flex;
	flex-direction: column;
	
	margin-top: 1.5em;
	margin-bottom: 2em;
	
	@include breakpoint($breakpoint_desktop) {
		
		flex-direction: row;
		//align-items: center;
		
		margin-top: 1em;
	}
}

	.filter-checkboxes-tabs-search .filter-checkboxes-tabs-search__section-filters {
			
		@include breakpoint($breakpoint_desktop) {
			
			order: 1;
			flex-grow: 1;
		}
	}
	
		.filter-checkboxes-tabs-search .filter-checkboxes-tabs-search__section-filters .filter-checkboxes {
			
			margin-top: 0;
			//margin-bottom: 0;
		}
	
	
	.filter-checkboxes-tabs-search .search-combo {
		
		margin-top: 0;
		
		@include breakpoint($breakpoint_desktop) {
			
			order: 2;
			
			margin-left: 2em;
			margin-bottom: 0;
		}
	}

		/*.filter-tabs-search .filter-tabs__list {
			
			display: flex;
			
			@include breakpoint($breakpoint_desktop) {
				
				order: 1;
				flex-grow: 1;
			}
		}
		
		.filter-tabs-search .filter-tabs__list-item {
			
			color: $color_background-6;
			
			margin-right: 1em;
		}
		
			.filter-tabs-search .filter-tabs__list-item .filter-tabs__link {
			
				color: $color_background-6;
			}
		
			.filter-tabs-search .filter-tabs__list-item--selected,
			.filter-tabs-search .filter-tabs__list-item--selected .filter-tabs__link {
			
				color: $color_background-9;
			}
  
		.filter-tabs-search .search-combo {
			
			margin-top: 0;
			
			@include breakpoint($breakpoint_desktop) {
				
				order: 2;
				
				margin-bottom: 0;
			}
		}*/