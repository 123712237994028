//
// Radiozurnal Sport - embed player container
//



$sport_player-embed_launcher_height_mobile: 45px;
$sport_player-embed_launcher_height_desktop: 60px;

$sport_player-embed_logo-wrapper_height_mobile: $sport_player-embed_launcher_height_mobile;
$sport_player-embed_logo-wrapper_height_desktop: $sport_player-embed_launcher_height_desktop;

$sport_player-embed_launcher_button-play-live-size_mobile: 55px;
$sport_player-embed_launcher_button-play-live-size_desktop: 70px;

$sport_player-embed_background-image: '../images/bg/sport/sport_embed.jpg';


#{$sport_selector_body-player-embed} {

	.sport-player-embed {
		position: relative;

		flex-grow: 1;

		display: flex;
		flex-direction: column;
	}

	// before: gradient
	.sport-player-embed::before {
		content: '';

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
		//background-image: linear-gradient(to top, $color_radiozurnal_main, rgba(#ed2e38, 0) 40%); // do stransparentni
		background-image: linear-gradient(to top, $color_radiozurnal_main, rgba($color_background-9, 0.3) 40%); // do cerne 30%

		z-index: -1;
	}

	// after: bg
	.sport-player-embed::after {
		content: '';

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		background-image: url($sport_player-embed_background-image);
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: scroll;
		background-position: center top;

		z-index: -2;
	}

		// station header
		.sport-player-embed .b-015 {
			position: relative;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			margin-bottom: 0; // preneseno z .b-016__wrapper

			height: $sport_player-embed_logo-wrapper_height_mobile;

			// ciste debug default barva pozadi pro zobrazeni samostatneho atomu nebo navigace, pokud
			// nema rodicovsky obal, ktery jej kontextove obarvi tridou .bg-color-main
			background-color: $color_sport_main;

			transition: box-shadow 0.35s ease, opacity 0.35s ease, top 0.35s ease;

			@include breakpoint($breakpoint_desktop) {
				height: $sport_player-embed_logo-wrapper_height_desktop;
			}
		}

			// obecne vlastnosti sloupce
			.sport-player-embed .b-015__list-item {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
			}

			// levy sloupec - misto pro logo
			.sport-player-embed .b-015__list-item--left {
				flex-grow: 0;

				height: $sport_player-embed_logo-wrapper_height_mobile;

				@include breakpoint($breakpoint_desktop) {
					height: $sport_player-embed_logo-wrapper_height_desktop;
				}
			}

				.sport-player-embed .b-015__logo-wrapper {
					margin-top: 0;
					margin-left: $layout_main_horizontal-padding_mobile;
					margin-right: 0;

					@include breakpoint($breakpoint_desktop) {
						margin-top: 0;
						margin-left: $layout_main_horizontal-padding_desktop;
						margin-right: 0;
					}
				}

					.sport-player-embed .b-015__logo {
						display: block;

						width: 10em; // 168px
						height: $sport_player-embed_launcher_height_mobile;

						background-position: left center;
						background-repeat: no-repeat;

						@include breakpoint($breakpoint_desktop) {
								width: 14em; // 268px
								height: $sport_player-embed_launcher_height_desktop;
						}
					}

						.sport-player-embed .b-015__logo-textcontent {
							color: $color_text_link_inverted;
							display: none;
						}


			// pravy sloupec - launcher prehravace
			.sport-player-embed .b-015__list-item--right {
				flex-grow: 1;
				display: flex;
			}

				// wrapper pro launcher
				.sport-player-embed .b-015__launcher-wrapper {
					flex-grow: 1;

					display: flex;
					flex-direction: row;
					justify-content: flex-end;

					padding: 0 $layout_main_horizontal-padding_mobile;

					@include breakpoint($breakpoint_desktop) {
						padding: 0 $layout_main_horizontal-padding_desktop;
					}
				}

						.sport-player-embed .b-015__launcher {
							flex-grow: 1;
							max-width: 100%;

							display: flex;
							flex-direction: row;

							height: $sport_player-embed_launcher_height_desktop;

							@include breakpoint($breakpoint_desktop) {
								flex-grow: 1;
							}
						}

							.sport-player-embed .button-play--launcher-live {
								width: $sport_player-embed_launcher_button-play-live-size_mobile;
								height: $sport_player-embed_launcher_height_mobile;

								@include breakpoint($breakpoint_desktop) {
									width: $sport_player-embed_launcher_button-play-live-size_desktop;
									height: $sport_player-embed_launcher_height_desktop;
								}
							}


		// header content
		.sport-player-embed__content {
			position: relative;

			flex-grow: 1;

			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;

			margin: 2em 0;
		}

			// header block
			.sport-player-embed__block:not(:first-child) {
				//margin-top: 1em;
				margin-top: 10px;

				@include breakpoint($breakpoint_desktop) {
					//margin-top: 2em;
					margin-top: 10px;
				}
			}

			// header block: player
			.sport-player-embed__block--player {
				flex-grow: 1;

				display: flex;
				flex-direction: column;
				justify-content: center;

				width: 80%;
				margin-left: auto;

				@include breakpoint($breakpoint_tablet) {
					width: 50%;
					margin-right: 0;
				}

				@include breakpoint($breakpoint_desktop) {
					width: 33%;
					margin-right: 175px;
				}
			}

}
