//
// SOCR - obecne vlastnosti vsech stranek
//



// vars
$view-filters_socr_color_vse: $color_corporate_main;
$view-filters_socr_color_socr: $color_corporate_main;
$view-filters_socr_color_koncert: $color_vltava_main;
$view-filters_socr_color_taktovka: $color_vltava_main;


.socr.page-poslech,
.prso.page-media {

	.view-filters {

		form {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-exposed-widget {

						.views-widget {

							.form-type-select {

								.form-checkboxes {

									/* nevyuzita struktura obalovaci > views-widget > form-type-select > form-checkboxes */
									// filtrovaci checkboxy
									.bef-checkboxes {
									}

										/* obecny - checkbox item */
										.form-item {

											// border collapse hack - po zalomeni u desktopu
											@include breakpoint($breakpoint_desktop) {
											}

											input[type='checkbox'] {
											}

											input[type='checkbox'] + label {

												@include breakpoint($breakpoint_desktop) {
												}
											}
											/* last */
											&.form-item:last-child input[type='checkbox'] + label {
											}
											/* active */
											input[type='checkbox'] + label:active,
											input[type='checkbox']:checked + label:active {
											}
											/* checked */
											input[type='checkbox']:checked + label {
											}
										}
										/* vlastni barvy */
										.form-item:nth-of-type(2) input[type='checkbox'] + label {

											color: $view-filters_socr_color_socr;
											border: 2px solid $view-filters_socr_color_socr;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(2) input[type='checkbox']:checked + label {

											background-color: $view-filters_socr_color_socr;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(3) input[type='checkbox'] + label {

											color: $view-filters_socr_color_koncert;
											border: 2px solid $view-filters_socr_color_koncert;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(3) input[type='checkbox']:checked + label {

											background-color: $view-filters_socr_color_koncert;
											color: $color_text_link_inverted;
										}
										.form-item:nth-of-type(4) input[type='checkbox'] + label {

											color: $view-filters_socr_color_taktovka;
											border: 2px solid $view-filters_socr_color_taktovka;

											@include breakpoint($breakpoint_desktop) {
											}
										}
										/* checked */
										.form-item:nth-of-type(4) input[type='checkbox']:checked + label {

											background-color: $view-filters_socr_color_taktovka;
											color: $color_text_link_inverted;
										}
										/* specificke "vse" */
										.form-item.form-item-all {

											a {

												color: $view-filters_socr_color_vse;
												border: 2px solid $view-filters_socr_color_vse;

												@include breakpoint($breakpoint_desktop) {
												}
											}
											a:active {
											}

											&.highlight a {

												background-color: $view-filters_socr_color_vse;
												color: $color_text_link_inverted;
											}
										}
										.form-item.form-item-edit-field-term-theme-all--checked {

											a {
											}
										}


								}
							}
						}
					}
				}

				.views-exposed-widgets.clearfix::after {
					display: none;
				}
			}
		}
	}
}
