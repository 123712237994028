//
// Regina - HP
//



.regina.page-regina-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}