//
// datepicker
//

$datepicker-position-top: 23px;
$datepicker-dimensions: 30px;


.datepicker {
	display: flex;
	justify-content: center;
	color: $color_background-6;
	margin: 10px 0;
	margin: 0 auto 18px auto;
	position: relative;
	&__wrap {
		display: flex;
	}
	&__print {
		position: absolute;
		right: 10px;
		top: 15px;
		display: none;
		color: $color_background-5;
		font-size: .75rem;
		transition: color 100ms ease-in-out;
		@include no-select;
		@include breakpoint($breakpoint_tablet) {
			display: flex;
		}
		&:after {
			content: '';
			background: $file_button_action_print no-repeat;
			width: 24px;
			height: 24px;
			background-size: 24px 24px;
			display: inline-block;
			margin-left: 7px;
		}
		&:hover {
			color: darken($color_background-5, 25%);
		}
	}
	&__arrow-left {
		background: $file_icon_arrow_single_east no-repeat center transparent;
		background-size: 10px 10px;
		position: absolute;
		top: $datepicker-position-top;
		left: 0;
		width: $datepicker-dimensions;
		height: $datepicker-dimensions;
		cursor: pointer;
		transform: rotate(180deg);
		@include breakpoint($breakpoint_tablet) {
			display: none;
		}
		&--large-screen {
			display: none;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
				left: 0;
				transition: opacity 250ms ease-in-out;
				opacity: .6;
				z-index: 1;
			}
			&:hover {
				opacity: 1;
			}
		}
	}
	&__arrow-right {
		background: $file_icon_arrow_single_east no-repeat center transparent;
		background-size: 10px 10px;
		position: absolute;
		top: $datepicker-position-top;
		right: 0;
		width: $datepicker-dimensions;
		height: $datepicker-dimensions;
		cursor: pointer;
		@include breakpoint($breakpoint_tablet) {
			display: none;
		}
		&--large-screen {
			display: none;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
				right: 0;
				transition: opacity 250ms ease-in-out;
				opacity: .6;
				z-index: 1;
			}
			&:hover {
				opacity: 1;
			}
		}
	}
	&__item {
		text-align: center;
		flex-direction: column;
		text-decoration: none;
		&:nth-child(2),
		&:nth-child(6) {
			display: none;
			@include breakpoint($breakpoint_tablet) {
				display: flex;
			}
		}
		&:hover {
			text-decoration: none;
			.datepicker__day-name {
				text-decoration: underline;
			}
		}
	}
	&__day-name {
		color: $color_background-5;
		font-size: .75rem;
	}
	&__day-date {
		color: $color_decoration_main;
		@include typography_font(bold);
		font-size: 1.2rem;
		word-spacing: -2px;
		padding: 5px 12px;
		&:hover {
			color: darken($color_decoration_main, 12%);
		}
		@include breakpoint($breakpoint_tablet) {
			font-size: 1.3rem;
			padding: 5px 20px;
		}
		&--actual {
			border: 1px solid $color_divider;
			color: $color_background-9 !important;
			padding: 5px 8px 5px 10px;
			display: flex;
			align-items: center;
			&:after {
				content: '';
				background: $file_icon_calendar_colored-black no-repeat 10px;
				background-size: 20px 20px;
				width: 30px;
				height: 20px;
				display: inline-block;
			}
		}
	}
	// skryvani neaktivni casti datepickeru
	.is-hidden {
		visibility: hidden;
	}
}
