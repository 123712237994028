//
// filter tabs search styles
//



.filter-tabs-search {
	
	//background-color: $color_background-2;
    
	display: flex;
	flex-direction: column;
	
	margin-top: 1.5em;
	margin-bottom: 2em;
	
	@include breakpoint($breakpoint_desktop) {
		
		flex-direction: row;
		align-items: center;
		
		margin-top: 1em;
	}
}

		.filter-tabs-search .filter-tabs {
			
			@include breakpoint($breakpoint_desktop) {
				
				order: 1;
				flex-grow: 1;
			}
		}
		
		.filter-tabs-search .search-combo {
			
			margin-top: 0;
			
			@include breakpoint($breakpoint_desktop) {
				
				order: 2;
				
				margin-bottom: 0;
			}
		}