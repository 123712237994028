//
// Olomouc - HP
//



.olomouc.page-olomouc-hp {

	// box Doprava
	.b-102 { 
		display: none;
	}

}