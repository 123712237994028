//
// Styles b-008l - Exposed filters
//



// vars
$b-008l-view-filters_tab_border: none;
$b-008l-view-filters_tab_padding-horizontal-left_desktop: 1.9em;
$b-008l-view-filters_tab_padding-horizontal-right_desktop: 0;
$b-008l-view-filters_tab_padding-horizontal-left_mobile: 2em;
$b-008l-view-filters_tab_padding-horizontal-right_mobile: 0;
$b-008l-view-filters_tab_padding-vertical_mobile: 0;
$b-008l-view-filters_tab_padding-vertical_desktop: 0;
$b-008l-view-filters_tab_margin-horizontal-right_desktop: 1.5em;
$b-008l-view-filters_tab_font-size: 15px;
$b-008l-view-filters_tab_line-height: 24px;



.b-008l {

	@mixin b-008l-view-filters_tab() {
		position: relative;

		display: block;

		border: $b-008l-view-filters_tab_border;
		border-bottom: none; // mobile specificke

		padding: $b-008l-view-filters_tab_padding-vertical_mobile $b-008l-view-filters_tab_padding-horizontal-right_mobile $b-008l-view-filters_tab_padding-vertical_mobile $b-008l-view-filters_tab_padding-horizontal-left_mobile;

		font-size: $b-008l-view-filters_tab_font-size;
		line-height: $b-008l-view-filters_tab_line-height;
		text-align: left;
		white-space: nowrap;

		color: $color_text_paragraph;
		@include link-style($color_text_paragraph, none, $color_text_paragraph, none);

		cursor: pointer;


		@include breakpoint($breakpoint_desktop) {

			padding: $b-008l-view-filters_tab_padding-vertical_desktop $b-008l-view-filters_tab_padding-horizontal-right_desktop $b-008l-view-filters_tab_padding-vertical_desktop $b-008l-view-filters_tab_padding-horizontal-left_desktop;
			margin-right: $b-008l-view-filters_tab_margin-horizontal-right_desktop;
			border-bottom: $b-008l-view-filters_tab_border;
		}
	}
	@mixin b-008l-view-filters_tab--before() {
		content: "";

		position: absolute;
		top: 2px;
		left: 0;

		width: 20px;
		height: 20px;

		background-image: $file_checkbox_unchecked;
		background-repeat: no-repeat;
		background-position: center;

		@include breakpoint($breakpoint_desktop) {
			top: 2px;
		}
	}
	@mixin b-008l-view-filters_tab--lastchild() {

		border-bottom: $b-008l-view-filters_tab_border;
		margin-right: 0;

		@include breakpoint($breakpoint_desktop) {
		}
	}
	@mixin b-008l-view-filters_tab--active() {

		//opacity: 0.8;
		opacity: 1;
	}
	@mixin b-008l-view-filters_tab--checked() {

		background-color: transparent;
		color: $color_text_paragraph;
		@include link-style($color_text_paragraph, none, $color_text_paragraph, none);
	}
	@mixin b-008l-view-filters_tab--checked-before() {

		background-image: $file_checkbox_checked;
	}


	// tady se na vahu selektoru kasle
	// s markupem se stejne v teto fazi neda hnout, tak at v tom neni uplnej border klidne zanoruju
	.view-filters {

		//margin-bottom: 1em;
		margin-bottom: -1.5em;


		// nema svoji tridu na kterou by se dalo obecne chytit
		// zaroven je potreba resetovat zde pravidla z 05_html_elements
		// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
		form {

			// reset default vlastnosti
			padding: 0;
			background-color: transparent;


			.views-exposed-form {

				.views-exposed-widgets {


					// reset D
					// obecne jak pro radu checkboxu, tak pro sekci search inputu a submitu
					.views-exposed-widget {

						float: initial;
						clear: both;
						padding: 0;
					}



					/* nevyuzita struktura obalovaci > views-widget > form-type-select > form-checkboxes */
					// filtrovaci checkboxy
					.bef-checkboxes {

						display: flex;
						flex-direction: column;

						@include breakpoint($breakpoint_desktop) {

							flex-direction: row;
							flex-wrap: wrap;
						}

						/* obecny - checkbox item */
						.form-item {

							input[type='checkbox'] {

								// reset default vlastnosti z default 05_html_elements
								// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
								margin: 0;
								position: initial;
								top: auto;
								width: auto;

								// stylovani
								display: none;
							}

							input[type='checkbox'] + label {

								// reset default vlastnosti z default 05_html_elements
								// obecny styly elementu tam nepatri a maj jit pres odpovidajici tridy :(
								@include typography_font(regular);
								text-transform: none;

								// stylovani
								@include b-008l-view-filters_tab();
							}
							/* last */
							&.form-item:last-child input[type='checkbox'] + label {
								@include b-008l-view-filters_tab--lastchild();
							}
							/* active */
							input[type='checkbox'] + label:active,
							input[type='checkbox']:checked + label:active {
								@include b-008l-view-filters_tab--active();
							}
							/* checked */
							input[type='checkbox']:checked + label {
								@include b-008l-view-filters_tab--checked();
							}
							/* before */
							input[type='checkbox'] + label:before {
								@include b-008l-view-filters_tab--before();
							}
							/* checked before */
							input[type='checkbox']:checked + label:before {
								@include b-008l-view-filters_tab--checked-before();
							}
						}
					}
				}
			}
		}
	}


}
