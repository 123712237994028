//
// 1968 B-014a Styles
//



.domain-1968-rozhlas-cz {

	// nav
	.b-014a {
		background-color: unset;
	}

	// nekonecny pruh hlavni navigace
	.b-014a::before {
		background-color: $domain-1968_menu-bar-background-color;
		opacity: 0.85;
	}



		// logo
		.b-014a__logo {
		}

			.b-014a__logo a {
				background-image: $domain-1968_logo_corporate_colored-9e9ea9;
			}



		// menu
		.b-014a__list {
		}

			// obecna polozka menu
			//.b-014a__list-item {
			.b-014a__list > li {
			}

				// a jeji vnitrni odkaz
				//.b-014a__list-item > a {
				.b-014a__list > li > a {
					@include link-style ($color_background-5, none, $color_background-5, none);
				}
				.b-014a__list > li > a.expanded,
				.b-014a__list > li > a:hover {
					@include link-style ($color_text_paragraph, none, $color_text_accent, none);
				}



		// searchbar
		.b-014a__searchbar {
		}

			.b-014a__searchbar-submit {
			}

				.b-014a__searchbar-submit-image {
					background-image: $file_icon_search;
			}


}
