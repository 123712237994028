//
// B-026 Styles
//



.b-026 {
	
	// vizualni debug
	// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec
	max-width: 400px;
	
}
	
	.b-026__subsection {
		
		padding: 1em;
		
		background-color: $color_background-2;
		
	}
		
		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-026__subsection input {
			margin-bottom: 0.75em;
		}
		
		.b-026__legend--disclaimer {
			
			margin-top: 0.5em;
			margin-bottom: 1em;
			
			color: $color_text_paragraph;
			
			
			@include adjust-font-size-to($fontsize_box_petit_main);
			
			@include breakpoint($breakpoint_desktop) {
				@include adjust-font-size-to($fontsize_box_petit_main_desktop);
			}
		}
			
			.b-026__disclaimer-link {
				@include link-style($color_decoration_main, none, $color_decoration_main, none);
			}
		
		
		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-026__subsection .button-form-flat--confirm {
			
			// mobilni button je pres celou sirku (nekazana feature)
			
			// desktopovy button dle designu jen jak je potreba dle obsahu
			@include breakpoint($breakpoint_desktop) {
				width: auto;
			}
		}
	
	
	.b-026__legend--required-fields {
		
		margin-top: 0.5em;
		
		color: $color_text_paragraph;
		
		@include adjust-font-size-to($fontsize_box_petit_main);
		
		@include breakpoint($breakpoint_desktop) {
			@include adjust-font-size-to($fontsize_box_petit_main_desktop);
		}
	}
