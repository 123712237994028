//
// B-002k Styles
//
// markup tohoto boxu je velmi podobny b002i
//


// custom font sizes
$b-002k-custom-h3: 1.125rem;
$b-002k-custom-h3-desktop: 1.25rem;
$b-002k-custom-h4: .875rem;



.b-002k {
	@include breakpoint($breakpoint_desktop) {
		display: flex;
	}
}

	// obrazek
	.b-002k__image {
		position: relative;
		@include breakpoint($breakpoint_desktop) {
			margin-right: 20px;
			flex-basis: 40%;
		}
	}

		.b-002k__image picture {
				display: flex;
				flex-direction: column;
			@include breakpoint($breakpoint_desktop) {
				display: block;
			}
		}

		.b-002k__image:hover + .b-002k__list .b-002k__list-item .b-002k__promo h3 {
			@include breakpoint($breakpoint_desktop) {
				text-decoration: underline;
			}
		}

		// overlay pro mobil
		.b-002k__image .b-002k__overlay {
			position: absolute;
			bottom: 0;
			left: 10px;
			@include breakpoint($breakpoint_desktop) {
				display: none;
			}
		}

	// texty
	.b-002k__list {
		background-color: $color_background-2;
		padding: 15px;
		@include breakpoint($breakpoint_desktop) {
			padding: 0;
			flex-basis: 60%;
			background-color: transparent;
		}
	}

		// li
		.b-002k__list-item {
		}

			.b-002k__list-item:nth-child(2) {
				margin-top: 12px;
			}

			// overlay pro desktop
			.b-002k__list-item:first-child .b-002k__overlay {
				display: none;
				@include breakpoint($breakpoint_desktop) {
					display: block;
					margin-bottom: 1em;
				}
			}

			// obecne vlastnosti nadpisu prvniho elementu
			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-002k__list-item h3 {
				margin: 0;
				padding: 0;
			}

	.b-002k__promo {
		border-bottom: 1px solid $color_divider;
		padding-bottom: 15px;
		@include breakpoint($breakpoint_desktop) {
			padding-bottom: 5px;
		}
	}

		.b-002k__promo h3 {
			display: flex;
			font-size: $b-002k-custom-h3;
			float: none;
			background-color: transparent;
			@include breakpoint($breakpoint_desktop) {
				font-size: $b-002k-custom-h3-desktop;
			}
		}

			.b-002k__promo h3 a {
				width: 100%;
			}

			.b-002k__promo-text a p {
				display: none;
				@include breakpoint($breakpoint_desktop) {
					display: flex;
					color: $color_background-6;
					margin-top: .5em;
					margin-bottom: .5em;
				}
			}

	.b-002k__headers {
	}

		.b-002k__headers h4 {
			margin-top: .65em;
			margin-bottom: .65em;
			font-size: $b-002k-custom-h4;
			line-height: 1.25;
		}
