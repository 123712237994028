//
// button
//



button {
	@include typography_font(regular);
	border: 0;
	cursor: pointer;
}
