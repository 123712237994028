//
// Styles - Node Type Event
//



.node-type-event {

	// grid
	// TODO: zmenit z .item-special na .nth-of-type--special
	.l-grid--12-1.nth-of-type--special {
	}


	// title
	// TODO: v sablone zanorit do .l-grid--12-1.nth-of-type--1 (obrazkovy) a .l-grid--8-1.nth-of-type--1 (standardni/audio) a prepsat styly
	h1.event-title {
		//width: calc(100% - 2.75em); // kvuli soc. buttonu na mobilu

		margin: 1em 0;
		margin-bottom: 0.5em;

		line-height: 1.8rem;

		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			width: 66.66667%; // viz .l-grid--8-1

			padding-right: 25px; // viz .l-grid--8-1

			line-height: 2.5rem; // 3rem
		}
	}



	// grid
	.l-grid--12-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--12-1 {
		}


	// grid
	.l-grid--8-1.nth-of-type--1 {
	}

		// modified, show, top social buttons, primary image/audio
		.content-1--8-1 {

			.file-audio {
				margin-left: 0;
			}
		}


		// content-container
		.content-container {
			position: relative; // kvuli absolutnimu pozicobvani vertikalnich soc. buttonu
		}

			// vertical soc. buttons
			.content-1--1-1 {
				display: none;

				@include breakpoint($breakpoint_desktop) {
					display: block;

					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: calc(100% - #{$node_left-margin});
				}
			}

			// perex, body
			.content-1--7-2 {
			}

				// anotation box
				.event-annotation {
					margin: 1.2em 0; // bylo -10px jako odecteni .main paddingu,a le nakonec opusteno
					padding: 1.2em 10px; // v px protozoze ma byt stejne jako .main padding

					border: 1px solid $color_background-4;
					border-left: none;
					border-right: none;

					font-size: 14px;
					color: $color_text_paragraph;

					@include breakpoint($breakpoint_desktop) {
						margin: 1.7em 0;
						margin-left: $node_left-margin;
						padding: 0.75em $node_padding;

						background-color: $color_background-1;
						border: 1px solid $color_background-4;

						font-size: 16px;
						line-height: 1.5;
					}

					p {
						display: inline;
						margin: 0;
					}

					a {
						@include link-style($color_decoration_main, none, $color_decoration_main, none);
					}

					// date start, end, separators
					.event-start,
					.event-date-separator,
					.event-end {
						color: $color_text_paragraph_node;
					}
					.event-date-vertical-separator {
						margin: 0 0.25em;
					}
				}


				// field perex
				.field.field-perex {}


				// field body
				.field.body {

					// collapsible
					&.body-collapsible {
						@include breakpoint($breakpoint_desktop) {
							padding: 0;
						}

						// button readmore before/after
						.button-readmore__body-before,
						.button-readmore__body-after {
							display: block;

							@include link-style($color_decoration_main, none, $color_decoration_main, underline);

							position: relative;
							z-index: 1;
						}

							// label
							.button-readmore__label {
								font-size: 16px;
								line-height: 1.3;
								text-align: center;

								color: $color_decoration_main;
							}

							// list
							.button-readmore__list {
								display: flex;
								align-items: center;
							}

								// list-item
								.button-readmore__list-item {
									flex-grow: 0;
									flex-shrink: 1;
								}

								// button readmore
								.button-readmore__list-item:first-child,
								.button-readmore__list-item:last-child {
									flex-grow: 1;
								}

								.button-readmore__list-item:first-child {
									margin-right: 15px;
								}

								.button-readmore__list-item:last-child {
									margin-left: 15px;
								}

									// button readmore: line
									.button-readmore__line {
										width: 100%;
										height: 1px;
										background-color: $color_decoration_main;
									}

									// button readmore: ico
									.button-readmore__ico {
											width: 1.5em;
											height: 1.5em;

											background-image: $file_icon_arrow_single_south_colored-decoration-main;
											background-repeat: no-repeat;
											background-position: center center;
											background-size: 0.85em;
									}

									// button readmore after: ico
									.button-readmore__body-after .button-readmore__ico {
										transform: rotate(180deg);
									}


						// body collapsible container
						.body-collapsible-container {
							transition: max-height 0.2s ease;
							overflow: hidden;

							@include breakpoint($breakpoint_desktop) {
								margin-left: 0;
								padding: 0 $node_padding;
							}
						}

						// collapsed
						&--collapsed {
							.body-collapsible-container {
								position: relative;
								max-height: 150px;
								overflow: hidden;
							}

							// gradient
							.body-collapsible-container::after {
								content: '';

								position: absolute;
								top: 0;
								left: 0;

								width: 100%;
								height: 100%;

								// gradient v Safari: https://stackoverflow.com/questions/55594942/transparent-gradient-not-working-in-safari
								//background-image: linear-gradient(to top, $color_background-0, transparent 50%);
								background-image: linear-gradient(to top, $color_background-0, rgba(#ffffff, 0) 50%); // list: gradient color
							}

							// button readmore after
							.button-readmore__body-after {
								pointer-events: none;
							}

							// list
							.button-readmore__body-after .button-readmore__list {}

								// list-item
								.button-readmore__body-after .button-readmore__list-item {
									display: none;
								}

								.button-readmore__body-after .button-readmore__list-item:first-child,
								.button-readmore__body-after .button-readmore__list-item:last-child {
									display: block;
									margin-right: 0;
									margin-left: 0;
								}

							// label
							.button-readmore__body-after .button-readmore__label {
								display: none;
							}
						}
					}
				}


		// main audio anchor, authors, bottom soc. buttons
		.content-2--8-1 {
		}

			// fotka autora - fallback
			.view-display-id-node_author_main.box {
				display: none; // opusteno
			}

			// jmeno autora - fallback
			.view-display-id-node_author_main_2.box {
				display: none; // opusteno
			}

			// expired audio
			.article-type--audio .audio-rights-expired {
				@include breakpoint($breakpoint_desktop) {
					margin-left: 0;
				}
			}


	// grid - aside
	.l-grid--4-9.nth-of-type--1 {
		@include breakpoint($breakpoint_desktop) {
			margin-top: 8em;
		}
	}

		// content boxes, ad
		.content-1--4-9 {
		}


	// grid
	.l-grid--12-1.nth-of-type--2 {
	}

		// iRozhlas
		.content-2--12-1 {
		}


}


/***
 * .no-title
 */
.node-type-event.no-title h1.event-title { display: none; }
.node-type-event.no-title .l-grid--8-1.nth-of-type--1 .content-1--8-1  .field-image-primary {
	margin-top: 32px; // jako 2em u .box

	@include breakpoint($breakpoint_desktop) {
		margin-top: 48px; // jako 3em u .box
	}
}


/***
 * .no-main-image
 */
.node-type-event.no-main-image .l-grid--8-1.nth-of-type--1 .content-1--8-1 .block-social-buttons--top,
.node-type-event.no-main-image .l-grid--8-1.nth-of-type--1 .content-1--8-1 .field-image-primary,
.node-type-event.no-main-image .l-grid--8-1.nth-of-type--1 .content-1--8-1 .img-metadata { display: none; }
.node-type-event.no-main-image .l-grid--8-1.nth-of-type--1 .content-1--7-2 .field-perex,
.node-type-event.no-main-image:not(.junior) .l-grid--8-1.nth-of-type--1 .content-1--7-2 .field-perex p:first-of-type { margin-top: 0; }


/***
 * .no-title.no-main-image
 */
.node-type-event.no-title.no-main-image .l-grid--8-1.nth-of-type--1 .content-1--7-2 .field-perex {
	margin-top: 32px; // jako 2em u .box

	@include breakpoint($breakpoint_desktop) {
		margin-top: 48px; // jako 3em u .box
	}
}
// grid - aside
.node-type-event.no-title.no-main-image .l-grid--4-9.nth-of-type--1 {
	@include breakpoint($breakpoint_desktop) {
		margin-top: 0;
	}
}





/***
 * fallback na stary typ udalosti
 */
.event-original.node-type-event .event-title {
	display: none;
}

.event-original.node-type-event {

	// grid
	.l-grid--12-1.item-first {
		margin-bottom: -20px; // hack pro variantu s nebo bez h1

		@include breakpoint($breakpoint_desktop) {
			margin-bottom: -29px; // hack pro variantu s nebo bez h1
		}
	}
	// clearfix
	.l-grid--12-1.item-first:after {
		clear: both;
		content: "";
		display: table;
	}

		// title
		.l-grid--12-1.item-first h1 { // proc nema tridu jako u article?!
			//width: calc(100% - 2.75em);

			margin: 1em 0;
			margin-bottom: 0.5em;
			margin-bottom: 0px; // hack pro variantu s nebo bez h1

			line-height: 1.8rem;

			color: $color_text_accent;

			@include breakpoint($breakpoint_desktop) {
				width: calc(66.66667% - 4.375rem);

				margin-bottom: 0.5em; // hack pro variantu s nebo bez h1
				padding-left: 1.875rem;
				padding-right: 25px;

				line-height: 2.5rem; // 3rem

				float: left;
			}
		}



	// content wrapper - _node.scss
	.inner-content-wrapper {
		margin-top: 32px; // hack pro variantu s nebo bez h1

		@include breakpoint($breakpoint_desktop) {
			margin-top: 48px; // hack pro variantu s nebo bez h1
		}
	}



		// grid
		.l-grid--8-1 {
		}

			// modified, show, top social buttons
			.content-1-0 {
			}

			// vertical soc. buttons, primary image/audio, serial pager, serial link, perex, body
			.content-1-1 {

			}

			// main audio anchor
			.content-1-2 {
			}

			// authors, bottom soc. buttons
			.content-1-3 {
			}


			// ...
			.content-1-4 {
			}



		// grid - aside
		.l-grid--4-9 {
			@include breakpoint($breakpoint_desktop) {
				margin-top: 8em;
			}
		}

			// content boxes, ad
			.sidebar-1-1 {
			}

			// content boxes
			.sidebar-1-2 {
			}



	// triangle
	.node-trianle {
		@include breakpoint($breakpoint_desktop) {
			//margin-top: 0;
		}
	}

	// metadata box
	.event-metadata {
		display: block; // table

		margin: 1.2em -10px; // -10px je odecteni .main paddingu
		padding: 0.5em 10px; // v px protozoze ma byt stejne jako .main padding

		border: 1px solid $color_background-4;
		border-left: none;
		border-right: none;

		font-size: 14px;

		@include breakpoint($breakpoint_desktop) {
			margin: 1.7em 0;
			margin-left: $node_left-margin;
			padding: 0.75em $node_padding;

			background-color: $color_background-1;
			border: 1px solid $color_background-4;

			font-size: 16px;
		}

			// organizer
			.event-metadata__organizer {
			.event-metadata__organizer--label {}
			.event-metadata__organizer--title {}
			}

		// date
		.event-metadata__date {
			.event-metadata__date--label {}
			.event-metadata__date--date {}
		}

		// location
		.event-metadata__location {
		.event-metadata__location--label {}
		.event-metadata__location--location {}
		}

		// tr
		// organizer, date, location
		.event-metadata__organizer,
		.event-metadata__date,
		.event-metadata__place {
		}

		// td labels
		// organizer, date, location
		.event-metadata__organizer .event-metadata__organizer--label,
		.event-metadata__date .event-metadata__date--label,
		.event-metadata__location .event-metadata__location--label {
			padding-right: 1em;
			text-align: right;
			vertical-align: top;

			@include breakpoint($breakpoint_desktop) {
				padding-right: 1.5em;
			}
		}

		// td values
		// organizer, date, location
		.event-metadata__organizer .event-metadata__organizer--title,
		.event-metadata__date .event-metadata__date--date,
		.event-metadata__location .event-metadata__location--location {
			color: $color_text_paragraph;
		}

		// web
		.event-metadata__organizer .event-metadata__organizer--web {
			display: inline-block;

			a {
				@include link-style($color_decoration_main, none, $color_decoration_main, none);
			}
		}

		// spacer
		.event-metadata__organizer .event-metadata__organizer--spacer {
			margin: 0 0.5em;
			color: $color_text_paragraph;
		}

		// od - do
		.event-metadata__date--from,
		.event-metadata__date--to {
			//padding: 0 0.5em;
		}
	}

	.field.body .img-metadata,
	.field.body .node-image-metadata {
		@include breakpoint($breakpoint_desktop) {
			margin-left: 0;
		}
	}

	.img-metadata,
	.node-image-metadata {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}
	}

	.field-entity-organizer,
	.field-event-start,
	.field-odkaz {
		@include breakpoint($breakpoint_desktop) {
			margin: 0.2rem 0 0 $node_left-margin;
			padding: 0 $node_padding;
		}
	}

	.field-entity-organizer,
	.field.body {
		//@include typography_font(medium);
		//font-size: 1em;
		//line-height: 1.6em;
		//color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			//font-size: 1.250rem;
		}
	}

	.field-entity-organizer {
		@include typography_font(medium);
		font-size: 1em;
		line-height: 1.6em;
		color: $color_text_accent;

		@include breakpoint($breakpoint_desktop) {
			font-size: 1.250rem;
		}
	}

	.field-event-start {
		color: $color_text_paragraph;
	}

	.field-odkaz {
		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}
		font-size: .9em;
	}

	.b-027 {
		margin-top: 0;
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
			padding-left: 1.875em;
		}
	}

	.field-perex {
		margin-top: 2em;
	}

	.field-image-primary {
		@include breakpoint($breakpoint_desktop) {
			padding-left: $node_left-margin;
		}
	}

	.field-entity-show-wrapper {
		display: inline-block;
	}

	.field-term-event {
		margin: 0 0 .5em 0;
		display: inline-block;
		//color: $color_decoration_main;
		color: $color_text_paragraph;

		a {
			@include link-style($color_decoration_main, none, $color_decoration_main, underline);
		}

		@include breakpoint($breakpoint_desktop) {
			display: inline-block;
			border-left: 1px solid #aaa;
			padding: 0 0 0 0.625em;
			height: 1em;
			line-height: 1em;
			margin: .5em 0;
		}
	}

	.tabs {
		@include breakpoint($breakpoint_desktop) {
			margin-left: $node_left-margin;
		}
	}

	.l-grid--8-1 .content-1-2 .box {
		margin-left: $node_left-margin;

		h2 {
			font-size: 1.7rem;
			margin-bottom: .6rem;
		}
	}
}
