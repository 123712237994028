//
// B-001b Styles
//
// markup tohoto boxu je stejny (velmi podobny) s b001a
// zmeny jsou na urovni stylu
// promenne jsou sdilene z b001a
//



$b-001b_item_background-arrow-size: 14px;



.b-001b {
}

	.b-001b__title {
		// v plabu neni, ale v drupalu tato trida pritomna
	}

	.b-001b__list {
	}

		.b-001b__list-item {

			background: $color_background-1;
		}

			.b-001b__link {

				position: relative;

				display: block;
				width: 100%;

				padding: 0.8em $b-001_item_padding_horizontal;
				padding-right: calc(2 * #{$b-001_item_padding_horizontal} + #{$b-001b_item_background-arrow-size});

				@include adjust-font-size-to($b-001_item_fontsize_mobile);

				background-image: $file_icon_arrow_single_east_colored-decoration-main;
				background-repeat: no-repeat;
				background-position: right $b-001_item_padding_horizontal center;
				background-position-x: right $b-001_item_padding_horizontal; // IE safe positioning
				background-position-y: center;
				background-size: $b-001b_item_background-arrow-size $b-001b_item_background-arrow-size;

				@include breakpoint($breakpoint_desktop) {

					padding: 0.5em $b-001_item_padding_horizontal;
					padding-right: calc(2 * #{$b-001_item_padding_horizontal} + #{$b-001b_item_background-arrow-size});

					@include adjust-font-size-to($b-001_item_fontsize_desktop, 1.5);
				}
			}

			// oddelovaci pseudo linka...
			.b-001b__list-item > .b-001b__link:before {

				content: "";

				position: absolute;
				left: 0; // fallback pro calc, pokud nefunguje calc sirky, zafunguje 100% a musime zarovnat uplne vlevo)
				left: calc(#{$b-001_item_padding_horizontal});
				bottom: 0;

				width: 100%; // fallback pro calc
				width: calc(100% - 2 * #{$b-001_item_padding_horizontal});

				border-bottom: 1px solid $color_divider;
			}
			// ...neni na poslednim miste
			.b-001b__list-item:last-child > .b-001b__link:before {

				//content: initial; // nefunguje v IE
				display: none;
			}





// vizualni debug - plab only
.patternlab .b-001b {

	@include breakpoint($breakpoint_desktop) {
		// vizualni debug
		// tenhle box stejne neni urceny na vetsi sirku nez aside sloupec (v desktop)
		max-width: 400px;
	}
}
