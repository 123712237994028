//
// Portal - obecne vlastnosti vsech stranek
//



.portal {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_portal_main, $color_portal_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_portal_main);
	}


	/* b-016 wrapper - neni nav, nechceme ani wrapper */
	.b-016__wrapper {
		display: none;
	}

	/* main */
	.main {
		margin-top: 0;

		@include breakpoint($breakpoint_desktop) {
			margin-top: 0;
		}
	}
}
