//
// img-metadata



.img-metadata {
	display: inline-block;

	margin-top: 0.5em;
	width: 100%;

	font-size: 12px;
	line-height: 1.2;

	color: $color_text_accent;

	overflow: hidden;

	@include breakpoint($breakpoint_desktop) {
		font-size: 14px;
		line-height: 1.3;
	}

	a {
		@include link-style($color_decoration_main, none, $color_decoration_main, underline);
	}
}

	// image metadata: title
	.img-metadata__title {
		color: $color_background-6; // o stupen svetlejsi nez $color_text_paragraph;
	}

	// image metadata: spacer
	.img-metadata__spacer {
		display: inline-block;
		margin: 0 0.5em; // default
	}

	// image metadata: spacer pipe
	.img-metadata__spacer--pipe {
	}

	// image metadata: spacer comma
	.img-metadata__spacer--comma {
		display: inline;
		margin: 0;

		// TODO: fallback, pokud zustal nekde stary markup, casem dame pryc
		.img-metadata__authoring-info & {
			display: inline;
			letter-spacing: 5px;
		}
	}

	// image metadata - authoring: label
	.img-metadata__label {
		margin-right: 0.5em;
	}

	// image metadata - authoring: author
	.img-metadata__author {
	}

	// image metadata - authoring: source
	.img-metadata__source {
	}

	// image metadata - authoring: licence
	.img-metadata__licence {
	}
