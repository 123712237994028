//
// Sound Manager
//


// embed
.cro-soundmanager-iframe-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;

	iframe {
		position:absolute;
		left:0;
		top:0;

		width:100%;
		height:100%;
	}
}


.b-112,
.b-200,
.page-node,
.section-zaznamy,
.view-cestyzavodou-hp {

	// bg
	.b-032a-wrapper {

		background: rgba(0,0,0,0.75) none repeat scroll 0 0;

		display: flex;
		align-items: center;
		justify-content: center;

		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		width: 100vw;
		height: 100vh;

		z-index: 1000;

		.b-032a {
			max-width: 100%;

			background-color: $color_background-0;

			z-index: 1001;

			@include breakpoint($breakpoint_desktop) {
				//max-width: 700px;
				width: 700px;
			}
		}
	}
}
