//
// Bubble
//
// miry v pixelech aby vychazela haluz s pseudo css sipkou
//


// obecna bublina neexistuje a asi nema smysl zavadet
// navic by byla trida mozna prilis obecneho nazvu, takze kdyby mela vznikat tak pozor




//
// Bubble Share
//

.bubble-share {
	position: relative;

	display: inline-block;

	margin-top: 15px; // prostor pro ukazatel
	padding: 13px;

	background-color: $color_background-0;
	border: 1px solid $color_divider;
	box-shadow: $shadow_bubble;

	z-index: 99;
}

.bubble-share::before {
	content: '';

	position: absolute;
	top: -9px;
	left: 29px;

	width: 15px;
	height: 15px;

	background-color: $color_background-0;
	border-left: 1px solid $color_divider;
	border-top: 1px solid $color_divider;

	transform: rotate(45deg);
}

	.bubble-share__list {
		display: inline-flex;
		flex-direction: column;
	}

		.bubble-share__list-item {
			margin-bottom: 12px;
		}
		.bubble-share__list-item:last-child {
			margin: 0;
		}

			// z duvodu atomu bez vlastni tridy
			// ohybani vychozi vlastnosti atomu
			.bubble-share__list-item > .button-social {
				width: 48px;
				height: 48px;
			}





//
// Bubble Subscribe
//

$bubble-subscribe-width: 225px; // kvuli vertikalnimu pozicovani na stred, viz napr. b-019b, b-001d, apod.

.bubble-subscribe {
	position: relative;

	display: inline-block;

	width: $bubble-subscribe-width; // kvuli vertikalnimu pozicovani na stred, viz napr. b-019b, b-001d, apod.

	// margin-top: 15px; // prostor pro ukazatel
	padding: 8px 0;

	background-color: $color_background-0;
	border: 1px solid $color_divider;
	box-shadow: $shadow_bubble;

	z-index: 99;
}

.bubble-subscribe::before {
	content: '';

	position: absolute;
	top: -9px;
	left: calc(50% - 21.213203435596px/2); // c = 15, b = 15, α = 90°  a^2 = b^2 + c^2 - 2bc * cos α

	width: 15px;
	height: 15px;

	background-color: $color_background-0;
	border-left: 1px solid $color_divider;
	border-top: 1px solid $color_divider;

	transform: rotate(45deg);
	z-index: -1;
}

.bubble-subscribe::after {
	content: '';

	position: absolute;
	top: 0;
	left: calc(50% - 15px);

	width: 26px;
	height: 1px;

	background-color: $color_background-0;
}
	.bubble-subscribe .bubble-subscribe__list {
	}

		.bubble-subscribe .bubble-subscribe__list-item {
		}

			// POZOR toto nove separovano jako smostatny atom _button-subscribe
			// zatim nechano jako fallback prave pro tuto bublinu (nutno nejdrive zmenit markup v Drupalu)
			.bubble-subscribe .bubble-subscribe__list-item a {
				position: relative;

				display: block;

				align-items: center;

				background-repeat: no-repeat;
				background-position: left .75em center;
				background-size: 2.2em 2.2em;

				height: 100%;

				padding: 0.6em 3.6em;

				font-size: 0.8rem;
				white-space: nowrap;

				@include link-style ( $color_background-6, none,$color_background-2, none);

				&:before {
					content: '';

					position: absolute;
					right: 0;

					display: inline-block;

					width: 30px;
					height: 24px;

					background-image: $file_icon_arrow_single_east;
					background-repeat: no-repeat;
					background-position-y: center;
					background-size: 10px 10px;

					opacity: 0.7;
				}

				&:hover,
				&:active {
					background-color: $color_background-6;
					&:before {
						background-image: $file_icon_arrow_single_east_colored-white;
					}
				}
			}


			.bubble-subscribe .bubble-subscribe__list-item--mujrozhlas a {
				background-image: $file_icon_mujrozhlas;
			}
				.bubble-subscribe .bubble-subscribe__list-item--mujrozhlas a:hover {
					background-image: $file_icon_mujrozhlas_colored-white;
				}


			.bubble-subscribe .bubble-subscribe__list-item--ios a {
				background-image: $file_icon_apple;
			}
				.bubble-subscribe .bubble-subscribe__list-item--ios a:hover {
					background-image: $file_icon_apple_colored-white;
				}


			.bubble-subscribe .bubble-subscribe__list-item--android a {
				background-image: $file_icon_android;
			}
				.bubble-subscribe .bubble-subscribe__list-item--android a:hover {
					background-image: $file_icon_android_colored-white;
				}

			.bubble-subscribe .bubble-subscribe__list-item--spotify a {
				background-image: $file_icon_spotify;
			}
				.bubble-subscribe .bubble-subscribe__list-item--spotify a:hover {
					background-image: $file_icon_spotify_colored-white;
				}

			.bubble-subscribe .bubble-subscribe__list-item--rss a {
				background-image: $file_icon_rss;
			}
				.bubble-subscribe .bubble-subscribe__list-item--rss a:hover {
					background-image: $file_icon_rss_colored-white;
				}

			.bubble-subscribe .bubble-subscribe__list-item--ytmusic a {
				background-image: $file_icon_ytmusic;
			}
				.bubble-subscribe .bubble-subscribe__list-item--ytmusic a:hover {
					background-image: $file_icon_ytmusic_colored-white;
				}





// vizualni debug - plab only
.patternlab .bubble-subscribe__wrapper {
	position: relative;
	left: 0;
}
