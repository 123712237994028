//
// tabs styles
//



.filter-tabs {
	
}	
	.filter-tabs .filter-tabs__list {
		
		display: flex;
	}
		
	.filter-tabs .filter-tabs__list-item {
		
		color: $color_background-6;
		
		margin-right: 1em;
	}
	
		.filter-tabs .filter-tabs__list-item .filter-tabs__link {
		
			color: $color_background-6;
		}
	
		.filter-tabs .filter-tabs__list-item--selected,
		.filter-tabs.filter-tabs__list-item--selected .filter-tabs__link {
		
			color: $color_background-9;
		}