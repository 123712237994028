//
// Zelenavlna - node 7934671 (Mapa)
//



.zelenavlna.page-node-7934671 {

	.main {

		.content {

			> h1.page-type {
				display: none;
			}

			> .inner-content-wrapper {
				display: none;
			}
		}
	}
}
