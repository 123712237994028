//
// B-038c Styles
//



.b-038c {
}

	.b-038c__list {
		padding: 0 1em;
		background: $color_background-1;
	}

		.b-038c__list-item {
			padding: 1em 0;
			border-bottom: 1px solid $color_divider;

			&:last-child {
				border-bottom: none;
			}
		}

		// TODO z duvodu atomu zatim ne pres specifickou tridu
		.b-038c__list-item h3 {
			margin: 0;
			margin-bottom: 0.25em;
		}

			// title link obal
			.b-038c__item-title {
			}

				// TODO z duvodu atomu zatim ne pres specifickou tridu
				.b-038c__item-title h3 {
					margin: 0;
					margin-bottom: 0.25em;
				}


			.b-038c__item-datetime {
				clear: left;
				display: inline;

				padding: 0.1em 0.25em;
				margin-right: 0.25em;

				background-color: $color_background-5;
				color: $color_text_accent_inverted;

				font-size: 0.85em;

				/*@include adjust-font-size-to($fontsize_box_petit_main);

				@include breakpoint($breakpoint_desktop) {
					@include adjust-font-size-to($fontsize_box_petit_main_desktop);
				}*/
			}

			// TODO z duvodu atomu zatim ne pres specifickou tridu
			.b-038c__list-item p {
				display: inline;
				margin: 0.25em 0 0 0;
				color: $color_text_paragraph;
			}

			.b-038c__list-item .button__wrapper {
				margin-top: 0;
			}





// vizualni debug - plab only
.patternlab .b-038c {

	@include breakpoint($breakpoint_desktop) {
		max-width: 500px;
	}
}
