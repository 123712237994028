//
// Portal - vysilace
//


$vysilace-font-size: .95rem;
$vysilace-font-size-tab: .85rem;


.page-vysilace.section-vysilace {
}

	// vyluky - box umisteny v asside
	.page-vysilace.section-vysilace .vyluky_box {
		display: inline-block;
		padding: 1.22222em 1em;
		background-color: $color_background-1;
		color: $color_background-6;
		font-size: $vysilace-font-size;
		line-height: 1.3;
	}
		// vyluky - nadpis
		.page-vysilace.section-vysilace .vyluky_box .outage_title {
			margin-top: 0;
			margin-bottom: 2px;
			color: $color_text_accent;
		}

		// vyluky - odstavec
		.page-vysilace.section-vysilace .vyluky_box .outage_details {
			margin-top: 0;
		}
		// vyluky - posledni odstavec
		.page-vysilace.section-vysilace .vyluky_box .outage_details:last-child {
			margin-bottom: 0;
		}


	// prepinac zalozek
  .page-vysilace.section-vysilace .box.vysilace {}

		// tab
  	.page-vysilace.section-vysilace .box.vysilace #sel_loc,
  	.page-vysilace.section-vysilace .box.vysilace #sel_map {
			display: inline-block;
			padding: 5px 10px;
			font-size: $vysilace-font-size-tab;
			background-color: $color_background-3;
			transition: background-color .125s, color .125s;
		}
		// tab :hover
		.page-vysilace.section-vysilace .box.vysilace #sel_loc:hover,
  	.page-vysilace.section-vysilace .box.vysilace #sel_map:hover {
			text-decoration: none;
		}
		// tab active :hover
		.page-vysilace.section-vysilace .box.vysilace #sel_loc:not(.tab-is-active):hover,
		.page-vysilace.section-vysilace .box.vysilace #sel_map:not(.tab-is-active):hover {
			color: $color_background-0;
			background-color: $color_background-6;
		}
			// tab active
			.page-vysilace.section-vysilace .box.vysilace #sel_loc.tab-is-active,
			.page-vysilace.section-vysilace .box.vysilace #sel_map.tab-is-active {
				background-color: $color_background-2;
			}

	// 1. zalozka
	// vysilace - dle lokality
  .page-vysilace.section-vysilace .box.vysilace #lokality_box {
	}

	  .page-vysilace.section-vysilace .box.vysilace #lokality_box #geocoder {
			@include breakpoint($breakpoint_desktop) {
				display: flex;
			}
		}

			.page-vysilace.section-vysilace .box.vysilace #lokality_box .views-exposed-widget.views-submit-button-wrapper {
				@include breakpoint($breakpoint_desktop) {
					display: flex;
					flex-basis: 40%;
				}
			}

				.page-vysilace.section-vysilace .box.vysilace #lokality_box .views-exposed-widget.views-submit-button-wrapper .views-widget .form-item {
					@include breakpoint($breakpoint_desktop) {
						margin-bottom: 0;
					}
				}

				.page-vysilace.section-vysilace .box.vysilace #lokality_box .views-exposed-widget.views-submit-button-wrapper input[type=submit] {
					width: 100%;
					@include breakpoint($breakpoint_desktop) {
						width: auto;
						display: flex;
						text-indent:-9999px;
						color: transparent;
						max-height: 34px;

						background-color: $color_background-0;

						background-image: $file_icon_search_colored-bg5;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: $view-filters_search-submit_background-icon-size_mobile $view-filters_search-submit_background-icon-size_mobile;

						cursor: pointer;
					}
				}

			.page-vysilace.section-vysilace .box.vysilace #lokality_box #found_addr {
				@include breakpoint($breakpoint_desktop) {
					flex-basis: 60%;
					margin-left: 20px;
				}
			}

				.page-vysilace.section-vysilace .box.vysilace #lokality_box .views-exposed-widget.views-submit-button-wrapper .views-widget .form-input {
				}

				.page-vysilace.section-vysilace .box.vysilace #lokality_box .views-exposed-widget.views-submit-button-wrapper .views-widget input.form-text {
					border: 1px solid transparent;
				}

				.page-vysilace.section-vysilace .box.vysilace #lokality_box .views-exposed-widget.views-submit-button-wrapper .views-exposed-widget.views-submit-button {
				}


		// vyluky upozorneni
		.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky {
			display: flex;
			align-items: stretch;
			height: 100%;
			margin-top: 20px;
			font-size: $vysilace-font-size;
			color: $color_background-6;
		}
		// vyluky upozorneni - wrapper
			.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky .outage-warning__wrap {
				display: flex;
				flex-wrap: wrap;
				flex-basis: 100%;
				padding: 10px;
				@include breakpoint($breakpoint_desktop) {
					padding: 20px 10px;
				}
			}

				// nalezena lokalita
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #found_addr {
					margin-top: 20px;
					margin-bottom: 10px;
					color: $color_background-6;
					@include breakpoint($breakpoint_desktop) {
						margin-top: 7px;
						margin-bottom: 0;
					}
				}


				// vyluky upozorneni - titulek hlasky
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky .outage-warning__title {
					color: $color_decoration_alert;
					flex-basis: 100%;
					margin-bottom: 12px;
					font-size: 1rem;
					@include breakpoint($breakpoint_tablet) {
						font-size: 1.125rem;
						margin-bottom: 8px;
					}
				}

				// vyluky upozorneni - odstavec hlasky
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky .outage-warning__paragraph {
					flex-basis: 100%;
				}

				// vyluky upozorneni - graficke symboly uvozujici upozorneni
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky .leva,
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky .prava {
					display: flex;
					width: 15px;
					border-top: 1px solid $color_background-4;
					border-bottom: 1px solid $color_background-4;
				}
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky .leva {
					border-left: 1px solid $color_background-4;
				}
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #vyluky .prava {
					border-right: 1px solid $color_background-4;
				}

		// vyhledavani
		.page-vysilace.section-vysilace .box.vysilace #lokality_box #geocoder .inputs {
			display: flex;
			flex-wrap: wrap;
			@include breakpoint($breakpoint_tablet) {
				flex-wrap: nowrap;
			}
		}
		.page-vysilace.section-vysilace .box.vysilace #lokality_box #geocoder .inputs input[type=submit] {
			flex-basis: 100%;
			margin-top: 1em;
			@include breakpoint($breakpoint_tablet) {
				flex-basis: auto;
				margin-top: 0;
				margin-left: 1em;
			}
		}

		// prepinac celoplosne/regionalni a detail jednotlivych vysilacu
		.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail {
			display: flex;
			flex-wrap: wrap;
			@include breakpoint($breakpoint_tablet) {
				margin-left: -5px;
				margin-right: -5px;
				width: auto;
			}
		}

		// tisk
		.page-vysilace.section-vysilace .box.vysilace #lokality_box #print_page {
			display: none;
			font-size: $vysilace-font-size-tab;
			color: $color_background-5;
			@include breakpoint($breakpoint_desktop) {
				display: flex;
				margin-top: 3.2em;
				@include no-select;
			}
		}
		// tisk - ikona
		.page-vysilace.section-vysilace .box.vysilace #lokality_box #print_page::after {
			content: '';
			margin-left: 5px;
			display: inline-block;
			width: 24px;
			height: 24px;
			background: $file_button_action_print no-repeat center;
			background-size: 24px 24px;
		}

			// item
			.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item {
				flex-basis: 100%;
				margin-bottom: 10px;
				padding: 1.2em;
				font-size: $vysilace-font-size;
				background-color: $color_background-2;
				@include breakpoint($breakpoint_tablet) {
					flex-basis: calc(50% - 10px);
					margin-left: 5px;
					margin-right: 5px;
					transition: background-color .3s;
				}
				&:hover {
					@include breakpoint($breakpoint_tablet) {
						background-color: $color_background-3;
					}
				}
			}

				// loga jednotlivych stanic
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item .station_name {
					display: inline-block;
					padding: 6px;
					background-color: $color_corporate_main; // default coporate color
					background-repeat: no-repeat;
					background-position: center;
					text-indent: -9999px;
					&:after {
						content: '';
						display: block;
						height: 16px;
						background-repeat: no-repeat;
					}
					& > span {
						display: none;
					}
				}


					// logo: celoplosne stanice

					// radiozurnal
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--radiozurnal .station_name {
						&:after {
							background-image: $file_logo_radiozurnal_colored-white;
							width: 107px;
						}
					}

					// dvojka
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--dvojka .station_name {
						&:after {
							background-image: $file_logo_dvojka_colored-white;
							width: 66px;
							height: 18px; // zvetseni kvuli j v logu
						}
					}

					// vltava
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--vltava .station_name {
						&:after {
							background-image: $file_logo_vltava_colored-white;
							width: 63px;
						}
					}

					// plus
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--plus .station_name {
						&:after {
							background-image: $file_logo_plus_colored-white;
							width: 49px;
						}
					}

					// wave
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--radiowave .station_name {
						&:after {
							background-image: $file_logo_wave_colored-white;
							width: 106px;
						}
					}

					// D-dur
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--ddur .station_name {
						&:after {
							background-image: $file_logo_d-dur_colored-white;
							width: 59px;
						}
					}

					// Jazz
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--jazz .station_name {
						&:after {
							background-image: $file_logo_jazz_colored-white;
							width: 51px;
						}
					}

					// Junior
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--radiojunior .station_name {
						&:after {
							background-image: $file_logo_junior_colored-white;
							width: 113px;
						}
					}

					// Retro
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--archiv .station_name {
						&:after {
							background-image: $file_logo_retro_colored-white;
							width: 108px;
						}
					}

					// Sport
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--sport .station_name {
						&:after {
							background-image: $file_logo_sport_colored-white;
							width: 58px;
							height: 18px; // zvetseni kvuli p v logu
						}
					}

					// Radiozurnal Sport
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--radiozurnalsport .station_name {
						&:after {
							background-image: $file_logo_radiozurnalsport_colored-white;
							width: 151px;
							height: 18px; // zvetseni kvuli p v logu
						}
					}

					// Pohoda
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--pohoda .station_name {
						&:after {
							background-image: $file_logo_pohoda_colored-white;
							width: 75px;
						}
					}


					// regionalni stanice

					// Brno
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--brno .station_name {
						&:after {
							background-image: $file_logo_brno_colored-white;
							width: 53px;
						}
					}

					// Ceske Budejovice
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--ceskebudejovice .station_name {
						background-color: $color_region_main;
						&:after {
							background-image: $file_logo_budejovice_colored-white;
							width: 150px;
							height: 18px; // zvetseni kvuli j v logu
						}
					}

					// Hradec Kralove
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--hradeckralove .station_name {
						background-color: $color_region_main;
						&:after {
							background-image: $file_logo_hradec_colored-white;
							width: 133px;
						}
					}

					// Karlovy Vary
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item--karlovyvary .station_name {
						&:after {
							background-image: $file_logo_vary_colored-white;
							width: 110px;
							height: 18px; // zvetseni kvuli y v logu
						}
					}

					// Liberec
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--liberec .station_name {
						&:after {
							background-image: $file_logo_liberec_colored-white;
							width: 74px;
						}
					}

					// Olomouc
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--olomouc .station_name {
						&:after {
							background-image: $file_logo_olomouc_colored-white;
							width: 86px;
						}
					}

					// Ostrava
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--ostrava .station_name {
						&:after {
							background-image: $file_logo_ostrava_colored-white;
							width: 75px;
						}
					}

					// Pardubice
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--pardubice .station_name {
						&:after {
							background-image: $file_logo_pardubice_colored-white;
							width: 93px;
						}
					}

					// Plzen
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--plzen .station_name {
						&:after {
							background-image: $file_logo_plzen_colored-white;
							width: 58px;
						}
					}

					// Radio DAB Praha
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--radiodabpraha .station_name,
          .page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--radiopraha .station_name {
						background-color: $color_region_main !important; // tady prebijime .bg-color-main-radiopraha kde je defaulni korporatni barva
						&:after {
							background-image: $file_logo_dab_colored-white;
							//width: 147px; // Radio DAB Praha
							width: 110px; // Radio Praha
						}
					}

					// Region
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--regionprahaastrednicechy .station_name {
						background-color: $color_region_main;
						&:after {
							background-image: $file_logo_region_colored-white;
							width: 67px;
							height: 18px; // zvetseni kvuli y v logu
						}
					}

					// Sever
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--sever .station_name {
						&:after {
							background-image: $file_logo_sever_colored-white;
							width: 61px;
						}
					}

					// Vysocina
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--vysocina .station_name {
						&:after {
							background-image: $file_logo_vysocina_colored-white;
							width: 81px;
							height: 18px; // zvetseni kvuli y v logu
						}
					}

					// Zlin
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--zlin .station_name {
						&:after {
							background-image: $file_logo_zlin_colored-white;
							width: 45px;
						}
					}

					// Praha
					.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item.vysilac-item--cro7 .station_name {
						&:after {
							background-image: $file_logo_radiopraha_colored-white;
							width: 144px;
							height: 18px; // zvetseni kvuli g v logu
						}
					}


				// popisky vysilace
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item .vysilac-item__description {
					margin-top: 0;
					margin-bottom: 0;
					color: $color_background-6;
				}
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .vysilac-item .vysilac-item__description strong {
					color: $color_text_accent;
				}

				// prepinac celoplosne/regionalni
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .radiobutton-filter {
					justify-content: flex-start;
					margin-top: 1.5em;
					@include breakpoint($breakpoint_tablet) {
						margin-left: 5px;
						margin-right: 5px;
					}
					@include breakpoint($breakpoint_desktop) {
						flex-basis: calc(100% - 68px); // uvolneni mista pro print button
						margin-top: 2.5em;
						margin-right: 0;
					}
				}
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .radiobutton-filter .radiobutton-filter__item:hover {
					text-decoration: none;
				}
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .radiobutton-filter .radiobutton-filter__item.tab-is-active {
					background-color: $color_decoration_main;
				}
				.page-vysilace.section-vysilace .box.vysilace #lokality_box #stat_detail .radiobutton-filter .radiobutton-filter__item.tab-is-active label {
					color: $color_background-0;
				}



				// 2. zalozka
				// vysilace - mapa
				.page-vysilace.section-vysilace .box.vysilace #mapa_box {
				}
					// velikost mapy v zavislosti na velikosti viewportu
					.page-vysilace.section-vysilace .box.vysilace #mapa_box #mapa {
						height: 300px;
						@include breakpoint($breakpoint_tablet) {
							height: 400px;
						}
						@include breakpoint($breakpoint_desktop) {
							height: 500px;
						}
					}

					// oznaceni konkretniho vysilace na mape
					.page-vysilace.section-vysilace .box.vysilace #mapa_box	.selected-transmitter-marker {
						border-radius: 4px;
						background-color: $color_background-0;
						box-shadow: 0 0 0 3px $color_background-0;
						z-index: 999 !important;
						&:focus {
							outline: none;
						}
					}

					// vyber stanice
					.page-vysilace.section-vysilace .box.vysilace #mapa_box #stations_sel {
						background-color: $color_background-2;
						padding: 1.875em;
					}

					// vyber stanice - navod
					.page-vysilace.section-vysilace .box.vysilace #mapa_box .vysilace-description {
						color: $color_background-6;
						font-size: $vysilace-font-size;
						line-height: 1.3;
						margin-bottom: 0;
						@include breakpoint($breakpoint_desktop) {
							display: inline-flex;
							margin-left: 20px;
							margin-top: 0;
						}
					}

				// detaily vysilace
				.page-vysilace.section-vysilace .box.vysilace #trans_detail {
					display: flex;
					flex-wrap: wrap;
					margin-top: 20px;
					color: $color_text_accent;
					font-size: $vysilace-font-size;
				}

					.page-vysilace.section-vysilace .box.vysilace #trans_detail .vysilace__intro {
						width: 100%;
						margin-bottom: 20px;
						padding: 18px;
						background-color: $color_background-2;
					}

					.page-vysilace.section-vysilace .box.vysilace #trans_detail .vysilace__intro .vysilace__text {
						line-height: 1;
					}

					.page-vysilace.section-vysilace .box.vysilace #trans_detail .vysilace__detail-item {
						flex-basis: 100%;
						margin-bottom: 2em;
						@include breakpoint($breakpoint_tablet) {
							flex-basis: 50%;
							margin-bottom: 3em;
						}
					}

					.page-vysilace.section-vysilace .box.vysilace #trans_detail .vysilace__detail-item .station_title {
						margin-top: 0;
						margin-bottom: 0;
					}

					.page-vysilace.section-vysilace .box.vysilace #trans_detail .vysilace__detail-item hr {
						display: none;
					}

					.page-vysilace.section-vysilace .box.vysilace #trans_detail .vysilace__text {
						color: $color_background-6;
						line-height: 2.5;
					}


					// uprava tlacitka
					.page-vysilace.section-vysilace .box.vysilace #trans_detail #dump_all {
						margin-top: 20px;
						@include breakpoint($breakpoint_tablet) {
							width: 60%;
						}
					}




// tisk
@media print {
	.page-vysilace.section-vysilace {
		header nav.b-014a,
		footer {
			display: none !important; // skryti footeru a headeru
		}
		.box.vysilace {
			#sel_loc,
			#sel_map,
			#lokality_box form#geocoder,
			.radiobutton-filter {
				display: none !important; // skryti filtru
			}
			#lokality_box {
				#vyluky {
					display: none !important;
				}
				#stat_detail {
					.vysilac-item {
						display: flex !important;
						flex-basis: 50% !important;
						flex-wrap: wrap !important;
						margin-right: 0 !important;
						background-color: transparent !important;
						.station_name {
							display: flex !important;
							flex-basis: 100% !important;
							background-color: transparent !important;
							padding: 0 !important;
							&::after {
								content: '' !important;
								background-image: none !important;
								width: 0 !important;
								height: 0 !important;
							}
						}
						.station_name span {
							display: block !important;
							margin-bottom: 2px !important;
							color: $color_text_accent !important;
							text-indent: 0 !important;
							font-size: 1.2rem !important;
							@include typography_font(medium);
						}
						.vysilac-item__description:not(:last-child) {
							margin-right: 5px !important;
							&::after {
								content: ' | ';
								color: $color_background-6 !important;
							}
						}
					}
				}
				#print_page {
					display: none !important; // skryti tlacitka tisku
				}
			}
			// vyluky
			.vyluky_box {
				background-color: transparent !important;
			}
		}
	}
}
