//
// Svet rozhlasu - obecne vlastnosti vsech stranek
//



$svet_selector_body: 'body.svet';
$svet_selector_body-hp: $svet_selector_body + '.page-svet-hp'; // hp


#{$svet_selector_body} {

	// specificke stanicni barveni elementu boxu
	@include station_element-coloring($color_corporate_main, $color_corporate_main_darker);

	&.page-node.node-type-article,
	&.page-node.node-type-page,
	&.page-node.node-type-profil,
  &.page-node.node-type-recipe,
	&.page-node.node-type-serial,
	&.page-node.node-type-show {

		// specificke stanicni barveni v nodu plneho zneni
		@include station_element-coloring_node-type-article($color_corporate_main);
	}

	// box
	.box {

		// titulek
		h2 {
			color: $color_corporate_main;
			a {
				@include link-style ($color_corporate_main, none, $color_corporate_main, underline);
			}
		}
	}

	// header
	.b-015 {
		.b-015__list-item--right {
			display: none; // prehravac a addons nechceme
		}
	}

}
